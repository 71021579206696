<div class="container">
    <form [formGroup]="headerForm" (ngSubmit)="onSubmit(headerForm)">
        <div class="row">
            <div class="col-md-6 mt-2" formArrayName="headers" *ngFor="let item of headers().controls; let i = index;"
                cdkDrag>
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="headers().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removeheaders(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Image URL</label>
                            <div class=" d-flex">
                                <!-- <input type="file" accept="image/*" (change)="handleIcon($event.target.files,i)"
                                    class="form-control" placeholder="Upload Image"> -->
                                <input type="text" class="form-control" placeholder="URL"
                                    formControlName="banner_image_url" (change)="dataChanged(i)">
                                <a [href]="headerForm.value.headers[i].banner_image_url" target="_blank"><img
                                        class="ms-1" height="40"
                                        [src]="headerForm.value.headers[i].banner_image_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'banner_image_url')"><i class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <label class="col-form-label">Image URL for Mobile</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL"
                                    formControlName="mob_banner_image_url" (change)="dataChanged(i)">
                                <a [href]="headerForm.value.headers[i].mob_banner_image_url" target="_blank"><img
                                        class="ms-1" height="40"
                                        [src]="headerForm.value.headers[i].mob_banner_image_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'mob_banner_image_url')"><i class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex p-5">
                <div class="m-auto">
                    <!-- <button *ngIf="(page=='home' && checkPermission('content_management_home_create'))"
                        class="btn btn-primary mt-1 col-md-12" type="button" (click)="addheaders()"><i
                            class="uil-plus"></i> Add</button> -->
                    <button *ngIf="(page=='home' && checkPermission('content_management_home_create'))  && 
                      (page=='home' && checkPermission('content_management_home_edit'))"
                        class="btn btn-info mt-1 mb-1 col-md-12" type="submit"><i
                            [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                        Save All</button>
                    <app-publish-btn *ngIf="(page=='home' && checkPermission('content_management_home_publish')) || 
                  (page=='consultant' && checkPermission('content_management_consultant_publish'))"
                        class="col-md-12 p-0" [publish]="'TrustedPartner'" [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
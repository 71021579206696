<div class="container-fluid set-minwh">
    <app-page-title title="Age Groups" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="my-3 row">
        <div class="offset-md-8 col-md-4">
            <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
                bindValue="id" [(ngModel)]="country" [clearable]="false" (change)="getAgeGroups(country)"></ng-select>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 offset-lg-3" *ngIf="!selected && ageGroupList.length == 0 && !isLoading">
            <div class="border shadow card  text-center p-4">
                Select Country to view Age Groups
            </div>
        </div>
        <div class="col-lg-4 offset-lg-4" *ngIf="isLoading">
            <div class="card text-center p-4">
                <i class="fa fa-spin fa-spinner fa-pulse fa-3x"></i>
            </div>
        </div>
        <div class="col-lg-12" [hidden]="!selected">
            <app-age-groups (refreshData)="refreshAgeGroups($event)"></app-age-groups>
        </div>
    </div>
</div>
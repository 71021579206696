<div class="container">
    <form class="row" [formGroup]="aboutForm" (ngSubmit)="onSubmit(aboutForm)">
        <div class="col-md-8 offset-md-2 p-4 mt-2">
            <div class="border shadow">
                <div class="my-2 mt-3 row">
                    <label class="col-md-3 offset-md-1 col-form-label">Heading</label>
                    <div class="col-md-7">
                        <input type="text" class="form-control" placeholder="Enter Heading" formControlName="heading"
                            [ngClass]="{'invalid': aboutForm.get('heading').invalid && isSubmitted}">
                    </div>
                </div>
                <div class="my-2 row">
                    <label class="col-md-3 offset-md-1 col-form-label">Description</label>
                    <div class="col-md-7">
                      <textarea cols="30" rows="5" class="form-control" placeholder="Enter Description"
                        formControlName="description" [ngClass]="{'invalid': aboutForm.get('description').invalid && isSubmitted}"></textarea>
                    </div>
                  </div>
                <div class="my-2 row">
                    <div class="col-md-12">
                        <button *ngIf="(page=='consultant' && checkPermission('content_management_consultant_create'))&&(page=='consultant' && checkPermission('content_management_consultant_edit'))" class="btn btn-success float-end me-5" [disabled]="!hasChanges"><i
                                [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                            Save Changes</button>
                            <app-publish-btn *ngIf="(page=='consultant' && checkPermission('content_management_consultant_publish'))" class="float-end me-2" [publish]="'ConsultancyService'" [page]="'consultancy'"></app-publish-btn>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
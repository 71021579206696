import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastrService } from "ngx-toastr";
import { OtherPagesService } from "src/app/core/services/other-pages.service";
import { PermissionService } from "src/app/core/services/permision.service";

@Component({
  selector: "app-consultant-visapro-description",
  templateUrl: "./consultant-visapro-description.component.html",
  styleUrls: ["./consultant-visapro-description.component.scss"],
})
export class ConsultantVisaproDescriptionComponent {
  public Editor = ClassicEditor;
  visaproForm: FormGroup;

  hasChanges = false;
  isUpdating = false;
  isSubmitted = false;
  isNew = false;
  publishPage: string;
  @Input() page;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    public permission: PermissionService,
    private consultantApi: OtherPagesService
  ) { }

  ngOnInit(): void {
    this.resetForm();
    this.getData();
    this.publishPage = 'VisaproApplicationProcess';
  }
  
  resetForm(data?) {
    this.visaproForm = this.fb.group({
      id: [data ? data.id : ""],
      is_updated: [false],
      is_deleted: [false],
      created_at: [false],
      description_data: this.fb.array([]),
    });
    if (data) {
      this.visaproForm.value.id = data.id;
      data.description.forEach((ao) => {
        (this.visaproForm.get("description_data") as FormArray).push(
          this.fb.group({
            description: [ao, [Validators.required]],
          })
        );
      });
    }
    this.onChanges();
  }

  onChanges(): void {
    this.visaproForm.valueChanges.subscribe(() => {
      Object.keys(this.visaproForm.controls).map((key) => {
        if (this.visaproForm.get(key).dirty) {
          this.hasChanges = true;
          this.visaproForm.value.is_updated = true;
        }
      });
    });
  }

  getData() {
    this.consultantApi.getVisaProDescription().subscribe((result: any) => {
      this.resetForm(result[0]);
      if (result.length == 0) this.isNew = true;
      else this.isNew = false;
    });
  }

  get descriptions(): FormArray {
    return this.visaproForm.get("description_data") as FormArray;
  }

  newDescription(): FormGroup {
    return this.fb.group({
      description: "",
    });
  }

  addDescription() {
    this.descriptions.push(this.newDescription());
  }

  removeDescription(i: number) {
    this.isSubmitted = false;
    this.hasChanges = true;
    this.descriptions.removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      let descriptions: any = [];
      form.value.description_data.forEach((ae) => {
        descriptions.push(ae.description);
      });
      form.value.description = descriptions;

      if (this.isNew) {
        this.consultantApi.postVisaProDescription(form.value).subscribe(
          () => {
            // this.resetForm(result);
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
            this.isNew = false;
          },
          () => {
            this.isUpdating = false;
          }
        );
      } else {
        this.consultantApi
          .setVisaProDescription(form.value.id, form.value)
          .subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          );
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

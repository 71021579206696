import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AccessManagementService } from "src/app/core/services/access-management.service";
import { AddUserComponent } from "./add-user/add-user.component";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
})
export class UserComponent implements OnInit {
  @Input() table: any;
  pageSize = 10;
  pageNumber = 1;
  page_details = {
    count: 0,
    next: "",
    previous: "",
  };
  searchTerm = "";
  isLoading = false;
  status = "any";
  userList = [];

  constructor(
    private modalService: NgbModal,
    private api: AccessManagementService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getDataList(this.pageSize, 0);
  }

  nextPage(page) {
    this.getDataList(this.pageSize,this.pageSize*(page-1));
  }

  addUserModal(data?) {
    const modalRef = this.modalService.open(AddUserComponent, {
      size: "xl",
      scrollable: true,
      windowClass: "modal-big-lg",
      // keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.fromParent = data ? data : null;

    modalRef.result.then((result) => {
      this.getDataList(this.pageSize, 0);
    }, (reason) => {
    });
  }

  getDataList(limit, offset) {
    this.api.getUserList(limit, offset).subscribe(
      (result: any) => {
        this.userList = result.results;
        this.page_details = {
          count: result.count,
          next: result.next,
          previous: result.previous
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  
  editUser(table) {
    this.addUserModal(table);
  }

  changeActiveStatus(event, data) {
    let status = { is_active: event.target.checked };
    this.api.setDisplayStatus(data.id,status).subscribe(
      (result:any)=>{
        this.toastr.success(`Status Changed Successfully!`,`Success!`);
      })
  }
}

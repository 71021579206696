import { Component, Input, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastrService } from "ngx-toastr";
import { FaqSeoService } from "src/app/core/services/faq-seo.service";
import { PermissionService } from "src/app/core/services/permision.service";

@Component({
  selector: "app-faq-section",
  templateUrl: "./faq-section.component.html",
  styleUrls: ["./faq-section.component.scss"],
})
export class FaqSectionComponent {
  faqForm: FormGroup;
  subFaqForm: FormGroup;
  @ViewChild('content', { static: false }) subModal: ElementRef;
  public Editor = ClassicEditor;

  selectedFaqtoDel: any;
  selectedFaqCattoDel: any;
  isDeleting: boolean = false;

  faqList = [];
  subFaqList = [];

  isCategoryDetails = false;
  categoryDetailsId: number;
  countryId: number;

  selectedFaqId: number;
  selectedSubFaqId: number;
  showFaqForm = false;
  showSubFaqForm = false;
  isFaqEdit = false;

  isGettingSubFaq = false;
  isSubmitted = false;

  isLoadingCats = false;

  closeResult = "";
  @Input() page;
  @Input() category;
  @Input() catId;

  isFaq = true;
  selectedFaq;
  selectedSubFaq;
  isSubFaqEdit = false;

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private api: FaqSeoService,
    private toastr: ToastrService,
    public permission: PermissionService
  ) { }

  ngOnInit(): void { }

  initFaqSection(id) {
    this.category = id;
    this.api.selectedFaqCategoryId = id;
    this.resetForm();
    this.getDataList();
    this.getDataAddList();
  }

  initFaqGeneralSection() {
    this.getGeneralDataList();
  }

  resetForm(data?) {
    this.faqForm = this.fb.group({
      faq_id: [data ? data.id : this.category],
      name: [data ? data.name : "", [Validators.required]],
    });

  }

  onFaqSubmit(form) {
    if (form.valid) {
      if (this.isFaqEdit) {
        form.value.faqtype = "sub-category";
        this.api.editFaqSubCategoryList(this.selectedFaq.id, form.value).subscribe(
          (result: any) => {
            this.faqList.forEach((ae, i) => {
              if (ae.id == this.selectedFaq.id) this.faqList[i] = result;
            });
            this.showSubFaqlist(this.selectedFaq.id);
            this.resetForm();
            this.showFaqForm = false;
            this.toastr.success("Sub-Category Updated Successfully!");
          })
      } else {
        form.value.faq_id = this.category;
        this.api.setFaqSubCategoryList(form.value).subscribe(
          (result: any) => {
            this.faqList.forEach((ae) => {
              ae.selected = false;
            });
            result.selected = true;
            this.faqList.push(result);
            this.resetForm();
            this.selectedFaqId = result.id ? result.id : result.faq_id;
            this.showFaqForm = false;
            this.subFaqList = [];
            this.toastr.success("Sub-Category Added Successfully!");
          });
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all the fields to continue!");
    }
  }

  showSubFaqlist(id) {
    this.selectedFaqId = id;
    this.resetForm();
    this.isGettingSubFaq = true;
    this.faqList.forEach((ae) => {
      if (ae.id == id) ae.selected = true;
      else ae.selected = false;
    });

    this.api.getAddFaqSubCategoryList(this.selectedFaqId).subscribe(
      (result) => {
        this.isGettingSubFaq = false;
        this.subFaqList = result;
        this.showSubFaqForm = false;
      },
      () => {
        this.isGettingSubFaq = false;
      }
    );
  }

  resetSubFaqForm(data?) {
    this.subFaqForm = this.fb.group({
      question: [data ? data.question : "", [Validators.required]],
      answer: [data ? data.answer : "", [Validators.required]],
    });
  }

  onSubFaqSubmit(form) {
    if (form.valid) {
      if (this.isSubFaqEdit) {
        form.value.answer_type = this.selectedSubFaq.id;
        this.api.editAddFaqSubCategoryList(this.selectedSubFaq.id, form.value).subscribe(
          () => {
            this.showSubFaqForm = false;
            this.showSubFaqlist(this.selectedFaqId);
            this.resetSubFaqForm();
          })
      } else {
        form.value.faq_id = this.selectedFaqId;
        this.api.setAddFaqSubCategoryList(form.value).subscribe(
          (result: any) => {
            this.subFaqList.forEach((ae) => {
              ae.selected = false;
            });
            result.selected = true;
            this.subFaqList.push(result.data);
            this.resetSubFaqForm();
            this.selectedSubFaqId = result.data.faq_id;
          }
        );
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all the fields to continue!");
    }
  }

  getGeneralDataList() {
    this.api.getFaqGeneralDetails().subscribe(
      (result: any) => {
        this.resetForm(result);
        this.faqList = result.datas;
        this.category = result.parent_id;
      });
  }

  getDataList() {
    this.api.getFaqSubCategoryList(this.category).subscribe(
      (result: any) => {
        this.faqList = result.datas;
      });
  }
  getDataAddList() {
    this.isLoadingCats = true;
    this.api.getAddFaqSubCategoryList(this.category).subscribe(
      (result: any) => {
        this.isLoadingCats = false;
        this.resetSubFaqForm(result);
        this.subFaqList = result;
      });
  }

  open(content) {
    this.subFaqForm = new FormGroup({
      question: new FormControl(),
      answer: new FormControl(),
    });
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  editFaq(isFaq, faq) {
    if (isFaq) {
      this.isFaqEdit = true;
      this.selectedFaq = faq;
      this.resetForm(faq);
      this.showFaqForm = true;
    } else {
      this.isSubFaqEdit = true;
      this.selectedSubFaq = faq;
      this.resetSubFaqForm(faq);
      this.showSubFaqForm = true;
      this.modalService.open(this.subModal, { ariaLabelledBy: "modal-basic-title" });
    }
  }

  selectSubFaq(id) {
    this.subFaqList.forEach((ae) => {
      if (ae.id == id) ae.selected = true;
      else ae.selected = false;
    });
  }

  deleteFaqConfirm(faq) {
    this.isDeleting = true;
    this.api.deleteFaqById(faq.id).subscribe(
      () => {
        this.isDeleting = false;
        this.showSubFaqlist(this.selectedFaqId);
        this.modalService.dismissAll({ data: 'details' });
        this.toastr.success("Deleted Successfully!")
      },
      () => {
        this.isDeleting = false;
      });
  }

  deleteFaqCategory(faq) {
    this.isDeleting = true;
    this.api.deleteFaqCategory(faq.id).subscribe(
      () => {
        this.isDeleting = false;
        this.faqList = this.faqList.filter(ae => ae.id != faq.id);
        if (this.selectedFaqId == faq.id) this.subFaqList = [];
        this.modalService.dismissAll({ data: 'details' });
        this.toastr.success("Deleted Successfully!")
      },
      () => {
        this.isDeleting = false;
      });
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AboutUsPageService } from 'src/app/core/services/about-us-page.service';
import { PermissionService } from 'src/app/core/services/permision.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CustomSortComponent } from '../../ui-elements/custom-sort/custom-sort.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';

@Component({
  selector: 'app-about-statistics',
  templateUrl: './about-statistics.component.html',
  styleUrls: ['./about-statistics.component.scss']
})
export class AboutStatisticsComponent implements OnInit {

  statForm: FormGroup;
  hasChanges = false;
  public Editor = ClassicEditor;

  isUpdating = false;
  isSubmitted = false;
  deletedstats = [];
  @Input() country;
  @Input() page;


  constructor(private fb: FormBuilder, private api: AboutUsPageService,
    private toastr: ToastrService, public permission: PermissionService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.resetForm();
    this.getCrews();
  }

  resetForm() {
    this.statForm = this.fb.group({
      stats: this.fb.array([
        this.fb.group({
          id: [''],
          heading: ['', [Validators.required]],
          sub_heading: ['', [Validators.required]],
          description: ['', [Validators.required]],
          image_url: ['', [Validators.required]],
          is_updated: [''],
        })])
    });
  }

  getCrews() {
    this.api.getCrewData().subscribe(
      (result: any) => {
        this.setData(result);
      })
  }

  setData(result) {
    this.statForm = this.fb.group({
      stats: new FormArray([]),
    });
    result.forEach(ao => {
      (this.statForm.get('stats') as FormArray).push(
        this.fb.group({
          id: [ao.id, [Validators.required]],
          heading: [ao.heading, [Validators.required]],
          sub_heading: [ao.sub_heading, [Validators.required]],
          image_url: [ao.image_url, [Validators.required]],
          // country: [this.country?this.country:0],
          description: [ao.description, [Validators.required]],
          is_updated: [true],
        })
      );
      this.deletedstats = [];
    });
  }

  stats(): FormArray {
    return this.statForm.get("stats") as FormArray
  }

  newstats(): FormGroup {
    return this.fb.group({
      heading: ['', [Validators.required]],
      sub_heading: ['', [Validators.required]],
      image_url: ['', [Validators.required]],
      // country: [this.country?this.country:0],
      description: ['', [Validators.required]],
      is_updated: [true],
    })
  }

  addstats() {
    this.stats().push(this.newstats());
  }

  removestats(i: number) {
    this.isSubmitted = false;
    this.statForm.value.stats[i].is_deleted = true;
    if (this.statForm.value.stats[i].hasOwnProperty("id")) this.deletedstats.push(this.statForm.value.stats[i]);
    this.stats().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedstats.forEach(ae => {
        ae.is_updated = false;
        form.value.stats.push(ae);
      })
      this.api.setCrewData(form.value.stats).subscribe(
        () => {
          this.toastr.success("Content Updated Successfully!");
          this.getCrews();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        })
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  dataChanged(i) {
    this.statForm.value.stats[i].is_updated = true;
  }
  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  showSortModal(data, label) {
    let data_card: any = {
      list: data,
      label: label,
      api_url: 'cms-admin/about-us/crew-priority/position'
    };
    const modalRef = this.modalService.open(CustomSortComponent,
      {
        size: 'md',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.sortData = data_card;
    modalRef.result.then((result) => {
      if (result) this.getCrews();
    });
  }

  openUploadModal(i, field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if(result.status) {
        this.statForm.get(['stats', i, field]).setValue(result.image);
        this.statForm.get(['stats', i, 'is_updated']).setValue(true);
      }
    });
}
}

import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  page = 'product';
  country = this.sharedService.selectedCmsCountryId;
  category = this.sharedService.selectedCmsCategoryId;
  product = this.sharedService.selectedCmsProductId;
  countryList = this.sharedService.cmsCountryList;
  categoryList = this.sharedService.cmsCategoryList;
  productList = this.sharedService.cmsProductList;

  constructor(private sharedService: SharedService, private router: Router, private route: ActivatedRoute) { }
  
  ngOnInit(){
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'Country Page', active: true }];
    if (this.countryList.length == 0) this.getCountriesList();
    if(this.country && this.categoryList.length==0) this.getCategories(this.country);
    if(this.category && this.productList.length==0) this.getProducts(this.category);
  }

  getCountriesList() {
    this.sharedService.getCmsCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response;
        this.sharedService.cmsCountryList = this.countryList;
      })
  }

  selectCountry(country){
    this.sharedService.selectedCmsCountryId = country;
    this.sharedService.selectedCmsCategoryId = null;
    this.sharedService.cmsCategoryList = [];
    this.category = null;
    this.categoryList = [];

    this.sharedService.selectedCmsProductId = null;
    this.sharedService.cmsProductList = [];
    this.product = null;
    this.productList = [];

    this.getCategories(country);
  }

  getCategories(country_id){
    this.sharedService.getCmsCategoryList(country_id).subscribe(
      (result:any)=>{
        this.categoryList = result;
        this.sharedService.cmsCategoryList = this.categoryList;
      })
  }

  selectCategory(category){
    this.sharedService.selectedCmsCategoryId = category;
    this.sharedService.selectedCmsProductId = null;
    this.sharedService.cmsProductList = [];
    this.product = null;
    this.productList = [];
    this.getProducts(category);
    // const currentUrl = this.router.url;
    // this.router.navigate([this.router.url]);
    // this.router.navigateByUrl('/products/country', { skipLocationChange: true }).then(() =>
    // this.router.navigateByUrl(currentUrl));
  }

  getProducts(category){
    this.sharedService.getCmsProductList(category).subscribe(
      (result:any)=>{
        this.productList = result;
        this.sharedService.cmsProductList = this.productList;
      })
  }

  selectProduct(product){
    this.sharedService.selectedCmsProductId = product;
  }
}

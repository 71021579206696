<div class="container">
    <form class="row" [formGroup]="visaproForm" (ngSubmit)="onSubmit(visaproForm)">
        <div class="col-md-8 offset-md-2 p-4 mt-2">
            <div class="border shadow">
                <div class="my-2 row p-3">
                    <label class="col-md-12 col-form-label">Description</label>
                    <div class="col-md-12" formArrayName="description_data">
                        <div *ngFor="let desc of descriptions.controls; let i=index">
                            <div [formGroupName]="i">
                                <div class="form-group my-2">
                                    <button type="button" *ngIf="descriptions.controls.length!=1"
                                        class="deletebtn btn btn-pseudo remove float-end"
                                        (click)="removeDescription(i);"><i class="fa fa-times text-danger"></i></button>
                                    <ckeditor [editor]="Editor" formControlName="description" (keyup)="hasChanges=true;"
                                        (change)="dataChanged(i)"
                                        [ngClass]="{'invalid': desc.get('description').invalid && isSubmitted}"
                                        data="<p>Content of the editor.</p>"></ckeditor>

                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-sm btn-primary mt-2 float-end" (click)="addDescription()">
                            <i class="fa fa-plus"></i> Add Description
                        </button>
                    </div>
                </div>
                <div class="my-2 row">
                    <div class="col-md-12">
                        <button *ngIf="(page=='consultant' &&checkPermission('content_management_consultant_create'))"
                            class="btn btn-success float-end me-5" [disabled]="!hasChanges"><i
                                [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                            Save Changes</button>
                        <app-publish-btn
                            *ngIf="(page=='consultant' &&checkPermission('content_management_consultant_publish'))"
                            class="float-end me-2" [publish]="publishPage" [page]="page"></app-publish-btn>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
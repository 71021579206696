import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-add-on",
  templateUrl: "./add-on.component.html",
  styleUrls: ["./add-on.component.scss"],
})
export class AddOnComponent implements OnInit {
 
  breadCrumbItems = [
    { label: "Add-On Management" },
    { label: "Insurance", active: true },
  ];
  constructor(
  ) {
  }

  ngOnInit(): void {
  }


  setBreadCrumb(title:string){
    this.breadCrumbItems[1].label=title
  }

 
}

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <div class="d-flex justify-content-between">
          <form class="row mr-auto p-2">
            <div class="col-12">
              <div class="input-group">
                <input
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="dateToShow"
                  class="form-control"
                  placeholder="yyyy-mm-dd - yyyy-mm-dd"
                />
                <button
                  class="btn btn-outline-secondary fa fa-calendar-o"
                  (click)="openDateRangeModal()"
                  type="button"
                ></button>
              </div>
            </div>
          </form>
          <div class="position-relative">
            <div class="position-absolute" style="left:-130px">
              <button class="btn btn-success" (click)="downloadCsv()">Download CSV</button>
            </div>

            <ng-select
              placeholder="Select product"
              (clear)="getData()"
              [(ngModel)]="fiterTerm"
              (change)="getTableData()"
            >
              <ng-option value="visa">Visa</ng-option>
              <ng-option value="insurance">Insurance</ng-option>
              <ng-option value="forex">Forex</ng-option>
              <ng-option value="hotel_flight"
                >Other Products (Flights + hotels)</ng-option
              >
              <ng-option value="all"
                >All ( visa+ insurance+ forex+ flight+hotel)</ng-option
              >
            </ng-select>
          </div>
        </div>

        <!-- Table -->
        <div class="table-responsive">
          <table
            id="basic-datatable"
            class="table table-centered datatables dt-responsive nowrap table-card-list"
          >
            <thead>
              <tr>
                <th class="text-center">Country</th>
                <th class="text-center">Booking Count</th>
                <th class="text-center">Passenger Count</th>
                <th class="text-center">Sales Amount</th>
                <th class="text-center">Revenue</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let table of tableData">
                <td
                  class="text-center"
                  [ngClass]="{ 'text-bold': table?.country === 'Total' }"
                >
                  <ngb-highlight>{{ table?.country }}</ngb-highlight>
                </td>
                <td
                  class="text-center"
                  [ngClass]="{ 'text-bold': table?.country === 'Total' }"
                >
                  <ngb-highlight>{{ table?.Total_Bookings }}</ngb-highlight>
                </td>
                <td
                  class="text-center"
                  [ngClass]="{ 'text-bold': table?.country === 'Total' }"
                >
                  <ngb-highlight>{{ table?.Total_Passengers }}</ngb-highlight>
                </td>
                <td
                  class="text-center"
                  [ngClass]="{ 'text-bold': table?.country === 'Total' }"
                >
                  <ngb-highlight>{{ table?.Sales_Amount }}</ngb-highlight>
                </td>
                <td
                  class="text-center"
                  [ngClass]="{ 'text-bold': table?.country === 'Total' }"
                >
                  <ngb-highlight>{{ table?.Revenue }}</ngb-highlight>
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="isLoading" class="d-flex mt-5">
            <i class="fa fa-spin fa-spinner fa-pulse fa-5x m-auto"></i>
          </div>
          <div class="text-center" *ngIf="tableData?.length == 0 && !isLoading">
            No data to show
          </div>
        </div>
        <!-- <div *ngIf="isLoading" class="d-flex mt-5">
                      <i class="fa fa-spin fa-spinner fa-pulse fa-5x m-auto"></i>
                  </div> -->
        <!-- End table -->
        <!-- <div class="row justify-content-md-between align-items-md-center mt-2">
            <div class="col-sm-12 col-md-5">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Showing
                {{ (pageNumber - 1) * pageSize + 1 }} to
                {{
                  pageNumber * pageSize < page_details.count
                    ? pageNumber * pageSize
                    : page_details.count
                }}
                of {{ page_details.count }} entries
              </div>
            </div>
         
            <div class="col-sm-12 col-md-5">
              <div class="text-md-end float-md-end pagination-rounded">
                <ngb-pagination
                  [collectionSize]="page_details.count"
                  [(page)]="pageNumber"
                  [pageSize]="pageSize"
                  (pageChange)="nextPage(pageNumber)"
                >
                </ngb-pagination>
              </div>
            </div>
           
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { AddIntegrationComponent } from './add-integration/add-integration.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from 'src/app/core/services/settings.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  isDeleting: boolean;

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, public api: SettingsService, private toastr: ToastrService) { }

  integrationsList:any = [];
  selectedIntegrationDel:any;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: "Settings" }, { label: "Integrations", active: true }];
    this.getIntegrations();
  }

  getIntegrations(){
    this.api.getIntegrationsList().subscribe(
      result => {
        this.integrationsList = result;
      }
    )
  }

  addModal(details?) {

    const modalRef = this.modalService.open(AddIntegrationComponent, {
      size: 'md',
      scrollable: true,
      windowClass: 'modal-big-lg',
      // keyboard: false,
      backdrop: 'static'
    });

    let data = { ...details };
    modalRef.componentInstance.details = data;
    modalRef.result.then((result) => {
      if(result) this.getIntegrations();
    });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  deleteConfirm(integration) {
    this.isDeleting = true;
    this.api.deleteIntegrationsList(integration.id).subscribe(
      (result: any) => {
        this.isDeleting = false;
        this.modalService.dismissAll({ data: 'details' });
        this.toastr.warning("Deleted Successfully!");
        this.getIntegrations();
      },
      error => {
        this.isDeleting = false;
      });
  }


}

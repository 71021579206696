<div class="w-100 text-end mt-3" *ngIf="statForm.value.stats.length>0">
    <a class="cpr btn btn-primary" (click)="showSortModal(statForm.value.stats, 'heading')">
        <i class="fa fa-sort" aria-hidden="true"></i> Change Position</a>
</div>
<div class="container">
    <form [formGroup]="statForm" (ngSubmit)="onSubmit(statForm)">
        <div class="row">
            <div class="col-md-4 mt-2" formArrayName="stats" *ngFor="let item of stats().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="stats().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removestats(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Image</label>
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="URL" formControlName="image_url">
                                <a [href]="statForm.value.stats[i].image_url" target="_blank">
                                    <img class="ms-1" height="40" [src]="statForm.value.stats[i].image_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'image_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                                (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('heading').invalid && isSubmitted}">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Sub-Heading</label>
                            <input type="text" class="form-control" placeholder="Sub-Heading"
                                formControlName="sub_heading" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('sub_heading').invalid && isSubmitted}">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Description</label>
                            <ckeditor [editor]="Editor" formControlName="description"
                                data="<p>Content of the editor.</p>"
                                [ngClass]="{'invalid': item.get('description').invalid && isSubmitted}"></ckeditor>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button *ngIf="(page=='about-us' && checkPermission('content_management_about_us_create'))"
                        class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addstats()"><i
                            class="uil-plus"></i> Add</button>
                    <button
                        *ngIf="(page=='about-us' && checkPermission('content_management_about_us_create'))&&(page=='about-us' && checkPermission('content_management_about_us_edit'))"
                        class="btn btn-info mt-1 me-1 col-md-12" type="submit"><i
                            [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                        Save All</button>
                    <app-publish-btn
                        *ngIf="(page=='about-us' && checkPermission('content_management_about_us_publish'))"
                        class="col-md-12 p-0 mt-1" [publish]="'AboutUsStatitics'" [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  // country description
  getDescription(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/category/category-description/?category=${id}`).pipe(retry(2));
  }

  setDescription(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/category/category-description/${id}/`, data);
  }

  postDescription(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/category/category-description/`, data);
  }

  // side info
  getSideInfo(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/category/side-infocategory/?category=${id}`).pipe(retry(2));
  }

  setSideInfo(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/category/side-infocategory/`, data);
  }

  // Eligibility
  getEligibility(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/category/category-eligibilty/?category=${id}`).pipe(retry(2));
  }

  setEligibility(id,data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/category/category-eligibilty/${id}/`, data);
  }

  postEligibility(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/category/category-eligibilty/`, data);
  }

  // How to apply 
  getApplySteps(category): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/category/category-visa-process/?category=${category}`).pipe(retry(2));
  }

  setApplySteps(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/category/category-visa-process/`, data);
  }

    // how to apply process
  getCategoryApplyProcess(category): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/category/category-application-process/?category=${category}`).pipe(retry(2));
  }

  setCategoryApplyProcess(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/category/category-application-process/${id}/`, data);
  }

  postCategoryApplyProcess(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/category/category-application-process/`, data);
  }

  // how to apply steps
  getCategoryApplySteps(category): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/category/category-application-step/?category=${category}`).pipe(retry(2));
  }

  setCategoryApplySteps(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/category/category-application-step/`, data);
  }

   // required docs
   getRequiredDocs(category): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/category/category-required-document/?category=${category}`).pipe(retry(2));
  }

  setRequiredDocs(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/category/category-required-document/`, data);
  }

  // Additional Info
  getAdditionalInfo(category): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/category/category-additional-information/?category=${category}`).pipe(retry(2));
  }

  setAdditionalInfo(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/category/category-additional-information/`, data);
  }

  // Voucher 
  getVoucher(category): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/category/voucher/?category=${category}`).pipe(retry(2));
  }

  setVoucher(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/category/voucher/`, data);
  }
}

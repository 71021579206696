import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { SharedService } from "src/app/core/services/shared.service";
import { CategoryService } from "src/app/core/services/category.service";
import { ToastrService } from "ngx-toastr";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { ProductPageService } from "src/app/core/services/product-page.service";
import { PermissionService } from "src/app/core/services/permision.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// pages: category, product
@Component({
  selector: "app-eligibility",
  templateUrl: "./eligibility.component.html",
  styleUrls: ["./eligibility.component.scss"],
})
export class EligibilityComponent implements OnInit {

  public Editor = ClassicEditor;
  eligibleForm: FormGroup;
  fileToUpload: File | null = null;
  hasChanges = false;
  isUpdating = false;
  isSubmitted = false;
  isNew = false;

  progress: number = 0;

  @Input() page;
  @Input() country;
  @Input() category;
  @Input() product;
  publishPage: string;

  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    private api: CategoryService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private productService: ProductPageService,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {
    this.resetForm();
    this.getData();
    switch (this.page) {
      case "category":
        this.publishPage = "CategoryEligibility";
        break;
      case "product":
        this.publishPage = "ProductEligibility";
        break;
      default:
        break;
    }
  }

  resetForm(data?) {
    this.eligibleForm = this.fb.group({
      id: [data ? data.id : ""],
      heading: ["-", [Validators.required]],
      button_url: ["-", [Validators.required]],
      button_label: ["-", [Validators.required]],
      // category: [this.category],
      image_url: ["-"],
      is_updated: [false],
      is_deleted: [false],
      description_data: this.fb.array([]),
    });
    if (data) {
      this.eligibleForm.value.id = data.id;
      data.description.forEach((ao) => {
        (this.eligibleForm.get("description_data") as FormArray).push(
          this.fb.group({
            text: [ao, [Validators.required]],
          })
        );
      });
    }
    this.onChanges();
  }

  onChanges(): void {
    this.eligibleForm.valueChanges.subscribe(() => {
      Object.keys(this.eligibleForm.controls).map((key) => {
        if (this.eligibleForm.get(key).dirty) {
          this.hasChanges = true;
          this.eligibleForm.value.is_updated = true;
        }
      });
    });
  }

  getData() {
    if (this.page == "category") {
      this.api.getEligibility(this.category).subscribe((result: any) => {
        this.resetForm(result[0]);
        if (result.length == 0) this.isNew = true;
        else this.isNew = false;
      });
    } else if (this.page == "product") {
      this.productService
        .getEligibility(this.product)
        .subscribe((result: any) => {
          this.resetForm(result[0]);
          if (result.length == 0) this.isNew = true;
          else this.isNew = false;
        });
    }
  }

  get descriptions(): FormArray {
    return this.eligibleForm.get("description_data") as FormArray;
  }

  newDescription(): FormGroup {
    return this.fb.group({
      text: "",
    });
  }

  addDescription() {
    this.descriptions.push(this.newDescription());
  }

  removeDescription(i: number) {
    this.isSubmitted = false;
    this.hasChanges = true;
    this.descriptions.removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      let descriptions: any = [];
      form.value.description_data.forEach((ae) => {
        descriptions.push(ae.text);
      });
      form.value.description = descriptions;
      if (this.page == "category") {
        form.value.category = this.category;
        if (this.isNew) {
          this.api.postEligibility(form.value).subscribe(
            () => {
              // this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
              this.isNew = false;
            },
            () => {
              this.isUpdating = false;
            }
          );
        } else {
          this.api.setEligibility(form.value.id, form.value).subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          );
        }
      } else if (this.page == "product") {
        form.value.product = this.product;
        if (this.isNew) {
          this.productService.postEligibility(form.value).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
              this.isNew = false;
            },
            () => {
              this.isUpdating = false;
            }
          );
        } else {
          this.productService
            .setEligibility(form.value.id, form.value)
            .subscribe(
              (result: any) => {
                this.resetForm(result);
                this.toastr.success("Content Updated Successfully!");
                this.hasChanges = false;
                this.isUpdating = false;
              },
              () => {
                this.isUpdating = false;
              }
            );
        }
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  handleIcon(files: FileList) {
    this.hasChanges = true;
    this.fileToUpload = files.item(0);
    const formData: FormData = new FormData();
    formData.append("document", this.fileToUpload);
    this.sharedService
      .uploadFile(formData)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress = Math.round((100 * event.loaded) / event.total);
            break;
          case HttpEventType.Response:
            this.eligibleForm.get("image_url").setValue(event.body.document);

            this.progress = 0;
            break;
        }
      });
    let unsafeImageUrl = URL.createObjectURL(this.fileToUpload);
    this.eligibleForm.value.image_url =
    this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  }
  
  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

<div class="container-fluid">
  <app-page-title
    title="Visa Process"
    [breadcrumbItems]="breadCrumbItems"
  ></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="d-flex align-items-center">
              <div class="col-md-2 mt-1">
                <div class="dataTables_length" id="tickets-table_length">
                  <label class="d-inline-flex align-items-center"
                    >Show
                    <select
                      name="pageSize"
                      [(ngModel)]="pageSize"
                      class="form-control form-control-sm mx-2"
                      (change)="getProcessData(pageSize, 0)"
                    >
                      <option [ngValue]="5">5</option>
                      <option [ngValue]="10">10</option>
                      .
                      <option [ngValue]="25">25</option>
                      <option [ngValue]="50">50</option>
                      <option [ngValue]="100">100</option>
                    </select>
                    entries</label
                  >
                </div>
              </div>
              <div class="d-flex">
                <div
                  *ngIf="!isHide"
                  class="me-2 pe-3 ps-3 pt-1 pb-1  cpr {{
                    isPaid ? 'pay_button_active' : ''
                  }}"
                  (click)="paidData()"
                >
                  Paid
                </div>
                <div
                  class="pe-3 ps-3 pt-1 pb-1 cpr {{
                    isPaid ? '' : 'pay_button_active'
                  }}"
                  (click)="unPaidData()"
                  *ngIf="!isHide"
                >
                  Unpaid
                </div>
                <div class="position-relative w-100 me-3" *ngIf="!isHide">
                  <select class="form-control cpr" [(ngModel)]="dropDownItem">
                    <option value="">Search By</option>
                    <option value="name">Name</option>
                    <option value="email">Email</option>
                    <option value="phone">Phone number</option>
                    <option value="stp_code">Application number</option>
                  </select>
                  <span class="arrow-down">
                    <!-- <i class="mdi mdi-chevron-down"></i> -->
                  </span>
                </div>

                <div class="position-relative w-100" *ngIf="!isHide">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Search"
                    [(ngModel)]="searchText"
                    (keyup)="resetData($event, 'search')"
                    (keyup.enter)="searchVisaData()"
                  />
                  <button
                    class="search_btn_visa"
                    (click)="searchVisaData()"
                    [disabled]="!searchText || !dropDownItem"
                  >
                    <i class="fa fa-search text-black"></i>
                  </button>
                </div>
                <button
                  class="border-0 bg-transparent ms-2"
                  (click)="resetData('', 'reset')"
                >
                  <i class="fa fa-refresh text-black"></i>
                </button>

                <div
                  class="me-2 pe-3 ps-3 pt-1 pb-1 cpr"
                  (click)="openFilterModal()"
                >
                  <button class="form-control">Filter</button>
                </div>
                <div
                  class="me-2 pe-3 ps-3 pt-1 pb-1 cpr"
                  (click)="downloadCsv()"
                >
                  <button class="form-control csv_button">Download CSV</button>
                </div>
                <div *ngIf="filteredCheck">
                  <p>{{filterMessage}} </p>
                </div>
              </div>
            </div>

            <!-- <div class="col-md-2">
                            <div class="d-flex">
                                <input class="form-control" name="name" type="search" [(ngModel)]="search.name" placeholder="Search Name">
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="d-flex">
                                <input class="form-control" name="country" type="search" [(ngModel)]="search.country" placeholder="Search Country">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="d-flex">
                                <input class="form-control" name="category" type="search" [(ngModel)]="search.category" placeholder="Search Category">
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="d-flex">
                                <input class="form-control" name="date" type="date" [(ngModel)]="search.app_date" placeholder="Application Date Interval">
                            </div>
                        </div> -->
          </div>
          <!-- Table -->
          <div class="table-responsive" *ngIf="!isLoading">
            <table
              id="basic-datatable"
              class="table table-centered datatables dt-responsive nowrap table-card-list"
            >
              <thead>
                <tr>
                  <th class="text-center">Sl No.</th>
                  <th class="text-center">
                    <span class="me-2"> Application Date </span>
                    <i class="fas fa-sort cpr" (click)="sortList()"></i>
                  </th>

                  <th class="text-center">Application No.</th>
                  <th class="text-center">Name</th>
                  <th class="text-center">Total Traveller</th>
                  <th class="text-center">Country</th>
                  <th class="text-center">Category</th>
                  <th class="text-center">Expert</th>
                  <th class="text-center">Date of Travel</th>

                  <!-- <th class="text-center">Payment</th> -->
                </tr>
              </thead>
              <tbody *ngFor="let table of processData; let i = index">
                <tr
                  [ngStyle]="{
                    'background-color':
                      table?.status === 'PENDING' ? '#FFA500' : ''
                  }"
                >
                  <td class="text-center">
                    <ngb-highlight>{{
                      (pageNumber - 1) * pageSize + (i + 1)
                    }}</ngb-highlight>
                  </td>
                  <td class="text-center">
                    <ngb-highlight>{{
                      table.created_at * 1000 | date : "medium"
                    }}</ngb-highlight>
                  </td>
                  <td class="text-center">
                    <ngb-highlight>
                      <a
                        class="text-info"
                        [routerLink]="['/visa/application', table.id]"
                        [queryParams]="{ title: 'visa-process',page: pageNumber}"
                      >
                        {{ table.stp_code }}
                      </a>
                    </ngb-highlight>
                  </td>
                  <td class="text-center">
                    <ngb-highlight>{{ table.customer_name }}</ngb-highlight>
                  </td>
                  <td class="text-center">
                    <ngb-highlight>{{ table.traveller_count }}</ngb-highlight>
                  </td>
                  <td class="text-center">
                    <ngb-highlight>{{ table.country }}</ngb-highlight>
                  </td>
                  <td class="text-center">
                    <ngb-highlight>{{ table.category }}</ngb-highlight>
                  </td>
                  <td class="text-center w-xl">
                    <i
                      *ngIf="!userList"
                      class="fa fa-spin fa-spinner fa-pulse fa-1x m-auto"
                    ></i>
                    <div *ngIf="userList">
                      <i
                        *ngIf="isExpertLoading[i]"
                        class="fa fa-spin fa-spinner fa-pulse fa-1x m-auto"
                      ></i>
                      <ng-select
                        *ngIf="!isExpertLoading[i]"
                        [(ngModel)]="expertListValue[i]"
                        (change)="changeExpert(table?.id, i)"
                      >
                        <ng-option
                          *ngFor="let user of userList"
                          [value]="user.id"
                          >{{ user?.expert_name }}</ng-option
                        >
                      </ng-select>
                    </div>
                  </td>
                  <td class="text-center">
                    <ngb-highlight>{{
                      table?.dot_from + " " + "To" + " " + table?.dot_to
                    }}</ngb-highlight>
                  </td>
                  <td
                    class="text-center"
                    (click)="table.isExpanded = !table.isExpanded"
                  >
                    <ngb-highlight>
                      <div>
                        <i *ngIf="!table.isExpanded" class="uil-plus"></i>
                        <i *ngIf="table.isExpanded" class="uil-minus"></i>
                      </div>
                    </ngb-highlight>
                  </td>
                  <!-- <td class="text-center">
                                        <ngb-highlight>{{table.payment}}</ngb-highlight>
                                    </td> -->
                </tr>
                <tr *ngIf="table.isExpanded">
                  <td colspan="9" width="100%">
                    <table
                      class="table table-centered datatables dt-responsive nowrap table-card-list"
                    >
                      <thead>
                        <tr>
                          <th>Normal/Express</th>
                          <th>DIY/VisaPro</th>
                          <th>Source</th>
                          <th *ngIf="table.status">Application Status</th>
                          <th *ngIf="table?.status?.toLowerCase() === 'done'">
                            Visa Status
                          </th>
                          <th>Total Paid</th>

                          <th>Add Ons</th>
                          <th>Status</th>
                          <th>Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          [ngStyle]="{
                            'background-color':
                              table?.status === 'PENDING' ? '#FFA500' : ''
                          }"
                        >
                          <td>
                            <ngb-highlight>{{
                              table.normal_or_express
                            }}</ngb-highlight>
                          </td>
                          <td>
                            <ngb-highlight>{{
                              table.diy_or_visapro
                            }}</ngb-highlight>
                          </td>
                          <td>
                            <ngb-highlight>{{ table.source }}</ngb-highlight>
                          </td>
                          <td *ngIf="table.status">{{ table.status }}</td>
                          <td
                            *ngIf="
                              table?.status?.toLowerCase() === 'done' &&
                              processData
                            "
                          >
                            <!-- <td  *ngIf="!table?.status">No data</td> -->
                            <select
                              class="status_dropdown"
                              (change)="changeVisaStatus(table?.id, $event)"
                              id=""
                              [(ngModel)]="table.visa_status"
                            >
                              <option
                                *ngIf="table?.status?.toLowerCase() === 'done'"
                                value="Pending"
                              >
                                Pending
                              </option>
                              <option *ngIf="!table?.status" value="Pending">
                                Pending
                              </option>
                              <option value="Accepted">Accepted</option>
                              <option value="Rejected">Rejected</option>
                            </select>
                            <!-- <ngb-highlight>{{ table.status }}</ngb-highlight> -->
                          </td>

                          <td>
                            <ngb-highlight>{{
                              table.total_payed
                            }}</ngb-highlight>
                          </td>

                          <td>
                            <div>Insurance</div>
                            <div>Hotel</div>
                            <div>Flight</div>
                            <div>Forex</div>
                          </td>
                          <td>
                            <div>
                              {{ table.is_insurance ? "Yes" : "No" }}
                            </div>
                            <div>
                              {{ table.is_hotel ? "Yes" : "No" }}
                            </div>
                            <div>
                              {{ table.is_flight ? "Yes" : "No" }}
                            </div>
                            <div>
                              {{ table.is_forex ? "Yes" : "No" }}
                            </div>
                          </td>
                          <td>
                            <input
                              [(ngModel)]="table.remarks"
                              appOutsideClick
                              maxlength="50"
                              type="text"
                              class="form-control"
                              [index]="i"
                              [table]="table"
                              (dataChanged)="onChangeData($event)"
                            />
                            <!-- <ngb-highlight>{{ table.remarks }}</ngb-highlight> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="isLoading" class="d-flex mt-5">
            <i class="fa fa-spin fa-spinner fa-pulse fa-5x m-auto"></i>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-md-between align-items-md-center mt-2"
          >
            <div class="col-sm-12 col-md-5">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Showing
                {{ (pageNumber - 1) * pageSize + 1 }} to
                {{
                  pageNumber * pageSize < page_details.count
                    ? pageNumber * pageSize
                    : page_details.count
                }}
                of {{ page_details.count }} entries
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-5" *ngIf="page_details?.count">
              <div class="text-md-end float-md-end pagination-rounded">
                <ngb-pagination
                  aria-label="Custom pagination"
                  [collectionSize]="page_details.count"
                  [(page)]="pageNumber"
                  [pageSize]="pageSize"
                  (pageChange)="nextPage($event)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- container-fluid -->

<div class="modal-header">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)">
    </button>
</div>
<div class="modal-body mh-500">
    <!-- <pre>{{coverData|json}}</pre> -->
    <div class="row">
        <div class="offset-md-4 col-md-4">
            <label>Cover Letter</label>
            <ng-select (change)="onChange(selectedCover)" [(items)]="coverDetails.response" [multiple]="false" [placeholder]="'Select Cover Letter'" bindLabel="template_name"
                bindValue="id" [(ngModel)]="selectedCover" [closeOnSelect]="true" [clearable]="false"></ng-select>
        </div>
        <div class="row" *ngIf="coverData">
            <div class="col-md-12 row">
                <div class="card offset-md-3 col-md-6 row mt-4 p-3">
                    <div class="col-6"> From: <b>{{coverData?.from}}</b></div>
                    <div class="col-6"> To: <b>{{coverData?.to}}</b></div>
                    <div class="col-6"> Subject: <b>{{coverData?.subject}}</b></div>
                    <div class="col-12"> Body: <b>{{coverData?.body}}</b></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer text-end">
    <button class="btn btn-primary me-2" *ngIf="coverData" (click)="generateAndDownloadTxtFile()">Generate and Download TXT</button>
    <button class="btn btn-secondary" (click)="closeModal(false)"><i class="fa fa-times"></i> Close</button>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'phone',
  template: `
      <div [formGroup]="form">
        <!-- <ng-select [multiple]="false" [placeholder]="field.placeholder" [formControlName]="field.name"  class="min_w_220">
            <ng-option *ngFor="let opt of field.options" [value]="opt"
                [disabled]="false">
                {{opt?.answer_choice}}
            </ng-option>
            <input [type]="type" [attr.field_type]="field.field_type" class="form-control rounded-0"  [id]="field.name" [name]="field.name" [formControlName]="field.name" [placeholder]="field.placeholder">
        </ng-select> -->
      </div> 

      <style>
        ::ng-deep .ng-select .ng-select-container {
            border-radius: 0px !important;
            background: #f5f5f5 !important;
        }

        ::ng-deep .ng-dropdown-panel {
            overflow: hidden;
            border-radius: 0px !important;
        }

        ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      </style>
    `
})
export class PhoneComponent implements OnInit {
  @Input() field: any = {};
  @Input() type: any;
  @Input() form!: FormGroup;

  currentCountry: any;

  constructor() {

  }

  ngOnInit(): void {
    // this.form.patchValue(this.field.answer)
    if (this.field?.answer)
      this.form.controls[this.field?.name].setValue(this.field?.answer);

    if (this.field.disabled) {
      this.form.controls[this.field?.name].disable();
    } else {
      this.form.controls[this.field?.name].enable();
    }
  }
}
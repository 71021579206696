import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent {

  @Input() details;
  isDeleting = false;

  constructor(public activeModal: NgbActiveModal, public api: WorkflowService, public toastr: ToastrService) { }

  closeModal(is_deleted) {
    this.activeModal.close({ deleted: is_deleted });
  }

  confirmDelete(id){
    this.isDeleting = true;
    this.api.removeWorkflowNotifications(id).subscribe(
      result=>{
        this.isDeleting = false;
        this.toastr.warning("Removed Successfully!");
        this.closeModal(true);
      },
      error=>{
        this.isDeleting = false;
      }
    )
  }

}

import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HomeManagementService {

  constructor(private http: HttpClient) { }

  // home description
  getHomeDescription(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/home-description/`).pipe(retry(2));
  }

  setHomeDescription(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/home/home-description/${id}/`, data);
  }

  postHomeDescription(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/home-description/`, data);
  }

  // home usp features
  getUspFeatures(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/usp-features/`).pipe(retry(2));
  }

  setUspFeatures(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/usp-features/`, data);
  }


  // home offers
  getHomeOffers(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/home-offers/`).pipe(retry(2));
  }

  setHomeOffers(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/home-offers/`, data);
  }

  // product detail
  getProductDetail(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/country/product-detail/`).pipe(retry(2));
  }

  setProductDetail(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/country/product-detail/`, data);
  }

  // how to apply process
  getHomeApplyProcess(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/homepage-application-process/`).pipe(retry(2));
  }

  setHomeApplyProcess(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/home/homepage-application-process/${id}/`, data);
  }

  postHomeApplyProcess(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/homepage-application-process/`, data);
  }

  // how to apply steps
  getHomeApplySteps(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/homepage-application-step/`).pipe(retry(2));
  }

  setHomeApplySteps(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/homepage-application-step/`, data);
  }

  // visa-category
  getVisaCategory(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/visa-category/`).pipe(retry(2));
  }

  setVisaCategory(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/visa-category/`, data);
  }

  // holidays
  getHolidays(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/holiday/`).pipe(retry(2));
  }

  setHolidays(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/holiday/`, data);
  }

  // Partners
  getTrustedPartners(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/trusted-partner/`).pipe(retry(2));
  }

  setPartners(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/trusted-partner/`, data);
  }

  // Testimonials
  getTestimonials(country): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/testimonial/testimonial/?country=${country}`).pipe(retry(2));
  }

  setTestimonials(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/testimonial/testimonial/`, data);
  }

  // UsefulMedia
  getUsefulMedia(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/useful-media/`).pipe(retry(2));
  }

  setUsefulMedia(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/useful-media/`, data);
  }

  // How to apply
  getApplySteps(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/visa-process/`).pipe(retry(2));
  }

  setApplySteps(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/visa-process/`, data);
  }

  getApplyStepsV2(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/home/home-application-step/`).pipe(retry(2));
  }

  setApplyStepsV2(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/home/home-application-step/`, data);
  }

  changePositionPopular(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/home/product-details-priority-update/position/`, data);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'dropdown',
  template: `
      <div [formGroup]="form" *ngIf="field?.options" class="position-relative">
        <div class="disable_handler" *ngIf="field?.disabled"></div>
      <!-- formControlName -->
      <!-- formArrayName -->
        <ng-select [placeholder]="field?.placeholder" [formControlName]="field?.name"  class="min_w_220" (change)="triggerForOptionalQue($event,field?.name)"
        *ngIf="!['language', 'multiple selections', 'country_ms'].includes((field?.field_type)?.toLowerCase())">
            <ng-option *ngFor="let opt of field?.options" [value]="opt.answer_choice">
                {{opt?.answer_choice}}
            </ng-option>
        </ng-select>
  
        <ng-select [multiple]="true" [placeholder]="field?.placeholder" [formControlName]="field?.name"  class="min_w_220" (change)="triggerForOptionalQue($event,field?.name)"
        *ngIf="['language', 'multiple selections', 'country_ms'].includes((field?.field_type)?.toLowerCase())">
            <ng-option *ngFor="let opt of field?.options" [value]="opt.answer_choice">
                {{opt?.answer_choice}}
            </ng-option>
        </ng-select>

        <!-- <ng-select [multiple]="field?.field_type == 'Country_MS'|| field?.field_type =='multiple selections'" [placeholder]="field?.placeholder" [formControlName]="field?.name"  class="min_w_220" (change)="triggerForOptionalQue($event,field?.name)">
            <ng-option *ngFor="let opt of field?.options" [value]="opt.answer_choice">
                {{opt?.answer_choice}}
            </ng-option>
        </ng-select> -->
      </div> 

      <style>
        ::ng-deep .ng-select .ng-select-container {
            border-radius: 0px !important;
            background: #f5f5f5 !important;
        }

        ::ng-deep .ng-dropdown-panel {
            overflow: hidden;
            border-radius: 0px !important;
        }

        ::ng-deep .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }

        

      </style>
    `
})

export class DropDownComponent implements OnInit {
  @Input() field: any = {};
  @Input() form!: FormGroup;


  constructor() { }

  ngOnInit(): void {

    // if (this.field?.answer) {
    //   this.form.controls[this.field?.name].setValue(this.field?.answer);
    // }
    // if (this.field.disabled) {
    //   this.form.controls[this.field?.name].disable();
    // } else {
    //   this.form.controls[this.field?.name].enable();
    // }
  }

  ngAfterViewInit(): void {
    if (this.field?.answer) this.valueInitailization();
    this.optionalQueinitisalizer()
  }

  valueInitailization() {
    setTimeout(() => {
      this.form.controls[this.field?.name].setValue(this.field?.answer);
    }, 300);
  }

  optionalQueinitisalizer() {
    for (let opt of this.field.options) {
      if (opt.answer_choice == this.field?.answer) {
        setTimeout(() => {
          this.dropdownOptionChanged(opt);
        }, 300);
        break;
      }
    }
  }

  dropdownOptionChanged(curData: any) {
    var event = new CustomEvent('triggerNewQue', { detail: curData });
    document.dispatchEvent(event);
  }


  triggerForOptionalQue(value: any, name: any) {

    if (value && typeof value != 'object') {
      let trimValue = value.trim();
      if (this.field?.options) {
        for (let opt of this.field?.options) {
          if (opt?.answer_choice === trimValue) {
            let curData = opt;
            let event = new CustomEvent('triggerNewQue', { detail: curData });
            document.dispatchEvent(event);
            break;
          }
        }
      }
    }


  }
}
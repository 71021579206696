<div class="modal-header">
    <h5 class="modal-title w-100 text-center">Enter Details</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)">
    </button>
</div>
<div class="modal-body">
    <div class="row" >
        <!-- *ngIf="dataDetails.role=='agent'" -->
    <div class="mb-1" *ngIf="dataDetails.role=='agent'">
            <label class="form-label">Select Date Range</label>
            <input class="form-control flatpickr-input" [(ngModel)]="dateRange" type="text" mwlFlatpickr
                [altInput]="true" [convertModelValue]="true" mode="range">
        </div>
    </div>
    <div class="row" *ngIf="dataDetails.role=='user'">
        <div class="mb-1">
            <!-- <label class="form-label">Date Range55</label>
            <input disabled class="form-control flatpickr-input" [(ngModel)]="dateRange" type="text" mwlFlatpickr
                [altInput]="true" [convertModelValue]="true" mode="range"> -->
                <label class="form-label">Select Date Range</label>
                <input class="form-control flatpickr-input" [(ngModel)]="dateRange" type="text" mwlFlatpickr
                    [altInput]="true" [convertModelValue]="true" mode="range">
        </div>
    </div>
    <div class="row" *ngIf="dataDetails.role=='user-update'">
        <div class="mb-1">
            <label class="form-label">Date Range</label>
            <input disabled class="form-control flatpickr-input" [(ngModel)]="dateRange" type="text" mwlFlatpickr
                [altInput]="true" [convertModelValue]="true" mode="range">

        </div>
    </div>
</div>
<div class="modal-footer text-end" *ngIf="dataDetails.role=='agent'">
    <!-- *ngIf="dataDetails.role=='agent'" -->
    <button class="btn btn-success me-2" (click)="onConfirm()"><i class="fa"
            [ngClass]="{'fa-spin fa-spinner fa-pulse': isConfirming, 'fa-check': !isConfirming}"></i> Submit</button>
    <button class="btn btn-secondary" (click)="closeModal(false)"><i class="fa fa-times"></i> Cancel</button>
</div>
<div class="modal-footer text-end" *ngIf="dataDetails.role=='user'">
    <button class="btn btn-success me-2" (click)="onConfirm()"><i class="fa"
            [ngClass]="{'fa-spin fa-spinner fa-pulse': isConfirming, 'fa-check': !isConfirming}"></i> Submit</button>
    <button class="btn btn-secondary" (click)="closeModal(false)"><i class="fa fa-times"></i> Cancel</button>
</div>
<div class="modal-footer text-end" *ngIf="dataDetails.role=='user-update'">
    <button class="btn btn-success me-2" *ngIf="status=='PENDING'" (click)="onConfirmData('ACCEPTED')"><i class="fa"
            [ngClass]="{'fa-spin fa-spinner fa-pulse': isConfirming, 'fa-check': !isConfirming}"></i> Accept</button>
    <button class="btn btn-danger" *ngIf="status=='PENDING'" (click)="onConfirmData('REJECTED')"><i
            class="fa fa-times"></i> Reject</button>
    <button disabled class="btn btn-success me-2" *ngIf="status=='ACCEPTED'"><i class="fa fa-check"></i>
        Accepted</button>
    <button disabled class="btn btn-danger me-2" *ngIf="status=='REJECTED'"><i class="fa fa-times"></i>
        Rejected</button>
</div>
<div class="container-fluid">
  <!-- start page title -->
  <app-page-title title="News Page" [breadcrumbItems]="breadCrumbItems"></app-page-title>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <app-preview-btn [page]="page"></app-preview-btn>
          <ul ngbNav #justifiednavpills="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
            <li [ngbNavItem]="1">
              <a ngbNavLink>
                <span>Header</span>
              </a>
              <ng-template ngbNavContent>
                <app-media-header [page]="specificPage"></app-media-header>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>
                <span>Media&nbsp;Description</span>
              </a>
              <ng-template ngbNavContent>
                <app-media-description [page]="page"></app-media-description>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>
                <span>SEO</span>
              </a>
              <ng-template ngbNavContent>
                <app-media-seo [page]="'news'"></app-media-seo>
              </ng-template>
            </li>
           
          </ul>
          <div [ngbNavOutlet]="justifiednavpills"></div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ProductService } from 'src/app/core/services/product.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-relevant-products',
  templateUrl: './relevant-products.component.html',
  styleUrls: ['./relevant-products.component.scss']
})
export class RelevantProductsComponent implements OnInit {

  @ViewChild('id', { static: false }) subModal: ElementRef;
  workflowList: any;
  workflowId: number;
  countryFormList: any;
  countryFormId: number;

  selectedProductId: number;

  relevantProductList = [];
  relevantProductListCopy = [];
  countryId: number;
  selectedProductList = [];
  isLoading = false;
  @Input() isAdd;
  @Input() fromAdd;
  suggestionList = [];
  suggestionType = 'name';
  selectAll = false;
  termDetailsWord = '';
  showSuggestion = false;

  constructor(private sharedService: SharedService, private api: ProductService,
    public activeModal: NgbActiveModal, private modalService: NgbModal, private fb: FormBuilder, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  initProducts() {
    this.countryId = this.sharedService.selectedCountryId;
    if (this.isAdd) this.getProductData(this.countryId);
    else this.getProductDataGeneral(this.countryId)

    if (!this.isAdd) this.getProductDataGeneral(this.countryId);
    this.getProductData(this.countryId);
  }

  getProductData(id) {
    this.relevantProductList = [];
    this.api.getPossibleProducts(id).subscribe(
      (result: any) => {
        if (this.isAdd) {
          result.forEach(ae => {
            ae.checked = false;
          });
          this.relevantProductList = result; this.relevantProductListCopy = [...result]
        }
        else this.suggestionList = result;

        if (this.relevantProductList.length == 0) this.toastr.warning('No Product Suggestions', 'Oops');
      })
  }

  getProductDataGeneral(id) {
    this.relevantProductList = [];
    this.api.getProductListByCountry(id).subscribe(
      (result: any) => {
        this.relevantProductList = result;
      })
  }


  addRemoveProduct(checked, data) {
    if (this.isAdd) {
      if (checked) {
        data.country = this.countryId;
        this.selectedProductList.push(data);
      } else {
        this.selectedProductList = this.selectedProductList.filter(ae => ae.name != data.name);
      }
    } else {
      data.is_active = !data.is_active;
      this.api.changeProductStatus(data.id, data).subscribe(
        (result: any) => {
          this.toastr.success('Status Updated Successfully!!', 'Success!');
        })
    }
  }

  selectAllProducts(event) {
    this.selectedProductList = [];
    this.relevantProductListCopy.forEach(ae => ae.create_product = false);
    this.relevantProductList.forEach(ae => {
      ae.country = this.countryId;
      ae.create_product = event.target.checked;
      if (event.target.checked) this.selectedProductList.push(ae);
    });
  }

  getSelectedProducts() {
    if (this.selectedProductList.length > 0 || (this.isAdd && this.termDetailsWord != '')) {
      this.isLoading = true;
      this.api.setProductList(this.selectedProductList).subscribe(
        (result: any) => {
          this.isLoading = false;
          this.toastr.success('Product Created Successfully!', 'Success!');
          if (this.isAdd) this.activeModal.close('close');
          else this.initProducts();

        },
        error => {
          this.isLoading = false;
        }
      )
    } else {
      this.toastr.warning('You must select atleast 1 Product to create a Country');
    }
  }

  selectProductsFromSuggestion(products) {
    this.isLoading = true;
    this.api.setProductList(products).subscribe(
      (result: any) => {
        this.isLoading = false;
        this.toastr.success(`Product${products.length > 1 ? 's' : ''} Added Successfully!`, 'Success!');
        if (this.isAdd) this.activeModal.close('close');
        else this.initProducts();

      },
      error => {
        this.isLoading = false;
      }
    )
  }

  // addProduct(i) {
  // let selectedProduct = this.suggestionList.filter(ae => ae.name == this.newProduct);
  // this.selectedProductList = selectedProduct;
  // this.selectedProductList.forEach(ae => { ae.country = this.countryId; });
  // this.getSelectedProducts();
  // }

  // showProductDetails() {
  //   return this.suggestionList.filter(ae => ae.name == this.newProduct)[0];
  // }

  searchBy(type, word) {
    this.selectedProductList = [];
    this.relevantProductListCopy.forEach(ae => {
      if (ae.create_product) this.selectedProductList.push(ae);
    });
    this.selectAll = false;
    if (type == 'jurisdiction') this.relevantProductList = this.relevantProductListCopy.filter(obj => obj.jurisdiction_code.toLowerCase().includes(word.toLowerCase()));
    else if (type == 'age_group') this.relevantProductList = this.relevantProductListCopy.filter(obj => obj.age_group_code.toLowerCase().includes(word.toLowerCase()));
    else if (type == 'category') this.relevantProductList = this.relevantProductListCopy.filter(obj => obj.category_code.toLowerCase().includes(word.toLowerCase()));
    else if (type == 'sub_category') this.relevantProductList = this.relevantProductListCopy.filter(obj => obj.sub_category_code.toLowerCase().includes(word.toLowerCase()));
    else if (type == 'name') this.relevantProductList = this.relevantProductListCopy.filter(obj => obj.name.toLowerCase().includes(word.toLowerCase()));
  }

  openCountry(countryForm) {
    this.modalService.open(countryForm, { ariaLabelledBy: "modal-basic-countryForm" });
  }
  openWorkflow(workFlow) {
    this.modalService.open(workFlow, { ariaLabelledBy: "modal-basic-workFlow" });
  }

  onWorkflowSubmit() {
    this.api.saveWorkflowProduct({
      productid: this.selectedProductId,
      workflowid: this.workflowId
    }).subscribe(
      (result: any) => {
        this.toastr.success(`Workflow Added Successfully!`);
        this.initProducts();
      },
      error => {

      }
    )
  }
  editWorkflow(id) {
    this.selectedProductId = id;
    this.api.setWorkflowProduct({ productid: id }).subscribe(
      (result: any) => {
        this.workflowList = result;
      },
      error => {

      }
    )
  }

  onCountryFormSubmit(id) {
    this.api.saveCountryFormProduct(this.selectedProductId, {country_form: this.countryFormId}).subscribe(
      (result: any) => {
        this.toastr.success(`Country Form Added Successfully!`);
        this.initProducts();
      },
      error => {

      }
    )
  }

  editCountryForm(id) {
    this.selectedProductId = id;
    this.api.getCountryFormProduct(this.selectedProductId).subscribe(
      (result: any) => {
        this.countryFormList = result;
      })
  }

}

<div class="container">
    <form [formGroup]="visaCatForm" (ngSubmit)="onSubmit(visaCatForm)">
        <div class="row">
            <div class="col-md-6 mt-2" formArrayName="category" *ngFor="let item of category().controls; let i = index;"
                cdkDrag>
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="category().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removecategory(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Name</label>
                            <input type="text" readonly class="form-control" placeholder="Name" formControlName="name"
                                (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('name').invalid && isSubmitted}">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Description</label>
                            <ckeditor [editor]="Editor" formControlName="description" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('description').invalid && isSubmitted}"
                                data="<p>Content of the editor.</p>"></ckeditor>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button
                        *ngIf="(page=='home' && checkPermission('content_management_home_create')) || (page=='consultant' && checkPermission('content_management_consultant_create'))"
                        class="btn btn-primary mt-1 col-md-12" type="button" (click)="addcategory()"><i
                            class="uil-plus"></i> Add</button>
                    <button
                        *ngIf="(page=='home' && checkPermission('content_management_home_create')) || (page=='consultant' && checkPermission('content_management_consultant_create')) && 
                    (page=='home' && checkPermission('content_management_home_edit')) || (page=='consultant' && checkPermission('content_management_consultant_edit'))"
                        class="btn btn-info mt-1 col-md-12" type="submit"><i class="bx bx-save"></i> Save All</button>
                    <app-publish-btn *ngIf="(page=='home' && checkPermission('content_management_home_publish')) || 
                    (page=='consultant' && checkPermission('content_management_consultant_publish'))"
                        class="col-md-12 p-0 mt-1" [publish]="'VisaCategory'" [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
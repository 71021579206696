import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AccessManagementService } from "src/app/core/services/access-management.service";

@Component({
  selector: "app-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.scss"],
})
export class UserDetailsComponent implements OnInit {
  @Output("showNextStep") showNextStep: EventEmitter<any> = new EventEmitter();

  @Input() fromParent: any;
  userBasicForm: FormGroup;
  password: FormControl;
  // userList: any = [];
  isUserFormSubmitted: boolean = false;
  isEdit = false;
  selectedUserId: number;
  passwordFieldType = 'password'; // set the initial input type to "password"
  @Input() tab;
  constructor(
    private fb: FormBuilder,
    private api: AccessManagementService,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef
  ) { }
  ngOnInit(): void {
    this.basicFormDetails(this.fromParent);
    // this.getDataList();
  }

  basicFormDetails(data?) {
    
     
      this.userBasicForm = this.fb.group(
        {
          firstname: [data ? data.firstname : "", [Validators.required]],
          lastname: [data ? data.lastname : "", [Validators.required]],
          calendly: [data?.calendly ? data.calendly : ""],
          email: [data ? data.email : "", [Validators.required, Validators.email]],
          phone_no: [data ? data.phone_no : "", [Validators.required]],
          username: [data ? data.username : "", [Validators.required]],
          password: [""],
          confirmPassword: new FormControl("", [
            Validators.required
          ])
        }, {
        validator: this.passwordMatchValidator
      });
      if (!data) {
        this.userBasicForm.controls['password'].setValidators([Validators.required]);
      }
   
    
  }


  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('password');
    const confirmPassword = formGroup.get('confirmPassword');

    if (password?.value !== confirmPassword?.value) {
      confirmPassword.setErrors({ passwordMismatch: true });
    } else {
      confirmPassword.setErrors(null);
    }
  }

  togglePasswordVisibility() {
    this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  onSubmit(form) {
    this.isUserFormSubmitted = true;
    if (this.userBasicForm.valid) {
      if (this.fromParent) {
        const data={
          firstname:this.userBasicForm.value.firstname,
          lastname:this.userBasicForm.value.lastname,
          email:this.userBasicForm.value.email,
          phone_no:this.userBasicForm.value.phone_no,
          username:this.userBasicForm.value.username,
          password:this.userBasicForm?.value.password?this.userBasicForm?.value.password:undefined,
          calendly:this.userBasicForm.value.calendly
        }
        
        this.selectedUserId = this.fromParent.id;
        this.api.editUserList(this.selectedUserId, data).subscribe(
          (result: any) => {
            // this.userList = result;
            this.showNextStep.emit({
              tab: "CountryAssign",
              // data: this.userList,
            });
          },
          (error) => {
            this.isUserFormSubmitted = true;
            console.log(error);
          }
        );
      } else {
        this.api.setUserList(form.value).subscribe(
          (result: any) => {
            // this.userList = result;
            this.showNextStep.emit({
              tab: "CountryAssign",
              data: result,
            });
          },
          (error) => {
            this.isUserFormSubmitted = true;
            console.log(error);
          }
        );
      }
    } else {
      this.toastr.warning("Fill all the fields to continue!");
    }
  }

  // getDataList() {
  //   this.api.getUserList(10, 0).subscribe(
  //     (result: any) => {
  //       this.userList = result;
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  // }
}

<div class="container">
    <form [formGroup]="aboutForm" (ngSubmit)="onSubmit(aboutForm)">
        <div class="row">
            <div class="col-md-4 mt-2" formArrayName="infos" *ngFor="let item of infos().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="infos().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removeinfos(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Icon</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL" formControlName="icon_url">
                                <a [href]="aboutForm.value.infos[i].icon_url" target="_blank">
                                    <img class="ms-1" height="40" [src]="aboutForm.value.infos[i].icon_url">
                                </a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'icon_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                                (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('heading').invalid && isSubmitted}">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Description</label>
                            <textarea cols="30" rows="5" class="form-control" placeholder="Description"
                                formControlName="description" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('description').invalid && isSubmitted}"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button *ngIf="(page=='about-us' && checkPermission('content_management_about_us_create'))"
                        class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addinfos()"><i
                            class="uil-plus"></i> Add</button>
                    <button
                        *ngIf="(page=='about-us' && checkPermission('content_management_about_us_create'))&&(page=='about-us' && checkPermission('content_management_about_us_edit'))"
                        class="btn btn-info mt-1 me-1 col-md-12" type="submit"><i
                            [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                        Save All</button>
                    <app-publish-btn
                        *ngIf="(page=='about-us' && checkPermission('content_management_about_us_publish'))"
                        class="col-md-12 p-0 mt-1" [publish]="'AboutusInfo'" [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
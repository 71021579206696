import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { GeneralService } from "src/app/core/services/general.service";
import { PermissionService } from "src/app/core/services/permision.service";
import { SharedService } from "src/app/core/services/shared.service";

@Component({
  selector: "app-footer-list",
  templateUrl: "./footer-list.component.html",
  styleUrls: ["./footer-list.component.scss"],
})
export class FooterListComponent implements OnInit, OnChanges {
  hasChanges = false;
  isUpdating = false;
  isSubmitted = false;
  @Input() page: any;
  @Input() title: any;
  @Input() data: any;
  publishPage;

  constructor(
    private toastr: ToastrService,
    private api: GeneralService,
    public sharedService: SharedService,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log("Data For Tourist / Business", this.data);
  }

  drop(event: CdkDragDrop<[]>) {
    moveItemInArray(this.data, event.previousIndex, event.currentIndex);
  }

  save() {
    let priority = [];
    let passData:any;

    this.data.forEach((element, i) => {
      priority.push({ priority: i, id: element.id });
    });

    passData = { 'priority': priority };
    
    this.api.editFooterPosition(passData).subscribe((result: any) => {
      this.toastr.success("Footer Position Changed Successfully!");
    }),
      (err: any) => {
        console.log(err);
      };
  }
  checkPermission(type){
    return this.permission.hasPermission(type);
  }
}

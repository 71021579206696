import { Component, OnInit, ViewChild } from "@angular/core";
import { ProductService } from "src/app/core/services/product.service";
import { SharedService } from "src/app/core/services/shared.service";
import { CategoryDetailsComponent } from "../country/add-country/category-details/category-details.component";

@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.scss"],
})
export class CategoryComponent implements OnInit {
  countryList = this.sharedService.addedCountryList;
  categoryList = [];
  statesList = [];
  statesListCopy = [];
  selectedCountry;
  title: string = "";
  breadCrumbItems: Array<{}>;
  country = this.sharedService.selectedCountryId;
  selected = false;
  isLoading = false;

  @ViewChild(CategoryDetailsComponent)
  categoryDetailsComponent: CategoryDetailsComponent;

  constructor(
    private api: ProductService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.ChangeTitle();
    this.breadCrumbItems = [
      { label: "Product Management" },
      { label: "Categories", active: true },
    ];
    if (this.countryList.length == 0) this.getCountriesList();
    if (this.country) this.getCategories(this.country);
  }

  ChangeTitle() {
    this.sharedService.showCatSubCatTitle$.subscribe((res) => {
      if (res) {
        this.title = "Subcategory";
      } else {
        this.title = "Category";
      }
    });
  }

  getCountriesList() {
    this.api.getCountryDetails().subscribe((response: any) => {
      this.countryList = response;
      this.sharedService.addedCountryList = this.countryList;
    });
  }

  getCategories(country) {
    this.selectedCountry = country;
    this.selected = true;
    this.isLoading = true;
    this.categoryList = [];

    this.api.getCategoriesByCountry(country).subscribe((result: any) => {
      this.isLoading = false;
      this.categoryList = result;
      this.sharedService.countryAllData.is_categories = true;
      this.sharedService.countryAllData.categories = this.categoryList;
      this.sharedService.selectedCountryId = this.selectedCountry;
      this.categoryDetailsComponent.initCategories();
    });
  }
}

import { Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { addOnForm } from "src/app/core/models/add-on.model";
import { ProductService } from "src/app/core/services/product.service";
import { SharedService } from "src/app/core/services/shared.service";

@Component({
  selector: "app-add-on-insurance",
  templateUrl: "./add-on-insurance.component.html",
  styleUrls: ["./add-on-insurance.component.scss"],
})
export class AddOnInsuranceComponent {
  constructor(
    private sharedService: SharedService,
    private api: ProductService,
    private fb: FormBuilder,
    private tosatr: ToastrService
  ) {}
  countryList = this.sharedService.addedCountryList;
  country = this.sharedService.selectedCountryId;
  addOnForm: FormGroup;
  isUpdated: boolean = false;
  typeId: any;
  selected = false;
  ngOnInit(): void {
    this.formInitialization();
    if (this.countryList.length == 0) this.getCountriesList();
  }
  getAddOnForm() {
    this.api.getAddOnData(this.country).subscribe((res) => {
      this.setFormData(res);
      this.isUpdated = res[0]?.id ? true : false;
      this.typeId = res[0]?.id;
    });
  }

  setFormData(data: any) {
    if (data[0]?.key_values?.length) {
      //this.addOnForm.controls['insurance'].setValue()
      data[0].key_values.forEach((res: any, index: number) => {
        let key: any = Object.keys(res);
        let values: any = Object.values(res);
        key.forEach((res: any, index: number) => {
          this.addOnForm
            .get(key[index]?.toLocaleLowerCase())
            .setValue(values[index]);
        });
      });
    } else {
      this.addOnForm.reset();
    }
  }

  formInitialization() {
    this.addOnForm = this.fb.nonNullable.group<addOnForm>({
      insurance: new FormControl(null),
      insurance_71_80: new FormControl(null)
      // hotel: new FormControl(null),
      // flight: new FormControl(null),
      // forex: new FormControl(null),
    });
  }

  postAddOnData() {
    if (!this.addOnForm.value.insurance) {
      this.tosatr.error("Insurance is required");
      return;
    }
    const data = {
      country: this.country,
      key_values: [
        {
          Insurance: this.addOnForm.get("insurance").value,
          Insurance_71_80:this.addOnForm.get("insurance_71_80").value
          // Hotel: this.addOnForm.get("hotel").value,
          // Flight: this.addOnForm.get("flight").value,
          // Forex: this.addOnForm.get("forex").value,
        },
      ],
    };
    if (this.isUpdated) {
      this.api.editAddOnData(this.typeId, data).subscribe((res) => {
        this.tosatr.success("Success", "Price edited successfully");
      });
    }

    if (!this.isUpdated) {
      this.api.postAddOnData(data).subscribe((res) => {
        this.tosatr.success("Success", "Price added successfully");
        this.getAddOnForm();
      });
    }
  }

  getCountriesList() {
    this.api.getCountryDetails().subscribe((response: any) => {
      this.countryList = response;
      this.sharedService.addedCountryList = this.countryList;
      this.selected = true;
    });
  }
}

<div class="accordion-que">
    <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" [activeIds]="questionsList.length==1 ? 'static-0': ''">
        <ngb-panel *ngFor="let item of questionsList; let i=index;" id="static-{{i}}">
            <ng-template ngbPanelTitle>
                <h5 class="w-100 verify-div">{{item.section_name}}
                    <div class="form-check float-end me-3" *ngIf="isMaster">
                        <input type="checkbox" id="formCheck{{i}}" [checked]="checked[i]" [(ngModel)]="checked[i]"
                            class="form-check-input" [disabled]="checkRequired(i)">
                        <label for="formCheck{{i}}" class="form-check-label"> Verified </label>
                    </div>
                    <span *ngIf="fromVisaFlow && !isMaster && checkRaised(i)"
                        class="float-end badge rounded-pill bg-soft-warning me-3">Customer Raised</span>
                </h5>
            </ng-template>
            <ng-template ngbPanelContent>
                <app-questions [isMaster]="isMaster" [formInputs]="item.form_inputs" [fromVisaFlow]="fromVisaFlow"
                    *ngIf="!isMasterQuestion"></app-questions>
                <dynamic-form-builder [fields]="item" (onSubmit)="onFormSubmit($event)"
                    *ngIf="isMasterQuestion"></dynamic-form-builder>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FlowNotificationsComponent } from '../flow-notifications/flow-notifications.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-flow-view',
  templateUrl: './flow-view.component.html',
  styleUrls: ['./flow-view.component.scss']
})
export class FlowViewComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  @ViewChild('svgScroll') svgScroll: ElementRef;
  selectedStep: number;
  displayName: string;
  @Input() stepsList;
  country = null;
  isAdd: boolean = true;
  subProcessData = this.api.subProcess;
  stepsDetails = this.api.stepsDetails;
  isSaving = false;
  selectedIndex: number;
  isSavingSubProcess = false;
  isSavedSubProcess = false;

  otherFile:any={};
  updateKey:string;


  selectedStepType: number;
  selectedStepProcess: number;
  selectedBell = '';
  selType = null;
  selectedList = [];

  showGrouping = false;
  groupingList: any;

  displayList = [];

  step_select:number[] = [];

  flowData = [];
  flowList = [];

  selectedFlowDetails: any;
  flowName = '';
  highlightCard: boolean[] = [false];

  removedSteps = [];
  cardDetails: any;

  workflow_process_id = null;
  isSaved = false;

  subProcessDetails:any = {};
  isRegexOk = false;

  public mr: NgbModalRef;

  constructor(elementRef: ElementRef, public api: WorkflowService,
    private sharedService: SharedService, private modalService: NgbModal, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'Work Flow', active: true }];
    this.country = this.sharedService.selectedCountryId;
    if (this.country) this.getWorkFlows(this.country);
  }


  generateSteps() {
    this.flowData = [];
    this.isAdd = true;
    this.isSaved = false;
    this.flowName = '';
    this.selectedStepType = null;
    this.selectedFlowDetails = null;
    this.displayList = [...this.stepsList];
    this.flowData.push({ id: 0, process: 0, type_id: 0, selected: false });
    this.pushData(1, true);
    this.svgScroll.nativeElement.scrollLeft = this.svgScroll.nativeElement ? 0 : 0;
  }

  open(content) {
    this.mr = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  pushData(type_id: any, isNew, id?, process?) {
    let step = {
      ...this.stepsList.filter(ae => ae.type_id == type_id)[0],
      is_new: isNew
    };
    if (!isNew) step.id = id;
    if (!isNew) step.process = process;
    this.flowData.push(step);
  }

  deleteStep(i, id?) {
    this.flowData[i].is_deleted = true;
    this.removedSteps.push(this.flowData[i]);
    this.flowData.splice(i, 1);
    this.displayList = [];
    let ids = [...new Set(this.flowData.map(a => a.type_id))];
    this.stepsList.forEach((ae) => {
      if (!ids.includes(ae.type_id) || ae.multiple) this.displayList.push(ae);
    });
    this.selectedStepType = null;
    // if (this.flowData.length > 5) this.svgScroll.nativeElement.scrollLeft -= 125;
    this.highlightCard = [false];
    this.saveFlow();
  }

  selectStep(selectedStep, isNew, id?, process?) {
    this.resetVars();
    this.pushData(selectedStep, isNew, id, process);
    let step = this.displayList.filter(ae => ae.type_id == selectedStep)[0];
    if (!step.multiple) this.displayList = this.displayList.filter(ae => ae.type_id != selectedStep);
    if(isNew) this.saveFlow();
    setTimeout(() => {
      // if ( this.flowData.length > 5) this.svgScroll.nativeElement.scrollLeft += 25;
      this.selectedStep = null
    });
  }

  showFlow(flow) {
    this.displayList = [...this.stepsList];
    this.selectedFlowDetails = flow;
    this.flowData = [];
    this.flowName = flow?.name;
    this.selectedStepType = null;
    this.isAdd = false;
    this.removedSteps = [];
    this.groupingList = [];
    this.isSaved = true;
    this.flowData.push({ type_id: 0, selected: false, is_new: false, is_updated: false });
    this.selectedFlowDetails.processes.forEach(ae => {
      this.groupingList.push({ id: ae.id, step: 1 });
      this.selectStep(ae.type_id, false, ae.id, ae.process)
    });
  }

  getCardDetails(process_id) {
    this.api.getDefaultSubProcessById(process_id).subscribe(
      result => {
        this.cardDetails = result;
      }
      )
    }
    
    showStepDetails(id, index, process, process_id) {
      this.selectedStepType = id;
      this.selectedStepProcess = process;
      this.selectedBell = null;
      this.selectedIndex = index;
      this.workflow_process_id = process_id;
      for (let i = 0; i < this.flowData.length; i++) this.highlightCard[i] = (i == index);
      this.subProcessDetails = {process_id: process_id, role: '', doc_type: ''}
      this.getCardDetails(process);
  }

  saveSubProcesss(data?){
    this.isSavedSubProcess = false;
    this.subProcessDetails.doc_type = this.selectedFlowDetails.processes.filter(ae=>ae.id==this.workflow_process_id)[0].doc_type;
    this.api.setDocUpdate(data ? data : this.subProcessDetails).subscribe(
      result=>{
        if(data) this.mr.close();
        this.isSavingSubProcess = false;
        this.isSavedSubProcess = true;
        setTimeout(() => { this.isSavedSubProcess = false; }, 1000);
      }
    )
  }

  onWheel(event: WheelEvent): void {
    if (this.flowData.length > 5) this.svgScroll.nativeElement.scrollLeft += event.deltaY;
    event.preventDefault();
  }

  showNotifyList(pos, i) {
    this.selectedBell = pos;
    this.selType = i;
    let data: any = this.stepsList.filter(ae => ae.type_id == this.selectedStepType)[0].notifications[this.selType];
    this.displayName = data ? data[pos].display_name : '';
    this.selectedList = data ? data[pos].list : [];
  }

  getNotifications(selectedStepType, pos) {
    let data: any = this.stepsList.filter(ae => ae.type_id == selectedStepType)[0].notifications[this.selType];
    this.displayName = data ? data[pos].display_name : '';
    return data ? data[pos].list : [];
  }

  resetVars() {
    this.displayName = '';
    this.selectedBell = '';
    this.selType = null;
    this.selectedList = [];
  }

  // checkType1Tab(tab) {
  //   let activeTab = -1;
  //   this.flowData[this.selectedIndex].tabs.forEach((ae, i) => {
  //     if (ae.selected) activeTab = i;
  //   });
  //   if (activeTab < 0) activeTab = 0;
  //   this.flowData[this.selectedIndex].tabs[activeTab].selected = true;
  //   return this.flowData[this.selectedIndex].tabs[activeTab].name == tab;
  // }

  // setType1Tab(tab) {
  //   this.flowData[this.selectedIndex].tabs.forEach(ae => {
  //     if (ae.name == tab) ae.selected = true;
  //     else ae.selected = false;
  //   });
  // }

  checkTypeDocType(sche_for) {
    let doc_type = this.selectedFlowDetails.processes.filter(ae=>ae.id==this.workflow_process_id)[0].doc_type;
    return doc_type==sche_for;
  }

  setTypeDocType(sche_for, tab) {
    const index = this.selectedFlowDetails.processes.findIndex(ae => { return ae.id === this.workflow_process_id; })
    this.subProcessDetails.role = tab;
    this.selectedFlowDetails.processes[index].doc_type = sche_for;
    if(sche_for!='other') {this.isSavingSubProcess = true; this.saveSubProcesss();}
    else this.getOtherFile(this.workflow_process_id);
  }

  getOtherFile(process_id){
    this.api.getOtherFileName(process_id).subscribe(
      result=>{
        this.otherFile = result;
      }
    )
  }

  checkTypeTab(tab) {
    let doc_type = this.selectedFlowDetails.processes.filter(ae=>ae.id==this.workflow_process_id)[0].role;
    return doc_type==tab;
  }

  setTypeTab(tab) {
    const index = this.selectedFlowDetails.processes.findIndex(ae => { return ae.id === this.workflow_process_id; })
    this.selectedFlowDetails.processes[index].role = tab;
    this.subProcessDetails.role = tab;
    this.isSavingSubProcess = true;
    this.saveSubProcesss();
  }

  setBoolObject(i, data, match) {
    Object.keys(data).map((key) => {
      if (key == match) data[key] = true;
      else data[key] = false;
    })
  }

  getWorkFlows(country) {
    if (this.api.stepsList.length > 0) this.displayList = this.api.stepsList;
    else this.displayList = [...this.stepsList];
    // this.flowList = [];
    this.flowData = [];
    this.selectedStepType = null;
    this.api.getWFbyCountry(country).subscribe(
      result => {
        this.flowList = result;
        if (!this.isAdd) {
          this.showFlow(this.flowList.filter(ae => ae.id == this.selectedFlowDetails.id)[0]);
        }
      })
  }

  showNotificationData(process_name, position, tab?) {
    let data_card: any = {
      tabs: this.cardDetails,
      process: this.selectedStepProcess,
      process_name: process_name,
      position: position,
      tab: tab ? tab : 'Default',
      isTab: tab ? true : false,
      wf_processid: this.workflow_process_id,
      stepType: this.selectedStepType,
      // currentTab: this.getCurrentTab(this.flowData[this.selectedIndex].tabs)
    };
    const modalRef = this.modalService.open(FlowNotificationsComponent,
      {
        size: 'lg',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.nodeDetails = data_card;
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  // getCurrentTab(data) {
  //   let tab = '';
  //   data.forEach(ae => {
  //     if (ae.selected) tab = ae.name;
  //   });
  //   return tab;
  // }

  saveFlow() {
    this.isSaving = true;
    let flowDataCopy = this.flowData.concat(this.removedSteps);
    // for(let i =0;i<this.removedSteps.length;i++) flowDataCopy.push(this.removedSteps[i])
    let data = {
      name: this.flowName,
      country: this.sharedService.selectedCountryId,
      processes: flowDataCopy.map((ae, i) => ({
        process: ae.process ? ae.process : ae.id,
        position: i,
        is_new: ae.is_new,
        is_deleted: ae.is_deleted,
        id: !ae.is_new ? ae.id : null
      }))
    }
    if (this.isAdd) {
      this.api.setWorkflow(data).subscribe(
        result => {
          this.getWorkFlows(this.sharedService.selectedCountryId);
          this.toastr.success("Workflow Saved!");
          this.removedSteps = [];
          this.isSaved = true;
          this.selectedStepProcess = null;
          this.selectedIndex = null;
          this.highlightCard = [false];
          this.isSaving = false;
        },
        error => {
          this.isSaving = false;
        })
    } else {
      this.api.updateWorkflow(this.selectedFlowDetails.id, data).subscribe(
        result => {
          this.getWorkFlows(this.sharedService.selectedCountryId);
          this.removedSteps = [];
          this.isSaved = true;
          this.selectedStepProcess = null;
          this.selectedIndex = null;
          this.highlightCard = [false];
          this.toastr.success("Workflow Updated!");
          this.isSaving = false;
        },
        error => {
          this.isSaving = false;
        })
    }
  }

  setGrouping(step, i) {
    this.groupingList[i].step = step;
  }

  checkIfDisabled(step){
    let isDisabled = false;
    this.groupingList.forEach(ae => {
      if(ae.step>step) isDisabled = true;
    });
    return isDisabled;
  }

  confirmGrouping(stepsData){
    let steps = [1,2,3,4];
    let formatted_data = {};
    steps.forEach(ae => {
      let ea = stepsData.filter(ao=>ao.step==ae);
      let fin = {};
      fin['S'+ae] = ea.map(oo=>oo.id);
      Object.assign(formatted_data, fin);
    });
  }

  checkIfMultiOptSelected(){
    let checkType = [4,6];
    if(checkType.includes(this.selectedStepType)){
      let isChecked = this.selectedFlowDetails.processes[this.selectedIndex-1];
      return isChecked?.doc_type ? false : true;
    }
    return false;
  }

  getKeyUpdate(){
      this.api.getUpdatedKey(this.workflow_process_id).subscribe(
        (result:any)=>{
          this.updateKey = result?.key;
        }
      )
    }

    updateKeyApi(updateKey){
      this.isSavedSubProcess = true;
      this.api.setUpdateKey({
        "key": updateKey,
        "workflow_process_id": this.workflow_process_id
    }).subscribe(
        (result:any)=>{
          this.toastr.success("Key Added Successfully!");
          this.isSavedSubProcess = false;
          this.mr.close();
        },
        error=>{
          this.isSavedSubProcess = false;
        }
      )
    }

    checkNameRegex(name){
      if(!/[^a-zA-Z_]/.test(name)) this.isRegexOk = true;
      else this.isRegexOk = false;
    }

}

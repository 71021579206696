import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MasterFormService } from 'src/app/core/services/master-form.service';
import { CountryQuestionsService } from 'src/app/core/services/country-questions.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalQuestionsService } from 'src/app/core/services/global-questions.service';
import { AddedQueModalComponent } from '../added-que-modal/added-que-modal.component';
import { CountryFormModalComponent } from '../country-form-modal/country-form-modal.component';
import { OrderModalComponent } from '../order-modal/order-modal.component';
import { SharedService } from 'src/app/core/services/shared.service';

@Component({
  selector: 'app-country-questions',
  templateUrl: './country-questions.component.html',
  styleUrls: ['./country-questions.component.scss']
})
export class CountryQuestionsComponent implements OnInit {

  @ViewChildren('checkbox') checkboxes: QueryList<any>;

  countryList: any;
  currentCountry: any;
  isCntrFormCreate: boolean = false;
  newCntryFormData: any;
  curFormDataForCpy: any;
  CountryFormName: any;
  allCountryFormData: any;
  dataToDelete: any;
  allQuestions: any;
  dataAddedForView: any[] = []
  formQuestions: any;
  formLoader: boolean = false;
  raiseQuestionValue: [boolean];
  modalCtrl: any;
  seleCountryForCopy: any;
  // selectedCountryId: number;

  countryForm = this.fb.group({
    country: [{ value: '', disabled: false }, Validators.required],
    // questLevel: [''],
    name: [{ value: '', disabled: false }, Validators.required],
  });



  constructor(private modalService: NgbModal, private fb: FormBuilder, public masterService: MasterFormService, public activeModal: NgbActiveModal,
    public globalQueService: GlobalQuestionsService, public countryQueSer: CountryQuestionsService, private toastr: ToastrService, public sharedSvc: SharedService) {

  }

  ngOnInit(): void {



    if (!this.sharedSvc.formCountryList?.length ?? true) {

      this.getCoutnry();

    } else {
      this.countryList = this.sharedSvc.formCountryList;
    }

    if (!this.sharedSvc.globalQue?.length ?? true) {

      this.getAllQuestions();
    } else {
      this.allQuestions = this.sharedSvc.globalQue;
    }






    // if (this.sharedSvc.globalQue == null || this.sharedSvc.globalQue == undefined || this.sharedSvc.globalQue.length == 0) {
    //   this.getAllQuestions();
    // }
  }

  getCoutnry() {
    this.countryQueSer.getCountry().subscribe((data: any) => {

      this.countryList = data;
      this.sharedSvc.formCountryList = data;
    })
  }




  getAllQuestions() {

    this.globalQueService.getAllGlobalQustionnairNew().subscribe((data: any) => {

      this.allQuestions = data;
      this.sharedSvc.globalQue = this.allQuestions;

    }), ((error) => {
      console.log(error);
    })
  }

  getCountryFormQuestions(formId) {

    this.formLoader = true;
    this.countryQueSer.getCountryFormsQuestion(formId).subscribe((data) => {

      this.formLoader = false;
      this.formQuestions = data;
    }, err => {
      this.formLoader = false;
      console.log(err);
    })

  }

  changeOrder(countryFormData: any) {

    this.newCntryFormData = countryFormData;

    this.countryQueSer.getFormSections(this.newCntryFormData.id).subscribe((data) => {

      this.orderModal(data);

    }, (err => {
      console.log(err);
    }));


  }

  orderModal(dataToPass) {
    const modalRef = this.modalService.open(OrderModalComponent, { fullscreen: true });
    // modalRef.componentInstance.openFor = 'edit';
    modalRef.componentInstance.currData = dataToPass;
    modalRef.result.then((data) => {
      // on close
      if (data.reload) {
        this.getCountryFormQuestions(this.newCntryFormData.id);
      }
    }, (reason) => {
    });
  }

  editCountryForm(data: any) {

    this.isCntrFormCreate = true;
    this.newCntryFormData = data;

    if (this.allQuestions == null || this.allQuestions == undefined || Object.keys(this.allQuestions).length == 0) {
      this.getAllQuestions();

    }

    this.getCountryFormQuestions(data.id);

  }

  queValueUpdation(e, value) {
    console.log(e.target.checked);
    let dataToSend = {

      "raise_question": e.target.checked

    }
    this.countryQueSer.patchRaiseQueState(value.id,dataToSend).subscribe((res:any)=>{
      this.toastr.success('Successfully updated');
    },((err:any)=>{
      console.log(err);
      this.toastr.error('Error!')
    }))
  }

  countryFormCopyModal(copyForm, curForm) {

    this.curFormDataForCpy = curForm;
    this.seleCountryForCopy = this.curFormDataForCpy.country;
    this.modalCtrl = this.modalService.open(copyForm);
    this.modalCtrl.result.then(
      (result) => {
        // console.log(result);

      },
      (reason) => {
        // console.log(reason);
      },
    );
  }

  copyCountryForm() {

    let dataToSend: any = { country: this.seleCountryForCopy, form_id: this.curFormDataForCpy.id };

    this.countryQueSer.copyForm(dataToSend).subscribe((res) => {
      this.viewForms()
      this.modalCtrl.close('Success');
      this.toastr.success('Created successfully')
    }, (err) => {
      console.log(err);
    })
  }

  openEditFormNameModal(editFormName) {
    this.CountryFormName = this.newCntryFormData.name;
    this.modalCtrl = this.modalService.open(editFormName);
    this.modalCtrl.result.then(
      (result) => {
      },
      (reason) => {
      },
    );


    // editFormName
  }

  editCountryFormName() {

    if (this.CountryFormName.trim() == this.newCntryFormData.name) {

      this.toastr.warning('Please update something');
    } else {

      let dataToSend = { name: this.CountryFormName, country: this.newCntryFormData.country };
      this.countryQueSer.editCountryFormName(this.newCntryFormData.id, dataToSend).subscribe((res: any) => {

        this.newCntryFormData.name = res.name;
        this.viewForms();
        this.toastr.success('Updated successfully');
        this.modalCtrl.close('Success');


      }, (err) => { console.log(err); });
    }

  }


  createNewForm(content) {




    if (this.currentCountry?.id) {

      this.countryForm.controls['country'].setValue(this.currentCountry.id);
    } else {

      this.toastr.info('Please select the country')
      return
    }

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => { },
      (reason) => { },
    );
  }

  viewForms() {

    if (this.currentCountry != undefined && this.currentCountry != null) {

      this.formLoader = true;

      this.countryQueSer.getCountryForms(this.currentCountry.id).subscribe((res: any) => {

        this.allCountryFormData = res;
        this.formLoader = false;
      }), ((err: any) => {

        console.log(err);
        this.formLoader = false;
      });

    } else {

      this.allCountryFormData = null;
      this.toastr.show('Please select the country to see Forms');
    }

  }

  delConfModal(modalElem: any, data: any) {


    this.dataToDelete = data
    this.modalService.open(modalElem, { ariaLabelledBy: 'modal-basic-title' }).result.then(
      (result) => {
        if (data && data.id) {
          this.deleteForm(data)
        }


      }, (reason) => {
        // console.log(reason);

      }
    );
  }

  deleteForm(item: any) {

    this.countryQueSer.deleteCountryForms(item?.id).subscribe((res: any) => {

      let index = this.allCountryFormData.findIndex(formDataItem => formDataItem.id == item.id);
      this.allCountryFormData.splice(index, 1);
      this.toastr.success('Successfully deleted');

    }), ((err: any) => {
      console.log(err);

    })
  }





  addQuestions() {
    const modalRef = this.modalService.open(AddedQueModalComponent, { fullscreen: true });


    modalRef.componentInstance.currentFormData = this.newCntryFormData;
    modalRef.componentInstance.allQuestions = this.allQuestions;

    modalRef.result.then((data) => {
      if (data.reload) {
        this.getCountryFormQuestions(this.newCntryFormData.id);
      }
    }, (reason) => {
    });
  }

  editOrDelCurrentQuestion(questionData) {

    const modalRef = this.modalService.open(CountryFormModalComponent, { size: 'xl' });

    modalRef.componentInstance.openFor = 'edit';
    modalRef.componentInstance.currQueData = questionData;
    modalRef.componentInstance.allFormQuestions = this.formQuestions;

    modalRef.result.then((data) => {
      // on close
      if (data.reload) {
        this.getCountryFormQuestions(this.newCntryFormData.id);
      }

    }, (reason) => {
      // on dismiss
      // console.log(reason);

    });
  }

  createNewQuestion() {

    const modalRef = this.modalService.open(CountryFormModalComponent, { size: 'xl' });

    modalRef.componentInstance.openFor = 'create';
    modalRef.componentInstance.currQueData = this.newCntryFormData;
    modalRef.componentInstance.allFormQuestions = this.formQuestions;

    modalRef.result.then((data) => {
      // on close
      if (data.reload) {
        this.getCountryFormQuestions(this.newCntryFormData.id);
      }
    }, (reason) => {
    });
  }

  onSubmit() {

    this.countryQueSer.createCountryForm(this.countryForm.value).subscribe((res: any) => {
      this.newCntryFormData = res;
      this.isCntrFormCreate = true;
      this.viewForms();
      this.formQuestions = [];
      this.toastr.success('Successfully Created')
    }), ((err: any) => {
      console.log(err);

    })

  }

}

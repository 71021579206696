<div class="row" *ngFor="let category of categoryTypes">
    <div class="col-sm-12 col-lg-12">
        <h4>{{category.name}}</h4>
    </div>
    <div class="col-sm-6 col-lg-6" *ngFor="let item of termDetailsList">
        <div class="card text-center">
            <div class="card-abs-head">
                <a class="float-end p-1"><i class="uil-edit"></i></a>
            </div>
            <div class="card-body">
                <h5 class="card-title">{{item.name}}</h5>
            </div>
        </div>
    </div>
    <div class="col-sm-6 col-lg-6">
      <div class="card text-center">
          <div class="card-body">
              <h5 class="card-title">
                <select type="text" class="form-select">
                    <option>Term</option>
                </select>
            </h5>
              <div class="card-text text-start">
                <div class="d-flex">
                    <input type="text" class="form-control" placeholder="Stay"> 
                    <small class="m-auto">&nbsp;Days&nbsp;&nbsp;</small>
                    <input type="text" class="form-control" placeholder="Validity">
                    <small class="m-auto">&nbsp;Days&nbsp;&nbsp;</small>
                </div>
              </div>
          </div>
      </div>
  </div>
</div>
<div class="container-fluid">
  <!-- start page title -->
  <app-page-title title="Country Page" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="my-3 row">
    <div class="offset-md-8 col-md-4">
      <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
        bindValue="id" [(ngModel)]="country" [clearable]="false" (change)="selectCountry(country)"></ng-select>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-6 offset-lg-3 set-minwh-1" *ngIf="country==null;">
      <div class="card text-center p-4">
        Select Country to Continue
      </div>
    </div>
    <div class="col-12" *ngIf="country">
      <div class="card">
        <div class="card-body">
          <app-preview-btn [page]="page"></app-preview-btn>
          <ul ngbNav #justifiednavpills="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
            <li [ngbNavItem]="1">
              <a ngbNavLink>
                <span>Header</span>
              </a>
              <ng-template ngbNavContent>
                <app-country-header [country]="country" [page]="page"></app-country-header>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>
                <span>Side&nbsp;Info</span>
              </a>
              <ng-template ngbNavContent>
                <app-header-side-info [country]="country" [page]="page"></app-header-side-info>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>
                <span>Suitable&nbsp;Visa</span>
              </a>
              <ng-template ngbNavContent>
                <app-country-suitable-visa [country]="country" [page]="page"></app-country-suitable-visa>
              </ng-template>
            </li>
            <li [ngbNavItem]="4">
              <a ngbNavLink>
                <span *ngIf="page!='country'">Popular&nbsp;Destination</span>
                <span *ngIf="page=='country'">Recommended&nbsp;Visas</span>
              </a>
              <ng-template ngbNavContent>
                <app-popular-destination [country]="country" [page]="page"></app-popular-destination>
              </ng-template>
            </li>
            <li [ngbNavItem]="10">
              <a ngbNavLink>
                <span>Vouchers</span>
              </a>
              <ng-template ngbNavContent>
                <app-vouchers [country]="country" [page]="page"></app-vouchers>
              </ng-template>
            </li>
            <li [ngbNavItem]="11">
              <a ngbNavLink>
                <span>Testimonials</span>
              </a>
              <ng-template ngbNavContent>
                <app-testimonials [country]="country" [page]="page"></app-testimonials>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="justifiednavpills"></div>
        </div>
      </div>
    </div>
  </div>
</div>
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Component, Input, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { FaqSeoService } from "src/app/core/services/faq-seo.service";
import { PermissionService } from "src/app/core/services/permision.service";
import { SharedService } from "src/app/core/services/shared.service";


@Component({
  selector: "app-author-specific-seo",
  templateUrl: "./author-specific-seo.component.html",
  styleUrls: ["./author-specific-seo.component.scss"],
})
export class AuthorSpecificSeoComponent {
  seoForm: FormGroup;
  hasChanges = false;
  isUpdating = false;
  isUploading = [false];
  updateId: number;
  isNew = false;

  robotIndex = false;
  robotfollow = false;

  @Input() slugId: any;
  @Input() seoType: string;
  fileToUpload: File | [null] = [null];
  publishPage: string;
  progress: number[] = [0];

  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    private api: FaqSeoService,
    private toastr: ToastrService,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {
    this.resetForm();
    this.getData();
  }

  resetForm(data?) {
    this.seoForm = this.fb.group({
      admin_seo_key: [this.seoType],
      admin_seo_id: [this.slugId],
      title: [data ? data.title : null, [Validators.required]],
      description: [data ? data.description : null],
      canonical_url: [data ? data.canonical_url : null],
      robots_index: [data?.robots_index ? data.robots_index : false],
      robots_follow: [data?.robots_follow ? data.robots_follow : false],
      og_title: [data ? data.og_title : null],
      og_description: [data ? data.og_description : null],
      og_image_url: [data ? data.og_image_url : null],
      og_image_secure_url: [data ? data.og_image_secure_url : null],
      og_image_type: [data ? data.og_image_type : null],
      og_image_width: [data ? data.og_image_width : null],
      og_image_height: [data ? data.og_image_height : null],
      og_type: [data ? data.og_type : null],
      og_url: [data ? data.og_url : null],
      og_site_name: [data ? data.og_site_name : null],
      og_locale: [data ? data.og_locale : null],
      twitter_card_type: [data ? data.twitter_card_type : null],
      twitter_creator: [data ? data.twitter_creator : null],
      twitter_site: [data ? data.twitter_site : null],
      twitter_title: [data ? data.twitter_title : null],
      twitter_description: [data ? data.twitter_description : null],
      twitter_image_url: [data ? data.twitter_image_url : null],
      twitter_image_alt: [data ? data.twitter_image_alt : null],
      facebook_og_type: [data ? data.facebook_og_type : null],
      facebook_og_url: [data ? data.facebook_og_url : null],
      facebook_og_title: [data ? data.facebook_og_title : null],
      facebook_og_description: [data ? data.facebook_og_description : null],
      facebook_og_image_url: [data ? data.facebook_og_image_url : null],
      facebook_og_image_secure_url: [
        data ? data.facebook_og_image_secure_url : null,
      ],
      facebook_og_image_type: [data ? data.facebook_og_image_type : null],
      facebook_og_image_width: [data ? data.facebook_og_image_width : null],
      facebook_og_image_height: [data ? data.facebook_og_image_height : null],
      instagram_title: [data ? data.instagram_title : null],
      instagram_image_url: [data ? data.instagram_image_url : null],
      instagram_image_width: [data ? data.instagram_image_width : null],
      instagram_image_height: [data ? data.instagram_image_height : null],

      schema: [data ? data.schema : null],
      schema_multiple_files: new FormArray([]),
      h1: [data ? data.h1 : null],
      h2: [data ? data.h2 : null],
      keywords: [data ? data.keywords : []],
      keywords_data: this.fb.array([]),
    });

    if (data?.schema_multiple_files != undefined) {
      let files = data?.schema_multiple_files;
      files.forEach((ao) => {
        (this.seoForm.get("schema_multiple_files") as FormArray).push(
          this.fb.group({
            schema_file: [ao.schema_file, [Validators.required]],
          })
        );
      });
    } else {
      this.addfiles();
    }
    if (data) {
      data.keywords?.forEach((ao) => {
        (this.seoForm.get("keywords_data") as FormArray).push(
          this.fb.group({
            keywords: [ao],
          })
        );
      });
    }
    this.onChanges();
  }

  files(): FormArray {
    return this.seoForm.get("schema_multiple_files") as FormArray;
  }

  newfiles(): FormGroup {
    return this.fb.group({
      schema_file: ["", [Validators.required]],
    });
  }

  addfiles() {
    this.files().push(this.newfiles());
  }

  removefiles(i: number) {
    this.files().removeAt(i);
  }

  get keyword(): FormArray {
    return this.seoForm.get("keywords_data") as FormArray;
  }

  newKeyword(): FormGroup {
    return this.fb.group({
      keywords: "",
    });
  }

  addKeyword() {
    this.keyword.push(this.newKeyword());
  }

  removeKeyword(i: number) {
    this.hasChanges = true;
    this.keyword.removeAt(i);
  }

  onChanges(): void {
    this.seoForm.valueChanges.subscribe(() => {
      Object.keys(this.seoForm.controls).map((key) => {
        if (this.seoForm.get(key).dirty) this.hasChanges = true;
      });
    });
  }

  onSubmit(form) {
    if (true) {
      let keyword: any = [];
      form.value.keywords_data.forEach((ae) => {
        keyword.push(ae.keywords);
      });
      form.value.keywords = keyword;
      let data_to_send = JSON.parse(JSON.stringify(form.value));
      delete data_to_send["keywords_data"];
      if (this.isNew) {
        this.isUpdating = true;
        this.api.postMediaSeo(data_to_send).subscribe(
          () => {
            // this.resetForm(result);
            this.toastr.success("Content Added Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
            this.isNew = false;

            //this.dataOnSelect();
          },
          () => {
            this.isUpdating = false;
          }
        );
      } else {
        this.isUpdating = true;
        data_to_send.admin_seo_id = undefined;
        data_to_send.admin_seo_key = undefined;
        this.api.editMediaSeo(this.updateId, data_to_send).subscribe(
          () => {
            // this.resetForm(result);
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
            //this.dataOnSelect();
          },
          () => {
            this.isUpdating = false;
          }
        );
      }
    } else {
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  getData() {
    
    this.api
      .getSingleMediaSeo(this.seoType, this.slugId,true)
      .subscribe((result: any) => {
        this.resetForm(result);
        if (Object.keys(result).length === 0) this.isNew = true;
        this.updateId = result.id;
      });
  }

  fileUpload = (i) => {
    let element: HTMLElement = document.getElementById(
      `fileupload${i}`
    ) as HTMLElement;
    element.click();
  };

  getFile = (files: FileList, i) => {
    this.hasChanges = true;
    this.isUploading[i] = true;
    const formData: FormData = new FormData();
    formData.append("document", files.item(0));
    this.sharedService
      .uploadFile(formData)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress[i] = Math.round((100 * event.loaded) / event.total);
            break;
          case HttpEventType.Response:
            this.seoForm
              .get(["schema_multiple_files", i, "schema_file"])
              .setValue(event.body.document);
            this.progress[i] = 0;
            this.isUploading[i] = false;
            break;
        }
      });
  };

  backtoList() {
    this.sharedService.backTolist()
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

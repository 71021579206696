import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SharedService } from 'src/app/core/services/shared.service';

@Component({
  selector: 'app-custom-sort',
  templateUrl: './custom-sort.component.html',
  styleUrls: ['./custom-sort.component.scss']
})
export class CustomSortComponent {
  @Input() sortData;
  isSorting: boolean;

  constructor(public activeModal: NgbActiveModal,
    public toastr: ToastrService,
    private api: SharedService) { }

  closeModal(status) {
    this.activeModal.close(status);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sortData?.list, event.previousIndex, event.currentIndex);
  }

  confirmSort() {
    const pass_data = this.sortData.list.map((ae, i) => ({
      id: ae.id,
      priority: i
    }));
    this.isSorting = true;
    this.api.generalPutApi({priority:pass_data}, this.sortData.api_url).subscribe(
      () => {
        this.isSorting = false;
        this.toastr.success("Positions Changed!");
        this.closeModal(true);
      },
      () => {
        this.isSorting = false;
      }
    );
  }

}

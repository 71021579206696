<div class="modal-header">
    <h5 class="modal-title w-100 text-center">Gallery</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)">
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div *ngIf="isLoading" class="w-00 text-center fa-5x">
            <div role="status" class="spinner-grow text-warning loader"><span class="sr-only">Loading...</span></div>
        </div>
        <div class="row ps-4 pe-1 gallery scroll col-md-8"
            [ngClass]="{'col-md-12': !selectedImage, 'col-md-8': selectedImage}">
            <div [ngClass]="{'col-md-2': !selectedImage, 'col-md-3': selectedImage}"
                *ngFor="let item of galleryList; let i=index;">
                <div class="img-div" [ngClass]="{'selected': item.selected}" (click)="selectImage(item, i)"
                    [style.background-image]="'url(' + item.document + ')'"></div>
                <img [src]="item.document" (load)="getImageDetails($event, i)" style="display: none;">
            </div>

            <div class="col-md-12">
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            Showing
                            {{(pageNumber-1)*pageSize + 1}} to
                            {{pageNumber*pageSize < page_details.count ? pageNumber*pageSize : page_details.count}} of
                                {{page_details.count}} entries </div>
                        </div>
                        <!-- Pagination -->
                        <div class="col-sm-12 col-md-5">
                            <div class="text-md-end float-md-end pagination-rounded" [ngClass]="{'side-info': selectedImage}">
                                <ngb-pagination [collectionSize]="page_details.count" [(page)]="pageNumber"
                                    [pageSize]="pageSize" (pageChange)="nextPage(pageNumber);">
                                </ngb-pagination>
                            </div>
                        </div>
                        <!-- End Pagination -->
                    </div>
                </div>
            </div>
            <div class="col-md-4" *ngIf="selectedImage">
                <div class="details scroll">
                    <h4 class="text-center">Image Details <a class="text-danger float-end cpr"
                            (click)="selectedImage=null"><i class="fa fa-times"></i></a></h4>
                    <img (click)="openImageInNewTab(selectedImage.document)" [src]="selectedImage.document" width="250">
                    <ul class="ul-details text-muted font-size-12">
                        <li>Dimensions : {{imageWidths[selectedIndex]}} x {{imageHeights[selectedIndex]}}</li>
                        <li>Uploaded On : September 1, 2023</li>
                        <li>File name : hello.webp</li>
                        <li>File type : image/webp</li>
                        <li>File size : 250KB</li>
                    </ul>

                    <form [formGroup]="imageForm" (ngSubmit)="onSubmit(imageForm)">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="col-form-label">URL</label>
                                <input type="text" class="form-control" readonly placeholder="Enter URL"
                                    formControlName="document">
                            </div>
                            <div class="col-md-12">
                                <label class="col-form-label">Alternative Text</label>
                                <input type="text" class="form-control" placeholder="Enter Alt Text"
                                    formControlName="alt_text">
                            </div>
                            <div class="col-md-12">
                                <label class="col-form-label">Title</label>
                                <input type="text" class="form-control" placeholder="Enter Title"
                                    formControlName="title">
                            </div>
                            <div class="col-md-12">
                                <label class="col-form-label">Caption</label>
                                <input type="text" class="form-control" placeholder="Enter Caption"
                                    formControlName="caption">
                            </div>
                            <div class="col-md-12">
                                <label class="col-form-label">Description</label>
                                <textarea type="text" class="form-control" placeholder="Enter Description"
                                    formControlName="description" rows="3"></textarea>
                            </div>
                            <div class="col-md-12 text-end mt-2">
                                <button class="btn btn-primary">
                                    <i *ngIf="isUpdating" class="fa fa-pulse fa-spin fa-spinner"></i> Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="closeModal(false)">Cancel</button>
        <button class="btn btn-primary" (click)="triggerClick('uploadImg')">Upload Image</button>
        <button class="btn btn-success" (click)="setImage()">Set Image</button>

        <input id="uploadImg" type="file" accept="image/*" (change)="handleIcon($event.target.files)"
            class="form-control" placeholder="Upload Icon" style="display: none;">

        <div class="mt-2 progress-bar" *ngIf="progress>0">
            <div class="progress" [style.width.%]="progress">
                {{progress}}%
            </div>
        </div>
    </div>
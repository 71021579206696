import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CountryManagementService {

  constructor(private http: HttpClient) { }

  // country description
  getDescription(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/country/country-description/?country=${id}`).pipe(retry(2));
  }

  setDescription(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/country/country-description/${id}/`, data);
  }

  postDescription(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/country/country-description/`, data);
  }

  // side info
  getSideInfo(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/country/side-info-country/?country=${id}`).pipe(retry(2));
  }

  setSideInfo(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/country/side-info-country/`, data);
  }

  // how to apply process
  getCountryApplyProcess(country): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/country/country-application-process/?country=${country}`).pipe(retry(2));
  }

  setCountryApplyProcess(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/country/country-application-process/${id}/`, data);
  }

  postCountryApplyProcess(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/country/country-application-process/`, data);
  }

  // how to apply steps
  getCountryApplySteps(country): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/country/country-application-step/?country=${country}`).pipe(retry(2));
  }

  setCountryApplySteps(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/country/country-application-step/`, data);
  }

  // suitable visa
  getSuitableVisa(country): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/country/category-detail/?category__country=${country}`).pipe(retry(2));
  }

  setSuitableVisa(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/country/category-detail/`, data);
  }

  // required docs
  getRequiredDocs(country): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/country/required-document/?country=${country}`).pipe(retry(2));
  }

  setRequiredDocs(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/country/required-document/`, data);
  }

  // Additional Info
  getAdditionalInfo(country): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/country/additional-information/?country=${country}`).pipe(retry(2));
  }

  setAdditionalInfo(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/country/additional-information/`, data);
  }

  // Video 
  getVideo(country?): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/country/video/${country ? '?&country=' : ''}${country ? country : ''}`).pipe(retry(2));
  }

  setVideo(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/country/video/`, data, {
      reportProgress: true,
      observe: 'events',
    });
  }

  updateVideo(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/country/video/${id}/`, data, {
      reportProgress: true,
      observe: 'events',
    });
  }

  // Voucher 
  getVoucher(country): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/country/voucher/?country=${country}`).pipe(retry(2));
  }

  setVoucher(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/country/voucher/`, data);
  }

  // Other visas 
  getOtherVisas(country): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/country/othervisa/?country=${country}`).pipe(retry(2));
  }

  setOtherVisas(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/country/othervisa/`, data);
  }

  getProductDetail(is_starred, country_starred?, product__category__country?, category_starred?, product__category?, product?): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/country/product-detail/`+
    `?is_starred=${is_starred}`+
    `${country_starred ? '&country_starred=' : ''}${country_starred ? country_starred : ''}`+
    `${category_starred ? '&category_starred=' : ''}${category_starred ? category_starred : ''}`+
    `${product ? '&product=' : ''}${product ? product : ''}`+
    `${product__category ? '&product__category=' : ''}${product__category ? product__category : ''}`+
    `${product__category__country ? '&product__category__country=' : ''}${product__category__country ? product__category__country : ''}`).pipe(retry(2));
  }
  
  setProductDetail(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/country/product-detail/`, data);
  }

  // How to apply 
  getApplySteps(country): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/country/country-visa-process/?country=${country}`).pipe(retry(2));
  }

  setApplySteps(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/country/country-visa-process/`, data);
  }

}

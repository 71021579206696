import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/core/services/shared.service';
import { VisaManageService } from 'src/app/core/services/visa-manage.service';

@Component({
  selector: 'app-upload-modal',
  templateUrl: './upload-modal.component.html',
  styleUrls: ['./upload-modal.component.scss']
})
export class UploadModalComponent implements OnInit {
  fileToUpload: File | null = null;
  progress: number;
  image_url: string;
  imagePdf:string;
  status: string;
  @Input() uploadDetails;
  isSubmitting = false;
  isGetting = false;
  uploadedData: any = '';

  constructor(public activeModal: NgbActiveModal,
    public api: VisaManageService,
    public toastr: ToastrService,
    private sharedService: SharedService,) { }

  ngOnInit(): void {
    this.getUploadedUrl(this.uploadDetails.url);

  }

  closeModal(status) {
    this.activeModal.close(status);
  }

  handleIcon(files: FileList) {
    if(files[0]?.type==='video/mp4') {
      this.toastr.warning('Invalid file type','Error');
      return
    }
   
    this.status=''
    this.fileToUpload = files.item(0);
    const formData: FormData = new FormData();
    formData.append("document", this.fileToUpload);
    this.sharedService
      .uploadFile(formData)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress = Math.round((100 * event.loaded) / event.total);
            break;
          case HttpEventType.Response:
            this.image_url = event.body.document;
            this.imagePdf = event.body?.document?.includes('.pdf')?'assets/images/pdf_image.png':event.body.document;
            this.progress = 0;
            break;
        }
      });
  }

  @ViewChild('iconClick') myDiv: ElementRef<HTMLElement>;

  triggerClick() {
    let el: HTMLElement = this.myDiv.nativeElement;
    el.click();
  }

  uploadConsulate() {
    this.isSubmitting = true;
    this.api.uploadDetailsUpdate(this.uploadDetails.url, {
      'document': this.image_url,
      'pax_id': parseInt(this.uploadDetails.pax_id, 10),
      'process_id': this.uploadDetails.process_id,
      'doc_type': this.uploadDetails.doc_type,
    }).subscribe(
      result => {
        this.isSubmitting = false;
        this.toastr.success(result.message);
        this.closeModal(true);
      },
      error => {
        this.isSubmitting = false;
      }
    )
  }

  getUploadedUrl(url) {
    this.isGetting = true;
    this.api.getUploadDetails(url, parseInt(this.uploadDetails.pax_id, 10)).subscribe(
      result => {
        this.image_url = result?.document;
        this.imagePdf=result?.document?.includes('.pdf')?'assets/images/pdf_image.png':result?.document;
        this.status = result?.status;
        this.isGetting = false;
        this.uploadedData = result;
      },
      error => {
        this.isGetting = false;
      }
    )
  }
}

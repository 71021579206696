import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessManagementService {

  constructor(private http: HttpClient) { }

  getUserList(limit, offset) {
    return this.http.get(`${environment.apiUrl}/users/agents/` +
      `?&limit=${limit}` +
      `&offset=${offset}`).pipe(retry(2));
  }

  getUserListForVisaPro() {
    return this.http.get(`${environment.apiUrl}/users/agents/?page=false`)
  }
  setUserList(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/agents/`, data);
  }

  editUserList(id, data): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/users/agents/${id}/`, data);
  }

  getUserRole(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/users/role-permission/role-list/`).pipe(retry(2));
  }

  getUserPermissionDetailsId(id): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/users/role-permission/${id}/`).pipe(retry(2));
  }

  getUserPermissionDetails(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/users/role-permission/`).pipe(retry(2));
  }

  setUserRoleDetails(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/role-permission/`, data);
  }

  editUserRoleDetails(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/users/role-permission/${id}/`, data);
  }

  setUserRolePermission(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/agent-permission/`, data);
  }

  editUserRolePermission(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/users/agent-permission/${id}/`, data);
  }

  setDisplayStatus(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/users/agents/${id}/change-active-status/`, data);
  } 
  
  setCountryAssign(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/review-360/assign_country/`, data);
  }
}

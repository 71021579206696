import { Component, Input } from '@angular/core';

@Component({
  selector: 'field-builder',
  template: `
    <div class="form-group" [formGroup]="form">
      <label class="form-control-label" [attr.for]="field.field_name"  [ngClass]="{'text-danger': submitted && form.get(field.name).hasError('required')}">
        {{ field.display_name }}
        <strong class="text-danger ms-1" *ngIf="field?.is_required">*</strong>
        <span class="material-icons pointer" *ngIf="field.info"  placement="top" ngbTooltip="{{field.info}}"> info </span>
      </label>
      <div [ngSwitch]="field.field_type.toLowerCase()">
        <checkbox *ngSwitchCase="'checkboxes'" [field]="field" [form]="form"></checkbox>
        <radio *ngSwitchCase="'radio button'" [field]="field" [form]="form"></radio>
        <file *ngSwitchCase="'dropbox'" [field]="field" [form]="form"></file>
        <text-area *ngSwitchCase="'paragragh'" [field]="field" [form]="form"></text-area>

        <textbox *ngSwitchCase="'user input'" [type]="'text'" [field]="field" [form]="form"></textbox>
        <textbox *ngSwitchCase="'email'" [type]="'email'" [field]="field" [form]="form"></textbox>
        <textbox *ngSwitchCase="'date'" [type]="'date'" [field]="field" [form]="form"></textbox>
        <textbox *ngSwitchCase="'date format'" [type]="'date'" [field]="field" [form]="form"></textbox>
        <textbox *ngSwitchCase="'time'" [type]="'time'" [field]="field" [form]="form"></textbox>
        <textbox *ngSwitchCase="'phone'" [type]="'tel'" [field]="field" [form]="form"></textbox>
        
        <dropdown *ngSwitchCase="'dropdown'" [field]="field" [form]="form"></dropdown>
        <dropdown *ngSwitchCase="'state'" [field]="field" [form]="form"></dropdown>
        <dropdown *ngSwitchCase="'country_ms'" [field]="field" [form]="form"></dropdown>
        <dropdown *ngSwitchCase="'country_s'" [field]="field" [form]="form"></dropdown>
        <dropdown *ngSwitchCase="'language'" [field]="field" [form]="form"></dropdown>
        <dropdown *ngSwitchCase="'multiple selections'" [field]="field" [form]="form"></dropdown>
      </div>
      <!-- <small class="text-danger font_12" *ngIf="submitted && form.get(field.name).hasError('required')">
        This field is mandatory
      </small> -->
    </div>
  `,
})
export class FieldBuilderComponent {
  @Input() field: any;
  @Input() form: any;
  @Input() submitted: boolean = false;
  get isValid() {
    return true
    // return this.form.controls[this.field.name].valid;
  }
  get isDirty() {
    return false
    // return this.form.controls[this.field.name].dirty;
  }

  isSubmitted(status: boolean) {
    this.submitted = status;
  }

  constructor() { }

}

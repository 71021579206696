import { Component, ElementRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PermissionService } from "src/app/core/services/permision.service";
import { NotificationTemplatesService } from "src/app/core/services/notification-templates.service";
import { AddWhatsappComponent } from "./add-whatsapp/add-whatsapp.component";


@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss']
})
export class WhatsappComponent implements OnInit{

  templateList = [];
  isLoading = false;
  syncLoading = false;


  constructor(
    private modalService: NgbModal,
    private api: NotificationTemplatesService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {
    // if(this.templateList.length==0) this.syncTemplates();
    this.getData();
  }

  getData(){
    this.isLoading = true;
    this.api.getWhatsappNotification().subscribe(result => {
      this.templateList = result;
      this.isLoading = false;
    }, error=>{
      this.isLoading = false;
    });
    
  }

  syncTemplates(){
    this.syncLoading = true;
    this.api.syncWhatsappNotification().subscribe(result => {
      this.getData();
      this.syncLoading = false;
    }, error=>{
      this.syncLoading = false;
    });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  addWhatsappModal(data?, item?) {
    const modalRef = this.modalService.open(AddWhatsappComponent, {
      size: "md",
      // scrollable: true,
      windowClass: "modal-small-lg",
      backdrop: "static",
    });
    let details = {
      id: item?.id,
      name: data?.name,
      language: item?.name,
      memory_variables: item?.memory_variables
    }

    modalRef.componentInstance.fromParent = details ? details : null;

    modalRef.result.then((result) => {
      if(result) this.getData();
    },
      (reason) => {}
    );
  }


}

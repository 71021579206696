import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VisaManageService } from 'src/app/core/services/visa-manage.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-schedule-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.scss']
})
export class ScheduleModalComponent implements OnInit{

  @Input() dataDetails;
  isConfirming = false;
  dateRange:any;
  status:string;
  isGetting: boolean;

  constructor(public activeModal: NgbActiveModal, public api: VisaManageService, public toastr: ToastrService){}
  ngOnInit(): void {
    if(this.dataDetails.role==='user-update') this.getDates(this.dataDetails.api);
  }

  closeModal(status) {
    this.activeModal.close(status);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

  onConfirm() {
    this.isConfirming = true;
    let data:any
    if(this.dataDetails?.role==='user'){
       data={
        doc_type:this.capitalizeFirstLetter(this.dataDetails.doc_type),
        role:this.dataDetails?.role,
        from:this.dateRange?.from.toLocaleDateString("en-CA"),
        to:this.dateRange?.to.toLocaleDateString("en-CA"),
        pax_id:parseInt(this.dataDetails.pax_id, 10),
      }
    }else{
      data={
        doc_type : this.dataDetails.doc_type,
        role:this.dataDetails?.role,
        dates: this.dateRange,
        pax_id:parseInt(this.dataDetails.pax_id, 10),
      }
    }
    this.api.dataUpdate(this.dataDetails.api,data).subscribe(
      (result:any)=>{
        this.isConfirming = false;
        this.toastr.success(result.message);
        this.closeModal(true);
      },
      error=>{
        this.isConfirming = false;
      }
    )
  }

  onConfirmData(status){
    this.isConfirming = true;
    this.api.dataUpdatePut(this.dataDetails.api,{
      'dates' : this.dateRange,
      'pax_id' : parseInt(this.dataDetails.pax_id, 10),
      'status' : status,
      'role': this.dataDetails.role,
      'doc_type' : this.dataDetails.doc_type,
    }).subscribe(
      (result:any)=>{
        this.isConfirming = false;
        this.toastr.success(result.message);
        this.closeModal(true);
      },
      error=>{
        this.isConfirming = false;
      }
    )
  }

  getDates(url){
    this.isGetting = true;
    const doc_type=this.dataDetails.role==='user-update'?this.capitalizeFirstLetter(this.dataDetails.doc_type):this.dataDetails.doc_type
    const role=this.dataDetails.role==='user-update'?'user':this.dataDetails.role
    this.api.getVerifyDoc(url, parseInt(this.dataDetails.pax_id, 10), doc_type, role).subscribe(
      (result:any)=>{
        this.dateRange = result?.dates;
        this.status = result?.status;
        this.isGetting = false;
      },
      error=>{
        this.isGetting = false;
      }
    )
  }
}

import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GlobalQuestionsService {

  constructor(private http: HttpClient) { }


  getFormFields(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/form-fields`).pipe(retry(2));
  }

  getStorVarOptions(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/storage-variables/`).pipe(retry(2));
  }

  getGlobalQustionnair(limit: number, offset: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/global-questionnaire/?` + `limit=${limit}` + '&' + `offset=${offset}`).pipe(retry(2));
  }

  getAllGlobalQustionnair(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/global-questionnaire/`).pipe(retry(2));
  }

  getAllGlobalQustionnairNew(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/global-questionnaire/all/`).pipe(retry(2));
  }

  postGlobalQustionnair(data:any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/global-questionnaire/`, data);
  }

  editGlobalQustionnair(data:any,id:any,): Observable<any> {
    return this.http.put(`${environment.apiUrl}/global-questionnaire/`+`${id}/`, data);
  }

  deleteGlobalQustionnair(id:any) {
    return this.http.delete(`${environment.apiUrl}/global-questionnaire/`+`${id}/`);
  }



}

import { Component, OnInit } from "@angular/core";
import { SharedService } from "src/app/core/services/shared.service";

@Component({
  selector: "app-access-management",
  templateUrl: "./access-management.component.html",
  styleUrls: ["./access-management.component.scss"],
})
export class AccessManagementComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  page = "access-management";

  constructor(private sharedService: SharedService){

  }

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Settings" },
      { label: "Access Management", active: true },
    ];
  }
}

import { Component, OnInit } from "@angular/core";
import { SharedService } from "src/app/core/services/shared.service";

@Component({
  selector: "app-general",
  templateUrl: "./general.component.html",
  styleUrls: ["./general.component.scss"],
})
export class GeneralComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  page = "general";

  constructor(private sharedService: SharedService,) {}
  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "CMS" },
      { label: "General Page", active: true },
    ];
  }
}

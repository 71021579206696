import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss']
})
export class QuestionsComponent {

  @Input() formInputs;
  @Input() fromVisaFlow;
  @Input() isMaster;
  unblockQuestions = [];

  typeOfVar(ans: any, typ: any) {
    return (typeof (ans) == typ)
  }

  checkIfRequired(item, check) {
    if(item.verification_required){
      if (item.agent_raised && check == 'raised') item.is_verified = false;
      else if (item.is_verified && check == 'verify') item.agent_raised = false;
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VisaManageService } from 'src/app/core/services/visa-manage.service';
import { ToastrService } from 'ngx-toastr';
import { switchAll } from 'rxjs';

@Component({
  selector: 'app-verify-modal',
  templateUrl: './verify-modal.component.html',
  styleUrls: ['./verify-modal.component.scss']
})
export class VerifyModalComponent implements OnInit {

  @Input() uploadDetails;
  openFor: string = null;
  isVerifiying = false;
  isGetting = false;
  doc_url = '';
  status = '';
  isDocUpload: boolean;
  isAgentUpdated: boolean;
  dataList = [];
  consulateFormData: any;
  paxDetails: any;
  documentData: any;
  dataLoader: boolean = false;
  scheduleData: any;
  _consulate_form:string = 'consulate_form';

  passportFront = '';
  passportBack = '';
  otherDocsList:any;

  constructor(public activeModal: NgbActiveModal, public api: VisaManageService, public toastr: ToastrService) { }
  ngOnInit(): void {

    console.log(this.uploadDetails);
    this.getDocUrl(this.uploadDetails.api);
    this.checkUrl(this.uploadDetails.api);
    this.passportLoad();
  }

  checkUrl(url) {
    switch (url) {
      case 'documents-upload':
        this.isDocUpload = true;
        break;

      case 'data-update/agent-updated-data':
        this.isAgentUpdated = true;
        break;

      default:
        break;
    }
  }

  closeModal(status) {
    this.activeModal.close(status);
  }

  onVerify(status, uid?) {
    this.isVerifiying = true;
    let data_send = {
      status: status,
      pax_id: this.uploadDetails.pax_id,
      doc_type: this.uploadDetails.doc_type
    }
    this.api.verifyDocument(this.uploadDetails.api, uid ? Object.assign({ uid: uid }, data_send) : data_send).subscribe(
      (result: any) => {
        this.isVerifiying = false;
        this.toastr.success(result.message);
        this.closeModal(true);
      },
      error => {
        this.isVerifiying = false;
      }
    )
  }

  getDocUrl(url) {
    this.isGetting = true;
    this.api.getVerifyDoc(url, parseInt(this.uploadDetails.pax_id, 10), this.uploadDetails.doc_type).subscribe(
      (result: any) => {
        if (this.isDocUpload) {
          this.doc_url = result?.document;
          this.status = result?.status;
        } else if (this.isAgentUpdated) {
          this.dataList = result;
        }
        this.isGetting = false;
      },
      error => {
        this.isGetting = false;
      }
    )
  }

  getApplicationDetails() {

    let pax_id = this.uploadDetails.pax_id;
    this.api.getVisaDetails(pax_id).subscribe((res: any) => {
      console.log(res);

    })
  }

  consulateFormLoad() {
    this.dataLoader = true;
    let pax_id = this.uploadDetails.pax_id;
    this.api.getConsulateData(pax_id).subscribe((res: any) => {
      this.consulateFormData = res;
      this.dataLoader = false;
      console.log(res);

    }, (err: any) => {
      console.log(err);
      this.dataLoader = false;
    })
  }

  documentDataLoad() {
    this.dataLoader = true;
    let pax_id = this.uploadDetails.pax_id;
    this.api.getDocument(pax_id).subscribe((res: any) => {

      this.documentData = res;
      this.dataLoader = false;
      console.log(res);
    }, (err: any) => {
      console.log(err);
      this.dataLoader = false;
    })
  }

  Details() {
    this.dataLoader = true;
    let pax_id = this.uploadDetails.pax_id;
    this.api.getDetails(pax_id).subscribe((res: any) => {
      this.paxDetails = res;
      this.dataLoader = false;
      console.log(res);
    }, (err: any) => {
      console.log(err);
      this.dataLoader = false;
    })
  }

  schedule() {
    this.dataLoader = true;
    let pax_id = this.uploadDetails.pax_id;
    this.api.getShedule(pax_id).subscribe((res: any) => {
      this.scheduleData = res;
      this.dataLoader = false;
      console.log(res);
    }, (err: any) => {
      console.log(err);
      this.dataLoader = false;
    })
  }

  passportLoad(){
    this.dataLoader = true;
    let pax_id = this.uploadDetails.pax_id;
    this.api.getPassport(pax_id).subscribe((res: any) => {
      this.passportFront = res?.image_url_front;
      this.passportBack = res?.image_url_back;
      this.dataLoader = false;
      console.log(res);

    }, (err: any) => {
      console.log(err);
      this.dataLoader = false;
    })
  }

  otherDocs() {
    this.dataLoader = true;
    let pax_id = this.uploadDetails.pax_id;
    this.api.getOtherDocs(pax_id).subscribe((res: any) => {
      this.otherDocsList = res;
      this.dataLoader = false;
      console.log(res);
    }, (err: any) => {
      console.log(err);
      this.dataLoader = false;
    })
  }

}

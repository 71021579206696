<div class="container-fluid">
    <!-- start page title -->
    <app-page-title title="Consultant Page" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <app-preview-btn [page]="'consultant'"></app-preview-btn>
                    <!-- orientation="vertical"  -->
                    <ul ngbNav #justifiednavpills="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span>Header</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-header [page]="'consultant'"></app-header>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="12">
                            <a ngbNavLink>
                                <span>Consultancy&nbsp;Service</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-consultancy-service [page]="page"></app-consultancy-service>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="13">
                            <a ngbNavLink>
                                <span>VisaPro</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-consultant-visapro-description [page]="page"></app-consultant-visapro-description>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="6">
                            <a ngbNavLink>
                                <span>Application&nbsp;Steps</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-home-application-steps [page]="page"></app-home-application-steps>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="11">
                            <a ngbNavLink>
                                <span>Testimonials</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-testimonials [page]="page"></app-testimonials>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="justifiednavpills"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="d-flex justify-content-end" *ngIf="couponList?.length==0">
  <button class="btn btn-primary me-2" (click)="getCouponList()">View Coupon</button>
  <button class="btn btn-primary" (click)="generateCoupon()">Generate Coupon</button>
</div>
<div class="row">
  <div  class="col-md-3 mt-2" *ngFor="let coupon of couponList">
    <div [ngStyle]="{'background-color':coupon?.is_applied? '#808080':''}" class="card  px-3 py-3">{{coupon?.coupon_code}}</div>
  </div>
</div>
<div class="d-flex justify-content-center" *ngIf="couponList?.length!=pagination.count">
<button class="btn btn-primary" (click)="getCouponList('View')">
View more
</button>
</div>

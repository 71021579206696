<div class="modal-header">
    <h5 class="modal-title w-100 text-center">Upload Screenshot</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)">
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex">
                        <div class="w-75 m-auto">
                            <div (click)="triggerClick()" class="p-4 cpr">
                                <div class="dz-wrapper dropzone dz-multiple dz-clickable">
                                    <div class="dz-message">
                                        <div class="dz-text text-center">
                                            <div class="mb-3">
                                                <i class="display-4 text-muted uil uil-cloud-upload"></i>
                                            </div>Click to upload.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input #iconClick style="display: none;" type="file"
                            accept="image/png, image/gif, image/jpeg" 
                                (change)="handleIcon($event.target.files)" class="form-control"
                                placeholder="Upload Icon">
                            <div class="w-100 text-center">
                                <a [href]="image_url" target="_blank"><img class="ms-1" height="100"
                                        [src]="imagePdf"></a>
                                <p class="mt-3" *ngIf="status">Status: <b
                                        [ngClass]="{'text-success': status=='ACCEPTED', 'text-danger': status=='REJECTED'}">{{status}}</b>
                                </p>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="progress>0">
                        <div class="mt-2 progress-bar">
                            <div class="progress" [style.width.%]="progress">
                                {{progress}}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            Issue : {{uploadedData.raised_issues}}
        </div>
    </div>
</div>
<div class="modal-footer text-end">
    <button class="btn btn-primary me-2" (click)="uploadConsulate()" [disabled]="!image_url">
        <i [ngClass]="{'fa fa-spin fa-pulse fa-spinner': isSubmitting, 'fa fa-upload': !isSubmitting}"></i>
        Upload</button>
    <button class="btn btn-secondary" (click)="closeModal(null)">Cancel</button>
</div>
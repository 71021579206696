<div class="modal-header">
  <h5 class="modal-title">Add Country</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">

  <ng-wizard [config]="config">

    <ng-wizard-step [description]="'Country'" [canEnter]="isValidTypeBoolean">
      <div class="tab-pane" id="country-details">
        <app-country-details [fromAdd]="fromAdd" [countriesList]="fromParent.countries"
          (showNextStep)="showNextStep($event)"></app-country-details>
      </div>
    </ng-wizard-step>

    <ng-wizard-step [description]="'Jurisdiction'">
      <div class="tab-pane" id="jurisdiction-details">
        <app-jurisdiction-details [fromAdd]="fromAdd" (showNextStep)="showNextStep($event)"></app-jurisdiction-details>
      </div>
    </ng-wizard-step>

    <ng-wizard-step [description]="'Category Types'" [canEnter]="isValidFunctionReturnsObservable.bind(this)"
      [canExit]="isValidFunctionReturnsBoolean.bind(this)">
      <div class="tab-pane" id="category">
        <app-category-details [fromAdd]="fromAdd" (showNextStep)="showNextStep($event)"></app-category-details>
      </div>
    </ng-wizard-step>

    <ng-wizard-step [description]="'Age Groups'" [canEnter]="isValidFunctionReturnsObservable.bind(this)"
      [canExit]="isValidFunctionReturnsBoolean.bind(this)">
      <div class="tab-pane" id="age-groups">
        <app-age-groups [fromAdd]="fromAdd" (showNextStep)="showNextStep($event)"></app-age-groups>
      </div>
    </ng-wizard-step>
  
    <!-- <ng-wizard-step [description]="'Term Details'" [canEnter]="isValidFunctionReturnsObservable.bind(this)"
      [canExit]="isValidFunctionReturnsBoolean.bind(this)">
      <div class="tab-pane" id="age-groups">
        <app-term-details></app-term-details>
      </div>
    </ng-wizard-step> -->

    <!-- <ng-wizard-step [description]="'Fee Details'" [canEnter]="isValidFunctionReturnsObservable.bind(this)"
      [canExit]="isValidFunctionReturnsBoolean.bind(this)">
      <div class="tab-pane" id="fee-details">
        <app-fee-details></app-fee-details>
      </div>
    </ng-wizard-step> -->

    <ng-wizard-step [description]="'Relevant Products'">
      <div class="tab-pane" id="relevant-products">
        <app-relevant-products [fromAdd]="fromAdd" [isAdd]="true"></app-relevant-products>
      </div>
    </ng-wizard-step>

  </ng-wizard>

</div>
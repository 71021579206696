import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }

  getIntegrationsList(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/integration/`).pipe(retry(2));
  }

  setIntegrationsList(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/integration/`, data)
  }

  updateIntegrationsList(id,data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/integration/${id}/`, data)
  }

  deleteIntegrationsList(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/integration/${id}/`)
  }

  ariseCouponGenerate(){
    return this.http.post(`${environment.apiUrl}/arise-coupon-generate/`,{})
  }

  getCouponList(offset:number){
    return this.http.get(`${environment.apiUrl}/arise-coupon-generate/?limit=30&offset=${offset}`)
  }
}

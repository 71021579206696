<div class="container">
    <div class="w-100">
        <button class="btn btn-primary mt-3" (click)="isDetails=!isDetails;">Show {{isDetails?'Pricing':'Details'}}</button>
    </div>
    <div *ngIf="isDetails">
        <app-sku-details [country]="country" [page]="page" [product]="product"></app-sku-details>
    </div>
    <div *ngIf="!isDetails">
        <app-sku-pricing [country]="country" [page]="page" [product]="product"></app-sku-pricing>
    </div>
    
</div>
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private http: HttpClient) { }

  getTermsCondition(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/cms-admin/terms/terms/`).pipe(retry(2));
  }

  editTermsCondition(id,data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/terms/terms/${id}/`, data);
  }

  setTermsCondition(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/terms/terms/`, data);
  }

  getPrivacyPolicy(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/cms-admin/privacy/privacy/`).pipe(retry(2));
  }

  editPrivacyPolicy(id,data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/privacy/privacy/${id}/`, data);
  }

  setPrivacyPolicy(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/privacy/privacy/`, data);
  }

  getFooter(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/cms-admin/footer/footer/`).pipe(retry(2));
  }

  getFooterBusiness(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/cms-admin/footer/category-footer/?name=Business Visa`).pipe(retry(2));
  }

  getFooterTourist(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/cms-admin/footer/category-footer/?name=Tourist Visa`).pipe(retry(2));
  }

  editFooter(id,data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/footer/category-footer/${id}/`, data);
  }

  editFooterPosition(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/footer/category-footer/position/`, data);
  }

  userAgreement(): Observable<any>{
    return this.http.get(`${environment.apiUrl}/cms-admin/useragreement/user-agreement/`);
    
  }

  setUserAgreement(data:any):Observable<any>{
    return this.http.post(`${environment.apiUrl}/cms-admin/useragreement/user-agreement/`,data);
  }

}

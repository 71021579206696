<div class="container-fluid">
    <!-- start page title -->
    <app-page-title title="About Us Page" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                  <app-preview-btn [page]="page"></app-preview-btn>
                    <!-- orientation="vertical"  -->
                    <ul ngbNav #justifiednavpills="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span>Header</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-header-about [page]="page"></app-header-about>
                            </ng-template>
                        </li>
                          <li [ngbNavItem]="3">
                            <a ngbNavLink>
                              <span>About</span>
                            </a>
                            <ng-template ngbNavContent>
                              <app-about-about [page]="page"></app-about-about>
                            </ng-template>
                          </li>
                          <li [ngbNavItem]="6">
                            <a ngbNavLink>
                              <span>Crew</span>
                            </a>
                            <ng-template ngbNavContent>
                              <app-about-statistics [page]="page"></app-about-statistics>
                            </ng-template>
                          </li>
                          <li [ngbNavItem]="4">
                            <a ngbNavLink>
                              <span>Info</span>
                            </a>
                            <ng-template ngbNavContent>
                              <app-about-infos [page]="page"></app-about-infos>
                            </ng-template>
                          </li>
                        <li [ngbNavItem]="2">
                          <a ngbNavLink>
                            <span>Side&nbsp;Info</span>
                          </a>
                          <ng-template ngbNavContent>
                            <app-header-side-info [page]="page"></app-header-side-info>
                          </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="justifiednavpills"></div>
                </div>
            </div>
        </div>
    </div>
</div>
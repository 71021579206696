<div class="container-fluid set-minwh">
    <app-page-title [title]="title" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="my-3 row">
        <div class="offset-md-8 col-md-4">
            <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
                bindValue="id" [(ngModel)]="country"  [clearable]="false" (change)="getCategories(country)"></ng-select>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 offset-lg-3" *ngIf="!selected && categoryList.length == 0 && !isLoading">
            <div class="border shadow card  text-center p-4">
                Select Country to view Category
            </div>
        </div>
        <div class="col-lg-4 offset-lg-4" *ngIf="isLoading">
            <div class="card text-center p-4">
                <i class="fa fa-spin fa-spinner fa-pulse fa-3x"></i>
            </div>
        </div>
        <!-- <div class="col-lg-12 card" [hidden]="!selected">
            <app-category-details></app-category-details>
        </div> -->
        <div  [hidden]="!selected">
            <app-category-details></app-category-details>
        </div>
    </div>
</div>
<div class="mt-2">
    <div class="d-flex">
        <button class="btn btn-success me-2" *ngIf="!siteMapData" (click)="getSiteMap()">Create Sitemap</button>
    </div>
    <div class="d-flex justify-content-end" *ngIf="siteMapData">
        <button class="btn btn-success me-2" (click)="downloadXml()">Download Sitemap</button>
        <button  class="btn btn-success " (click)="publishXml()">Publish</button>
    </div>
   
    <pre class="mt-2" *ngIf="!isLoading">
        {{siteMapData| xmlFormatter}}
    </pre>
    <div *ngIf="isLoading" class="d-flex mt-5">
        <i class="fa fa-spin fa-spinner fa-pulse fa-5x m-auto"></i>
    </div>
</div>

    

import { Component, inject } from '@angular/core';
import { NgbActiveModal, NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent {
	calendar = inject(NgbCalendar);

	hoveredDate: NgbDate | null = null;
	// fromDate: NgbDate = this.calendar.getToday();
	// toDate: NgbDate | null = this.calendar.getNext(this.fromDate, 'd', 10);
  fromDate: NgbDate | null =null;
	toDate: NgbDate | null = null;
  constructor( private activeModal: NgbActiveModal){
	
    
  }

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
			this.toDate = date;
		} else {
			this.toDate = null;
			this.fromDate = date;
		}
	}

  close() {
    this.activeModal.close(null);
  }

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}

	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}

	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}
  selectDate(){
    const selectedDates = {
      fromDate:
        this.fromDate.year +
        '-' +
        this.fromDate.month +
        '-' +
        this.fromDate.day,
      toDate:
        this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day,
    };
    if(selectedDates) this.activeModal.close(selectedDates);
  }
}

<div class="container">
  <form [formGroup]="firstForm" *ngIf="priceData">
    <div class="mt-2 col-md-12">
      <label class="col-form-label">Name</label>
      <input
        type="text"
        class="form-control"
        placeholder="Name"
        formControlName="name"
      />
    </div>
    <div class="mt-2 col-md-12">
      <label class="col-form-label">Info</label>
      <input
        type="text"
        class="form-control"
        placeholder="Sub Name"
        formControlName="sub_name"
      />
    </div>
    <div class="mt-2 col-md-6">
      <label class="col-form-label">Currency&nbsp;Symbol</label>
      <input
        type="text"
        class="form-control"
        placeholder="Currency Symbol"
        formControlName="currency_symbol"
      />
    </div>
    <div class="mt-2 col-md-12">
      <label class="col-form-label">Price</label>
      <input
        type="text"
        class="form-control"
        placeholder="Price"
        formControlName="price"
      />
    </div>
  </form>

  <form [formGroup]="pricingForm" (ngSubmit)="onSubmit(pricingForm)">
    <div class="row" cdkDropList (cdkDropListDropped)="drop($event)">
      <div
        class="col-md-4 mt-2"
        formArrayName="price"
        *ngFor="let item of price().controls; let i = index"
        cdkDrag
      >
        <div class="example-handle" cdkDragHandle></div>
        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
        <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
          <div class="row">
            <!-- <div class="mt-2 col-md-12">
              <label class="col-form-label">Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Name"
                formControlName="name"
                readonly
              />
            </div> -->
            <!-- <div class="mt-2 col-md-12">
              <label class="col-form-label">Info</label>
              <input
                type="text"
                class="form-control"
                placeholder="Sub Name"
                formControlName="sub_name"
                (change)="dataChanged(i)"
                [ngClass]="{
                  invalid: item.get('sub_name').invalid && isSubmitted
                }"
                readonly
              />
            </div> -->
            <!-- <div class="mt-2 col-md-6">
              <label class="col-form-label">Currency&nbsp;Symbol</label>
              <input
                type="text"
                class="form-control"
                placeholder="Currency Symbol"
                formControlName="currency_symbol"
                (change)="dataChanged(i)"
                [ngClass]="{
                  invalid: item.get('currency_symbol').invalid && isSubmitted
                }"
                
              />
            </div> -->
            <!-- <div class="mt-2 col-md-6">
              <label class="col-form-label">Price</label>
              <input
                type="number"
                min="0"
                class="form-control"
                placeholder="price"
                formControlName="price"
                (change)="dataChanged(i)"
                [ngClass]="{
                  invalid: item.get('price').invalid && isSubmitted
                }"
                readonly
              />
            </div> -->

            <div class="mt-2 col-md-6">
              <label class="col-form-label">Consultant Fee</label>
              <input
                type="number"
                min="0"
                class="form-control"
                placeholder="Consultant Fee"
                formControlName="consultant_fee"
                (change)="dataChanged(i)"
                [ngClass]="{
                  invalid: item.get('consultant_fee').invalid && isSubmitted
                }"
                readonly
              />
            </div>
            <!-- <div class="mt-2 col-md-6">
              <label class="col-form-label">Tax</label>
              <input
                type="number"
                min="0"
                class="form-control"
                placeholder="Tax"
                formControlName="tax"
                (change)="dataChanged(i)"
                [ngClass]="{ invalid: item.get('tax').invalid && isSubmitted }"
                readonly
              />
            </div> -->
          </div>
          <div class="row" formArrayName="consultant_description">
            <div
              class="mt-2 col-md-12"
              *ngFor="
                let description of item.get('consultant_description').controls;
                let j = index
              "
            >
              <div class="position-relative" [formGroupName]="j">
                <label class="col-form-label">Consultant Description</label>
                <input
                  type="text"
                  min="0"
                  class="form-control"
                  placeholder="DIY Fee"
                  formControlName="description"
                  (change)="dataChanged(i)"
                />
                <a
                  href="javascript:void(0)"
                  class="ms-3 close-btn"
                  (click)="
                    deleteDescription(item.controls.consultant_description, j)
                  "
                  ><i class="uil-times fa-2x"></i
                ></a>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-info mt-3 col-md-12"
              (click)="addDescription(i)"
            >
              Add Decsription
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4 mt-2" *ngIf="priceData">
        <app-sku-secondform
          (newItemEvent)="addItem($event)"
          [item]="priceData"
          [product]="product"
        ></app-sku-secondform>
      </div>

      <div class="col-md-4 p-5">
        <div class="row">
          <!-- <button *ngIf="checkPermission('content_management_product_create') && price().controls.length<1" class="btn btn-primary mt-1 col-md-12" type="button" (click)="addprice()"><i
                            class="uil-plus"></i> Add</button> -->
          <button
            *ngIf="
              checkPermission('content_management_product_create') &&
              checkPermission('content_management_product_edit')
            "
            class="btn btn-info mt-1 col-md-12"
            type="submit"
            [disabled]="isUpdating"
          >
            <i
              [ngClass]="{
                'fa fa-pulse fa-spin fa-spinner': isUpdating,
                'bx bx-save': !isUpdating
              }"
            ></i>
            Save All
          </button>
          <app-publish-btn
            *ngIf="checkPermission('content_management_product_publish')"
            class="col-md-12 p-0 mt-1"
            [publish]="'ProductPrice'"
            [page]="'product'"
          ></app-publish-btn>
        </div>
      </div>
    </div>
  </form>
</div>

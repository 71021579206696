<div class="container-fluid">
    <!-- start page title -->
    <app-page-title title="Testimonial Page" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <app-preview-btn [page]="page"></app-preview-btn>
                    <!-- orientation="vertical"  -->
                    <ul ngbNav #justifiednavpills="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span>Header</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-header [page]="page"></app-header>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span>Testimonials</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-testimonials [page]="page"></app-testimonials>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="justifiednavpills"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <form class="row" [formGroup]="headerForm" (ngSubmit)="onSubmit(headerForm)">
        <div class="col-md-8 offset-md-2 p-4 mt-2">
            <div class="border shadow p-4">
                <div class="mt-2 row">
                    <div class="col-md-12 row">
                        <label class="col-md-12 col-form-label">Headings</label>
                        <div class="col-md-12">
                          <div formArrayName="headings" *ngFor="let item of heads().controls; let i = index;" cdkDrag>
                          <div class="example-handle" cdkDragHandle></div>
                          <div [formGroupName]="i" class="mt-2 d-flex">
                            <input type="text" class="form-control" placeholder="Enter Heading" formControlName="text" [ngClass]="{'invalid': item.get('text').invalid && isSubmitted}">
                            <a *ngIf="heads().controls.length>1" class="btn btn-sm btn-outline text-danger float-end cpr mt-2" (click)="removeheads(i)"><i class="fa fa-times"></i></a>
                          </div>
                          </div>
                          <a class="btn btn-sm btn-primary float-end cpr mt-2" (click)="addheads()"><i class="fa fa-plus"></i></a>
                        </div>
                      </div>
                    <div class="col-md-6">
                        <label class="col-form-label">Sub Heading</label>
                        <input type="text" class="form-control" placeholder="Enter Sub Heading"
                            formControlName="sub_heading" [ngClass]="{'invalid': headerForm.get('sub_heading').invalid && isSubmitted}">
                    </div>
                </div>
                <div class="row">
                    <div class="mt-2 col-md-12">
                        <label class="col-form-label">Image URL</label>
                        <div class="d-flex">
                            <input type="file" accept="image/*" (change)="handleIcon($event.target.files)" class="form-control"
                                placeholder="Upload Icon">
                            <a [href]="headerForm.value.banner_image_url" target="_blank"><img class="ms-1" height="40"
                                    [src]="headerForm.value.banner_image_url"></a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="mt-2 col-md-6">
                        <label class="col-form-label">Amount</label>
                        <input type="number" class="form-control" placeholder="Enter Amount"
                            formControlName="amount" [ngClass]="{'invalid': headerForm.get('amount').invalid && isSubmitted}">
                    </div>
                    <div class="mt-2 col-md-6">
                        <label class="col-form-label">Symbol</label>
                        <input type="text" class="form-control" placeholder="Enter Symbol"
                            formControlName="currency_symbol" [ngClass]="{'invalid': headerForm.get('currency_symbol').invalid && isSubmitted}">
                    </div>
                    <div class="mt-2 col-md-6">
                        <label class="col-form-label">Price Label</label>
                        <input type="text" class="form-control" placeholder="Enter Label"
                            formControlName="price_label" [ngClass]="{'invalid': headerForm.get('price_label').invalid && isSubmitted}">
                    </div>
                </div>
                <div class="mt-2 row">
                    <div class="col-md-12">
                        <button *ngIf="(page=='country' && checkPermission('content_management_country_create'))&&(page=='country' && checkPermission('content_management_country_edit'))" class="btn btn-info float-end"><i *ngIf="isUpdating" class="fa fa-spin fa-spinner fa-pulse"></i> Save Changes</button>
                        <app-publish-btn *ngIf="(page=='country' && checkPermission('content_management_country_publish'))" class="float-end me-2" [publish]="publishPage" [page]="page"></app-publish-btn>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
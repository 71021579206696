import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "src/app/core/services/shared.service";
import { FaqSeoService } from "src/app/core/services/faq-seo.service";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { PermissionService } from "src/app/core/services/permision.service";

@Component({
  selector: "app-seo-section",
  templateUrl: "./seo-section.component.html",
  styleUrls: ["./seo-section.component.scss"],
})
export class SeoSectionComponent implements OnInit {
  seoForm: FormGroup;
  hasChanges = false;
  isUpdating = false;
  isUploading = [false];
  updateId: number;
  isNew = false;

  robotIndex = false;
  robotfollow = false;

  @Input() country;
  @Input() category;
  @Input() product;
  @Input() page;

  fileToUpload: File | [null] = [null];
  publishPage: string;
  progress: number[] = [0];

  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService,
    private api: FaqSeoService,
    private toastr: ToastrService,
    public permission: PermissionService
  ) { }

  ngOnInit(): void {
    this.resetForm();
    switch (this.page) {
      case 'home': this.publishPage = 'HomeSeo';
        this.getData();
        break;
      case 'visa_home': this.publishPage = 'VisaHomeSeo';
        this.getData();
        break;
      case 'country': this.publishPage = 'CountrySeo';
        if (this.sharedService.selectedCmsCountryId) this.getData(this.sharedService.selectedCmsCountryId);
        break;
      case 'category': this.publishPage = 'CategorySeo';
        if (this.sharedService.selectedCmsCountryId && this.sharedService.selectedCmsCategoryId) this.getData(this.sharedService.selectedCmsCountryId, this.sharedService.selectedCmsCategoryId);
        break;
      case 'product': this.publishPage = 'ProductSeo';
        if (this.sharedService.selectedCmsCountryId && this.sharedService.selectedCmsCategoryId && this.sharedService.selectedCmsProductId) this.getData(this.sharedService.selectedCmsCountryId, this.sharedService.selectedCmsCategoryId, this.sharedService.selectedCmsProductId);
        break;
      case 'consultant_page': this.publishPage = 'ConsultantPageSeo';
        this.getData();
        break;
      case 'diy_assistance': this.publishPage = 'DiyAssistanceSeo';
        this.getData();
        break;
      case 'about_us': this.publishPage = 'AboutUsSeo';
        this.getData();
        break;
      case 'testimonials': this.publishPage = 'TestimonialSeo';
        this.getData();
        break;
      case 'faq': this.publishPage = 'FaqSeo';
        this.getData();
        break;
      case 'faq_country': this.publishPage = 'FaqCountrySeo';
        if (this.sharedService.selectedCmsCountryId) this.getData(this.sharedService.selectedCmsCountryId);
        break;
      default: break;
    }
  }

  dataOnSelect() {
    switch (this.page) {
      case "country": if (this.sharedService.selectedCmsCountryId) this.getData(this.sharedService.selectedCmsCountryId); break;
      case "category": if (this.sharedService.selectedCmsCountryId && this.sharedService.selectedCmsCategoryId) this.getData(this.sharedService.selectedCmsCountryId, this.sharedService.selectedCmsCategoryId); break;
      case "product": if (this.sharedService.selectedCmsCountryId && this.sharedService.selectedCmsCategoryId && this.sharedService.selectedCmsProductId) this.getData(this.sharedService.selectedCmsCountryId, this.sharedService.selectedCmsCategoryId, this.sharedService.selectedCmsProductId); break;
      case "faq_country": if (this.sharedService.selectedCmsCountryId) this.getData(this.sharedService.selectedCmsCountryId); break;
      default: this.getData(); break;
    }
  }

  resetForm(data?) {
    let id = 0;
    switch (this.page) {
      case "country":
        id = this.country
        break;
      case "category":
        id = this.category
        break;
      case "product":
        id = this.product
        break;
      case "faq_country":
        id = this.country
        break;
      default:
        id = -1
        break;
    }

    this.seoForm = this.fb.group({
      admin_seo_key: [this.page],
      admin_seo_id: [id],
      title: [data ? data.title : "", [Validators.required]],
      description: [data ? data.description : ""],
      canonical_url: [data ? data.canonical_url : ""],
      robots_index: [data?.robots_index ? data.robots_index : false],
      robots_follow: [data?.robots_follow ? data.robots_follow : false],
      og_title: [data ? data.og_title : ""],
      og_description: [data ? data.og_description : ""],
      og_image_url: [data ? data.og_image_url : ""],
      og_image_secure_url: [data ? data.og_image_secure_url : ""],
      og_image_type: [data ? data.og_image_type : ""],
      og_image_width: [data ? data.og_image_width : ""],
      og_image_height: [data ? data.og_image_height : ""],
      og_type: [data ? data.og_type : ""],
      og_url: [data ? data.og_url : ""],
      og_site_name: [data ? data.og_site_name : ""],
      og_locale: [data ? data.og_locale : ""],
      twitter_card_type: [data ? data.twitter_card_type : ""],
      twitter_creator: [data ? data.twitter_creator : ""],
      twitter_site: [data ? data.twitter_site : ""],
      twitter_title: [data ? data.twitter_title : ""],
      twitter_description: [data ? data.twitter_description : ""],
      twitter_image_url: [data ? data.twitter_image_url : ""],
      twitter_image_alt: [data ? data.twitter_image_alt : ""],
      facebook_og_type: [data ? data.facebook_og_type : ""],
      facebook_og_url: [data ? data.facebook_og_url : ""],
      facebook_og_title: [data ? data.facebook_og_title : ""],
      facebook_og_description: [data ? data.facebook_og_description : ""],
      facebook_og_image_url: [data ? data.facebook_og_image_url : ""],
      facebook_og_image_secure_url: [
        data ? data.facebook_og_image_secure_url : "",
      ],
      facebook_og_image_type: [data ? data.facebook_og_image_type : ""],
      facebook_og_image_width: [data ? data.facebook_og_image_width : ""],
      facebook_og_image_height: [data ? data.facebook_og_image_height : ""],
      instagram_title: [data ? data.instagram_title : ""],
      instagram_image_url: [data ? data.instagram_image_url : ""],
      instagram_image_width: [data ? data.instagram_image_width : ""],
      instagram_image_height: [data ? data.instagram_image_height : ""],

      schema: [data ? data.schema : ""],
      schema_multiple_files: new FormArray([]),
      h1: [data ? data.h1 : ""],
      h2: [data ? data.h2 : ""],
      keywords: [data ? data.keywords : "", [Validators.required]],
      keywords_data: this.fb.array([]),
    });

    if (data?.schema_multiple_files != undefined) {
      let files = data?.schema_multiple_files;
      files.forEach(ao => {
        (this.seoForm.get('schema_multiple_files') as FormArray).push(
          this.fb.group({
            schema_file: [ao.schema_file, [Validators.required]],
          })
        );
      });
    } else {
      this.addfiles();
    }
    if (data) {
      data.keywords?.forEach((ao) => {
        (this.seoForm.get("keywords_data") as FormArray).push(
          this.fb.group({
            keywords: [ao],
          })
        );
      });
    }
    this.onChanges();
  }

  files(): FormArray {
    return this.seoForm.get("schema_multiple_files") as FormArray
  }

  newfiles(): FormGroup {
    return this.fb.group({
      schema_file: ['', [Validators.required]]
    })
  }

  addfiles() {
    this.files().push(this.newfiles());
  }

  removefiles(i: number) {
    this.files().removeAt(i);
  }


  get keyword(): FormArray {
    return this.seoForm.get("keywords_data") as FormArray;
  }

  newKeyword(): FormGroup {
    return this.fb.group({
      keywords: "",
    });
  }

  addKeyword() {
    this.keyword.push(this.newKeyword());
  }

  removeKeyword(i: number) {
    this.hasChanges = true;
    this.keyword.removeAt(i);
  }

  onChanges(): void {
    this.seoForm.valueChanges.subscribe(() => {
      Object.keys(this.seoForm.controls).map((key) => {
        if (this.seoForm.get(key).dirty) this.hasChanges = true;
      });
    });
  }

  onSubmit(form) {
    if (true) {
      let keyword: any = [];
      form.value.keywords_data.forEach((ae) => {
        if (!ae.keywords) {
          keyword.push('/');
        } else {
          keyword.push(ae.keywords);
        }


      });
      form.value.keywords = keyword;
      let data_to_send = JSON.parse(JSON.stringify(form.value));
      delete data_to_send['keywords_data'];
      if (this.isNew) {
        this.isUpdating = true;
        this.api.setSeoDatas(data_to_send).subscribe(
          () => {
            // this.resetForm(result);
            this.toastr.success("Content Added Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
            this.isNew = false;
            this.dataOnSelect();
          },
          () => {
            this.isUpdating = false;
          }
        );
      } else {
        this.isUpdating = true;
        this.api.editSeoDatas(this.updateId, data_to_send).subscribe(
          () => {
            // this.resetForm(result);
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
            this.dataOnSelect();
          },
          () => {
            this.isUpdating = false;
          }
        );
      }
    } else {
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  getData(country?, category?, product?) {
    this.country = country;
    this.category = category;
    this.product = product;
    if (this.page && country && category && product) {
      this.api.getSeoDatasProduct(this.page, country, category, product).subscribe(
        (result: any) => {
          this.resetForm(result);
          if (Object.keys(result).length === 0) this.isNew = true;
          this.updateId = result.id;
        });
    } else if (this.page && this.country && this.category) {
      this.api.getSeoDatasCategory(this.page, country, category).subscribe(
        (result: any) => {
          this.resetForm(result);
          if (Object.keys(result).length === 0) this.isNew = true;
          this.updateId = result.id;
        });
    } else if (this.page && this.country) {
      this.api.getSeoDatasCountry(this.page, country).subscribe(
        (result: any) => {
          this.resetForm(result);
          if (Object.keys(result).length === 0) this.isNew = true;
          this.updateId = result.id;
        });
    } else if (this.page != 'faq_country') {
      this.api.getSeoDatas(this.page).subscribe(
        (result: any) => {
          this.resetForm(result);
          if (Object.keys(result).length === 0) this.isNew = true;
          this.updateId = result.id;
        });
    }
  }

  fileUpload = (i) => {
    let element: HTMLElement = document.getElementById(`fileupload${i}`) as HTMLElement;
    element.click();
  }

  getFile = (files: FileList, i) => {
    this.hasChanges = true;
    this.isUploading[i] = true;
    const formData: FormData = new FormData();
    formData.append('document', files.item(0));
    this.sharedService.uploadFile(formData).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress[i] = Math.round(100 * event.loaded / event.total);
            break;
          case HttpEventType.Response:
            this.seoForm.get(['schema_multiple_files', i, 'schema_file']).setValue(event.body.document);
            this.progress[i] = 0;
            this.isUploading[i] = false;
            break;
        }
      })
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

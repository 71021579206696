import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { VisaManageService } from "src/app/core/services/visa-manage.service";

@Component({
  selector: "app-user-verification-modal",
  templateUrl: "./user-verification-modal.component.html",
  styleUrls: ["./user-verification-modal.component.scss"],
})
export class UserVerificationModalComponent {
  @Input() paxId;
  statusResponse:any
  constructor(
    private actvieModal: NgbActiveModal,
    private visaMangment: VisaManageService
  ) {}

  ngOnInit(){
    this.visaMangment.checkStatus(this.paxId).subscribe(res=>{
      this.statusResponse=res
    })
  }
  closeModal() {
    this.actvieModal.close();
  }

  acceptUser() {
    this.visaMangment.acceptUser(this.paxId).subscribe((res) => {
      if(res){
        this.closeModal()
      }
    });
  }

  rejectUser(){
    this.visaMangment.rejectUser(this.paxId).subscribe((res) => {
      if(res){
        this.closeModal()
      }
    });
  }

 
}

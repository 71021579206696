import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UploadGalleryComponent } from "src/app/shared/ui/upload-gallery/upload-gallery.component";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { PermissionService } from "src/app/core/services/permision.service";
import { MediaService } from "src/app/core/services/media.service";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from "@angular/router";
@Component({
  selector: "app-seo-description",
  templateUrl: "./seo-description.component.html",
  styleUrls: ["./seo-description.component.scss"],
})
export class SeoDescriptionComponent {
  public Editor = ClassicEditor;
  mediaForm: FormGroup;
  hasChanges = false;
  allMedias: any;
  isSubmitted: boolean = false;
  mediId: number;
  page: string;
  isUpdating: boolean = false;
  descriptionData:any
  constructor(
    private fb: FormBuilder,
    public permission: PermissionService,
    private api: MediaService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private router:Router
  ) {}

  ngOnInit(): void {
    this.getParams();
    this.getUseFullMedia();
    this.resetForm();
    this.getDescription();
   
  }

  getDescription() {
    this.api.getSeoDescription(this.mediId).subscribe((res) => {
      this.descriptionData=res;
      this.resetForm(res)
    });
  }

  getUseFullMedia() {
    if (this.page == "news") {
      this.api.getNewsUsefulMedia(2).subscribe((result: any) => {
        this.allMedias = result;
      });
    } else if (this.page == "blog") {
      this.api.getUsefulMedia(1).subscribe((result: any) => {
        this.allMedias = result;
      });
    }
  }
  isStarredChange() {
    this.mediaForm.controls["is_updated"].setValue(true);
    this.mediaForm.controls["is_starred"].setValue(
      !this.mediaForm.value.is_starred
    );
  }


  dataChanged() {
   
    this.mediaForm.controls["is_updated"].setValue(true);
  }


  getParams() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.mediId = res?.id;
      this.page = res?.page;
    });
  }

  resetForm(data?: any) {
    this.mediaForm = this.fb.group({
      id: [this.mediId, [Validators.required]],
      author: [data?.author_name ? data?.author_name : "", [Validators.required]],
      heading: [data?.heading ? data?.heading : "", [Validators.required]],
      description: [
        data?.description ? data?.description : "",
        [Validators.required],
      ],
      date_published: [
        data?.date_published ? data?.date_published : "",
        [Validators.required],
      ],
      //link_url: [data?.link_url ? data.link_url : "", [Validators.required]],
      banner_image_url: [
        data?.banner_image_url ? data.banner_image_url : "",
        [Validators.required],
      ],
      media_type: [2, [Validators.required]],
      related_medias: [
        data?.related_medias ? data.related_medias : "",
        [Validators.required],
      ],
      is_starred: [
        data?.is_starred ? data?.is_starred : false,
        [Validators.required],
      ],
      is_deleted: [false],
      is_updated: [false],
    }); 
    this.mediaForm.get('description').valueChanges.subscribe(newValue => {
      this.dataChanged();
    });
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  openUploadModal(field) {
    const modalRef = this.modalService.open(UploadGalleryComponent, {
      size: "xl",
      scrollable: true,
      windowClass: "modal-big-lg",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.mediaForm.controls[field].setValue(result.image);
        this.mediaForm.get('is_updated').setValue(true);
      }
    });
  }

  backtoList(){
    if(this.page == "news"){
      this.router.navigate(['/content/news-specific'])
    }else{
      this.router.navigate(['/content/specific'])
    }
    
  }
  

  onSubmit(form) {
   
  
    if (this.page == "news") {
      if (form.valid) {
        this.isUpdating = true;
        form.value.author=this.descriptionData.author
        const data = [form.value];
        this.api.setNewsUsefulMedia(data).subscribe(
          () => {
            this.toastr.success("Content Updated Successfully!");
            this.mediaForm.controls["is_updated"].setValue(false);
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        );
      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }
    } else if (this.page == "blog") {
      if (form.valid) {
        this.isUpdating = true;
        form.value.author=this.descriptionData.author
        const data = [form.value];
        this.api.setUsefulMedia(data).subscribe(
          () => {
            this.toastr.success("Content Updated Successfully!");
            this.mediaForm.controls["is_updated"].setValue(false);
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        );
      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }
    }
  }
}

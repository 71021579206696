import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { CountryManagementService } from 'src/app/core/services/country-management.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';
import { ContactUsService } from 'src/app/core/services/contact-us-page.service';
// pages: country, category, product, about-us
@Component({
  selector: 'app-contact-us-side-info',
  templateUrl: './contact-us-side-info.component.html',
  styleUrls: ['./contact-us-side-info.component.scss']
})
export class ContactUsSideInfoComponent implements OnInit {

  sideForm: FormGroup;
  hasChanges = false;
  @Input() country;
  @Input() category;
  @Input() page;
  @Input() product;
  isSubmitted = false;

  isUpdating = false;
  deletedsideinfo = [];
  publishPage: string;
  isSuggestion: boolean;

  constructor(private fb: FormBuilder, private countryApi: CountryManagementService,
    private toastr: ToastrService, public permission: PermissionService,
    public contactUsService: ContactUsService) { }

  ngOnInit(): void {

    this.resetForm();
    this.getSideInfos();
    switch (this.page) {
      case 'country': this.publishPage = 'SideInfoCountry'; break;
      case 'category': this.publishPage = 'SideInfoCategory'; break;
      case 'product': this.publishPage = 'SideInfoProduct'; break;
      case 'about-us': this.publishPage = 'AboutUsSideInfo'; break;
      default: break;
    }

  }

  resetForm() {
    this.sideForm = this.fb.group({
      sideinfo: this.fb.array([])
    });
  }

  onSubmit(form) {

    if (form.valid) {
      this.isUpdating = true;
      this.deletedsideinfo.forEach(ae => {
        ae.is_updated = false;
        form.value.sideinfo.push(ae);
      })

      this.contactUsService.postSideInfo(form.value.sideinfo).subscribe(
        () => {
          this.toastr.success("Content Updated Successfully!");
          this.getSideInfos();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        });
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  onChanges(): void {
    this.sideForm.valueChanges.subscribe(() => {
      Object.keys(this.sideForm.controls).map((key) => {
        if (this.sideForm.get(key).dirty) this.hasChanges = true;
      })
    });
  }

  sideinfo(): FormArray {
    return this.sideForm.get("sideinfo") as FormArray
  }

  newsideinfo(): FormGroup {
    return this.fb.group({
      heading: ['', [Validators.required]],
      sub_heading: ['', [Validators.required]],
      phone_no: [''],
      address: [''],
      // country: [this.country],
      is_updated: [false],
    })
  }

  addsideinfo() {
    this.sideinfo().push(this.newsideinfo());
  }

  removesideinfo(i: number) {
    this.isSubmitted = false;
    this.sideForm.value.sideinfo[i].is_deleted = true;
    if (this.sideForm.value.sideinfo[i].hasOwnProperty("id")) this.deletedsideinfo.push(this.sideForm.value.sideinfo[i]);
    this.sideinfo().removeAt(i);
  }

  getSideInfos() {
    this.contactUsService.getSideInfo().subscribe(
      (result: any) => {
        this.setData(result);
      })
  }

  getCountryData() {
    this.countryApi.getSideInfo(this.country).subscribe(
      (result: any) => {
        this.setData(result);
        if (result.length > 0 && (this.page == 'category' || this.page == 'product')) this.toastr.warning("Showing suggestion from country", " Save to reflect changes")
      })
  }

  dataChanged(i) {
    this.sideForm.value.sideinfo[i].is_updated = true;
  }

  setData(result) {
    this.sideForm = this.fb.group({
      sideinfo: new FormArray([]),
    });
    result.forEach(ao => {
      (this.sideForm.get('sideinfo') as FormArray).push(
        this.fb.group({
          id: [this.isSuggestion ? null : ao.id],
          heading: [ao.heading, [Validators.required]],
          sub_heading: [ao.sub_heading, [Validators.required]],
          phone_no: [ao.phone_no],
          address: [ao.address, [Validators.required]],
          is_updated: [this.isSuggestion ? true : false]

        })
      );
    });
  }
  
  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

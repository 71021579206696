<ng-container *ngIf="isSlugList && !seoComp">
  <div (click)="backtoList()">
    <button class="btn btn-success mt-2">Back</button>
  </div>
  <div class="container" *ngIf="isContentLoaded">
    <form [formGroup]="featuresForm" (ngSubmit)="onSubmit(featuresForm)">
      <div class="row" *ngIf="isContentLoaded">
        <div
          class="col-md-6 mt-2"
          formArrayName="features"
          *ngFor="let item of features().controls; let i = index"
        >
          <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
            <a
              *ngIf="features().controls.length > 1"
              href="javascript:void(0)"
              class="ms-2 close-btn"
              (click)="removeFeatures(i)"
              ><i class="uil-times fa-2x"></i
            ></a>
            <div class="my-2 row">
              <div class="col-md-12">
                <label class="col-form-label">Slug</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Useful media url"
                  formControlName="useful_media_url"
                  (change)="dataChanged(i)"
                  [ngClass]="{
                    invalid: item.get('useful_media_url').invalid && isSubmitted
                  }"
                />
              </div>
            </div>
            <div class="my-2 row">
              <div class="col-md-12">
                <label class="col-form-label">Media</label>
                <input
                *ngIf="!isAddCheck"
                  readonly
                  type="text"
                  class="form-control"
                  placeholder="Heading"
                  formControlName="useful_media"
                  (change)="detailsDataChanged(i, j)"
                  [ngClass]="{
                    invalid: item.get('useful_media').invalid && isSubmitted
                  }"
                />
                <select
                *ngIf="isAddCheck"
              
                  class="form-select"
                  formControlName="useful_media"
                  (change)="dataChanged(i)"
                  [ngClass]="{
                    invalid: item.get('useful_media').invalid && isSubmitted
                  }"
                >
                  <option *ngFor="let media of allMedia" [value]="media.id">
                    <div>{{ media?.heading }}</div>
                  </option>
                </select>
              </div>
            </div>
            <div class="position-relative" formArrayName="details">
              <!-- item.get('details').controls; -->
              <div
                *ngFor="
                  let detail of item.get('details').controls;
                  let j = index
                "
                class="details_block"
              >
                <div
                  [formGroupName]="j"
                  *ngIf="!detail.get('is_deleted').value"
                >
                  <div class="my-2 row">
                    <div class="col-md-12">
                      <div
                        class="details_del_ctrl d-flex justify-content-end"
                        *ngIf="item.get('details').value.length > 1"
                        (click)="delDetails(i, j, detail)"
                      >
                        <div
                          class="details_close_btn_pn d-flex justify-content-center align-items-center cpr"
                        >
                          <i class="uil-times fa-2x font_16 color_white"></i>
                        </div>
                      </div>
                      <label class="col-form-label">Heading</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Heading"
                        formControlName="heading"
                        (change)="detailsDataChanged(i, j)"
                        [ngClass]="{
                          invalid: detail.get('heading').invalid && isSubmitted
                        }"
                      />
                    </div>
                  </div>
                  <div class="my-2 row">
                    <div class="col-md-12">
                      <label class="col-form-label">Image</label>
                      <div class="d-flex">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="URL"
                          formControlName="image_url"
                          (change)="detailsDataChanged(i, j)"
                        />
                        <a
                          [href]="featuresForm.value.features[i].image_url"
                          target="_blank"
                        >
                          <img
                            class="ms-1"
                            height="40"
                            [src]="
                              featuresForm.value.features[i].details[j]
                                .image_url
                            "
                          />
                        </a>
                        <button
                          class="btn btn-primary ms-1"
                          type="button"
                          (click)="openUploadModal(i, j, 'image_url')"
                        >
                          <i class="fas fa-cloud-upload-alt"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="my-2 row">
                    <div class="col-md-12">
                      <label class="col-form-label">Description</label>
                      <ckeditor
                        [editor]="Editor"
                        formControlName="description"
                        (change)="detailsDataChanged(i, j)"
                        [ngClass]="{
                          invalid:
                            detail.get('description').invalid && isSubmitted
                        }"
                        data="<p>Content of the editor.</p>"
                      ></ckeditor>
                    </div>
                  </div>
                </div>
              </div>
              <!-- data:-{{item|json}} -->
              <div class="d-flex justify-content-end">
                <div
                  class="pointer cpr details_add_btn"
                  (click)="addDetails(item)"
                >
                  <i class="uil-plus"></i>
                  <span>add</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4 p-5">
                <div class="row">
                    <button *ngIf="checkPermission('content_management_home_create')"
                        class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addFeatures()"><i
                            class="uil-plus"></i> Add</button>
                    <button
                        *ngIf="checkPermission('content_management_home_create')&&checkPermission('content_management_home_edit')"
                        class="btn btn-info mt-1 col-md-12" type="submit"><i
                            [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                        Save All</button>
                    <app-publish-btn *ngIf="checkPermission('content_management_home_publish')"
                        class="col-md-12 pt-1 px-0" [publish]="'UspFeature'" [page]="'home'"></app-publish-btn>
                </div>
            </div> -->
      </div>
      <div class="row">
        <div class="col-12">
          <!-- <div class="mb-5"></div> -->
          <div class="d-flex action_button_section">
            <!-- <button
              *ngIf="checkPermission('content_management_home_create')"
              class="btn btn-primary mt-1 me-2"
              type="button"
              (click)="addFeatures()"
            >
              <i class="uil-plus"></i> Add
            </button> -->
            <button
              *ngIf="
                checkPermission('content_management_home_create') &&
                checkPermission('content_management_home_edit')
              "
              class="btn btn-info mt-1 me-2"
              type="submit"
            >
              <i
                [ngClass]="{
                  'fa fa-pulse fa-spin fa-spinner': isUpdating,
                  'bx bx-save': !isUpdating
                }"
              ></i>
              Save All
            </button>
            <app-publish-btn
              *ngIf="checkPermission('content_management_home_publish')"
              class="pt-1 px-0"
              [publish]="'UspFeature'"
              [page]="'home'"
            ></app-publish-btn>
          </div>
        </div>
      </div>
    </form>
  </div>
</ng-container>
<ng-container *ngIf="isContentLoaded && !isSlugList && !seoComp">
  <div
    *ngIf="checkPermission('content_management_home_create')"
    class="d-flex justify-content-end mt-2"
    (click)="addFeatures()"
  >
    <button class="btn btn-success mb-2"><i class="uil-plus"></i> Add</button>
  </div>
  <div>
    <ol>
      <li
        class="bg-light d-flex align-items-stretch justify-content-between mb-2"
        *ngFor="let item of slugListArray; let i = index"
      >
        <div class="d-flex">
          <span class="bg-white d-flex align-items-center">
            {{ (pageNumber - 1) * 10 + i + 1 + "." + "" }}
          </span>
          <span class="d-flex align-items-center ms-2">
            {{ item?.useful_media_url }}
          </span>
        </div>

        <span class="d-flex justify-content-end">
          <button class="btn btn-info mx-2" (click)="gotoDescription(item)">
            Description
          </button>
          <button class="btn btn-info mx-2" (click)="gotoSeo(item)">SEO</button>
          <button class="btn btn-success mx-2" (click)="editSlug(item, i)">
            Edit
          </button>
        </span>
      </li>
    </ol>
  </div>
</ng-container>
<div
  class="row justify-content-md-between align-items-md-center mt-2"
  *ngIf="loading && !isSlugList && !seoComp"
>
  <div class="col-sm-12 col-md-5">
    <div
      class="dataTables_info mb-2"
      id="tickets-table_info"
      role="status"
      aria-live="polite"
    >
      <!-- Showing
      {{ (pageNumber - 1) * pageSize }} to
      {{
        pageNumber * pageSize < page_details.count
          ? pageNumber * pageSize
          : page_details.count
      }}
      of {{ page_details.count }} entries -->
    </div>
  </div>
  <!-- Pagination -->
  <div class="col-sm-12 col-md-5">
    <div class="text-md-end float-md-end pagination-rounded">
      <ngb-pagination
        [collectionSize]="page_details.count"
        [(page)]="pageNumber"
        [pageSize]="page_details.limit"
        (pageChange)="nextPage(pageNumber)"
      >
      </ngb-pagination>
    </div>
  </div>
  <!-- End Pagination -->
</div>
<app-specific-content-seo
  *ngIf="seoComp"
  [slugId]="slugId"
  [seoType]="seoType"
  [slugTitle]="slugTitle"
></app-specific-content-seo>

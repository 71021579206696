import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'news-specific-page',
  templateUrl: './news-specific-page.component.html',
  styleUrls: ['./news-specific-page.component.scss']
})
export class NewsSpecificPageComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  page = 'news';
  curComponent = 'specific_news_page';
  previewPage="specific-news";

  ngOnInit(){
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'About Us Page', active: true }];
  }
}

import { ChangeDetectorRef, Component, Input } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NotificationTemplatesService } from "src/app/core/services/notification-templates.service";

@Component({
  selector: "app-add-message",
  templateUrl: "./add-message.component.html",
  styleUrls: ["./add-message.component.scss"],
})
export class AddMessageComponent {
  @Input() fromParent: any;
  userMessageForm: FormGroup;
  smsList: any = [];
  isSubmitted = false;
  isEdit = false;
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private api: NotificationTemplatesService,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if(this.fromParent) this.basicFormDetails(this.fromParent);
    else this.resetFormDetails();
  }

  closeModal() {
    this.activeModal.close("close");
  }

  resetFormDetails() {
    this.userMessageForm = this.fb.group({
      id: ["", null],
      name: ["", [Validators.required]],
      body: ["", [Validators.required]],
      to: ["", [Validators.required]],
      tagsGet: new FormArray([]),
    });
    this.addTags();
  }

  basicFormDetails(data?) {
    this.userMessageForm = this.fb.group({
      id: [data ? data.id : null],
      name: [data ? data.name : "", [Validators.required]],
      body: [data ? data.body : "", [Validators.required]],
      to: [data ? data.to : "", [Validators.required]],
      tagsGet: new FormArray([])
    });
    data.tags.forEach(ao => {
      (this.userMessageForm.get('tagsGet') as FormArray).push(
        this.fb.group({
          tags: [ao, [Validators.required]]
        })
      );
    });
  }


  get tagsGet() : FormArray {
    return this.userMessageForm.get("tagsGet") as FormArray
  }
 
  newTag(): FormGroup {
    return this.fb.group({
      tags: '',
    })
  }
 
  addTags() {
    this.tagsGet.push(this.newTag());
  }
 
  removeTags(i:number) {
    this.tagsGet.removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      form.value.tags =form.value.tagsGet.map(ae=>ae.tags);
      delete form.value.tagsGet;
      if (this.fromParent?.id) {
         let editId = this.fromParent?.id;
        this.api.editNotification(editId, form.value).subscribe(
          (result: any) => {
            this.toastr.success("Message Updated Successfully!");
            this.closeModal();
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.api.setNotification(form.value).subscribe(
          (result: any) => {
            this.toastr.success("Message Added Successfully!");
            this.closeModal();
          },
          (error) => {
            console.log(error);
          }
        ); 
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all the fields to continue!");
    }
  }
  getDataList() {
    this.api.getNotification().subscribe((response: any) => {
      this.smsList = response.results;
    });
  }
}

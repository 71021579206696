import { HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VisaManageService } from 'src/app/core/services/visa-manage.service';


@Component({
  selector: 'file',
  template: `
      <div [formGroup]="form">
        <div class="position-relative" *ngIf="!field.value" dropZone [class.hovering]="isHovering">
              <div class="disable_handler" *ngIf="field?.disabled"></div>
              <input class="input_file_upload" type="file" (change)="onUpload($event.target)" />
              <div class="height_zero">
                <input class="input_file_upload" type="text" #fileInput [formControlName]="field.name"/>
              </div>
              
              <div class="outer_file_upload">
                  <div class="upload_box d-flex align-items-center justify-content-center">
                      <span class="material-icons-outlined me-2">
                          file_upload
                      </span>
                      {{fileName? 'Success':'Upload'}}
                      
                  </div>
              </div>
        </div>
        <div *ngIf="field.value">
          <div class="card">
            <img class="card-img-top" [src]="field.value">
          </div>
        </div>
        <p *ngIf="fileName" class="color_42A347">Successfully Uploaded</p>
      </div> 
    `,
  styles: [
    ` 
        .height_zero {
          height: 0px;
          overflow:hidden;
        }

        .input_file_upload {
            width: 100%;
            height: 45px;
            opacity: 0;
            position: relative;
            z-index: 3;
            cursor: pointer;
        }
        .outer_file_upload {
            position: absolute;
            width: 100%;
            background: rgba(11, 47, 106, 0.05);
            border: 1px dashed #0b2f6a;
            border-radius: 5px;
            top: 0;
            justify-content: center;
            display: flex;
            align-items: center;
            .upload_box {
                border-radius: 5px;
                width: 130px;
                height: 45px;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #0b2f6a;
            }
        }
        .document_link {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            text-decoration-line: underline;
            color: #0b2f6a;
        }
      `
  ]
})
export class FileComponent implements OnInit {
  @Input() field: any = {};
  @Input() form!: FormGroup;
  fileName: string | undefined;
  isHovering = false;
  get isValid() { return this.form.controls[this.field.name].valid; }
  get isDirty() { return this.form.controls[this.field.name].dirty; }

  constructor(public visaProService: VisaManageService) {


  }

  ngOnInit(): void {

    if (this.field?.answer){
      this.form.controls[this.field?.name].setValue(this.field?.answer);
    }
    // if (this.field.disabled) {
    //   this.form.controls[this.field?.name].disable();
    // } else {
    //   this.form.controls[this.field?.name].enable();
    // }
  }

  toggleHover(e: any) { }


  onUpload(fileInput: any) {
    this.visaProService.uploadImgFile
    const file = fileInput.files?.[0];
    const formData = new FormData();
    formData.append('document', file);
    this.fileName = file ? file.name : undefined;//this file name is included for handling success message under button

    this.visaProService.uploadImgFile(formData).subscribe((event: any) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          break;
        case HttpEventType.Response:
          let url = event.body.document;
          this.form.controls[this.field.name].setValue(url);
          break;
      }

    }, err => {
      console.log(err);

    });
  }
}
<div class="container-fluid mnht-300">
    <!-- start page title -->
    <app-page-title title="" [breadcrumbItems]="breadCrumbItems"></app-page-title>
  
    <div class="my-3 row">
      <div class="col-md-12">
        <h5 class="text-danger mb-2">Delete Section</h5>
      </div>
      <div class="col-md-4">
        <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
          bindValue="id" [(ngModel)]="country" [clearable]="false" (change)="selectCountry(country)"></ng-select>
      </div>
      <div class="col-md-4">
        <ng-select [(items)]="categoryList" [multiple]="false" [placeholder]="'Select Category'" bindLabel="name"
          bindValue="id" [(ngModel)]="category" [clearable]="false" (change)="selectCategory(category)"></ng-select>
      </div>
      <div class="col-md-4">
        <ng-select [(items)]="productList" [multiple]="false" [placeholder]="'Select Product'" bindLabel="name"
          bindValue="id" [(ngModel)]="product" [clearable]="false"></ng-select>
      </div>
    </div>

    <div class="my-3 row">
        <div class="col-md-4 text-center">
            <button class="btn btn-danger" placement="bottom" ngbTooltip="By deleting this country, you will also delete all data linked to it, including categories and products." *ngIf="country" (click)="deleteCountry();">Delete Country</button>
        </div>
        <div class="col-md-4 text-center">
            <button class="btn btn-danger" placement="bottom" ngbTooltip="By deleting this category, you will also delete all products linked to it." *ngIf="category" (click)="deleteCategory();">Delete Category</button>
        </div>
        <div class="col-md-4 text-center">
            <button class="btn btn-danger" placement="bottom" ngbTooltip="To delete specific product." *ngIf="product" (click)="deleteProduct();">Delete Product</button>
        </div>
    </div>

    <div class="my-3 row mt-5">
      <div class="col-md-12">
        <h5 class="text-danger mb-2">Delete CMS Section</h5>
      </div>
      <div class="col-md-4">
        <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
          bindValue="id" [(ngModel)]="cmsCountry" [clearable]="false" (change)="getCMSDetails(cmsCountry)"></ng-select>
      </div>
      <div class="col-md-4" *ngIf="cmsCountry">
        <button class="btn btn-danger" placement="bottom" ngbTooltip="To delete specific country in CMS." (click)="open(deleteCmsmodal);">Delete Country</button>
    </div>
    </div>
</div>


<ng-template #deletemodal let-modal>
  <div class="modal-header w-100"><h4 class="modal-title w-100 text-center" id="modal-basic-title">Delete  {{isCountry ? 'Country ': (isCategory ? 'Category ':'Product ')}}</h4></div>
  <div class="modal-body text-center">
      <div class="mt-3 row" *ngIf="this.isCountry">
        <div class="col-md-12">This action will delete all data linked to the selected country, such as categories and products.</div>
        <div class="offset-md-3 col-md-6 row">
          <div class="col-md-12 text-start mt-2">Country -> <b>{{selectedCountryDetails?.name}}</b></div>
          <div class="col-md-12 row text-start ms-4">
              <div class="col-md-12 row" *ngFor="let cat of selectedCountryDetails?.admin_data.categories">
                <div class="col-md-12 mt-1">&#8627; Category &#8594; <b>{{cat.name}}</b></div>
                <div class="col-md-12 mt-1 ms-5">&#8627; Sub Categories &#8594; <b *ngFor="let subcat of cat.sub_categories; let isLast=last">{{subcat.name}}{{!isLast ? ', ': ''}}</b></div>
              </div>
          </div>
        </div>
      </div>
      <div class="mt-3 row" *ngIf="this.isCategory">
        <div class="col-md-12">This action will delete all data linked to the selected category such as products.</div>
        <div class="offset-md-3 col-md-6 row">
          <div class="col-md-12 text-start mt-2">Category -> <b>{{selectedCategoryDetails?.name}}</b></div>
          <div class="col-md-12 row text-start ms-4">
                <div class="col-md-12 mt-1 ms-5">&#8627; Sub Categories &#8594; <b *ngFor="let subcat of selectedCategoryDetails?.admin_data.sub_categories; let isLast=last">{{subcat.name}}{{!isLast ? ', ': ''}}</b></div>
          </div>
        </div>
      </div>
    <div class="mt-3">Type the 
      {{isCountry ? 'country ': (isCategory ? 'category ':'product ')}} name (  <b>{{isCountry ? selectedCountryDetails?.name : (isCategory ? selectedCategoryDetails?.name : selectedProductDetails?.heading )}}</b>  ) below and click <b>Delete</b> to proceed</div>
      <input type="text" placeholder="Enter name" [(ngModel)]="confirmDeleteName" class="form-control w-50 mx-auto mt-3" (keyup)="checkSameName(confirmDeleteName)">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="confirmDelete()" [disabled]="!isNameSame">
     <i class="fa fa-spin fa-spinner fa-pulse" *ngIf="isDeleting"></i> <i class="fa fa-trash" *ngIf="!isDeleting"></i> Delete
    </button>
    <button type="button" class="btn btn-outline" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>

<ng-template #deleteCmsmodal let-modal>
  <div class="modal-header w-100"><h4 class="modal-title w-100 text-center" id="modal-basic-title">Delete  {{isCountry ? 'Country ': (isCategory ? 'Category ':'Product ')}}</h4></div>
  <div class="modal-body text-center">
      <div class="mt-3 row" *ngIf="this.isCountry">
        <div class="col-md-12">This action will delete all data linked to the selected country, such as categories and products.</div>
        <div class="offset-md-3 col-md-6 row">
          <div class="col-md-12 text-start mt-2">Country -> <b>{{selectedCountryDetails?.name}}</b></div>
          <div class="col-md-12 row text-start ms-4">
              <div class="col-md-12 row" *ngFor="let cat of selectedCountryDetails?.admin_data.categories">
                <div class="col-md-12 mt-1">&#8627; Category &#8594; <b>{{cat.name}}</b></div>
                <div class="col-md-12 mt-1 ms-5">&#8627; Sub Categories &#8594; <b *ngFor="let subcat of cat.sub_categories; let isLast=last">{{subcat.name}}{{!isLast ? ', ': ''}}</b></div>
              </div>
          </div>
        </div>
      </div>
      <div class="mt-3 row" *ngIf="this.isCategory">
        <div class="col-md-12">This action will delete all data linked to the selected category such as products.</div>
        <div class="offset-md-3 col-md-6 row">
          <div class="col-md-12 text-start mt-2">Category -> <b>{{selectedCategoryDetails?.name}}</b></div>
          <div class="col-md-12 row text-start ms-4">
                <div class="col-md-12 mt-1 ms-5">&#8627; Sub Categories &#8594; <b *ngFor="let subcat of selectedCategoryDetails?.admin_data.sub_categories; let isLast=last">{{subcat.name}}{{!isLast ? ', ': ''}}</b></div>
          </div>
        </div>
      </div>
    <div class="mt-3">Type the 
     Country name (  <b>{{selectedCMSCountryDetails?.name}}</b>  ) below and click <b>Delete</b> to proceed</div>
      <input type="text" placeholder="Enter name" [(ngModel)]="confirmCMSDeleteName" class="form-control w-50 mx-auto mt-3" (keyup)="checkCMSSameName(confirmCMSDeleteName)">
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="confirmCMSDelete()" [disabled]="!isCMSNameSame">
     <i class="fa fa-spin fa-spinner fa-pulse" *ngIf="isDeleting"></i> <i class="fa fa-trash" *ngIf="!isDeleting"></i> Delete
    </button>
    <button type="button" class="btn btn-outline" (click)="modal.close('Save click')">Close</button>
  </div>
</ng-template>
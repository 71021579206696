<div class="container-fluid">
    <!-- start page title -->
    <app-page-title title="Access Management" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <!-- orientation="vertical"  -->
                    <ul ngbNav #justifiednavpills="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span>Users</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-user></app-user>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span>Roles</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-roles></app-roles>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="justifiednavpills"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive" *ngIf="!isLoading">
                        <div class="min_height_45 table table-centered datatables dt-responsive nowrap table-card-list">
                            <div class="row mb-3">
                                <div class="col-md-3 text-center"><b>Name</b></div>
                                <div class="col-md-3 text-center"><b>Message</b></div>
                                <div class="col-md-3 text-center"><b>E-mail</b></div>
                                <div class="col-md-3 text-center"><b>WhatsApp</b></div>
                            </div>

                            <div class="row" *ngFor="let table of triggerList;let i=index">
                                <div class="col-md-3">
                                    {{table.trigger_points}}
                                </div>
                                <div class="col-md-3">
                                    <ng-select dropdownPosition="bottom" [(items)]="triggerSmsList" groupBy="tag_name"
                                        [multiple]="true" (change)="changeList(table.id, 1, $event)" [placeholder]="'Select Template'" bindLabel="name"
                                        bindValue="id"  [closeOnSelect]="false" [clearable]="false"></ng-select>

                                </div>
                                <div class="col-md-3">
                                    <ng-select dropdownPosition="bottom" [(items)]="triggerEmailList" groupBy="tag_name"
                                        [multiple]="true" (change)="changeList(table.id, 2, $event)" [placeholder]="'Select Template'" bindLabel="name"
                                        bindValue="id" [closeOnSelect]="false" [clearable]="false"></ng-select>

                                </div>
                                <div class="col-md-3">
                                    <ng-select dropdownPosition="bottom" [(items)]="triggerWhatsAppList"
                                        [multiple]="true" (change)="changeList(table.id, 3, $event)" [placeholder]="'Select Template'" bindLabel="name"
                                        bindValue="id" [closeOnSelect]="false" [clearable]="false"></ng-select>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isLoading" class="d-flex mt-5">
                        <i class="fa fa-spin fa-spinner fa-pulse fa-5x m-auto"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
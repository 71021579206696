import { Component, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { CountryManagementService } from 'src/app/core/services/country-management.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/core/services/shared.service';
import { PermissionService } from 'src/app/core/services/permision.service';
import { CustomSortComponent } from '../../ui-elements/custom-sort/custom-sort.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-country-suitable-visa',
  templateUrl: './country-suitable-visa.component.html',
  styleUrls: ['./country-suitable-visa.component.scss']
})
export class CountrySuitableVisaComponent {
  visaForm: FormGroup;
  hasChanges = false;
  fileToUpload: File | [null] = [null];
  fileImgToUpload: File | [null] = [null];

  isUpdating = false;
  progress: number[] = [0];
  progressImg: number[] = [0];
  deletedvisa = [];
  categoryList = [];
  @Input() country;
  @Input() page;

  constructor(private fb: FormBuilder, private api: CountryManagementService, 
    private toastr: ToastrService, private sharedService: SharedService, 
    public permission: PermissionService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.resetForm();
    this.getHomevisa();
    this.getCategories(this.country);
  }

  resetForm() {
    this.visaForm = this.fb.group({
      visa: this.fb.array([
        this.fb.group({
          heading: ['', [Validators.required]],
          key_values: new FormArray([]),
          left_button_url: ['-', [Validators.required]],
          left_button_label: ['-', [Validators.required]],
          category: ['', [Validators.required]],
          is_updated: [true, [Validators.required]],
          is_deleted: [false, [Validators.required]],

          amount: [0],
          currency_symbol: ['-'],
          icon_url: ['-'],
          image_url: ['-'],
          description_datas: new FormArray([
            this.fb.group({
              text: '-',
            })
          ]),
        })])
    });
  }

  visa(): FormArray {
    return this.visaForm.get("visa") as FormArray
  }

  newvisa(): FormGroup {
    return this.fb.group({
      heading: ['', [Validators.required]],
      left_button_url: ['-'],
      left_button_label: ['-'],
      category: ['', [Validators.required]],
      is_updated: [true, [Validators.required]],
      is_deleted: [false, [Validators.required]],
      key_values: new FormArray([
        this.fb.group({
          key: '',
          value: '',
        })
      ]),

      amount: [0],
      currency_symbol: ['-'],
      icon_url: ['-'],
      image_url: ['-'],
      description_datas: new FormArray([
        this.fb.group({
          text: '-',
        })
      ]),
    })
  }

  addvisa() {
    this.visa().push(this.newvisa());
  }

  removevisa(i: number) {
    this.visaForm.value.visa[i].is_deleted = true;
    if (this.visaForm.value.visa[i].hasOwnProperty("id")) this.deletedvisa.push(this.visaForm.value.visa[i]);
    this.visa().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedvisa.forEach(ae => {
        ae.is_updated = false;
        form.value.visa.push(ae);
      })
      this.api.setSuitableVisa(form.value.visa).subscribe(
        () => {
          this.toastr.success("Content Updated Successfully!");
          this.getHomevisa();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        }
      )
    } else {
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  getHomevisa() {
    this.resetForm();
    this.api.getSuitableVisa(this.country).subscribe(
      (result: any) => {
        this.visaForm = this.fb.group({
          visa: new FormArray([]),
        });
        result.forEach(ao => {
          (this.visaForm.get('visa') as FormArray).push(
            this.fb.group({
              id: [ao.id, [Validators.required]],
              heading: [ao.heading, [Validators.required]],
              category: [ao.category, [Validators.required]],
              is_updated: [true, [Validators.required]],
              is_deleted: [false, [Validators.required]],
              key_values: this.setDescriptions(ao),
              
              left_button_url: ['-'],
              left_button_label: ['-'],
              amount: [0],
              currency_symbol: ['-'],
              icon_url: ['-'],
              image_url: ['-'],
              description_datas: new FormArray([
                this.fb.group({
                  text: '-',
                })
              ]),
            })
          );
          this.deletedvisa = [];
        });
      })
  }

  setDescriptions(x) {
    let arr = new FormArray([])
    x.key_values.forEach(y => {
      arr.push(this.fb.group({
        key: y.key,
        value: y.value,
      }))
    })
    return arr;
  }

  addNewDescription(control) {
    control.push(
      this.fb.group({
        key: [''],
        value: [''],
      }));
  }

  deleteDescription(control, index) {
    control.removeAt(index)
  }

  getCategories(country_id) {
    this.sharedService.getCmsCategoryList(country_id).subscribe(
      (result: any) => {
        this.categoryList = result;
      })
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  showSortModal(data, label) {
    let data_card: any = {
      list: data,
      label: label,
      api_url: 'cms-admin/country/category-detail-priority/position'
    };
    const modalRef = this.modalService.open(CustomSortComponent,
      {
        size: 'md',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.sortData = data_card;
    modalRef.result.then((result) => {
      if (result) this.getHomevisa();
    });
  }

}

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-md-2">
                        <div class="col-md-3 offset-md-9">
                            <button type="button" class="btn btn-success float-end" (click)="syncTemplates()"><i class="me-1"
                                    [ngClass]="{'mdi mdi-plus': !syncLoading, 'fa fa-spin fa-spinner fa-pulse': syncLoading}"></i>
                                Sync</button>
                        </div>
                    </div>
                    <!-- Table -->
                    <div class="table-responsive" *ngIf="!isLoading">
                        <table id="basic-datatable"
                            class="table table-centered datatables dt-responsive nowrap table-card-list">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Languages</th>
                                    <!-- <th class="text-center">Cc</th>
                                    <th class="text-center">Tags</th>
                                    <th class="text-center">Subject</th> -->
                                    <!-- <th class="text-center">Action</th> -->
                                </tr>
                            </thead>
                            <tbody *ngFor="let table of templateList;let i=index">
                                <tr>
                                    <td>
                                        <ngb-highlight>{{table?.name}}</ngb-highlight>
                                    </td>
                                    <td>
                                        <ngb-highlight>
                                            <span class="badge bg-info p-2 m-1 cpr" *ngFor="let item of table?.language"  (click)="addWhatsappModal(table, item)">{{item.name}}</span>
                                        </ngb-highlight>
                                    </td>
                                    <!-- <td class="text-center">
                                        <ngb-highlight>{{table?.cc}}</ngb-highlight>
                                    </td>
                                    <td class="text-center">
                                        <ngb-highlight>{{table?.tags}}</ngb-highlight>
                                    </td>
                                    <td class="text-center">
                                        <ngb-highlight>{{table?.subject}}</ngb-highlight>
                                    </td> -->
                                    <!-- (click)="addEmailModal(table)" -->
                                    <!-- <td class="text-center">
                                        <a class="cpr" (click)="addWhatsappModal(table)" ><i
                                                class="fas fa-edit"></i></a>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="isLoading" class="d-flex mt-5">
                        <i class="fa fa-spin fa-spinner fa-pulse fa-5x m-auto"></i>
                    </div>
                    <!-- End table -->
                </div>
            </div>
        </div>
    </div>
</div>

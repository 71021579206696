<div class="container-fluid">
  <app-page-title title="Blog-Author" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <app-preview-btn [page]="specificPage"></app-preview-btn> -->
          <ul ngbNav #justifiednavpills="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
            <li [ngbNavItem]="1">
              <a ngbNavLink>
                <span>Header</span>
              </a>
              <ng-template ngbNavContent>
                <!-- <app-header-contact-us [page]="specificPage"></app-header-contact-us> -->
                <app-media-header [page]="specificPage"></app-media-header>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>
                <span>Author&nbsp;</span>
              </a>
              <ng-template ngbNavContent>
                <!-- <app-contact-us-side-info [page]="page"></app-contact-us-side-info> -->
                <app-author [page]="page"></app-author>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="justifiednavpills"></div>
        </div>
      </div>
    </div>
  </div>
</div>
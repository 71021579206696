import { Component, Input, OnInit, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FieldBuilderComponent } from './field-builder/field-builder.component';

@Component({
  selector: 'dynamic-form-builder',
  template: `
    <form  [formGroup]="form" (ngSubmit)="onFormSubmit(form)" class="form-horizontal" >
      <div class="row">
          <div  *ngFor="let field of fields?.form_inputs " [attr.id]="'q_' + field.id" class="col-md-3 {{field.is_optional? 'd-none':'d-block'}}">
            
            <field-builder #formBuilder [field]="field" [form]="form" [submitted]="submitted"></field-builder>
            
          </div>
      </div>
      <div class="form-row"></div>
      <div class="form-group row">
        <div class="col-md-3"></div>
        <div class="col-md-9 d-flex justify-content-end">
          <button type="submit" class="btn btn-primary" *ngIf="status">Proceed</button>
          <button type="submit" class="btn btn-primary" *ngIf="!status" [disabled]="!form.valid">Next</button>
          <!-- [disabled]="!form.valid" -->
        </div>
      </div>
    </form>
  `,
})
export class DynamicFormBuilderComponent implements OnInit {

  @Output() onSubmit = new EventEmitter();
  @Input() fields: any;
  fieldsCopy: any;
  @Input() status: boolean = true;
  form!: FormGroup;
  submitted: boolean = false;
  @ViewChild('formBuilder')
  fbComponent: FieldBuilderComponent = new FieldBuilderComponent;
  constructor(private toastr: ToastrService) { }

  ngOnInit() {

    this.fieldsCopy = JSON.parse(JSON.stringify(this.fields));
    this.setFields();

  }

  setFields() {
    let fieldsCtrls: any = {};
    for (let f of this.fields?.form_inputs) {
      let lowerFieldType = f.field_type.toLowerCase()
      if ('checkboxes' == lowerFieldType) {
        let opts: any = {};
        for (let opt of f?.options) {
          opts[opt?.answer_choice] = new FormControl(opt?.value);
        }
        fieldsCtrls[f?.name] = new FormGroup(opts)
      } else {

        if (f?.is_required) {
          fieldsCtrls[f.name] = new FormControl(f.answer ? f.answer : '', Validators.required);
        } else {
          fieldsCtrls[f.name] = new FormControl(f.answer ? f.answer : '');
        }

      }


      // if (['user input', 'dropbox', 'paragragh', 'email', 'date format', 'date', 'time', 'phone', 'tel', 'country_s', 'country_ms', 'dropdown', 'radio button', 'state', 'country_ms', 'language', 'multiple selections'].includes(lowerFieldType)) {

      //   fieldsCtrls[f.name] = new FormControl(f.answer ? f.answer : '', f?.is_required ? Validators.required : {});

      // }
      // else {
      //   let opts: any = {};
      //   for (let opt of f?.options) {
      //     opts[opt?.answer_choice] = new FormControl(opt?.value);
      //   }
      //   fieldsCtrls[f?.name] = new FormGroup(opts)
      // }

      // else if (['country_ms', 'language', 'multiple selections'].includes(lowerFieldType)) {
      //   fieldsCtrls[f.name] = new FormArray(f.answer ? f.answer : '', f?.is_required ? Validators.required : {});
      // }
    }
    this.form = new FormGroup(fieldsCtrls);
  }

  @HostListener('document:triggerNewQue', ['$event', '$event.detail',])
  async ctrlQue(el: any) {

    let queIdsToSend = el?.detail?.related_questions;
    if (queIdsToSend) {
      this.handleQue(queIdsToSend);
    }
  }

  handleQue(queIdsToSend: any) {
    for (let queId of queIdsToSend) {
      let elemIdToSend = 'q_' + queId?.id;
      queId.enable ? this.showQue(elemIdToSend) : this.hideQue(elemIdToSend);
      const field = this.fields.form_inputs.find((ae: any) => ae.id === queId.id);
      if (field) {
        const myFieldControl = this.form.get(field.name);
        if (queId.enable && field.is_required) {
          myFieldControl?.setValidators(Validators.required);
        } else {
          myFieldControl?.clearValidators();
        }
        myFieldControl?.updateValueAndValidity();
      }

    }


  }

  showQue(elemId: string) {
    let elemToShow = document.getElementById(elemId);
    let isContainClass = this.isContain(elemToShow, 'd-none');

    if (isContainClass) {
      elemToShow?.classList.remove('d-none');
    }

    elemToShow?.classList.add('d-block');
  }

  hideQue(id: any) {
    let elemToShow = document.getElementById(id);
    let isContainClass = this.isContain(elemToShow, 'd-block');

    if (isContainClass) {
      elemToShow?.classList.remove('d-block');
    }

    elemToShow?.classList.add('d-none');
  }

  isContain(elem: any, className: string): boolean | undefined {
    let isContainClass = elem?.classList.contains(className)
    return isContainClass
  }

  onFormSubmit(form:FormGroup){
    if(form.valid){
      this.submitted = false;
      this.fbComponent.isSubmitted(false);
      this.onSubmit.emit(form.value);
    } else {
      this.submitted = true;
      this.fbComponent.isSubmitted(true);
      this.toastr.warning("Please fill all mandatory fields!")
    }
  }


}

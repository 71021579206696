import { ChangeDetectorRef, Component, Input } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NotificationTemplatesService } from "src/app/core/services/notification-templates.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: 'app-add-email',
  templateUrl: './add-email.component.html',
  styleUrls: ['./add-email.component.scss']
})
export class AddEmailComponent {
  @Input() fromParent: any;
  userEmailForm: FormGroup;
  emailList: any = [];
  isSubmitted = false;
  isEdit = false;
  public Editor = ClassicEditor;
  
  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private api: NotificationTemplatesService,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if(this.fromParent) this.basicFormDetails(this.fromParent);
    else this.resetFormDetails();
  }

  closeModal() {
    this.activeModal.close("close");
  }

  resetFormDetails() {
    this.userEmailForm = this.fb.group({
      id: ["", null],
      name: ["", [Validators.required]],
      to: ["", [Validators.required]],
      cc: ["", [Validators.required]],
      subject: ["", [Validators.required]],
      body: ["", [Validators.required]],
      tagsGet: new FormArray([]),
      attachments: new FormArray([]),
    });
    this.addTags();
    this.addAttachment();
  }

  basicFormDetails(data?) {
    this.userEmailForm = this.fb.group({
      id: [data ? data.id : null],
      name: [data ? data.name : "", [Validators.required]],
      to: [data ? data.to : "", [Validators.required]],
      cc: [data ? data.cc : "", [Validators.required]],
      subject: [data ? data.subject : "", [Validators.required]],
      body: [data ? data.body : "", [Validators.required]],
      tagsGet: new FormArray([]),
      attachments: new FormArray([]),
    });
    data.tags.forEach(ao => {
      (this.userEmailForm.get('tagsGet') as FormArray).push(
        this.fb.group({
          tags: [ao, [Validators.required]]
        })
      );
    });
    data.attachments.forEach(ao => {
      (this.userEmailForm.get('attachments') as FormArray).push(
        this.fb.group({
          attachment: [ao, [Validators.required]]
        })
      );
    });
  }

  get attachments() : FormArray {
    return this.userEmailForm.get("attachments") as FormArray
  }
 
  newAttachment(): FormGroup {
    return this.fb.group({
      attachment: '',
    })
  }
 
  addAttachment() {
    this.attachments.push(this.newAttachment());
  }
 
  removeAttachment(i:number) {
    this.attachments.removeAt(i);
  }

  get tagsGet() : FormArray {
    return this.userEmailForm.get("tagsGet") as FormArray
  }
 
  newTag(): FormGroup {
    return this.fb.group({
      tags: '',
    })
  }
 
  addTags() {
    this.tagsGet.push(this.newTag());
  }
 
  removeTags(i:number) {
    this.tagsGet.removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      form.value.tags =form.value.tagsGet.map(ae=>ae.tags);
      delete form.value.tagsGet;
      form.value.attachments =form.value.attachments.map(ae=>ae.attachment);
      if (this.fromParent?.id) {
         let editId = this.fromParent?.id;
        this.api.editEmailNotification(editId, form.value).subscribe(
          (result: any) => {
            this.toastr.success("Email Updated Successfully!");
            this.closeModal();
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.api.setEmailNotification(form.value).subscribe(
          (result: any) => {
            this.toastr.success("Email Added Successfully!");
            this.closeModal();
          },
          (error) => {
            console.log(error);
          }
        ); 
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all the fields to continue!");
    }
  }
  getDataList() {
    this.api.getEmailNotification().subscribe((response: any) => {
      this.emailList = response.results;
    });
  }
}

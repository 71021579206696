import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AccessManagementService } from "src/app/core/services/access-management.service";
import { PermissionItemComponent } from "../../../roles/permission-item/permission-item.component";

@Component({
  selector: "app-user-role-details",
  templateUrl: "./user-role-details.component.html",
  styleUrls: ["./user-role-details.component.scss"],
})
export class UserRoleDetailsComponent implements OnInit {
  @Output("showNextStep") showNextStep: EventEmitter<any> = new EventEmitter();
  @ViewChild(PermissionItemComponent)
  permissionComponent: PermissionItemComponent;

  roleList = [];
  selectedRoleList = null;
  permissionList = [];
  selectedRoleId: number;
  userList = [];
  userSelectId: FormGroup;
  selectedRoleName: string;
  @Input() fromParent;

  itemDisabled = false;

  constructor(
    private api: AccessManagementService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.getRoleListData();
    if(this.fromParent){
      if(this.fromParent?.user_permission?.id) {
        this.selectedRoleName = this.fromParent.role_name;
        this.itemDisabled = true;
      } else {
        this.itemDisabled = false;
      }
    }
    
  }

  selectRoleList(id) {
    this.api.getUserPermissionDetailsId(this.selectedRoleId).subscribe(
      (result: any) => {
        this.permissionList = result;
        this.permissionComponent.resetForm(this.permissionList);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSubmit() {}

  getRoleListData() {
    this.api.getUserRole().subscribe(
      (result: any) => {
        this.roleList = result;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}

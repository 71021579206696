import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AboutUsPageService } from 'src/app/core/services/about-us-page.service';
import { PermissionService } from 'src/app/core/services/permision.service';

@Component({
  selector: 'app-header-about',
  templateUrl: './header-about.component.html',
  styleUrls: ['./header-about.component.scss']
})
export class HeaderAboutComponent implements OnInit{

  headerForm: FormGroup;
  fileToUpload: File | null = null;
  hasChanges = false;
  isUpdating = false;
  isSubmitted = false;
  isNew = false;

  @Input() page;

  constructor(private fb: FormBuilder,
    private api: AboutUsPageService, private toastr: ToastrService,
    public permission: PermissionService){

  }

  ngOnInit(): void {
    this.resetForm();
    this.getData();
  }

  resetForm(data?){
    this.headerForm = this.fb.group({
      id: [data ? data.id : null],
      // image_url: [data ? data.image_url : ''],
      heading: [data ? data.heading : '', [Validators.required]],
      sub_heading: [data ? data?.sub_heading : '', [Validators.required]],
      // button_url: [data ? data.button_url : '', [Validators.required]],
    })
    this.onChanges();
  }

  onChanges(): void {
    this.headerForm.valueChanges.subscribe(() => {
      Object.keys(this.headerForm.controls).map((key) => {
        if(this.headerForm.get(key).dirty) this.hasChanges = true;
      })
      
    });
  }

  onSubmit(form){
    if(form.valid){
      this.isUpdating = true;
      const formData: FormData = new FormData();
      formData.append('heading', form.value.heading);
      formData.append('sub_heading', form.value.sub_heading);
      formData.append('button_label', form.value.button_label);
      formData.append('button_url', form.value.button_url);
      if(this.page == 'about-us'){
        if(this.isNew){
          this.api.postDescription(formData).subscribe(
            (result:any)=>{
              this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
              this.isNew = false;
            },
            ()=>{
              this.isUpdating = false;
            }
          )
        } else {
          this.api.setDescription(form.value.id, formData).subscribe(
            (result:any)=>{
              this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
            },
            ()=>{
              this.isUpdating = false;
            }
          )
        }
      } else {
    
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }

  }

  getData(){
    if(this.page=='about-us'){
      this.api.getDescription().subscribe(
        (result:any)=>{
          this.resetForm(result[0]);
          if(result.length==0) this.isNew = true;
          else this.isNew = false;
        })
    } else {
  
    }
  }
  
  checkPermission(type){
    return this.permission.hasPermission(type);
  }
}

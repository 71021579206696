import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { CategoryService } from "src/app/core/services/category.service";
import { ToastrService } from "ngx-toastr";
import { DomSanitizer } from "@angular/platform-browser";
import { ProductPageService } from "src/app/core/services/product-page.service";
import { CountryManagementService } from "src/app/core/services/country-management.service";
import { PermissionService } from "src/app/core/services/permision.service";
// pages: category, product
@Component({
  selector: "app-header-category",
  templateUrl: "./header-category.component.html",
  styleUrls: ["./header-category.component.scss"],
})
export class HeaderCategoryComponent implements OnInit {
  headerForm: FormGroup;
  fileToUpload: File | null = null;
  hasChanges = false;
  isUpdating = false;
  isSubmitted = false;
  isNew = false;

  @Input() page;
  @Input() country;
  @Input() category;
  @Input() product;
  publishPage: string;
  isSuggestion: boolean;

  constructor(
    private fb: FormBuilder,
    private api: CategoryService,
    private countryApi: CountryManagementService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private productApi: ProductPageService,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {
    this.resetForm();
    this.getData();
    switch (this.page) {
      case "category":
        this.publishPage = "CategoryDescription";
        break;
      case "product":
        this.publishPage = "ProductDescription";
        break;
      default:
        break;
    }
  }

  resetForm(data?) {
    this.headerForm = this.fb.group({
      id: [data ? data.id : null],
      banner_image_url: [data ? data.banner_image_url : ""],
      heading: [data ? data.heading : "-"],
      sub_heading: [data ? data.sub_heading : "-", [Validators.required]],
      description: [data ? data.description : "-", [Validators.required]],
      category: [this.category],
      headings: new FormArray([]),
    });
    if (data?.heading != undefined) {
      let heads = JSON.parse(data?.heading);
      heads.forEach((ao) => {
        (this.headerForm.get("headings") as FormArray).push(
          this.fb.group({
            text: [ao, [Validators.required]],
          })
        );
      });
    }
    this.onChanges();
  }

  heads(): FormArray {
    return this.headerForm.get("headings") as FormArray;
  }

  newheads(): FormGroup {
    return this.fb.group({
      text: ["", [Validators.required]],
    });
  }

  addheads() {
    this.heads().push(this.newheads());
  }

  removeheads(i: number) {
    this.isSubmitted = false;
    this.heads().removeAt(i);
  }

  onChanges(): void {
    this.headerForm.valueChanges.subscribe(() => {
      Object.keys(this.headerForm.controls).map((key) => {
        if (this.headerForm.get(key).dirty) this.hasChanges = true;
      });
    });
  }

  handleIcon(files: FileList) {
    this.hasChanges = true;
    this.fileToUpload = files.item(0);
    let unsafeImageUrl = URL.createObjectURL(this.fileToUpload);
    this.headerForm.value.banner_image_url =
      this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      const formData: FormData = new FormData();
      if (this.fileToUpload)
        formData.append("banner_image_url", this.fileToUpload);
      else formData.delete("banner_image_url");
      let heads = [];
      form.value.headings.forEach((ae) => {
        heads.push(ae.text);
      });
      formData.append("heading", JSON.stringify(heads));
      // formData.append('heading', form.value.heading);
      formData.append("sub_heading", form.value.sub_heading);
      formData.append("description", form.value.description);
      if (this.page == "category") {
        formData.append("category", this.category);
        if (this.isSuggestion)
          formData.append("url", form.value.banner_image_url);
        if (this.isNew) {
          this.api.postDescription(formData).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
              this.isNew = false;
            },
            () => {
              this.isUpdating = false;
            }
          );
        } else {
          this.api.setDescription(form.value.id, formData).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          );
        }
      } else {
        formData.append("product", this.product);
        if (this.isSuggestion)
          formData.append("url", form.value.banner_image_url);
        if (this.isNew) {
          this.productApi.postDescription(formData).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
              this.isNew = false;
            },
            () => {
              this.isUpdating = false;
            }
          );
        } else {
          this.productApi.setDescription(form.value.id, formData).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          );
        }
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  getData() {
    if (this.page == "category") {
      this.api.getDescription(this.category).subscribe((result: any) => {
        this.resetForm(result[0]);
        if (result.length == 0) {
          this.isSuggestion = true;
          this.isNew = true;
          this.getCountryData();
        } else {
          this.isSuggestion = false;
          this.isNew = false;
        }
      });
    } else {
      this.productApi.getDescription(this.product).subscribe((result: any) => {
        this.resetForm(result[0]);
        if (result.length == 0) {
          this.isSuggestion = true;
          this.isNew = true;
          this.getCountryData();
        } else {
          this.isSuggestion = false;
          this.isNew = false;
        }
      });
    }
  }

  getCountryData() {
    this.countryApi.getDescription(this.country).subscribe((result: any) => {
      if (result.length > 0) {
        if (result[0]?.heading != undefined) {
          let heads = JSON.parse(result[0]?.heading);
          this.headerForm
            .get("banner_image_url")
            .setValue(result[0]?.banner_image_url);
          this.toastr.warning(
            "These are the suggested data. Please verify and save."
          );
          heads.forEach((ao) => {
            (this.headerForm.get("headings") as FormArray).push(
              this.fb.group({
                text: [ao, [Validators.required]],
              })
            );
          });
        }
      } else {
        this.addheads();
      }
    });
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

<div>
    <div class="table-responsive border shadow">
        <table id="basic-datatable"
            class="table table-centered datatables dt-responsive nowrap table-card-list bg-white m-0 p-3">
            <thead>
                <tr *ngIf="isAdd">
                    <th colspan="3">
                        <div *ngIf="selectedProductList.length>0">
                            Selected <b>{{selectedProductList.length}}</b> products
                        </div>
                    </th>
                    <th class="text-center">
                        <select class="form-control" [(ngModel)]="suggestionType"
                            (change)="searchBy(suggestionType,termDetailsWord)">
                            <option value="name">Internal Name</option>
                            <option value="age_group">Age Group</option>
                            <option value="category">Category</option>
                            <option value="sub_category">Term Details</option>
                        </select>
                    </th>
                    <th class="text-center">
                        <input type="text" [(ngModel)]="termDetailsWord" class="form-control" placeholder="Keyword"
                            (keyup)="searchBy(suggestionType,termDetailsWord)">
                    </th>
                    <th class="d-flex">
                        <div *ngIf="relevantProductList.length>0" class="d-flex">
                            <label class="all-label">All</label>
                            <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                                <input type="checkbox" class="form-check-input" id="customSwitchsizelg"
                                    [(ngModel)]="selectAll" (change)="selectAllProducts($event)">
                            </div>
                        </div>
                    </th>
                </tr>
                <tr>
                    <th>Internal Name</th>
                    <th class="text-center">Age Group</th>
                    <th class="text-center">Category</th>
                    <th class="text-center">Term Details</th>
                    <th *ngIf="!isAdd" class="text-center">Country Form</th>
                    <th *ngIf="!isAdd" class="text-center">Workflow</th>
                    <th class="text-center">Active</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let table of relevantProductList;">
                    <td>
                        {{table.name}}
                    </td>
                    <td class="text-center">
                        {{table.age_group_code}}
                    </td>
                    <td class="text-center">
                        {{table.category_code}}
                    </td>
                    <td class="text-center">
                        {{table.sub_category_code}}
                    </td>
                    <td *ngIf="!isAdd" class="text-center">
                        {{table.country_form_name}} &nbsp;
                        <a class="cpr" (click)="openCountry(countryForm);editCountryForm(table.id)"><i class="fas fa-edit text-info"></i></a>
                    </td>
                    <td *ngIf="!isAdd" class="text-center">
                        {{table.work_flow_name}} &nbsp;
                        <a class="cpr" (click)="openWorkflow(workFlow);editWorkflow(table.id)"><i class="fas fa-edit text-info"></i></a>
                    </td>
                    <td class="d-flex">
                        <div *ngIf="isAdd" class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                            <input type="checkbox" class="form-check-input" id="customSwitchsizelg"
                                [(ngModel)]="table.create_product"
                                (change)="addRemoveProduct(table.create_product,table)">
                            <!-- [attr.checked]="table.create_product ? true : null" -->
                        </div>
                        <div *ngIf="!isAdd" class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                            <input type="checkbox" class="form-check-input" id="customSwitchsizelg"
                                [attr.checked]="table.is_active ? true : null"
                                (change)="addRemoveProduct(table.create_product,table)">
                        </div>
                    </td>
                </tr>
                <tr *ngIf="!isAdd && suggestionList.length>0">
                    <td colspan="6" class="text-center">
                        <div class="row">
                            <div class="offset-md-4 col-md-4">
                                <button (click)="showSuggestion=!showSuggestion;"
                                    class="btn btn-success m-3">{{showSuggestion ? 'Hide' : 'Show'}} Product
                                    Suggestions</button>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <table *ngIf="suggestionList.length>0 && showSuggestion"
            class="table table-centered datatables dt-responsive nowrap table-card-list bg-white m-0 p-3">
            <thead>
                <tr>
                    <th>Internal Name</th>
                    <th class="text-center">Age Group</th>
                    <th class="text-center">Category</th>
                    <th class="text-center">Term Details</th>
                    <th class="text-center">Action</th>
                </tr>
            </thead>
            <tbody>
                <tr class="bg-primary text-white" *ngFor="let item of suggestionList; let i = index;">
                    <td>
                        {{item.name}}
                    </td>
                    <td class="text-center">
                        {{item.age_group_code}}
                    </td>
                    <td class="text-center">
                        {{item.category_code}}
                    </td>
                    <td class="text-center">
                        {{item.sub_category_code}}
                    </td>
                    <td class="text-center">
                        <button class="btn btn-success ms-2" (click)="selectProductsFromSuggestion([item])">Add</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="w-100 text-end" *ngIf="suggestionList.length>0 && showSuggestion"><button
                (click)="selectProductsFromSuggestion(suggestionList)" class="btn btn-success m-3"><i
                    [ngClass]="{'fa fa-spin fa-spinner':isLoading, 'uil-arrow-right': !isLoading}"></i>Add All
                Products</button></div>
    </div>
    <button *ngIf="fromAdd && relevantProductList.length>0" [disabled]="isLoading"
        class="btn btn-success mt-2 float-end" type="submit" (click)="getSelectedProducts()">
        Submit <i [ngClass]="{'fa fa-spin fa-spinner':isLoading, 'uil-arrow-right': !isLoading}"></i>
    </button>
</div>

<ng-template #workFlow let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-workFlow">Work Flow</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
            <div class="my-1 row">
                <div class="col-md-12">
                    <label class="col-form-label">Select Workflow</label>
                    <ng-select [(items)]="workflowList" [multiple]="false" [placeholder]="'Select Workflow'"
                        bindLabel="name" bindValue="id" [(ngModel)]="workflowId" [clearable]="false">
                    </ng-select>
                </div>
            </div>
            <div class="my-3 row">
                <div class="col-md-12">
                    <button class="btn btn-primary mt-1 w-100" type="submit" (click)="onWorkflowSubmit();modal.close('Save click')"><i
                            class="bx bx-save"></i> Save
                    </button>
                </div>
            </div>
    </div>
</ng-template>

<ng-template #countryForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-countryForm">Country Form</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
            <div class="my-1 row">
                <div class="col-md-12">
                    <label class="col-form-label">Select Country Form</label>
                    <ng-select [(items)]="countryFormList" [multiple]="false" [placeholder]="'Select Country Form'"
                        bindLabel="name" bindValue="id" [(ngModel)]="countryFormId" [clearable]="false">
                    </ng-select>
                </div>
            </div>
            <div class="my-3 row">
                <div class="col-md-12">
                    <button class="btn btn-primary mt-1 w-100" type="submit" (click)="onCountryFormSubmit();modal.close('Save click')"><i
                            class="bx bx-save"></i> Save
                    </button>
                </div>
            </div>
    </div>
</ng-template>
import { Component, OnInit, inject } from "@angular/core";
import { NgbCalendar, NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { ReportsService } from "src/app/core/services/reports.service";
import { DateRangePickerComponent } from "./date-range-picker/date-range-picker.component";

@Component({
  selector: "app-chart-table",
  templateUrl: "./chart-table.component.html",
  styleUrls: ["./chart-table.component.scss"],
})
export class ChartTableComponent implements OnInit {
  tableData: any = [];
  page_details = {
    count: 0,
    next: "",
    previous: "",
    fromDate: "",
    toDate: "",
    product_type: "",
  };
  fiterTerm: string = "visa";
  dateToShow: string;
  displayMonths = 2;
  navigation = "select";
  showWeekNumbers = false;
  outsideDays = "visible";
  isLoading: boolean = false;
  calendar = inject(NgbCalendar);

  constructor(
    private reportsService: ReportsService,
    private modalService: NgbModal
  ) {}
  ngOnInit(): void {
    this.setData();
    this.getTableData();
  }
  setData() {
    const fromDate = this.calendar.getToday();
    this.page_details.fromDate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
    const toDate = this.calendar.getNext(fromDate, "d", 20);
    this.page_details.toDate = `${toDate.year}-${toDate.month}-${toDate.day}`;
    this.dateToShow =
      this.page_details.fromDate + "-" + this.page_details.toDate;
  }
  nextPage(event) {}

  getTableData() {
    if (this.fiterTerm) {
      this.tableData=[]
      this.isLoading = true;
      this.page_details.product_type = this.fiterTerm;
      this.reportsService.getTableData(this.page_details).subscribe(
        (res) => {
          this.tableData = res;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
    }
  }

  openDateRangeModal() {
    const modalRef = this.modalService.open(DateRangePickerComponent, {
      size: "ld",
    });
    modalRef.result.then((res) => {
      if (res) {
        this.dateToShow = res?.fromDate + "-" + res?.toDate;
        this.page_details.fromDate = res?.fromDate;
        this.page_details.toDate = res?.toDate;
        this.getTableData();
      }
    });
  }

  getData() {
    this.fiterTerm = "";
    if (this.fiterTerm) this.getTableData();
  }
}

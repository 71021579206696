<div class="container-fluid set-minwh">
    <div class="my-3 row">
      <div class="offset-md-8 col-md-4">
        <ng-select
          [(items)]="countryList"
          [multiple]="false"
          [placeholder]="'Select Country'"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="country"
          [clearable]="false"
          (change)="getAddOnForm()"
        ></ng-select>
      </div>
    </div>
    <div>

    </div>
    <div class="container">
        <form class="row" [formGroup]="addOnForm">
          <div class="col-md-4 mt-2">
            <div class="border shadow p-4 mt-2 bg-white">
              <div class="my-3 row">
                <div class="col-md-12">
                  <label class="col-form-label">Insurance</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Price"
                    formControlName="insurance"
                    numbersOnly
                  />
                
                </div>
                <div class="col-md-12">
                  <label class="col-form-label">Insurance Age gap(71-80)</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Age"
                    formControlName="insurance_71_80"
                    numbersOnly
                  />
                  <button
                    class="btn btn-success mt-1"
                    type="button"
                    (click)="postAddOnData()"
                  >
                    <i class="bx bx-save"></i> Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

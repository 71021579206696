import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { CountryManagementService } from 'src/app/core/services/country-management.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/core/services/shared.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { PermissionService } from 'src/app/core/services/permision.service';
// page: country
@Component({
  selector: 'app-suitable-visa',
  templateUrl: './suitable-visa.component.html',
  styleUrls: ['./suitable-visa.component.scss']
})
export class SuitableVisaComponent implements OnInit {
  visaForm: FormGroup;
  hasChanges = false;
  fileToUpload: File | [null] = [null];
  fileImgToUpload: File | [null] = [null];

  isUpdating = false;
  progress: number[] = [0];
  progressImg: number[] = [0];
  deletedvisa = [];
  categoryList = [];
  @Input() country;
  @Input() page;

  constructor(private fb: FormBuilder, private api: CountryManagementService,
    private sanitizer: DomSanitizer, private toastr: ToastrService, private sharedService: SharedService, public permission: PermissionService) { }

  ngOnInit(): void {
    this.resetForm();
    this.getHomevisa();
    this.getCategories(this.country);
  }

  resetForm() {
    this.visaForm = this.fb.group({
      visa: this.fb.array([
        this.fb.group({
          heading: ['', [Validators.required]],
          description_datas: new FormArray([]),
          amount: [0],
          currency_symbol: ['-'],
          icon_url: [''],
          image_url: [''],
          left_button_url: ['', [Validators.required]],
          left_button_label: ['', [Validators.required]],
          category: ['', [Validators.required]],
          is_updated: [true, [Validators.required]],
          is_deleted: [false, [Validators.required]],
        })])
    });
  }

  visa(): FormArray {
    return this.visaForm.get("visa") as FormArray
  }

  newvisa(): FormGroup {
    return this.fb.group({
      heading: ['', [Validators.required]],
      amount: [0],
      currency_symbol: ['-'],
      icon_url: [''],
      image_url: [''],
      left_button_url: ['', [Validators.required]],
      left_button_label: ['', [Validators.required]],
      category: ['', [Validators.required]],
      is_updated: [true, [Validators.required]],
      is_deleted: [false, [Validators.required]],
      description_datas: new FormArray([
        this.fb.group({
          text: '',
        })
      ]),
    })
  }

  addvisa() {
    this.visa().push(this.newvisa());
  }

  removevisa(i: number) {
    this.visaForm.value.visa[i].is_deleted = true;
    if (this.visaForm.value.visa[i].hasOwnProperty("id")) this.deletedvisa.push(this.visaForm.value.visa[i]);
    this.visa().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedvisa.forEach(ae => {
        ae.is_updated = false;
        form.value.visa.push(ae);
      })
      this.api.setSuitableVisa(form.value.visa).subscribe(
        () => {
          this.toastr.success("Content Updated Successfully!");
          this.getHomevisa();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        }
      )
    } else {
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  getHomevisa() {
    this.resetForm();
    this.api.getSuitableVisa(this.country).subscribe(
      (result: any) => {
        this.visaForm = this.fb.group({
          visa: new FormArray([]),
        });
        result.forEach(ao => {
          (this.visaForm.get('visa') as FormArray).push(
            this.fb.group({
              id: [ao.id, [Validators.required]],
              heading: [ao.heading, [Validators.required]],
              amount: [0],
              currency_symbol: ['-'],
              icon_url: [ao.icon_url],
              image_url: [ao.image_url],
              left_button_url: [ao.left_button_url, [Validators.required]],
              left_button_label: [ao.left_button_label, [Validators.required]],
              category: [ao.category, [Validators.required]],
              is_updated: [true, [Validators.required]],
              is_deleted: [false, [Validators.required]],
              description_datas: this.setDescriptions(ao),
            })
          );
          this.deletedvisa = [];
        });
      })
  }

  handleIcon(files: FileList, i, isIcon) {
    this.hasChanges = true;
    if (isIcon) this.fileToUpload[i] = files.item(0);
    else this.fileImgToUpload[i] = files.item(0);
    this.visaForm.get(['visa', i, 'is_updated']).setValue(true);

    const formData: FormData = new FormData();
    if (isIcon) formData.append('document', this.fileToUpload[i]);
    else formData.append('document', this.fileImgToUpload[i]);

    this.sharedService.uploadFile(formData).subscribe(
      (event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            if (isIcon) this.progress[i] = Math.round(100 * event.loaded / event.total);
            else this.progressImg[i] = Math.round(100 * event.loaded / event.total)
            break;
          case HttpEventType.Response:
            if (isIcon) {
              this.visaForm.get(['visa', i, 'icon_url']).setValue(event.body.document);
              this.progress[i] = 0;
            }
            else {
              this.visaForm.get(['visa', i, 'image_url']).setValue(event.body.document);
              this.progressImg[i] = 0;
            }
            break;
        }
      })
    if (isIcon) {
      let unsafeImageUrl = URL.createObjectURL(this.fileToUpload[i]);
      this.visaForm.value.visa[i].icon_url = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
    } else {
      let unsafeImageUrl = URL.createObjectURL(this.fileImgToUpload[i]);
      this.visaForm.value.visa[i].image_url = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
    }
  }

  setDescriptions(x) {
    let arr = new FormArray([])
    x.description.forEach(y => {
      arr.push(this.fb.group({
        text: y
      }))
    })
    return arr;
  }

  addNewDescription(control) {
    control.push(
      this.fb.group({
        text: ['']
      }));
  }

  deleteDescription(control, index) {
    control.removeAt(index)
  }

  getCategories(country_id) {
    this.sharedService.getCmsCategoryList(country_id).subscribe(
      (result: any) => {
        this.categoryList = result;
      })
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

<div class="container-fluid">
  <app-page-title title="Blog-Specific" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <app-preview-btn [page]="previewPage"></app-preview-btn>
          <ul ngbNav #justifiednavpills="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
            <li [ngbNavItem]="1">
              <a ngbNavLink>
                <span>Header</span>
              </a>
              <ng-template ngbNavContent>
                <!-- <app-header-contact-us [page]="specificPage"></app-header-contact-us> -->
                <app-media-header [page]="curComponent"></app-media-header>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>
                <span>Content&nbsp;</span>
              </a>
              <ng-template ngbNavContent>
                <app-specific-content [page]="page"></app-specific-content>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="justifiednavpills"></div>
        </div>
      </div>
    </div>
  </div>
</div>
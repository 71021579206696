<div *ngFor="let config of chartConfigs[index]; let i = index">
  <ngx-charts-pie-chart
    [view]="config.view"
    [scheme]="config.color"
    [results]="config.data"
    [labels]="config.label"
    [doughnut]="config.donught"
  >
  </ngx-charts-pie-chart>
</div>

import { Component, NgZone, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { GlobalQuestionsService } from 'src/app/core/services/global-questions.service';
import { ToastrService } from 'ngx-toastr';
import { CountryQuestionsService } from 'src/app/core/services/country-questions.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-order-modal',
  templateUrl: './order-modal.component.html',
  styleUrls: ['./order-modal.component.scss']
})


export class OrderModalComponent implements OnInit {

  currData: any;
  currQueData: any;
  selecteElemInd:number;

  isSectionLoading: boolean = false;
  isSectionChanged: boolean = false;

  isQueLoading: boolean = false;
  isQueChanged: boolean = false;


  constructor(public activeModal: NgbActiveModal, private ngZone: NgZone, private fb: FormBuilder,
    public globalQueService: GlobalQuestionsService, private toastr: ToastrService, private modalService: NgbModal, public countryQueSer: CountryQuestionsService) {

  }

  ngOnInit(): void {
  }

  getSectionsWiseQuestion(sectionData,index) {
    this.selecteElemInd = index;
    this.countryQueSer.getSectionsWiseQuestion(sectionData.id).subscribe((data) => {

      this.currQueData = data;
      
    }, (err => {
      console.log(err);

    }))
  }

  sectionOrderSave() {

    if (!this.isSectionChanged) {
      this.toastr.info('Please change something!')
      return
    }

    this.isSectionLoading = true;
    let dataToSend: any;
    let dataToAggrigate: any[] = [];

    this.currData.forEach((element, index) => {

      dataToAggrigate.push({ id: element.id, position: index });

    });

    dataToSend = { form_section_positions: dataToAggrigate };

    this.countryQueSer.editSectionPosition(dataToSend).subscribe((res) => {

      this.isSectionLoading = false;
      this.isSectionChanged = false;
      this.toastr.success('Successfully Updated');

    }, (err => {

      this.isSectionLoading = false;
      console.log(err);

    }));


  }

  questionOrderSave() {

    if (!this.isQueChanged) {
      this.toastr.info('Please change something!')
      return
    }

    this.isQueLoading = true;
    let dataToSend: any;
    let dataToAggrigate: any[] = [];

    this.currQueData.forEach((element, index) => {

      dataToAggrigate.push({ id: element.id, position: index });

    });

    dataToSend = { form_section_positions: dataToAggrigate };

    this.countryQueSer.editQuePosition(dataToSend).subscribe((res) => {

      this.isQueLoading = false;
      this.isQueChanged = false;
      this.toastr.success('Successfully Updated');
    }, (err => {
      this.isQueLoading = false;
      console.log(err);
    }));
  }


  drop(event: CdkDragDrop<string[]>) {

    this.isSectionChanged = true;
    moveItemInArray(this.currData, event.previousIndex, event.currentIndex);
  }

  dropQue(event: CdkDragDrop<string[]>) {
    this.isQueChanged = true;
    moveItemInArray(this.currQueData, event.previousIndex, event.currentIndex);
  }







}

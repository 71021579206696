import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'news-author-page',
  templateUrl: './news-author-page.component.html',
  styleUrls: ['./news-author-page.component.scss']
})
export class NewsAuthorPageComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  page = 'news';
  specificPage = 'author_news_page';
  previewPage="author-news";

  ngOnInit(){
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'About Us Page', active: true }];
  }
}

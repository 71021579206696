import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { VisaManageService } from "src/app/core/services/visa-manage.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-data-modal",
  templateUrl: "./data-modal.component.html",
  styleUrls: ["./data-modal.component.scss"],
})
export class DataModalComponent {
  @Input() dataDetails;
  @Input() role;
  isConfirming = false;
  data_key: string;
  data_value: string;

  constructor(
    public activeModal: NgbActiveModal,
    public api: VisaManageService,
    public toastr: ToastrService
  ) {}

  closeModal(status) {
    this.activeModal.close(status);
  }

  onConfirm() {
    this.isConfirming = true;
    let data: any;
    if (this.role === "Agent") {
      data = {
        pax_id: parseInt(this.dataDetails.pax_id, 10),
        key: this.dataDetails.key,
        value: this.data_value,
      };
    } else {
      data = {
        pax_id: parseInt(this.dataDetails.pax_id, 10),
        key: this.dataDetails.key,
        value: this.data_value,
        role: this.role,
      };
    }
    this.api.dataUpdate(this.dataDetails.api, data).subscribe(
      (result: any) => {
        this.isConfirming = false;
        this.toastr.success(result.message);
        this.closeModal(true);
      },
      (error) => {
        this.isConfirming = false;
      }
    );
  }
}

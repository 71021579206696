<div class="container">
    <form [formGroup]="visaForm" (ngSubmit)="onSubmit(visaForm)">
        <div class="row">
            <div class="col-md-4 mt-2" formArrayName="visa" *ngFor="let item of visa().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Icon URL</label>
                            <div class="d-flex">
                                <input type="file" accept="image/*" (change)="handleIcon($event.target.files,i,true)"
                                    class="form-control" placeholder="Upload Icon">
                                <a [href]="visaForm.value.visa[i].icon_url" target="_blank"><img class="ms-1"
                                        height="40" [src]="visaForm.value.visa[i].icon_url"></a>
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="progress[i]>0">
                            <div class="mt-2 progress-bar">
                                <div class="progress" [style.width.%]="progress[i]">
                                    {{progress[i]}}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Image URL</label>
                            <div class="d-flex">
                                <input type="file" accept="image/*" (change)="handleIcon($event.target.files,i,false)"
                                    class="form-control" placeholder="Upload Image">
                                <a [href]="visaForm.value.visa[i].image_url" target="_blank"><img class="ms-1"
                                        height="40" [src]="visaForm.value.visa[i].image_url"></a>
                            </div>
                        </div>
                        <div class="col-md-12" *ngIf="progressImg[i]>0">
                            <div class="mt-2 progress-bar">
                                <div class="progress" [style.width.%]="progressImg[i]">
                                    {{progressImg[i]}}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="col-form-label">Description</label>
                                <div formArrayName="description_datas" class="btn_grp">
                                    <div *ngFor="let lines of item.get('description_datas').controls; let j=index">
                                        <div [formGroupName]="j" class="description">

                                            <div class="form-group d-flex my-2">
                                                <input formControlName="text" class="form-control" type="text"
                                                    placeholder="Description" />
                                                <button type="button"
                                                    *ngIf="item.get('description_datas').controls.length!=1"
                                                    class="deletebtn btn btn-pseudo remove"
                                                    (click)="deleteDescription(item.controls.description_datas, j);dataChanged(i);"><i
                                                        class="fa fa-times text-danger"></i></button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-sm btn-primary mt-2 float-end"
                                    (click)="addNewDescription(item.controls.description_datas)"
                                    *ngIf="item.get('description_datas').controls.length<10">
                                    <i class="fa fa-plus"></i> Add Description
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="my-2 col-md-6">
                            <label class="col-form-label">Category</label>
                            <ng-select [(items)]="categoryList" [multiple]="false" [readonly]="true"
                                [placeholder]="'Select Category'" bindLabel="name" bindValue="id"
                                formControlName="category" [clearable]="false"></ng-select>
                        </div>
                        <div class="my-2 col-md-6">
                            <label class="col-form-label">Button Label</label>
                            <input type="text" class="form-control" placeholder="Button Label"
                                formControlName="left_button_label">
                        </div>
                        <div class="my-2 col-md-12">
                            <label class="col-form-label">Button URL</label>
                            <input type="text" class="form-control" placeholder="Button URL"
                                formControlName="left_button_url">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button
                        *ngIf="(page=='country' && checkPermission('content_management_country_create'))&&(page=='country' && checkPermission('content_management_country_edit'))"
                        class="btn btn-info mt-1 me-1 col-md-12" type="submit"><i class="bx bx-save"></i> Save
                        All</button>
                    <app-publish-btn *ngIf="(page=='country' && checkPermission('content_management_country_publish'))"
                        class="col-md-12 p-0 mt-1" [publish]="'CategoryDetail'" [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consultant-page',
  templateUrl: './consultant-page.component.html',
  styleUrls: ['./consultant-page.component.scss']
})
export class ConsultantPageComponent {
  breadCrumbItems: Array<{}>;
  page = 'consultant';

  ngOnInit(){
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'Consultant Page', active: true }];
  }
}

import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { countries } from '../models/visa-management.model';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  getCountryNames(): Observable<any>  {
    return this.http.get<any>(`${environment.apiUrl}/country-list/`).pipe(retry(2));
  }

  getHotelAndFlight(){
    return this.http.get(`${environment.apiUrl}/add-on-hotel-flight/`).pipe(retry(2))
  }

  postHotelAndFlight(data:any){
    return this.http.post(`${environment.apiUrl}/add-on-hotel-flight/`,data).pipe(retry(2))
  }

  editHotelAndFlight(data:any,id:any){
    return this.http.patch(`${environment.apiUrl}/add-on-hotel-flight/${id}/`,data).pipe(retry(2))
  }

  getCountryAllData(id): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/country-list/${id}/complete-data/`).pipe(retry(2));
  }

  getCountryDetails(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/country/`).pipe(retry(2));
  }

  setCountryDetails(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/country/`, data)
  }

  updateCountryDetails(id,data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/country/${id}/`, data)
  }

  getJurisdictionDetails(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/jurisdiction`).pipe(retry(2));
  }

  getJurisdictionsById(id): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/jurisdiction/?country=${id}`).pipe(retry(2));
  }

  setJurisdictionDetails(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/jurisdiction/`, data)
  }

  updateJurisdictionDetails(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/jurisdiction/jurisdriction-bulk-update/`, data)
  }

  getAgeGroupDetails(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/sub-category-age-group/`).pipe(retry(2));
  }

  getAgeGroupDetailsBySubCat(sub_category): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/sub-category-age-group/?sub_category=${sub_category}`).pipe(retry(2));
  }

  setAgeGroupDetails(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/sub-category-age-group/`, data)
  }

  updateAgeGroupDetails(data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/sub-category-age-group/bulk-update/`, data)
  }

  getCatTypeDetails(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/category/`).pipe(retry(2));
  }

  getSubcategories(id): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/category/${id}/`).pipe(retry(2));
  }

  setSubcategories(data): Observable<any>  {
    return this.http.post(`${environment.apiUrl}/sub-categories/`, data);
  }

  editSubcategories(id,data): Observable<any>  {
    return this.http.put(`${environment.apiUrl}/sub-categories/${id}/`, data);
  }

  setCatTypeDetails(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/category/`, data)
  }

  editCatTypeDetails(id,data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/category/${id}/`, data)
  }

  getStatesList(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/states/`).pipe(retry(2));
  }

  getPossibleProducts(id): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/country/${id}/suggested-products/`).pipe(retry(2));
  }

  setProductList(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/products/`, data)
  }

  changeProductStatus(id,data): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/products/${id}/`, data)
  }


  getProductListByCountry(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/products/?country=${id}`).pipe(retry(2));
  }

  getCategoriesByCountry(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/category/?country=${id}`).pipe(retry(2));
  }

  getAgeGroupsByCountry(id): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/sub-category-age-group/?sub_category__category__country=${id}`).pipe(retry(2));
  }

  setDisplayStatus(id,data): Observable<any>  {
    return this.http.put(`${environment.apiUrl}/country/${id}/display-status/`, data).pipe(retry(2));
  }

  setLeadFormStatus(id,data): Observable<any>  {
    return this.http.put(`${environment.apiUrl}/country/${id}/lead-form-status/`, data).pipe(retry(2));
  }

  getRequiredDocs(id): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/required-documents/?country=${id}`).pipe(retry(2));
  }

  setRequiredDocs(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/required-documents/`, data)
  }

  editRequiredDocs(id,data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/required-documents/${id}/`, data)
  }

  deleteRequiredDocs(id): Observable<any>  {
    return this.http.delete(`${environment.apiUrl}/required-documents/${id}/`).pipe(retry(2));
  }

  // Voucher 
  getVoucher(country): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/products/voucher/?country=${country}`).pipe(retry(2));
  }

  setVoucher(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/products/voucher/`, data);
  }

  // Workflow

  setWorkflowProduct(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/workflow/product-workflows/`, data);
  }

  saveWorkflowProduct(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/workflow/product-workflows-save/`, data);
  }

  // country Form

  getCountryFormProduct(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/product-country-form/?id=${id}`).pipe(retry(2));
  }

  saveCountryFormProduct(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/product-country-form/${id}/`, data);
  }

  sortCateogoryOrder(data:any){
    return this.http.put(`${environment.apiUrl}/category/category-position/`,data)
  }

  getAddOnData(id:any){
    return this.http.get(`${environment.apiUrl}/add-on-insurance/?country_id=${id}`)
  }

  postAddOnData(data:any){
    return this.http.post(`${environment.apiUrl}/add-on-insurance/?country_id=${data.country}`,data)
  }

  editAddOnData(id:any,data:any){
    return this.http.patch(`${environment.apiUrl}/add-on-insurance/${id}/`,data)
  }

  changeHotel(countryId:number,data){
    return this.http.put(`${environment.apiUrl}/country/${countryId}/hotel-status/`,data)
  }

  changeFlight(countryId:number,data){
    return this.http.put(`${environment.apiUrl}/country/${countryId}/flight-status/`,data)
  }

  chnageInsurance(countryId:number,data){
    return this.http.put(`${environment.apiUrl}/country/${countryId}/insurance-status/`,data) 
  }

  chnageForex(countryId:number,data){
    return this.http.put(`${environment.apiUrl}/country/${countryId}/forex-status/`,data) 
  }
  chnageItinerary(countryId:number,data){
    return this.http.put(`${environment.apiUrl}/country/${countryId}/itinerary-status/`,data) 
  }

  chnageCourier(countryId:number,data){
    return this.http.put(`${environment.apiUrl}/country/${countryId}/courier-status/`,data) 
  }

  changeCategoryStatus(catId:number,data){
    return this.http.put(`${environment.apiUrl}/category/${catId}/display-status/`,data)
  }

  changeCategoryCourier(catId:number,data){
    return this.http.put(`${environment.apiUrl}/category/${catId}/courier-status/`,data)
  }

  changeSubCategoryStatus(subCatId:number,data){
    return this.http.put(`${environment.apiUrl}/sub-categories/${subCatId}/display-status/`,data)
  }
  changeSubCategoryCourier(subCatId:number,data){
    return this.http.put(`${environment.apiUrl}/sub-categories/${subCatId}/courier-status/`,data)
  }
}

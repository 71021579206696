import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { SharedService } from 'src/app/core/services/shared.service';
import { HomeManagementService } from 'src/app/core/services/home-management.service';
import { CountryManagementService } from 'src/app/core/services/country-management.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { PermissionService } from 'src/app/core/services/permision.service';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popular-destination',
  templateUrl: './popular-destination.component.html',
  styleUrls: ['./popular-destination.component.scss']
})
export class PopularDestinationComponent implements OnInit {

  destinationsForm: FormGroup;
  hasChanges = false;

  country;
  category;
  product;
  countryList = [];
  categoryList = [];
  productList = [];
  productListCopy = [];
  isSubmitted = false;
  isUpdating = false;
  @Input() page;
  deletedOffers = [];
  destinationsList = [];
  newPopDest: any;
  showListing = false;
  isPosChanging = false;

  constructor(private fb: FormBuilder, private api: HomeManagementService, private countryApi: CountryManagementService,
    private modalService: NgbModal, private toastr: ToastrService, private sharedService: SharedService, public permission: PermissionService) { }

  ngOnInit(): void {
    this.country = this.sharedService.selectedCmsCountryId;
    this.category = this.sharedService.selectedCmsCategoryId;
    this.product = this.sharedService.selectedCmsCategoryId;
    this.countryList = this.sharedService.cmsCountryList;
    this.categoryList = this.sharedService.cmsCategoryList;
    this.productList = this.sharedService.cmsProductList;
    this.productListCopy = [...this.sharedService.cmsProductList];
    this.resetForm();
    if (this.countryList.length == 0) this.getCountriesList();
    if (this.country) this.getCategories(this.country);
    if (this.sharedService.selectedCmsCategoryId) this.getProducts(this.sharedService.selectedCmsCategoryId);
    if (this.page == 'category') {
      let productsList = this.sharedService.cmsProductList;
      this.productListCopy = [...this.sharedService.cmsProductList];
      let ids = [...new Set(this.destinationsList.map(a => a.product))]; //unique array from ids
      productsList.forEach((ae, i) => {
        if (ids.includes(ae.id)) this.productList = this.productList.filter(ao => ae.id != ao.id);
      })
    }
    if (this.page != 'country') this.getDestinations();
  }

  resetForm() {
    this.destinationsForm = this.fb.group({
      destinations: this.fb.array([])
    });
  }

  destinations(): FormArray {
    return this.destinationsForm.get("destinations") as FormArray
  }

  newDestinations(): FormGroup {
    return this.fb.group({
      image_url: [''],
      heading: ['', [Validators.required]],
      price_label: ['', [Validators.required]],
      price: ['', [Validators.required]],
      tax: ['', [Validators.required]],
      button_url: ['', [Validators.required]],
      button_label: ['', [Validators.required]],
      side_button_url: ['-'],
      side_button_label: ['-'],
      is_starred: [false, [Validators.required]],
      country_starred: [false, [Validators.required]],
      category_starred: [false, [Validators.required]],
      product: [null, [Validators.required]],
      is_updated: [true, [Validators.required]],
      is_deleted: [false, [Validators.required]],
      priority: [1],
      description_datas: [
        [{ text: "-" }]
      ],
      key_values: new FormArray([
        this.fb.group({
          key: ['', [Validators.required]],
          value: ['', [Validators.required]],
        })
      ]),
    })
  }

  addDestinations() {
    this.destinations().push(this.newDestinations());
  }

  removeDestinations(i: number) {
    this.isSubmitted = false;
    this.destinationsForm.value.destinations[i].is_deleted = true;
    if (this.destinationsForm.value.destinations[i].hasOwnProperty("id")) this.deletedOffers.push(this.destinationsForm.value.destinations[i]);
    this.destinations().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedOffers.forEach(ae => {
        ae.is_updated = false;
        form.value.destinations.push(ae);
      })
      this.countryApi.setProductDetail(form.value.destinations).subscribe(
        (result: any) => {
          this.isUpdating = false;
          this.getDestinations();
          this.toastr.success("Content Updated Successfully!");
          this.newPopDest = null;
        },
        error => {
          this.isUpdating = false;
          this.isSubmitted = true;
        })
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  setDescriptions(x) {
    let arr = new FormArray([])
    x.key_values.forEach(y => {
      arr.push(this.fb.group({
        key: [y.key, [Validators.required]],
        value: [y.value, [Validators.required]]
      }))
    })
    return arr;
  }

  addNewDescription(control) {
    this.isSubmitted = false;
    control.push(
      this.fb.group({
        key: ['', [Validators.required]],
        value: ['', [Validators.required]]
      }));
  }

  deleteDescription(control, index) {
    control.removeAt(index)
  }

  dataChanged(i) {
    this.destinationsForm.value.destinations[i].is_updated = true;
  }

  getCountriesList() {
    this.sharedService.getCmsCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response;
        this.sharedService.cmsCountryList = this.countryList;
      })
  }

  selectCountry(country) {
    this.sharedService.selectedCmsCountryId = country;
    this.category = null;
    this.product = null;
    this.getCategories(country)

  }

  getCategories(country_id) {
    this.category = null;
    this.product = null;
    this.categoryList = [];
    this.productList = [];
    this.sharedService.getCmsCategoryList(country_id).subscribe(
      (result: any) => {
        this.categoryList = result;
      })
  }

  getProducts(category) {
    this.product = null;
    this.productList = [];
    this.sharedService.getCmsProductList(category).subscribe(
      (result: any) => {
        this.productList = [...result];
        let ids = [...new Set(this.destinationsList.map(a => a.product))]; //unique array from ids
        result.forEach((ae, i) => {
          if (ids.includes(ae.id)) this.productList = this.productList.filter(ao => ae.id != ao.id);
        })
      })
  }

  setData(result) {
    this.destinationsList = result;
    this.destinationsForm = this.fb.group({
      destinations: new FormArray([]),
    });
    result.forEach(ao => {
      (this.destinationsForm.get('destinations') as FormArray).push(
        this.fb.group({
          id: [ao.id, [Validators.required]],
          image_url: [ao.image_url],
          heading: [ao.heading, [Validators.required]],
          price_label: [ao.price_label, [Validators.required]],
          price: [ao.price, [Validators.required]],
          tax: [ao.tax, [Validators.required]],
          button_url: [ao.button_url, [Validators.required]],
          button_label: [ao.button_label, [Validators.required]],
          side_button_url: ['-'],
          side_button_label: ['-'],
          is_starred: [ao.is_starred, [Validators.required]],
          country_starred: [ao.country_starred, [Validators.required]],
          category_starred: [ao.category_starred, [Validators.required]],
          product: [ao.product, [Validators.required]],
          is_updated: [false, [Validators.required]],
          is_deleted: [false, [Validators.required]],
          priority: [1],
          description_datas: [[{ text: "-" }]],
          key_values: this.setDescriptions(ao),
        })
      );
    });
  }

  getDestinations() {
    if (this.page == 'home') {
      this.countryApi.getProductDetail(true).subscribe(
        (result: any) => {
          if (result.length == 0) this.toastr.warning("No Products Available!");
          this.setData(result);
        })
    } else if (this.page == 'country') {
      // this.countryApi.getProductDetail(null, true, this.country).subscribe(
      //   (result: any) => {
      //     if (result.length == 0) this.toastr.warning("No Products Available!");
      //     this.setData(result);
      //   })
      this.getProductsByCategory(this.category)
    } else if (this.page == 'category') {
      this.countryApi.getProductDetail(null, null, null, true, this.sharedService.selectedCmsCategoryId).subscribe(
        (result: any) => {
          if (result.length == 0) this.toastr.warning("No Products Available!");
          this.setData(result);
        })
    }
  }

  isStarredChange(i) {
    this.destinationsForm.get(['destinations', i, 'is_updated']).setValue(true);
    if (this.page == 'home') {
      this.destinationsForm.get(['destinations', i, 'is_starred']).setValue(!this.destinationsForm.value.destinations[i].is_starred);
    } else if (this.page == 'country') {
      this.destinationsForm.get(['destinations', i, 'country_starred']).setValue(!this.destinationsForm.value.destinations[i].country_starred);
    } else if (this.page == 'category') {
      this.destinationsForm.get(['destinations', i, 'category_starred']).setValue(!this.destinationsForm.value.destinations[i].category_starred);
    }
  }

  addProduct(product) {
    // let query = {};
    // if (this.page == 'home') query = { product_id: product, is_starred: true };
    // else if (this.page == 'country') query = { product_id: product, country_starred: true };
    // else if (this.page == 'category') query = { product_id: product, category_starred: true };
    // this.sharedService.popularStarRating(query).subscribe(result => {
    //   this.toastr.success("Product Added! Save to reflect changes.")
    // })
    let data = this.productList.filter(ae => ae.id == product);
    this.destinations().push(
      this.fb.group({
        id: [this.newPopDest ? this.newPopDest.id : null],
        image_url: [this.newPopDest ? this.newPopDest.image_url : ''],
        heading: [this.newPopDest ? this.newPopDest.heading : '', [Validators.required]],
        price_label: [this.newPopDest ? this.newPopDest.price_label : '', [Validators.required]],
        price: [this.newPopDest ? this.newPopDest.price : '', [Validators.required]],
        tax: [this.newPopDest ? this.newPopDest.tax : '', [Validators.required]],
        button_url: [this.newPopDest ? this.newPopDest.button_url : '', [Validators.required]],
        button_label: [this.newPopDest ? this.newPopDest.button_label : '', [Validators.required]],
        side_button_url: ['-'],
        side_button_label: ['-'],
        is_starred: [this.page == 'home' ? true : false, [Validators.required]],
        country_starred: [this.page == 'country' ? true : false, [Validators.required]],
        category_starred: [this.page == 'category' ? true : false, [Validators.required]],
        product: [product, [Validators.required]],
        is_updated: [true, [Validators.required]],
        is_deleted: [false, [Validators.required]],
        priority: [1],
        // key_values: new FormArray([
        //   this.fb.group({
        //     text: ['', [Validators.required]],
        //   })
        // ]),
        description_datas: [[{ text: "-" }]],
        key_values: this.setDescriptions(this.newPopDest),
      })
    );
    this.product = null;
    this.productList = this.productList.filter(ae => ae.id !== product);
    this.destinationsList.push(this.destinationsForm.get(['destinations', this.destinationsForm.value.destinations.length - 1]).value);
  }

  getPopularDestination(product_id) {
    this.sharedService.getCmsSubCategoryDataById(product_id).subscribe(
      result => {
        this.newPopDest = result.length > 0 ? result[0] : null;
      },
      error => {
        console.log(error)
      }
    )
  }

  getProductsByCategory(category) {
    this.sharedService.getCmsSubCategoryDataByCatId(category).subscribe(
      result => {
        this.resetForm();
        this.setData(result);
        // this.newPopDest = result.length>0 ? result[0] : null;
      },
      error => {
        console.log(error)
      }
    )
  }

  getProductName(id) {
    let name = this.productListCopy.filter(ae => ae.id)[0] ? this.productList.filter(ae => ae.id)[0] : '';
    return name;
  }

  drop(event: CdkDragDrop<string[]>) {
    this.isPosChanging = true;
    moveItemInArray(this.destinations().controls, event.previousIndex, event.currentIndex);

    let pos = [];
    this.destinations().controls.forEach((ae: any, i) => {
      pos.push({
        id: ae.value.id,
        priority: i
      })
    });
    let datas = {
      priority: pos
    }
    this.api.changePositionPopular(datas).subscribe(
      data => {
        this.isPosChanging = false;
        this.getDestinations();
        this.toastr.success(`Position Changed Successfully!`);
      },
      error => {
        console.log(error);
        this.isPosChanging = false;
        this.toastr.error(`Position Reverted!`, `Oops! API Failed!`);
      })
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  openUploadModal(i) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.destinationsForm.get(['destinations', i, 'image_url']).setValue(result.image);
        this.destinationsForm.get(['destinations', i, 'is_updated']).setValue(true);
      }
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VisaManageService } from 'src/app/core/services/visa-manage.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss']
})
export class NotificationsListComponent implements OnInit{

  @Input() notifications;

  constructor(public activeModal: NgbActiveModal, public api: VisaManageService, public toastr: ToastrService){
  }

  ngOnInit(): void {
  }

  closeModal(status) {
    this.activeModal.close(status);
  }

  

}

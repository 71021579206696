<div class="col-md-12">
   
    <div class="border shadow p-3">
        <div class="d-flex justify-content-end">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()"></button>
        </div>
        
        <form  [formGroup]="catForm" (ngSubmit)="onCatSubmit(catForm)">
            <div class="mt-2 row">
                <div class="col-md-12">
                    <label class="col-form-label">Category Name</label>
                    <input type="text" class="form-control" placeholder="Enter Name"
                    formControlName="name"
                    [ngClass]="{'invalid': catForm.get('name').invalid && isSubmitted}"
                    (keyup)="generateUrlParam(true, catForm.value.name)"
                       >
                </div>
                <div class="col-md-12">
                    <div class="form-check mt-2">
                        <input type="checkbox" id="forCat" class="form-check-input"
                        [(ngModel)]="isSuggestCat" [ngModelOptions]="{standalone: true}"
                        (change)="generateUrlParam(true, catForm.value.name)" >
                        <label for="forCat" class="form-check-label"> Show Suggestion for URL
                            Param</label>
                    </div>
                </div>
            </div>
            <div class="mt-2 row">
                <div class="col-md-12">
                    <label class="col-form-label">Internal Code</label>
                    <input type="text" class="form-control" placeholder="Enter Internal Code"
                    formControlName="internal_code"
                    [ngClass]="{'invalid': catForm.get('internal_code').invalid && isSubmitted}" >
                </div>
            </div>
            <div class="mt-2 row">
                <div class="col-md-12">
                    <label class="col-form-label">URL Param</label>
                    <input type="text" class="form-control" placeholder="Enter URL Param"
                    (keyup)="checkURLParam(catForm.value.url_param, true)"
                    formControlName="url_param"
                    [ngClass]="{'invalid': catForm.get('url_param').invalid && isSubmitted}">
                </div>
            </div>
            <div class="mt-2 row">
                <div class="col-md-12">
                    <button class="btn btn-primary mt-1 w-100" type="submit"
                    [disabled]="isSubmittingCat || isUrlCat"><i class="uil-plus"></i>
                    {{category?'Update':'Add'}}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="w-100 text-end mt-3" *ngIf="applicationForm.value.steps.length>0">
    <a class="cpr btn btn-primary" 
        (click)="showSortModal(applicationForm.value.steps, 'heading')">
        <i class="fa fa-sort" aria-hidden="true"></i> Change Position</a>
</div>
<div class="container">
    <form [formGroup]="applicationForm" (ngSubmit)="onSubmit(applicationForm)">
        <div class="row" cdkDropList (cdkDropListDropped)="drop($event)">
            <div class="col-md-6 mt-2" formArrayName="steps" *ngFor="let item of steps().controls; let i = index;"
                cdkDrag>
                <div class="example-handle" cdkDragHandle></div>
                <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="steps().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removesteps(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                                (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('heading').invalid && isSubmitted}">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Description</label>
                            <!-- <textarea row="5" class="form-control" placeholder="Description" formControlName="description" (change)="dataChanged(i)"[ngClass]="{'invalid': item.get('description').invalid && isSubmitted}"></textarea> -->
                            <ckeditor [editor]="Editor" formControlName="description" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('description').invalid && isSubmitted}"
                                data="<p>Content of the editor.</p>"></ckeditor>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <!-- <a href="javascript:void(0)" class="m-auto" (click)="addsteps()"><i class="uil-plus fa-5x text-success"></i></a> -->
                <div class="row">
                    <button
                        *ngIf="(page=='home' && checkPermission('content_management_home_create')) || (page=='country' && checkPermission('content_management_country_create')) || (page=='consultant' && checkPermission('content_management_consultant_create')) || (page=='category' &&checkPermission('content_management_category_create')) || (page=='product' && checkPermission('content_management_product_create'))"
                        class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addsteps()"><i
                            class="uil-plus"></i> Add</button>
                    <button
                        *ngIf="(page=='home' && checkPermission('content_management_home_create')) || (page=='country' && checkPermission('content_management_country_create')) || (page=='consultant' && checkPermission('content_management_consultant_create')) || (page=='category' &&checkPermission('content_management_category_create')) || (page=='product' && checkPermission('content_management_product_create')) &&(page=='home' && checkPermission('content_management_home_edit')) || 
                    (page=='country' && checkPermission('content_management_country_edit')) || (page=='consultant' && checkPermission('content_management_consultant_edit')) || (page=='category' &&checkPermission('content_management_category_edit')) || (page=='product' && checkPermission('content_management_product_edit'))"
                        class="btn btn-info mt-1 col-md-12" type="submit"><i
                            [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                        Save All</button>
                    <!-- <app-publish-btn class="col-md-12 p-0 mt-1" [publish]="publishPage" [page]="page" [pseudo_page]="pseudo_page" [value_from_page]="valueFromPage"></app-publish-btn> -->

                    <app-publish-btn
                        *ngIf="(page=='home' && checkPermission('content_management_home_publish')) || (page=='country' && checkPermission('content_management_country_publish')) || (page=='consultant' && checkPermission('content_management_consultant_publish')) || (page=='category' &&checkPermission('content_management_category_publish')) || (page=='product' && checkPermission('content_management_product_publish'))"
                        class="col-md-12 p-0 mt-1" [publish]="publishPage" [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
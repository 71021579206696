import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedService } from 'src/app/core/services/shared.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { SettingsService } from 'src/app/core/services/settings.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-integration',
  templateUrl: './add-integration.component.html',
  styleUrls: ['./add-integration.component.scss']
})
export class AddIntegrationComponent implements OnInit {

  @Input() details;
  integrationForm: FormGroup;
  isSubmitted = false;
  isAdding = false;

  fileToUpload: File | null = null;
  progress: number = 0;


  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, public sharedService: SharedService, private api: SettingsService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.resetFormDetails(this.details);
  }

  closeModal(data?) {
    this.activeModal.close(data);
  }

  resetFormDetails(data?) {
    this.integrationForm = this.fb.group({
      id: [data ? data.id : null],
      name: [data ? data.name : "", [Validators.required]],
      icon_url: [data ? data.icon_url : "", [Validators.required]],
      key_values: new FormArray([]),
    });
    if (data.key_values) data.key_values.forEach(ae => this.addKeyValue(ae));
    else this.addKeyValue();
  }

  get keyValues(): FormArray {
    return this.integrationForm.get("key_values") as FormArray
  }

  newKeyValue(data?): FormGroup {
    return this.fb.group({
      key: data ? data.key : "",
      value: data ? data.value : ""
    })
  }

  addKeyValue(data?) {
    this.keyValues.push(this.newKeyValue(data));
  }

  removeKeyValue(i: number) {
    this.keyValues.removeAt(i);
  }

  onSubmit(form) {
    if(form.valid){
      this.isAdding = true;
      if (form.value.id) {
        this.api.updateIntegrationsList(form.value.id, form.value).subscribe(
          result=>{
            this.isAdding = false;
            this.closeModal(result);
            this.toastr.success("Updated Successfully!")
          },
          error=>{
            this.isAdding = false;
          }
        )
      } else {
        this.api.setIntegrationsList(form.value).subscribe(
          result=>{
            this.isAdding = false;
            this.closeModal(result);
            this.toastr.success("Added Successfully!")
          },
          error=>{
            this.isAdding = false;
          }
        )
      }
    }
  }

  handleIcon(files: FileList) {
    this.fileToUpload = files.item(0);
    const formData: FormData = new FormData();
    formData.append("document", this.fileToUpload);
    this.sharedService
      .uploadFile(formData)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress = Math.round((100 * event.loaded) / event.total);
            break;
          case HttpEventType.Response:
            this.integrationForm.get("icon_url").setValue(event.body.document);

            this.progress = 0;
            break;
        }
      });
    // let unsafeImageUrl = URL.createObjectURL(this.fileToUpload);
    // this.eligibleForm.value.icon_url =
    //   this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
    // // this.dataChanged(i);
  }
  
}

import { Component, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/core/services/shared.service';
import { HomeManagementService } from 'src/app/core/services/home-management.service';
import { PermissionService } from 'src/app/core/services/permision.service';
import { OtherPagesService } from 'src/app/core/services/other-pages.service';
import { CustomSortComponent } from '../../ui-elements/custom-sort/custom-sort.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';

@Component({
  selector: 'app-home-application-steps',
  templateUrl: './home-application-steps.component.html',
  styleUrls: ['./home-application-steps.component.scss']
})
export class HomeApplicationStepsComponent {

  applicationForm: FormGroup;
  hasChanges = false;

  isUpdating = false;
  deletedapply = [];
  isSuggestion = false;

  onDrag = false;

  @Input() page;
  category_id = this.sharedService.selectedCmsCategoryId
  product_id = this.sharedService.selectedCmsProductId
  publishPage: string;

  constructor(private fb: FormBuilder, private toastr: ToastrService,
    public permission: PermissionService,
    private sharedService: SharedService, private homeApi: HomeManagementService,
    private consultantApi: OtherPagesService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.resetForm();
    this.getSteps();
    switch (this.page) {
      case 'home': this.publishPage = 'VisaProcess'; break;
      case 'consultant': this.publishPage = 'VisaproApplicationStep'; break;
      default: break;
    }
  }

  resetForm() {
    this.applicationForm = this.fb.group({
      apply: this.fb.array([
        this.fb.group({
          'image_url': ['', [Validators.required]],
          'heading': ['', [Validators.required]],
          'is_updated': [''],
        })])
    });
  }

  getSteps() {
    if (this.page == 'home') {
      this.homeApi.getApplyStepsV2().subscribe(
        (result: any) => {
          this.setData(result);
        })
    } else if (this.page == 'consultant') {
      this.consultantApi.getApplyStepsConsultant().subscribe(
        (result: any) => {
          this.setData(result);
        })
    }
  }

  setData(result) {
    this.applicationForm = this.fb.group({
      apply: new FormArray([]),
    });
    result.forEach(ao => {
      (this.applicationForm.get('apply') as FormArray).push(
        this.fb.group({
          id: [this.isSuggestion ? null : ao.id],
          image_url: [ao.image_url, [Validators.required]],
          heading: [ao.heading, [Validators.required]],
          is_updated: [this.isSuggestion ? true : false],
        })
      );
      this.deletedapply = [];
    });
  }

  apply(): FormArray {
    return this.applicationForm.get("apply") as FormArray
  }

  newapply(): FormGroup {
    return this.fb.group({
      image_url: ['', [Validators.required]],
      heading: ['', [Validators.required]],
      is_updated: [true],
    })
  }

  addapply() {
    this.apply().push(this.newapply());
  }

  removeapply(i: number) {
    this.applicationForm.value.apply[i].is_deleted = true;
    if (this.applicationForm.value.apply[i].hasOwnProperty("id")) this.deletedapply.push(this.applicationForm.value.apply[i]);
    this.apply().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedapply.forEach(ae => {
        ae.is_updated = false;
        form.value.apply.push(ae);
      })
      if (this.page == 'home') {
        this.homeApi.setApplyStepsV2(form.value.apply).subscribe(
          () => {
            this.setSuccess();
          },
          () => {
            this.isUpdating = false;
          })
      } else if (this.page == 'consultant') {
        this.consultantApi.setApplyStepsConsultant(form.value.apply).subscribe(
          () => {
            this.setSuccess();
          },
          () => {
            this.isUpdating = false;
          })
      }

    } else {
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  setSuccess() {
    this.toastr.success("Content Updated Successfully!");
    this.getSteps();
    this.hasChanges = false;
    this.isUpdating = false;
  }

  dataChanged(i) {
    this.applicationForm.value.apply[i].is_updated = true;
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  showSortModal(data, label) {
    let url = '';
    if (this.page == 'home') url = 'cms-admin/home/home-application-step-priority/position';
    else if (this.page == 'consultant') url = 'cms-admin/home/consultant-application-step-priority/position';
    let data_card: any = {
      list: data,
      label: label,
      api_url: url
    };
    const modalRef = this.modalService.open(CustomSortComponent,
      {
        size: 'md',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.sortData = data_card;
    modalRef.result.then((result) => {
      if (result) this.getSteps();
    });
  }

  openUploadModal(i) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if(result.status) {
        this.applicationForm.get(['apply', i, 'image_url']).setValue(result.image);
        this.applicationForm.get(['apply', i, 'is_updated']).setValue(true);
      }
    });
}
}

import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FlatpickrOptions } from "ng2-flatpickr";
import {
  categories,
  countries,
  dataToSend,
  filterModalForm,
  fiter,
} from "src/app/core/models/visa-management.model";
import { ProductService } from "src/app/core/services/product.service";
import { StorageService } from "src/app/core/services/storage.service";
import { VisaManageService } from "src/app/core/services/visa-manage.service";

@Component({
  selector: "app-filter-modal",
  templateUrl: "./filter-modal.component.html",
  styleUrls: ["./filter-modal.component.scss"],
})
export class FilterModalComponent implements OnInit {
  @Input() userList: any;
  @Input() pageSize:number
  countryList: countries[];
  categoryList: any;
  visaFilterForm: FormGroup;
  selectedIndex: number;
  pickerOptions: FlatpickrOptions[] = [{
   
    dateFormat: "Y-m-d",
    defaultDate: "",
  },
  {
   
    dateFormat: "Y-m-d",
    defaultDate: "",
  }]
  ;
  applicationDateOptions: FlatpickrOptions[] = [{
  
    dateFormat: "Y-m-d",
    defaultDate: "",
  },
  {
  
    dateFormat: "Y-m-d",
    defaultDate: "",
  }];
  filterData: dataToSend;

  constructor(
    private activeModal: NgbActiveModal,
    private api: ProductService,
    private visaManageService: VisaManageService,
    private fb: FormBuilder,
    private storageService: StorageService,
    private router: Router
  ) {
   // this.getLocalStorageItem();
  }
  @ViewChild("datePickerElement1") datePickerElement1: any;
  @ViewChild("datePickerElement2") datePickerElement2: any;
  @ViewChild("applicationDatePicker1") applicationDatePicker1: any;
  @ViewChild("applicationDatePicker2") applicationDatePicker2: any;
  ngOnInit(): void {
    this.initializeForm();
    this.getCountryBasedCategories(
      this.filterData?.countryIds ? this.filterData?.countryIds : []
    );
    this.getCountriesList();
  }
  close() {
    this.activeModal.close(null);
  }

  initializeForm() {
    this.visaFilterForm = this.fb.nonNullable.group<filterModalForm>({
      countries: new FormControl(
        this.filterData?.countryIds ? this.filterData?.countryIds : ""
      ),
      category: new FormControl(
        this.filterData?.categoryIds ? this.filterData?.categoryIds : ""
      ),
      travel_date_from: new FormControl(
        this.pickerOptions[0].defaultDate ? this.pickerOptions[0].defaultDate : ""
      ),
      travel_date_to: new FormControl(
        this.pickerOptions[1].defaultDate ? this.pickerOptions[1].defaultDate : ""
      ),
      express: new FormControl(this.filterData?.express == true ? true : false),
      expert: new FormControl(
        this.filterData?.expertIds ? this.filterData?.expertIds : null
      ),
      // visa: new FormControl(this.filterData?.visa ? true : false),
      // diy: new FormControl(this.filterData?.diy ? true : false),
      status: new FormControl(
        this.filterData?.status ? this.filterData?.status : "PAID"
      ),
      source: new FormControl(
        this.filterData?.source ? this.filterData?.source : ""
      ),
      product_status: new FormControl(
        this.filterData?.product_status
          ? this.filterData?.product_status
          : "NEW"
      ),
      application_to_date: new FormControl(
        this.applicationDateOptions[1].defaultDate
          ? this.applicationDateOptions[1].defaultDate
          : ""
      ),
      application_from_date: new FormControl(
        this.applicationDateOptions[0].defaultDate
          ? this.applicationDateOptions[0].defaultDate
          : ""
      ),
    });
  }

  // getLocalStorageItem() {
  //   this.filterData = this.storageService.getItem("filterData")
  //     ? this.storageService.getItem("filterData")
  //     : "";
  //   if (this.filterData?.fromDate && this.filterData?.fromDate) {
      
  //     const defaultDateRange: any = [
  //       new Date(this.filterData?.fromDate?.toString()),
  //       new Date(this.filterData?.toDate?.toString()),
  //     ];
  //     this.pickerOptions.defaultDate = defaultDateRange;
     
  //   }
  //   if (
  //     this.filterData?.application_from_date &&
  //     this.filterData?.application_to_date
  //   ) {
  //     const defaultDateRange: any = [
  //       new Date(this.filterData?.application_from_date?.toString()),
  //       new Date(this.filterData?.application_to_date?.toString()),
  //     ];
  //     this.applicationDateOptions.defaultDate = defaultDateRange;
  //   }
  // }

  getCountriesList() {
    this.api.getCountryDetails().subscribe((response: any) => {
      this.countryList = response;
    });
  }

  OnselectCountry(country: any) {
    let countries = this.visaFilterForm.value.countries;
    if (countries?.length === 1)
      this.visaFilterForm.controls["category"].setValue("");

    this.getCountryBasedCategories(countries);
  }
  resestCategory() {
    let countries = this.visaFilterForm.value.countries;
    if (!countries.length) {
      this.visaFilterForm.controls["countries"].setValue("");
      this.visaFilterForm.controls["category"].setValue("");
    }
    this.getCountryBasedCategories(countries);
  }

  resestCalendar(data: any) {
    if(data==='travel-from'){
      this.datePickerElement1.flatpickr.clear();
      this.datePickerElement2.flatpickr.clear();
    }
    if(data==='travel-to'){
      this.datePickerElement2.flatpickr.clear();
    }
    if(data==='application-to-date'){
      this.applicationDatePicker2.flatpickr.clear();
    }
    if(data==='application-from-date'){
      this.applicationDatePicker1.flatpickr.clear();
      this.applicationDatePicker2.flatpickr.clear();
    }
    // if (!data && this.datePickerElement1.flatpickr && this.datePickerElement2.flatpickr) {
    //   this.datePickerElement1.flatpickr.clear();
    //      this.datePickerElement2.flatpickr.clear();
    // }
    // if (data && this.applicationDatePicker.flatpickr) {
    //   this.applicationDatePicker.flatpickr.clear();
    // }
  }

  onApplicationDateSelect() {
    
    if (this.visaFilterForm.value.application_from_date[0] &&  this.visaFilterForm.value.application_to_date[0]) {
     
      if (
        this.visaFilterForm.value.application_from_date[0]?.toLocaleDateString(
          "en-CA"
        ) ===
        this.visaFilterForm.value.application_to_date[0]?.toLocaleDateString(
          "en-CA"
        )
      ) 
      {
        //  this.applicationDatePicker1.flatpickr.clear();
        //  this.applicationDatePicker2.flatpickr.clear();
      }
    }
  }

  onTravelDateSelect(){
    if (this.visaFilterForm.value.travel_date_from[0] &&  this.visaFilterForm.value.travel_date_to[0]) {
     
      if (
        this.visaFilterForm.value.travel_date_from[0]?.toLocaleDateString(
          "en-CA"
        ) ===
        this.visaFilterForm.value.travel_date_to[0]?.toLocaleDateString(
          "en-CA"
        )
      ) 
      {
         this.datePickerElement1.flatpickr.clear();
         this.datePickerElement2.flatpickr.clear();
      }
    }
  }

  getCountryBasedCategories(countryId?: Array<number>) {
    if (!this.visaFilterForm.value.countries) {
      this.visaManageService
        .getCategoiesOnCountry()
        .subscribe((response: Array<string>[]) => {
          this.categoryList = response;
        });
    } else {
      this.visaManageService
        .getCategoiesOnCountry(countryId)
        .subscribe((response: categories[]) => {
          this.categoryList = response;
          const updatedCategory: Array<number> = [];
          const categories = this.visaFilterForm.value.category;
          this.categoryList.forEach((element) => {
            if (categories.includes(element.id)) {
              updatedCategory.push(element.id);
              this.visaFilterForm.controls["category"].setValue(
                updatedCategory
              );
            } else {
            }
          });
          if (!updatedCategory.length)
            this.visaFilterForm.controls["category"].setValue(updatedCategory);
        });
    }
  }
  // onCheckBoxClick(event: any, index: number) {
  //   this.selectedIndex = event.target.checked ? index : undefined;
  //   if (index === 1) {
  //     this.visaFilterForm.controls["visa"].setValue(false);
  //   } else {
  //     this.visaFilterForm.controls["diy"].setValue(false);
  //   }
  // }

  onSubmit() {
    let data: dataToSend;
    data = {
      date: this.visaFilterForm.value?.date,
      countryIds: this.visaFilterForm.value?.countries,
      categoryIds: this.visaFilterForm.value?.category,
      fromDate: this.visaFilterForm.value?.travel_date_from
        ? this.visaFilterForm.value?.travel_date_from[0]?.toLocaleDateString("en-CA")
        : "",
      toDate: this.visaFilterForm.value?.travel_date_to
      ? this.visaFilterForm.value?.travel_date_to[0]?.toLocaleDateString("en-CA")
      : "",
      express: this.visaFilterForm.value?.express,
      // diy: this.visaFilterForm.value?.diy ? 2 : "",
      // visa: this.visaFilterForm.value?.visa ? 1 : "",
      choice: this.router.url.includes("process") ? "consultant" : "diy",
      status: this.visaFilterForm.value?.status,
      product_status: this.visaFilterForm.value?.product_status,
      expertIds: this.visaFilterForm.value?.expert,
      source: this.visaFilterForm.value?.source,
      application_from_date: this.visaFilterForm.value?.application_from_date[0]
        ? this.visaFilterForm.value?.application_from_date[0].toLocaleDateString(
            "en-CA"
          )
        : "",
      application_to_date:
        this.visaFilterForm.value?.application_to_date[0]?.toLocaleDateString(
          "en-CA"
        ),
        limit:this.pageSize,
        offset:0
    };

    this.visaManageService
      .getVisaFilterResult(data)
      .subscribe((response: fiter) => {
        this.storageService.setItem("filterData", data);
        const dataToPass = {
          filterMessage:`Showing filter data of ${data?.countryIds?'country,':""}${data?.categoryIds?'category,':''}${data?.fromDate?'from travel date,':''}${data?.toDate?'to travel date,':''}${data?.expertIds?'experts,':''}${data?.express?'express,':''}${data?.status?'status,':''}${data?.product_status?'product status,':''}${data?.source?'source,':''}`,
          response: response,
          queryParmas: data,
        };
        this.activeModal.close(dataToPass);
      });
  }
}

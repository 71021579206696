<div class="modal-header">
  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    aria-label="Close"
    (click)="closeModal(false)"
  ></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 text-center">
      <h5>Sort</h5>
    </div>
    <div class="col-12">
      <div cdkDropList  class="example-list" (cdkDropListDropped)="drop($event)" >
        <div class="example-box" *ngFor="let data of categoryList" cdkDrag >
          <div class="example-custom-placeholder" *cdkDragPlaceholder>{{ data?.name }}</div>
          {{ data?.name }}
          <div class="font-size-20"><i class="mdi mdi-cursor-move"></i></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer text-end">
  <button class="btn btn-success me-2" (click)="confirmSort()"><i class="fa" [ngClass]="{'fa-spin fa-spinner fa-pulse': isSorting, 'fa-check': !isSorting}"></i> Confirm Sort</button>
    <button class="btn btn-secondary" (click)="closeModal(false)"><i class="fa fa-times"></i> No</button>
</div>

<ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
    <li [ngbNavItem]="1">
      <a ngbNavLink>
        <!-- <span class="d-block d-sm-none"><i class="fas fa-home"></i></span> -->
        <span class="d-none d-sm-block">Notifications</span>
      </a>
      <ng-template ngbNavContent>
        <app-notifications [isDiy]="isDiy" [wf_processid]="wf_processid" [position]="position" [process_name]="process_name" [data]="data" [templateTypes]="templateTypes" [consultantType]="consultantType"></app-notifications>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>
        <!-- <span class="d-block d-sm-none"><i class="far fa-user"></i></span> -->
        <span class="d-none d-sm-block">SLA</span>
      </a>
      <ng-template ngbNavContent>
        <app-sla [isDiy]="isDiy" [wf_processid]="wf_processid" [position]="position" [process_name]="process_name" [data]="data" [templateTypes]="templateTypes" [consultantType]="consultantType"></app-sla>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="customNav"></div>
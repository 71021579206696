import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { HomeManagementService } from 'src/app/core/services/home-management.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-usp-features',
  templateUrl: './usp-features.component.html',
  styleUrls: ['./usp-features.component.scss']
})
export class UspFeaturesComponent implements OnInit {

  public Editor = ClassicEditor;

  featuresForm: FormGroup;
  hasChanges = false;
  
  isUpdating = false;
  deletedFeatures = [];
  isSubmitted = false;

  constructor(private fb: FormBuilder, private api: HomeManagementService, public permission: PermissionService,
    private toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.resetForm();
    this.getUspFeatures();
  }

  resetForm() {
    this.featuresForm = this.fb.group({
      features: this.fb.array([
        this.fb.group({
          'id': ['', [Validators.required]],
          'icon_url': ['', [Validators.required]],
          'heading': ['', [Validators.required]],
          'description': ['', [Validators.required]],
          'is_updated': [''],
        })])
    });
  }

  getUspFeatures() {
    this.api.getUspFeatures().subscribe(
      (result: any) => {
        this.featuresForm = this.fb.group({
          features: new FormArray([]),
        });
        result.forEach(ao => {
          (this.featuresForm.get('features') as FormArray).push(
            this.fb.group({
              id: [ao.id, [Validators.required]],
              icon_url: [ao.icon_url, [Validators.required]],
              heading: [ao.heading, [Validators.required]],
              description: [ao.description, [Validators.required]],
              is_updated: [false],
            })
          );
          this.deletedFeatures = [];
        });
      })
  }

  features(): FormArray {
    return this.featuresForm.get("features") as FormArray
  }

  newFeatures(): FormGroup {
    return this.fb.group({
      icon_url: ['', [Validators.required]],
      heading: ['', [Validators.required]],
      description: ['', [Validators.required]],
      is_updated: [true],
    })
  }

  addFeatures() {
    this.features().push(this.newFeatures());
  }

  removeFeatures(i: number) {
    this.isSubmitted = false;
    this.featuresForm.value.features[i].is_deleted = true;
    if(this.featuresForm.value.features[i].hasOwnProperty("id")) this.deletedFeatures.push(this.featuresForm.value.features[i]);
    this.features().removeAt(i);
  }

  onSubmit(form) {
    if(form.valid){
      this.isUpdating = true;
      this.deletedFeatures.forEach(ae=>{
        ae.is_updated = false;
        form.value.features.push(ae);
      })
      this.api.setUspFeatures(form.value.features).subscribe(
        ()=>{
          this.toastr.success("Content Updated Successfully!");
          this.getUspFeatures();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        ()=>{
          this.isUpdating = false;
        }
      )
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  dataChanged(i){
    this.featuresForm.value.features[i].is_updated = true; 
  }

  checkPermission(type){
    return this.permission.hasPermission(type);
  }

  openUploadModal(i) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if(result.status) {
        this.featuresForm.get(['features', i, 'icon_url']).setValue(result.image);
        this.featuresForm.get(['features', i, 'is_updated']).setValue(true);
      }
    });
}
}

<div class="container">
    <form class="row" [formGroup]="headerForm" (ngSubmit)="onSubmit(headerForm)">
      <div class="col-md-8 offset-md-2 p-4 mt-2">
        <div class="border shadow">
          <!-- <div class="my-2 mt-3 row">
            <label class="col-md-3 offset-md-1 col-form-label">Heading</label>
            <div class="col-md-7">
              <input type="text" class="form-control" placeholder="Enter Heading" formControlName="heading" [ngClass]="{'invalid': headerForm.get('heading').invalid && isSubmitted}">
            </div>
          </div> -->
          <div class="my-2 mt-3 row">
            <label class="col-md-3 offset-md-1 col-form-label">Headings</label>
            <div class="col-md-7">
              <div formArrayName="headings"  *ngFor="let item of heads().controls; let i = index;"
              cdkDrag>
              <div class="example-handle" cdkDragHandle></div>
              <div [formGroupName]="i" class="mt-2 d-flex">
                <input type="text" class="form-control" placeholder="Enter Heading" formControlName="text" [ngClass]="{'invalid': item.get('text').invalid && isSubmitted}">
                <a *ngIf="heads().controls.length>1" class="btn btn-sm btn-outline text-danger float-end cpr mt-2" (click)="removeheads(i)"><i class="fa fa-times"></i></a>
              </div>
              </div>
              <a class="btn btn-sm btn-primary float-end cpr mt-2" (click)="addheads()"><i class="fa fa-plus"></i></a>
            </div>
          </div>
          <!-- <div class="my-2 row">
            <label class="col-md-3 offset-md-1 col-form-label">Sub Heading</label>
            <div class="col-md-7">
              <input type="text" class="form-control" placeholder="Enter Sub Heading" formControlName="sub_heading" [ngClass]="{'invalid': headerForm.get('sub_heading').invalid && isSubmitted}">
            </div>
          </div>
          <div class="my-2 row">
            <label class="col-md-3 offset-md-1 col-form-label">Description</label>
            <div class="col-md-7">
              <textarea cols="30" rows="5" class="form-control" placeholder="Enter Description"
                formControlName="description" [ngClass]="{'invalid': headerForm.get('description').invalid && isSubmitted}"></textarea>
            </div>
          </div> -->
          <div class="my-2 row">
            <label class="col-md-3 offset-md-1 col-form-label">Image URL</label>
            <div class="col-md-7 d-flex">
              <input type="file" accept="image/*" (change)="handleIcon($event.target.files)" class="form-control"
                placeholder="Upload Icon">
              <a [href]="headerForm.value.banner_image_url" target="_blank"><img class="ms-1" height="40"
                  [src]="headerForm.value.banner_image_url"></a>
            </div>
          </div>
          <div class="my-2 row">
            <div class="col-md-12">
              <button *ngIf="(page=='category' &&checkPermission('content_management_category_create')) || (page=='product' && checkPermission('content_management_product_create')) && (page=='country' && checkPermission('content_management_country_edit')) || (page=='category' &&checkPermission('content_management_category_edit')) || (page=='product' && checkPermission('content_management_product_edit'))" class="btn btn-info float-end me-5"><i [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i> Save Changes</button>
              <app-publish-btn *ngIf="(page=='category' &&checkPermission('content_management_category_publish')) || (page=='product' && checkPermission('content_management_product_publish'))" class="float-end me-2" [publish]="publishPage" [page]="page"></app-publish-btn>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
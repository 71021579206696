import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { VisaManageService } from 'src/app/core/services/visa-manage.service';

@Component({
  selector: 'app-review-questions',
  templateUrl: './review-questions.component.html',
  styleUrls: ['./review-questions.component.scss']
})

export class ReviewQuestionsComponent implements OnInit {

  @Input() questionsList;
  @Input() fromVisaFlow;
  @Input() paxId;
  @Input() isMaster;
  @Input() isMasterQuestion = false;
  @Output() closeModal = new EventEmitter<string>();
  unblockQuestionsList = [];
  isUnblocking = false;
  isVerify = false;
  isVerifying = false;
  lock = false;
  checked = [false];

  constructor(private api: VisaManageService, private toastr: ToastrService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.questionsList.forEach((ae, i) => { this.checked[i] = false; });
  }

  unblockQuestions() {
    this.isUnblocking = true;
    this.unblockQuestionsList = [];
    this.questionsList.forEach(ae => {
      ae.form_inputs.forEach(ao => {
        if (ao.agent_raised) this.unblockQuestionsList.push({
          question_id: ao.id,
          question: ao.name,
          unblock: true,
          agent_raise: true
        });
      });
    });
    this.api.unblockCountryQuestions(this.unblockQuestionsList, this.paxId).subscribe(
      result => {
        this.isUnblocking = false;
        this.close();
        this.toastr.success("Unblocked Successfully");
      },
      error => {
        this.isUnblocking = false;
      }
    )
  }

  close(): void {
    this.closeModal.next('success');
  }

  checkVerified() {
    for (let i in this.checked) if (!this.checked[i]) return true;
    return false;
  }

  checkUnblocked() {
    for (let i in this.questionsList) {
      for (let j in this.questionsList[i].form_inputs) {
        if (this.questionsList[i].form_inputs[j].agent_raised) return false;
      }
    }
    return true;
  }

  verifiedOk() {
    this.isVerifying = true;
    this.api.agentVerificationSuccess({ pax_id: this.paxId }).subscribe(
      result => {
        this.toastr.success("Verified Successfully");
        this.isVerifying = false;
        this.close();
      },
      error => {
        this.isVerifying = false;
      }
    )
  }

}

<div class="modal-header">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)">
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12 text-center">
            <h5>{{getQuestion(paxDetails?.api)}}</h5>
        </div>
    </div>
</div>
<div class="modal-footer text-end">
    <button class="btn btn-success me-2" (click)="onConfirm()"><i class="fa" [ngClass]="{'fa-spin fa-spinner fa-pulse': isConfirming, 'fa-check': !isConfirming}"></i> Yes</button>
    <button class="btn btn-secondary" (click)="closeModal(false)"><i class="fa fa-times"></i> No</button>
</div>
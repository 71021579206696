import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SharedService } from "src/app/core/services/shared.service";
import { ProductService } from "src/app/core/services/product.service";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PermissionService } from "src/app/core/services/permision.service";
import { SortCategoryComponent } from "../sort-category/sort-category.component";
import { AddEditCategoryModalComponent } from "../add-edit-category-modal/add-edit-category-modal.component";

@Component({
  selector: "app-category-details",
  templateUrl: "./category-details.component.html",
  styleUrls: ["./category-details.component.scss"],
})
export class CategoryDetailsComponent implements OnInit {
  isSubcategory:boolean=false;
  subCategriesUnderCountry:any
  searchTerm: string = "";
  categoryCopied: any;
  page_details = {
    count: 0,
    next: "",
    previous: "",
    current: 0,
  };
  pageNumber = 1;
  pageSize = 10;
  categoryList = [];
  subCatList = [];
  countryId: number;
  selectedCatId: number;
  @Input() fromAdd;
  checkInterval: any;
  selectedCat;
  // breadCrumbItems: any = [
    //   { label: "Product Management" },
    //   { label: "Category", active: true },
    // ];
    //catForm: FormGroup;
    // isLoading: boolean = false;
   // subCatForm: FormGroup;

  // isCategoryDetails = false;
  // categoryDetailsId: number;
  //showCatForm = false;
  //showSubCatForm = false;

  //isGettingSubCat = false;
  //isSubmitted = false;
  //isSubCatSubmitted = false;
  //isCat = true;

  //isUrlCat = false;
  //isUrlSubCat = false;

 
  //selectedSubCat;
  //isCatEdit = false;
  //isSubCatEdit = false;

  // isSubmittingCat = false;
  //isSubmittingSubCat = false;

  //isSuggestCat = false;
  //isSuggestSubCat = false;

  //@Output("showNextStep") showNextStep: EventEmitter<any> = new EventEmitter();
  //isDupUrl = false;

  constructor(
    //private fb: FormBuilder,
    private sharedService: SharedService,
    public permission: PermissionService,
    private api: ProductService,
    private toastr: ToastrService,
    private modalService: NgbModal
  ) {
    //this.resetForm();
    //this.resetSubCatForm();
  }

  ngOnInit(): void {
    this.backToList()
  }

  backToList(){
    this.sharedService.backToList$.subscribe((res)=>{
      if(res){
        this.isSubcategory=false
        this.getAllCategoriesAfterChange()
      }
    })
  }

  initCategories() {
    this.countryId = this.sharedService.selectedCountryId;
    if (this.sharedService.countryAllData.is_categories) {
      this.categoryList = this.sharedService.countryAllData?.categories;
      
      this.categoryCopied = this.sharedService.countryAllData?.categories;
      this.categoryList.forEach((ae) => {
        ae.selected = false;
      });
     // this.resetForm();
    } else {
      this.categoryList = [];
     // this.resetForm();
    }
  }

  getAllCategoriesAfterChange() {
    this.api.getCategoriesByCountry(this.countryId).subscribe((result: any) => {
      this.categoryList = result;
      this.categoryCopied = result;
      this.sharedService.countryAllData.is_categories = true;
      this.sharedService.countryAllData.categories = this.categoryList;
      this.sharedService.selectedCountryId = this.countryId;
    });
  }

  openAddorEditModal(category?: any) {
    const modalRef = this.modalService.open(AddEditCategoryModalComponent, {
      size: "sm",
      // keyboard: false,
      backdrop: "static",
      centered: true,
    });
    modalRef.componentInstance.countryId = this.countryId;
    modalRef.componentInstance.category = category ? category : "";
    modalRef.result.then((res) => {
      if (res) {
        this.getAllCategoriesAfterChange();
      }
    });
  }

  searchCountry(searchTerm) {
    if (!searchTerm) {
      this.categoryList = this.categoryCopied;
    } else {
      this.categoryList = this.categoryList.filter((ae) =>
        ae.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  }

  changeStatus(event: any, data: any) {
    const dataToSend = {
      display_status: event.target.checked,
    };
    this.api.changeCategoryStatus(data?.id, dataToSend).subscribe((res) => {
     if(res){
      this.getAllCategoriesAfterChange();
      this.toastr.success('Status changed successfully','Success')
     }
    });
  }

  changeCourierStatus(event: any, data: any){
    const dataToSend = {
      courier_status: event.target.checked,
    };
    this.api.changeCategoryCourier(data?.id, dataToSend).subscribe((res) => {
     if(res){
      this.getAllCategoriesAfterChange();
      this.toastr.success('Courier status changed successfully','Success')
     }
    });
  }
  enableSubCatTable(category:any){
    this.isSubcategory=true;
    this.selectedCatId=category?.id
    this.subCategriesUnderCountry=category?.sub_categories
    this.sharedService.changeCatTitle(true)
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  changePosition() {
    const modalRef = this.modalService.open(SortCategoryComponent, {});
    modalRef.componentInstance.categoryList = this.categoryList;
  }
  nextPage(page) {
    // this.getProductsData(this.pageSize,this.pageSize*(page-1),this.vendorId,this.stockStatus,this.searchTerm);
  }
  
  // open(content) {
  //   this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  // }
  
  // resetForm(data?) {
  //   this.catForm = this.fb.group({
  //     id: [data ? data.id : ""],
  //     name: [data ? data.name : "", [Validators.required]],
  //     country: [this.countryId, [Validators.required]],
  //     internal_code: [data ? data.internal_code : "", [Validators.required]],
  //     url_param: [data ? data.url_param : "", [Validators.required]],
  //     sub_categories: [[]],
  //   });
  // }

  // resetSubCatForm(data?) {
  //   this.subCatForm = this.fb.group({
  //     id: [data ? data.id : null],
  //     category: [this.selectedCatId, [Validators.required]],
  //     name: [data ? data.name : null, [Validators.required]],
  //     entry_type: [data ? data.entry_type : null, [Validators.required]],
  //     url_param: [data ? data.url_param : null, [Validators.required]],

  //     internal_code: [data ? data.internal_code : null, [Validators.required]],
  //     price: [data ? data.price : null, [Validators.required]],

  //     as_per_embassy: [
  //       data ? (data.as_per_embassy ? data.as_per_embassy : false) : false,
  //     ],
  //     duration_value: [data ? data.duration_value : null],
  //     duration_unit: [data ? data.duration_unit : "Day"],

  //     validity_as_per_embassy: [
  //       data
  //         ? data.validity_as_per_embassy
  //           ? data.validity_as_per_embassy
  //           : false
  //         : false,
  //     ],
  //     validity_value: [data ? data.validity_value : null],
  //     validity_unit: [data ? data.validity_unit : "Day"],

  //     processing_time_min: [
  //       data ? data.processing_time_min : null,
  //       [Validators.required],
  //     ],
  //     processing_time_max: [
  //       data ? data.processing_time_max : null,
  //       [Validators.required],
  //     ],
  //     processing_time_unit: [data ? data.processing_time_unit : "Hour"],
  //   });
  // }

 

  
  // onCatSubmit(form) {
  //   if (form.valid) {
  //     this.isSubmittingCat = true;
  //     if (this.isCatEdit) {
  //       this.api.editCatTypeDetails(this.selectedCat.id, form.value).subscribe(
  //         (result: any) => {
  //           this.isSubmittingCat = false;
  //           this.resetForm();
  //           this.categoryList.forEach((ae, i) => {
  //             if (ae.id == this.selectedCat.id) this.categoryList[i] = result;
  //           });
  //           this.showCatForm = false;
  //           if (!this.fromAdd) this.toastr.success("Category Updated Successfully!");
  //         },
  //         error => {
  //           this.isSubmittingCat = false;
  //         })
  //     } else {
  //       this.api.setCatTypeDetails(form.value).subscribe(
  //         (result: any) => {
  //           this.categoryList.forEach(ae => {
  //             ae.selected = false;
  //           });
  //           result.selected = true;
  //           this.categoryList.push(result);
  //           this.resetForm();
  //           this.showCatForm = false;
  //           this.selectedCatId = result.id;
  //           this.resetSubCatForm();
  //           this.subCatList = result.sub_categories;
  //           this.isSubmittingCat = false;
  //           if (!this.fromAdd) this.toastr.success("Category Added Successfully!");
  //         },
  //         error => { this.isSubmittingCat = false; })
  //     }
  //   } else {
  //     this.isSubmitted = true;
  //     this.toastr.warning("Fill all the fields to continue")
  //   }
  // }

  // showSubcategories(id) {
  //   this.selectedCatId = id;
  //   //this.resetSubCatForm();
  //   this.isGettingSubCat = true;
  //   this.categoryList.forEach((ae) => {
  //     if (ae.id == id) ae.selected = true;
  //     else ae.selected = false;
  //   });

  //   this.api.getSubcategories(id).subscribe(
  //     (result) => {
  //       this.isGettingSubCat = false;
  //       this.subCatList = result.sub_categories;
  //       this.showSubCatForm = false;
  //     },
  //     (error) => {
  //       this.isGettingSubCat = false;
  //     }
  //   );
  // }

 
  // onSubCatSubmit(form) {
  //   if (form.valid) {
  //     this.isSubmittingSubCat = true;
  //     if (this.isSubCatEdit) {
  //       this.api
  //         .editSubcategories(this.selectedSubCat.id, form.value)
  //         .subscribe(
  //           (result: any) => {
  //             this.showSubCatForm = false;
  //             this.isSubmittingSubCat = false;
  //             //this.showSubcategories(this.selectedCatId);
  //             //this.resetSubCatForm();
  //             if (!this.fromAdd)
  //               this.toastr.success("Sub-Category Added Successfully!");
  //           },
  //           (error) => {
  //             this.isSubmittingSubCat = false;
  //           }
  //         );
  //     } else {
  //       this.api.setSubcategories(form.value).subscribe(
  //         (result: any) => {
  //           this.subCatList.push(result);
  //           this.showSubCatForm = false;
  //           //this.resetSubCatForm();
  //           this.isSubmittingSubCat = false;
  //           if (!this.fromAdd)
  //             this.toastr.success("Sub-Category Added Successfully!");
  //         },
  //         (error) => {
  //           this.isSubmittingSubCat = false;
  //         }
  //       );
  //     }
  //   } else {
  //     this.isSubCatSubmitted = true;
  //     this.toastr.warning("Fill all the fields to continue");
  //   }
  // }

  // gotoProducts() {
  //   this.showNextStep.emit({ tab: "category", data: this.categoryList });
  // }

  

  // editCat(isCat, cat) {
  //   if (isCat) {
  //     this.isCatEdit = true;
  //     this.selectedCat = cat;
  //     //this.resetForm(cat);
  //     this.showCatForm = true;
  //   } else {
  //     this.isSubCatEdit = true;
  //     this.selectedSubCat = cat;
  //     //this.resetSubCatForm(cat);
  //     this.showSubCatForm = true;
  //   }
  // }

  // selectSubCat(id) {
  //   this.subCatList.forEach((ae) => {
  //     if (ae.id == id) ae.selected = true;
  //     else ae.selected = false;
  //   });
  // }

  // generateUrlParam(isCat, text) {
  //   if (isCat && this.isSuggestCat) this.catForm.get('url_param').setValue(text.toLowerCase().replace(/[^\w\s]/gi, '').split(' ').join('-'));
  //   else if (!isCat && this.isSuggestSubCat) this.subCatForm.get('url_param').setValue(text.toLowerCase().replace(/[^\w\s]/gi, '').split(' ').join('-'));
  // }

  // changeRequiredDuration(value, isDuration) {
  //   if (isDuration) {
  //     if (value) {
  //       this.subCatForm
  //         .get("duration_value")
  //         .setValidators(Validators.required);
  //       this.subCatForm.get("duration_unit").setValidators(Validators.required);
  //     } else {
  //       this.subCatForm.get("duration_value").clearValidators();
  //       this.subCatForm.get("duration_unit").clearValidators();
  //     }
  //   } else {
  //     if (value) {
  //       this.subCatForm
  //         .get("validity_value")
  //         .setValidators(Validators.required);
  //       this.subCatForm.get("validity_unit").setValidators(Validators.required);
  //     } else {
  //       this.subCatForm.get("validity_value").clearValidators();
  //       this.subCatForm.get("validity_unit").clearValidators();
  //     }
  //   }
  // }

  // checkURLParam(url, isCat) {
  //   let data: any;
  //   clearTimeout(this.checkInterval);
  //   if (isCat) {
  //     this.isUrlCat = true;
  //     data = {
  //       category_url_param: url,
  //       country_id: this.countryId,
  //     };
  //     if (this.isCatEdit) data.id = this.selectedCatId;
  //   } else {
  //     this.isUrlSubCat = true;
  //     data = {
  //       product_url_param: url,
  //       category_id: this.selectedCatId,
  //     };
  //     if (this.isCatEdit) data.id = this.selectedSubCat.id;
  //   }

  //   this.checkInterval = setTimeout(() => {
  //     this.sharedService.checkUrlParam(data).subscribe((result) => {
  //       this.isDupUrl = result.is_exists;
  //       if (!this.isDupUrl) {
  //         if (isCat) this.isUrlCat = false;
  //         else this.isUrlSubCat = false;
  //       }
  //       if (this.isDupUrl) this.toastr.warning("URL Param must be unique");
  //     });
  //   }, 1000);
  // }
 
}

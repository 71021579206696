import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  page = 'news';
  mediaSelect: number = 1;
  specificPage = 'news';

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'Home Page', active: true }];
  }


  mediaChanged() {
    // alert(this.mediaSelect)
  }
}

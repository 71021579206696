import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ProductService } from 'src/app/core/services/product.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';


@Component({
  selector: 'app-jurisdiction-details',
  templateUrl: './jurisdiction-details.component.html',
  styleUrls: ['./jurisdiction-details.component.scss']
})
export class JurisdictionDetailsComponent implements OnInit {

  jurisdictionList = [];
  statesList = this.sharedService.statesList;
  statesListCopy = [...this.statesList];
  jurisdictionForm: FormGroup;
  countryList = [];
  isJurisdictionDetails: boolean = false;isDuplicate: boolean;
  isUpdating = false;
  jurisdictionDetailsId: number;
  countryId: number;
  @Output("showNextStep") showNextStep: EventEmitter<any> = new EventEmitter();
  @Input() fromAdd;
  deletedjurisdiction = [];
  isSubmitted = false;
  @Output() refreshData = new EventEmitter<any>();

  constructor(private fb: FormBuilder, private api: ProductService, 
    private sharedService: SharedService, private toastr: ToastrService,
    public permission:PermissionService) { }

  ngOnInit(): void {
    this.jurisdictionList = [];
    // this.statesList = [1867958969, 1008289231, 1258296281];
    this.countryList = this.sharedService.countryList;
    this.initForm();

  }

  initForm() {
    this.jurisdictionForm = this.fb.group({
      jurisdiction: this.fb.array([
        this.fb.group({
          'name': ['', [Validators.required]],
          'states': [null, [Validators.required]],
          'is_updated': [false],
          'country': [null, [Validators.required]],
          'internal_code': ['', [Validators.required]],
          'addresses': new FormArray([]),
        })])
    });
  }

  initJurisdiction() {
    this.countryId = this.sharedService.selectedCountryId;
    this.getStates();
    if (this.sharedService.countryAllData.is_jurisdiction) {
      this.isJurisdictionDetails = true;
      let juris = this.sharedService.countryAllData.jurisdictions;
      this.jurisdictionForm = this.fb.group({
        jurisdiction: this.fb.array([])
      });
      juris.forEach(ao => {
        (this.jurisdictionForm.get('jurisdiction') as FormArray).push(
          this.fb.group({
            'id': [ao.id, [Validators.required]],
            'name': [ao.name, [Validators.required]],
            'states': [ao.states],
            'country': [this.countryId],
            'is_updated': [false],
            'internal_code': [ao.internal_code, [Validators.required]],
            'addresses': this.setAddress(ao.addresses),
          })
        );
      });
      this.deletedjurisdiction = [];
    } else {
      this.jurisdictionForm = this.fb.group({
        jurisdiction: this.fb.array([
          this.fb.group({
            'name': ['', [Validators.required]],
            'states': [null,[Validators.required]],
            'is_updated': [false],
            'country': [this.countryId],
            'internal_code': ['', [Validators.required]],
            'addresses': new FormArray([]),
          })])
      });
    }
  }

  jurisdiction(): FormArray {
    return this.jurisdictionForm.get("jurisdiction") as FormArray
  }

  getStates() {
    if (this.sharedService.statesList.length == 0) {
      this.api.getStatesList().subscribe(
        (result: any) => {
          result.forEach(ae => {ae.all = 'Select All';});
          this.statesList = result;
          this.statesListCopy = [...result];
          this.sharedService.statesList = result;
        })
    }
  }

  selectAllStates(item){return item.all;}

  newJurisdiction(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required]],
      states: [null, [Validators.required]],
      is_updated: [false],
      country: [this.countryId, [Validators.required]],
      internal_code: ['', [Validators.required]],
      addresses: new FormArray([]),
    })
  }

  addJurisdiction() {
    this.selectState();
    this.jurisdiction().push(this.newJurisdiction());
  }

  removeJurisdiction(i: number) {
    this.jurisdictionForm.value.jurisdiction[i].is_deleted = true;
    if (this.jurisdictionForm.value.jurisdiction[i].hasOwnProperty("id")) this.deletedjurisdiction.push(this.jurisdictionForm.value.jurisdiction[i]);
    this.jurisdiction().removeAt(i);
    let jurisNames = [];
    this.jurisdictionForm.value.jurisdiction.forEach((ae,j) => {jurisNames.push(ae.name);});
    if(this.findDuplicates(jurisNames).length>0) this.isDuplicate = true;
    else this.isDuplicate = false;
  }

  onSubmit() {
    if (this.jurisdictionForm.valid) {
      this.isUpdating = true;
      this.deletedjurisdiction.forEach(ae => {
        ae.is_updated = false;
        this.jurisdictionForm.value.jurisdiction.push(ae);
      })
      if (this.isJurisdictionDetails) {
        this.api.updateJurisdictionDetails(this.jurisdictionForm.value.jurisdiction).subscribe(
          (result: any) => {
            this.showNextStep.emit({ tab: 'jurisdiction', data: this.jurisdictionForm.value });
            if(!this.fromAdd) this.toastr.success("Jurisdiction Updated Successfully!");
            this.statesList = [...this.statesListCopy];
            this.refreshData.emit();
            this.isUpdating = false;
          },
          (error)=>{
            this.isUpdating = false;
          })
      } else {
        this.api.setJurisdictionDetails(this.jurisdictionForm.value.jurisdiction).subscribe(
          (result: any) => {
            this.showNextStep.emit({ tab: 'jurisdiction', data: this.jurisdictionForm.value });
            if(!this.fromAdd) this.toastr.success("Jurisdiction Added Successfully!");
            this.statesList = [...this.statesListCopy];
            this.refreshData.emit();
            this.isUpdating = false;
          },
          (error)=>{
            this.isUpdating = false;
          })
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all the fields to continue")
    }
  }

  selectState() {
    let selectedStates = [];
    this.statesList = this.statesListCopy.slice();
    for (let i in this.jurisdictionForm.value.jurisdiction) {
      for (let j in this.jurisdictionForm.value.jurisdiction[i].states) {
        selectedStates.push(this.jurisdictionForm.value.jurisdiction[i].states[j]);
      }
    }
    this.statesListCopy.forEach((ae) => {
      if (selectedStates.includes(ae.id)) { this.statesList = this.statesList.filter(ao => ao.id != ae.id) }
    });
  }

  changeStatus(i){
    this.jurisdictionForm.get(['jurisdiction', i, 'is_updated']).setValue(true);
  }

  checkDuplicateNames(i, name){
    this.isDuplicate = false;
    let jurisNames = [];
    this.jurisdictionForm.value.jurisdiction.forEach((ae,j) => {if(i!=j) jurisNames.push(ae.name);});
    if(jurisNames.includes(name) || this.findDuplicates(jurisNames).length>0){
      this.toastr.warning("Jurisdiction name must be unique");
      this.isDuplicate = true;
    }
  }

  findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) !== index)

  checkPermission(type){
    return this.permission.hasPermission(type);
  }

  addNewAddress(control) {
    control.push(new FormControl(''));
  }

  deleteAddress(control, index) {
    control.removeAt(index)
  }

  setAddress(ax) {
    let arr = new FormArray([])
    ax.forEach(ay => {
      arr.push(new FormControl(ay))
    })
    return arr;
  }

}

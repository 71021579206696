<form>
  <div class="my-3 row">
    <label class="col-md-3 col-form-label">Age Group</label>
    <div class="col-md-4">
      <select class="form-select">
        <option>Select</option>
      </select>
    </div>
  </div>
    <div class="my-3 row">
      <label class="col-md-3 col-form-label">Visa Fee</label>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Enter default Visa Fee here">
      </div>
    </div>
    <div class="my-3 row">
      <label class="col-md-3 col-form-label">Vendor Fee</label>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Enter default Vendor Fee here">
      </div>
    </div>
    <div class="my-3 row">
      <label class="col-md-3 col-form-label">Merch Fee</label>
      <div class="col-md-4">
        <input type="text" class="form-control" placeholder="Enter default Merch Fee here">
      </div>
    </div>
    <div class="my-3 row">
      <label class="col-md-3 col-form-label">GST</label>
      <div class="col-md-4">
        <input type="text" class="form-control"placeholder="Enter default GST Percentage here">
      </div>
    </div>
</form>
import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductService } from 'src/app/core/services/product.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { JurisdictionDetailsComponent } from '../country/add-country/jurisdiction-details/jurisdiction-details.component';
@Component({
  selector: 'app-jurisdiction',
  templateUrl: './jurisdiction.component.html',
  styleUrls: ['./jurisdiction.component.scss']
})
export class JurisdictionComponent implements OnInit {

  countryList = this.sharedService.addedCountryList;
  jurisdictionList = [];
  statesList = [];
  statesListCopy = [];
  selectedCountry = this.sharedService.selectedCountryId;
  @ViewChild(JurisdictionDetailsComponent) jurisComponent: JurisdictionDetailsComponent;

  breadCrumbItems: Array<{}>;
  country = this.sharedService.selectedCountryId;
  selected = false;
  isLoading = false;
  fromAdd = false;

  constructor(private api: ProductService, private sharedService: SharedService) { }

  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Product Management' }, { label: 'Jurisdiction', active: true }];
    if(this.countryList.length==0)this.getCountriesList();
    if(this.country) this.getJurisdictions(this.country);
    
  }

  getCountriesList() {
    this.api.getCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response;
        this.sharedService.addedCountryList = this.countryList;
      })
  }

  getJurisdictions(country) {
    this.selectedCountry = country;
    this.selected = true;
    this.isLoading = true;
    this.jurisdictionList = [];
    this.api.getJurisdictionsById(country).subscribe(
      (result: any) => {
        this.isLoading = false;
        this.jurisdictionList = result;
        this.sharedService.countryAllData.is_jurisdiction = true;
        this.sharedService.countryAllData.jurisdictions = this.jurisdictionList;
        this.sharedService.selectedCountryId = this.selectedCountry;
        this.jurisComponent.initJurisdiction();
      },
      error => {
        this.isLoading = false;
      }
    )
  }

  refreshJurisdiction(event){
    this.getJurisdictions(this.selectedCountry);
  }

}

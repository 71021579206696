<ng-container *ngIf="isAuthorList && !seoComp">
    <div (click)="backtoList()">
        <button class="btn btn-success mt-2">Back</button>
      </div>
    <div class="container">
        <form [formGroup]="featuresForm" (ngSubmit)="onSubmit(featuresForm)">
            <div class="row">
                <div class="col-md-6 mt-2" formArrayName="features"
                    *ngFor="let item of features().controls; let i = index;">
                    <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                        <a *ngIf="features().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                            (click)="removeFeatures(i)"><i class="uil-times fa-2x"></i></a>
                        <div class="my-2 row">
                            <div class="col-md-12">
                                <label class="col-form-label">Image</label>
                                <div class=" d-flex">
                                    <input type="file" accept="image/*" (change)="handleIcon($event.target.files,i)"
                                        class="form-control" placeholder="Upload Icon">
                                    <a [href]="featuresForm.value.features[i].image" target="_blank">
                                        <img class="ms-1" height="40" [src]="featuresForm.value.features[i].image">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-12" *ngIf="progress[i]>0">
                                <div class="mt-2 progress-bar">
                                    <div class="progress" [style.width.%]="progress[i]">
                                        {{progress[i]}}%
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-md-12">
                                <label class="col-form-label">Name</label>
                                <input type="text" class="form-control" placeholder="Name" formControlName="name"
                                    (change)="dataChanged(i)"
                                    [ngClass]="{'invalid': item.get('name').invalid && isSubmitted}">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-md-12">
                                <label class="col-form-label">slug</label>
                                <input type="text" class="form-control" placeholder="Slug" formControlName="author_url"
                                    (change)="dataChanged(i)"
                                    [ngClass]="{'invalid': item.get('author_url').invalid && isSubmitted}">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-md-12">
                                <label class="col-form-label">Description</label>
                                <ckeditor [editor]="Editor" formControlName="description" (change)="dataChanged(i)"
                                    [ngClass]="{'invalid': item.get('description').invalid && isSubmitted}"
                                    data="<p>Content of the editor.</p>"></ckeditor>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 p-5">
                    <div class="row">
                        <!-- <button *ngIf="checkPermission('content_management_home_create') && !fetauresArray?.length"
                            class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addFeatures()"><i
                                class="uil-plus"></i> Add</button> -->
                        <button
                            *ngIf="checkPermission('content_management_home_create')&&checkPermission('content_management_home_edit')"
                            class="btn btn-info mt-1 col-md-12" type="submit"><i
                                [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                            Save Changes</button>
                        <app-publish-btn *ngIf="checkPermission('content_management_home_publish')"
                            class="col-md-12 pt-1 px-0" [publish]="'UspFeature'" [page]="'home'"></app-publish-btn>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-container>
<ng-container *ngIf="!isAuthorList && !seoComp">
    <div class="d-flex justify-content-end mt-2 mb-2" (click)="addFeatures()" *ngIf="checkPermission('content_management_home_create')">
        <button class="btn btn-success">Add Author</button>
    </div>
    <div>
        <ul>
          <li class="bg-light d-flex align-items-stretch justify-content-between mb-2 ms-2" *ngFor="let item of authorList; let i = index">
            <div class="d-flex">
                <span class="bg-white d-flex align-items-center">
                    {{i+1+'.' +''}}
                </span>
                <span class="d-flex align-items-center ms-2">
                  {{ item?.name }} 
                </span>
               
              </div>
            <span class="d-flex justify-content-end">
              <button class="btn btn-info mx-2" (click)="gotoSeo(item)">SEO</button>
              <button class="btn btn-success mx-2" (click)="gotoEditAuthor(item)">
                Edit
              </button>
            </span>
          </li>
        </ul>
      </div>
</ng-container>
<app-author-specific-seo *ngIf="seoComp" [slugId]="slugId" [seoType]="seoType"></app-author-specific-seo>
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from 'src/app/core/services/general.service';
import { PermissionService } from 'src/app/core/services/permision.service';
import { SharedService } from 'src/app/core/services/shared.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
    public Editor = ClassicEditor;
  
    termsConditionForm: FormGroup;
    hasChanges = false;
  
    isUpdating = false;
    isSubmitted = false;
    deletedSteps = [];
  
    @Input() page;
  
    publishPage: string;
    valueFromPage: number;
    isSuggestion: boolean;
  
    constructor(
      private fb: FormBuilder,
      private toastr: ToastrService,
      private api: GeneralService,
      public sharedService: SharedService,
      public permission: PermissionService
    ) {}
  
    ngOnInit(): void {
      this.getData();
      this.resetForm();
    }
  
    resetForm() {
      this.termsConditionForm = this.fb.group({
        steps: this.fb.array([
          this.fb.group({
            heading: ["", [Validators.required]],
            description: ["", [Validators.required]]
          }),
        ]),
      });
    }
  
    steps(): FormArray {
      return this.termsConditionForm.get("steps") as FormArray;
    }
  
    newsteps(): FormGroup {
      return this.fb.group({
        description: ["", [Validators.required]],
        heading: ["", [Validators.required]],
        is_updated: [true],
      });
    }
  
    addsteps() {
      this.steps().push(this.newsteps());
    }
  
    removesteps(i: number) {
      this.termsConditionForm.value.steps[i].is_deleted = true;
      if (this.termsConditionForm.value.steps[i].hasOwnProperty("id"))
        this.deletedSteps.push(this.termsConditionForm.value.steps[i]);
      this.steps().removeAt(i);
    }

    setData(result){
      this.termsConditionForm = this.fb.group({
        steps: new FormArray([]),
      });
      result.forEach(ao => {
        (this.termsConditionForm.get('steps') as FormArray).push(
          this.fb.group({
            description: [ao.description, [Validators.required]],
            heading: [ao.heading, [Validators.required]]
          })
        );
        this.deletedSteps = [];
      });
    }
  
    onSubmit(form) {
      if (form.valid) {
        this.isUpdating = true;
        this.deletedSteps.forEach((ae) => {
          ae.is_updated = false;
          form.value.steps.push(ae);
        });
        this.api.setTermsCondition(form.value.steps).subscribe(
          ()=>{
            this.toastr.success("Content Added Successfully!");
          },
        ()=>{
            this.isUpdating = false;
          })
      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }
    }
  
    dataChanged(i) {
      this.termsConditionForm.value.steps[i].is_updated = true;
    }
    
    getData() {
      this.api.getTermsCondition().subscribe(
        (result: any) => {
          this.setData(result);
        })
    }

    checkPermission(type){
      return this.permission.hasPermission(type);
    }
  }
  

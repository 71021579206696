import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';

import { User } from '../models/auth.models';

import { environment } from 'src/environments/environment';

const get_url = environment.apiUrl;
@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

 
    login(username: string, password: string) {
        return this.http.post<any>(`${get_url}/login/`, { username, password })
            .pipe(map(user => {
                if (user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
            return user;
        }));
    }
    

    loginPermission() {
        return this.http.get(`${environment.apiUrl}/users/permission/`).pipe(retry(2));
    }
    

    logout() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('STP_Permissions');
        this.currentUserSubject.next(null);
    }

    logoutApi(): Observable<any> {
        return this.http.post(`${environment.apiUrl}/logout/`, null)
    }


}


import { Component, inject } from '@angular/core';
import { DateRangePickerComponent } from '../chart-table/date-range-picker/date-range-picker.component';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from 'src/app/core/services/reports.service';
import { CsvDownloadService } from 'src/app/core/services/csv-download.service';

@Component({
  selector: 'app-country-table-report',
  templateUrl: './country-table-report.component.html',
  styleUrls: ['./country-table-report.component.scss']
})
export class CountryTableReportComponent {
  tableData: any = [];
  page_details = {
    count: 0,
    next: "",
    previous: "",
    fromDate: "",
    toDate: "",
    product_type: "",
    csv:false
  };
  fiterTerm: string = "visa";
  dateToShow: string;
  displayMonths = 2;
  navigation = "select";
  showWeekNumbers = false;
  outsideDays = "visible";
  isLoading: boolean = false;
  calendar = inject(NgbCalendar);

  constructor(
    private reportsService: ReportsService,
    private modalService: NgbModal,
    private csvDownloadService:CsvDownloadService 
  ) {}
  ngOnInit(): void {
    this.setData();
    this.getTableData();
  }
  setData() {
    const fromDate = this.calendar.getToday();
    this.page_details.fromDate = `${fromDate.year}-${fromDate.month}-${fromDate.day}`;
    const toDate = this.calendar.getNext(fromDate, "d", 20);
    this.page_details.toDate = `${toDate.year}-${toDate.month}-${toDate.day}`;
    this.dateToShow =
      this.page_details.fromDate + "-" + this.page_details.toDate;
  }
  

  getTableData() {
    if (this.fiterTerm) {
      this.isLoading = true;
      this.tableData=[]
      this.page_details.product_type = this.fiterTerm;
      this.reportsService.getCountryTableData(this.page_details).subscribe(
        (res) => {
          this.tableData = res;
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
    }
  }

  openDateRangeModal() {
    const modalRef = this.modalService.open(DateRangePickerComponent, {
      size: "ld",
    });
    modalRef.result.then((res) => {
      if (res) {
        this.dateToShow = res?.fromDate + "-" + res?.toDate;
        this.page_details.fromDate = res?.fromDate;
        this.page_details.toDate = res?.toDate;
        this.getTableData();
      }
    });
  }

  getData() {
    this.fiterTerm = "";
    if (this.fiterTerm) this.getTableData();
  }

  downloadCsv(){
    this.page_details.csv=true
    this.reportsService.getCountryTableData(this.page_details).subscribe((res)=>{
      const filename='country-wise-report.csv'
      this.csvDownloadService.downloadCsv(res,filename)
      this.page_details.csv=false
    },err=>{
      this.page_details.csv=false
    })
  }
}

<div class="spinner-border center_fixed_loader" role="status" *ngIf="loader">
    <span class="sr-only"></span>
</div>
<div class="personal_details" *ngIf="!loader">
    <h4>Passport Details</h4>
    <form [formGroup]="personalDetailsForm" (ngSubmit)="onPassprtSubmit()">
        <div class="col-md-6">
            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia
                consequat duis enim velit
                mollit.</p>
        </div>
        <div class="col-md-12" *ngIf="progress>0">
            <div class="mt-2 progress-bar">
                <div class="progress" [style.width.%]="progress">
                    {{progress}}%
                </div>
            </div>
        </div>
        <div class="row component_separation">
            <div class="col-md-6 mb-5 mb-md-0">
                <h4>Front Image</h4>
                <div class="position_relative">
                    <img src="./assets/images/passport_front.jpg" class="img-fluid w-100" alt="">
                    <div class="h-100 w-100">
                        <input class="input_file_upload" type="file" formControlName="documentFront"
                            (change)="uploadFrontImage($event)" />
                        <div class="outer_file_upload"
                            [ngStyle]="{background: 'url('+ frontImage + ')' + 'no-repeat center / cover,rgba(0, 0, 0, 0.5)'}">
                            <div class="upload_box d-flex align-items-center justify-content-center upload_button">
                                <i class="mdi mdi-upload"></i>
                                Upload
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <h4>Back Image</h4>
                <div class="position_relative">
                    <img src="./assets/images/passport_back.jpg" class="img-fluid w-100" alt="">
                    <div class="h-100 w-100">
                        <input class="input_file_upload" type="file" formControlName="documentBack"
                            (change)="uploadBackImage($event)" />
                        <div class="outer_file_upload"
                            [ngStyle]="{background: 'url('+ backImage + ')' + 'no-repeat center / cover,rgba(0, 0, 0, 0.5)'}">
                            <div class="upload_box d-flex align-items-center justify-content-center upload_button">

                                <i class="mdi mdi-upload"></i>
                                Upload
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div class="mt-5 row">
            <div class="col-md-12 text-end">
                <!-- !personalDetailsForm.valid -->
                <button type="submit" class="btn btn-primary" [disabled]="!frontImage && !backImage">Proceed</button>

            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <img src="" alt="">
            </div>

        </div>
    </form>
</div>
<div class="container-fluid">
    <!-- start page title -->
    <app-page-title title="Templates" [breadcrumbItems]="breadCrumbItems"></app-page-title>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <!-- orientation="vertical"  -->
                    <ul ngbNav #justifiednavpills="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span>Message</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-message></app-message>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span>E-Mail</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-email></app-email>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="3">
                            <a ngbNavLink>
                                <span>WhatsApp</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-whatsapp></app-whatsapp>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="4">
                            <a ngbNavLink>
                                <span>Cover Letter</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-cover-letter></app-cover-letter>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="5">
                            <a ngbNavLink>
                                <span>Trigger Point</span>
                            </a>
                            <ng-template ngbNavContent>
                                <app-trigger-point></app-trigger-point>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="justifiednavpills"></div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

// text,email,tel,textarea,password, 
@Component({
  selector: 'text-area',
  template: `
      <div [formGroup]="form" class="position-relative">
        <div class="disable_handler" *ngIf="field?.disabled"></div>
        <textarea [class.is-invalid]="isDirty && !isValid" [formControlName]="field.name" [id]="field.name"
        rows="1" class="form-control rounded-0" [placeholder]="field.placeholder"></textarea>
      </div> 
    `
})
export class TextAreaComponent implements OnInit {
  @Input() field: any = {};
  @Input() form!: FormGroup;
  // get isValid() { return this.form.controls[this.field.name].valid; }
  // get isDirty() { return this.form.controls[this.field.name].dirty; }
  get isValid() { return true }
  get isDirty() { return false }

  constructor() {

  }

  ngOnInit(): void {
    // this.form.patchValue(this.field.answer)
    if (this.field?.answer)
      this.form.controls[this.field?.name].setValue(this.field?.answer);

    // if (this.field.disabled) {
    //   this.form.controls[this.field?.name].disable();
    // } else {
    //   this.form.controls[this.field?.name].enable();
    // }
  }

}
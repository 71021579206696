<div class="container">
    <form class="row" [formGroup]="aboutForm" (ngSubmit)="onSubmit(aboutForm)">
        <div class="col-md-8 offset-md-2 p-4 mt-2">
            <div class="border shadow">
                <div class="my-2 row">
                    <label class="col-md-3 offset-md-1 col-form-label">Description</label>
                    <div class="col-md-7">
                        <textarea cols="30" rows="5" class="form-control" placeholder="Enter Description"
                            formControlName="description"
                            [ngClass]="{'invalid': aboutForm.get('description').invalid && isSubmitted}"></textarea>
                    </div>
                </div>
                <div class="my-2 row">
                    <div class="col-md-12">
                        <button
                            *ngIf="(page=='about-us' && checkPermission('content_management_about_us_create')) &&(page=='about-us' && checkPermission('content_management_about_us_edit')) || page=='home'"
                            class="btn btn-info float-end me-5" [disabled]="!hasChanges"><i
                                [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                            Save Changes</button>
                        <app-publish-btn
                            *ngIf="(page=='about-us' && checkPermission('content_management_about_us_publish')) || page=='home'"
                            class="float-end me-2" [publish]="'AboutUsAbout'" [page]="page"></app-publish-btn>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
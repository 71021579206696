<h4 class="w-100 text-center">
    <a class="float-start" [routerLink]="[backUrl]"  [queryParams]="{ page:pageNumber }"><i class="mdi mdi-arrow-left-bold text-primary"></i></a>
    {{applicationDetails?.application_no}}
    <i *ngIf="isLoading" class="fa fa-spin fa-spinner fa-pulse fa-2x text-primary"></i>
</h4>

<app-general-details [insuranceDetails]="insuranceDetails" [applicationDetails]="applicationDetails" [paymentDetails]="paymentDetails"></app-general-details>

<app-traveller-info [travellerInfo]="primaryTraveller" [isPrimary]="true" [app_id]="application_id" [title]="title" [paxDetails]="paxDetails"  (newItemEvent)="getApplicationDeatails($event)"></app-traveller-info>

<app-traveller-info [travellerInfo]="travellersDetails" [isPrimary]="false" [app_id]="application_id" [title]="title" [paxDetails]="paxDetails" (newItemEvent)="getApplicationDeatails($event)"></app-traveller-info>
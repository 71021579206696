<div class="modal-header">
    <h5 class="modal-title w-100 text-center">Remove Template</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)">
    </button>
</div>
<div class="modal-body">
        <div class="m-0 text-center">
            Are you sure to remove template <span class="badge rounded-pill bg-primary">{{details.name}}</span> ?
    </div>
</div>
<div class="modal-footer text-end">
    <button class="btn btn-danger me-2" (click)="confirmDelete(details.id)"> <i class="fa" [ngClass]="{'fa-spin fa-spinner fa-pulse':isDeleting, 'fa-trash':!isDeleting}"></i> Remove</button>
    <button class="btn btn-secondary" (click)="closeModal(false)">Cancel</button>
</div>
<div class="w-100 text-end mt-3" *ngIf="visaForm.value.visa.length>0">
    <a class="cpr btn btn-primary" 
        (click)="showSortModal(visaForm.value.visa, 'heading')">
        <i class="fa fa-sort" aria-hidden="true"></i>  Change Position</a>
</div>
<div class="container">
    <form [formGroup]="visaForm" (ngSubmit)="onSubmit(visaForm)">
        <div class="row">
            <div class="col-md-4 mt-2" formArrayName="visa" *ngFor="let item of visa().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="col-form-label">Key Values</label>
                                <div formArrayName="key_values" class="btn_grp">
                                    <div *ngFor="let lines of item.get('key_values').controls; let j=index">
                                        <div [formGroupName]="j" class="description">
                                            <div class="form-group d-flex my-2">
                                                <input formControlName="key" class="form-control me-1" type="text"
                                                    placeholder="Key" (change)="dataChanged(i)" />
                                                <input formControlName="value" class="form-control ms-1" type="text"
                                                    placeholder="Value" (change)="dataChanged(i)" />
                                                <button type="button" *ngIf="item.get('key_values').controls.length!=1"
                                                    class="deletebtn btn btn-pseudo remove"
                                                    (click)="deleteDescription(item.controls.key_values, j);dataChanged(i);"><i
                                                        class="fa fa-times text-danger"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-sm btn-primary mt-2 float-end"
                                    (click)="addNewDescription(item.controls.key_values)"
                                    *ngIf="item.get('key_values').controls.length<10">
                                    <i class="fa fa-plus"></i> Add
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="my-2 col-md-12">
                            <label class="col-form-label">Category</label>
                            <ng-select [(items)]="categoryList" [multiple]="false" [readonly]="true"
                                [placeholder]="'Select Category'" bindLabel="name" bindValue="id"
                                formControlName="category" [clearable]="false"></ng-select>
                        </div>
                        <!-- <div class="my-2 col-md-6">
                            <label class="col-form-label">Button Label</label>
                            <input type="text" class="form-control" placeholder="Button Label" formControlName="left_button_label"  (change)="dataChanged(i)"
                            >
                        </div>
                        <div class="my-2 col-md-12">
                            <label class="col-form-label">Button URL</label>
                            <input type="text" class="form-control" placeholder="Button URL" formControlName="left_button_url"  (change)="dataChanged(i)"
                                >
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <!-- <a href="javascript:void(0)" class="m-auto" (click)="addvisa()"><i class="uil-plus fa-5x text-success"></i></a> -->
                <div class="row">
                    <!-- <button class="btn btn-primary mt-1 me-1" type="button" (click)="addvisa()"><i class="uil-plus"></i>
                        Add</button> -->
                    <button
                        *ngIf="(page=='country' && checkPermission('content_management_country_create'))&&(page=='country' && checkPermission('content_management_country_edit'))"
                        class="btn btn-info mt-1 me-1 col-md-12" type="submit"><i class="bx bx-save"></i> Save
                        All</button>
                    <app-publish-btn *ngIf="(page=='country' && checkPermission('content_management_country_publish'))"
                        class="col-md-12 p-0 mt-1" [publish]="'CategoryDetail'" [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
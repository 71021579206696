<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-md-2">
                        <div class="col-md-3 offset-md-9">
                            <button type="button" class="btn btn-success float-end" (click)="addEmailModal()"><i
                                    class="mdi mdi-plus me-1"></i>
                                Add Template</button>
                        </div>
                    </div>
                    <!-- Table -->
                    <div class="table-responsive" *ngIf="!isLoading">
                        <table id="basic-datatable"
                            class="table table-centered datatables dt-responsive nowrap table-card-list">
                            <thead>
                                <tr>
                                    <th class="text-center">Name</th>
                                    <th class="text-center">To</th>
                                    <!-- <th class="text-center">Cc</th> -->
                                    <th class="text-center">Tags</th>
                                    <th class="text-center">Subject</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let table of emailList;let i=index">
                                <tr>
                                    <td class="text-center">
                                        <ngb-highlight>{{table?.name}}</ngb-highlight>
                                    </td>
                                    <td class="text-center">
                                        <ngb-highlight>{{table?.to}}</ngb-highlight>
                                    </td>
                                    <!-- <td class="text-center">
                                        <ngb-highlight>{{table?.cc}}</ngb-highlight>
                                    </td> -->
                                    <td class="text-center">
                                        <ngb-highlight>{{table?.tags}}</ngb-highlight>
                                    </td>
                                    <td class="text-center">
                                        <ngb-highlight>{{table?.subject}}</ngb-highlight>
                                    </td>
                                    <td class="text-center">
                                        <a class="cpr" (click)="addEmailModal(table)"><i
                                                class="fas fa-edit"></i></a>&nbsp;&nbsp;
                                        <a class="cpr" (click)="open(deleteEmailModal); selectedEmailDel=table"><i
                                                class="fas fa-trash text-danger"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="isLoading" class="d-flex mt-5">
                        <i class="fa fa-spin fa-spinner fa-pulse fa-5x m-auto"></i>
                    </div>
                    <!-- End table -->
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #emailDetails let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Edit Email Details</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
    </div>
</ng-template>

<ng-template #deleteEmailModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body text-center">
        Are you sure to delete <b>{{selectedEmailDel.name}}</b> ?<br>
    </div>
    <div class="modal-footer text-end">
        <button class="btn btn-danger" type="button" (click)="deleteEmail(selectedEmailDel)"><i
                [ngClass]="{'bx bx-save':!isDeleting, 'fa fa-spin fa-spinner fa-pulse':isDeleting}"></i> Delete
        </button>
        <button class="btn btn-outline" type="button" (click)="modal.close('Cancel')"> Cancel
        </button>
    </div>
</ng-template>
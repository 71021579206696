<div class="modal-header">
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="closeModal(false)"
    ></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 text-center">
        <h5>Sort</h5>
      </div>
      <div class="col-12">
        <div cdkDropList  class="example-list" (cdkDropListDropped)="drop($event)" >
          <div class="example-box" *ngFor="let data of arrayToSort" cdkDrag >
            <div class="example-custom-placeholder" *cdkDragPlaceholder>
                {{ data?.answer_choice }}
            </div>
            {{ data?.answer_choice }}
            <div class="font-size-20"><i class="mdi mdi-cursor-move"></i></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer text-end">
    <button class="btn btn-success me-2" (click)="confirmSort()"><i class="fa" ></i> Confirm Sort</button>
      <button class="btn btn-secondary" (click)="closeModal(false)"><i class="fa fa-times"></i> No</button>
  </div>
  
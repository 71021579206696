<div class="container">
    <form [formGroup]="voucherForm" (ngSubmit)="onSubmit(voucherForm)">
        <div class="row">
            <div class="col-md-6 mt-2" formArrayName="vouchers" *ngFor="let item of vouchers().controls; let i = index;"
                cdkDrag>
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="vouchers().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removevouchers(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Icon</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL" formControlName="image_url">
                                <a [href]="voucherForm.value.vouchers[i].image_url" target="_blank"><img class="ms-1"
                                        height="40" [src]="voucherForm.value.vouchers[i].image_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'image_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                                (change)="dataChanged(i)">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Description</label>
                            <ckeditor [editor]="Editor" formControlName="description" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('description').invalid}"
                                data="<p>Content of the editor.</p>"></ckeditor>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button
                        *ngIf="((page=='country' && checkPermission('content_management_country_create')) || (page=='category' && checkPermission('content_management_category_create')) || (page=='product' && checkPermission('content_management_product_create'))) && vouchers().controls.length<1"
                        class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addvouchers()">
                        <i class="uil-plus"></i> Add
                    </button>
                    <button
                        *ngIf="(page=='country' && checkPermission('content_management_country_create')) || (page=='category' &&checkPermission('content_management_category_create')) || (page=='product' && checkPermission('content_management_product_create')) && (page=='country' && checkPermission('content_management_country_edit')) || (page=='category' &&checkPermission('content_management_category_edit')) || (page=='product' && checkPermission('content_management_product_edit'))"
                        class="btn btn-info mt-1 me-1 col-md-12" [disabled]="isUpdating" type="submit">
                        <i class="bx bx-save"></i> Save All
                    </button>
                    <app-publish-btn
                        *ngIf="(page=='country' && checkPermission('content_management_country_publish')) || (page=='category' &&checkPermission('content_management_category_publish')) || (page=='product' && checkPermission('content_management_product_publish'))"
                        class="col-md-12 p-0 mt-1" [publish]="'Voucher'" [page]="'country'"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
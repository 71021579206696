<div class="container">
    <form [formGroup]="seoForm" (ngSubmit)="onSubmit(seoForm)">
        <div class="row">
            <div class="col-md-12 mt-2">
                <div class="example-custom-placeholder"></div>
                <div class="border shadow bg-white p-4 mt-2">
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">h1</label>
                            <input type="text" class="form-control" placeholder="H1 Tag" formControlName="h1">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">h2</label>
                            <input type="text" class="form-control" placeholder="H2 Tag" formControlName="h2">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <label class="col-md-12 col-form-label">Keywords</label>
                        <div formArrayName="keywords_data" class="col-md-12 row">
                            <div *ngFor="let desc of keyword.controls; let i=index" class="col-md-4">
                                <div [formGroupName]="i">
                                    <div class="form-group my-2 d-flex">
                                        <!-- <textarea cols="10" rows="1" class="form-control" placeholder="Enter Keywords"
                                        formControlName="keywords" (keyup)="hasChanges=true;"
                                        [ngClass]="{'invalid': desc.get('keywords').invalid && isSubmitted}"></textarea> -->
                                        <input class="form-control" placeholder="Enter Keywords"
                                        formControlName="keywords" (keyup)="hasChanges=true;"
                                        [ngClass]="{'invalid': desc.get('keywords').invalid && isSubmitted}">
                                        <button type="button"
                                            class="deletebtn btn btn-pseudo remove float-end"
                                            (click)="removeKeyword(i);"><i class="fa fa-times text-danger"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex align-items-center">
                                <button type="button" class="btn btn-sm btn-primary mt-2 float-end" (click)="addKeyword()">
                                    <i class="fa fa-plus"></i> Add keywords
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Title</label>
                            <input type="text" class="form-control" placeholder="Title" formControlName="title">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Description</label>
                            <input type="text" class="form-control" placeholder="Description"
                                formControlName="description">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Schema</label>
                            <div class="d-grid">
                                <div formArrayName="schema_multiple_files" class="btn_grp">
                                    <div *ngFor="let lines of files().controls; let i=index">
                                        <div [formGroupName]="i">
                                            <div class="form-group d-flex my-2">
                                                <input formControlName="schema_file" class="form-control" type="url"
                                                    placeholder="File URL" (change)="hasChanges=true;" />
                                                <input type="file" id="fileupload{{i}}"
                                                    (change)="getFile($event.target.files, i)" accept=".json"
                                                    style="display:none" />
                                                <button type="button" class="btn btn-success btn-sm py-0 ms-2"
                                                    (click)="fileUpload(i);hasChanges=true;"><i
                                                        class="fa fa-spinner fa-spin fa-pulse"
                                                        *ngIf="isUploading[i]"></i><i *ngIf="!isUploading[i]"
                                                        class="uil-file-upload-alt font-size-20"></i></button>
                                                <a (click)="removefiles(i);hasChanges=true;" class="my-auto mx-2 cpr"><i
                                                        class="fa fa-times text-danger"></i></a>
                                            </div>
                                        </div>
                                        <div class="col-md-12" *ngIf="progress[i]>0">
                                            <div class="mt-2 progress-bar">
                                                <div class="progress" [style.width.%]="progress[i]">
                                                    {{progress[i]}}%
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-sm btn-primary mt-2 float-end"
                                (click)="addfiles();hasChanges=true;">
                                <i class="fa fa-plus"></i> Add File
                            </button>
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Generated Schema</label>
                            <textarea rows="5" class="form-control" placeholder="Enter Schema" formControlName="schema"
                                readonly></textarea>
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Canonical url</label>
                            <input type="text" class="form-control" placeholder="Canonical url"
                                formControlName="canonical_url">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Og Title</label>
                            <input type="text" class="form-control" placeholder="Og Title" formControlName="og_title">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Og Description</label>
                            <input type="text" class="form-control" placeholder="Og Description"
                                formControlName="og_description">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Og Image url</label>
                            <input type="text" class="form-control" placeholder="Og Image url"
                                formControlName="og_image_url">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Og Image Secure url</label>
                            <input type="text" class="form-control" placeholder="Og Image Secure url"
                                formControlName="og_image_secure_url">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Og Image Type</label>
                            <input type="text" class="form-control" placeholder="Og Image Type"
                                formControlName="og_image_type">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Og Image Width</label>
                            <input type="number" min="0" class="form-control" placeholder="Og Image Width"
                                formControlName="og_image_width">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Og Image Height</label>
                            <input type="number" min="0" class="form-control" placeholder="Og Image Height"
                                formControlName="og_image_height">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Og Type</label>
                            <input type="text" class="form-control" placeholder="Og Type" formControlName="og_type">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Og url</label>
                            <input type="text" class="form-control" placeholder="Og url" formControlName="og_url">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Og Site Name</label>
                            <input type="text" class="form-control" placeholder="Og Site Name"
                                formControlName="og_site_name">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Og Locale</label>
                            <input type="text" class="form-control" placeholder="Og Locale" formControlName="og_locale">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Twitter Card Type</label>
                            <input type="text" class="form-control" placeholder="Twitter Card Type"
                                formControlName="twitter_card_type">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Twitter Site</label>
                            <input type="text" class="form-control" placeholder="Twitter Site"
                                formControlName="twitter_site">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Twitter Creator</label>
                            <input type="text" class="form-control" placeholder="Twitter Creator"
                                formControlName="twitter_creator">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Twitter Title</label>
                            <input type="text" class="form-control" placeholder="Twitter Title"
                                formControlName="twitter_title">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Twitter Description</label>
                            <input type="text" class="form-control" placeholder="Twitter Description"
                                formControlName="twitter_description">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Twitter Image url</label>
                            <input type="text" class="form-control" placeholder="Twitter Image url"
                                formControlName="twitter_image_url">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Twitter Image alt</label>
                            <input type="text" class="form-control" placeholder="Twitter Image alt"
                                formControlName="twitter_image_alt">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Facebook Og Type</label>
                            <input type="text" class="form-control" placeholder="Facebook Og Type"
                                formControlName="facebook_og_type">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Facebook Og url</label>
                            <input type="text" class="form-control" placeholder="Facebook Og url"
                                formControlName="facebook_og_url">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Facebook Og Title</label>
                            <input type="text" class="form-control" placeholder="Facebook Og Title"
                                formControlName="facebook_og_title">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Facebook Og Description</label>
                            <input type="text" class="form-control" placeholder="Facebook Og Description"
                                formControlName="facebook_og_description">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Facebook Og Image url</label>
                            <input type="text" class="form-control" placeholder="Facebook Og Image url"
                                formControlName="facebook_og_image_url">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Facebook Og Image Secure url</label>
                            <input type="text" class="form-control" placeholder="Facebook Og Image Secure url"
                                formControlName="facebook_og_image_secure_url">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Facebook Og Image Type</label>
                            <input type="text" class="form-control" placeholder="Facebook Og Image Type"
                                formControlName="facebook_og_image_type">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Facebook Og Image Width</label>
                            <input type="number" min="0" class="form-control" placeholder="Facebook Og Image Width"
                                formControlName="facebook_og_image_width">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Facebook Og Image Height</label>
                            <input type="number" min="0" class="form-control" placeholder="Facebook Og Image Height"
                                formControlName="facebook_og_image_height">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Instagram Title</label>
                            <input type="text" class="form-control" placeholder="Instagram Title"
                                formControlName="instagram_title">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Instagram Image url</label>
                            <input type="text" class="form-control" placeholder="Instagram Image url"
                                formControlName="instagram_image_url">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Instagram Image Width</label>
                            <input type="number" min="0" class="form-control" placeholder="Instagram Image Width"
                                formControlName="instagram_image_width">
                        </div>
                        <div class="col-md-6">
                            <label class="col-form-label">Instagram Image Height</label>
                            <input type="number" min="0" class="form-control" placeholder="Instagram Image url Height"
                                formControlName="instagram_image_height">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-6">
                            <label class="col-form-label">Robots Index</label>
                            <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                                Robots Index <input type="checkbox" class="form-check-input"
                                    formControlName="robots_index" id="customSwitchsizeOne">
                            </div>
                        </div>

                        <div class="col-md-6">
                            <label class="col-form-label">Robots Follow</label>
                            <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                                Robots Follow <input type="checkbox" class="form-check-input"
                                    formControlName="robots_follow" id="customSwitchsizeTwo">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 py-5">
                <div class="text-end">
                    <button
                        *ngIf="checkPermission('content_management_seo_create')&&checkPermission('content_management_seo_edit')"
                        class="btn btn-success float-end" [disabled]="!hasChanges"><i *ngIf="isUpdating"
                            class="fa fa-spin fa-spinner fa-pulse"></i> Save Changes</button>
                    <app-publish-btn *ngIf="checkPermission('content_management_seo_publish')" class="float-end me-2"
                        [publish]="publishPage" [page]="'seo'" [value_from_page]="updateId"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AddCountryComponent } from "./add-country/add-country.component";
import { ProductService } from "src/app/core/services/product.service";
import { SharedService } from "src/app/core/services/shared.service";
import { ToastrService } from "ngx-toastr";
import { PermissionService } from "src/app/core/services/permision.service";

@Component({
  selector: "app-country",
  templateUrl: "./country.component.html",
  styleUrls: ["./country.component.scss"],
})
export class CountryComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  pageSize = 10;
  pageNumber = 1;
  page_details = {
    count: 0,
    next: "",
    previous: "",
    current: 0,
  };
  searchTerm = "";
  isLoading = false;
  countryList = this.sharedService.countryList;
  status = "any";
  countryData = [];
  countryDataCopy = [];
  selectedData: any;
  @ViewChild("countryDetails", { static: false }) countryModal: ElementRef;
  modalReference: any;

  constructor(
    private api: ProductService,
    private modalService: NgbModal,
    private sharedService: SharedService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    public permission: PermissionService
  ) {}

  ngOnInit() {
    this.breadCrumbItems = [
      { label: "Product Management" },
      { label: "Country", active: true },
    ];
    if (this.sharedService.countryList.length == 0) this.getCountriesList();
    this.getAddedCountries();
  }

  nextPage(page) {
    // this.getProductsData(this.pageSize,this.pageSize*(page-1),this.vendorId,this.stockStatus,this.searchTerm);
  }

  getAddedCountries() {
    this.isLoading = true;
    this.countryData = [];
    this.api.getCountryDetails().subscribe(
      (result: any) => {
        this.countryData = result;
        this.countryDataCopy = [...result];
        this.sharedService.countryAllData = this.countryData;
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  getCountriesList() {
    this.api.getCountryNames().subscribe((response: any) => {
      this.countryList = response;
      this.sharedService.countryList = response;
    });
  }

  addCountryModal() {
    const modalRef = this.modalService.open(AddCountryComponent, {
      size: "xl",
      scrollable: true,
      windowClass: "modal-big-lg",
      // keyboard: false,
      backdrop: "static",
    });

    let data = {
      countries: this.countryList,
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {
        this.getAddedCountries();
        this.getCountriesList();
        this.sharedService.cmsCountryList = [];
      },
      (reason) => {
        this.getAddedCountries();
        this.getCountriesList();
        this.sharedService.cmsCountryList = [];
      }
    );
  }

  changeStatus(event, data) {
    let status = { display_status: event.target.checked };
    this.api.setDisplayStatus(data.id, status).subscribe((result: any) => {
      this.toastr.success(`Status Changed Successfully!`, `Success!`);
      this.getCountriesList();
    });
  }

  changeLdFrmStatus(event, data) {
    let dataToSend = { lead_form: event.target.checked };
    this.api.setLeadFormStatus(data.id, dataToSend).subscribe((result: any) => {
      this.toastr.success(`Status Changed Successfully!`, `Success!`);
    });
  }

  searchCountry(searchTerm) {
    this.countryData = this.countryDataCopy.filter((ae) =>
      ae.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  open(content) {
    this.modalReference = this.modalService.open(content, {
      size: "lg",
      ariaLabelledBy: "modal-basic-title",
    });
  }

  editCountry(data) {
    this.selectedData = data;
    this.open(this.countryModal);
  }

  closeModal() {
    this.modalReference.close("close");
    this.getAddedCountries();
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  changeHotel(event, data) {
    const dataToSend = {
      hotel_status: event.target.checked,
    };
    this.api.changeHotel(data.id, dataToSend).subscribe((res) => {
      this.toastr.success("Hotel status changed successfully", "Success");
    });
  }

  changeFlight(event, data) {
    const dataToSend = {
      flight_status: event.target.checked,
    };
    this.api.changeFlight(data.id, dataToSend).subscribe((res) => {
      this.toastr.success("Flight status changed successfully", "Success");
    });
  }

  changeInsurance(event, data) {
    const dataToSend = {
      insurance_status: event.target.checked,
    };
    this.api.chnageInsurance(data.id, dataToSend).subscribe((res) => {
      this.toastr.success("Insurance status changed successfully", "Success");
    });
  }

  changeForex(event, data) {
    const dataToSend = {
      forex_status: event.target.checked,
    };
    this.api.chnageForex(data.id, dataToSend).subscribe((res) => {
      this.toastr.success("Forex status changed successfully", "Success");
    });
  }

  changeItinerary(event,data){
    const dataToSend = {
      itinerary_status: event.target.checked,
    }
    this.api.chnageItinerary(data.id, dataToSend).subscribe((res) => {
      this.toastr.success("Itinerary status changed successfully", "Success");
    });
  }

  changeCourier(event, data) {
    const dataToSend = {
      courier_status: event.target.checked,
    };
    this.api.chnageCourier(data.id, dataToSend).subscribe((res) => {
      this.toastr.success("Courier status changed successfully", "Success");
    });
  }
}

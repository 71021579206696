<!-- <div class="options-panel">
    <button class="btn btn-primary cog-btn" 
    (click)="showPanel=!showPanel" 
    (mouseover)="hoverCog=true;"
    (mouseout)="hoverCog=false;"><i [ngClass]="{'fa-spin fa-pulse':hoverCog}" class="fa fa-cog"></i></button>
    <div class="panel-box" *ngIf="showPanel">
      <button class="btn btn-info" (click)="previewPage()"><i class="fa fa-eye"></i> Preview</button>
      <button class="btn btn-success" (click)="publishPage()"><i class="fa fa-check"></i> Publish</button>
    </div>
  </div> -->

  <div class="options-panel">
    <button class="btn btn-primary cog-btn d-flex" 
    (mouseover)="hoverCog=true;"
    (mouseout)="hoverCog=false;"
    (click)="previewPage()">&nbsp;<i class="fa fa-eye m-auto"></i>&nbsp;{{hoverCog ? 'Preview' : ''}}</button>
  </div>
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { VisaManageService } from 'src/app/core/services/visa-manage.service';
import { ActivatedRoute, Router } from '@angular/router';

import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';
import { UploadModalComponent } from './modals/upload-modal/upload-modal.component';
import { VerifyModalComponent } from './modals/verify-modal/verify-modal.component';
import { DataModalComponent } from './modals/data-modal/data-modal.component';
import { ScheduleModalComponent } from './modals/schedule-modal/schedule-modal.component';
import { NotificationsListComponent } from './modals/notifications-list/notifications-list.component';
import { UserVerificationModalComponent } from './modals/user-verification-modal/user-verification-modal.component';


@Component({
  selector: 'app-visa-flow',
  templateUrl: './visa-flow.component.html',
  styleUrls: ['./visa-flow.component.scss']
})
export class VisaFlowComponent implements OnInit {
  pax_id: string;
  flowDetails:any = [];
  flowData = [];
  selectedStepType: number;
  selectedFlowDetails: any;
  workflow_process_id: any;
  subProcessDetails: any;
  isSubBtnEnabled: boolean;
  cardDetails: any;
  selectedStepProcess: any;
  selectedIndex: any;
  stepsList: any;
  highlightCard: boolean[] = [false];
  @ViewChild('svgScroll') svgScroll: ElementRef;
  currentTab: string;
  isLoading: boolean;
  public mr: NgbModalRef;
  fromWhere: boolean;

  app_id = history.state.app_id;
  @ViewChild('noPermission') noPermissionModal: any;
  questionsList: Object;
  @ViewChild('questionModal', { static: false }) queModal: ElementRef;

  constructor(private route: ActivatedRoute, private api: VisaManageService, private modalService: NgbModal, public activeModal: NgbActiveModal) { }


  ngOnInit(): void {
    this.pax_id = this.route.snapshot.paramMap.get('id');
    this.getFlow(this.pax_id);
  }

  getFlow(pax_id, isSub?) {
    this.isLoading = true;
    this.api.paxWorkFlow(pax_id).subscribe(
      (result: any) => {
        this.isLoading = false;
        this.flowDetails = result.length > 0 ? result[0] : [];
        this.flowData = result.length > 0 ? result[0].processes : [];
        if(isSub) this.showStepDetails(this.selectedStepType, this.selectedIndex);
      },
      error => {
        this.isLoading = false;
      }
    )
  }



  openUserVerificationModal(){
    const modalRef = this.modalService.open(UserVerificationModalComponent, {
      size: 'sm',
      keyboard: false,
      backdrop: 'static',
      centered:true
    })
    modalRef.componentInstance.paxId=this.flowDetails?.pax
  }

  checkTypeTab(tab) {
    return this.flowData[this.selectedIndex].section_name == tab;
  }

  showNotificationData(process_name, position, tab?) {
    console.log(process_name, position, tab);
  }

  showConfirmModal(api, pax_id) {
    let data_card: any = {
      api: api,
      pax_id: parseInt(pax_id, 10)
    };
    if (this.flowData[this.selectedIndex].has_permission) {
      const modalRef = this.modalService.open(ConfirmModalComponent,
        {
          size: 'md',
          scrollable: true,
          windowClass: 'modal-big-lg',
          keyboard: false,
          backdrop: 'static'
        });
      modalRef.componentInstance.paxDetails = data_card;
      modalRef.result.then((result) => {
        if (result) {
          this.getFlow(this.pax_id, true);
          if (api == 'master-form/agent-verification-started') this.openQuestionModal(true);
        }
      }, (reason) => {
      });
    } else {
      this.open(this.noPermissionModal);
    }
  }

  openUploadModal(url) {
    let data = {
      url: url,
      pax_id: this.pax_id,
      process_id: this.flowData[this.selectedIndex].id,
      doc_type: this.flowData[this.selectedIndex].doc_type || ''
    }
    if (this.flowData[this.selectedIndex].has_permission) {
      const modalRef = this.modalService.open(UploadModalComponent,
        {
          size: 'lg',
          scrollable: true,
          windowClass: 'modal-big-lg',
          keyboard: false,
          backdrop: 'static'
        });
      modalRef.componentInstance.uploadDetails = data;
      modalRef.result.then((result) => {
        if (result) this.getFlow(this.pax_id, true);
      }, (reason) => {
      });
    } else {
      this.open(this.noPermissionModal);
    }
  }

  openVerifyModal(url) {
    let data = {
      api: url,
      pax_id: parseInt(this.pax_id, 10),
      doc_type: this.flowData[this.selectedIndex].doc_type || ''
    }
    if (this.flowData[this.selectedIndex].has_permission) {
      const modalRef = this.modalService.open(VerifyModalComponent,
        {
          size: 'lg',
          scrollable: true,
          windowClass: 'modal-big-lg',
          keyboard: false,
          backdrop: 'static'
        });
      modalRef.componentInstance.uploadDetails = data;
      modalRef.result.then((result) => {
        if (result) this.getFlow(this.pax_id, true);
      }, (reason) => {
      });
    } else {
      this.open(this.noPermissionModal);
    }
  }

  openDataModal(url,role) {
    console.log(url)
    let data = {
      api: url,
      pax_id: parseInt(this.pax_id, 10),
      doc_type: this.flowData[this.selectedIndex].doc_type || '',
      key: this.flowData[this.selectedIndex]?.key
    }
    if (this.flowData[this.selectedIndex].has_permission) {
      const modalRef = this.modalService.open(DataModalComponent,
        {
          size: 'lg',
          scrollable: true,
          windowClass: 'modal-big-lg',
          keyboard: false,
          backdrop: 'static'
        });
      modalRef.componentInstance.dataDetails = data;
      modalRef.componentInstance.role=role
      modalRef.result.then((result) => {
        if (result) this.getFlow(this.pax_id, true);
      }, (reason) => {
      });
    } else {
      this.open(this.noPermissionModal);
    }
  }

  openScheduleModal(url, role) {
    let data = {
      api: url,
      pax_id: parseInt(this.pax_id, 10),
      role: role,
      doc_type: this.flowData[this.selectedIndex].doc_type || ''
    }
    if (this.flowData[this.selectedIndex].has_permission) {
      const modalRef = this.modalService.open(ScheduleModalComponent,
        {
          size: 'md',
          scrollable: true,
          windowClass: 'modal-big-lg',
          keyboard: false,
          backdrop: 'static'
        });
      modalRef.componentInstance.dataDetails = data;
      modalRef.result.then((result) => {
        if (result) this.getFlow(this.pax_id, true);
      }, (reason) => {
      });
    } else {
      this.open(this.noPermissionModal);
    }
  }

  getCurrentTab(data) {
    let tab = '';
    data.forEach(ae => {
      if (ae.selected) tab = ae.name;
    });
    return tab;
  }

  showStepDetails(id, index, process?, process_id?) {
    this.selectedStepType = id;
    this.selectedIndex = index;
    this.currentTab = this.flowData[this.selectedIndex].section_name;
  }

  checkTypeDocType(sche_for) {
    return this.flowData[this.selectedIndex].doc_type == sche_for;
  }

  onWheel(event: WheelEvent): void {
    if (this.flowData.length > 5) this.svgScroll.nativeElement.scrollLeft += event.deltaY;
    event.preventDefault();
  }

  checkCurrentStatus(sub_process, pos, section_name?) {
    let status = this.flowData[this.selectedIndex]?.notifications.filter(ae => ae.subprocess_name == sub_process);
    return status.length > 0 ? status[0][pos].is_current_event : false;
  }

  open(content) {
    this.mr = this.modalService.open(content, { size: 'lg', ariaLabelledBy: "modal-basic-title" });
  }

  openQuestionModal(isMaster) {
    if (this.flowData[this.selectedIndex].has_permission) {
      this.fromWhere = isMaster;
      this.api.getCountryQuestionsByPax(this.pax_id).subscribe(
        result => {
          this.open(this.queModal);
          this.questionsList = result;
        })
    } else {
      this.open(this.noPermissionModal);
    }
  }

  closeModal(status?) {
    if(status=='success'){
      this.getFlow(this.pax_id, true);
    }
    this.mr.close();
  }

  openNotificationModal(notifications) {
    const modalRef = this.modalService.open(NotificationsListComponent,
      {
        size: 'lg',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.notifications = notifications;
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }

  showNotificationModal(sub_process, status) {
    let data = {
      workflow_process_id: this.flowData[this.selectedIndex].id,
      status_id: this.flowData[this.selectedIndex].notifications.filter(ae => ae.subprocess_name == sub_process)[0][status].id
    }
    this.getNotifications(data.status_id, data.workflow_process_id);
  }

  getNotifications(status_id, workflow_process_id){
    this.api.getNotificationsList(status_id, workflow_process_id).subscribe(
      (result:[])=>{
        this.openNotificationModal(result)
      }
    )
  }


}

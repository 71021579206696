<div class="container">
    <form [formGroup]="headerForm" (ngSubmit)="onSubmit(headerForm)">
        <div class="row">
            <div class="col-md-4 mt-2">
                <div class="border shadow bg-white p-4 mt-2">
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Image URL</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL" formControlName="image_url">
                                <a [href]="headerForm.value.image_url" target="_blank">
                                    <img class="ms-1" height="40" [src]="headerForm.value.image_url">
                                </a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal('image_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Mobile Image URL</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL" formControlName="mob_image_url">
                                <a [href]="headerForm.value.mob_image_url" target="_blank">
                                    <img class="ms-1" height="40" [src]="headerForm.value.mob_image_url">
                                </a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal('mob_image_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="page=='blog' || page=='news'">
                        <div class="col-12">
                            <div class="my-2">
                                <label class="col-form-label">Button Label</label>
                                <input type="text" class="form-control" placeholder="Button Label"
                                    formControlName="button_label">
                                <!-- [ngClass]="{'invalid': item.get('button_label').invalid && isSubmitted}" -->
                            </div>
                            <div class="my-2">
                                <label class="col-form-label">Button URL</label>
                                <input type="text" class="form-control" placeholder="Button URL"
                                    formControlName="button_url" [ngClass]="{'invalid': headerForm.get('button_url').invalid && isSubmitted}">
                                <!-- [ngClass]="{'invalid': item.get('button_url').invalid && isSubmitted}" -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-flex p-5">
                <div class="m-auto">
                    <button class="btn btn-info mt-1 mb-1 col-md-12" type="submit"><i
                            [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                        Save</button>
                    <app-publish-btn 
                        class="col-md-12 p-0" [publish]="'TrustedPartner'" [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
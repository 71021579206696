<div class="container-fluid">
    <h4 class="w-100 text-center">
        <a *ngIf="app_id" class="float-start" [routerLink]="['/visa/application', app_id]"queryParamsHandling="merge"><i
                class="mdi mdi-arrow-left-bold text-primary"></i></a>
        <a *ngIf="!app_id" class="float-start" [routerLink]="['/visa/process']"><i
                class="mdi mdi-arrow-left-bold text-primary"></i></a>
        Flow {{pax_id}}
    </h4>
    <div class="w-100 text-center mt-4" *ngIf="isLoading"><i
            class="fa fa-spin fa-spinner fa-pulse fa-4x text-primary"></i></div>
    <div class="svg-wrapper scroll" [ngStyle]="{'overflow-x': (flowData.length>5) ? 'auto' : 'hidden'}" #svgScroll>
        <div class="svg-container" *ngIf="flowData.length>0">
            <svg version="1.1" [attr.viewBox]="'0 0 4000 100'" preserveAspectRatio="xMinYMin meet" class="svg-content"
                (wheel)="onWheel($event)">

                <defs>
                    <marker id="arrow-start" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-11" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                    <marker id="arrow" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-12" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                </defs>

                <g *ngFor="let item of flowData; let i=index; let isLast = last; let isFirst = first;">
                    <g class="box-group" [attr.transform]="'translate(0, 0)'">
                        <g transform="translate(5)" *ngIf="item.type_id==0">
                            <circle fill="#CCC" cx="34" cy="31" r="22" opacity="1" />
                            <circle fill="#277e71" cx="30" cy="30" r="22" opacity="1" />
                            <text x="19" y="32" font-family="Open Sans Condensed" font-size="6" stroke="none"
                                fill="#f5f3e7" font-weight="900"
                                style="text-transform:uppercase; letter-spacing: 1px">Start</text>
                        </g>

                        <g [attr.transform]="'translate(' + 75*i + ')'" *ngIf="item.type_id==1" class="cpr"
                            (mousedown)="showStepDetails(item.type_id, i,item.process,item.id)">
                            <rect [attr.fill]="selectedIndex==i ? '#66cc00' : '#CCC'" x="10" y="13" rx="3" ry="3"
                                width="50" height="40" />
                            <rect [attr.fill]="item.is_current_process ? '#40db5ebf' : '#002024'" x="7" y="10" rx="3"
                                ry="3" width="50" height="40" />
                            <image width="25" height="17" stroke="none" x="20" y="13"
                                xlink:href="/assets/icons/agent.svg"></image>
                            <text x="5" y="29" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="22" dy="9">Agent</tspan>
                                <tspan x="21" dy="6">Assign</tspan>
                            </text>
                        </g>

                        <g [attr.transform]="'translate(' + 75*i + ')'" *ngIf="item.type_id==2" class="cpr"
                            (click)="showStepDetails(item.type_id, i,item.process,item.id)">
                            <rect [attr.fill]="selectedIndex==i ? '#66cc00' : '#CCC'" x="10" y="13" rx="3" ry="3"
                                width="50" height="40" />
                            <rect [attr.fill]="item.is_current_process ? '#40db5ebf' : '#002024'" x="7" y="10" rx="3"
                                ry="3" width="50" height="40" />
                            <image width="25" height="17" stroke="none" x="22" y="13"
                                xlink:href="/assets/icons/master-form.svg"></image>
                            <text x="5" y="29" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="10" dy="9">Master Form</tspan>
                                <tspan x="20" dy="6">FIlling</tspan>
                            </text>
                        </g>

                        <g [attr.transform]="'translate(' + 75*i + ')'" *ngIf="item.type_id==3" class="cpr"
                            (click)="showStepDetails(item.type_id, i,item.process,item.id)">
                            <rect [attr.fill]="selectedIndex==i ? '#66cc00' : '#CCC'" x="10" y="13" rx="3" ry="3"
                                width="50" height="40" />
                            <rect [attr.fill]="item.is_current_process ? '#40db5ebf' : '#002024'" x="7" y="10" rx="3"
                                ry="3" width="50" height="40" />
                            <image width="25" height="17" stroke="none" x="22" y="13"
                                xlink:href="/assets/icons/consulate-form.svg"></image>
                            <text x="5" y="29" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="18" dy="8">Consulate</tspan>
                                <tspan x="13" dy="6">Form FIlling</tspan>
                            </text>
                        </g>

                        <g [attr.transform]="'translate(' + 75*i + ')'" *ngIf="item.type_id==4" class="cpr"
                            (click)="showStepDetails(item.type_id, i,item.process,item.id)">
                            <rect [attr.fill]="selectedIndex==i ? '#66cc00' : '#CCC'" x="10" y="13" rx="3" ry="3"
                                width="50" height="40" />
                            <rect [attr.fill]="item.is_current_process ? '#40db5ebf' : '#002024'" x="7" y="10" rx="3"
                                ry="3" width="50" height="40" />
                            <image width="25" height="20" stroke="none" x="19" y="15"
                                xlink:href="/assets/icons/upload.svg"></image>
                            <text x="5" y="29" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="20" dy="13">Upload</tspan>
                            </text>
                        </g>

                        <g [attr.transform]="'translate(' + 75*i + ')'" *ngIf="item.type_id==5" class="cpr"
                            (click)="showStepDetails(item.type_id, i,item.process,item.id)">
                            <rect [attr.fill]="selectedIndex==i ? '#66cc00' : '#CCC'" x="10" y="13" rx="3" ry="3"
                                width="50" height="40" />
                            <rect [attr.fill]="item.is_current_process ? '#40db5ebf' : '#002024'" x="7" y="10" rx="3"
                                ry="3" width="50" height="40" />
                            <image width="25" height="20" stroke="none" x="19" y="15"
                                xlink:href="/assets/icons/update.svg"></image>
                            <text x="5" y="29" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="12" dy="13">Data Update</tspan>
                            </text>
                        </g>

                        <g [attr.transform]="'translate(' + 75*i + ')'" *ngIf="item.type_id==6" class="cpr"
                            (click)="showStepDetails(item.type_id, i,item.process,item.id)">
                            <rect [attr.fill]="selectedIndex==i ? '#66cc00' : '#CCC'" x="10" y="13" rx="3" ry="3"
                                width="50" height="40" />
                            <rect [attr.fill]="item.is_current_process ? '#40db5ebf' : '#002024'" x="7" y="10" rx="3"
                                ry="3" width="50" height="40" />
                            <image width="25" height="20" stroke="none" x="21" y="15"
                                xlink:href="/assets/icons/schedule.svg"></image>
                            <text x="5" y="29" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="16" dy="13">Schedule</tspan>
                            </text>
                        </g>

                        <g [attr.transform]="'translate(' + 75*i + ')'" *ngIf="item.type_id==7" class="cpr"
                            (click)="showStepDetails(item.type_id, i,item.process,item.id)">
                            <rect [attr.fill]="selectedIndex==i ? '#66cc00' : '#CCC'" x="10" y="13" rx="3" ry="3"
                                width="50" height="40" />
                            <rect [attr.fill]="item.is_current_process ? '#40db5ebf' : '#002024'" x="7" y="10" rx="3"
                                ry="3" width="50" height="40" />
                            <image width="25" height="20" stroke="none" x="20" y="15"
                                xlink:href="/assets/icons/passport.svg"></image>
                            <text x="5" y="29" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="17" dy="11">Document</tspan>
                                <tspan x="15" dy="5">Submission</tspan>
                            </text>
                        </g>

                        <g *ngIf="!isLast">
                            <line [attr.x1]="isFirst ? 58 : 75*i+57" [attr.x2]="isFirst ? 81 : 75*i+81" y1="29" y2="29"
                                stroke-width="1.5" stroke="#443c3d" stroke-dasharray="2,1"
                                [attr.marker-start]="'url(#arrow)'" />
                        </g>

                    </g>
                </g>
            </svg>
        </div>
    </div>
    <div class="mt-2">
        <!-- Agent Assign -->
        <div class="wrapper-details" *ngIf="selectedStepType==1">
            <!-- Agent Assign -->
            <svg version="1.1" viewBox="-50 22 500 145" preserveAspectRatio="xMinYMin meet" class="svg-content">
                <defs>
                    <marker id="arrow-start" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-13" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                    <marker id="arrow-end" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-43" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                    <marker id="arrow-end2" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-30" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                </defs>

                <g class="box-group">
                    <rect width="198" height="105" x="82" y="23" fill="rgb(255, 255, 255)"
                        vector-effect="non-scaling-stroke" stroke-width="1" stroke="rgb(255 99 71)"
                        stroke-dasharray="10,5" />

                    <g [attr.transform]="currentTab=='DIY' ? 'translate(245)' : 'translate(215)'">
                        <rect fill="#66cc00" x="10" y="23" [attr.width]="currentTab=='DIY' ? 25 : 55" height="11" />
                        <text x="10" y="16" font-family="Open Sans Condensed" font-size="5" stroke="none" fill="#f5f3e7"
                            font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="16" dy="14">{{currentTab}}</tspan>
                        </text>
                        <rect opacity="0" x="10" y="23" [attr.width]="currentTab=='DIY' ? 25 : 55" height="10"
                            class="cpr" />
                    </g>

                    <g *ngIf="checkTypeTab('DIY')">
                        <line class="path" x1="50" x2="153" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-start)" />
                        <g transform="translate(152)">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#7f7f7f" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="16" stroke="none" x="15" y="59"
                                xlink:href="/assets/icons/agent.svg"></image>
                            <text x="12" y="65" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="18" dy="16">Assign</tspan>
                                <tspan x="12" dy="6">OPS Agent</tspan>
                            </text>
                        </g>
                        <line x1="205" x2="310" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        <g transform="translate(104)">
                            <circle
                                [attr.fill]="checkCurrentStatus('ASSIGN OPS AGENT','start_status','DIY') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('ASSIGN OPS AGENT','start_status','DIY') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('ASSIGN OPS AGENT', 'start_status')" />
                        </g>
                        <g transform="translate(163.5)">
                            <circle
                                [attr.fill]="checkCurrentStatus('ASSIGN OPS AGENT','success_status','DIY') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('ASSIGN OPS AGENT','success_status','DIY') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('ASSIGN OPS AGENT', 'success_status')" />
                        </g>
                    </g>
                    <g *ngIf="checkTypeTab('Consultant')">
                        <line class="path" x1="50" x2="112" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-start)" />
                        <g transform="translate(200)" class="cpr"
                            (click)="showConfirmModal('agent-assign-schedule/agent-assign-schedule-start',this.pax_id);">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#a3748f" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="16" stroke="none" x="15" y="64"
                                xlink:href="/assets/icons/schedule.svg">
                            </image>
                            <text x="12" y="65" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="12" dy="20">Schedule</tspan>
                            </text>
                        </g>
                        <line x1="164" x2="200" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow)" />
                        <g transform="translate(110)">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#7f7f7f" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="16" stroke="none" x="15" y="59"
                                xlink:href="/assets/icons/agent.svg">
                            </image>
                            <text x="12" y="65" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="18" dy="16">Assign</tspan>
                                <tspan x="12" dy="6">OPS Agent</tspan>
                            </text>
                        </g>
                        <line x1="255" x2="310" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end2)" />
                        <g transform="translate(152)">
                            <circle
                                [attr.fill]="checkCurrentStatus('SCHEDULE','start_status','Consultant') ? '#66cc00' : '#000'"
                                cx="45" cy="75" fill="#000" r="5" [attr.r]="5" opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('SCHEDULE', 'start_status')" />
                        </g>
                        <g transform="translate(212)">
                            <circle
                                [attr.fill]="checkCurrentStatus('SCHEDULE','success_status','Consultant') ? '#66cc00' : '#000'"
                                cx="45" cy="75" [attr.r]="5" opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72" xlink:href="/assets/icons/bell.svg"
                                class="cpr" (click)="showNotificationModal('SCHEDULE', 'success_status')">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" />
                        </g>
                        <g transform="translate(62)">
                            <circle
                                [attr.fill]="checkCurrentStatus('ASSIGN OPS AGENT','start_status','Consultant') ? '#66cc00' : '#000'"
                                cx="45" cy="75" [attr.r]="5" opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('ASSIGN OPS AGENT', 'start_status')" />
                        </g>
                        <g transform="translate(124)">
                            <circle [attr.fill]="'#000'" cx="45" cy="75" [attr.r]="5" opacity="1"
                                [attr.fill]="checkCurrentStatus('ASSIGN OPS AGENT','success_status','Consultant') ? '#66cc00' : '#000'" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('ASSIGN OPS AGENT', 'success_status')" />
                        </g>

                        <line x1="270" x2="270" y1="76" y2="112" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                        </line>

                        <line x1="185" x2="185" y1="112" y2="76" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                        </line>

                        <line x1="269" x2="187" y1="111" y2="111" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />

                        <g transform="translate(185)">
                            <circle
                                [attr.fill]="checkCurrentStatus('SCHEDULE','failure_status','Consultant') ? '#66cc00' : '#000'"
                                cx="45" cy="111" [attr.r]="5" opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="108"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="111" r="5" opacity="0"
                                (click)="showNotificationModal('SCHEDULE', 'failure_status')" />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <!-- Master form filling -->
        <div class="wrapper-details" *ngIf="selectedStepType==2">
            <svg version="1.1" viewBox="-50 22 500 145" preserveAspectRatio="xMinYMin meet" class="svg-content">
                <defs>
                    <marker id="arrow-up" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-9" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                    <marker id="arrow-bottom" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-8" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                    <marker id="arrow" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-13" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                </defs>

                <g class="box-group">
                    <rect width="198" height="105" x="82" y="23" fill="rgb(255, 255, 255)"
                        vector-effect="non-scaling-stroke" stroke-width="1" stroke="rgb(255 99 71)"
                        stroke-dasharray="10,5" />
                    <line class="path" x1="50" x2="100" y1="50" y2="50" stroke-width="1.5" stroke="#443c3d"
                        stroke-dasharray="2,1" marker-start="url(#arrow)" />
                    <g transform="translate(98)">
                        <rect fill="#CCC" x="5" y="33" rx="3" ry="3" width="50" height="40" />
                        <rect fill="#7f7f7f" x="2" y="30" rx="3" ry="3" width="50" height="40" />
                        <image width="25" height="16" stroke="none" x="17" y="35" xlink:href="/assets/icons/form1.svg">
                        </image>
                        <text x="12" y="36" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                            fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="10" dy="24">Fill Form</tspan>
                        </text>
                    </g>
                    <line x1="150" x2="200" y1="50" y2="50" stroke-width="1.5" stroke="#443c3d" stroke-dasharray="2,1"
                        marker-start="url(#arrow)" />
                    <g transform="translate(198)" class="cpr"
                        (click)="showConfirmModal('master-form/agent-verification-started',this.pax_id);">
                        <rect fill="#CCC" x="5" y="33" rx="3" ry="3" width="50" height="40" />
                        <rect fill="#007791" x="2" y="30" rx="3" ry="3" width="50" height="40" />
                        <image width="25" height="16" stroke="none" x="15" y="34"
                            xlink:href="/assets/icons/agent-verify.svg"></image>
                        <text x="12" y="40" font-family="Open Sans Condensed" font-size="4" stroke="none" fill="#f5f3e7"
                            font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="18" dy="16">Agent</tspan>
                            <tspan x="7" dy="6">Verification</tspan>
                        </text>
                    </g>
                    <line x1="250" x2="300" y1="50" y2="50" stroke-width="1.5" stroke="#443c3d" stroke-dasharray="2,1"
                        marker-start="url(#arrow)" />
                    <line x1="225" x2="225" y1="70" y2="103" stroke-width="1.5" stroke="#443c3d" stroke-dasharray="2,1"
                        marker-start="url(#arrow-bottom)">
                    </line>
                    <line x1="224" x2="200" y1="102" y2="102" stroke-width="1.5" stroke="#443c3d"
                        stroke-dasharray="2,1" />
                    <g transform="translate(149)">
                        <rect fill="#CCC" x="5" y="83" rx="3" ry="3" width="50" height="40" />
                        <rect fill="#007791" x="2" y="80" rx="3" ry="3" width="50" height="40" />
                        <image width="25" height="17" stroke="none" x="16" y="85"
                            xlink:href="/assets/icons/unblock.svg"></image>
                        <text x="16" y="102" font-family="Open Sans Condensed" font-size="4" stroke="none"
                            fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="14" dy="7">Unblock</tspan>
                            <tspan x="13" dy="6">Question</tspan>
                        </text>
                    </g>
                    <line x1="125" x2="125" y1="103" y2="70" stroke-width="1.5" stroke="#443c3d" stroke-dasharray="2,1"
                        marker-start="url(#arrow-up)">
                    </line>
                    <line x1="150" x2="126" y1="102" y2="102" stroke-width="1.5" stroke="#443c3d"
                        stroke-dasharray="2,1" />

                    <g transform="translate(50)">
                        <circle [attr.fill]="checkCurrentStatus('FILL FORM','start_status') ? '#66cc00' : '#000'"
                            cx="45" cy="50" [attr.r]="checkCurrentStatus('FILL FORM','start_status') ? 5.5 : 5"
                            opacity="1" />
                        <image width="10" height="6" stroke="none" x="40" y="47" xlink:href="/assets/icons/bell.svg">
                        </image>
                        <circle cx="45" cy="50" r="5" opacity="0" class="cpr"
                            (click)="showNotificationModal('FILL FORM', 'start_status')" />
                    </g>
                    <g transform="translate(110)">
                        <circle [attr.fill]="checkCurrentStatus('FILL FORM','success_status') ? '#66cc00' : '#000'"
                            cx="45" cy="50" [attr.r]="checkCurrentStatus('FILL FORM','success_status') ? 5.5 : 5"
                            opacity="1" />
                        <image width="10" height="6" stroke="none" x="40" y="47" xlink:href="/assets/icons/bell.svg">
                        </image>
                        <circle cx="45" cy="50" r="5" opacity="0" class="cpr"
                            (click)="showNotificationModal('FILL FORM', 'success_status')" />
                    </g>
                    <g transform="translate(150)">
                        <circle
                            [attr.fill]="checkCurrentStatus('AGENT VERIFICATION','start_status') ? '#66cc00' : '#000'"
                            cx="45" cy="50" [attr.r]="checkCurrentStatus('AGENT VERIFICATION','start_status') ? 5.5 : 5"
                            opacity="1" />
                        <image width="10" height="6" stroke="none" x="40" y="47" xlink:href="/assets/icons/bell.svg">
                        </image>
                        <circle cx="45" cy="50" r="5" opacity="0" class="cpr"
                            (click)="showNotificationModal('AGENT VERIFICATION', 'start_status')" />
                    </g>
                    <g transform="translate(210)">
                        <circle
                            [attr.fill]="checkCurrentStatus('AGENT VERIFICATION','success_status') ? '#66cc00' : '#000'"
                            cx="45" cy="50"
                            [attr.r]="checkCurrentStatus('AGENT VERIFICATION','success_status') ? 5.5 : 5"
                            opacity="1" />
                        <image width="10" height="6" stroke="none" x="40" y="47" xlink:href="/assets/icons/bell.svg">
                        </image>
                        <circle cx="45" cy="50" r="5" opacity="0" class="cpr"
                            (click)="showNotificationModal('AGENT VERIFICATION', 'success_status')" />
                    </g>
                    <g transform="translate(89)">
                        <circle [attr.fill]="checkCurrentStatus('UNBLOCK QUESTION','start_status') ? '#66cc00' : '#000'"
                            cx="57" cy="103" [attr.r]="checkCurrentStatus('UNBLOCK QUESTION','start_status') ? 5.5 : 5"
                            opacity="1" />
                        <image width="10" height="6" stroke="none" x="52" y="100" xlink:href="/assets/icons/bell.svg">
                        </image>
                        <circle cx="57" cy="103" r="5" opacity="0" class="cpr"
                            (click)="showNotificationModal('UNBLOCK QUESTION', 'start_status')" />
                    </g>
                    <g transform="translate(149)">
                        <circle
                            [attr.fill]="checkCurrentStatus('UNBLOCK QUESTION','success_status') ? '#66cc00' : '#000'"
                            cx="57" cy="103"
                            [attr.r]="checkCurrentStatus('UNBLOCK QUESTION','success_status') ? 5.5 : 5" opacity="1" />
                        <image width="10" height="6" stroke="none" x="52" y="100" xlink:href="/assets/icons/bell.svg">
                        </image>
                        <circle cx="57" cy="103" r="5" opacity="0" class="cpr"
                            (click)="showNotificationModal('UNBLOCK QUESTION', 'success_status')" />
                    </g>
                </g>
            </svg>
        </div>
        <!-- Consulate form filling -->
        <div class="wrapper-details" *ngIf="selectedStepType==3">
            <svg version="1.1" viewBox="-50 22 500 145" preserveAspectRatio="xMinYMin meet" class="svg-content">
                <defs>
                    <marker id="arrow-up" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-9" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                    <marker id="arrow-bottom" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-8" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                    <marker id="arrow" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-13" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                </defs>

                <g class="box-group">
                    <rect width="198" height="105" x="82" y="23" fill="rgb(255, 255, 255)"
                        vector-effect="non-scaling-stroke" stroke-width="1" stroke="rgb(255 99 71)"
                        stroke-dasharray="10,5" />
                    <line class="path" x1="50" x2="100" y1="50" y2="50" stroke-width="1.5" stroke="#443c3d"
                        stroke-dasharray="2,1" marker-start="url(#arrow)" />
                    <g transform="translate(98)" class="cpr"
                        (click)="openUploadModal('consulate-form/user-form-filling')">
                        <rect fill="#CCC" x="5" y="33" rx="3" ry="3" width="50" height="40" />
                        <rect fill="#bc3a3a" x="2" y="30" rx="3" ry="3" width="50" height="40" />
                        <image width="25" height="16" stroke="none" x="17" y="35" xlink:href="/assets/icons/form1.svg">
                        </image>
                        <text x="12" y="42" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                            fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="18" dy="16">Agent</tspan>
                            <tspan x="6" dy="6">Form Filling</tspan>
                        </text>
                    </g>
                    <line x1="150" x2="200" y1="50" y2="50" stroke-width="1.5" stroke="#443c3d" stroke-dasharray="2,1"
                        marker-start="url(#arrow)" />
                    <g transform="translate(198)" *ngIf="!flowDetails?.workflow_bypass">
                        <rect fill="#CCC" x="5" y="33" rx="3" ry="3" width="50" height="40" />
                        <rect fill="#7f7f7f" x="2" y="30" rx="3" ry="3" width="50" height="40" />
                        <image width="25" height="16" stroke="none" x="15" y="34"
                            xlink:href="/assets/icons/agent-verify.svg"></image>
                        <text x="12" y="40" font-family="Open Sans Condensed" font-size="4" stroke="none" fill="#f5f3e7"
                            font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="21" dy="16">User</tspan>
                            <tspan x="8" dy="6">Verification</tspan>
                        </text>
                    </g>
                    <g (click)="openUserVerificationModal()" transform="translate(198)" class="cpr" *ngIf="flowDetails?.workflow_bypass">
                        <rect fill="#CCC" x="5" y="33" rx="3" ry="3" width="50" height="40" />
                        <rect fill="#bc3a3a" x="2" y="30" rx="3" ry="3" width="50" height="40" />
                        <image width="25" height="16" stroke="none" x="15" y="34"
                            xlink:href="/assets/icons/agent-verify.svg"></image>
                        <text x="12" y="40" font-family="Open Sans Condensed" font-size="4" stroke="none" fill="#f5f3e7"
                            font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="21" dy="16">User</tspan>
                            <tspan x="8" dy="6">Verification</tspan>
                        </text>
                    </g>
                    <line x1="250" x2="300" y1="50" y2="50" stroke-width="1.5" stroke="#443c3d" stroke-dasharray="2,1"
                        marker-start="url(#arrow)" />
                    <line x1="225" x2="225" y1="70" y2="103" stroke-width="1.5" stroke="#443c3d" stroke-dasharray="2,1"
                        marker-start="url(#arrow-bottom)">
                    </line>
                    <line x1="224" x2="200" y1="102" y2="102" stroke-width="1.5" stroke="#443c3d"
                        stroke-dasharray="2,1" />
                    <g transform="translate(149)" class="cpr" (click)="openQuestionModal(false)">
                        <rect fill="#CCC" x="5" y="83" rx="3" ry="3" width="50" height="40" />
                        <rect fill="#bc3a3a" x="2" y="80" rx="3" ry="3" width="50" height="40" />
                        <image width="25" height="17" stroke="none" x="16" y="85"
                            xlink:href="/assets/icons/unblock.svg"></image>
                        <text x="16" y="102" font-family="Open Sans Condensed" font-size="4" stroke="none"
                            fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="14" dy="7">Unblock</tspan>
                            <tspan x="13" dy="6">Question</tspan>
                        </text>
                    </g>
                    <line x1="125" x2="125" y1="103" y2="70" stroke-width="1.5" stroke="#443c3d" stroke-dasharray="2,1"
                        marker-start="url(#arrow-up)">
                    </line>
                    <line x1="150" x2="126" y1="102" y2="102" stroke-width="1.5" stroke="#443c3d"
                        stroke-dasharray="2,1" />

                    <g transform="translate(50)">
                        <circle
                            [attr.fill]="checkCurrentStatus('AGENT FORM FILLING','start_status') ? '#66cc00' : '#000'"
                            cx="45" cy="50" [attr.r]="checkCurrentStatus('AGENT FORM FILLING','start_status') ? 5.5 : 5"
                            opacity="1" />
                        <image width="10" height="6" stroke="none" x="40" y="47" xlink:href="/assets/icons/bell.svg">
                        </image>
                        <circle cx="45" cy="50" r="5" opacity="0" class="cpr"
                            (click)="showNotificationModal('AGENT FORM FILLING','start_status')" />
                    </g>
                    <g transform="translate(110)">
                        <circle
                            [attr.fill]="checkCurrentStatus('AGENT FORM FILLING','success_status') ? '#66cc00' : '#000'"
                            cx="45" cy="50"
                            [attr.r]="checkCurrentStatus('AGENT FORM FILLING','success_status') ? 5.5 : 5"
                            opacity="1" />
                        <image width="10" height="6" stroke="none" x="40" y="47" xlink:href="/assets/icons/bell.svg">
                        </image>
                        <circle cx="45" cy="50" r="5" opacity="0" class="cpr"
                            (click)="showNotificationModal('AGENT FORM FILLING','success_status')" />
                    </g>
                    <g transform="translate(150)">
                        <circle
                            [attr.fill]="checkCurrentStatus('USER VERIFICATION','start_status') ? '#66cc00' : '#000'"
                            cx="45" cy="50" [attr.r]="checkCurrentStatus('USER VERIFICATION','start_status') ? 5.5 : 5"
                            opacity="1" />
                        <image width="10" height="6" stroke="none" x="40" y="47" xlink:href="/assets/icons/bell.svg">
                        </image>
                        <circle cx="45" cy="50" r="5" opacity="0" class="cpr"
                            (click)="showNotificationModal('USER VERIFICATION','start_status')" />
                    </g>
                    <g transform="translate(210)">
                        <circle
                            [attr.fill]="checkCurrentStatus('USER VERIFICATION','success_status') ? '#66cc00' : '#000'"
                            cx="45" cy="50"
                            [attr.r]="checkCurrentStatus('USER VERIFICATION','success_status') ? 5.5 : 5" opacity="1" />
                        <image width="10" height="6" stroke="none" x="40" y="47" xlink:href="/assets/icons/bell.svg">
                        </image>
                        <circle cx="45" cy="50" r="5" opacity="0" class="cpr"
                            (click)="showNotificationModal('USER VERIFICATION','success_status')" />
                    </g>
                    <g transform="translate(89)">
                        <circle [attr.fill]="checkCurrentStatus('UNBLOCK QUESTION','start_status') ? '#66cc00' : '#000'"
                            cx="57" cy="103" [attr.r]="checkCurrentStatus('UNBLOCK QUESTION','start_status') ? 5.5 : 5"
                            opacity="1" />
                        <image width="10" height="6" stroke="none" x="52" y="100" xlink:href="/assets/icons/bell.svg">
                        </image>
                        <circle cx="57" cy="103" r="5" opacity="0" class="cpr"
                            (click)="showNotificationModal('UNBLOCK QUESTION','start_status')" />
                    </g>
                    <g transform="translate(149)">
                        <circle
                            [attr.fill]="checkCurrentStatus('USER VERIFICATION','failure_status') ? '#66cc00' : '#000'"
                            cx="57" cy="103"
                            [attr.r]="checkCurrentStatus('USER VERIFICATION','failure_status') ? 5.5 : 5" opacity="1" />
                        <image width="10" height="6" stroke="none" x="52" y="100" xlink:href="/assets/icons/bell.svg">
                        </image>
                        <circle cx="57" cy="103" r="5" opacity="0" class="cpr"
                            (click)="showNotificationModal('USER VERIFICATION','failure_status')" />
                    </g>
                </g>
            </svg>
        </div>
        <!-- Upload -->
        <div class="wrapper-details" *ngIf="selectedStepType==4">
            <svg version="1.1" viewBox="-50 22 500 145" preserveAspectRatio="xMinYMin meet" class="svg-content">
                <defs>
                    <marker id="arrow-start" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-13" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                    <marker id="arrow-end" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-33" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                </defs>
                <g class="box-group">
                    <rect width="198" height="105" x="82" y="23" fill="rgb(255, 255, 255)"
                        vector-effect="non-scaling-stroke" stroke-width="1" stroke="rgb(255 99 71)"
                        stroke-dasharray="10,5" />

                    <g [attr.transform]="currentTab=='USER' ? 'translate(245)' : 'translate(234)'">
                        <rect fill="#66cc00" x="10" y="23" [attr.width]="currentTab=='USER' ? 32 : 36" height="11"
                            height="11" />
                        <text x="10" y="16" font-family="Open Sans Condensed" font-size="5" stroke="none" fill="#f5f3e7"
                            font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="16" dy="14">{{currentTab}}</tspan>
                        </text>
                        <rect opacity="0" x="10" y="23" width="36" height="10" class="cpr" />
                    </g>


                    <g *ngIf="checkTypeTab('User')">
                        <line class="path" x1="50" x2="100" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-start)" />
                        <g *ngIf="!flowDetails?.workflow_bypass"transform="translate(103)">
                            <rect fill="#CCC" x="5" y="50" rx="3" ry="3" width="68" height="55" />
                            <rect fill="#7f7f7f" x="2" y="47" rx="3" ry="3" width="68" height="55" />
                            <image width="25" height="20" stroke="none" x="24" y="52"
                                xlink:href="/assets/icons/upload.svg"></image>
                            <text x="12" y="63" font-family="Open Sans Condensed" font-size="5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="14" dy="16">Upload</tspan>
                            </text>
                            <g>
                                <rect width="63" height="6"
                                    [attr.fill]="checkTypeDocType('application_letter') ? '#66cc00' : '#fff'"
                                    stroke="grey" stroke-width="0.5" x="5" rx="1" ry="1" y="75"></rect>
                                <text x="12" y="58" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                    [attr.fill]="checkTypeDocType('application_letter') ? '#fff' : '#000'"
                                    font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                    <tspan x="5.5" dy="21.5">Application Letter</tspan>
                                </text>
                                <rect width="63" height="6" opacity="0" x="5" rx="1" ry="1" y="75" class="cpr"
                                    (click)="setTypeDocType('application_letter', 'User')"></rect>
                            </g>
                            <g>
                                <rect width="63" height="6"
                                    [attr.fill]="checkTypeDocType('covering_letter') ? '#66cc00' : '#fff'" stroke="grey"
                                    stroke-width="0.5" x="5" rx="1" ry="1" y="82"></rect>
                                <text x="12" y="65" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                    [attr.fill]="checkTypeDocType('covering_letter') ? '#fff' : '#000'"
                                    font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                    <tspan x="10" dy="21.5">Covering Letter</tspan>
                                </text>
                                <rect width="63" height="6" opacity="0" x="5" rx="1" ry="1" y="82" class="cpr"
                                    (click)="setTypeDocType('covering_letter', 'User')"></rect>
                            </g>
                            <g>
                                <rect width="63" height="6" [attr.fill]="checkTypeDocType('other') ? '#66cc00' : '#fff'"
                                    stroke="grey" stroke-width="0.5" x="5" rx="1" ry="1" y="89"></rect>
                                <text x="12" y="72" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                    [attr.fill]="checkTypeDocType('other') ? '#fff' : '#000'" font-weight="900"
                                    style="text-transform:uppercase; letter-spacing: 1px">
                                    <tspan x="28" dy="21.5">Other</tspan>
                                </text>
                                <rect width="63" height="6" opacity="0" x="5" rx="1" ry="1" y="89" class="cpr"
                                    (click)="setTypeDocType('other', 'User')"></rect>
                            </g>
                            <line x1="134" x2="134" y1="76" y2="112" stroke-width="1.5" stroke="#443c3d"
                                stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                            </line>

                            <line x1="35" x2="35" y1="117" y2="105" stroke-width="1.5" stroke="#443c3d"
                                stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                            </line>

                            <line x1="135" x2="35" y1="117" y2="117" stroke-width="1.5" stroke="#443c3d"
                                stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        </g>
                        <g transform="translate(103)" (click)="openUploadModal('documents-upload')" class="cpr" *ngIf="flowDetails?.workflow_bypass">
                            <rect fill="#CCC" x="5" y="50" rx="3" ry="3" width="68" height="55" />
                            <rect fill="#ce5f38" x="2" y="47" rx="3" ry="3" width="68" height="55" />
                            <image width="25" height="20" stroke="none" x="24" y="52"
                                xlink:href="/assets/icons/upload.svg"></image>
                            <text x="12" y="63" font-family="Open Sans Condensed" font-size="5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="14" dy="16">Upload</tspan>
                            </text>
                            <g>
                                <rect width="63" height="6"
                                    [attr.fill]="checkTypeDocType('application_letter') ? '#66cc00' : '#fff'"
                                    stroke="grey" stroke-width="0.5" x="5" rx="1" ry="1" y="75"></rect>
                                <text x="12" y="58" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                    [attr.fill]="checkTypeDocType('application_letter') ? '#fff' : '#000'"
                                    font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                    <tspan x="5.5" dy="21.5">Application Letter</tspan>
                                </text>
                                <rect width="63" height="6" opacity="0" x="5" rx="1" ry="1" y="75" class="cpr"
                                    (click)="setTypeDocType('application_letter', 'User')"></rect>
                            </g>
                            <g>
                                <rect width="63" height="6"
                                    [attr.fill]="checkTypeDocType('covering_letter') ? '#66cc00' : '#fff'" stroke="grey"
                                    stroke-width="0.5" x="5" rx="1" ry="1" y="82"></rect>
                                <text x="12" y="65" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                    [attr.fill]="checkTypeDocType('covering_letter') ? '#fff' : '#000'"
                                    font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                    <tspan x="10" dy="21.5">Covering Letter</tspan>
                                </text>
                                <rect width="63" height="6" opacity="0" x="5" rx="1" ry="1" y="82" class="cpr"
                                    (click)="setTypeDocType('covering_letter', 'User')"></rect>
                            </g>
                            <g>
                                <rect width="63" height="6" [attr.fill]="checkTypeDocType('other') ? '#66cc00' : '#fff'"
                                    stroke="grey" stroke-width="0.5" x="5" rx="1" ry="1" y="89"></rect>
                                <text x="12" y="72" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                    [attr.fill]="checkTypeDocType('other') ? '#fff' : '#000'" font-weight="900"
                                    style="text-transform:uppercase; letter-spacing: 1px">
                                    <tspan x="28" dy="21.5">Other</tspan>
                                </text>
                                <rect width="63" height="6" opacity="0" x="5" rx="1" ry="1" y="89" class="cpr"
                                    (click)="setTypeDocType('other', 'User')"></rect>
                            </g>
                            <line x1="134" x2="134" y1="76" y2="112" stroke-width="1.5" stroke="#443c3d"
                                stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                            </line>

                            <line x1="35" x2="35" y1="117" y2="105" stroke-width="1.5" stroke="#443c3d"
                                stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                            </line>

                            <line x1="135" x2="35" y1="117" y2="117" stroke-width="1.5" stroke="#443c3d"
                                stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        </g>
                        <line x1="184" x2="210" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" />
                        <g transform="translate(208)" class="cpr" (click)="openVerifyModal('documents-upload')">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#e04a16" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="16" stroke="none" x="15" y="62"
                                xlink:href="/assets/icons/agent-verify.svg">
                            </image>
                            <text x="12" y="68" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="7" dy="16">Verification</tspan>
                            </text>
                        </g>
                        <line x1="260" x2="310" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        <g transform="translate(55)">
                            <circle
                                [attr.fill]="checkCurrentStatus('APPLICATION LETTER/COVERING LETTER/OTHER','start_status','User') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('APPLICATION LETTER/COVERING LETTER/OTHER','start_status','User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('APPLICATION LETTER/COVERING LETTER/OTHER','start_status')" />
                        </g>
                        <g transform="translate(133)">
                            <circle
                                [attr.fill]="checkCurrentStatus('APPLICATION LETTER/COVERING LETTER/OTHER','success_status','User') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('APPLICATION LETTER/COVERING LETTER/OTHER','success_status','User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('APPLICATION LETTER/COVERING LETTER/OTHER','success_status')" />
                        </g>
                        <g transform="translate(160)">
                            <circle
                                [attr.fill]="checkCurrentStatus('VERIFICATION','start_status','User') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('VERIFICATION','start_status','User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('VERIFICATION','start_status')" />
                        </g>
                        <g transform="translate(220)">
                            <circle
                                [attr.fill]="checkCurrentStatus('VERIFICATION','success_status','User') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('VERIFICATION','success_status','User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('VERIFICATION','success_status')" />
                        </g>
                        <g transform="translate(140)">
                            <circle
                                [attr.fill]="checkCurrentStatus('VERIFICATION','failure_status','User') ? '#66cc00' : '#000'"
                                cx="45" cy="117"
                                [attr.r]="checkCurrentStatus('VERIFICATION','failure_status','User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="114"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="117" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('VERIFICATION','failure_status')" />
                        </g>
                    </g>

                    <g *ngIf="checkTypeTab('Agent')">
                        <line class="path" x1="50" x2="153" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-start)" />
                        <g transform="translate(152)" class="cpr" (click)="openUploadModal('documents-upload-agent')">
                            <rect fill="#CCC" x="5" y="50" rx="3" ry="3" width="68" height="55" />
                            <rect fill="#ce5f38" x="2" y="47" rx="3" ry="3" width="68" height="55" />
                            <image width="25" height="20" stroke="none" x="24" y="52"
                                xlink:href="/assets/icons/upload.svg"></image>
                            <text x="12" y="63" font-family="Open Sans Condensed" font-size="5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="14" dy="16">Upload</tspan>
                            </text>
                            <g>
                                <rect width="63" height="6"
                                    [attr.fill]="checkTypeDocType('application_letter') ? '#66cc00' : '#fff'"
                                    stroke="grey" stroke-width="0.5" x="5" rx="1" ry="1" y="75"></rect>
                                <text x="12" y="58" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                    [attr.fill]="checkTypeDocType('application_letter') ? '#fff' : '#000'"
                                    font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                    <tspan x="5.5" dy="21.5">Application Letter</tspan>
                                </text>
                                <rect width="63" height="6" opacity="0" x="5" rx="1" ry="1" y="75" class="cpr"
                                    (click)="setTypeDocType('application_letter','Agent')"></rect>
                            </g>
                            <g>
                                <rect width="63" height="6"
                                    [attr.fill]="checkTypeDocType('covering_letter') ? '#66cc00' : '#fff'" stroke="grey"
                                    stroke-width="0.5" x="5" rx="1" ry="1" y="82"></rect>
                                <text x="12" y="65" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                    [attr.fill]="checkTypeDocType('covering_letter') ? '#fff' : '#000'"
                                    font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                    <tspan x="10" dy="21.5">Covering Letter</tspan>
                                </text>
                                <rect width="63" height="6" opacity="0" x="5" rx="1" ry="1" y="82" class="cpr"
                                    (click)="setTypeDocType('covering_letter','Agent')"></rect>
                            </g>
                            <g>
                                <rect width="63" height="6" [attr.fill]="checkTypeDocType('other') ? '#66cc00' : '#fff'"
                                    stroke="grey" stroke-width="0.5" x="5" rx="1" ry="1" y="89"></rect>
                                <text x="12" y="72" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                    [attr.fill]="checkTypeDocType('other') ? '#fff' : '#000'" font-weight="900"
                                    style="text-transform:uppercase; letter-spacing: 1px">
                                    <tspan x="28" dy="21.5">Other</tspan>
                                </text>
                                <rect width="63" height="6" opacity="0" x="5" rx="1" ry="1" y="89" class="cpr"
                                    (click)="setTypeDocType('other','Agent')"></rect>
                            </g>
                        </g>
                        <line x1="223" x2="310" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        <g transform="translate(104)">
                            <circle
                                [attr.fill]="checkCurrentStatus('APPLICATION LETTER/COVERING LETTER/OTHER','start_status','Agent') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('APPLICATION LETTER/COVERING LETTER/OTHER','start_status','Agent') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('APPLICATION LETTER/COVERING LETTER/OTHER','start_status')" />
                        </g>
                        <g transform="translate(182.5)">
                            <circle
                                [attr.fill]="checkCurrentStatus('APPLICATION LETTER/COVERING LETTER/OTHER','success_status','Agent') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('APPLICATION LETTER/COVERING LETTER/OTHER','success_status','Agent') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('APPLICATION LETTER/COVERING LETTER/OTHER','success_status')" />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <!-- Update -->
        <div class="wrapper-details" *ngIf="selectedStepType==5">
            <svg version="1.1" viewBox="-50 22 500 145" preserveAspectRatio="xMinYMin meet" class="svg-content">
                <defs>
                    <marker id="arrow-start" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-13" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                    <marker id="arrow-end" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-43" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                </defs>
                <g class="box-group">
                    <rect width="198" height="105" x="82" y="23" fill="rgb(255, 255, 255)"
                        vector-effect="non-scaling-stroke" stroke-width="1" stroke="rgb(255 99 71)"
                        stroke-dasharray="10,5" />
                    <g [attr.transform]="currentTab=='User' ? 'translate(238)' : 'translate(234)'">
                        <rect fill="#66cc00" x="10" y="23" [attr.width]="currentTab=='User' ? 32 : 36" height="11" />
                        <text x="10" y="16" font-family="Open Sans Condensed" font-size="5" stroke="none" fill="#f5f3e7"
                            font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="17" dy="14">{{currentTab}}</tspan>
                        </text>
                        <rect opacity="0" x="10" y="23" width="32" height="10" class="cpr" />
                    </g>

                    <!-- <g transform="translate(234)">
                        <rect fill="#66cc00" x="10" y="23" width="36"
                            height="11" />
                        <text x="10" y="16" font-family="Open Sans Condensed" font-size="5" stroke="none"
                            fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="16" dy="14">AGENT</tspan>
                        </text>
                        <rect opacity="0" x="10" y="23" width="36" height="10" class="cpr"/>
                    </g> -->

                    <g *ngIf="checkTypeTab('User')" >
                        <line class="path" x1="50" x2="100" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-start)" />
                        <g *ngIf="!flowDetails?.workflow_bypass"transform="translate(103)">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#f0b24d" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="20" stroke="none" x="15" y="59"
                                xlink:href="/assets/icons/update.svg"></image>
                            <text x="12" y="70" font-family="Open Sans Condensed" font-size="5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="14" dy="16">Update</tspan>
                            </text>
                            <line x1="134" x2="134" y1="76" y2="112" stroke-width="1.5" stroke="#443c3d"
                                stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                            </line>

                            <line x1="28" x2="28" y1="118" y2="98" stroke-width="1.5" stroke="#443c3d"
                                stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                            </line>

                            <line x1="134" x2="30" y1="117" y2="117" stroke-width="1.5" stroke="#443c3d"
                                stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        </g>
                        <g (click)="openDataModal('data-update/user-update','User')" *ngIf="flowDetails?.workflow_bypass"transform="translate(103)" class="cpr">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#ce5f38" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="20" stroke="none" x="15" y="59"
                                xlink:href="/assets/icons/update.svg"></image>
                            <text x="12" y="70" font-family="Open Sans Condensed" font-size="5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="14" dy="16">Update</tspan>
                            </text>
                            <line x1="134" x2="134" y1="76" y2="112" stroke-width="1.5" stroke="#443c3d"
                                stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                            </line>

                            <line x1="28" x2="28" y1="118" y2="98" stroke-width="1.5" stroke="#443c3d"
                                stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                            </line>

                            <line x1="134" x2="30" y1="117" y2="117" stroke-width="1.5" stroke="#443c3d"
                                stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        </g>
                        <line x1="155" x2="210" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" />
                        <g transform="translate(208)" class="cpr"
                            (click)="openVerifyModal('data-update/agent-updated-data',this.pax_id);">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#f89d0a" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="16" stroke="none" x="15" y="62"
                                xlink:href="/assets/icons/agent-verify.svg">
                            </image>
                            <text x="12" y="68" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="7" dy="16">Verification</tspan>
                            </text>
                        </g>
                        <line x1="260" x2="310" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        <g transform="translate(55)">
                            <circle
                                [attr.fill]="checkCurrentStatus('UPDATE','start_status','User') ? '#66cc00' : '#000'"
                                cx="45" cy="75" [attr.r]="checkCurrentStatus('UPDATE','start_status','User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" />
                        </g>
                        <g transform="translate(115)">
                            <circle
                                [attr.fill]="checkCurrentStatus('UPDATE','success_status','User') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('UPDATE','success_status','User') ? 5.5 : 5" opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('UPDATE','success_status')" />
                        </g>
                        <g transform="translate(160)">
                            <circle
                                [attr.fill]="checkCurrentStatus('VERIFICATION','start_status','User') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('VERIFICATION','start_status','User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('VERIFICATION','start_status')" />
                        </g>
                        <g transform="translate(220)">
                            <circle
                                [attr.fill]="checkCurrentStatus('VERIFICATION','success_status','User') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('VERIFICATION','success_status','User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('VERIFICATION','success_status')" />
                        </g>
                        <g transform="translate(140)">
                            <circle
                                [attr.fill]="checkCurrentStatus('VERIFICATION','failure_status','User') ? '#66cc00' : '#000'"
                                cx="45" cy="117"
                                [attr.r]="checkCurrentStatus('VERIFICATION','failure_status','User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="114"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="117" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('VERIFICATION','failure_status')" />
                        </g>
                    </g>

                    <g *ngIf="checkTypeTab('Agent')">
                        <line class="path" x1="50" x2="153" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-start)" />
                        <g transform="translate(152)" class="cpr" (click)="openDataModal('data-update/agent-update','Agent')">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#e8a435" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="20" stroke="none" x="15" y="59"
                                xlink:href="/assets/icons/update.svg"></image>
                            <text x="12" y="70" font-family="Open Sans Condensed" font-size="5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="14" dy="16">Update</tspan>
                            </text>
                        </g>
                        <line x1="205" x2="310" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        <g transform="translate(104)">
                            <circle
                                [attr.fill]="checkCurrentStatus('UPDATE','start_status', 'Agent') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('UPDATE','start_status', 'Agent') ? 5.5 : 5" opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('UPDATE','start_status')" />
                        </g>
                        <g transform="translate(163.5)">
                            <circle
                                [attr.fill]="checkCurrentStatus('UPDATE','success_status', 'Agent') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('UPDATE','success_status', 'Agent') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('UPDATE','success_status')" />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <!--Schedule -->
        <div class="wrapper-details" *ngIf="selectedStepType==6">
            <svg version="1.1" viewBox="-50 22 500 145" preserveAspectRatio="xMinYMin meet" class="svg-content">
                <defs>
                    <marker id="arrow-start" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-13" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                    <marker id="arrow-end" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-43" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                    <marker id="arrow-end2" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-30" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                </defs>

                <g class="box-group">
                    <rect width="198" height="105" x="82" y="23" fill="rgb(255, 255, 255)"
                        vector-effect="non-scaling-stroke" stroke-width="1" stroke="rgb(255 99 71)"
                        stroke-dasharray="10,5" />

                    <g [attr.transform]="currentTab=='User' ? 'translate(238)' : 'translate(234)'">
                        <rect fill="#66cc00" x="10" y="23" [attr.width]="currentTab=='User' ? 32 : 36" height="11" />
                        <text x="10" y="16" font-family="Open Sans Condensed" font-size="5" stroke="none" fill="#f5f3e7"
                            font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="17" dy="14">{{currentTab}}</tspan>
                        </text>
                        <rect opacity="0" x="10" y="23" width="32" height="10" class="cpr" />
                    </g>

                    <g *ngIf="checkTypeTab('User')">
                        <line class="path" x1="50" x2="112" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-start)" />
                        <g transform="translate(110)" class="cpr" (click)="openScheduleModal('schedule-dates', 'user')">
                            <rect fill="#CCC" x="5" y="38" rx="3" ry="3" width="50" height="72" />
                            <rect fill="#7651d6" x="2" y="35" rx="3" ry="3" width="50" height="72" />
                            <image width="25" height="16" stroke="none" x="15" y="43"
                                xlink:href="/assets/icons/schedule.svg">
                            </image>
                            <text x="12" y="49" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="12" dy="20">Schedule</tspan>
                            </text>
                            <g>
                                <rect width="44" height="6"
                                    [attr.fill]="checkTypeDocType('courier') ? '#66cc00' : '#fff'" stroke="grey"
                                    stroke-width="0.5" x="5" rx="1" ry="1" y="75"></rect>
                                <text x="12" y="58" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                    [attr.fill]="checkTypeDocType('courier') ? '#fff' : '#000'" font-weight="900"
                                    style="text-transform:uppercase; letter-spacing: 1px">
                                    <tspan x="15" dy="21.5">Courier</tspan>
                                </text>
                                <rect width="44" height="6" opacity="0" x="5" rx="1" ry="1" y="75" class="cpr"
                                ></rect>
                                <!-- (click)="setTypeDocType('courier','User')" -->
                            </g>
                            <g>
                                <rect width="44" height="6"
                                    [attr.fill]="checkTypeDocType('interview') ? '#66cc00' : '#fff'" stroke="grey"
                                    stroke-width="0.5" x="5" rx="1" ry="1" y="82"></rect>
                                <text x="12" y="65" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                    [attr.fill]="checkTypeDocType('interview') ? '#fff' : '#000'" font-weight="900"
                                    style="text-transform:uppercase; letter-spacing: 1px">
                                    <tspan x="12" dy="21.5">Interview</tspan>
                                </text>
                                <rect width="44" height="6" opacity="0" x="5" rx="1" ry="1" y="82" class="cpr"
                                    ></rect>
                                    <!-- (click)="setTypeDocType('interview','User')" -->
                            </g>
                            <g>
                                <rect width="44" height="6"
                                    [attr.fill]="checkTypeDocType('biometric') ? '#66cc00' : '#fff'" stroke="grey"
                                    stroke-width="0.5" x="5" rx="1" ry="1" y="89"></rect>
                                <text x="12" y="72" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                    [attr.fill]="checkTypeDocType('biometric') ? '#fff' : '#000'" font-weight="900"
                                    style="text-transform:uppercase; letter-spacing: 1px">
                                    <tspan x="12" dy="21.5">Biometric</tspan>
                                </text>
                                <rect width="44" height="6" opacity="0" x="5" rx="1" ry="1" y="89" class="cpr"
                                    ></rect>
                                    <!-- (click)="setTypeDocType('biometric','User')" -->
                            </g>
                            <g>
                                <rect width="44" height="6"
                                    [attr.fill]="checkTypeDocType('submission') ? '#66cc00' : '#fff'" stroke="grey"
                                    stroke-width="0.5" x="5" rx="1" ry="1" y="96"></rect>
                                <text x="12" y="79" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                    [attr.fill]="checkTypeDocType('submission','User') ? '#fff' : '#000'"
                                    font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                    <tspan x="11" dy="21.5">Submission</tspan>
                                </text>
                                <rect width="44" height="6" opacity="0" x="5" rx="1" ry="1" y="96" class="cpr"
                                    ></rect>
                                    <!-- (click)="setTypeDocType('submission')" -->
                            </g>
                        </g>
                        <line x1="164" x2="200" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        <g transform="translate(195)" class="cpr" (click)="openScheduleModal('schedule-dates', 'user-update')">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#7651d6" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="16" stroke="none" x="15" y="59"
                                xlink:href="/assets/icons/schedule.svg">
                            </image>
                            <text x="12" y="65" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="10" dy="21">Update Date</tspan>
                            </text>
                        </g>
                        <line x1="250" x2="310" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end2)" />
                        <g transform="translate(62)">
                            <circle
                                [attr.fill]="checkCurrentStatus('SCHEDULE','start_status', 'User') ? '#66cc00' : '#000'"
                                cx="45" cy="75" fill="#000" r="5"
                                [attr.r]="checkCurrentStatus('SCHEDULE','start_status', 'User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('SCHEDULE','start_status')" />
                        </g>
                        <g transform="translate(122)">
                            <circle
                                [attr.fill]="checkCurrentStatus('SCHEDULE','success_status', 'User') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('SCHEDULE','success_status', 'User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('SCHEDULE','success_status')" />
                        </g>
                        <g transform="translate(147)">
                            <circle
                                [attr.fill]="checkCurrentStatus('VERIFICATION','start_status', 'User') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('VERIFICATION','start_status', 'User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('VERIFICATION','start_status')" />
                        </g>
                        <g transform="translate(209)">
                            <circle
                                [attr.fill]="checkCurrentStatus('VERIFICATION','success_status', 'User') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('VERIFICATION','success_status', 'User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('VERIFICATION','success_status')" />
                        </g>

                        <line x1="225" x2="225" y1="95" y2="118" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                        </line>

                        <line x1="94" x2="95" y1="118" y2="76" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                        </line>

                        <line x1="225" x2="96" y1="117" y2="117" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />

                        <g transform="translate(134)">
                            <circle
                                [attr.fill]="checkCurrentStatus('VERIFICATION','failure_status', 'User') ? '#66cc00' : '#000'"
                                cx="45" cy="116"
                                [attr.r]="checkCurrentStatus('VERIFICATION','failure_status', 'User') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="113"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="116" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('VERIFICATION','failure_status')" />
                        </g>
                    </g>

                    <g *ngIf="checkTypeTab('Agent')">
                        <line class="path" x1="50" x2="153" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-start)" />
                        <g transform="translate(152)" class="cpr"
                            (click)="openScheduleModal('schedule-dates', 'agent')">
                            <rect fill="#CCC" x="5" y="53" rx="3" ry="3" width="68" height="55" />
                            <rect fill="#7651d6" x="2" y="50" rx="3" ry="3" width="68" height="55" />
                            <image width="25" height="20" stroke="none" x="24" y="52"
                                xlink:href="/assets/icons/schedule.svg"></image>
                            <text x="12" y="63" font-family="Open Sans Condensed" font-size="5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <!-- <tspan x="14" dy="16">Schedule</tspan> -->
                            </text>
                            <g transform="translate(10)">
                                <g>
                                    <rect width="44" height="6"
                                        [attr.fill]="checkTypeDocType('courier') ? '#66cc00' : '#fff'" stroke="grey"
                                        stroke-width="0.5" x="5" rx="1" ry="1" y="75"></rect>
                                    <text x="12" y="58" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                        [attr.fill]="checkTypeDocType('courier') ? '#fff' : '#000'" font-weight="900"
                                        style="text-transform:uppercase; letter-spacing: 1px">
                                        <tspan x="15" dy="21.5">Courier</tspan>
                                    </text>
                                    <rect width="44" height="6" opacity="0" x="5" rx="1" ry="1" y="75" class="cpr"
                                        (click)="setTypeDocType('courier','Agent')"></rect>
                                </g>
                                <g>
                                    <rect width="44" height="6"
                                        [attr.fill]="checkTypeDocType('interview') ? '#66cc00' : '#fff'" stroke="grey"
                                        stroke-width="0.5" x="5" rx="1" ry="1" y="82"></rect>
                                    <text x="12" y="65" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                        [attr.fill]="checkTypeDocType('interview') ? '#fff' : '#000'" font-weight="900"
                                        style="text-transform:uppercase; letter-spacing: 1px">
                                        <tspan x="12" dy="21.5">Interview</tspan>
                                    </text>
                                    <rect width="44" height="6" opacity="0" x="5" rx="1" ry="1" y="82" class="cpr"
                                        (click)="setTypeDocType('interview','Agent')"></rect>
                                </g>
                                <g>
                                    <rect width="44" height="6"
                                        [attr.fill]="checkTypeDocType('biometric') ? '#66cc00' : '#fff'" stroke="grey"
                                        stroke-width="0.5" x="5" rx="1" ry="1" y="89"></rect>
                                    <text x="12" y="72" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                        [attr.fill]="checkTypeDocType('biometric') ? '#fff' : '#000'" font-weight="900"
                                        style="text-transform:uppercase; letter-spacing: 1px">
                                        <tspan x="12" dy="21.5">Biometric</tspan>
                                    </text>
                                    <rect width="44" height="6" opacity="0" x="5" rx="1" ry="1" y="89" class="cpr"
                                        (click)="setTypeDocType('biometric','Agent')"></rect>
                                </g>
                                <g>
                                    <rect width="44" height="6"
                                        [attr.fill]="checkTypeDocType('submission') ? '#66cc00' : '#fff'" stroke="grey"
                                        stroke-width="0.5" x="5" rx="1" ry="1" y="96"></rect>
                                    <text x="12" y="79" font-family="Open Sans Condensed" font-size="4" stroke="none"
                                        [attr.fill]="checkTypeDocType('submission') ? '#fff' : '#000'" font-weight="900"
                                        style="text-transform:uppercase; letter-spacing: 1px">
                                        <tspan x="11" dy="21.5">Submission</tspan>
                                    </text>
                                    <rect width="44" height="6" opacity="0" x="5" rx="1" ry="1" y="96" class="cpr"
                                        (click)="setTypeDocType('submission','Agent')"></rect>
                                </g>
                            </g>
                        </g>
                        <line x1="223" x2="310" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        <g transform="translate(104)">
                            <circle
                                [attr.fill]="checkCurrentStatus('SCHEDULE','start_status','Agent') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('SCHEDULE','start_status','Agent') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('SCHEDULE','start_status')" />
                        </g>
                        <g transform="translate(182.5)">
                            <circle
                                [attr.fill]="checkCurrentStatus('SCHEDULE','success_status','Agent') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('SCHEDULE','success_status','Agent') ? 5.5 : 5" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('SCHEDULE','success_status')" />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <!-- Document Submission -->
        <div class="wrapper-details" *ngIf="selectedStepType==7">
            <!-- Agent Assign -->
            <svg version="1.1" viewBox="-50 22 500 145" preserveAspectRatio="xMinYMin meet" class="svg-content">
                <defs>
                    <marker id="arrow-start" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-13" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                    <marker id="arrow-end" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-43" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                    <marker id="arrow-end2" markerWidth="4" markerHeight="10" viewBox="-2 -4 4 4" refX="-30" refY="0"
                        markerUnits="strokeWidth" orient="auto">
                        <polyline points="-2,2 0,0 -2,-2" stroke="#443c3d" stroke-width="0.75px" fill="none" />
                    </marker>
                </defs>

                <g class="box-group">
                    <rect width="198" height="105" x="82" y="23" fill="rgb(255, 255, 255)"
                        vector-effect="non-scaling-stroke" stroke-width="1" stroke="rgb(255 99 71)"
                        stroke-dasharray="10,5" />

                    <g transform="translate(225)" *ngIf="currentTab=='AGENT_PASSPORT'">
                        <rect fill="#66cc00" x="10" y="23" width="45" height="11" />
                        <text x="10" y="16" font-family="Open Sans Condensed" font-size="4" stroke="none" fill="#f5f3e7"
                            font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="23" dy="12">AGENT</tspan>
                            <tspan x="18" dy="4">Passport</tspan>
                        </text>
                        <rect opacity="0" x="10" y="23" width="55" height="10" class="cpr" />
                    </g>

                    <g transform="translate(235)" *ngIf="currentTab=='AGENT_OTHER'">
                        <rect fill="#66cc00" x="10" y="23" width="35" height="11" />
                        <text x="10" y="16" font-family="Open Sans Condensed" font-size="4" stroke="none" fill="#f5f3e7"
                            font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="19" dy="12">AGENT</tspan>
                            <tspan x="19" dy="4">Other</tspan>
                        </text>
                        <rect opacity="0" x="10" y="23" width="25" height="10" class="cpr" />
                    </g>

                    <g transform="translate(225)" *ngIf="currentTab=='USER_PASSPORT'">
                        <rect fill="#66cc00" x="10" y="23" width="45" height="11" />
                        <text x="10" y="16" font-family="Open Sans Condensed" font-size="4" stroke="none" fill="#f5f3e7"
                            font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="25" dy="12">User</tspan>
                            <tspan x="20" dy="4">Passport</tspan>
                        </text>
                        <rect opacity="0" x="10" y="23" width="55" height="10" class="cpr" />
                    </g>

                    <g transform="translate(235)" *ngIf="currentTab=='USER_OTHER'">
                        <rect fill="#66cc00" x="10" y="23" width="35" height="11" />
                        <text x="10" y="16" font-family="Open Sans Condensed" font-size="4" stroke="none" fill="#f5f3e7"
                            font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                            <tspan x="21" dy="12">User</tspan>
                            <tspan x="19" dy="4">Other</tspan>
                        </text>
                        <rect opacity="0" x="10" y="23" width="25" height="10" class="cpr" />
                    </g>

                    <g *ngIf="checkTypeTab('AGENT_OTHER')">
                        <line class="path" x1="50" x2="153" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-start)" />
                        <g transform="translate(152)" class="cpr"
                            (click)="showConfirmModal('document-submission/other-document-agent-status-success',this.pax_id);">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#a265e4" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="16" stroke="none" x="15" y="64"
                                xlink:href="/assets/icons/status.svg"></image>
                            <text x="10" y="70" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="17" dy="16">Status</tspan>
                            </text>
                        </g>
                        <line x1="205" x2="310" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        <g transform="translate(104)">
                            <circle
                                [attr.fill]="checkCurrentStatus('STATUS','start_status', 'USER_OTHER') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('STATUS','start_status', 'USER_OTHER') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('STATUS','start_status')" />
                        </g>
                        <g transform="translate(163.5)">
                            <circle
                                [attr.fill]="checkCurrentStatus('STATUS','success_status', 'USER_OTHER') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('STATUS','success_status', 'USER_OTHER') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('STATUS','success_status')" />
                        </g>
                    </g>

                    <g *ngIf="checkTypeTab('USER_OTHER')">
                        <line class="path" x1="50" x2="153" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-start)" />
                        <g transform="translate(152)" class="cpr"
                            (click)="showConfirmModal('document-submission/other-document-user-status-success',this.pax_id);">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#a265e4" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="16" stroke="none" x="15" y="64"
                                xlink:href="/assets/icons/status.svg"></image>
                            <text x="10" y="70" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="17" dy="16">Status</tspan>
                            </text>
                        </g>
                        <line x1="205" x2="310" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        <g transform="translate(104)">
                            <circle
                                [attr.fill]="checkCurrentStatus('STATUS','start_status', 'USER_OTHER') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('STATUS','start_status', 'USER_OTHER') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('STATUS','start_status')" />
                        </g>
                        <g transform="translate(163.5)">
                            <circle
                                [attr.fill]="checkCurrentStatus('STATUS','success_status', 'USER_OTHER') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('STATUS','success_status', 'USER_OTHER') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg"></image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('STATUS','success_status')" />
                        </g>
                    </g>

                    <g *ngIf="checkTypeTab('AGENT_PASSPORT')">
                        <line class="path" x1="50" x2="112" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-start)" />
                        <g transform="translate(110)">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#7f7f7f" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="16" stroke="none" x="15" y="63"
                                xlink:href="/assets/icons/received.svg">
                            </image>
                            <text x="12" y="65" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="12" dy="20">Received</tspan>
                            </text>
                        </g>
                        <line x1="164" x2="200" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        <g transform="translate(195)" class="cpr"
                            (click)="showConfirmModal('document-submission/passport-agent-status-success',this.pax_id);">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#a265e4" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="16" stroke="none" x="15" y="64"
                                xlink:href="/assets/icons/status.svg">
                            </image>
                            <text x="10" y="65" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="17" dy="21">Status</tspan>
                            </text>
                        </g>
                        <line x1="250" x2="310" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end2)" />
                        <g transform="translate(62)">
                            <circle
                                [attr.fill]="checkCurrentStatus('RECEIVED','start_status', 'AGENT_PASSPORT') ? '#66cc00' : '#000'"
                                cx="45" cy="75" r="5"
                                [attr.r]="checkCurrentStatus('RECEIVED','start_status', 'AGENT_PASSPORT') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('RECEIVED','start_status')" />
                        </g>
                        <g transform="translate(122)">
                            <circle
                                [attr.fill]="checkCurrentStatus('RECEIVED','success_status', 'AGENT_PASSPORT') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('RECEIVED','success_status', 'AGENT_PASSPORT') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('RECEIVED','success_status')" />
                        </g>
                        <g transform="translate(147)">
                            <circle
                                [attr.fill]="checkCurrentStatus('STATUS','start_status', 'AGENT_PASSPORT') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('STATUS','start_status', 'AGENT_PASSPORT')  ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('STATUS','start_status')" />
                        </g>
                        <g transform="translate(209)">
                            <circle
                                [attr.fill]="checkCurrentStatus('STATUS','success_status', 'AGENT_PASSPORT')  ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('STATUS','success_status', 'AGENT_PASSPORT') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('STATUS','success_status')" />
                        </g>

                        <line x1="179" x2="179" y1="76" y2="112" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                        </line>

                        <line x1="94" x2="95" y1="112" y2="76" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                        </line>

                        <line x1="178" x2="96" y1="111" y2="111" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />

                        <g transform="translate(96)">
                            <circle
                                [attr.fill]="checkCurrentStatus('RECEIVED','failure_status', 'AGENT_PASSPORT') ? '#66cc00' : '#000'"
                                cx="45" cy="111"
                                [attr.r]="checkCurrentStatus('RECEIVED','failure_status', 'AGENT_PASSPORT') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="108"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="111" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('RECEIVED','failure_status')" />
                        </g>
                    </g>

                    <g *ngIf="checkTypeTab('USER_PASSPORT')">
                        <line class="path" x1="50" x2="112" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-start)" />
                        <g transform="translate(110)">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#7f7f7f" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="16" stroke="none" x="15" y="63"
                                xlink:href="/assets/icons/received.svg">
                            </image>
                            <text x="12" y="65" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="12" dy="20">Received</tspan>
                            </text>
                        </g>
                        <line x1="164" x2="200" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />
                        <g transform="translate(195)" class="cpr"
                            (click)="showConfirmModal('document-submission/passport-user-status-success',this.pax_id);">
                            <rect fill="#CCC" x="5" y="58" rx="3" ry="3" width="50" height="40" />
                            <rect fill="#a265e4" x="2" y="55" rx="3" ry="3" width="50" height="40" />
                            <image width="25" height="16" stroke="none" x="15" y="64"
                                xlink:href="/assets/icons/status.svg">
                            </image>
                            <text x="10" y="65" font-family="Open Sans Condensed" font-size="4.5" stroke="none"
                                fill="#f5f3e7" font-weight="900" style="text-transform:uppercase; letter-spacing: 1px">
                                <tspan x="17" dy="21">Status</tspan>
                            </text>
                        </g>
                        <line x1="250" x2="310" y1="75" y2="75" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end2)" />
                        <g transform="translate(62)">
                            <circle
                                [attr.fill]="checkCurrentStatus('RECEIVED','start_status', 'USER_PASSPORT') ? '#66cc00' : '#000'"
                                cx="45" cy="75" r="5"
                                [attr.r]="checkCurrentStatus('RECEIVED','start_status', 'USER_PASSPORT') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('RECEIVED','start_status')" />
                        </g>
                        <g transform="translate(122)">
                            <circle
                                [attr.fill]="checkCurrentStatus('RECEIVED','success_status', 'USER_PASSPORT') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('RECEIVED','success_status', 'USER_PASSPORT') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('RECEIVED','success_status')" />
                        </g>
                        <g transform="translate(147)">
                            <circle
                                [attr.fill]="checkCurrentStatus('STATUS','start_status', 'USER_PASSPORT') ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('STATUS','start_status', 'USER_PASSPORT')  ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('STATUS','start_status')" />
                        </g>
                        <g transform="translate(209)">
                            <circle
                                [attr.fill]="checkCurrentStatus('STATUS','success_status', 'USER_PASSPORT')  ? '#66cc00' : '#000'"
                                cx="45" cy="75"
                                [attr.r]="checkCurrentStatus('STATUS','success_status', 'USER_PASSPORT') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="72"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="75" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('STATUS','success_status')" />
                        </g>

                        <line x1="179" x2="179" y1="76" y2="112" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                        </line>

                        <line x1="94" x2="95" y1="112" y2="76" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-bottom)">
                        </line>

                        <line x1="178" x2="96" y1="111" y2="111" stroke-width="1.5" stroke="#443c3d"
                            stroke-dasharray="2,1" marker-start="url(#arrow-end)" />

                        <g transform="translate(96)">
                            <circle
                                [attr.fill]="checkCurrentStatus('RECEIVED','failure_status', 'USER_PASSPORT') ? '#66cc00' : '#000'"
                                cx="45" cy="111"
                                [attr.r]="checkCurrentStatus('RECEIVED','failure_status', 'USER_PASSPORT') ? 5.5 : 5"
                                opacity="1" />
                            <image width="10" height="6" stroke="none" x="40" y="108"
                                xlink:href="/assets/icons/bell.svg">
                            </image>
                            <circle cx="45" cy="111" r="5" opacity="0" class="cpr"
                                (click)="showNotificationModal('RECEIVED','failure_status')" />
                        </g>
                    </g>
                </g>
            </svg>
        </div>
    </div>
</div>



<ng-template #noPermission let-modal>
    <div class="modal-header">
        <h4 class="modal-title w-100 text-center" id="modal-basic-title">Oops!</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body text-center text-danger">
        This step is not available to update now!<br>
    </div>
    <div class="modal-footer text-end">
        <button class="btn btn-outline" type="button" (click)="modal.close('Cancel')"> Close
        </button>
    </div>
</ng-template>

<ng-template #questionModal let-modal>
    <div class="modal-header">
        <h4 class="mb-1 w-100 text-center">{{fromWhere ? 'Unblock' : 'Raised'}} Questions</h4>
        <button type="button" class="btn-close float-end" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body text-center">
        <app-review-questions [isMaster]="fromWhere" [isMasterQuestion]="false" [questionsList]="questionsList"
            [fromVisaFlow]="true" [paxId]="pax_id" (closeModal)="closeModal($event)"></app-review-questions>
    </div>
</ng-template>
import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from 'src/app/core/services/product.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';
import { UpdateFormComponent } from './update-form/update-form.component';

@Component({
  selector: "app-country-keyword",
  templateUrl: "./country-keyword.component.html",
  styleUrls: ["./country-keyword.component.scss"],
})
export class CountryKeywordComponent {

  constructor(private api: ProductService, private modalService: NgbModal, 
    private sharedService: SharedService, private toastr: ToastrService, public activeModal: NgbActiveModal,
    public permission:PermissionService) {}

  pageSize = 10;
  pageNumber = 1;
  page_details = {
    count: 0,
    next: '',
    previous: '',
    current: 0
  };
  searchTerm = '';
  isLoading = false;
  countryList = this.sharedService.countryList;
  status = 'any';
  countryData = [];
  countryDataCopy = [];
  selectedData:any;
  @ViewChild('countryDetails', { static: false }) countryModal: ElementRef;
  modalReference: any;



  ngOnInit() {
    if(this.sharedService.countryList.length == 0) this.getCountriesList();
    this.getAddedCountries();
  }

  getAddedCountries(){
    this.isLoading = true;
    this.countryData = [];
    this.sharedService.getKeywords().subscribe(
      (result:any)=>{
        this.countryData = result;
        this.countryDataCopy = [...result];
        this.isLoading = false;
      },
      ()=>{
        this.isLoading = false;
      }
    )
  }
  
  getCountriesList(){
    this.api.getCountryNames().subscribe(
      (response:any)=>{
        this.countryList = response;
        this.sharedService.countryList = response;
      })
  }

  changeStatus(event, data){
    let status = {display_status: event.target.checked};
    this.api.setDisplayStatus(data.id,status).subscribe(
      ()=>{
        this.toastr.success(`Status Changed Successfully!`,`Success!`);
        this.getCountriesList();
      })
  }

  searchCountry(searchTerm){
    this.countryData = this.countryDataCopy.filter(ae=>ae.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  open(content) { 
    this.modalReference = this.modalService.open(content, { size: 'lg', ariaLabelledBy: "modal-basic-title" });
  }

  editCountry(data){
    this.selectedData = data;
    this.open(this.countryModal);
  }

  closeModal() {
    this.modalReference.close('close');
    this.getAddedCountries();
  }

  showSortModal(data) {
    const modalRef = this.modalService.open(UpdateFormComponent,
      {
        size: 'md',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.formData = data;
    modalRef.result.then((result) => {
      if (result) this.getAddedCountries()
    });
  }
}

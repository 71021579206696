import { Component, OnInit } from "@angular/core";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { ReportsService } from "src/app/core/services/reports.service";

@Component({
  selector: "app-pie-charts",
  templateUrl: "./pie-charts.component.html",
  styleUrls: ["./pie-charts.component.scss"],
})
export class PieChartsComponent implements OnInit {
  countryList: any[] = [];
  reportsCustomColors = {
    domain: ["#ff7f0e", "#2ca02c",  "#bcbd22"]
  };
  diyVisaProColors = {
    domain: ["#00ff00", "#ff00ff"]
  };
  reportsList: any[] = [];
  view: any[] = [500, 400];

  // options

  showLabels: boolean = true;
  isDoughnut: boolean = false;
  loader = [];
  diyVisaProData: any = [];
  chartData = [];

  colorScheme = {
    domain: [
      "#1f77b4",
      "#ff7f0e",
      "#2ca02c",
      "#d62728",
      "#9467bd",
      "#8c564b",
      "#e377c2",
      "#7f7f7f",
      "#bcbd22",
      "#17becf",
      "#ff5733",
    ],
  };
  fromDate: NgbDate | null = this.calendar.getPrev(
    this.calendar.getToday(),
    "d",
    30
  );
  toDate: NgbDate | null = this.calendar.getToday();

  constructor(
    public formatter: NgbDateParserFormatter,
    private reportsService: ReportsService,
    private calendar: NgbCalendar
  ) {}

  ngOnInit(): void {
    this.setDate();
  }

  setDate() {
    const date = {
      fromDate:
        this.fromDate.year +
        "-" +
        this.fromDate.month +
        "-" +
        this.fromDate.day,
      toDate:
        this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day,
    };
    this.getAllCountryData(date);
    this.getNewProgressCompleted(date);
    this.getDiyVisapro(date);
  }

  getAllCountryData(event) {
    this.loader[0] = true;
    this.countryList = [];
    this.chartData[0]=[]
    this.reportsService.getAllCountryData(event).subscribe(
      (res: any) => {
        this.setCountryList(res);
        this.setDataToChart(this.colorScheme, this.countryList, 0);
        this.loader[0] = false;
      },
      (err) => {
        this.loader[0] = false;
      }
    );
  }

  setCountryList(res) {
    const countryData = res;
    countryData?.forEach((res, index) => {
      const data = {
        name: res?.Country,
        value: res?.Percentage,
      };
      this.countryList.push(data);
    });
  }

  setDataToChart(color, data, index) {
    this.chartData[index] = [
      {
        color: color,
        view: this.view,
        data: data,
        label: this.showLabels,
        donught: this.isDoughnut,
      },
    ];
  }

  getDiyVisapro(date) {
    this.loader[2] = true;
    this.diyVisaProData = [];
    this.chartData[2]=[]
    this.reportsService.getDiyVisaPro(date).subscribe(
      (res: any) => {
        this.setDiyVisaaList(res);

        this.setDataToChart(this.diyVisaProColors, this.diyVisaProData, 2);
        this.loader[2] = false;
      },
      (err) => {
        this.loader[2] = false;
      }
    );
  }

  setDiyVisaaList(res) {
    const diyVisapro = res;
    diyVisapro.forEach((res) => {
      const data = {
        name: res?.Choice,
        value: res?.Percentage,
      };
      this.diyVisaProData.push(data);
    });
  }

  getNewProgressCompleted(date) {
    this.reportsList = [];
    this.loader[1] = true;
    this.chartData[1]=[]
    this.reportsService.getNewProgressCompleted(date).subscribe(
      (res: any) => {
        
        this.setProgressList(res);

        this.loader[1] = false;
        this.setDataToChart(this.reportsCustomColors, this.reportsList, 1);
      },
      (err) => {
        this.loader[1] = false;
      }
    );
  }
  setProgressList(res) {
    const reportsData = res;
    reportsData.forEach((res) => {
      const data = {
        name: res?.Application_Status,
        value: res?.Percentage,
      };
      this.reportsList.push(data);
    });
  }
}

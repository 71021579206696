<div class="container">
    <form class="row" [formGroup]="headerForm" (ngSubmit)="onSubmit(headerForm)">
        <div class="col-md-8 offset-md-2 p-4 mt-2">
            <div class="border shadow">
                <div class="my-2 mt-3 row">
                    <label class="col-md-3 offset-md-1 col-form-label">Heading</label>
                    <div class="col-md-7">
                        <input type="text" class="form-control" placeholder="Enter Heading" formControlName="heading"
                            [ngClass]="{'invalid': headerForm.get('heading').invalid && isSubmitted}">
                    </div>
                </div>
                <div class="my-2 mt-3 row">
                    <label class="col-md-3 offset-md-1 col-form-label">Sub Heading</label>
                    <div class="col-md-7">
                        <input type="text" class="form-control" placeholder="Enter Label" formControlName="sub_heading"
                            [ngClass]="{'invalid': headerForm.get('sub_heading').invalid && isSubmitted}">
                    </div>
                </div>
                <div class="my-2 row">
                    <div class="col-md-12">
                        <button *ngIf="(page=='about-us' && checkPermission('content_management_about_us_create')) && (page=='about-us' && checkPermission('content_management_about_us_edit'))" class="btn btn-info float-end me-5" [disabled]="!hasChanges"><i
                                [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                            Save Changes</button>
                        <app-publish-btn *ngIf="(page=='about-us' && checkPermission('content_management_about_us_publish'))" class="float-end me-2" [publish]="'AboutUsDescription'" [page]="page"></app-publish-btn>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  page = 'blog';
  mediaSelect: number = 1;
  specificPage = 'blog';

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'Home Page', active: true }];
  }


  mediaChanged() {
    // alert(this.mediaSelect)
  }
}

<div class="modal-header">
    <h5 class="modal-title">Add Cover</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="userCoverForm" (ngSubmit)="onSubmit(userCoverForm)">
        <div class="my-3 row">
            <div class="col-md-6">
                <label class="col-md-12 col-form-label">Name</label>
                <div class="col-md-12">
                    <input type="text" class="form-control" formControlName="template_name"
                        [ngClass]="{'invalid': userCoverForm.get('template_name').invalid && isSubmitted}">
                </div>
            </div>
        </div>
        <div class="my-3 row">
            <div class="col-md-6">
                <label class="col-md-12 col-form-label">From</label>
                <div class="col-md-12">
                    <input type="text" class="form-control" formControlName="letter_from"
                        [ngClass]="{'invalid': userCoverForm.get('letter_from').invalid && isSubmitted}">
                </div>
            </div>
            <div class="col-md-6">
                <label class="col-md-12 col-form-label">To</label>
                <div class="col-md-12">
                    <input type="text" class="form-control" formControlName="to"
                        [ngClass]="{'invalid': userCoverForm.get('to').invalid && isSubmitted}">
                </div>
            </div>
        </div>
        <div class="my-3 row">
            <label class="col-md-12 col-form-label">Subject</label>
            <div class="col-md-12">
                <input type="text" class="form-control" formControlName="subject"
                    [ngClass]="{'invalid': userCoverForm.get('subject').invalid && isSubmitted}">
            </div>
        </div>

        <div class="my-3 row">
            <label class="col-md-12 col-form-label">Content</label>
            <div class="col-md-12">
                <!-- <input type="text" class="form-control" formControlName="body"
                    [ngClass]="{'invalid': userCoverForm.get('body').invalid && isSubmitted}"> -->
                <ckeditor [editor]="Editor" formControlName="body"
                    [ngClass]="{'invalid': userCoverForm.get('body').invalid && isSubmitted}"
                    data="<p>Content of the editor.</p>"></ckeditor>
            </div>
        </div>
        <div class="my-3 row">
            <div class="col-md-12 text-end">
                <button type="submit" class="btn btn-primary">{{fromParent?.id ? 'Update':'Add'}}</button>
            </div>
        </div>
    </form>
</div>
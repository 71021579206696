import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OtherPagesService } from 'src/app/core/services/other-pages.service';
import { PermissionService } from 'src/app/core/services/permision.service';

@Component({
  selector: 'app-consultancy-service',
  templateUrl: './consultancy-service.component.html',
  styleUrls: ['./consultancy-service.component.scss']
})
export class ConsultancyServiceComponent implements OnInit {

  aboutForm: FormGroup;
  hasChanges = false;
  isUpdating = false;
  isSubmitted = false;
  isNew = false;

  @Input() page;

  constructor(private fb: FormBuilder,
    private api: OtherPagesService, private toastr: ToastrService,
    public permission: PermissionService) { }

  ngOnInit(): void {
    this.resetForm();
    this.getData();
  }

  resetForm(data?) {
    this.aboutForm = this.fb.group({
      id: [data ? data.id : null],
      heading: [data ? data.heading : '', [Validators.required]],
      description: [data ? data.description : '', [Validators.required]],
    })
    this.onChanges();
  }

  onChanges(): void {
    this.aboutForm.valueChanges.subscribe(() => {
      Object.keys(this.aboutForm.controls).map((key) => {
        if (this.aboutForm.get(key).dirty) this.hasChanges = true;
      })
    });
  }


  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      if (this.isNew) {
        this.api.postConsultancyData(form.value).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
            this.isNew = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      } else {
        this.api.setConsultancyData(form.value.id, form.value).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  getData() {
    this.api.getConsultancyData().subscribe(
      (result: any) => {
        this.resetForm(result[0]);
        if (result.length == 0) this.isNew = true;
        else this.isNew = false;
      })
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

<!-- <div class="w-100 text-end mt-3" *ngIf="mediaForm.value.media.length>0">
    <a class="cpr btn btn-primary" 
        (click)="showSortModal(mediaForm.value.media, 'heading')">
        <i class="fa fa-sort" aria-hidden="true"></i>  Change Position</a>
</div> -->
<div class="container">
    <div (click)="backtoList()">
        <button class="btn btn-success mt-2">Back</button>
      </div>
    <form [formGroup]="mediaForm" (ngSubmit)="onSubmit(mediaForm)">
        <div class="row">
            <div class="col-md-6 mt-2">
                <a class="starred" href="javascript:void(0)" >
                    <i  [ngClass]="{'selected': mediaForm.value.is_starred}" (click)="isStarredChange()" class="bx bxs-star"></i>
                </a>
                <div class="border shadow bg-white p-4 mt-2" >
                    <a  href="javascript:void(0)" class="ms-2 close-btn"
                       ><i class="uil-times fa-2x"></i></a>
                    <div class="row">
                        <div class="mt-2 col-md-6">
                            <label class="col-form-label">Author</label>
                            <input type="text" formControlName="author" readonly class="form-control" placeholder="Heading">
                        </div>
                        <div class="mt-2 col-md-6">
                            <label class="col-form-label">Heading</label>
                            <input (change)="dataChanged()" [ngClass]="{'invalid': mediaForm.get('heading').invalid && isSubmitted}" type="text" formControlName="heading" class="form-control" placeholder="Heading">
                        </div>
                        <div class="mt-2 col-md-12">
                            <label class="col-form-label">Description</label>
                            <ckeditor [ngClass]="{'invalid': mediaForm.get('description').invalid && isSubmitted}"   formControlName="description"  [editor]="Editor"
                                data="<p>Content of the editor.</p>"></ckeditor>
                        </div>
                        <div class="mt-2 col-md-8">
                            <label class="col-form-label">Date Published</label>
                            <input [ngClass]="{'invalid': mediaForm.get('date_published').invalid && isSubmitted}" (change)="dataChanged()" formControlName="date_published" type="text" class="form-control" placeholder="YYYY-MM-DD"
                                >
                        </div>
                        <!-- <div class="mt-2 col-md-12">
                            <label class="col-form-label">Link URL</label>
                            <input [ngClass]="{'invalid': mediaForm.get('link_url').invalid && isSubmitted}" (change)="dataChanged()" formControlName="link_url" type="text" class="form-control" placeholder="Link URL">
                        </div> -->
                        <div class="mt-2 col-md-12">
                            <label class="col-form-label">Related Medias</label>
                            <!-- <select class="form-select" formControlName="related_medias" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('author').invalid && isSubmitted}">
                                <option *ngFor="let allMedia of allMedias" [value]="allMedia.id"
                                    class="{{allMedia.id==item.get('id').value ? 'd-none' : ''}}">
                                    {{ allMedia.heading}}
                                </option>
                            </select> -->
                            <ng-select [ngClass]="{'invalid': mediaForm.get('related_medias').invalid && isSubmitted}" (change)="dataChanged()" [multiple]="true" formControlName="related_medias" >
                                <div *ngFor="let allMedia of allMedias">
                                    <ng-option [value]="allMedia.id">
                                        {{ allMedia.heading}}
                                    </ng-option>
                                </div>
                            </ng-select>
                        </div>
                        <div class="my-2 row">
                            <div class="col-md-12">
                                <label class="col-form-label">Image</label>
                                <div class=" d-flex">
                                    <input type="text" [ngClass]="{'invalid': mediaForm.get('banner_image_url').invalid && isSubmitted}" (change)="dataChanged()" formControlName="banner_image_url" class="form-control" placeholder="URL" >
                                    <a [href]="mediaForm.value.banner_image_url" target="_blank">
                                        <img class="ms-1" height="40" [src]="mediaForm.value.banner_image_url">
                                    </a>
                                    <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal('banner_image_url')" ><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <!-- <button class="btn btn-primary mt-1 col-md-12" type="button">
                        <i class="uil-plus"></i>
                        Add
                    </button> -->
                    <button *ngIf="(checkPermission('content_management_media_create'))"
                        class="btn btn-info mt-1 col-md-12 mb-1" type="submit">
                        <i [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"> </i>
                        Save All
                    </button>
                    <!-- <app-publish-btn
                        *ngIf="(page=='home' && checkPermission('content_management_home_publish')) || (page=='country' && checkPermission('content_management_country_publish')) || (page=='consultant' && checkPermission('content_management_consultant_publish')) || (page=='category' &&checkPermission('content_management_category_publish')) || (page=='product' && checkPermission('content_management_product_publish'))"
                        class="col-md-12 p-0" [publish]="'UsefulMedia'" [page]="'home'"></app-publish-btn> -->
                </div>
            </div>
        </div>
    </form>
</div>
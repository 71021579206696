import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/core/services/shared.service';
import { ProductPageService } from 'src/app/core/services/product-page.service';

@Component({
  selector: 'app-sku-data',
  templateUrl: './sku-data.component.html',
  styleUrls: ['./sku-data.component.scss']
})
export class SkuDataComponent  implements OnInit{
  isDetails = true;
  @Input() country;
  @Input() page;
  @Input() product;

  ngOnInit(){
    
  }
  
}

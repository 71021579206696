<div class="modal-header">
    <h5 class="modal-title">Add Email Template</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="userEmailForm" (ngSubmit)="onSubmit(userEmailForm)">
        <div class="my-3 row">
            <div class="col-md-6">
                <label class="col-md-12 col-form-label">Template Name</label>
                <div class="col-md-12">
                    <input type="text" class="form-control" formControlName="name"
                        [ngClass]="{'invalid': userEmailForm.get('name').invalid && isSubmitted}">
                </div>
            </div>
            <div class="col-md-6">
                <label class="col-md-12 col-form-label">To</label>
                <div class="col-md-12">
                    <input type="text" class="form-control" formControlName="to"
                        [ngClass]="{'invalid': userEmailForm.get('to').invalid && isSubmitted}">
                </div>
            </div>
        </div>

        <div class="my-3 row">
            <div class="col-md-6">
                <label class="col-md-12 col-form-label">Cc</label>
                <div class="col-md-12">
                    <input type="text" class="form-control" formControlName="cc"
                        [ngClass]="{'invalid': userEmailForm.get('cc').invalid && isSubmitted}">
                </div>
            </div>
            <div class="col-md-6">
                <label class="col-md-12 col-form-label">Subject</label>
                <div class="col-md-12">
                    <input type="text" class="form-control" formControlName="subject"
                        [ngClass]="{'invalid': userEmailForm.get('subject').invalid && isSubmitted}">
                </div>
            </div>
        </div>
        <div class="my-3 row">
            <!-- <label class="col-md-12 col-form-label">Tags</label>
            <div class="col-md-12">
                <input type="text" class="form-control" formControlName="tags"
                    [ngClass]="{'invalid': userEmailForm.get('tags').invalid && isSubmitted}">
            </div> -->
            <div class="form-group col-md-6">
                <label class="col-form-label">Tags</label>
                <div formArrayName="tagsGet">
                    <div *ngFor="let item of tagsGet.controls; let i=index">
                        <div [formGroupName]="i">
                            <div class="form-group d-flex my-2">
                                <input type="text" class="form-control" formControlName="tags">
                                <button type="button" class="deletebtn btn btn-pseudo remove"
                                    (click)="removeTags(i)">
                                    <i class="fa fa-times text-danger"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-sm btn-primary mt-2 float-end" (click)="addTags()">
                    <i class="fa fa-plus"></i> Add tags
                </button>
            </div>
            <div class="form-group col-md-6">
                <label class="col-form-label">Attachments</label>
                <div formArrayName="attachments">
                    <div *ngFor="let item of attachments.controls; let i=index">
                        <div [formGroupName]="i">
                            <div class="form-group d-flex my-2">
                                <input type="text" class="form-control" formControlName="attachment">
                                <button type="button" class="deletebtn btn btn-pseudo remove"
                                    (click)="removeAttachment(i)">
                                    <i class="fa fa-times text-danger"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-sm btn-primary mt-2 float-end" (click)="addAttachment()">
                    <i class="fa fa-plus"></i> Add Attachment
                </button>
            </div>
        </div>
        <div class="my-3 row">
            <label class="col-md-12 col-form-label">Content</label>
            <div class="col-md-12">
                <!-- <input type="text" class="form-control" formControlName="body"
                    [ngClass]="{'invalid': userEmailForm.get('body').invalid && isSubmitted}"> -->
                <ckeditor [editor]="Editor" formControlName="body"
                    [ngClass]="{'invalid': userEmailForm.get('body').invalid && isSubmitted}"
                    data="<p>Content of the editor.</p>"></ckeditor>
            </div>
        </div>
        <div class="my-3 row">
            <div class="col-md-12 text-end">
                <button type="submit" class="btn btn-primary">{{fromParent?.id ? 'Update':'Add'}}</button>
            </div>
        </div>
    </form>
</div>
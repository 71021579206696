import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';
import { ContactUsService } from 'src/app/core/services/contact-us-page.service';

@Component({
  selector: 'app-header-contact-us',
  templateUrl: './header-contact-us.component.html',
  styleUrls: ['./header-contact-us.component.scss']
})
export class HeaderContactUsComponent implements OnInit {

  headerForm: FormGroup;
  fileToUpload: File | null = null;
  hasChanges = false;
  isUpdating = false;
  isSubmitted = false;
  isNew = false;

  @Input() page;

  constructor(private fb: FormBuilder,
    private api: ContactUsService, private toastr: ToastrService, public permission: PermissionService) {

  }

  ngOnInit(): void {
    this.resetForm();
    this.getData();
  }

  resetForm(data?) {
    this.headerForm = this.fb.group({
      id: [data ? data.id : null],
      // image_url: [data ? data.image_url : ''],
      heading: [data ? data.heading : '', [Validators.required]],
      sub_heading: [data ? data?.sub_heading : '', [Validators.required]],
      email: [data ? data?.email : '', [Validators.required]],
      phone_no: [data ? data?.phone_no : '', [Validators.required]],
      // button_url: [data ? data.button_url : '', [Validators.required]],
    })
    this.onChanges();
  }

  setForm(data?) {
    this.headerForm.patchValue(data);
  }

  onChanges(): void {
    this.headerForm.valueChanges.subscribe(() => {
      Object.keys(this.headerForm.controls).map((key) => {
        if (this.headerForm.get(key).dirty) this.hasChanges = true;
      })

    });
  }

  onSubmit(form) {

    if (form.valid) {
      this.isUpdating = true;
      const formData: FormData = new FormData();
      if (this.fileToUpload) formData.append('image_url', this.fileToUpload);
      else formData.delete('image_url')
      formData.append('heading', form.value.heading);
      formData.append('button_label', form.value.button_label);
      formData.append('button_url', form.value.button_url);

      if (this.isNew) {
        this.api.postDescription(form.value).subscribe(
          (result: any) => {

            this.resetForm(result[0]);
            // this.setForm(result[0])
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
            this.isNew = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      } else {

        this.api.editDescription(form.value.id, form.value).subscribe(
          (result: any) => {
            this.resetForm(result);
            // this.setForm(result[0])
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      };
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }

  }

  getData() {
    this.api.getDescription().subscribe(
      (result: any) => {

        this.resetForm(result[0]);
        if (result.length == 0) this.isNew = true;
        else this.isNew = false;
      })
  }
  
  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

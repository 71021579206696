<div class="row m-2" *ngIf="!viewPassport && !quesionView">
  <div
    class="col-md-12"
    *ngIf="title === 'visa-process' && !isPrimary && !paxDetails?.workflow"
  >
    <div class="card">
      <div class="card-header pb-0 mb-0">
        <!-- [ngClass]="{'bg-success': isPrimary, 'bg-primary': !isPrimary}" -->
        <h5 class="font-size-16 pb-0">Traveler's Details</h5>
      </div>

      <form [formGroup]="travellorInfoForm" (ngSubmit)="onSubmit()">
        <div formArrayName="travellors">
          <div
            class="d-flex ms-1 flex-wrap"
            *ngFor="let travellor of travellors.controls; let i = index"
            [formGroupName]="i"
          >
            <div class="me-4">
              <label class="fw-bold">Fist Name</label>
              <div>
                <input
                  type="text"
                  formControlName="first_name"
                  class="form-control-sm"
                />
              </div>

              <div
                class="text-danger"
                *ngIf="
                  travellor.get('first_name').hasError('required') &&
                  (travellor.get('first_name').touched ||
                    travellor.get('first_name').dirty)
                "
              >
                First Name is required
              </div>
            </div>

            <div class="me-4">
              <label class="fw-bold">Last Name</label>
              <div>
                <input
                  type="text"
                  formControlName="last_name"
                  class="form-control-sm"
                />
              </div>

              <div
                class="text-danger"
                *ngIf="
                  travellor.get('last_name').hasError('required') &&
                  (travellor.get('last_name').touched ||
                    travellor.get('last_name').dirty)
                "
              >
                Last Name is required
              </div>
            </div>
            <div class="me-4">
              <label class="fw-bold">DOB</label>
              <div>
                <input
                  type="date"
                  formControlName="dob"
                  class="form-control-sm"
                />
              </div>

              <div
                class="text-danger"
                *ngIf="
                  travellor.get('dob').hasError('required') &&
                  (travellor.get('dob').touched || travellor.get('dob').dirty)
                "
              >
                DOB is required
              </div>
            </div>
            <div class="me-4">
              <label class="fw-bold">Email</label>
              <div>
                <input
                  type="text"
                  formControlName="email_id"
                  class="form-control-sm"
                />
              </div>

              <div
                class="text-danger"
                *ngIf="
                  !travellor.get('email_id').hasError('required') &&
                  travellor.get('email_id').invalid
                "
              >
                Invalid email
              </div>
              <div
                class="text-danger"
                *ngIf="
                  travellor.get('email_id').hasError('required') &&
                  (travellor.get('email_id').touched ||
                    travellor.get('email_id').dirty)
                "
              >
                Email is required
              </div>
            </div>
            <div class="me-4">
              <label class="fw-bold">Contact</label>
              <div>
                <input
                  type="text"
                  numbersOnly
                  formControlName="phone_no"
                  class="form-control-sm"
                />
              </div>

              <div
                class="text-danger"
                *ngIf="
                  travellor.get('phone_no').hasError('required') &&
                  (travellor.get('phone_no').touched ||
                    travellor.get('phone_no').dirty)
                "
              >
                Contact is required
              </div>
            </div>
            <div class="me-4">
              <label class="fw-bold">Passport number</label>
              <div>
                <input
                  (input)="validatePassword(i)"
                  maxlength="8"
                  type="text"
                  formControlName="passport_no"
                  class="form-control-sm"
                />
              </div>
              <div
                class="text-danger"
                *ngIf="
                  travellor.get('passport_no').touched &&
                  travellor.get('passport_no').errors
                "
              >
                <div *ngIf="travellor.get('passport_no').errors?.required">
                  Passport number is required.
                </div>
                <div
                  *ngIf="!travellor.get('passport_no').errors?.required && travellor.get('passport_no').errors?.['is_exist']"
                >
                  {{ travellor.get("passport_no").errors.is_exist }}
                </div>
              </div>
            </div>
            <div class="me-4">
              <label class="fw-bold">Select Profile</label>
              <ng-select
                formControlName="profile_id"
                id="profile"
                placeholder="Select profile"
                (change)="setPrfileData(i)"
              >
                <ng-option
                  [value]="profile.id"
                  *ngFor="let profile of myProfileOptions"
                  >{{ profile?.passport_w_customer_name }}</ng-option
                >
              </ng-select>
            </div>
            <!-- <div class="mt-4 me-1">
            
              <button (click)="removeTravellor(i)" *ngIf="addOrRemve>1">Remove</button>
            </div> -->
          </div>
        </div>
        <div class="d-flex justify-content-end mt-2">
          <button
            class="btn btn-success m-2"
            type="button"
            (click)="addTravellor()"
            *ngIf="travellors.length < paxDetails?.count"
          >
            Add
          </button>
          <button
            class="btn btn-success border m-2"
            type="submit"
            *ngIf="travellors.length == paxDetails?.count"
          >
            Assign Work Flow
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-12">
    <div class="card">
      <div class="card-header pb-0 mb-0">
        <!-- [ngClass]="{'bg-success': isPrimary, 'bg-primary': !isPrimary}" -->
        <h5 class="font-size-16 pb-0">
          {{
            isPrimary
              ? "Primary Customer - Communication"
              : "Traveler's Details"
          }}
        </h5>
      </div>
      <div class="card-body pt-0">
        <div class="table-responsive">
          <table class="table table-responsive mb-0">
            <thead>
              <tr>
                <th *ngIf="!isPrimary">Visa Flow</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th *ngIf="!isPrimary">DOB</th>
                <th>Contact Number</th>
                <th>Email</th>
                <th>Status</th>
                <th class="text-center" *ngIf="!isPrimary">
                  Application Details
                </th>
                <th class="text-center" *ngIf="!isPrimary">Covering Letter</th>
                <th class="text-center" *ngIf="!isPrimary">Master Form</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of travellerInfo">
                <td *ngIf="!isPrimary">
                  <a
                    class="text-info"
                    [routerLink]="['/visa/visa-flow/', item.id]"
                    queryParamsHandling="merge"
                    [state]="{ app_id: app_id }"
                  >
                    <i class="fa fa-eye"></i>
                  </a>
                </td>
                <td>{{ item.first_name }}</td>
                <td>{{ item.last_name }}</td>
                <td *ngIf="!isPrimary">
                  {{ item.dob | date : "dd MMM yyyy" }}
                </td>
                <td>
                  <a href="tel:{{ item.phone_no }}">{{ item.phone_no }}</a>
                </td>
                <td>
                  <a
                    *ngIf="isPrimary"
                    class="d-flex"
                    href="mailto:{{ item.email }}"
                  >
                    <i class="fa fa-reply mt-1 me-1"></i> {{ item.email }}
                  </a>
                  <a
                    *ngIf="!isPrimary"
                    class="d-flex"
                    href="mailto:{{ item.email_id }}"
                  >
                    <i class="fa fa-reply mt-1 me-1"></i> {{ item.email_id }}
                  </a>
                </td>
                <td>
                  {{ item.current_process }}
                </td>
                <td class="text-center" *ngIf="!isPrimary">
                  <a
                    class="cpr"
                    (click)="showForm(item.id, 'application_details')"
                  >
                    <i
                      [ngClass]="{
                        'fas fa-edit text-info': !isComplVisaDeta,
                        'fa fa-spin fa-spinner fa-pulse text-success':
                          isComplVisaDeta
                      }"
                    ></i>
                  </a>
                </td>

                <td class="text-center" *ngIf="!isPrimary">
                  <a class="cpr" (click)="showForm(item.id, 'covering_letter')">
                    <i
                      [ngClass]="{
                        'fas fa-edit text-info': !isCoveringLetter,
                        'fa fa-spin fa-spinner fa-pulse text-success':
                          isCoveringLetter
                      }"
                    ></i>
                  </a>
                </td>

                <td class="text-center" *ngIf="!isPrimary">
                  <a class="cpr" (click)="getQuestions(item.id)">
                    <i
                      [ngClass]="{
                        'fas fa-edit text-info': !isGetting,
                        'fa fa-spin fa-spinner fa-pulse text-success': isGetting
                      }"
                    ></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="passport_view" *ngIf="!isPrimary && viewPassport">
  <stp-passport-details
    [passportData]="passportData"
    [paxId]="paxId"
    (showNextStep)="showNextStep()"
  ></stp-passport-details>
</div>

<ng-template #questionModal let-modal>
  <div class="modal-header">
    <h4 class="mb-1 w-100 text-center">Review Questions</h4>
    <button
      type="button"
      class="btn-close float-end"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body text-center">
    <app-review-questions
      [questionsList]="questionsList"
      [fromVisaFlow]="false"
      [paxId]="paxId"
      [isMasterQuestion]="isMasterQuestion"
    ></app-review-questions>
  </div>
</ng-template>

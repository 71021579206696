import { Component, NgZone, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { GlobalQuestionsService } from 'src/app/core/services/global-questions.service';
import { ToastrService } from 'ngx-toastr';
import { CountryQuestionsService } from 'src/app/core/services/country-questions.service';

@Component({
  selector: 'app-added-que-modal',
  templateUrl: './added-que-modal.component.html',
  styleUrls: ['./added-que-modal.component.scss']
})


export class AddedQueModalComponent implements OnInit {

  checkedQuestions: any[] = [];
  currentFormData: any;
  allQuestions: any;
  allQueToDisplay: any;
  dataAdded: any[] = [];

  searchValue: any;

  constructor(public activeModal: NgbActiveModal, private toastr: ToastrService, private modalService: NgbModal, public CntryQueService: CountryQuestionsService) {

  }

  ngOnInit(): void {
    this.allQueToDisplay = this.allQuestions;
  }

  searchChange(searchValue) {

    this.allQueToDisplay = [];
    let value = this.allQuestions.filter(data => {

      if (data.display_name.toLowerCase().match(searchValue.toLowerCase())) {
        // console.log(data);
        this.allQueToDisplay.push(data)

      }
    });

  }

  addDataToArray(event: any, elem: any) {

    if (event.target.checked) {

      this.checkedQuestions.push(elem)

    } else {

      let dataAddedIndex = this.checkedQuestions.findIndex(dataElem => dataElem.id == elem.id);
      this.checkedQuestions.splice(dataAddedIndex, 1);
    }


  }

  addQuestion() {

    let dataToPass: { questions: any };
    dataToPass = { questions: this.checkedQuestions };
    this.CntryQueService.addCountryFormQuestions(this.currentFormData.id, dataToPass).subscribe((res: any) => {
      this.toastr.success('Data added successfully');
      this.activeModal.close({ reload: true });

    }, err => { console.log(err) });

  }


}

<div class="container">
    <form class="row" [formGroup]="eligibleForm" (ngSubmit)="onSubmit(eligibleForm)">
        <div class="col-md-8 offset-md-2 p-4 mt-2">
            <div class="border shadow">
                <div class="my-2 row p-3">
                    <label class="col-md-12 col-form-label">Description</label>
                    <div class="col-md-12" formArrayName="description_data">
                        <div *ngFor="let desc of descriptions.controls; let i=index">
                            <div [formGroupName]="i">
                                <div class="form-group my-2">
                                    <button type="button" *ngIf="descriptions.controls.length!=1"
                                        class="deletebtn btn btn-pseudo remove float-end"
                                        (click)="removeDescription(i);"><i class="fa fa-times text-danger"></i></button>
                                    <textarea cols="30" rows="3" class="form-control" placeholder="Enter Description"
                                        formControlName="text" (keyup)="hasChanges=true;"
                                        [ngClass]="{'invalid': desc.get('text').invalid && isSubmitted}"></textarea>

                                </div>
                            </div>
                        </div>
                        <button type="button" class="btn btn-sm btn-primary mt-2 float-end" (click)="addDescription()">
                            <i class="fa fa-plus"></i> Add Description
                        </button>
                    </div>
                </div>
                <div class="my-2 row">
                    <div class="col-md-12">
                        <button
                            *ngIf="(page=='category' &&checkPermission('content_management_category_create')) || (page=='product' && checkPermission('content_management_product_create')) && (page=='category' &&checkPermission('content_management_category_edit')) || (page=='product' && checkPermission('content_management_product_edit'))"
                            class="btn btn-success float-end me-5" [disabled]="!hasChanges"><i
                                [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                            Save Changes</button>
                        <app-publish-btn
                            *ngIf="(page=='category' &&checkPermission('content_management_category_publish')) || (page=='product' && checkPermission('content_management_product_publish'))"
                            class="float-end me-2" [publish]="publishPage" [page]="page"></app-publish-btn>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
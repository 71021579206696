<div class="row">
    <div class="col-md-3">
        <div class="text-center w-100 p-4">
            <button type="button" class="btn btn-success" (click)="addRole()"><i class="mdi mdi-plus me-1"></i>
                Add Role</button>
        </div>
        <div class="role_names_list">
            <div class="row">
                <div class="col-md-12 mb-2" *ngFor="let role of userRole.reverse()">
                    <div class="border shadow text-center py-2 cpr" [ngClass]="{'role-selected':role.selected}" 
                    (click)="showPermissionDetails(role?.id)">
                       {{role?.role_name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-9 border_left">
        <div class="col-md-12 mt-5 mb-2" *ngIf="!showPermission">
            <div class="border shadow text-center py-2 cpr"> No Roles Added.  Add New Role<br>
            </div>
        </div>
        <div class="permission_section" [hidden]="!showPermission">
            <app-permission-item [tab]="'roles'"></app-permission-item>
        </div>
    </div>
</div>
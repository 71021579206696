<div class="modal-header">
    <h5 class="modal-title w-100 text-center">Notifications</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)">
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-12 mt-3">
            <div class="table-responsive">
                <table class="table table-sm m-0">
                    <thead class="text-center">
                        <tr>
                            <th>#</th>
                            <th>Channel</th>
                            <th>Template</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Sent Time</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr *ngFor="let item of notifications; let i=index;">
                            <th scope="row">{{i+1}}</th>
                            <td>{{item.template_type_name}}</td>
                            <td>{{item.template_name}}</td>
                            <td><span class="badge rounded-pill bg-primary">{{item.sla ? 'SLA' : 'Notification'}}</span></td>
                            <td>{{item.notification_status}}</td>
                            <td>
                                <span *ngIf="item.sent_time">{{item.sent_time*1000 | date: 'medium'}}</span>
                                <span class="text-danger" *ngIf="!item.sent_time">-</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer text-end">
    <button class="btn btn-outline-secondary" (click)="closeModal(false)"><i class="fa fa-times"></i> Close</button>
</div>
<!-- <div class="w-100 text-end mt-3" *ngIf="mediaForm.value.media.length>0">
    <a class="cpr btn btn-primary" 
        (click)="showSortModal(mediaForm.value.media, 'heading')">
        <i class="fa fa-sort" aria-hidden="true"></i>  Change Position</a>
</div> -->
<div class="container">
    <form [formGroup]="mediaForm" (ngSubmit)="onSubmit(mediaForm)">
        <div class="row">
            <div class="col-md-6 mt-2" formArrayName="media" *ngFor="let item of media().controls; let i = index;">
                <a class="starred" href="javascript:void(0)" (click)="isStarredChange(i)">
                    <i [ngClass]="{'selected': mediaForm.value.media[i]?.is_starred}" class="bx bxs-star"></i>
                </a>
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="media().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removemedia(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="row">
                        <div class="mt-2 col-md-6">
                            <label class="col-form-label">Author</label>
                            <select class="form-select" formControlName="author" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('author').invalid && isSubmitted}">
                                <option *ngFor="let author of authors" [value]="author.id">{{author.name}}</option>
                                <!-- <option>News</option> -->
                            </select>
                        </div>
                        <div class="mt-2 col-md-6">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                                (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('heading').invalid && isSubmitted}">
                        </div>
                        <div class="mt-2 col-md-12">
                            <label class="col-form-label">Description</label>
                            <ckeditor [editor]="Editor" formControlName="description" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('description').invalid && isSubmitted}"
                                data="<p>Content of the editor.</p>"></ckeditor>
                        </div>
                        <div class="mt-2 col-md-8">
                            <label class="col-form-label">Date Published</label>
                            <input type="text" class="form-control" placeholder="YYYY-MM-DD"
                                formControlName="date_published" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('date_published').invalid && isSubmitted}">
                        </div>
                        <!-- <div class="mt-2 col-md-12">
                            <label class="col-form-label">Link URL</label>
                            <input type="text" class="form-control" placeholder="Link URL" formControlName="link_url"
                                (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('link_url').invalid && isSubmitted}">
                        </div> -->
                        <div class="mt-2 col-md-12">
                            <label class="col-form-label">Related Medias</label>
                            <!-- <select class="form-select" formControlName="related_medias" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('author').invalid && isSubmitted}">
                                <option *ngFor="let allMedia of allMedias" [value]="allMedia.id"
                                    class="{{allMedia.id==item.get('id').value ? 'd-none' : ''}}">
                                    {{ allMedia.heading}}
                                </option>
                            </select> -->
                            <ng-select [multiple]="true" formControlName="related_medias" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('author').invalid && isSubmitted}">
                                <div *ngFor="let allMedia of allMedias">
                                    <ng-option [value]="allMedia.id" *ngIf="allMedia.id!=item.get('id')?.value ">
                                        {{ allMedia.heading}}
                                    </ng-option>
                                </div>
                            </ng-select>
                        </div>
                        <div class="my-2 row">
                            <div class="col-md-12">
                                <label class="col-form-label">Image</label>
                                <div class=" d-flex">
                                    <input type="text" class="form-control" placeholder="URL" formControlName="banner_image_url">
                                    <a [href]="mediaForm.value.media[i].banner_image_url" target="_blank">
                                        <img class="ms-1" height="40" [src]="mediaForm.value.media[i].banner_image_url">
                                    </a>
                                    <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'banner_image_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button class="btn btn-primary mt-1 col-md-12" type="button" (click)="addmedia()">
                        <i class="uil-plus"></i>
                        Add
                    </button>
                    <button *ngIf="(checkPermission('content_management_media_create'))"
                        class="btn btn-info mt-1 col-md-12 mb-1" type="submit">
                        <i [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"> </i>
                        Save All
                    </button>
                    <app-publish-btn
                        *ngIf="(page=='home' && checkPermission('content_management_home_publish')) || (page=='country' && checkPermission('content_management_country_publish')) || (page=='consultant' && checkPermission('content_management_consultant_publish')) || (page=='category' &&checkPermission('content_management_category_publish')) || (page=='product' && checkPermission('content_management_product_publish'))"
                        class="col-md-12 p-0" [publish]="'UsefulMedia'" [page]="'home'"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AboutUsPageService {

  constructor(private http: HttpClient) { }

  // description
  getDescription(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/about-us/description/`).pipe(retry(2));
  }

  setDescription(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/about-us/description/${id}/`, data);
  }

  postDescription(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/about-us/description/`, data);
  }

  // side info
  getSideInfo(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/about-us/sideinfo/`).pipe(retry(2));
  }

  setSideInfo(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/about-us/sideinfo/`, data);
  }

  // description
  getAboutData(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/about-us/about_about_us/`).pipe(retry(2));
  }

  setAboutData(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/about-us/about_about_us/${id}/`, data);
  }

  postAboutData(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/about-us/about_about_us/`, data);
  }

   // infos
   getInfoData(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/about-us/infos/`).pipe(retry(2));
  }

  setInfoData(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/about-us/infos/`, data);
  }

  // Offers
  getOfferData(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/about-us/offers/`).pipe(retry(2));
  }

  setOfferData(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/about-us/offers/`, data);
  }

   // Statistics
   getStatisticsData(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/about-us/statitics/`).pipe(retry(2));
  }

  setStatisticsData(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/about-us/statitics/`, data);
  }

  // Crew
  getCrewData(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/about-us/crew/`).pipe(retry(2));
  }

  setCrewData(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/about-us/crew/`, data);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'xmlFormatter'
})
export class XmlFormatterPipe implements PipeTransform {
  transform(value: string): string {
    // Format XML here
    // For example, you can use regular expressions or external libraries like xml-formatter
   
    // Here's a basic example using RegExp:
    const formattedXML = value?.replace(/></g, '>\n<');
    return formattedXML;
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductService } from 'src/app/core/services/product.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { AgeGroupsComponent } from '../country/add-country/age-groups/age-groups.component'

@Component({
  selector: 'app-age-group',
  templateUrl: './age-group.component.html',
  styleUrls: ['./age-group.component.scss']
})
export class AgeGroupComponent implements OnInit{

  countryList = this.sharedService.addedCountryList;
  ageGroupList = [];
  selectedCountry;
  @ViewChild(AgeGroupsComponent) ageGroupComponent: AgeGroupsComponent;

  breadCrumbItems: Array<{}>;
  country = this.sharedService.selectedCountryId;
  selected = false;
  isLoading = false;

  constructor(private api: ProductService, private sharedService: SharedService) { }

  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Product Management' }, { label: 'Age Groups', active: true }];
    if(this.countryList.length==0)this.getCountriesList();
    if(this.country) this.getAgeGroups(this.country)
    
  }

  getCountriesList() {
    this.api.getCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response;
        this.sharedService.addedCountryList = this.countryList;
      })
  }

  getAgeGroups(country){
    this.selectedCountry = country;
    this.selected = true;
    this.isLoading = true;
    this.ageGroupList = [];
    this.api.getAgeGroupsByCountry(country).subscribe(
      (result:any)=>{
        this.isLoading = false;
        this.ageGroupList = result;
        this.sharedService.countryAllData.is_age_groups = true;
        this.sharedService.countryAllData.age_groups = this.ageGroupList;
        this.sharedService.selectedCountryId = this.selectedCountry;
        this.ageGroupComponent.initAgeGroup();
      })
  }

  refreshAgeGroups(event){
    this.getAgeGroups(this.selectedCountry);
  }

}

import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FaqSeoService } from "src/app/core/services/faq-seo.service";
import { FaqSectionComponent } from "../sections/faq-section/faq-section.component";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.scss"],
})
export class FaqComponent {
  breadCrumbItems: Array<{}>;
  page = "faq";
  @ViewChild(FaqSectionComponent ) faqComponent: FaqSectionComponent;
  @ViewChild(FaqSectionComponent ) faqGeneralComponent: FaqSectionComponent;

  country = this.faqSeoService.selectedFaqCountryId;
  category = this.faqSeoService.selectedFaqCategoryId;
  categoryId:any;
  countryList = this.faqSeoService.faqCountryList;
  categoryList = this.faqSeoService.faqCategoryList;

  constructor(
    private faqSeoService: FaqSeoService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.breadCrumbItems = [
      { label: "CMS" },
      { label: "Faq Page", active: true },
    ];
   
    if (this.countryList.length == 0) this.getCountriesList();
  }

  getCountriesList() {
    this.faqSeoService.getFaqCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response?.datas;
        this.faqSeoService.faqCountryList = this.countryList;
      });
  }

  selectCountry(country) {
    this.faqSeoService.selectedFaqCountryId = country;
    this.faqSeoService.selectedFaqCategoryId = null;
    this.faqSeoService.faqCategoryList = [];
    this.category = null;
    this.categoryList = [];
    this.getCategories(country);
  }

  getCategories(country_id) {
    this.faqSeoService.getFaqCategoryList(country_id).subscribe(
      (result: any) => {
        this.categoryList = result?.datas;
        this.selectCategory(this.categoryList[0]);
        this.faqSeoService.faqCategoryList = this.categoryList;
      });
  }

  selectCategory(data:any) {
    this.categoryId = data.id;
    this.faqSeoService.selectedFaqCategoryId = data.id;
    setTimeout(()=>{
      this.faqComponent.initFaqSection(data.id);
    },500)
  }

  selectGeneral() {
    this.faqGeneralComponent.initFaqGeneralSection();
  }
}

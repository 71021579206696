<!-- <div class="container">
    <div class="row mt-3">
        <div class="col-lg-4 col-md-6 row">
            <div class="col-md-12">
                <p class="text-center text-uppercase">Categories</p>
                <div class="col-md-12 my-2 mb-3 text-center"
                    *ngIf="checkPermission('product_management_category_create')">
                    <button class="btn btn-primary"
                        (click)="showCatForm=!showCatForm;isCatEdit=false;resetForm();isSubmitted=false;">{{showCatForm?'Show
                        List': '+ Add Category'}}</button>
                        <button *ngIf="categoryList?.length>0" class="btn btn-primary mx-2"
                        (click)="changePosition()">Change Position</button>
                </div>
              
                <div class="col-md-12 row" *ngIf="!showCatForm">
                    <div class="col-md-12 mb-2 d-flex" *ngFor="let cat of categoryList">
                        <div class="border shadow text-center py-2 cpr w-100 m-auto"
                            [ngClass]="{'cat-selected':cat.selected}" (click)="isCat=true;showSubcategories(cat.id)">
                            {{cat.name}} </div>
                        <div class="d-grid m-1" *ngIf="checkPermission('product_management_category_edit')">
                            <a class="cpr" (click)="editCat(true,cat);isCat=true;showSubcategories(cat.id)"><i
                                    class="far fa-edit text-success"></i></a>
                            
                    </div>
                </div>
                <div class="col-md-12" *ngIf="showCatForm">
                    <div class="border shadow p-3">
                        <form [formGroup]="catForm" (ngSubmit)="onCatSubmit(catForm)">
                            <div class="mt-2 row">
                                <div class="col-md-12">
                                    <label class="col-form-label">Category Name</label>
                                    <input type="text" class="form-control" placeholder="Enter Name"
                                        formControlName="name"
                                        [ngClass]="{'invalid': catForm.get('name').invalid && isSubmitted}"
                                        (keyup)="generateUrlParam(true, catForm.value.name)">
                                </div>
                                <div class="col-md-12">
                                    <div class="form-check mt-2">
                                        <input type="checkbox" id="forCat" class="form-check-input"
                                            [(ngModel)]="isSuggestCat" [ngModelOptions]="{standalone: true}"
                                            (change)="generateUrlParam(true, catForm.value.name)">
                                        <label for="forCat" class="form-check-label"> Show Suggestion for URL
                                            Param</label>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 row">
                                <div class="col-md-12">
                                    <label class="col-form-label">Internal Code</label>
                                    <input type="text" class="form-control" placeholder="Enter Internal Code"
                                        formControlName="internal_code"
                                        [ngClass]="{'invalid': catForm.get('internal_code').invalid && isSubmitted}">
                                </div>
                            </div>
                            <div class="mt-2 row">
                                <div class="col-md-12">
                                    <label class="col-form-label">URL Param</label>
                                    <input type="text" class="form-control" placeholder="Enter URL Param"
                                        (keyup)="checkURLParam(catForm.value.url_param, true)"
                                        formControlName="url_param"
                                        [ngClass]="{'invalid': catForm.get('url_param').invalid && isSubmitted}">
                                </div>
                            </div>
                            <div class="mt-2 row">
                                <div class="col-md-12">
                                    <button class="btn btn-primary mt-1 w-100" type="submit"
                                        [disabled]="isSubmittingCat || isUrlCat"><i class="uil-plus"></i>
                                        {{isCatEdit?'Update':'Add'}}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-lg-8 col-md-6 row" *ngIf="selectedCatId">
            <p class="text-center text-uppercase">Sub-Categories</p>
            <div class="col-md-12 mb-2" *ngIf="selectedCatId && subCatList.length==0 && !isGettingSubCat">
                <div class="border shadow text-center py-2 cpr"> No Sub-Categories<br>
                </div>
            </div>
            <div class="col-md-12 mb-2" *ngIf="isGettingSubCat">
                <div class="border shadow text-center py-2 cpr">
                    <i class="fa fa-2x fa-spin fa-spinner fa-pulse"></i>
                </div>
            </div>
            <div class="col-md-12 my-2 text-center">
                <button class="btn btn-primary"
                    (click)="showSubCatForm=!showSubCatForm;isSubCatEdit=false;resetSubCatForm();isSubCatSubmitted=false;">{{showSubCatForm?'Show
                    List': '+ Add Sub-Category'}}</button>
            </div>
            <div class="col-md-12 row" *ngIf="!showSubCatForm">
                <div class="col-md-6 mb-2" *ngFor="let subcat of subCatList">
                    <div class="border shadow text-center py-2 cpr w-minus m-auto"
                        [ngClass]="{'cat-selected':subcat.selected}" (click)="isCat=false;selectSubCat(subcat.id);">
                        {{subcat.name}} </div>
                    <div class="d-grid m-1 sub-list">
                        <a class="cpr" (click)="editCat(false,subcat);isCat=false;selectSubCat(subcat.id)"><i
                                class="far fa-edit text-success"></i></a>
                    </div>
                </div>
            </div>

            <div class="col-md-12" *ngIf="showSubCatForm && selectedCatId">
                <div class="border shadow p-3">
                    <form [formGroup]="subCatForm" (ngSubmit)="onSubCatSubmit(subCatForm)">
                        <div class="mt-2 row">
                            <div class="col-md-6">
                                <label class="col-form-label">Name</label>
                                <input type="text" class="form-control" placeholder="Name" formControlName="name"
                                    (keyup)="generateUrlParam(false, subCatForm.value.name)">
                                <div class="form-check mt-2">
                                    <input type="checkbox" id="forSubCat" class="form-check-input"
                                        [(ngModel)]="isSuggestSubCat" [ngModelOptions]="{standalone: true}"
                                        (change)="generateUrlParam(false, subCatForm.value.name)">
                                    <label for="forSubCat" class="form-check-label"> Show Suggestion for URL
                                        Param</label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label">Entry Type</label>
                                <select type="text" class="form-select" formControlName="entry_type"
                                    [ngClass]="{'invalid': subCatForm.get('entry_type').invalid && isSubCatSubmitted}">
                                    <option disabled value=null>Select Entry</option>
                                    <option [value]="'Single'">SINGLE</option>
                                    <option [value]="'Multiple'">MULTIPLE</option>
                                    <option [value]="'Single/Multiple'">BOTH</option>
                                </select>
                            </div>
                        </div>
                        <div class="mt-2 row">
                            <div class="col-md-6">
                                <label class="col-form-label">Price</label>
                                <input type="number" class="form-control" placeholder="Price" formControlName="price"
                                    [ngClass]="{'invalid': subCatForm.get('price').invalid && isSubCatSubmitted}">
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label">Internal Code</label>
                                <input type="text" class="form-control" placeholder="Internal Code"
                                    formControlName="internal_code"
                                    [ngClass]="{'invalid': subCatForm.get('internal_code').invalid && isSubCatSubmitted}">
                            </div>
                            <div class="col-md-8">
                                <label class="col-form-label">URL Param</label>
                                <input type="text" class="form-control" placeholder="Enter URL Param"
                                    (keyup)="checkURLParam(subCatForm.value.url_param, false)"
                                    formControlName="url_param"
                                    [ngClass]="{'invalid': subCatForm.get('url_param').invalid && isSubCatSubmitted}">
                            </div>
                        </div>
                        <div class="mt-2 row">
                            <div class="col-md-12 row shadow group-box">
                                <div class="col-md-6 col-form-label">Stay Duration</div>
                                <div class="col-md-6">
                                    <div class="form-check mt-3 float-end">
                                        <input type="checkbox" id="asPerEmbassy" class="form-check-input"
                                            formControlName="as_per_embassy"
                                            (change)="changeRequiredDuration(subCatForm.value.as_per_embassy, true)">
                                        <label for="asPerEmbassy" class="form-check-label fw-bold"> As Per Embassy
                                        </label>
                                    </div>
                                </div>
                                <div class="row col-md-12" *ngIf="!subCatForm.value.as_per_embassy">
                                    <div class="col-md-6">
                                        <label class="col-form-label">Value</label>
                                        <input type="number" class="form-control" placeholder="Duration"
                                            formControlName="duration_value"
                                            [ngClass]="{'invalid': subCatForm.get('duration_value').invalid && isSubCatSubmitted}">
                                    </div>
                                    <div class="col-md-6">
                                        <label class="col-form-label">Unit</label>
                                        <select type="text" class="form-select" formControlName="duration_unit"
                                            [ngClass]="{'invalid': subCatForm.get('duration_unit').invalid && isSubCatSubmitted}">
                                            <option disabled value=null>Select Unit</option>
                                            <option value="Day">Days</option>
                                            <option value="Month">Months</option>
                                            <option value="Year">Years</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 row">
                            <div class="col-md-12 row shadow group-box">
                                <label class="col-md-6 col-form-label">Visa Validity</label>
                                <div class="col-md-6">
                                    <div class="form-check mt-3 float-end">
                                        <input type="checkbox" id="asPerEmbassyValidation" class="form-check-input"
                                            formControlName="validity_as_per_embassy"
                                            (change)="changeRequiredDuration(subCatForm.value.validity_as_per_embassy, false)">
                                        <label for="asPerEmbassyValidation" class="form-check-label fw-bold"> As Per
                                            Embassy </label>
                                    </div>
                                </div>
                                <div class="row col-md-12" *ngIf="!subCatForm.value.validity_as_per_embassy">
                                    <div class="col-md-6">
                                        <label class="col-form-label">Value</label>
                                        <input type="number" class="form-control" placeholder="Validity"
                                            formControlName="validity_value"
                                            [ngClass]="{'invalid': subCatForm.get('validity_value').invalid && isSubCatSubmitted}">
                                    </div>
                                    <div class="col-md-6">
                                        <label class="col-form-label">Unit</label>
                                        <select type="text" class="form-select" formControlName="validity_unit"
                                            [ngClass]="{'invalid': subCatForm.get('validity_unit').invalid && isSubCatSubmitted}">
                                            <option disabled value=null>Select Unit</option>
                                            <option value="Day">Days</option>
                                            <option value="Month">Months</option>
                                            <option value="Year">Years</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 row">
                            <div class="col-md-12 row shadow group-box">
                                <label class="col-md-12 col-form-label">Process Time</label>
                                <div class="col-md-4">
                                    <label class="col-form-label">Minimum</label>
                                    <input type="number" class="form-control" placeholder="Min"
                                    min="0"
                                        formControlName="processing_time_min"
                                        [ngClass]="{'invalid': subCatForm.get('processing_time_min').invalid && isSubCatSubmitted}">
                                </div>
                                <div class="col-md-4">
                                    <label class="col-form-label">Maximum</label>
                                    <input type="number" min="0" class="form-control" placeholder="Max"
                                        formControlName="processing_time_max"
                                        [ngClass]="{'invalid': subCatForm.get('processing_time_max').invalid && isSubCatSubmitted}">
                                </div>
                                <div class="col-md-4">
                                    <label class="col-form-label">Unit</label>
                                    <select type="text" class="form-select" formControlName="processing_time_unit"
                                        [ngClass]="{'invalid': subCatForm.get('processing_time_unit').invalid && isSubCatSubmitted}">
                                        <option disabled value=null>Select Unit</option>
                                        <option value="Hour">Hours</option>
                                        <option value="Day">Days</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 row">
                            <div class="col-md-12">
                                <button class="btn btn-primary mt-1 w-100" type="submit"
                                    [disabled]="isSubmittingSubCat || isUrlSubCat"><i class="uil-plus"></i>
                                    {{isSubCatEdit?'Update':'Add'}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-md-12" *ngIf="fromAdd">
            <button class="btn btn-success mt-1 float-end" type="submit" [disabled]="subCatList.length==0"
                (click)="gotoProducts()">
                Next <i class="uil-arrow-right"></i>
            </button>
        </div>
    </div>
</div> -->

<!-- <ng-template #mymodal let-modal>
    <div class="modal-header w-100"></div>
    <div class="modal-body text-center">
        <h4 class="modal-title" id="modal-basic-title">Delete {{isCat? 'Category' : 'Sub-Category'}}</h4>
        <div class="mt-3">Are you sure to delete this {{isCat? 'Category' : 'Sub-Category'}} ?</div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="modal.close('Save click')">Close</button>
        <button type="button" class="btn btn-danger" (click)="modal.close('Save click')">Ok</button>
    </div>
</ng-template> -->
<div class="container-fluid mt-4">
  <!-- start page title -->
  <!-- <app-page-title title="Countries" [breadcrumbItems]="breadCrumbItems"></app-page-title> -->

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2" *ngIf="!isSubcategory">
            <div class="col-md-3">
              <div id="tickets-table_filter" class="dataTables_filter">
                <div class="d-flex">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Search Category"
                    (keyup)="searchCountry(searchTerm)"
                    [(ngModel)]="searchTerm"
                  />
                  <div class="d-flex" autocomplete="off">
                    <a
                      href="javascript:void(0)"
                      class="my-auto mx-2"
                      (click)="searchTerm = ''; searchCountry(searchTerm)"
                    >
                      <i class="fa fa-times text-danger"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-9 text-end">
              <button
                type="button"
                (click)="changePosition()"
                class="btn btn-primary me-2"
              >
                Change Position
              </button>
              <button
                (click)="openAddorEditModal()"
                type="button"
                class="btn btn-success"
              >
                <i class="mdi mdi-plus me-1"></i> Add Category
              </button>
            </div>
          </div>
          <!-- Table -->
          <div class="table-responsive" *ngIf="!isSubcategory">
            <table
              id="basic-datatable"
              class="table table-centered datatables dt-responsive nowrap table-card-list"
            >
              <thead>
                <tr>
                  <th>Categories</th>
                  <!-- <th class="text-center">Jurisdiction</th> -->
                  <!-- <th class="text-center">Products</th> -->
                  <th class="text-center">Active</th>
                  <!-- <th class="text-center">Lead Form</th>
                                    <th class="text-center">Hotel</th>
                                    <th class="text-center">Flight</th>
                                    <th class="text-center">Insurance</th>
                                    <th class="text-center">Forex</th> -->
                  <th class="text-center">Courier</th>
                  <th class="text-center">Action</th>
                  <th class="text-center">Subcategories</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let category of categoryList; let i = index">
                  <td>
                    {{ category?.name }}
                  </td>
                  <!-- <td class="text-center">
                                        1
                                    </td>
                                    <td class="text-center">
                                        1
                                    </td> -->
                  <!-- <td class="text-center">
                                        <ngb-highlight>{{table.active}}</ngb-highlight>
                                    </td> -->
                  <!-- <td>
                                        <div class="d-flex">
                                            <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                                                <input type="checkbox"
                                                    
                                                    class="form-check-input" id="customSwitchsizelg"
                                                   
                                                   >
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex">
                                            <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                                                <input type="checkbox" class="form-check-input" id="lead_form_{{'0'}}"
                                                    >
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex">
                                            <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                                                <input type="checkbox" class="form-check-input" id="hotel{{'0'}}"
                                                   >
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex">
                                            <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                                                <input type="checkbox" class="form-check-input" id="hotel{{'0'}}"
                                                   >
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex">
                                            <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                                                <input type="checkbox" class="form-check-input" id="hotel{{'0'}}"
                                                   >
                                            </div>
                                        </div>
                                    </td> -->
                  <td>
                    <div class="d-flex">
                      <div
                        class="form-check form-switch form-switch-lg m-auto"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="hotel{{ i }}"
                          [disabled]="
                            !checkPermission(
                              'product_management_country_active'
                            )
                          "
                          [attr.checked]="category.display_status ? true : null"
                          (change)="changeStatus($event, category)"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div
                        class="form-check form-switch form-switch-lg m-auto"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="hotel{{ '0' }}"
                          [attr.checked]="category.courier_add ? true : null"
                          (change)="changeCourierStatus($event, category)"
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    class="text-center"
                    (click)="openAddorEditModal(category)"
                  >
                    <a class="cpr"><i class="fas fa-edit text-info"></i></a>
                  </td>
                  <td class="text-center">
                    <button class="btn btn-primary" (click)="enableSubCatTable(category)">Subcategories</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <app-sub-category [catId]="selectedCatId" *ngIf="isSubcategory" [subCategriesUnderCountry]="subCategriesUnderCountry"></app-sub-category>
          <!-- <div *ngIf="isLoading" class="d-flex mt-5">
            <i class="fa fa-spin fa-spinner fa-pulse fa-5x m-auto"></i>
          </div> -->
          <!-- End table -->
          <div
            class="row justify-content-md-between align-items-md-center mt-2"
          >
            <div class="col-sm-12 col-md-5">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                <!-- Showing
                                {{(pageNumber-1)*pageSize}} to
                                {{pageNumber*pageSize < page_details.count ? pageNumber*pageSize : page_details.count}}
                                    of {{page_details.count}} entries  -->
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-5">
              <div class="text-md-end float-md-end pagination-rounded">
                <ngb-pagination
                  [collectionSize]="page_details.count"
                  [(page)]="pageNumber"
                  [pageSize]="pageSize"
                  (pageChange)="nextPage(pageNumber)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, ElementRef, Input, Output, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { PermissionService } from "src/app/core/services/permision.service";
import { AddMessageComponent } from "./add-message/add-message.component";
import { NotificationTemplatesService } from "src/app/core/services/notification-templates.service";

@Component({
  selector: "app-message",
  templateUrl: "./message.component.html",
  styleUrls: ["./message.component.scss"],
})
export class MessageComponent {
  breadCrumbItems: Array<{}>;
  isLoading = false;
  isDeleting = false;
  isEdit = false;
  smsList: any = [];
  selectedMessageDel: any;
  MessageId: number;
  @ViewChild(AddMessageComponent) addMsg:AddMessageComponent;


  @ViewChild("messageDetails", { static: false }) messageModal: ElementRef;
  modalReference: any;
  constructor(
    private modalService: NgbModal,
    private api: NotificationTemplatesService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    public permission: PermissionService
  ) {}

  ngOnInit() {
    this.breadCrumbItems = [
      { label: "Settings" },
      { label: "Templates" },
      { label: "Messages", active: true },
    ];
    this.getDataList();
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  addMessageModal(data?) {
    const modalRef = this.modalService.open(AddMessageComponent, {
      size: "md",
      // scrollable: true,
      windowClass: "modal-small-md",
      backdrop: "static",
    });

    modalRef.componentInstance.fromParent = data ? data : null;

    modalRef.result.then((result) => {
      this.getDataList();
    },
      (reason) => {}
    );
  }


  getDataList() {
    this.api.getNotification().subscribe((response: any) => {
      this.smsList = response;
    });
  }

  deleteMessage(table){
    this.isDeleting = true;
    this.api.deleteNotification(table.id).subscribe(
      (result: any) => {
        this.isDeleting = false;
        this.modalService.dismissAll({ data: 'details' });
        this.toastr.success("Deleted Successfully!");
        this.getDataList();
      },
      error => {
        this.isDeleting = false;
      });
  }
}

import { Component } from "@angular/core";
import { SettingsService } from "src/app/core/services/settings.service";

@Component({
  selector: "app-arise-coupon",
  templateUrl: "./arise-coupon.component.html",
  styleUrls: ["./arise-coupon.component.scss"],
})
export class AriseCouponComponent {
  constructor(private settingsService: SettingsService) {}
  pagination = {
    offset: 0,
    index: 1,
    count:0
  };
  couponList: any=[];
  offset: number = 30;
  generateCoupon() {
    this.settingsService.ariseCouponGenerate().subscribe((res: any) => {
      this.getCouponList();
    });
  }

  getCouponList(type?: string) {
    if (type) {
      this.pagination.index++;
    }

   
    this.settingsService
      .getCouponList(this.pagination.offset)
      .subscribe((res: any) => {
        this.pagination.offset=this.pagination.offset+30;
        this.pagination.count=res?.count
        this.couponList =[...this.couponList,... res?.results];
      });
  }
  

}

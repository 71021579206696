import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AccessManagementService } from "src/app/core/services/access-management.service";
import { SharedService } from "src/app/core/services/shared.service";

@Component({
  selector: "app-permission-item",
  templateUrl: "./permission-item.component.html",
  styleUrls: ["./permission-item.component.scss"],
})
export class PermissionItemComponent implements OnInit {
  @Output() onResultFetched = new EventEmitter<any>();
  @Input() hidePortionForm: boolean = true;

  permisionForm: FormGroup;
  hasChanges = false;
  isUpdating = false;
  show_assigned = false;
  show_individual = false;
  permissionList: any = [];
  isEdit = false;
  selectedRoleId: number;
  @Input() tab;
  @Input() fromRoles;

  constructor(
    private fb: FormBuilder,
    private api: AccessManagementService,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef,
    private sharedService: SharedService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    if (this.fromRoles) this.resetForm(this.fromRoles.user_permission);
    else this.resetForm();
    this.getDataList();
  }

  resetForm(data?) {
    this.permissionList = data ? data : [];
    if (this.tab == "roles") {
      if (this.permissionList?.id) this.isEdit = true;
      else this.isEdit = false;
    } else if (this.tab == "user") {
      if (this.fromRoles?.user_permission?.id) this.isEdit = true;
      else this.isEdit = false;
    }
    this.permisionForm = this.fb.group({
      id: [data ? data.id : null],
      role_name: [data ? data.role_name : ""],
      description: [data ? data.description : ""],
      created_on: [data ? data.created_on : ""],
      updated_on: [data ? data.updated_on : ""],

      content_management_home_publish: [
        data ? data.content_management_home_publish : false,
      ],
      content_management_home_create: [
        data ? data.content_management_home_create : false,
      ],
      content_management_home_edit: [
        data ? data.content_management_home_edit : false,
      ],
      content_management_home_view: [
        data ? data.content_management_home_view : false,
      ],
      content_management_home_delete: [
        data ? data.content_management_home_delete : false,
      ],

      content_management_country_publish: [
        data ? data.content_management_country_publish : false,
      ],
      content_management_country_create: [
        data ? data.content_management_country_create : false,
      ],
      content_management_country_edit: [
        data ? data.content_management_country_edit : false,
      ],
      content_management_country_view: [
        data ? data.content_management_country_view : false,
      ],
      content_management_country_delete: [
        data ? data.content_management_country_delete : false,
      ],

      content_management_category_publish: [
        data ? data.content_management_category_publish : false,
      ],
      content_management_category_create: [
        data ? data.content_management_category_create : false,
      ],
      content_management_category_edit: [
        data ? data.content_management_category_edit : false,
      ],
      content_management_category_view: [
        data ? data.content_management_category_view : false,
      ],
      content_management_category_delete: [
        data ? data.content_management_category_delete : false,
      ],

      content_management_product_publish: [
        data ? data.content_management_product_publish : false,
      ],
      content_management_product_create: [
        data ? data.content_management_product_create : false,
      ],
      content_management_product_edit: [
        data ? data.content_management_product_edit : false,
      ],
      content_management_product_view: [
        data ? data.content_management_product_view : false,
      ],
      content_management_product_delete: [
        data ? data.content_management_product_delete : false,
      ],

      content_management_consultant_publish: [
        data ? data.content_management_consultant_publish : false,
      ],
      content_management_consultant_create: [
        data ? data.content_management_consultant_create : false,
      ],
      content_management_consultant_edit: [
        data ? data.content_management_consultant_edit : false,
      ],
      content_management_consultant_view: [
        data ? data.content_management_consultant_view : false,
      ],
      content_management_consultant_delete: [
        data ? data.content_management_consultant_delete : false,
      ],

      content_management_about_us_publish: [
        data ? data.content_management_about_us_publish : false,
      ],
      content_management_about_us_create: [
        data ? data.content_management_about_us_create : false,
      ],
      content_management_about_us_edit: [
        data ? data.content_management_about_us_edit : false,
      ],
      content_management_about_us_view: [
        data ? data.content_management_about_us_view : false,
      ],
      content_management_about_us_delete: [
        data ? data.content_management_about_us_delete : false,
      ],

      content_management_testimonial_publish: [
        data ? data.content_management_testimonial_publish : false,
      ],
      content_management_testimonial_create: [
        data ? data.content_management_testimonial_create : false,
      ],
      content_management_testimonial_edit: [
        data ? data.content_management_testimonial_edit : false,
      ],
      content_management_testimonial_view: [
        data ? data.content_management_testimonial_view : false,
      ],
      content_management_testimonial_delete: [
        data ? data.content_management_testimonial_delete : false,
      ],

      content_management_faq_publish: [
        data ? data.content_management_faq_publish : false,
      ],
      content_management_faq_create: [
        data ? data.content_management_faq_create : false,
      ],
      content_management_faq_edit: [
        data ? data.content_management_faq_edit : false,
      ],
      content_management_faq_view: [
        data ? data.content_management_faq_view : false,
      ],
      content_management_faq_delete: [
        data ? data.content_management_faq_delete : false,
      ],

      content_management_seo_publish: [
        data ? data.content_management_seo_publish : false,
      ],
      content_management_seo_create: [
        data ? data.content_management_seo_create : false,
      ],
      content_management_seo_edit: [
        data ? data.content_management_seo_edit : false,
      ],
      content_management_seo_view: [
        data ? data.content_management_seo_view : false,
      ],
      content_management_seo_delete: [
        data ? data.content_management_seo_delete : false,
      ],
      content_management_news_publish:[data ? data.content_management_news_publish:false],
      content_management_news_view:[data ? data.content_management_news_view:false],
      content_management_news_edit:[data ? data.content_management_news_edit:false],
      content_management_news_create:[data ?data.content_management_news_create:false],
      content_management_blogs_publish:[data ?data.content_management_blogs_publish:false],
      content_management_blogs_view:[data ?data.content_management_blogs_view:false],
      content_management_blogs_edit:[data ?data.content_management_blogs_edit:false],
      content_management_blogs_create:[data ?data.content_management_blogs_create:false],
      general_privacy_policy_publish: [
        data ? data.general_privacy_policy_publish : false,
      ],
      general_privacy_policy_create: [
        data ? data.general_privacy_policy_create : false,
      ],
      general_privacy_policy_edit: [
        data ? data.general_privacy_policy_edit : false,
      ],
      general_privacy_policy_view: [
        data ? data.general_privacy_policy_view : false,
      ],

      general_terms_and_condition_publish: [
        data ? data.general_terms_and_condition_publish : false,
      ],
      general_terms_and_condition_create: [
        data ? data.general_terms_and_condition_create : false,
      ],
      general_terms_and_condition_edit: [
        data ? data.general_terms_and_condition_edit : false,
      ],
      general_terms_and_condition_view: [
        data ? data.general_terms_and_condition_view : false,
      ],

      general_country_keywords_publish: [
        data ? data.general_country_keywords_publish : false,
      ],
      general_country_keywords_create: [
        data ? data.general_country_keywords_create : false,
      ],
      general_country_keywords_edit: [
        data ? data.general_country_keywords_edit : false,
      ],
      general_country_keywords_view: [
        data ? data.general_country_keywords_view : false,
      ],

      general_footer_section_publish: [
        data ? data.general_footer_section_publish : false,
      ],
      general_footer_section_create: [
        data ? data.general_footer_section_create : false,
      ],
      general_footer_section_edit: [
        data ? data.general_footer_section_edit : false,
      ],
      general_footer_section_view: [
        data ? data.general_footer_section_view : false,
      ],

      product_management_country_create: [
        data ? data.product_management_country_create : false,
      ],
      product_management_country_edit: [
        data ? data.product_management_country_edit : false,
      ],
      product_management_country_view: [
        data ? data.product_management_country_view : false,
      ],
      product_management_country_delete: [
        data ? data.product_management_country_delete : false,
      ],
      product_management_country_active: [
        data ? data.product_management_country_active : false,
      ],

      product_management_jurisdiction_create: [
        data ? data.product_management_jurisdiction_create : false,
      ],
      product_management_jurisdiction_edit: [
        data ? data.product_management_jurisdiction_edit : false,
      ],
      product_management_jurisdiction_view: [
        data ? data.product_management_jurisdiction_view : false,
      ],
      product_management_jurisdiction_delete: [
        data ? data.product_management_jurisdiction_delete : false,
      ],

      product_management_age_group_create: [
        data ? data.product_management_age_group_create : false,
      ],
      product_management_age_group_edit: [
        data ? data.product_management_age_group_edit : false,
      ],
      product_management_age_group_view: [
        data ? data.product_management_age_group_view : false,
      ],
      product_management_age_group_delete: [
        data ? data.product_management_age_group_delete : false,
      ],

      product_management_category_create: [
        data ? data.product_management_category_create : false,
      ],
      product_management_category_edit: [
        data ? data.product_management_category_edit : false,
      ],
      product_management_category_view: [
        data ? data.product_management_category_view : false,
      ],
      product_management_category_delete: [
        data ? data.product_management_category_delete : false,
      ],

      product_management_product_create: [
        data ? data.product_management_product_create : false,
      ],
      product_management_product_edit: [
        data ? data.product_management_product_edit : false,
      ],
      product_management_product_view: [
        data ? data.product_management_product_view : false,
      ],
      product_management_product_delete: [
        data ? data.product_management_product_delete : false,
      ],

      product_management_required_docs_create: [
        data ? data.product_management_required_docs_create : false,
      ],
      product_management_required_docs_edit: [
        data ? data.product_management_required_docs_edit : false,
      ],
      product_management_required_docs_view: [
        data ? data.product_management_required_docs_view : false,
      ],
      product_management_required_docs_delete: [
        data ? data.product_management_required_docs_delete : false,
      ],

      dashboard_admin_view: [data ? data.dashboard_admin_view : false],
      dashboard_my_dashboard_view: [
        data ? data.dashboard_my_dashboard_view : false,
      ],
      dashboard_finance_view: [data ? data.dashboard_finance_view : false],
      dashboard_performance_view: [
        data ? data.dashboard_performance_view : false,
      ],

      visa_applications_view: [data ? data.visa_applications_view : false],
      visa_applications_edit: [data ? data.visa_applications_edit : false],

      customer_360_view: [data ? data.customer_360_view : false],
      customer_360_edit: [data ? data.customer_360_edit : false],
      customer_360_show_assigned: [
        data ? data.customer_360_show_assigned : false,
      ],
      customer_360_show_all: [data ? data.customer_360_show_all : false],

      employee_360_view: [data ? data.employee_360_view : false],
      employee_360_edit: [data ? data.employee_360_edit : false],

      logs_view: [data ? data.logs_view : false],
      logs_show_all: [data ? data.logs_show_all : false],
      logs_show_individual: [data ? data.logs_show_individual : false],

      settings_access_management_view: [
        data ? data.settings_access_management_view : false,
      ],
      settings_access_management_edit: [
        data ? data.settings_access_management_edit : false,
      ],
      settings_access_management_create: [
        data ? data.settings_access_management_create : false,
      ],

      settings_workflow_view: [
        data ? data.settings_workflow_view : false,
      ],
      settings_workflow_edit: [
        data ? data.settings_workflow_edit : false,
      ],
      settings_workflow_create: [
        data ? data.settings_workflow_create : false,
      ],

      settings_master_form_view: [
        data ? data.settings_master_form_view : false,
      ],
      settings_master_form_edit: [
        data ? data.settings_master_form_edit : false,
      ],
      settings_master_form_create: [
        data ? data.settings_master_form_create : false,
      ],

      settings_templates_view: [data ? data.settings_templates_view : false],
      settings_templates_edit: [data ? data.settings_templates_edit : false],
      settings_templates_create: [
        data ? data.settings_templates_create : false,
      ],

      settings_integrations_view: [
        data ? data.settings_integrations_view : false,
      ],
      settings_integrations_edit: [
        data ? data.settings_integrations_edit : false,
      ],
      settings_integrations_create: [
        data ? data.settings_integrations_create : false,
      ],
      settings_reports_view:[data?data.settings_reports_view:false],
      workflow_bypass:[data?data?.workflow_bypass:false]
    });
    this.onChanges();
  }

  onChanges(): void {
    this.permisionForm.valueChanges.subscribe((val) => {
      Object.keys(this.permisionForm.controls).map((key) => {
        if (this.permisionForm.get(key).dirty) this.hasChanges = true;
      });
    });
  }

  closeModal() {
    this.activeModal.close("close");
  }

  onSubmit(form) {
    let tableData: any = this.sharedService.selectedUserPermissionData;
    if (form.valid) {
      if (this.tab == "roles") {
        if (this.isEdit) {
          this.selectedRoleId = this.permissionList.id;
          this.api
            .editUserRoleDetails(this.selectedRoleId, form.value)
            .subscribe(
              (result: any) => {
                this.permissionList.forEach((ae, i) => {
                  if (ae.id == this.selectedRoleId)
                    this.permissionList[i] = result;
                });
                this.toastr.success("Permission Updated Successfully!");
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          this.api.setUserRoleDetails(form.value).subscribe(
            (result: any) => {
              this.toastr.success("Permission Added Successfully!");
            },
            (error) => {
              console.log(error);
            }
          );
        }
      } else if (this.tab == "user") {
        let userid: any = this.sharedService.selectedUserPermissionData;
        if (this.isEdit) {
          this.selectedRoleId = this.fromRoles.user_permission.id;
          form.value.user = this.fromRoles?.id ? this.fromRoles?.id : userid.id;
          form.value.role_name = this.fromRoles.role_name;
          this.api
            .editUserRolePermission(this.selectedRoleId, form.value)
            .subscribe(
              (result: any) => {
                this.permissionList.forEach((ae, i) => {
                  if (ae.id == this.selectedRoleId)
                    this.permissionList[i] = result;
                });
                this.closeModal();
                this.toastr.success("User Permission Updated Successfully!");
              },
              (error) => {
                console.log(error);
              }
            );
        } else {
          console.log(this.fromRoles);

          form.value.user = this.fromRoles?.id ? this.fromRoles?.id : userid.id;
          this.api.setUserRolePermission(form.value).subscribe(
            (result: any) => {
              this.closeModal();
              this.toastr.success("User Permission Added Successfully!");
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    } else {
      this.toastr.warning("Fill all the fields to continue!");
    }
  }

  getDataList() {
    this.api.getUserPermissionDetails().subscribe(
      (result: any) => {
        this.permissionList = result;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}

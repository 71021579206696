import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'radio',
  template: `
      <div [formGroup]="form" class="d-flex flex-wrap position-relative" *ngIf="field.options">
        <div class="disable_handler" *ngIf="field?.disabled"></div>
        <div class="form-check me-3" *ngFor="let opt of field.options">
          <label class="form-check-label pointer">
             <input [formControlName]="field?.name" [name]="field?.name" class="form-check-input me-2" type="radio" [value]="opt?.answer_choice" (change)="radioOptionChanged(opt)"/>
             <!-- ngModelChange -->
             {{opt?.answer_choice}}
            </label>
        </div>
      </div> 
    `
})
export class RadioComponent implements OnInit, AfterViewInit {
  @Input() field: any = {};
  @Input() form!: FormGroup;

  constructor() {

  }

  ngOnInit(): void {

    // if (this.field.disabled) {
    //   this.form.controls[this.field?.name].disable();
    // } else {
    //   this.form.controls[this.field?.name].enable();
    // }
  }

  ngAfterViewInit(): void {
    if (this.field?.answer)
      this.valueInitailization();

    this.optionalQueinitisalizer()
  }

  valueInitailization() {
    setTimeout(() => {
      this.form.controls[this.field?.name].setValue(this.field?.answer);
    }, 300);

  }


  optionalQueinitisalizer() {
    for (let opt of this.field.options) {
      if (opt.answer_choice == this.field?.answer) {
        setTimeout(() => {
          this.radioOptionChanged(opt);
        }, 300);
        break;
      }
    }
  }

  radioOptionChanged(curData: any) {
    var event = new CustomEvent('triggerNewQue', { detail: curData });
    document.dispatchEvent(event);
  }




}
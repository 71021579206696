import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { UIModule } from '../../shared/ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { VisaProcessComponent } from './visa-process/visa-process.component';
import { ApplicationDetailsComponent } from './application-details/application-details.component';
import { TravellerInfoComponent } from './application-details/traveller-info/traveller-info.component';
import { GeneralDetailsComponent } from './application-details/general-details/general-details.component';
import { ReviewQuestionsComponent } from './review-questions/review-questions.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { QuestionComponent } from './review-questions/question-section/question-section.component';
import { QuestionsComponent } from './review-questions/questions/questions.component';
import { VisaFlowComponent } from './visa-flow/visa-flow.component';
import { ConfirmModalComponent } from './visa-flow/modals/confirm-modal/confirm-modal.component';
import { UploadModalComponent } from './visa-flow/modals/upload-modal/upload-modal.component';
import { VerifyModalComponent } from './visa-flow/modals/verify-modal/verify-modal.component';
import { DataModalComponent } from './visa-flow/modals/data-modal/data-modal.component';
import { ScheduleModalComponent } from './visa-flow/modals/schedule-modal/schedule-modal.component';

import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NotificationsListComponent } from './visa-flow/modals/notifications-list/notifications-list.component';
import { UserFormComponent } from './application-details/traveller-info/user-form/user-form.component';
import { PassportDetailsComponent } from './application-details/traveller-info/passport-details/passport-details.component';
import { CoverLetterModalComponent } from './visa-flow/modals/cover-letter-modal/cover-letter-modal.component';
import { DynamicFormBuilderModule } from './application-details/traveller-info/dynamic-form-builder/dynamic-form-builder.module';
import { FilterModalComponent } from './modal/filter-modal/filter-modal.component';
import { OutsideClickDirective } from 'src/app/core/directives/inside-click.directive';
import { DiyComponent } from '../../pages/visa-management/diy/diy.component';
import { CoreModule } from 'src/app/core/core.module';
import { UserVerificationModalComponent } from './visa-flow/modals/user-verification-modal/user-verification-modal.component';

const routes: Routes = [
  { path: 'process', component: VisaProcessComponent },
  { path: 'application/:id', component: ApplicationDetailsComponent },
  { path: 'questions', component: ReviewQuestionsComponent },
  { path: 'visa-flow/:id', component: VisaFlowComponent },
  {path:'diy',component:DiyComponent}
];

@NgModule({
  declarations: [
    VisaProcessComponent,
    ApplicationDetailsComponent,
    TravellerInfoComponent,
    GeneralDetailsComponent,
    ReviewQuestionsComponent,
    QuestionComponent,
    QuestionsComponent,
    VisaFlowComponent,
    ConfirmModalComponent,
    UploadModalComponent,
    VerifyModalComponent,
    DataModalComponent,
    ScheduleModalComponent,
    UserFormComponent,
    NotificationsListComponent,
    PassportDetailsComponent,
    CoverLetterModalComponent,
    FilterModalComponent,
    OutsideClickDirective,
    DiyComponent,
    UserVerificationModalComponent
  ],
  imports: [
    CommonModule,
    UIModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgSelectModule,
    NgbAccordionModule,
    CoreModule,
    Ng2FlatpickrModule,
    DynamicFormBuilderModule,
    FlatpickrModule.forRoot(),
    NgbTooltipModule,
    RouterModule.forChild(routes),
  ]
})
export class VisaManagementModule { }

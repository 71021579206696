import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { CountryManagementService } from 'src/app/core/services/country-management.service';
import { CategoryService } from 'src/app/core/services/category.service';
import { ProductPageService } from 'src/app/core/services/product-page.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';

@Component({
  selector: 'app-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.scss']
})
export class VouchersComponent implements OnInit {

  public Editor = ClassicEditor;
  voucherForm: FormGroup;
  hasChanges = false;

  isUpdating = false;
  deletedvouchers = [];

  @Input() country;
  @Input() category;
  @Input() product;
  @Input() page;

  constructor(private fb: FormBuilder, private api: CountryManagementService,
    private toastr: ToastrService,private modalService: NgbModal,
    private catService: CategoryService, private proService: ProductPageService,
    public permission: PermissionService) { }

  ngOnInit(): void {
    this.resetForm();
    this.getUspvouchers();
  }

  resetForm() {
    this.voucherForm = this.fb.group({
      vouchers: this.fb.array([
        this.fb.group({
          'image_url': ['', [Validators.required]],
          'heading': ['', [Validators.required]],
          'sub_heading': ['-'],
          'description': ['', [Validators.required]],
          'is_updated': [''],
        })])
    });
  }

  getUspvouchers() {
    if (this.page == 'country') {
      this.api.getVoucher(this.country).subscribe(
        (result: any) => {
          this.setVouchers(result);
        })
    } else if (this.page == 'category') {
      this.catService.getVoucher(this.category).subscribe(
        (result: any) => {
          this.setVouchers(result);
        })
    } else if (this.page == 'product') {
      this.proService.getVoucher(this.product).subscribe(
        (result: any) => {
          this.setVouchers(result);
        })
    }
  }

  setVouchers(result) {
    this.voucherForm = this.fb.group({
      vouchers: new FormArray([]),
    });
    result.forEach(ao => {
      (this.voucherForm.get('vouchers') as FormArray).push(
        this.fb.group({
          id: [ao.id],
          image_url: [ao.image_url, [Validators.required]],
          heading: [ao.heading, [Validators.required]],
          sub_heading: ['-'],
          description: [ao.description, [Validators.required]],
          is_updated: [false],
        })
      );
      this.deletedvouchers = [];
    });
  }

  vouchers(): FormArray {
    return this.voucherForm.get("vouchers") as FormArray
  }

  newvouchers(): FormGroup {
    return this.fb.group({
      image_url: ['', [Validators.required]],
      heading: ['', [Validators.required]],
      sub_heading: ['-'],
      description: ['', [Validators.required]],
      is_updated: [true],
    })
  }

  addvouchers() {
    this.vouchers().push(this.newvouchers());
  }

  removevouchers(i: number) {
    this.voucherForm.value.vouchers[i].is_deleted = true;
    if (this.voucherForm.value.vouchers[i].hasOwnProperty("id")) this.deletedvouchers.push(this.voucherForm.value.vouchers[i]);
    this.vouchers().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedvouchers.forEach(ae => {
        ae.is_updated = false;
        form.value.vouchers.push(ae);
      })
      if (this.page == 'country') {
        form.value.vouchers.forEach(ae => {
          ae.country = this.country;
        });
        this.api.setVoucher(form.value.vouchers).subscribe(
          () => {
            this.callOnSuccess();
          },
          () => {
            this.isUpdating = false;
          }
        )
      } else if (this.page == 'category') {
        form.value.vouchers.forEach(ae => {
          ae.category = this.category;
        });
        this.catService.setVoucher(form.value.vouchers).subscribe(
          () => {
            this.callOnSuccess();
          },
          () => {
            this.isUpdating = false;
          }
        )
      } else if (this.page == 'product') {
        form.value.vouchers.forEach(ae => {
          ae.product = this.product;
        });
        this.proService.setVoucher(form.value.vouchers).subscribe(
          () => {
            this.callOnSuccess();
          },
          () => {
            this.isUpdating = false;
          }
        )
      }
    } else {
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  callOnSuccess() {
    this.toastr.success("Content Updated Successfully!");
    this.getUspvouchers();
    this.hasChanges = false;
    this.isUpdating = false;
  }

  dataChanged(i) {
    this.voucherForm.value.vouchers[i].is_updated = true;
  }
  
  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  openUploadModal(i, field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if(result.status) {
        this.voucherForm.get(['vouchers', i, field]).setValue(result.image);
        this.voucherForm.get(['vouchers', i, 'is_updated']).setValue(true);
      }
    });
}
}

<div class="border shadow p-4">
    <form [formGroup]="permisionForm" (ngSubmit)="onSubmit(permisionForm)">
        <div class="rle_dtls_tp" *ngIf="hidePortionForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="role_name">
                        <input type="text" class="form-control" placeholder="New Role" formControlName="role_name">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="role_update">
                        Updated On : <span>{{permissionList?.updated_on*1000 | date:'medium' }}</span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="role_description">
                        Description
                    </div>
                    <div class="rol_dscpn_dtls">
                        <input class="w-100" class="form-control" formControlName="description"
                            placeholder="Info on roles to be shown in as info in users page">
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mb-3">
                <label>
                    Dashboard
                </label>
                <div class="d-flex flex-wrap align-items-center ms-5">
                    <div class="d-flex align-items-center me-5">
                        <div class="font_weight me-3">
                            Admin
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="dashboard_admin_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-5">
                        <div class="font_weight me-3">
                            My Dashboard
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="dashboard_my_dashboard_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-5">
                        <div class="font_weight me-3">
                            Finance
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="dashboard_finance_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-5">
                        <div class="font_weight me-3">
                            Performance
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="dashboard_performance_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="font_weight me-3">
                                Workflow by pass
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="workflow_bypass">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-3">
                <label>
                    Visa Application
                </label>
                <div class="d-flex align-items-center ms-5">
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="visa_applications_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="visa_applications_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-12 mb-3">
                <label>
                    Customer 360
                </label>
                <div class="d-flex align-items-center ms-5">
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="customer_360_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="customer_360_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="font_weight">
                            Show All
                        </div>
                        <div class="ms-3">
                            <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                                <input type="checkbox" class="form-check-input"
                                    formControlName="customer_360_show_assigned" id="customSwitchsizeTwo">
                            </div>
                        </div>
                        <div class="font_weight">
                            Show Assigned
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-3">
                <label>
                    Employee 360
                </label>
                <div class="d-flex align-items-center ms-5">
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="employee_360_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="employee_360_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="col-md-12 mb-3">
                <label>
                    Logs
                </label>
                <div class="d-flex align-items-center ms-5">
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="logs_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>

                    <div class="d-flex align-items-center me-4">
                        <div class="font_weight">
                            Show All
                        </div>
                        <div class="ms-3">
                            <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                                <input type="checkbox" class="form-check-input" formControlName="logs_show_individual"
                                    id="customSwitchsizeOne">
                            </div>
                        </div>
                        <div class="font_weight">
                            Show Individual
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-3">
                <label>
                    Content Management
                </label>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Home
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_home_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_home_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_home_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_home_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Country
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_country_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_country_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_country_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_country_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Category
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_category_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_category_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_category_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_category_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Product
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_product_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_product_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_product_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_product_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Consultant
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_consultant_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_consultant_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_consultant_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_consultant_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            About Us
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_about_us_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_about_us_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_about_us_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_about_us_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Testimonial
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_testimonial_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_testimonial_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_testimonial_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_testimonial_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Faq
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_faq_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_faq_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_faq_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_faq_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Seo
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_seo_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_seo_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_seo_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_seo_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Privacy Policy
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_privacy_policy_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_privacy_policy_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_privacy_policy_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_privacy_policy_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Terms and Conditions
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_terms_and_condition_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_terms_and_condition_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_terms_and_condition_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_terms_and_condition_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>

                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Country Keyword
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_country_keywords_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_country_keywords_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_country_keywords_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_country_keywords_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Footer
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_footer_section_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_footer_section_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_footer_section_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="general_footer_section_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            News
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_news_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_news_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_news_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_news_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Blogs
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_blogs_publish">
                        </div>
                        <div>
                            Publish
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_blogs_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_blogs_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="content_management_blogs_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-3">
                <label>
                    Product Management
                </label>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Country
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_country_active">
                        </div>
                        <div>
                            Active
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_country_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_country_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_country_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Jurisdiction
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_jurisdiction_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_jurisdiction_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_jurisdiction_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Age Group
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_age_group_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_age_group_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_age_group_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Category
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_category_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_category_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_category_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Product
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_product_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_product_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_product_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Required Docs
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_required_docs_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_required_docs_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="product_management_required_docs_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mb-3">
                <label>
                    Settings
                </label>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Reports
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_reports_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <!-- <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="reports_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div> -->
                    <!-- <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="reports_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div> -->
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Access Management
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_access_management_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_access_management_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_access_management_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Master Form
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_master_form_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_master_form_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_master_form_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Work Flow
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_workflow_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_workflow_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_workflow_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Templates
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_templates_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_templates_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_templates_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center ms-5 mb-2">
                    <div class="d-flex align-items-center me-4">
                        <div class="width_150 font_weight me-3">
                            Integration
                        </div>
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_integrations_view">
                        </div>
                        <div>
                            View
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_integrations_edit">
                        </div>
                        <div>
                            Modify
                        </div>
                    </div>
                    <div class="d-flex align-items-center me-4">
                        <div class="me-2">
                            <input type="checkbox" formControlName="settings_integrations_create">
                        </div>
                        <div>
                            Add
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-success">Save</button>
                </div>
            </div>
        </div>
    </form>
</div>
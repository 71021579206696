<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-12">
                        <button type="button" class="btn btn-success float-end" (click)="addUserModal()"><i
                                class="mdi mdi-plus me-1"></i>
                            Add User</button>
                    </div>
                </div>
                <!-- Table -->
                <div class="table-responsive" *ngIf="!isLoading">
                    <table id="basic-datatable"
                        class="table table-centered datatables dt-responsive nowrap table-card-list">
                        <thead>
                            <tr>
                                <th class="text-center">Name</th>
                                <th class="text-center">E-mail</th>
                                <th class="text-center">Phone</th>
                                <th class="text-center">Role</th>
                                <th class="text-center">Last Modified</th>
                                <th class="text-center">Active</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let table of userList;let i=index">
                            <tr>
                                <td class="text-center">
                                    <ngb-highlight>{{table.firstname +' '+table.lastname}}</ngb-highlight>
                                </td>
                                <td class="text-center">
                                    <ngb-highlight>{{table.email}}</ngb-highlight>
                                </td>
                                <td class="text-center">
                                    <ngb-highlight>{{table.phone_no}}</ngb-highlight>
                                </td>
                                <td class="text-center">
                                    <ngb-highlight>{{table.role_name}}</ngb-highlight>
                                </td>
                                <td class="text-center">
                                    <ngb-highlight>{{table?.updated_on*1000 | date:'medium' }}</ngb-highlight>
                                </td>
                                <td class="d-flex">
                                    <div class="form-check form-switch form-switch-lg m-auto" dir="ltr">
                                        <input type="checkbox" class="form-check-input" id="customSwitchsizelg"
                                            [attr.checked]="table.is_active ? true : null"
                                            (change)="changeActiveStatus($event,table)">
                                    </div>
                                    <div class="d-grid m-1">
                                        <a class="cpr" (click)="editUser(table)"><i
                                                class="far fa-edit text-primary"></i></a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="isLoading" class="d-flex mt-5">
                    <i class="fa fa-spin fa-spinner fa-pulse fa-5x m-auto"></i>
                </div>
                <!-- End table -->
                <div class="row justify-content-md-between align-items-md-center mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                            Showing
                            {{(pageNumber-1)*pageSize + 1}} to
                            {{pageNumber*pageSize < page_details.count ? pageNumber*pageSize : page_details.count}}
                                of {{page_details.count}} entries </div>
                        </div>
                        <!-- Pagination -->
                        <div class="col-sm-12 col-md-5">
                            <div class="text-md-end float-md-end pagination-rounded">
                                <ngb-pagination [collectionSize]="page_details.count" [(page)]="pageNumber"
                                    [pageSize]="pageSize" (pageChange)="nextPage(pageNumber);">
                                </ngb-pagination>
                            </div>
                        </div>
                        <!-- End Pagination -->
                    </div>
                </div>
            </div>
        </div>
    </div>
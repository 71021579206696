import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VisaManageService } from 'src/app/core/services/visa-manage.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-cover-letter-modal',
  templateUrl: './cover-letter-modal.component.html',
  styleUrls: ['./cover-letter-modal.component.scss']
})
export class CoverLetterModalComponent {

  @Input() coverDetails;
  selectedCover:any;
  isConfirming = false;
  coverData:any;

  constructor(public activeModal: NgbActiveModal, public api: VisaManageService, public toastr: ToastrService){}

  closeModal(status) {
    this.activeModal.close(status);
  }

  onChange(selectedCover){
    this.api.getCoverLetterConversion(this.coverDetails.pax_id, selectedCover).subscribe(
      result=>{
        console.log(result);
        this.coverData = result; 
      },
      ()=>{
        this.toastr.error("Try Again!")
      }
    )
  }

  generateAndDownloadTxtFile() {
    this.generateTxtFile(this.coverData, `stp_${this.getCoverName(this.selectedCover)}`);
  }

  
  generateTxtFile(data: any, filename: string) {
    const coverLetter = `
    Date: ${this.getCurrentDateFormatted()}

    From,
    ${data.from}
    
    To,
    ${data.to}

    Sub: ${data.subject}
    
    ${data.body}
    `;

    const blob = new Blob([coverLetter], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename + '.txt';
    anchor.click();
    window.URL.revokeObjectURL(url);
  }

  getCurrentDateFormatted() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  getCoverName(id){
    return this.coverDetails.response.filter(ae=>ae.id==id)[0].template_name;
  }

}

<div class="modal-header">
    <h5 class="modal-title w-100 text-center">Enter Details</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)">
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="form-group d-flex my-2 px-5">
            <input class="form-control" [(ngModel)]="dataDetails.key" disabled placeholder="Key">
            <span class="arrow-i m-auto"><i class="mdi mdi-arrow-right"></i></span>
            <input class="form-control" [(ngModel)]="data_value" placeholder="Value">
        </div>
    </div>
</div>
<div class="modal-footer text-end">
    <button class="btn btn-success me-2" (click)="onConfirm()"><i class="fa" [ngClass]="{'fa-spin fa-spinner fa-pulse': isConfirming, 'fa-check': !isConfirming}"></i> Submit</button>
    <button class="btn btn-secondary" (click)="closeModal(false)"><i class="fa fa-times"></i> Cancel</button>
</div>
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { HomeManagementService } from 'src/app/core/services/home-management.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";


@Component({
  selector: 'app-visa-category',
  templateUrl: './visa-category.component.html',
  styleUrls: ['./visa-category.component.scss']
})
export class VisaCategoryComponent implements OnInit {

  public Editor = ClassicEditor;
  visaCatForm: FormGroup;
  hasChanges = false;

  isUpdating = false;
  deletedcategory = [];
  isSubmitted = false;
  publishPage: string;
  @Input() page;


  constructor(private fb: FormBuilder, public permission: PermissionService, private api: HomeManagementService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.resetForm();
    this.getApplicationcategory();
  }

  resetForm() {
    this.visaCatForm = this.fb.group({
      category: this.fb.array([
        this.fb.group({
          id: ['', [Validators.required]],
          name: ['', [Validators.required]],
          description: ['', [Validators.required]],
          is_updated: ['', [Validators.required]],
        })])
    });
  }

  getApplicationcategory() {
    this.api.getVisaCategory().subscribe(
      (result: any) => {
        this.visaCatForm = this.fb.group({
          category: new FormArray([]),
        });
        result.forEach(ao => {
          (this.visaCatForm.get('category') as FormArray).push(
            this.fb.group({
              id: [ao.id, [Validators.required]],
              description: [ao.description, [Validators.required]],
              name: [ao.name, [Validators.required]],
              is_updated: [false, [Validators.required]],
            })
          );
          this.deletedcategory = [];
        });
      })
  }

  category(): FormArray {
    return this.visaCatForm.get("category") as FormArray
  }

  newcategory(): FormGroup {
    return this.fb.group({
      description: ['', [Validators.required]],
      name: ['', [Validators.required]],
      is_updated: [true, [Validators.required]],
    })
  }

  addcategory() {
    this.category().push(this.newcategory());
  }

  removecategory(i: number) {
    this.isSubmitted = false;
    this.visaCatForm.value.category[i].is_deleted = true;
    if (this.visaCatForm.value.category[i].hasOwnProperty("id")) this.deletedcategory.push(this.visaCatForm.value.category[i]);
    this.category().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedcategory.forEach(ae => {
        ae.is_updated = false;
        form.value.category.push(ae);
      })
      this.api.setVisaCategory(form.value.category).subscribe(
        () => {
          this.toastr.success("Content Updated Successfully!");
          this.getApplicationcategory();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        }
      )
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  dataChanged(i) {
    this.visaCatForm.value.category[i].is_updated = true;
  }
  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

}

import {  Component, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-general-details",
  templateUrl: "./general-details.component.html",
  styleUrls: ["./general-details.component.scss"],
})
export class GeneralDetailsComponent {
  @Input() applicationDetails;
  @Input() paymentDetails;
  @Input() insuranceDetails;
  insurance: string = "No";
  error: string = "Null";
  apiCalls: string = "No";
  title: string = "";
  constructor(private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      this.title = params["title"];
      // Do something with the id
    });
  }
  ngOnChanges() {
    this.checkInsurance(this.insuranceDetails?.insurance_paid);
    this.checkErrorOccured(this.insuranceDetails?.error_occure);
    this.checkTataApiCall(this.insuranceDetails?.tata_api_call);
  }

  checkInsurance(insurance: any) {
    if (insurance === true) {
      this.insurance = "Yes";
    }
  }
  checkTataApiCall(apiCall: any) {
    if (apiCall) {
      this.apiCalls = "Yes";
    }
  }
  checkErrorOccured(error: any) {
    if (error) {
      this.error = error;
    }
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CsvDownloadService {

  constructor() { }

  downloadCsv(data,filename){
    const blob = new Blob([data], { type: "text/csv" }); // Set the correct MIME type
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download =filename; // Set the filename
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
}

<div class="container">
    <div class="row">
        <div class="col-md-6 mt-3">
            <div class="border shadow">
                <app-footer-list [title]="'Tourist Visa'" [data]="tourist"></app-footer-list>
            </div>
        </div>
        <div class="col-md-6 mt-3">
            <div class="border shadow">
                <app-footer-list [title]="'Business Visa'" [data]="business"></app-footer-list>
            </div>
        </div>
    </div>
</div>
import { ChangeDetectorRef, Component, Input } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NotificationTemplatesService } from "src/app/core/services/notification-templates.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: 'app-add-cover-letter',
  templateUrl: './add-cover-letter.component.html',
  styleUrls: ['./add-cover-letter.component.scss']
})
export class AddCoverLetterComponent {
  @Input() fromParent: any;
  userCoverForm: FormGroup;
  coverList: any = [];
  isSubmitted = false;
  isEdit = false;
  public Editor = ClassicEditor;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private api: NotificationTemplatesService,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.basicFormDetails(this.fromParent);
  }

  closeModal() {
    this.activeModal.close("close");
  }

  basicFormDetails(data?) {
    this.userCoverForm = this.fb.group({
      id: [data ? data.id : null],
      letter_from: [data ? data.letter_from : "", [Validators.required]],
      to: [data ? data.to : "", [Validators.required]],
      template_name: [data ? data.template_name : "", [Validators.required]],
      subject: [data ? data.subject : "", [Validators.required]],
      body: [data ? data.body : "", [Validators.required]]
    });
  }

  onSubmit(form) {
    if (form.valid) {
      if (this.fromParent?.id) {
         let editId = this.fromParent?.id;
        this.api.editCoverNotification(editId, form.value).subscribe(
          (result: any) => {
            this.toastr.success("Cover Updated Successfully!");
            this.closeModal();
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        this.api.setCoverNotification(form.value).subscribe(
          (result: any) => {
            this.toastr.success("Cover Added Successfully!");
            this.closeModal();
          },
          (error) => {
            console.log(error);
          }
        ); 
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all the fields to continue!");
    }
  }
  getDataList() {
    this.api.getCoverNotification().subscribe((response: any) => {
      this.coverList = response.results;
    });
  }
}

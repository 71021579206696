<div class="container">
    <div class="my-3 row">
        <label class="col-md-4 col-form-label">Please select a Role</label>
        <div class="col-md-3">
            <select class="form-select" [hidden]="itemDisabled" [(ngModel)]="selectedRoleId" (change)="selectRoleList(selectedRoleId)">
                <option disabled selected value=null>Select Role</option>
                <option *ngFor="let role of roleList" [value]="role?.id">
                    {{role?.role_name}}
                </option>
            </select>
            <input [disabled]="itemDisabled" [hidden]="!itemDisabled" type="text" [value]="selectedRoleName">
        </div>
    </div>
    <app-permission-item [fromRoles]="fromParent" [tab]="'user'" [hidePortionForm]="false"></app-permission-item>
</div>
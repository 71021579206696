import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { CountryManagementService } from 'src/app/core/services/country-management.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/core/services/shared.service';
import { CategoryService } from 'src/app/core/services/category.service';
import { ProductPageService } from 'src/app/core/services/product-page.service';
import { PermissionService } from 'src/app/core/services/permision.service';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss']
})
export class AdditionalInfoComponent implements OnInit {

  public Editor = ClassicEditor;
  infoForm: FormGroup;
  hasChanges = false;

  isUpdating = false;
  deletedinfo = [];

  @Input() country;
  @Input() page;
  category_id = this.sharedService.selectedCmsCategoryId;
  product_id = this.sharedService.selectedCmsProductId;
  publishPage: string;
  isSuggestion: boolean;

  constructor(private fb: FormBuilder, private api: CountryManagementService,
    private toastr: ToastrService, private sharedService: SharedService,
    private categoryApi: CategoryService, private productApi: ProductPageService,
    public permission: PermissionService) { }

  ngOnInit(): void {
    this.resetForm();
    this.getInfo();
    switch (this.page) {
      case 'country': this.publishPage = 'AdditionalInformation'; break;
      case 'category': this.publishPage = 'CategoryAdditionalInformation'; break;
      case 'product': this.publishPage = 'ProductAdditionalInformation'; break;
      default: break;
    }
  }

  resetForm() {
    this.infoForm = this.fb.group({
      info: this.fb.array([
        this.fb.group({
          heading: ['', [Validators.required]],
          description_datas: new FormArray([]),
          is_updated: [false, [Validators.required]],
        })])
    });
  }

  info(): FormArray {
    return this.infoForm.get("info") as FormArray
  }

  newinfo(): FormGroup {
    return this.fb.group({
      heading: ['', [Validators.required]],
      is_updated: [true, [Validators.required]],
      description_datas: new FormArray([
        this.fb.group({
          text: '',
        })
      ]),
    })
  }

  addinfo() {
    this.info().push(this.newinfo());
  }

  removeinfo(i: number) {
    this.infoForm.value.info[i].is_deleted = true;
    if (this.infoForm.value.info[i].hasOwnProperty("id")) this.deletedinfo.push(this.infoForm.value.info[i]);
    this.info().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedinfo.forEach(ae => {
        ae.is_updated = false;
        form.value.info.push(ae);
      })

      if (this.page == 'country') {
        form.value.info.forEach(ae => {
          ae.country = this.country
        })
        this.api.setAdditionalInfo(form.value.info).subscribe(
          () => {
            this.onSetSuccess();
          })
      } else if (this.page == 'category') {
        form.value.info.forEach(ae => {
          ae.category = this.category_id;
          if (this.isSuggestion) { delete ae.id; delete ae.country; }
        })
        this.categoryApi.setAdditionalInfo(form.value.info).subscribe(
          () => {
            this.onSetSuccess();
          })
      } else if (this.page == 'product') {
        form.value.info.forEach(ae => {
          ae.product = this.product_id;
          if (this.isSuggestion) { delete ae.id; delete ae.country; }
        })
        this.productApi.setAdditionalInfo(form.value.info).subscribe(
          () => {
            this.onSetSuccess();
          })
      }
    } else {
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  onSetSuccess() {
    this.toastr.success("Content Updated Successfully!");
    this.getInfo();
    this.hasChanges = false;
    this.isUpdating = false;
  }

  getInfo() {
    if (this.page == 'country') {
      this.getCountryData();
    } else if (this.page == 'category') {
      this.categoryApi.getAdditionalInfo(this.category_id).subscribe(
        (result: any) => {
          if (result.length == 0) { this.isSuggestion = true; this.getCountryData(); }
          else { this.isSuggestion = false; this.setData(result); }
        })
    } else if (this.page == 'product') {
      this.productApi.getAdditionalInfo(this.product_id).subscribe(
        (result: any) => {
          if (result.length == 0) { this.isSuggestion = true; this.getCountryData(); }
          else { this.isSuggestion = false; this.setData(result); }
        })
    }
  }

  getCountryData() {
    this.api.getAdditionalInfo(this.country).subscribe(
      (result: any) => {
        this.setData(result);
        if (result.length > 0 && (this.page == 'category' || this.page == 'product')) this.toastr.warning("Showing suggestion from country", " Save to reflect changes");
      })
  }

  setData(result) {
    this.infoForm = this.fb.group({
      info: new FormArray([]),
    });
    result.forEach(ao => {
      (this.infoForm.get('info') as FormArray).push(
        this.fb.group({
          id: [ao.id, [Validators.required]],
          heading: [ao.heading, [Validators.required]],
          is_updated: [this.isSuggestion ? true : false],
          description_datas: this.setDescriptions(ao),
        })
      );
      this.deletedinfo = [];
    });
  }

  dataChanged(i) {
    this.infoForm.value.info[i].is_updated = true;
  }

  setDescriptions(x) {
    let arr = new FormArray([])
    x.description.forEach(y => {
      arr.push(this.fb.group({
        text: y
      }))
    })
    return arr;
  }

  addNewDescription(control) {
    control.push(
      this.fb.group({
        text: ['']
      }));
  }

  deleteDescription(control, index) {
    control.removeAt(index)
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';
import { AddEmailComponent } from './add-email/add-email.component';
import { NotificationTemplatesService } from 'src/app/core/services/notification-templates.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent {
  breadCrumbItems: Array<{}>;
  isLoading = false;
  isDeleting = false;
  isEdit = false;
  emailList: any = [];
  selectedEmailDel: any;
  @ViewChild(AddEmailComponent) addMsg:AddEmailComponent;

  @ViewChild("emailDetails", { static: false }) emailModal: ElementRef;
  modalReference: any;

  constructor(
    private modalService: NgbModal,
    private api: NotificationTemplatesService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    public permission: PermissionService
  ) {}

  ngOnInit() {
    this.breadCrumbItems = [
      { label: "Settings" },
      { label: "Templates" },
      { label: "Email", active: true },
    ];
    this.getDataList();
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  addEmailModal(data?) {
    const modalRef = this.modalService.open(AddEmailComponent, {
      size: "md",
      // scrollable: true,
      windowClass: "modal-small-lg",
      backdrop: "static",
    });

    modalRef.componentInstance.fromParent = data ? data : null;

    modalRef.result.then((result) => {
      this.getDataList();
    },
      (reason) => {}
    );
  }


  getDataList() {
    this.api.getEmailNotification().subscribe((response: any) => {
      this.emailList = response;
    });
  }

  deleteEmail(table){
    this.isDeleting = true;
    this.api.deleteEmailNotification(table.id).subscribe(
      (result: any) => {
        this.isDeleting = false;
        this.modalService.dismissAll({ data: 'details' });
        this.toastr.success("Deleted Successfully!");
        this.getDataList();
      },
      error => {
        this.isDeleting = false;
      });
  }
}

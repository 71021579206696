<div class="container">
  <form [formGroup]="userBasicForm" (ngSubmit)="onSubmit(userBasicForm)">
    <pre>
    </pre>
    <div class="my-3 row">
      <label class="col-md-4 col-form-label">First Name</label>
      <div class="col-md-4">
        <input type="text" class="form-control" formControlName="firstname" [ngClass]="{'invalid': userBasicForm.get('firstname').invalid && isUserFormSubmitted}">
      </div>
    </div>
    <div class="my-3 row">
      <label class="col-md-4 col-form-label">Last Name</label>
      <div class="col-md-4">
        <input type="text" class="form-control" formControlName="lastname" [ngClass]="{'invalid': userBasicForm.get('lastname').invalid && isUserFormSubmitted}">
      </div>
    </div>
    <div class="my-3 row">
      <label class="col-md-4 col-form-label">E-mail</label>
      <div class="col-md-4">
        <input type="email" class="form-control" formControlName="email" [ngClass]="{'invalid': userBasicForm.get('email').invalid && isUserFormSubmitted}">
      </div>
    </div>
    <div class="my-3 row">
      <label class="col-md-4 col-form-label">Phone</label>
      <div class="col-md-4">
        <input type="text" class="form-control" formControlName="phone_no" [ngClass]="{'invalid': userBasicForm.get('phone_no').invalid && isUserFormSubmitted}">
      </div>
    </div>
    <div class="my-3 row">
      <label class="col-md-4 col-form-label">User Name</label>
      <div class="col-md-4">
        <input type="text" class="form-control" formControlName="username" [ngClass]="{'invalid': userBasicForm.get('username').invalid && isUserFormSubmitted}">
      </div>
    </div>
    <div class="my-3 row">
      <label class="col-md-4 col-form-label">Password</label>
      <div class="col-md-4 d-flex">
        <input type="{{ passwordFieldType }}" class="form-control" formControlName="password" [ngClass]="{'invalid': userBasicForm.get('password').invalid && isUserFormSubmitted}">
       <div class="visibility_icon" (click)="togglePasswordVisibility()">
          <i [ngClass]="passwordFieldType === 'password' ? 'mdi me-1 mdi-eye-off-outline' : 'mdi me-1 mdi-eye-outline'"></i>
        </div>
      </div>
    </div>
    <div class="my-3 row">
      <label class="col-md-4 col-form-label">Confirm Password</label>
      <div class="col-md-4">
        <input type="password" class="form-control" formControlName="confirmPassword" [ngClass]="{'invalid': userBasicForm.get('confirmPassword').invalid && isUserFormSubmitted}">
      </div>
    </div>
    <div class="my-3 row">
      <label class="col-md-4 col-form-label">Calendly</label>
      <div class="col-md-4">
        <input type="url" class="form-control" formControlName="calendly" [ngClass]="{'invalid': userBasicForm.get('calendly').invalid && isUserFormSubmitted}">
      </div>
    </div>
    <div class="my-3 row">
      <div class="col-md-12 text-end">
        <button type="submit" class="btn btn-primary">Next</button>
      </div>
    </div>
  </form>
</div>
<div class="w-100 text-end mt-3" *ngIf="applicationForm.value.apply.length>0">
    <a class="cpr btn btn-primary" (click)="showSortModal(applicationForm.value.apply, 'heading')">
        <i class="fa fa-sort" aria-hidden="true"></i> Change Position</a>
</div>
<div class="container ow-hd">
    <form [formGroup]="applicationForm" (ngSubmit)="onSubmit(applicationForm)">
        <div class="row favourite-list">
            <div class="mt-2 col-md-4" formArrayName="apply" *ngFor="let item of apply().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="apply().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removeapply(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Icon</label>
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="URL" formControlName="image_url"
                                    (change)="dataChanged(i)">
                                <a [href]="applicationForm.value.apply[i].image_url" target="_blank"><img class="ms-1"
                                        height="40" [src]="applicationForm.value.apply[i].image_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                     (click)="openUploadModal(i)"><i class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                                (change)="dataChanged(i)">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button
                        *ngIf="(page=='home' && checkPermission('content_management_home_create')) || (page=='consultant' && checkPermission('content_management_consultant_create')) && applicationForm.value.apply.length<4"
                        class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addapply()"><i
                            class="uil-plus"></i> Add</button>
                    <button
                        *ngIf="(page=='home' && checkPermission('content_management_home_create')) || (page=='consultant' && checkPermission('content_management_consultant_create'))"
                        class="btn btn-info mt-1 col-md-12" type="submit" [disabled]="isUpdating"><i
                            class="bx bx-save"></i> Save All</button>
                    <app-publish-btn
                        *ngIf="(page=='home' && checkPermission('content_management_home_publish')) || (page=='consultant' && checkPermission('content_management_consultant_publish'))"
                        class="col-md-12 p-0 mt-1" [publish]="publishPage" [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
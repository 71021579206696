import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/core/services/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-form',
  templateUrl: './update-form.component.html',
  styleUrls: ['./update-form.component.scss']
})
export class UpdateFormComponent implements OnInit {

  @Input() formData;
  countryForm: FormGroup;
  isAdding = false;
  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private api: SharedService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.resetForm(this.formData);
  }

  resetForm(data) {
    this.countryForm = this.fb.group({
      country_id: [data.id ? data.id : 0],
      is_starred: [data.is_starred],
      keywords: this.fb.array(data.keywords ? data.keywords : [])
    });
  }

  get keywordControls() {
    return (this.countryForm.get('keywords') as FormArray).controls;
  }

  addKeyword() {
    const keyword = this.fb.control('', Validators.required);
    this.keywords.push(keyword);
  }

  removeKeyword(index: number) {
    this.keywords.removeAt(index);
  }

  get keywords() {
    return this.countryForm.get('keywords') as FormArray;
  }

  closeModal(status) {
    this.activeModal.close(status);
  }

  submitForm() {
    this.isAdding = true;
    this.api.setKeywords(this.countryForm.value).subscribe(
      () => {
        this.isAdding = false;
        this.closeModal(true);
        this.toastr.success('Keywords Added!');
      },
      () => {
        this.isAdding = false;
      }
    )
  }

}

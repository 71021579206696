import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
KEY='STP_'
  constructor() { }

  getItem(key:string){
    return  JSON.parse(localStorage.getItem(`${this.KEY}${key}`))
  }
   setItem(key:string,value:any){
    return localStorage.setItem(`${this.KEY}${key}`,JSON.stringify(value))
   }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'author-page',
  templateUrl: './author-page.component.html',
  styleUrls: ['./author-page.component.scss']
})
export class AuthorPageComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  page = 'blog';
  specificPage = 'author-blog';

  ngOnInit(){
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'About Us Page', active: true }];
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { CountryManagementService } from 'src/app/core/services/country-management.service';
import { CategoryService } from 'src/app/core/services/category.service';
import { ToastrService } from 'ngx-toastr';
import { ProductPageService } from 'src/app/core/services/product-page.service';
import { AboutUsPageService } from 'src/app/core/services/about-us-page.service';
import { PermissionService } from 'src/app/core/services/permision.service';
// pages: country, category, product, about-us
@Component({
  selector: 'app-header-side-info',
  templateUrl: './header-side-info.component.html',
  styleUrls: ['./header-side-info.component.scss']
})
export class HeaderSideInfoComponent implements OnInit {

  sideForm: FormGroup;
  hasChanges = false;
  @Input() country;
  @Input() category;
  @Input() page;
  @Input() product;
  isSubmitted = false;

  isUpdating = false;
  deletedsideinfo = [];
  publishPage: string;
  isSuggestion: boolean;

  constructor(private fb: FormBuilder, private countryApi: CountryManagementService, private categoryApi: CategoryService,
    private toastr: ToastrService, private productApi: ProductPageService,
    private aboutService: AboutUsPageService, public permission: PermissionService) { }

  ngOnInit(): void {

    this.resetForm();
    this.getSideInfos();
    switch (this.page) {
      case 'country': this.publishPage = 'SideInfoCountry'; break;
      case 'category': this.publishPage = 'SideInfoCategory'; break;
      case 'product': this.publishPage = 'SideInfoProduct'; break;
      case 'about-us': this.publishPage = 'AboutUsSideInfo'; break;
      default: break;
    }

  }

  resetForm() {
    this.sideForm = this.fb.group({
      sideinfo: this.fb.array([])
    });
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedsideinfo.forEach(ae => {
        ae.is_updated = false;
        form.value.sideinfo.push(ae);
      })
      if (this.page == 'country') {
        form.value.sideinfo.forEach(ae => {
          ae.country = this.country;
        });
        this.countryApi.setSideInfo(form.value.sideinfo).subscribe(
          () => {
            this.toastr.success("Content Updated Successfully!");
            this.getSideInfos();
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          })
      } else if (this.page == 'category') {
        form.value.sideinfo.forEach(ae => {
          ae.category = this.category;
          if (this.isSuggestion) { delete ae['id']; delete ae['country']; }
        });
        this.categoryApi.setSideInfo(form.value.sideinfo).subscribe(
          () => {
            this.toastr.success("Content Updated Successfully!");
            this.getSideInfos();
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          })
      } else if (this.page == 'product') {
        form.value.sideinfo.forEach(ae => {
          ae.product = this.product;
          if (this.isSuggestion) { delete ae.id; delete ae.country; }
        })
        this.productApi.setSideInfo(form.value.sideinfo).subscribe(
          () => {
            this.toastr.success("Content Updated Successfully!");
            this.getSideInfos();
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          })
      } else if (this.page == 'about-us') {
        this.aboutService.setSideInfo(form.value.sideinfo).subscribe(
          () => {
            this.toastr.success("Content Updated Successfully!");
            this.getSideInfos();
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          })
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  sideinfo(): FormArray {
    return this.sideForm.get("sideinfo") as FormArray
  }

  newsideinfo(): FormGroup {
    return this.fb.group({
      heading: ['', [Validators.required]],
      sub_heading: ['', [Validators.required]],
      description: ['-'],
      // country: [this.country],
      is_updated: [false],
    })
  }

  addsideinfo() {
    this.sideinfo().push(this.newsideinfo());
  }

  removesideinfo(i: number) {
    this.isSubmitted = false;
    this.sideForm.value.sideinfo[i].is_deleted = true;
    if (this.sideForm.value.sideinfo[i].hasOwnProperty("id")) this.deletedsideinfo.push(this.sideForm.value.sideinfo[i]);
    this.sideinfo().removeAt(i);
  }

  getSideInfos() {
    if (this.page == 'country') {
      this.getCountryData();
    } else if (this.page == 'category') {
      this.categoryApi.getSideInfo(this.category).subscribe(
        (result: any) => {
          if (result.length == 0) { this.isSuggestion = true; this.getCountryData(); }
          else { this.isSuggestion = false; this.setData(result); }
        })
    } else if (this.page == 'product') {
      this.productApi.getSideInfo(this.product).subscribe(
        (result: any) => {
          if (result.length == 0) { this.isSuggestion = true; this.getCountryData(); }
          else { this.isSuggestion = false; this.setData(result); }
        })
    } else if (this.page == 'about-us') {
      this.aboutService.getSideInfo().subscribe(
        (result: any) => {
          this.setData(result);
        })
    } else if (this.page == 'contact-us') {
      this.aboutService.getSideInfo().subscribe(
        (result: any) => {
          this.setData(result);
        })
    }
  }

  getCountryData() {
    this.countryApi.getSideInfo(this.country).subscribe(
      (result: any) => {
        this.setData(result);
        if (result.length > 0 && (this.page == 'category' || this.page == 'product')) this.toastr.warning("Showing suggestion from country", " Save to reflect changes")
      })
  }

  dataChanged(i) {
    this.sideForm.value.sideinfo[i].is_updated = true;
  }

  setData(result) {
    this.sideForm = this.fb.group({
      sideinfo: new FormArray([]),
    });
    result.forEach(ao => {
      (this.sideForm.get('sideinfo') as FormArray).push(
        this.fb.group({
          id: [this.isSuggestion ? null : ao.id],
          heading: [ao.heading, [Validators.required]],
          sub_heading: [ao.sub_heading, [Validators.required]],
          // country: [this.country],
          is_updated: [this.isSuggestion ? true : false],
          description: [ao.description, [Validators.required]]
        })
      );
    });
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

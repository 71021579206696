<div class="container">
  <div class="my-3 row" *ngIf="fromAdd">
    <label class="col-md-4 col-form-label">Please select a new country</label>
    <div class="col-md-3">
      <select class="form-select" [(ngModel)]="selectedCountry" (change)="selectCountry()">
        <option disabled selected value=null>Select Country</option>
        <option *ngFor="let country of countriesList" value="{{country.code}}" [attr.disabled]="country.created ? 'true' : null">{{country.name}}</option>
      </select>
    </div>
  </div>
  <form [formGroup]="countryForm" (ngSubmit)="onSubmit()">
      <div class="my-3 row">
        <label class="col-md-4 col-form-label">Country Name</label>
        <div class="col-md-4">
          <input type="text" class="form-control" formControlName="name" [ngClass]="{'invalid': countryForm.get('name').invalid && isSubmitted}">
        </div>
      </div>
      <div class="my-3 row">
        <label class="col-md-4 col-form-label">Country Code</label>
        <div class="col-md-4">
          <input type="text" class="form-control" formControlName="code" [ngClass]="{'invalid': countryForm.get('code').invalid && isSubmitted}" [readOnly]="true">
        </div>
      </div>
      <div class="my-3 row">
        <label class="col-md-4 col-form-label">Seach Keywords</label>
        <div class="col-md-4">
          <input type="text" class="form-control" formControlName="search_key_words" [ngClass]="{'invalid': countryForm.get('search_key_words').invalid && isSubmitted}" >
        </div>
      </div>
      <div class="my-3 row">
        <label class="col-md-4 col-form-label">URL Param</label>
        <div class="col-md-4">
          <input type="text" class="form-control" formControlName="url_param" [ngClass]="{'invalid': countryForm.get('url_param').invalid && isSubmitted}" (keyup)="checkURLParam(countryForm.value.url_param)">
        </div>
      </div>
      <div class="my-3 row">
        <label class="col-md-4 col-form-label">Country Flag</label>
        <div class="col-md-4 d-flex">
          <input type="hidden" class="form-control me-1" formControlName="flag_url">
          <img *ngIf="countryForm.value.code" class="my-auto" src="{{flagSrc}}/{{countryForm.value.code | lowercase}}.png" width="50" alt="">
          <i *ngIf="!countryForm.value.code" class="bx bxs-flag-alt text-primary fa-3x"></i>
        </div>
      </div>
      <div class="my-3 row">
        <label class="col-md-4 col-form-label">Country Phone Code</label>
        <div class="col-md-4">
          <input type="text" class="form-control" formControlName="phone_code" [ngClass]="{'invalid': countryForm.get('phone_code').invalid && isSubmitted}" [readOnly]="true">
        </div>
      </div>
      <div class="my-3 row">
        <label class="col-md-4 col-form-label">Visa Category</label>
        <div class="col-md-4">
          <!-- <select class="form-select" formControlName="visa_type" [ngClass]="{'invalid': countryForm.get('visa_type').invalid && isSubmitted}">
            <option disabled selected value=null>Select Category</option>
            <option>Stamping</option>
            <option>E Visa</option>
            <option>Visa on Arrival</option>
            <option>Visa Not Required</option>
          </select> -->

          <ng-select [(items)]="categories" [multiple]="true" [placeholder]="'Select Category'" bindLabel="name" bindValue="name" [closeOnSelect]="false"  [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedCategories" dropdownPosition="top" [clearable]="false"></ng-select>
        </div>
      </div>
   
      <div class="my-3 row">
        <div class="col-md-12 text-end">
          <button type="submit" class="btn btn-primary" [disabled]="isDupUrl">{{fromAdd?'Next':'Update'}}</button>
        </div>
      </div>
  </form>
</div>
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ProductService } from "src/app/core/services/product.service";

@Component({
  selector: "app-sort-category",
  templateUrl: "./sort-category.component.html",
  styleUrls: ["./sort-category.component.scss"],
})
export class SortCategoryComponent implements OnInit {
  constructor(
    public activeModal: NgbActiveModal,
    private api: ProductService,
    private toastr: ToastrService
  ) {}
  isSorting: boolean;
  @Input() categoryList: [];
  ngOnInit(): void {}
  closeModal(status) {
    this.activeModal.close(status);
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categoryList, event.previousIndex, event.currentIndex);
  }

  confirmSort() {
    const pass_data = this.categoryList.map((ae: any, i) => ({
      id: ae.id,
      position: i,
    }));
    const data={
      category_positions:pass_data
    }
    console.log(pass_data)
    this.isSorting = true;
    this.api.sortCateogoryOrder(data).subscribe(
      () => {
        this.isSorting = false;
        this.toastr.success("Positions Changed!");
        this.closeModal(true);
      },
      () => {
        this.isSorting = false;
      }
    );
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AccessManagementService } from "src/app/core/services/access-management.service";
import { PermissionItemComponent } from "../../../roles/permission-item/permission-item.component";
import { SharedService } from "src/app/core/services/shared.service";

@Component({
  selector: 'app-country-assign',
  templateUrl: './country-assign.component.html',
  styleUrls: ['./country-assign.component.scss']
})
export class CountryAssignComponent implements OnInit{

  @Output("showNextStep") showNextStep: EventEmitter<any> = new EventEmitter();


  selectedRoleId: number;
  selectedData:any = this.sharedService.selectedUserPermissionData;
  userList = [];
  @Input() fromParent;

  itemDisabled = false;

  country : any;
  countryList: [];
  

  constructor(
    private api: AccessManagementService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private sharedService: SharedService,
  ) { }

  ngOnInit(): void {
    this.getCountriesList();
    this.selectedRoleId = this.fromParent?.id;
  }

  getCountriesList() {
    this.sharedService.getCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response;
        this.country = [];
        this.country = [...new Set(this.fromParent?.assign_country.map(a => a.country_id))];
      })
  }

  showNextStepRole(data) {
    let pass_data = { "id": [] };
    let dataset:any = this.sharedService.selectedUserPermissionData;
    data.forEach(ae => {
      pass_data.id.push(
        {
          "user": this.fromParent?.id ? this.fromParent?.id : dataset.id,
          "country": ae
        }
      )
    });
    this.api.setCountryAssign(pass_data).subscribe(
      (result: any) => {
        this.showNextStep.emit({
          tab: "userRoleDetails",
          data: dataset,
        });
      },
      (error) => {
        console.log(error);
      }
    )
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { CountryManagementService } from 'src/app/core/services/country-management.service';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { PermissionService } from 'src/app/core/services/permision.service';
// page: country
@Component({
  selector: 'app-header-alt',
  templateUrl: './header-alt.component.html',
  styleUrls: ['./header-alt.component.scss']
})
export class HeaderAltComponent implements OnInit {

  headerForm: FormGroup;
  fileToUpload: File | null = null;
  hasChanges = false;
  isUpdating = false;
  @Input() country;
  @Input() page;
  isNew = false;
  isSubmitted = false;
  publishPage: string;

  constructor(private fb: FormBuilder,
    private api: CountryManagementService, private toastr: ToastrService,
    private sanitizer: DomSanitizer, public permission: PermissionService) {

  }

  ngOnInit(): void {
    this.resetForm();
    this.getData();
    this.publishPage = 'CountryDescription';
  }

  resetForm(data?) {
    this.headerForm = this.fb.group({
      id: [data ? data.id : null],
      heading: ['-', [Validators.required]],
      sub_heading: [data ? data.sub_heading : '', [Validators.required]],
      description: ['-'],
      banner_image_url: [data ? data.banner_image_url : ''],
      amount: [data ? data.amount : ''],
      button_label: ['-'],
      button_url: ['-'],
      country: [this.country],
      currency_symbol: [data ? data.currency_symbol : ''],
      price_label: [data ? data.price_label : ''],
      headings: new FormArray([]),
    })

    if (data?.heading != undefined) {
      let heads = JSON.parse(data?.heading);
      heads.forEach(ao => {
        (this.headerForm.get('headings') as FormArray).push(
          this.fb.group({
            text: [ao, [Validators.required]],
          })
        );
      });
    } else {
      this.addheads();
    }

    if (this.page == 'country') { this.headerForm.get('heading').setValue('-'); }

    this.onChanges();

  }

  heads(): FormArray {
    return this.headerForm.get("headings") as FormArray
  }

  newheads(): FormGroup {
    return this.fb.group({
      text: ['', [Validators.required]]
    })
  }

  addheads() {
    this.heads().push(this.newheads());
  }

  removeheads(i: number) {
    this.isSubmitted = false;
    this.heads().removeAt(i);
  }

  onChanges(): void {
    this.headerForm.valueChanges.subscribe(() => {
      Object.keys(this.headerForm.controls).map((key) => {
        if (this.headerForm.get(key).dirty) this.hasChanges = true;
      })

    });
  }

  handleIcon(files: FileList) {
    this.hasChanges = true;
    this.fileToUpload = files.item(0);
    let unsafeImageUrl = URL.createObjectURL(this.fileToUpload);
    this.headerForm.value.banner_image_url = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      const formData: FormData = new FormData();
      Object.keys(this.headerForm.controls).map((key) => {
        if (key == 'banner_image_url' && this.fileToUpload) formData.append('banner_image_url', this.fileToUpload);
        else if (key == 'heading') {
          let heads = [];
          form.value.headings.forEach(ae => {
            heads.push(ae.text);
          });
          formData.append('heading', JSON.stringify(heads));
        }
        else formData.append(key, this.headerForm.value[key]);
      })

      if (this.fileToUpload == null) formData.delete('banner_image_url');

      if (this.isNew) {
        this.api.postDescription(formData).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Content Added Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
            this.isNew = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      } else {
        this.api.setDescription(form.value.id, formData).subscribe(
          (result: any) => {
            this.resetForm(result);
            this.toastr.success("Content Updated Successfully!");
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }

  }

  getData() {
    this.api.getDescription(this.country).subscribe(
      (result: any) => {
        this.resetForm(result[0]);
        if (result.length == 0) this.isNew = true;
        else this.isNew = false;
      })
  }
  checkPermission(type){
    return this.permission.hasPermission(type);
  }
}

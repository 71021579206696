import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'checkbox',
  template: `
      <div [formGroup]="form">
        <div [formGroupName]="field?.name" class="d-flex flex-wrap position-relative">
          <div class="disable_handler" *ngIf="field?.disabled"></div>
          <div *ngFor="let opt of field?.options" class="form-check me-3">
            <label class="form-check-label pointer">
             <input [formControlName]="opt?.answer_choice" class="form-check-input me-2" type="checkbox" name="{{opt?.answer_choice}}" [value]="opt?.answer"/>
             {{opt?.answer_choice}}
            </label>
          </div>
        </div>
      </div> 
    `
})
export class CheckBoxComponent implements OnInit {

  @Input() field: any = {};
  @Input() form!: FormGroup;
  get isValid() { return this.form.controls[this.field.name].valid; }
  get isDirty() { return this.form.controls[this.field.name].dirty; }

  constructor() {}

  ngOnInit(): void {
    // this.form.patchValue(this.field.answer)
    if (this.field?.answer)
      this.form.controls[this.field?.name].setValue(this.field?.answer);

    // if (this.field.disabled) {
    //   this.form.controls[this.field?.name].disable();
    // } else {
    //   this.form.controls[this.field?.name].enable();
    // }
  }

  // ngAfterViewInit(): void {
  //   if (this.field?.answer) this.valueInitailization();
  //   this.optionalQueinitisalizer()
  // }

  // valueInitailization() {
  //   setTimeout(() => {
  //     this.form.controls[this.field?.name].setValue(this.field?.answer);
  //   }, 300);
  // }

  // optionalQueinitisalizer() {
  //   for (let opt of this.field.options) {
  //     if (this.field?.answer[opt.answer_choice]) {
  //       setTimeout(() => {
  //         this.triggerForOptionalQue(opt);
  //       }, 300);
  //       break;
  //     }
  //   }
  // }

  // triggerForOptionalQue(curData: any) {
  //   var event = new CustomEvent('triggerNewQue', { detail: curData });
  //   document.dispatchEvent(event);
  // }
}
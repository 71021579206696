<div class="container-fluid set-minwh">
    <app-page-title title="Required Documents" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="my-3 row">
        <div class="col-md-4">
            <button class="btn btn-success ms-1" type="button" (click)="isEdit=false;open(addmodal);resetForm();" [disabled]="!selected"><i class="fa fa-plus"></i>
                Add </button>
        </div>
        <div class="offset-md-4 col-md-4">
            <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name" bindValue="id" [(ngModel)]="country" [clearable]="false" (change)="getDocs(country)"></ng-select>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 offset-lg-3" *ngIf="requiredDocsList.length == 0 && !isLoading">
            <div class="card text-center p-4">
                {{!selected ? 'Select Country to view Required Documents' : 'No Documents under this Country'}}
            </div>
        </div>
        <div class="col-lg-4 offset-lg-4" *ngIf="isLoading">
            <div class="card text-center p-4">
                <i class="fa fa-spin fa-spinner fa-pulse fa-3x"></i>
            </div>
        </div>
        <div class="col-md-12 row" >
            <div class="col-md-4" *ngFor="let item of requiredDocsList">
                <div class="row">
                    <div class="col-md-12 mt-2">
                        <div class="tool-div">
                            <a (click)="isEdit=true;resetForm(item);open(addmodal);"><i class="fa fa-edit text-info"></i></a>
                            <a (click)="selectedDelete=item;open(deletemodal);"><i class="fa fa-trash text-danger"></i></a>
                        </div>
                        <div class="border shadow bg-white p-4 mt-2">
                            <div class="my-3 row">
                                <h5 class="col-md-12 text-center">
                                    {{item.name}}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #addmodal let-modal>
	<div class="modal-header">
		<h4 class="modal-title w-100 text-center" id="modal-basic-title">{{isEdit ? 'Edit':'Add'}} Document</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<form [formGroup]="reqdDocForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-md-12 mt-2">
                    <div class="bg-white p-4 mt-2">
                        <div class="my-3 row">
                            <div class="col-md-12">
                                <input type="text" class="form-control" placeholder="Name" formControlName="name">
                            </div>
                        </div>
                        <div class="mt-3 row">
                            <div class="col-md-12 text-end">
                                <button class="btn btn-success mt-1 ms-1" type="submit" [disabled]="isLoading">
                                    <i *ngIf="!isLoading" class="bx bx-save"></i>
                                    <i *ngIf="isLoading" class="fa fa-spin fa-pulse fa-spinner"></i>
                                    {{isEdit ? 'Update':'Add'}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
	</div>
</ng-template>

<ng-template #deletemodal let-modal>
	<div class="modal-header">
		<h4 class="modal-title w-100 text-center" id="modal-basic-title">Delete Document</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body text-center">
		<h5>Are you sure to delete {{selectedDelete.name}} ?</h5>
	</div>
    <div class="modal-footer">
        <button (click)="deleteDoc(selectedDelete.id);" class="btn btn-danger"> <i *ngIf="isDeleting" class="fa fa-spin fa-spinner fa-pulse"></i> <i *ngIf="!isDeleting" class="fa fa-trash"></i> Yes</button>
        <button (click)="modal.dismiss('Closs btn')" class="btn btn-secondary">Cancel</button>
    </div>
</ng-template>
<div class="container-fluid">
    <div class="row">
        <div class="col-12 min_height_100_vh">
            <div class="loader_center d-flex align-items-center justify-content-center" *ngIf="formLoader">
                <div class="spinner-border m-5" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="header_portion d-flex justify-content-end align-items-center mb-3">
                <div class="w-100 d-flex justify-content-between" *ngIf="isCntrFormCreate">
                    <div class="cpr" (click)="isCntrFormCreate = false">Back</div>
                    <div (click)="openEditFormNameModal(editFormName)" class="cpr">
                        <span class="me-3 user-select-none">{{newCntryFormData?.name}}</span>
                        <i class="mdi mdi-book-edit-outline"></i>
                    </div>
                    <div class="d-flex">
                        <div class="me-3 cpr" (click)="addQuestions()">Add Questions</div>
                        <div class="cpr" (click)="createNewQuestion()">New Question</div>
                    </div>
                </div>
                <div class="me-4 cpr" *ngIf="!isCntrFormCreate">
                    <ng-select [multiple]="false" placeholder="Please select the country" (change)="viewForms()"
                        [(ngModel)]="currentCountry" class="min_w_220">
                        <ng-option *ngFor="let contryItem of countryList" [value]="contryItem" [disabled]="false">
                            {{contryItem?.name}}
                        </ng-option>
                    </ng-select>
                </div>
                <div class="cursor-pointer cpr" *ngIf="!isCntrFormCreate" (click)="createNewForm(content)">
                    <i class="mdi mdi-plus"></i>
                    Create New
                </div>
            </div>

            <!--  -->
            <div class="row" *ngIf="!isCntrFormCreate">
                <div class="col-12">

                    <div class="country_form_container">
                        <div class="country_name">{{currentCountry?.name}}</div>
                        <div class="d-flex flex-wrap">
                            <div class="country_form_box position-relative"
                                *ngFor="let countryFormData of allCountryFormData;let i=index;">
                                <i class="mdi mdi-delete-outline text-danger form_box_del_icon cpr"
                                    (click)="delConfModal(confirmationModal,countryFormData)"></i>
                                <span class="raise_que_checkbox">
                                    <input type="checkbox" name="raise_question"
                                        
                                        [(ngModel)]="countryFormData.raise_question" 
                                        (change)="queValueUpdation($event,countryFormData)">
                                </span>
                                <div class="country_form_header fw-bold">{{countryFormData?.name}}</div>
                                <small class="country_form_body">Count : <b>{{countryFormData?.count}}</b></small>
                                <div class="d-flex justify-content-between form_box_footer">
                                    <div class="cpr darkblue" (click)="editCountryForm(countryFormData)">Edit</div>
                                    <div class="cpr darkblue" (click)="countryFormCopyModal(copyForm,countryFormData)">
                                        Copy</div>
                                    <div class="cpr darkblue" (click)="changeOrder(countryFormData)">Order</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <!-- #region :- Tab view of questions -->
            <div class="row" *ngIf="isCntrFormCreate">
                <div class="col-12">
                    <div class="form-check-label {{isCntrFormCreate? '':'context_menu'}}"
                        *ngFor="let question of formQuestions;let i = index" for="inlineCheckbox{{i}}"
                        (click)="editOrDelCurrentQuestion(question)">
                        <div class="example-box">
                            <div class="d-flex justify-content-between w-100">
                                <div class="word_finish w-75">
                                    {{i+1}}.&nbsp;{{question?.display_name}}
                                </div>
                                <i class="mdi mdi-link-variant text-info fs-6" *ngIf="question?.related_question"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- #endregion :- Tab view of questions -->
        </div>
    </div>
</div>



<!-- #region: Modal for create New Form -->

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create Country Form</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">

        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="countryForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-md-6">
                    <div class="mb-3">
                        <div>
                            <label for="country" class="col-form-label">Country</label>
                        </div>

                        <ng-select [multiple]="false" formControlName="country" id="country">
                            <ng-option *ngFor="let contryItem of countryList" [value]="contryItem.id" [disabled]="true">
                                {{contryItem.name}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3">
                        <label class="col-form-label" for="name">Form Name</label>
                        <input type="text" class="form-control" placeholder="Enter Caption" formControlName="name"
                            id="name">
                    </div>
                </div>
            </div>

            <div class="w-100 text-end">
                <button type="button" class="btn btn-outline-dark" type="submit" (click)="modal.close('Save click')">
                    Save
                </button>
            </div>

        </form>
    </div>

</ng-template>

<!-- #endregion: Modal for create New Form -->


<!-- #region: Modal for Edit form name -->

<ng-template #editFormName let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Edit Form Name</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">

        </button>
    </div>
    <div class="modal-body">

        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="col-form-label" for="name">Form Name</label>
                    <input type="text" class="form-control" placeholder="Edit Caption" [(ngModel)]="CountryFormName">
                </div>
            </div>
        </div>

        <div class="w-100 text-end">
            <button type="button" class="btn btn-outline-dark" type="submit" (click)="editCountryFormName()">
                Save
            </button>
        </div>

    </div>

</ng-template>

<!-- #endregion: Modal for Edit form name -->

<!-- #region: Modal for Copy form -->

<ng-template #copyForm let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create copy</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">

        </button>
    </div>
    <div class="modal-body">

        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="col-form-label" for="country">Select Country</label>
                    <ng-select [multiple]="false" id="country" [(ngModel)]="seleCountryForCopy">
                        <ng-option *ngFor="let contryItem of countryList" [value]="contryItem.id">
                            {{contryItem.name}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
        </div>

        <div class="w-100 text-end">
            <button type="button" class="btn btn-outline-dark" type="submit" (click)="copyCountryForm()">
                Save
            </button>
        </div>

    </div>

</ng-template>

<!-- #endregion: Modal for Copy form -->


<!-- #region : MODAL FOR DELETE CONFIRMATION -->
<ng-template #confirmationModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Delete Data ?</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross-button')"></button>
    </div>
    <div class="modal-body">
        This action will permanently delete data from server. Do you want to continue ?
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cancel')">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="modal.close('confirmed')">
            Delete
        </button>
    </div>
</ng-template>
<!-- #endregion : MODAL FOR DELETE CONFIRMATION -->
import { Injectable } from '@angular/core';
import { AuthenticationService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  permissions:any;
  issuperuser:any;

  constructor(private authService: AuthenticationService){}

  hasPermission(permission) {
    this.permissions =  localStorage.getItem('STP_Permissions') ? JSON.parse(atob(localStorage.getItem('STP_Permissions'))) : null;
    this.issuperuser = this.permissions ? (this.permissions['superuser'] ? this.permissions['superuser'] : false) : false;
    if(this.permissions==null){this.authService.logout();location.reload();return false};
    if(this.issuperuser) return true;
    return this.permissions[permission];
  }

}

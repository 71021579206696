<div class="pt-5 px-4 pb-4">
  <button
    type="button"
    class="btn-close btn_close_position"
    aria-label="Close"
    (click)="close()"
  ></button>
  <div class="container">
    <form [formGroup]="visaFilterForm">
      <div class="row mb-3">
        <div class="col-md-12">
          <label>Country</label>
          <ng-select
            (clear)="resestCategory()"
            (remove)="resestCategory()"
            [multiple]="true"
            placeholder="Select countries"
            formControlName="countries"
            (add)="OnselectCountry($event)"
          >
            <ng-option
              *ngFor="let country of countryList"
              [value]="country.id"
              >{{ country?.name }}</ng-option
            >
          </ng-select>
        </div>
        <div class="col-md-12">
          <label>Category</label>
          <ng-select
            [multiple]="true"
            placeholder="Select category"
            formControlName="category"
          >
            <ng-container *ngIf="!visaFilterForm.value.countries">
              <ng-option
                *ngFor="let category of categoryList"
                [value]="category"
                >{{ category }}</ng-option
              >
            </ng-container>
            <ng-container *ngIf="visaFilterForm.value.countries">
              <ng-option
                *ngFor="let category of categoryList"
                [value]="category.id"
                >{{ category?.category_country }}</ng-option
              >
            </ng-container>
          </ng-select>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="">Date of travel</label>
          <button
            type="button"
            class="btn-close btn_close_position_calendaer"
            aria-label="Close"
            (click)="resestCalendar('travel-from')"
          ></button>

          <ng2-flatpickr
            id="picker1"
            #datePickerElement1
            placeholder="Pick a from date"
            formControlName="travel_date_from"
            [config]="pickerOptions[0]"
            (change)="onTravelDateSelect()"
          ></ng2-flatpickr>
        </div>
        <div class="col-md-6 mt-4">
          <button
            type="button"
            class="btn-close btn_close_position_calendaer2"
            aria-label="Close"
            (click)="resestCalendar('travel-to')"
          ></button>

          <ng2-flatpickr
            id="picker2"
            #datePickerElement2
            placeholder="Pick a to date"
            formControlName="travel_date_to"
            [config]="pickerOptions[1]"
            (change)="onTravelDateSelect()"
          ></ng2-flatpickr>
        </div>
     
      </div>
      <div class="row mb-3">
        <div class="col-md-6">
          <label for="">Date of Application</label>
          <button
            type="button"
            class="btn-close btn_close_position_calendaer"
          
            (click)="resestCalendar('application-from-date')"
          ></button>

          <ng2-flatpickr
            #applicationDatePicker1
            id="applicationPickr1"
            placeholder="Pick a from date"
            formControlName="application_from_date"
            [config]="applicationDateOptions[0]"
            (change)="onApplicationDateSelect()"
          ></ng2-flatpickr>

        </div>
        <div class="col-md-6 mt-4">
          <button
            type="button"
            class="btn-close btn_close_position_calendaer2"
           
            (click)="resestCalendar('application-to-date')"
          ></button>

          <ng2-flatpickr
          id="applicationPickr2"
            #applicationDatePicker2
            placeholder="Pick a to date"
            formControlName="application_to_date"
            [config]="applicationDateOptions[0]"
            (change)="onApplicationDateSelect()"
          ></ng2-flatpickr>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <label for="">Experts</label>

        <ng-select placeholder="Select expert" formControlName="expert">
          <ng-option *ngFor="let user of userList" [value]="user.id">{{
            user?.expert_name
          }}</ng-option>
        </ng-select>
      </div>

      <div class="row mb-3">
        <div class="col-md-12">
          <div class="d-flex align-item-center">
            <div class="me-3">
              <input class="me-2" formControlName="express" type="checkbox" />
              <label for="">Express</label>
            </div>
            <!-- <div class="me-3">
              <input
                class="me-2"
                type="checkbox"
                (click)="onCheckBoxClick($event, 0)"
                [checked]="selectedIndex === 0"
                formControlName="visa"
              />
              <label for="">Visa</label>
            </div> -->
            <!-- <div class="me-3">
              <input
                class="me-2"
                type="checkbox"
                [checked]="selectedIndex === 1"
                (click)="onCheckBoxClick($event, 1)"
                formControlName="diy"
              />
              <label for="">DIY</label>
            </div> -->
            <div class="me-3">
              <select class="status" formControlName="status">
                <option value="PAID">Paid</option>
                <option value="UNPAID">Unpaid</option>
              </select>
            </div>
            <div class="me-3">
              <select class="status" formControlName="product_status">
                <option value="NEW">New</option>
                <option value="IN-PROGRESS">In-Progress</option>
                <option value="DONE">Done</option>
              </select>
            </div>
            <div class="me-3">
              <select class="status" formControlName="source">
                <option value="">Select Source</option>
                <option value="club-mahindra">Club m</option>
                <option value="Website">Website</option>
                <option value="cordelia">Cordelia</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md-12 text-end">
        <!-- <button class="btn btn-secondary me-2" type="button" (click)="close()">
          Close
        </button> -->
        <button class="btn btn-success" type="button" (click)="onSubmit()">
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal-header">
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)">
    </button>
</div>
<form [formGroup]="countryForm" (ngSubmit)="submitForm()">
    <div class="modal-body">
        <div class="row">
            <div class="col-12 text-center">
                <h5>Update</h5>
            </div>
            <div class="col-12">
                <div class="m-3">
                    <input id="is_starred" type="checkbox" formControlName="is_starred" class="form-check-input"/>
                    <label for="is_starred" class="cpr ms-2"> Is Starred </label>
                </div>
                <div class="m-3">
                    <div formArrayName="keywords" class="row">
                        <div *ngFor="let keyword of keywordControls; let i = index" class="d-flex p-2 col-md-6">
                            <input class="form-control" type="text" [formControl]="keyword" />
                            <a (click)="removeKeyword(i)" class="d-flex align-items-center p-2"><i class="fa fa-times text-danger"></i></a>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-sm btn-primary float-end" (click)="addKeyword()">Add Keyword</button>
            </div>
        </div>
    </div>
    <div class="modal-footer text-end">
        <button class="btn btn-secondary" (click)="closeModal(false)"><i class="fa fa-times"></i> Close</button>
        <button type="submit" class="btn btn-success me-2"><i class="fa"
                [ngClass]="{'fa-spin fa-spinner fa-pulse': isAdding, 'fa-check': !isAdding}"></i> Submit</button>
    </div>
</form>
<div class="container-fluid">
  <!-- start page title -->
  <app-page-title title="Contact Us Page" [breadcrumbItems]="breadCrumbItems"></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <app-preview-btn [page]="page"></app-preview-btn>
          <!-- orientation="vertical"  -->
          <ul ngbNav #justifiednavpills="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
            <li [ngbNavItem]="1">
              <a ngbNavLink>
                <span>Header</span>
              </a>
              <ng-template ngbNavContent>
                <app-header-contact-us [page]="page"></app-header-contact-us>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>
                <span>Side&nbsp;Info</span>
              </a>
              <ng-template ngbNavContent>
                <app-contact-us-side-info [page]="page"></app-contact-us-side-info>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="justifiednavpills"></div>
        </div>
      </div>
    </div>
  </div>
</div>
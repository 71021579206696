import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { HomeManagementService } from 'src/app/core/services/home-management.service';
import { CountryManagementService } from 'src/app/core/services/country-management.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/core/services/category.service';
import { ProductPageService } from 'src/app/core/services/product-page.service';
import { PermissionService } from 'src/app/core/services/permision.service';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CustomSortComponent } from '../../ui-elements/custom-sort/custom-sort.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


// pages : home, country
@Component({
  selector: 'app-application-steps',
  templateUrl: './application-steps.component.html',
  styleUrls: ['./application-steps.component.scss']
})
export class ApplicationStepsComponent implements OnInit {

  public Editor = ClassicEditor;
  applicationForm: FormGroup;
  hasChanges = false;

  isUpdating = false;
  isSubmitted = false;
  deletedSteps = [];

  @Input() country;
  @Input() page;
  category_id = this.sharedService.selectedCmsCategoryId
  product_id = this.sharedService.selectedCmsProductId

  publishPage: string;
  valueFromPage: number;
  isSuggestion: boolean;


  constructor(private fb: FormBuilder, private homeApi: HomeManagementService, public permission: PermissionService, private toastr: ToastrService, private countryHomeApi: CountryManagementService, public sharedService: SharedService,
    private categoryApi: CategoryService, private productApi: ProductPageService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.resetForm();
    this.getApplicationsteps();
    switch (this.page) {
      case 'home': this.publishPage = 'HomePageApplicationStep'; break;
      case 'country': this.publishPage = 'CountryApplicationStep'; break;
      case 'category': this.publishPage = 'CategoryApplicationStep'; break;
      case 'product': this.publishPage = 'ProductApplicationStep'; break;
      default: break;
    }
  }

  resetForm() {
    this.applicationForm = this.fb.group({
      steps: this.fb.array([
        this.fb.group({
          id: [''],
          heading: ['', [Validators.required]],
          description: ['', [Validators.required]],
          // country: [this.country?this.country:0],
          is_updated: [''],
        })])
    });
  }

  getApplicationsteps() {
    if (this.page == 'home') {
      this.homeApi.getHomeApplySteps().subscribe(
        (result: any) => {
          this.setData(result);
        })
    } else if (this.page == 'country') {
      this.getCountryData();
    } else if (this.page == 'category') {
      this.categoryApi.getCategoryApplySteps(this.category_id).subscribe(
        (result: any) => {
          if (result.length == 0) { this.isSuggestion = true; this.getCountryData(); }
          else { this.isSuggestion = false; this.setData(result); }
        })
    } else if (this.page == 'product') {
      this.productApi.getProductApplySteps(this.product_id).subscribe(
        (result: any) => {
          if (result.length == 0) { this.isSuggestion = true; this.getCountryData(); }
          else { this.isSuggestion = false; this.setData(result); }
        })
    }
  }

  getCountryData() {
    this.countryHomeApi.getCountryApplySteps(this.country).subscribe(
      (result: any) => {
        this.setData(result);
        if (result.length > 0 && (this.page == 'category' || this.page == 'product')) this.toastr.warning("Showing suggestion from country", " Save to reflect changes");
      })
  }

  setData(result) {
    this.applicationForm = this.fb.group({
      steps: new FormArray([]),
    });
    result.forEach(ao => {
      (this.applicationForm.get('steps') as FormArray).push(
        this.fb.group({
          id: [ao.id, [Validators.required]],
          description: [ao.description, [Validators.required]],
          heading: [ao.heading, [Validators.required]],
          // country: [this.country?this.country:0],
          is_updated: [this.isSuggestion ? true : false],
        })
      );
      this.deletedSteps = [];
    });
  }

  steps(): FormArray {
    return this.applicationForm.get("steps") as FormArray
  }

  newsteps(): FormGroup {
    return this.fb.group({
      description: ['', [Validators.required]],
      heading: ['', [Validators.required]],
      // country: [this.country?this.country:0],
      is_updated: [true],
    })
  }

  addsteps() {
    this.steps().push(this.newsteps());
  }

  removesteps(i: number) {
    this.isSubmitted = false;
    this.applicationForm.value.steps[i].is_deleted = true;
    if (this.applicationForm.value.steps[i].hasOwnProperty("id")) this.deletedSteps.push(this.applicationForm.value.steps[i]);
    this.steps().removeAt(i);
  }

  onSubmit(form) {
    // console.log(form);

    if (this.applicationForm.valid) {
      this.isUpdating = true;
      this.deletedSteps.forEach(ae => {
        ae.is_updated = false;
        form.value.steps.push(ae);
      })
      if (this.page == 'home') {
        this.homeApi.setHomeApplySteps(form.value.steps).subscribe(
          () => {
            this.onSetSuccess();
          },
          () => {
            this.isUpdating = false;
          })
      } else if (this.page == 'country') {
        form.value.steps.forEach(ae => {
          ae.country = this.country;
        })
        this.countryHomeApi.setCountryApplySteps(form.value.steps).subscribe(
          () => {
            this.onSetSuccess();
          },
          () => {
            this.isUpdating = false;
          })
      } else if (this.page == 'category') {
        form.value.steps.forEach(ae => {
          ae.category = this.category_id;
          if (this.isSuggestion) { delete ae.id; delete ae.country; }
        })
        this.categoryApi.setCategoryApplySteps(form.value.steps).subscribe(
          () => {
            this.onSetSuccess();
          },
          () => {
            this.isUpdating = false;
          })
      } else if (this.page == 'product') {
        form.value.steps.forEach(ae => {
          ae.product = this.product_id;
          if (this.isSuggestion) { delete ae.id; delete ae.country; }
        })
        this.productApi.setProductApplySteps(form.value.steps).subscribe(
          () => {
            this.onSetSuccess();
          },
          () => {
            this.isUpdating = false;
          })
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  onSetSuccess() {
    this.toastr.success("Content Updated Successfully!");
    this.getApplicationsteps();
    this.hasChanges = false;
    this.isUpdating = false;
  }

  drop(event: CdkDragDrop<[]>) {
    moveItemInArray(this.steps().controls, event.previousIndex, event.currentIndex);
  }

  dataChanged(i) {
    this.applicationForm.value.steps[i].is_updated = true;
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  showSortModal(data, label) {
    let data_card: any = {
      list: data,
      label: label,
      api_url: 'cms-admin/products/product-application-step-priority/position'
    };
      const modalRef = this.modalService.open(CustomSortComponent,
        {
          size: 'md',
          scrollable: true,
          windowClass: 'modal-big-lg',
          keyboard: false,
          backdrop: 'static'
        });
      modalRef.componentInstance.sortData = data_card;
      modalRef.result.then((result) => {
        if (result) this.getApplicationsteps();
      });
  }
}

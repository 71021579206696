<div class="container">
    <div class="w-100 text-end">
        <button (click)="showListing=!showListing" class="btn btn-primary">
            {{showListing ? 'Show Data' : 'Change Position'}}
        </button>
    </div>
    <div class="data" *ngIf="!showListing">
        <div class="row mt-3" *ngIf="page=='country'">
            <div class="t-3" *ngIf="page=='country'">
                <div class="offset-md-8 col-md-4">
                    <ng-select [(items)]="categoryList" [multiple]="false" [placeholder]="'Select Category'"
                        bindLabel="name" bindValue="id" [(ngModel)]="category" [clearable]="false"
                        (change)="getProductsByCategory(category)" [ngModelOptions]="{standalone: true}"></ng-select>
                </div>
            </div>
        </div>
        <form [formGroup]="destinationsForm" (ngSubmit)="onSubmit(destinationsForm)" class="mnht-300">
            <div class="row">
                <div class="col-md-4 mt-2" formArrayName="destinations"
                    *ngFor="let item of destinations().controls; let i = index;">
                    <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                        <a class="starred" href="javascript:void(0)" (click)="isStarredChange(i)">
                            <i *ngIf="page=='home'"
                                [ngClass]="{'selected': destinationsForm.value.destinations[i].is_starred}"
                                class="bx bxs-star"></i>
                            <i *ngIf="page=='country'"
                                [ngClass]="{'selected': destinationsForm.value.destinations[i].country_starred}"
                                class="bx bxs-star"></i>
                            <i *ngIf="page=='category'"
                                [ngClass]="{'selected': destinationsForm.value.destinations[i].category_starred}"
                                class="bx bxs-star"></i>
                        </a>
                        <div class="my-2 row">
                            <div class="col-md-12">
                                <label class="col-form-label">Image</label>
                                <div class="d-flex">
                                    <input type="text" class="form-control" placeholder="URL"
                                        formControlName="image_url" (change)="dataChanged(i)">
                                    <a [href]="destinationsForm.value.destinations[i].image_url" target="_blank"><img
                                            class="ms-1" height="40"
                                            [src]="destinationsForm.value.destinations[i].image_url"></a>
                                    <button class="btn btn-primary ms-1" type="button" (click)="openUploadModal(i)"><i
                                            class="fas fa-cloud-upload-alt"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-md-12">
                                <label class="col-form-label">Heading</label>
                                <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                                    [ngClass]="{'invalid': item.get('heading').invalid && isSubmitted}"
                                    (change)="dataChanged(i);">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label class="col-form-label">Key Values</label>
                                    <div formArrayName="key_values" class="btn_grp">
                                        <div *ngFor="let lines of item.get('key_values').controls; let j=index">
                                            <div [formGroupName]="j" class="description">
                                                <div class="form-group d-flex my-2">
                                                    <input formControlName="key" class="form-control me-1" type="text"
                                                        placeholder="Key"
                                                        [ngClass]="{'invalid': lines.get('key').invalid && isSubmitted}"
                                                        (change)="dataChanged(i);" />
                                                    <input formControlName="value" class="form-control ms-1" type="text"
                                                        placeholder="Value"
                                                        [ngClass]="{'invalid': lines.get('value').invalid && isSubmitted}"
                                                        (change)="dataChanged(i);" />
                                                    <button type="button"
                                                        *ngIf="item.get('key_values').controls.length!=1"
                                                        class="deletebtn btn btn-pseudo remove"
                                                        (click)="deleteDescription(item.controls.key_values, j);dataChanged(i);"><i
                                                            class="fa fa-times text-danger"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-sm btn-primary mt-2 float-end"
                                        (click)="addNewDescription(item.controls.key_values)">
                                        <i class="fa fa-plus"></i> Add
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-md-6">
                                <label class="col-form-label">Price Label</label>
                                <input type="text" class="form-control" placeholder="Price Label"
                                    formControlName="price_label"
                                    [ngClass]="{'invalid': item.get('price_label').invalid && isSubmitted}"
                                    (change)="dataChanged(i);">
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label">Price</label>
                                <input type="text" class="form-control" placeholder="Price" formControlName="price"
                                    [ngClass]="{'invalid': item.get('price').invalid && isSubmitted}"
                                    (change)="dataChanged(i);">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-md-6">
                                <label class="col-form-label">Tax Amount</label>
                                <input type="number" class="form-control" placeholder="Tax" formControlName="tax"
                                    [ngClass]="{'invalid': item.get('tax').invalid && isSubmitted}"
                                    (change)="dataChanged(i);">
                            </div>
                            <div class="col-md-6">
                                <label class="col-form-label">Button Label</label>
                                <input type="text" class="form-control" placeholder="Button Label"
                                    formControlName="button_label"
                                    [ngClass]="{'invalid': item.get('button_label').invalid && isSubmitted}"
                                    (change)="dataChanged(i);">
                            </div>
                        </div>
                        <div class="my-2 row">
                            <div class="col-md-12">
                                <label class="col-form-label">Button URL</label>
                                <input type="text" class="form-control" placeholder="Button URL"
                                    formControlName="button_url"
                                    [ngClass]="{'invalid': item.get('button_url').invalid && isSubmitted}"
                                    (change)="dataChanged(i);">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 p-5">
                    <div class="my-3 row">
                        <div class="col-md-12 my-2"
                            *ngIf="checkPermission('content_management_home_create')&&page=='home'">
                            <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'"
                                bindLabel="name" bindValue="id" [(ngModel)]="country" [clearable]="false"
                                (change)="selectCountry(country)" [ngModelOptions]="{standalone: true}"></ng-select>
                        </div>
                        <div class="col-md-12 my-2"
                            *ngIf="checkPermission('content_management_home_create')&&page=='home'">
                            <ng-select [(items)]="categoryList" [multiple]="false" [placeholder]="'Select Category'"
                                bindLabel="name" bindValue="id" [(ngModel)]="category" [clearable]="false"
                                (change)="getProducts(category)" [ngModelOptions]="{standalone: true}"></ng-select>
                        </div>
                        <div class="col-md-12 my-2" *ngIf="checkPermission('content_management_home_create')&&
                        
                        page!='country'">
                            <ng-select [(items)]="productList" [multiple]="false" [placeholder]="'Select Product'"
                                (change)="getPopularDestination(product)" bindLabel="name" bindValue="id"
                                [(ngModel)]="product" [clearable]="false"
                                [ngModelOptions]="{standalone: true}"></ng-select>
                        </div>
                        <div class="col-md-12">
                            <button *ngIf="checkPermission('content_management_home_create')&&page!='country'"
                                class="btn btn-primary col-md-12" type="button" [disabled]="!product"
                                (click)="addProduct(product)">Select</button>
                            <button
                                *ngIf="(page=='home' && checkPermission('content_management_home_create')) || (page=='country' && checkPermission('content_management_country_create')) || (page=='category' &&checkPermission('content_management_category_create')) &&(page=='home' && checkPermission('content_management_home_edit')) || 
                            (page=='country' && checkPermission('content_management_country_edit')) || (page=='category' && checkPermission('content_management_category_edit'))&&destinationsForm.value.destinations.length>0"
                                class="btn btn-info col-md-12 my-1" type="submit"><i
                                    [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                                Save All</button>
                            <app-publish-btn
                                *ngIf="(page=='home' && checkPermission('content_management_home_publish')) || (page=='country' && checkPermission('content_management_country_publish')) || (page=='category' &&checkPermission('content_management_category_publish'))&&destinationsForm.value.destinations.length>0"
                                class="col-md-12 p-0" [publish]="'ProductDetail'" [page]="page"></app-publish-btn>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="container" *ngIf="showListing">
        <div class="row">
            <div class="col-md-4 offset-md-4">
                <ul class="" style="list-style: auto;" cdkDropList (cdkDropListDropped)="drop($event)">
                    <li class="border shadow bg-white px-2 py-1 col-md-10 offset-1 mt-2"
                        *ngFor="let item of destinationsForm.value.destinations; let i = index;" cdkDrag>
                        {{item.heading}}
                        <div class="example-handle float-end cpr" cdkDragHandle>
                            <i class="mdi mdi-cursor-move"></i>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
@Component({
  selector: 'textbox',
  template: `
      <div [formGroup]="form" class="position-relative">
        <div class="disable_handler" *ngIf="field?.disabled"></div>
        <input [type]="type"  [attr.field_type]="field.field_type" class="form-control rounded-0"  [id]="field.name" [name]="field.name" [formControlName]="field.name" [placeholder]="field.placeholder"  [autocomplete]="(type=='email') ? 'email' : ''" *ngIf="type!='tel'">
        
        <input type="tel"  [attr.field_type]="field.field_type" class="form-control rounded-0"  [id]="field.name" [name]="field.name" [formControlName]="field.name" [placeholder]="field.placeholder" [autocomplete]="'tel'" maxlength="10" pattern="[0-9]{10}" *ngIf="type=='tel'">
        <span class="validity_indicator"></span>
      </div> 

      <style>
         .validity_indicator {
              position: absolute;
              left: 2px;
              top: 24%;
          }
          input[type="tel"]+span {
            padding-right: 30px;
          }

          input[type="tel"]:invalid+span::after {
            position: absolute;
            content: "✖";
            color: #8b0000;
          }

          input[type="tel"]:valid+span::after {
            position: absolute;
            content: "✓";
            padding-left: 5px;
            color: #009000;
          }
      </style>
    `
})
export class TextBoxComponent implements OnInit, AfterViewInit {


  @Input() field: any = {};
  @Input() type: any;
  @Input() form!: FormGroup;
  get isValid() { return true }
  get isDirty() { return false }

  constructor() {

  }

  

  ngOnInit(): void {
    // console.log(this.form.value[this.field.name]);
  }

  ngAfterViewInit(): void {
    if (this.type.toLowerCase() == 'date' || this.type.toLowerCase() == 'time') {
      const dateString = this.field?.answer;
      if (dateString) {
        let dateToDisplay = '';
        if (dateString.includes('/')) {
          const dateParts = dateString.split('/');
          let day: any = (parseInt(dateParts[0], 10)).toString();
          let month: any = (parseInt(dateParts[1], 10)).toString();
          let year: any = (parseInt(dateParts[2], 10)).toString();
          if (month < 10) month = month.padStart(2, '0');
          if (day < 10) day = day.padStart(2, '0');
          dateToDisplay = year + '-' + month + '-' + day;
        } else {
          dateToDisplay = dateString;
        }
        if (this.field?.answer)
          setTimeout(() => {
            this.form.controls[this.field.name].setValue(dateToDisplay);

            // if (this.field.disabled) {
            //   this.form.controls[this.field?.name].disable();
            // } else {
            //   this.form.controls[this.field?.name].enable();
            // }
          }, 300);
      }

    } else {
      if (this.field?.answer)
        setTimeout(() => {
          this.form.controls[this.field.name].setValue(this.field?.answer);
        }, 300);
    }

    // if (this.field.disabled) {
    //   this.form.controls[this.field?.name].disable();
    // } else {
    //   this.form.controls[this.field?.name].enable();
    // }
  }


}
<div class="row">
    <div class="col-md-6" *ngFor="let item of formInputs; let i = index;">
        <div class="card  my-2">
            <div class="card-body p-2 d-grid text-start">
                <h4 class="card-title">{{i+1}}. {{item.display_name}}
                    <sup class="text-danger" *ngIf="item.verification_required">*</sup>
                    <span *ngIf="item.info && (!fromVisaFlow || isMaster)" class="float-end" [ngbTooltip]="item.info"><i class="fas fa-info-circle cpr text-info"></i></span>
                    <span *ngIf="item.customer_raised && fromVisaFlow && !isMaster" class="float-end badge rounded-pill bg-soft-warning ms-1">Customer Raised</span>
                </h4>
                <p *ngIf="!['Dropbox','Multiple Selections', 'Checkboxes'].includes(item.field_type) && !typeOfVar(item.answer,'object')" class="answer-hyphen card-title-desc mb-0" [innerHTML]="item.answer"></p>
                <div *ngIf="!['Dropbox','Multiple Selections', 'Checkboxes'].includes(item.field_type)">
                    <div *ngFor="let item of item.answer | keyvalue">
                        <span *ngIf="item.value">
                            <p class="answer-hyphen card-title-desc mb-0" *ngIf="item.value==true">{{item.key}}>{{item.value}}</p>
                        </span>
                    </div>
                </div>

                <div *ngIf="['Multiple Selections', 'Checkboxes'].includes(item.field_type)">
                    <div *ngFor="let item of item.answer | keyvalue">
                        <span *ngIf="item.value">
                            <p class="answer-hyphen card-title-desc mb-0" *ngIf="item.value==true">{{item.key}}</p>
                        </span>
                    </div>
                </div>
                <a class="cpr m-auto" [href]="item.answer" target="_blank" *ngIf="['Dropbox'].includes(item.field_type)">
                    <img [src]="item.answer" width="200">
                </a>
                <div class="col-md-12 row" *ngIf="fromVisaFlow">
                    <div class="col-md-6 mt-2">
                        <input type="checkbox" id="forCat{{i}}" class="form-check-input" [(ngModel)]="item.agent_raised" (change)="checkIfRequired(item, 'raised')">
                        <label for="forCat{{i}}" class="form-check-label ps-2"> Unblock</label>
                    </div>

                    <div class="col-md-6 mt-2 text-end verify-que" *ngIf="item.verification_required && isMaster">
                        <input type="checkbox" id="forVer{{i}}" class="form-check-input" [(ngModel)]="item.is_verified"  (change)="checkIfRequired(item, 'verify')">
                        <label for="forVer{{i}}" class="form-check-label ps-2"> Verified</label>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms'
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { ToastrService } from 'ngx-toastr';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notifyForm: FormGroup;
  @Input() data;
  @Input() templateTypes;
  @Input() consultantType;
  @Input() position;
  @Input() process_name;
  @Input() wf_processid;
  @Input() isDiy;
  templatesList = [];
  isGetting = false;
  notificationsList = [];
  isAdding:boolean;
 
  constructor(private fb: FormBuilder, private api: WorkflowService, private toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.iniForm();
    this.getNotifications();

  }

  iniForm(){
    this.notifyForm = this.fb.group({
      consultant_type: [this.consultantType, Validators.required],
      workflow_process: [this.wf_processid, Validators.required],
      status: [this.getStatusId(this.data), Validators.required],
      template_type: [null, Validators.required],
      diy  : [true, Validators.required],
      templates: [null, Validators.required],
    })

  }

  getStatusId(data){
    let id = null;
    this.data.sub_processes.forEach(ae => {
      if(this.process_name==ae.name) {id = ae[this.position].id;}
    });
    return id;
  }

  getTemplatesByType(id){
    this.isGetting = true;
    this.notifyForm.controls['templates'].setValue(null);
    this.api.getTemplatesByType(id).subscribe(
      (result)=>{
        this.isGetting = false;
        this.templatesList = [];
        if(this.notifyForm.value.template_type!=3){
          result.forEach(ae => {
            ae.templates.forEach(ao => {
              this.templatesList.push({
                ...ao,
                tag_name: ae.tag_name
              })
            });
          });
        } else {
          this.templatesList = result;
        }

        let templates = this.notificationsList.filter(ae=>ae.template_type==id)[0]?.templates.map(ae=>ae.template_id);
        this.notifyForm.controls['templates'].setValue(templates);
      },
      ()=>{
        this.isGetting = false;
      }
    )
  }

  onSubmit(form){
    this.isAdding = true;
    let templates = [];
    form.value.templates.forEach(ae => {
      templates.push({template_id: ae})
    });
    // form.value.diy = this.isDiy;
    // form.value.templates = templates;
    let data = {
      ...form.value,
      diy: this.isDiy,
      templates: templates
    }

    this.api.setWorkflowNotifications(data).subscribe(
      result=>{
        this.isAdding = false;
        this.iniForm();
        this.getNotifications();
        this.toastr.success("Notificaions Added Successfully!")
      },
      error=>{
        this.isAdding = false;
        console.log(error);
      }
    )
  }

  getNotifications(){
    this.api.getWorkflowNotifications(this.wf_processid, this.getStatusId(this.data), this.isDiy).subscribe(
      result=>{
        this.notificationsList = result;
      }
    )
  }

  getTemplateName(id){
    return this.templateTypes.filter(ae=>ae.type_id==id)[0].name;
  }

  showDeleteModal(details) {
    let data:any = {id: details.id, name: details.template_name};
    const modalRef = this.modalService.open(DeleteModalComponent,
      {
        size: 'md',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static',
        centered: true
      });
    modalRef.componentInstance.details = data;
    modalRef.result.then((result) => {
      if(result.deleted) this.getNotifications();
    }, (reason) => {
    });
  }
}

<div class="modal-header">
  <h5 class="modal-title w-100 text-center">Notifications - {{nodeDetails.process_name}}</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <div class="card">
    <div class="card-body m-0">
      <div class="row mx-1" *ngIf="nodeDetails.stepType == 1">
        <div class="col-md-6 text-center cpr tabs-list" *ngFor="let tab of tabsDiy; let i =index;">
          <a [ngClass]="{'active':tabSelect[i]}">{{tab}}</a>
        </div>
      </div>
      <div class="row mx-1" *ngIf="nodeDetails.stepType != 1">
        <div class="col-md-6 text-center cpr tabs-list" *ngFor="let tab of tabsDiy; let i =index;" (click)="tabSelect=[false];tabSelect[i]=true;selectedTab=tabsDiy[i];reloadTabFn()">
          <a [ngClass]="{'active':tabSelect[i]}">{{tab}}</a>
        </div>
      </div>
      <div  *ngIf="reloadTab">
        <ul ngbNav #justifiednavpills="ngbNav" [activeId]="selectedTabIndex"
          class="nav-tabs nav-tabs-custom nav-justified">
          <li *ngFor="let tab of nodeDetails.tabs; let i=index;" [ngbNavItem]="i" [disabled]="nodeDetails.isTab">
            <!-- <a ngbNavLink>
              <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
              <span class="d-none d-sm-block">{{tab.name}}</span>
            </a> -->
            <ng-template ngbNavContent>
              <div class="p-3 mb-0 text-muted">
                <app-notify-tab [isDiy]="selectedTab=='DIY' ? true : false" [wf_processid]="nodeDetails.wf_processid" [process_name]="nodeDetails.process_name" [position]="nodeDetails.position" [data]="tab" [templateTypes]="templateTypes" [consultantType]="tab.name"></app-notify-tab>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="justifiednavpills"></div>
      </div>
    </div>
  </div>
</div>
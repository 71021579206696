import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { CountryManagementService } from 'src/app/core/services/country-management.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/core/services/shared.service';
import { CategoryService } from 'src/app/core/services/category.service';
import { ProductPageService } from 'src/app/core/services/product-page.service';
import { PermissionService } from 'src/app/core/services/permision.service';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CustomSortComponent } from '../../ui-elements/custom-sort/custom-sort.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';

@Component({
  selector: 'app-required-docs',
  templateUrl: './required-docs.component.html',
  styleUrls: ['./required-docs.component.scss']
})
export class RequiredDocsComponent implements OnInit {

  public Editor = ClassicEditor;
  docsForm: FormGroup;
  hasChanges = false;

  isUpdating = false;
  deleteddocs = [];

  @Input() country;
  @Input() page;
  category_id = this.sharedService.selectedCmsCategoryId
  product_id = this.sharedService.selectedCmsProductId
  publishPage: string;
  isSuggestion: boolean;

  constructor(private fb: FormBuilder, private api: CountryManagementService, private toastr: ToastrService, private sharedService: SharedService,
    private categoryApi: CategoryService, private productApi: ProductPageService, 
    public permission: PermissionService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.resetForm();
    this.getDocs();
    switch (this.page) {
      case 'country': this.publishPage = 'RequiredDocument'; break;
      case 'category': this.publishPage = 'CategoryRequiredDocument'; break;
      case 'product': this.publishPage = 'ProductRequiredDocument'; break;
      default: break;
    }
  }

  resetForm() {
    this.docsForm = this.fb.group({
      docs: this.fb.array([
        this.fb.group({
          image_url: ['', [Validators.required]],
          name: ['', [Validators.required]],
          heading: ['', [Validators.required]],
          description_datas: new FormArray([]),
          is_updated: [false, [Validators.required]],
        })])
    });
  }

  docs(): FormArray {
    return this.docsForm.get("docs") as FormArray
  }

  newdocs(): FormGroup {
    return this.fb.group({
      image_url: ['', [Validators.required]],
      name: ['', [Validators.required]],
      heading: ['', [Validators.required]],
      is_updated: [true, [Validators.required]],
      description_datas: new FormArray([
        this.fb.group({
          text: '',
        })
      ]),
    })
  }

  adddocs() {
    this.docs().push(this.newdocs());
  }

  removedocs(i: number) {
    this.docsForm.value.docs[i].is_deleted = true;
    if (this.docsForm.value.docs[i].hasOwnProperty("id")) this.deleteddocs.push(this.docsForm.value.docs[i]);
    this.docs().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deleteddocs.forEach(ae => {
        ae.is_updated = false;
        form.value.docs.push(ae);
      })
      if (this.page == 'country') {
        form.value.docs.forEach(ae => {
          ae.country = this.country
        })
        this.api.setRequiredDocs(form.value.docs).subscribe(
          () => {
            this.onSetSuccess();
          },
          () => {
            this.isUpdating = false;
          })
      }
      else if (this.page == 'category') {
        form.value.docs.forEach(ae => {
          ae.category = this.category_id;
          if(this.isSuggestion) {delete ae.id; delete ae.country;}
        })
        this.categoryApi.setRequiredDocs(form.value.docs).subscribe(
          () => {
            this.onSetSuccess();
          },
          () => {
            this.isUpdating = false;
          })
      }
      else if (this.page == 'product') {
        form.value.docs.forEach(ae => {
          ae.product = this.product_id;
          if(this.isSuggestion) {delete ae.id; delete ae.country;}
        })
        this.productApi.setRequiredDocs(form.value.docs).subscribe(
          () => {
            this.onSetSuccess();
          },
          () => {
            this.isUpdating = false;
          })
      }
    } else {
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  onSetSuccess() {
    this.toastr.success("Content Updated Successfully!");
    this.getDocs();
    this.hasChanges = false;
    this.isUpdating = false;
  }

  getDocs() {
    if (this.page == 'country') {
      this.getCountryData();
    } else if (this.page == 'category') {
      this.categoryApi.getRequiredDocs(this.category_id).subscribe(
        (result: any) => {
          if(result.length==0) {this.isSuggestion = true; this.getCountryData();}
          else {this.isSuggestion = false; this.setData(result);}
        })
    } else if (this.page == 'product') {
      this.productApi.getRequiredDocs(this.product_id).subscribe(
        (result: any) => {
          if(result.length==0) {this.isSuggestion = true; this.getCountryData();}
          else {this.isSuggestion = false; this.setData(result);}
        })
    }
  }

  getCountryData(){
    this.api.getRequiredDocs(this.country).subscribe(
      (result: any) => {
        this.setData(result);
        if(result.length>0 && (this.page == 'category' || this.page == 'product')) this.toastr.warning("Showing suggestion from country"," Save to reflect changes");
      })
  }

  setData(result) {
    this.docsForm = this.fb.group({
      docs: new FormArray([]),
    });
    result.forEach(ao => {
      (this.docsForm.get('docs') as FormArray).push(
        this.fb.group({
          id: [ao.id, [Validators.required]],

          image_url: [ao.image_url, [Validators.required]],
          name: [ao.name, [Validators.required]],
          heading: [ao.heading, [Validators.required]],

          is_updated: [this.isSuggestion ? true : false],
          description_datas: this.setDescriptions(ao),
        })
      );
      this.deleteddocs = [];
    });
  }

  dataChanged(i) {
    this.docsForm.value.docs[i].is_updated = true;
  }

  setDescriptions(x) {
    let arr = new FormArray([])
    x.description.forEach(y => {
      arr.push(this.fb.group({
        text: y
      }))
    })
    return arr;
  }

  addNewDescription(control) {
    control.push(
      this.fb.group({
        text: ['']
      }));
  }

  deleteDescription(control, index) {
    control.removeAt(index)
  }

  checkPermission(type){
    return this.permission.hasPermission(type);
  }

  showSortModal(data, label) {
    let url = '';
    if(this.page == 'category') url = 'cms-admin/category/category-required-document-priority/position';
    if(this.page == 'product') url = 'cms-admin/product/product-required-document-priority/position';
    let data_card: any = {
      list: data,
      label: label,
      api_url: url
    };
      const modalRef = this.modalService.open(CustomSortComponent,
        {
          size: 'md',
          scrollable: true,
          windowClass: 'modal-big-lg',
          keyboard: false,
          backdrop: 'static'
        });
      modalRef.componentInstance.sortData = data_card;
      modalRef.result.then((result) => {
        if (result) this.getDocs();
      });
  }

  openUploadModal(i, field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if(result.status) {
        this.docsForm.get(['docs', i, field]).setValue(result.image);
        this.docsForm.get(['docs', i, 'is_updated']).setValue(true);
      }
    });
}

}

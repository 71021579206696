import { Component, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ProductPageService } from 'src/app/core/services/product-page.service';
import { PermissionService } from 'src/app/core/services/permision.service';

@Component({
  selector: 'app-sku-details',
  templateUrl: './sku-details.component.html',
  styleUrls: ['./sku-details.component.scss']
})
export class SkuDetailsComponent {

  infoForm: FormGroup;
  hasChanges = false;
  fileToUpload: File | [null] = [null];

  @Input() country;
  @Input() page;
  @Input() product;

  isUpdating = false;
  deletedinfo = [];
  isNew = false;

  constructor(private fb: FormBuilder, private api: ProductPageService,
    private toastr: ToastrService,
    public permission: PermissionService) { }

  ngOnInit(): void {
    this.resetForm();
    this.getinfo();
  }

  resetForm() {
    this.infoForm = this.fb.group({
      info: this.fb.array([
        this.fb.group({
          title: ['-', [Validators.required]],
          heading: ['', [Validators.required]],
          sub_heading: ['', [Validators.required]],
          description: new FormArray([]),
          product: [this.product, [Validators.required]],
          // is_updated: [false, [Validators.required]],
        })])
    });
  }

  info(): FormArray {
    return this.infoForm.get("info") as FormArray
  }

  newinfo(): FormGroup {
    return this.fb.group({
      title: ['-', [Validators.required]],
      heading: ['', [Validators.required]],
      sub_heading: ['', [Validators.required]],
      product: [this.product, [Validators.required]],
      // is_updated: [true, [Validators.required]],
      description: new FormArray([
        this.fb.group({
          key: '',
          value: '',
        })
      ]),
    })
  }

  addinfo() {
    this.info().push(this.newinfo());
  }

  removeinfo(i: number) {
    this.infoForm.value.info[i].is_deleted = true;
    if (this.infoForm.value.info[i].hasOwnProperty("id")) this.deletedinfo.push(this.infoForm.value.info[i]);
    this.info().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedinfo.forEach(ae => {
        ae.is_updated = false;
        form.value.info.push(ae);
      })
      if(this.isNew){
        this.api.postSkuData(form.value.info[0]).subscribe(
          (result: any) => {
            this.toastr.success("Content Updated Successfully!");
            this.getinfo();
            this.hasChanges = false;
            this.isUpdating = false;
            this.isNew = false;
          },
          error => {
            this.isUpdating = false;
          }
        )
      } else {
        this.api.setSkuData(form.value.info[0].id,form.value.info[0]).subscribe(
          (result: any) => {
            this.toastr.success("Content Updated Successfully!");
            this.getinfo();
            this.hasChanges = false;
            this.isUpdating = false;
          },
          error => {
            this.isUpdating = false;
          }
        )
      }
    } else {
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  drop(event: CdkDragDrop<[]>) {
    moveItemInArray(this.info().controls, event.previousIndex, event.currentIndex);
  }

  getinfo() {
    this.api.getSkuData(this.product).subscribe(
      (result: any) => {
        this.setForm(result);
        if(result.length==0) this.isNew = true;
        else {this.isNew = false;}
      })
  }

  setForm(result){
    this.infoForm = this.fb.group({
      info: new FormArray([]),
    });
    result.forEach(ao => {
      (this.infoForm.get('info') as FormArray).push(
        this.fb.group({
          id: [ao.id, [Validators.required]],
          title: ['-', [Validators.required]],
          heading: [ao.heading, [Validators.required]],
          sub_heading: [ao.sub_heading, [Validators.required]],
          product: [this.product, [Validators.required]],

          // is_updated: [false, [Validators.required]],
          description: this.setDescriptions(ao),
        })
      );
      this.deletedinfo = [];
    });
  }

  dataChanged(i) {
    this.infoForm.value.info[i].is_updated = true;
  }

  setDescriptions(x) {
    let arr = new FormArray([])
    x.description.forEach(y => {
      arr.push(this.fb.group({
        key: y.key,
        value: y.value
      }))
    })
    return arr;
  }

  addNewDescription(control) {
    control.push(
      this.fb.group({
        key: [''],
        value: ['']
      }));
  }

  deleteDescription(control, index) {
    control.removeAt(index)
  }
  checkPermission(type){
    return this.permission.hasPermission(type);
  }
}

import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  getAllCountryData(data){
    return this.http.get(`${environment.apiUrl}/pi-chart-country/?status=PAID&date_of_application_from=${data.fromDate}&date_of_application_to=${data.toDate}`)
  }

  getNewProgressCompleted(data){
    return this.http.get(`${environment.apiUrl}/pi-chart-application-status/?status=PAID&date_of_application_from=${data.fromDate}&date_of_application_to=${data.toDate}`)
  }

  getDiyVisaPro(data){
    return this.http.get(`${environment.apiUrl}/pi-chart-choice/?status=PAID&date_of_application_from=${data.fromDate}&date_of_application_to=${data.toDate}`)
  }

  getTableData(data){
    return this.http.get(`${environment.apiUrl}/table-report-product/${data?.product_type?'?product_type=':''}${data?.product_type?data?.product_type:''}${data?.fromDate?'&date_of_application_from=':''}${data?.fromDate?data.fromDate:''}${data?.toDate?'&date_of_application_to=':''}${data?.toDate?data?.toDate:''}`)
  }

  getCountryTableData(data){
    if(data?.csv){
      return this.http.get(`${environment.apiUrl}/table-report-country/?csv=${data?.csv}${data?.product_type?'&product_type=':''}${data?.product_type?data?.product_type:''}${data?.fromDate?'&date_of_application_from=':''}${data?.fromDate?data.fromDate:''}${data?.toDate?'&date_of_application_to=':''}${data?.toDate?data?.toDate:''}`, { responseType: "blob" })
    }else{
      return this.http.get(`${environment.apiUrl}/table-report-country/?csv=${data?.csv}${data?.product_type?'&product_type=':''}${data?.product_type?data?.product_type:''}${data?.fromDate?'&date_of_application_from=':''}${data?.fromDate?data.fromDate:''}${data?.toDate?'&date_of_application_to=':''}${data?.toDate?data?.toDate:''}`)
    }
   
  }
}

<div class="w-100 text-end mt-3" *ngIf="docsForm.value.docs.length>0">
    <a class="cpr btn btn-primary" (click)="showSortModal(docsForm.value.docs, 'name')">
        <i class="fa fa-sort" aria-hidden="true"></i> Change Position</a>
</div>
<div class="container">
    <form [formGroup]="docsForm" (ngSubmit)="onSubmit(docsForm)">
        <div class="row">
            <div class="col-md-6 mt-2" formArrayName="docs" *ngFor="let item of docs().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="docs().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removedocs(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Image URL</label>
                            <div class="d-flex">
                                <input type="text" class="form-control" placeholder="URL" formControlName="image_url">
                                <a [href]="docsForm.value.docs[i].image_url" target="_blank"><img class="ms-1"
                                        height="40" [src]="docsForm.value.docs[i].image_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                    (click)="openUploadModal(i, 'image_url')"><i
                                        class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Name</label>
                            <input type="text" class="form-control" placeholder="Name" formControlName="name"
                                (change)="dataChanged(i)">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                                (change)="dataChanged(i)">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Description</label>
                            <div formArrayName="description_datas" class="btn_grp">
                                <div *ngFor="let lines of item.get('description_datas').controls; let j=index">
                                    <div [formGroupName]="j" class="description">
                                        <div class="form-group my-2">
                                            <button type="button"
                                                *ngIf="item.get('description_datas').controls.length!=1"
                                                class="deletebtn btn btn-pseudo remove float-end"
                                                (click)="deleteDescription(item.controls.description_datas, j);dataChanged(i);"><i
                                                    class="fa fa-times text-danger"></i></button>
                                            <ckeditor [editor]="Editor" formControlName="text" (change)="dataChanged(i)"
                                                [ngClass]="{'invalid': lines.get('text').invalid}"
                                                data="<p>Content of the editor.</p>"></ckeditor>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-sm btn-primary mt-2 float-end"
                                (click)="addNewDescription(item.controls.description_datas)"
                                *ngIf="item.get('description_datas').controls.length<10">
                                <i class="fa fa-plus"></i> Add Description
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button
                        *ngIf="(page=='country' && checkPermission('content_management_country_create')) || (page=='category' &&checkPermission('content_management_category_create')) || (page=='product' && checkPermission('content_management_product_create'))"
                        class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="adddocs()"><i
                            class="uil-plus"></i>
                        Add</button>
                    <button
                        *ngIf="(page=='country' && checkPermission('content_management_country_create')) || (page=='category' &&checkPermission('content_management_category_create')) || (page=='product' && checkPermission('content_management_product_create')) && (page=='country' && checkPermission('content_management_country_edit')) || (page=='category' &&checkPermission('content_management_category_edit')) || (page=='product' && checkPermission('content_management_product_edit'))"
                        class="btn btn-info mt-1 me-1 col-md-12" type="submit"><i class="bx bx-save"></i> Save
                        All</button>
                    <app-publish-btn
                        *ngIf="(page=='country' && checkPermission('content_management_country_publish')) || (page=='category' &&checkPermission('content_management_category_publish')) || (page=='product' && checkPermission('content_management_product_publish'))"
                        class="col-md-12 p-0 mt-1" [publish]="publishPage" [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MediaService } from 'src/app/core/services/media.service';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';

@Component({
  selector: 'app-media-description',
  templateUrl: './media-description.component.html',
  styleUrls: ['./media-description.component.scss']
})
export class MediaDescriptionComponent implements OnInit {

  public Editor = ClassicEditor;
  mediaForm: FormGroup;
  hasChanges = false;

  isUpdating = false;
  deletedmedia = [];
  isSubmitted = false;
  authors: any = undefined;
  @Input() page;
  allMedias: any;


  constructor(private fb: FormBuilder, public permission: PermissionService,
    private api: MediaService, private toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.resetForm();
    this.getAuthor();
    this.getMedia();
  }

  resetForm() {
    if (this.page == 'news') {

      this.mediaForm = this.fb.group({
        media: this.fb.array([
          this.fb.group({
            id: ['', [Validators.required]],
            author: [null, [Validators.required]],
            heading: ['', [Validators.required]],
            description: ['', [Validators.required]],
            date_published: ['', [Validators.required]],
            //link_url: ['', [Validators.required]],
            banner_image_url: ['', [Validators.required]],
            media_type: [2, [Validators.required]],
            related_medias: [[]],
            is_starred: [false, [Validators.required]],
            is_deleted: [false, [Validators.required]],
            is_updated: [''],
          })])
      });
    } else if (this.page == 'blog') {

      this.mediaForm = this.fb.group({
        media: this.fb.array([
          this.fb.group({
            id: ['', [Validators.required]],
            author: [null, [Validators.required]],
            heading: ['', [Validators.required]],
            description: ['', [Validators.required]],
            date_published: ['', [Validators.required]],
           // link_url: ['', [Validators.required]],
            banner_image_url: ['', [Validators.required]],
            media_type: [1, [Validators.required]],
            related_medias: [[]],
            is_starred: [false, [Validators.required]],
            is_deleted: [false, [Validators.required]],
            is_updated: [''],
          })])
      });
    }
    
  }

  getAuthor() {


    if (this.page == 'news') {

      this.api.getAuthor(2).subscribe((res: any) => {
        let selectedElem: any[] = [];
        for (let index = 0; index < res.length; index++) {
          let elem: any = res[index];
          if (elem.media_type == 2) {
            selectedElem.push(elem);
          }
        }
        this.authors = selectedElem;

      }, (err: any) => {
        console.log(err);
      })
    } else if (this.page == 'blog') {

      this.api.getAuthor(1).subscribe((res: any) => {
        let selectedElem: any[] = [];

        for (let index = 0; index < res.length; index++) {

          let elem: any = res[index];
          if (elem.media_type == 1) {
            selectedElem.push(elem);
          }
        }
        this.authors = selectedElem;

      }, (err: any) => {
        console.log(err);
      })
    }

  }

  getMedia() {
    if (this.page == 'news') {

      this.api.getNewsUsefulMedia(2).subscribe(
        (result: any) => {
          this.allMedias = result;
          this.mediaForm = this.fb.group({
            media: new FormArray([]),
          });
          result.forEach(ao => {
            (this.mediaForm.get('media') as FormArray).push(
              this.fb.group({
                id: [ao.id, [Validators.required]],
                author: [ao.author, [Validators.required]],
                heading: [ao.heading, [Validators.required]],
                description: [ao.description, [Validators.required]],
                date_published: [ao.date_published, [Validators.required]],
                //link_url: [ao.link_url, [Validators.required]],
                banner_image_url: [ao.banner_image_url, [Validators.required]],
                media_type: [ao.media_type, [Validators.required]],
                related_medias: [ao.related_medias],
                is_starred: [(ao.is_starred ? true : false), [Validators.required]],
                is_deleted: [ao.is_deleted ? true : false, [Validators.required]],
                is_updated: [false],
              })
            );
            this.deletedmedia = [];
          });
        })

    } else if (this.page == 'blog') {
      this.api.getUsefulMedia(1).subscribe(
        (result: any) => {
          this.allMedias = result;
          this.mediaForm = this.fb.group({
            media: new FormArray([]),
          });
          result.forEach(ao => {
            (this.mediaForm.get('media') as FormArray).push(
              this.fb.group({
                id: [ao.id, [Validators.required]],
                author: [ao.author, [Validators.required]],
                heading: [ao.heading, [Validators.required]],
                description: [ao.description, [Validators.required]],
                date_published: [ao.date_published, [Validators.required]],
               // link_url: [ao.link_url, [Validators.required]],
                banner_image_url: [ao.banner_image_url, [Validators.required]],
                media_type: [ao.media_type, [Validators.required]],
                related_medias: [ao.related_medias],
                is_starred: [(ao.is_starred ? true : false), [Validators.required]],
                is_deleted: [ao.is_deleted ? true : false, [Validators.required]],
                is_updated: [false],
              })
            );
            this.deletedmedia = [];
          });
        })
    }

  }

  media(): FormArray {
    return this.mediaForm.get("media") as FormArray
  }

  newmedia(): FormGroup {
    if (this.page == 'news') {

      return this.fb.group({
        author: [null, [Validators.required]],
        heading: ['', [Validators.required]],
        description: ['', [Validators.required]],
        date_published: ['', [Validators.required]],
       // link_url: ['', [Validators.required]],
        banner_image_url: ['', [Validators.required]],
        media_type: [2, [Validators.required]],
        related_medias: [[]],
        is_deleted: [false, [Validators.required]],
        is_updated: [true],

      })
    } else if (this.page == 'blog') {

      return this.fb.group({
        author: [null, [Validators.required]],
        heading: ['', [Validators.required]],
        description: ['', [Validators.required]],
        date_published: ['', [Validators.required]],
        //link_url: ['', [Validators.required]],
        banner_image_url: ['', [Validators.required]],
        media_type: [1, [Validators.required]],
        related_medias: [[]],
        is_deleted: [false, [Validators.required]],
        is_updated: [true],

      })
    }

  }

  addmedia() {
    this.media().push(this.newmedia());
  }

  removemedia(i: number) {
    this.isSubmitted = false;
    this.mediaForm.value.media[i].is_deleted = true;
    if (this.mediaForm.value.media[i].hasOwnProperty("id")) this.deletedmedia.push(this.mediaForm.value.media[i]);
    this.media().removeAt(i);
  }

  isStarredChange(i) {
    this.mediaForm.get(['media', i, 'is_updated']).setValue(true);
    this.mediaForm.get(['media', i, 'is_starred']).setValue(!this.mediaForm.value.media[i].is_starred);
  }

  onSubmit(form) {

    if (this.page == 'news') {

      if (form.valid) {
        this.isUpdating = true;
        this.deletedmedia.forEach(ae => {
          ae.is_updated = false;
          form.value.media.push(ae);
        });

        this.api.setNewsUsefulMedia(form.value.media).subscribe(
          () => {
            this.toastr.success("Content Updated Successfully!");
            this.getMedia();
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }
    } else if (this.page == 'blog') {

      if (form.valid) {
        this.isUpdating = true;
        this.deletedmedia.forEach(ae => {
          ae.is_updated = false;
          form.value.media.push(ae);
        });

        this.api.setUsefulMedia(form.value.media).subscribe(
          () => {
            this.toastr.success("Content Updated Successfully!");
            this.getMedia();
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        )
      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }
    }

  }

  dataChanged(i) {
    this.mediaForm.value.media[i].is_updated = true;
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  openUploadModal(i, field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if(result.status) {
        this.mediaForm.get(['media', i, field]).setValue(result.image);
        this.mediaForm.get(['media', i, 'is_updated']).setValue(true);
      }
    });
}

}

<div class="container-fluid">
    <!-- start page title -->
    <app-page-title title="Home Page" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                  <app-preview-btn [page]="page"></app-preview-btn>
                  <ul ngbNav #justifiednavpills="ngbNav" [activeId]="1" class="nav-pills nav-justified bg-light">
                    <li [ngbNavItem]="1">
                      <a ngbNavLink>
                        <span>Header</span>
                      </a>
                      <ng-template ngbNavContent>
                        <app-home-header [page]="page"></app-home-header>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                      <a ngbNavLink>
                        <span>About</span>
                      </a>
                      <ng-template ngbNavContent>
                        <app-about-about [page]="page"></app-about-about>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                      <a ngbNavLink>
                        <span>USP&nbsp;Features</span>
                      </a>
                      <ng-template ngbNavContent>
                        <app-usp-features [page]="page"></app-usp-features>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="4">
                      <a ngbNavLink>
                        <span>Popular&nbsp;Destination</span>
                      </a>
                      <ng-template ngbNavContent>
                        <app-popular-destination [page]="page"></app-popular-destination>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="5">
                      <a ngbNavLink>
                        <span>Application&nbsp;Steps</span>
                      </a>
                      <ng-template ngbNavContent>
                        <app-home-application-steps [page]="page"></app-home-application-steps>
                      </ng-template>
                    </li>
                    <li [ngbNavItem]="6">
                      <a ngbNavLink>
                        <span>Visa&nbsp;Category</span>
                      </a>
                      <ng-template ngbNavContent>
                        <app-visa-category [page]="page"></app-visa-category>
                      </ng-template>
                    </li>
                    <!-- <li [ngbNavItem]="7">
                      <a ngbNavLink>
                        <span>Useful&nbsp;Media</span>
                      </a>
                      <ng-template ngbNavContent>
                        <app-useful-media [page]="page"></app-useful-media>
                      </ng-template>
                    </li> -->
                    <li [ngbNavItem]="8">
                      <a ngbNavLink>
                        <span>Partners</span>
                      </a>
                      <ng-template ngbNavContent>
                        <app-partners [page]="page"></app-partners>
                      </ng-template>
                    </li>
                  </ul>
                  <div [ngbNavOutlet]="justifiednavpills"></div>
                </div>
              </div>
        </div>
    </div>
</div>

<div class="my-3">
    <table class="table table-sm table-primary table-bordered border-primary text-center m-0">
        <thead>
            <tr>
                <th>Type</th>
                <th>Templates</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let item of notificationsList">
                <td>{{getTemplateName(item.template_type)}}</td>
                <td>
                   <!-- <pre> {{item.templates|json}}</pre> -->
                   <span class="badge rounded-pill bg-primary me-1 px-2" *ngFor="let template of item.templates">{{template.template_name}} &nbsp;<a (click)="showDeleteModal(template)" class="cpr"><i class="fa fa-trash text-danger fs-1em"></i></a></span>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<form [formGroup]="notifyForm" (ngSubmit)="onSubmit(notifyForm)">
    <div class="row mt-3">
        <div class="offset-md-2 col-md-4">
            <label>Template Type</label>
            <select class="form-select" formControlName="template_type"
                (change)="getTemplatesByType(notifyForm.value.template_type)">
                <option disabled selected value=null>Select Type</option>
                <option *ngFor="let item of templateTypes" [value]="item.type_id">{{item.name}}</option>
            </select>
        </div>
        <div class="col-md-4">
            <label><i class="fa fa-spin fa-spinner fa-pulse" *ngIf="isGetting"></i>Template</label>
            <ng-select *ngIf="notifyForm.value.template_type!=3" dropdownPosition="top" [(items)]="templatesList" groupBy="tag_name" [multiple]="true" [placeholder]="'Select Template'" bindLabel="name" bindValue="id" [closeOnSelect]="false" formControlName="templates" [clearable]="false"></ng-select>

            <ng-select *ngIf="notifyForm.value.template_type==3" dropdownPosition="top" [(items)]="templatesList"  [multiple]="true" [placeholder]="'Select Template'" bindLabel="name" bindValue="id" [closeOnSelect]="false" formControlName="templates" [clearable]="false"></ng-select>
        </div>
        <div class="col-md-2 mt-1">
            <button type="submit" class="btn btn-primary mt-4 d-flex" [disabled]="!notifyForm.valid">
            <i *ngIf="isAdding" class="fa fa-pulse fa-spin fa-spinner"></i> Add
            </button>
        </div>
    </div>
</form>
<!-- <pre>{{notifyForm.valid}} {{notifyForm.value|json}}</pre> -->
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { ProductService } from "src/app/core/services/product.service";
import { SharedService } from "src/app/core/services/shared.service";
import { ToastrService } from "ngx-toastr";
import { from } from "rxjs";
import { PermissionService } from "src/app/core/services/permision.service";
@Component({
  selector: "app-age-groups",
  templateUrl: "./age-groups.component.html",
  styleUrls: ["./age-groups.component.scss"],
})
export class AgeGroupsComponent implements OnInit {
  ageGroupForm: FormGroup;
  isAgeGroupDetails: boolean = false;
  selectedSubCatList: any[];
  subCatList: any[];
  subcat: any;
  category: any;
  selected_category: any;
  selected_subcat: any;
  selected_agegroup: any;
  selectedCategoryList: any;
  selectedAgeGroupList: any;
  categoryList: any;
  ageGroupList: any;
  ageGroupDetailsId: number;
  countryId: number;
  @Output("showNextStep") showNextStep: EventEmitter<any> = new EventEmitter();
  @Input() fromAdd;
  isSubmitted = false;
  isDuplicate = false;
  isUpdating = false;
  deletedAgegroups = [];
  @Output() refreshData = new EventEmitter<any>();

  constructor(
    private fb: FormBuilder,
    private api: ProductService,
    private sharedService: SharedService,
    private toastr: ToastrService,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {
    //  this.resetForm();
    // if(this.sharedService.selectedCountryId && !this.fromAdd) this.getInitCategories(this.sharedService.selectedCountryId);
    this.ageGroupForm = this.fb.group({
      age_groups: this.fb.array([]),
    });
  }

  resetForm() {
    this.ageGroupForm = this.fb.group({
      age_groups: this.fb.array([
        this.fb.group({
          name: ["", [Validators.required]],
          is_updated: [false],
          internal_code: [this.subcat, [Validators.required]],
          sub_category: [this.subcat, [Validators.required]],
          min_year: [null, [Validators.required]],
          max_year: [null, [Validators.required]],
          visa_fee: [null, [Validators.required]],
          vendor_fee: [null, [Validators.required]],
          merch_fee: [null, [Validators.required]],
          consultant_fee: [null, [Validators.required]],
          express_fee: [null, [Validators.required]],
          // courier_fee: [null, [Validators.required]],
          gst: [null, [Validators.required]],
        }),
      ]),
    });
  }

  initAgeGroup() {
    this.countryId = this.sharedService.selectedCountryId;
    this.selected_category = null;
    this.selected_subcat = null;
    this.selected_agegroup = null;
    this.category = null;
    this.subcat = null;
    this.subCatList = [];
    this.categoryList = [];
    this.getInitCategories(this.sharedService.selectedCountryId);
    this.ageGroupForm = this.fb.group({
      age_groups: this.fb.array([]),
    });
    // if (this.sharedService.countryAllData.is_age_groups) {
    //   this.isAgeGroupDetails = true;
    //   let agegroup = this.sharedService.countryAllData.age_groups;
    //   this.ageGroupForm = this.fb.group({
    //     age_groups: this.fb.array([])
    //   });
    //   agegroup.forEach(ao => {
    //     (this.ageGroupForm.get('age_groups') as FormArray).push(
    //       this.fb.group({
    //         id: [ao.id, [Validators.required]],
    //         name: [ao.name, [Validators.required]],
    //         is_updated: [false],
    //         internal_code: [ao.internal_code, [Validators.required]],
    //         sub_category: [ao.sub_category, [Validators.required]],
    //         subcategory_name: [ao.subcategory_name],
    //         min_year: [ao.min_year, [Validators.required]],
    //         max_year: [ao.max_year, [Validators.required]],
    //         visa_fee: [ao.visa_fee, [Validators.required]],
    //         vendor_fee: [ao.vendor_fee, [Validators.required]],
    //         merch_fee: [ao.merch_fee, [Validators.required]],
    //         gst: [ao.gst, [Validators.required]],
    //       })
    //     );
    //   });
    //   this.deletedAgegroups = [];
    // } else {
    //   this.ageGroupForm = this.fb.group({
    //     age_groups: this.fb.array([])
    //   });
    // }
  }

  ageGroup(): FormArray {
    return this.ageGroupForm.get("age_groups") as FormArray;
  }

  newAgeGroup(agegroup?): FormGroup {
    this.selected_agegroup = null;
    if (agegroup) {
      let groupNames = [];
      this.ageGroupForm.value.age_groups.forEach((ae) => {
        groupNames.push(ae.name);
      });
      groupNames.push(agegroup.name);
      if (this.findDuplicates(groupNames).length > 0) {
        agegroup.name = "Copy of " + agegroup.name;
      }
    }
    return this.fb.group({
      name: [agegroup ? agegroup.name : "", [Validators.required]],
      internal_code: [
        agegroup ? agegroup.internal_code : "",
        [Validators.required],
      ],
      sub_category: [this.subcat, [Validators.required]],
      is_updated: [false],
      min_year: [agegroup ? agegroup.min_year : null, [Validators.required]],
      max_year: [agegroup ? agegroup.max_year : null, [Validators.required]],
      visa_fee: [agegroup ? agegroup.visa_fee : null, [Validators.required]],
      vendor_fee: agegroup
        ? agegroup.vendor_fee
        : [null, [Validators.required]],
      merch_fee: [agegroup ? agegroup.merch_fee : null, [Validators.required]],
      consultant_fee: [
        agegroup ? agegroup.consultant_fee : null,
        [Validators.required],
      ],
      express_fee: [
        agegroup ? agegroup.express_fee : null,
        [Validators.required],
      ],
      // courier_fee: [agegroup?agegroup.courier_fee:null, [Validators.required]],
      gst: [agegroup ? agegroup.gst : null, [Validators.required]],
      pay_consultant_fee: [false, [Validators.required]],
      pay_vendor_fee: [false, [Validators.required]],
      pay_express_fee: [false, [Validators.required]],
      pay_merch_fee: [false, [Validators.required]],
      pay_visa_fee: [false, [Validators.required]],
    });
  }

  addAgeGroup(agegroup?) {
    this.ageGroup().push(this.newAgeGroup(agegroup));
  }

  removeAgeGroup(i: number) {
    if (this.ageGroupForm.value.age_groups[i].hasOwnProperty("id"))
      this.deletedAgegroups.push(this.ageGroupForm.value.age_groups[i]);
    this.ageGroup().removeAt(i);
    let groupNames = [];
    this.ageGroupForm.value.age_groups.forEach((ae, j) => {
      groupNames.push(ae.name);
    });
    if (this.findDuplicates(groupNames).length > 0) this.isDuplicate = true;
    else this.isDuplicate = false;
  }

  onSubmit(fromForm) {
    if (this.ageGroupForm.valid) {
      this.deletedAgegroups.forEach((ae) => {
        ae.is_updated = false;
        ae.is_deleted = true;
        this.ageGroupForm.value.age_groups.push(ae);
      });
      this.isUpdating = true;
      // if(this.isAgeGroupDetails){
      this.api
        .updateAgeGroupDetails(this.ageGroupForm.value.age_groups)
        .subscribe(
          (result: any) => {
            this.isUpdating = false;
            if (fromForm) {
              this.showNextStep.emit({
                tab: "age_groups",
                data: this.ageGroupForm.value,
              });
              this.refreshData.emit();
            } else {
              this.ageGroupForm = this.fb.group({
                age_groups: this.fb.array([]),
              });
              this.category = null;
              this.subcat = null;
              this.selected_agegroup = null;
            }
            this.toastr.success("Age Group Updated Successfully!");
          },
          (error) => {
            this.isUpdating = false;
          }
        );
      // } else {
      //   this.api.setAgeGroupDetails(this.ageGroupForm.value.age_groups).subscribe(
      //     (result: any) => {
      //       if(fromForm){
      //         this.showNextStep.emit({ tab: 'age_groups', data: this.ageGroupForm.value });
      //         this.refreshData.emit();
      //       }
      //       this.toastr.success("Age Group Added Successfully!");
      //     })
      // }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all the fields to continue");
    }
  }

  changeStatus(i) {
    this.ageGroupForm.get(["age_groups", i, "is_updated"]).setValue(true);
  }

  checkDuplicateNames(i, name) {
    this.isDuplicate = false;
    let groupNames = [];
    this.ageGroupForm.value.age_groups.forEach((ae, j) => {
      if (i != j) groupNames.push(ae.name);
    });
    if (
      groupNames.includes(name) ||
      this.findDuplicates(groupNames).length > 0
    ) {
      this.toastr.warning("Group name must be unique");
      this.isDuplicate = true;
    }
  }

  findDuplicates = (arr) =>
    arr.filter((item, index) => arr.indexOf(item) !== index);

  getCategories(country_id, isSelection) {
    if (isSelection) this.selectedCategoryList = [];
    else this.categoryList = [];
    this.api.getCategoriesByCountry(country_id).subscribe((result: any) => {
      if (isSelection) this.selectedCategoryList = result;
      else this.categoryList = result;
    });
  }

  getInitCategories(country_id) {
    this.selectedCategoryList = [];
    this.categoryList = [];
    this.api.getCategoriesByCountry(country_id).subscribe((result: any) => {
      this.selectedCategoryList = [...result];
      this.categoryList = [...result];
    });
  }

  selectCategory(category, isSelection) {
    if (isSelection) {
      this.selectedSubCatList = [];
      this.selectedAgeGroupList = [];
      this.selected_subcat = null;
      this.selected_agegroup = null;
    } else {
      this.subcat = null;
      this.subCatList = [];
      this.ageGroupForm = this.fb.group({
        age_groups: this.fb.array([]),
      });
    }
    this.getProducts(category, isSelection);
  }

  getProducts(category, isSelection) {
    this.api.getSubcategories(category).subscribe((result) => {
      if (isSelection) this.selectedSubCatList = [...result.sub_categories];
      else this.subCatList = [...result.sub_categories];
    });
  }

  selectAgeSuggestion(sub_category) {
    this.selected_agegroup = null;
    this.selectedAgeGroupList = [];
    this.api.getAgeGroupDetailsBySubCat(sub_category).subscribe((result) => {
      this.selectedAgeGroupList = result;
    });
  }

  selectSuggestedAge(agegroup) {
    let age_group = this.selectedAgeGroupList.filter(
      (ae) => ae.id == agegroup
    )[0];
    this.addAgeGroup(age_group);
  }

  getAgeGroupUnderSubCat(sub_category) {
    this.selected_agegroup = null;
    this.api.getAgeGroupDetailsBySubCat(sub_category).subscribe((result) => {
      this.ageGroupForm = this.fb.group({
        age_groups: this.fb.array([]),
      });
      result.forEach((ao) => {
        (this.ageGroupForm.get("age_groups") as FormArray).push(
          this.fb.group({
            id: [ao.id, [Validators.required]],
            name: [ao.name, [Validators.required]],
            is_updated: [false],
            internal_code: [ao.internal_code, [Validators.required]],
            sub_category: [ao.sub_category, [Validators.required]],
            min_year: [ao.min_year, [Validators.required]],
            max_year: [ao.max_year, [Validators.required]],
            visa_fee: [ao.visa_fee, [Validators.required]],
            vendor_fee: [ao.vendor_fee, [Validators.required]],
            merch_fee: [ao.merch_fee, [Validators.required]],
            consultant_fee: [ao.consultant_fee, [Validators.required]],
            express_fee: [ao.express_fee, [Validators.required]],
            // courier_fee: [ao.courier_fee, [Validators.required]],
            gst: [ao.gst, [Validators.required]],
            pay_consultant_fee: [
              ao?.pay_consultant_fee ? true : false,
              [Validators.required],
            ],
            pay_vendor_fee: [
              ao?.pay_vendor_fee ? true : false,
              [Validators.required],
            ],
            pay_express_fee: [
              ao?.pay_express_fee ? true : false,
              [Validators.required],
            ],
            pay_merch_fee: [
              ao?.pay_merch_fee ? true : false,
              [Validators.required],
            ],
            pay_visa_fee: [
              ao?.pay_visa_fee ? true : false,
              [Validators.required],
            ],
          })
        );
      });
      this.deletedAgegroups = [];
    });
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

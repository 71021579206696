import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-notify-tab',
  templateUrl: './notify-tab.component.html',
  styleUrls: ['./notify-tab.component.scss']
})
export class NotifyTabComponent implements OnInit{
  @Input() data;
  @Input() templateTypes;
  @Input() consultantType;
  @Input() position;
  @Input() process_name;
  @Input() wf_processid;
  @Input() isDiy;
  ngOnInit(): void {
  }
}

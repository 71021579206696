import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VisaManageService } from 'src/app/core/services/visa-manage.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

  @Input() paxDetails;
  isConfirming = false;

  constructor(public activeModal: NgbActiveModal, public api: VisaManageService, public toastr: ToastrService){}

  closeModal(status) {
    this.activeModal.close(status);
  }

  getQuestion(url){
    switch (url) {
      case "agent-assign-schedule/agent-assign-schedule-start":
        return "Have you scheduled a time with customer yet?"

      case "master-form/agent-verification-started":
        return "Have you started verification?"
      
      case "document-submission/passport-agent-status-start":
        return "Are you sure to start this process?"

      case "document-submission/passport-agent-status-success":
        return "Have you submitted the document?"

      case "document-submission/passport-user-status-start":
        return "Are you sure to start this process?"

      case "document-submission/passport-user-status-success":
        return "Have you submitted the document?"

      case "document-submission/other-document-agent-status-start":
        return "Are you sure to start this process?"

      case "document-submission/other-document-agent-status-success":
        return "Have you submitted the document?"

      case "document-submission/other-document-user-status-start":
        return "Are you sure to start this process?"

      case "document-submission/other-document-user-status-success":
        return "Have you submitted the document?"
    
      default:
        return "Are you sure?"
    }
  }

  onConfirm(){
    this.isConfirming = true;
    this.api.confirmInVisaFlow(this.paxDetails.api, {pax_id: this.paxDetails.pax_id}).subscribe(
      (result:any)=>{
        this.isConfirming = false;
        this.toastr.success(result.message);
        this.closeModal(true);
      },
      error=>{
        this.isConfirming = false;
      }
    )
  }
}

<div class="modal-header">
    <h5 class="modal-title w-100 text-center">Verify User</h5>
  <button
    type="button"
    class="btn-close"
    data-bs-dismiss="modal"
    aria-label="Close"
    (click)="closeModal()"
  ></button>
</div>
<div class="modal-body" >
  <p class="text-center" *ngIf="statusResponse?.status==='REJECTED'">
    User rejected the consulate form!
  </p>
  <div class="row" *ngIf="statusResponse?.status!='REJECTED'">
    <div class="col-md-6 text-center" >
      <button class="btn btn-success " (click)="acceptUser()">Accept</button>
    </div>
    <div class="col-md-6 text-center">
      <button class="btn btn-danger" (click)="rejectUser()">Reject</button>
    </div>
    <!-- <div class="col-12 text-center">
            <h5>{{getQuestion(paxDetails?.api)}}</h5>
        </div> -->
  </div>
</div>

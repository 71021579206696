import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss']
})
export class AboutUsPageComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  page = 'about-us';

  ngOnInit(){
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'About Us Page', active: true }];
  }
}

import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FaqSeoService } from 'src/app/core/services/faq-seo.service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent {
constructor(private api:FaqSeoService,private toastr:ToastrService){}
siteMapData:any;
isLoading:boolean=false;
ngOnInit(){
  
}


getSiteMap(){
  this.isLoading=true;
  this.api.viewSiteMap().subscribe((res:any)=>{
    this.siteMapData=res;
    this.isLoading=false;
  },err=>{
    this.isLoading=false;
  })
}

downloadXml(){
  this.api.downloadSitemapXml().subscribe((res:any)=>{
    var element = document.createElement('a');
  var blob = new Blob([res],{
    type:'text/xml'
  })
  var url = URL.createObjectURL(blob);
  element.href = url;
  element.setAttribute('download','sitemap.xml');
  document.body.appendChild(element);
  element.click();
  })
}

publishXml(){
  this.api.publishXml().subscribe(res=>{
    this.toastr.success('Sitemap published successfully','Success')
  })
}
}

import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormModalComponent } from '../form-modal/form-modal.component';
import { GlobalQuestionsService } from 'src/app/core/services/global-questions.service';


@Component({
  selector: 'app-global-questions',
  templateUrl: './global-questions.component.html',
  styleUrls: ['./global-questions.component.scss']
})
export class GlobalQuestionsComponent implements OnInit {


  globalQues?: any = [];
  page = 1;
  itemsInAPage: number = 50;
  itemsArrToDspl: number[] = [50, 100, 150];
  pageSize: number = null;
  collectionSize: number = null;
  loader: boolean = true;
  fieldType: any;


  constructor(private modalService: NgbModal, public globalQueService: GlobalQuestionsService) {

  }

  ngOnInit(): void {

    this.getFieldTypes();
    this.getQuestions(this.itemsInAPage, 0);
  }



  openFormModal() {

    const modalRef = this.modalService.open(FormModalComponent, { size: 'xl' });
    modalRef.componentInstance.openFor = 'create';
    modalRef.componentInstance.fieldTypeOptions = this.fieldType;

    modalRef.result.then((data) => {

      if (data.reload) {
        this.getQuestions(this.itemsInAPage, 0);
      }

    }, (reason) => {
    });
  }

  openCurrentModal(questionData) {

    const modalRef = this.modalService.open(FormModalComponent, { size: 'xl' });
    modalRef.componentInstance.openFor = 'edit';
    modalRef.componentInstance.currQueData = questionData;
    modalRef.componentInstance.fieldTypeOptions = this.fieldType;
    

    modalRef.result.then((data) => {
      // on close
      if (data.reload) {
        this.getQuestions(this.itemsInAPage, 0);
      }

    }, (reason) => {
      // on dismiss
      console.log(reason);

    });
  }





  getFieldTypes() {
    this.globalQueService.getFormFields().subscribe((data: any) => {
      this.fieldType = data;
    }), (error => {
      console.log(error);
    });
  }

  getQuestions(limit: number, offset: number) {
    this.loader = true;
    this.globalQueService.getGlobalQustionnair(limit, offset).subscribe((data: any) => {

      this.loader = false;
      this.globalQues = data?.results;
      this.collectionSize = data.count;

    }), ((error) => {

      this.loader = false;
      console.log(error);
    })
  }

  itemsInAPageChanged(item) {
    this.itemsInAPage = item;
    this.getQuestions(this.itemsInAPage, 0);
    this.page = 1;
  }

  loadPage(e) {

    let offset = (e - 1) * this.itemsInAPage;
    this.getQuestions(this.itemsInAPage, offset);

  }




}

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-sku-secondform",
  templateUrl: "./sku-secondform.component.html",
  styleUrls: ["./sku-secondform.component.scss"],
})
export class SkuSecondformComponent implements OnInit {
  @Input() item;
  @Input() product;
  pricingForm: FormGroup;
  descriptionArray: FormArray;
  @Output() newItemEvent = new EventEmitter<string>();
  constructor(private fb: FormBuilder) {}
  ngOnInit(): void {
    this.resetForm();
    this.setData();
  }

  resetForm() {
    this.pricingForm = this.fb.group({
      price: this.fb.array([
        this.fb.group({
         // name: ["", [Validators.required]],
          //sub_name: [""],
          //price: ["", [Validators.required]],
          //currency_symbol: ["", [Validators.required]],
          //tax: ["", [Validators.required]],
         mearch_fee: ["", [Validators.required]],
          diy_description: this.fb.array([
            this.fb.group({
              dydescription: [""],
            }),
          ]),
          product: [this.product],
          is_updated: [""],
        }),
      ]),
    });
  }

  price(): FormArray {
    return this.pricingForm.get("price") as FormArray;
  }

  deleteDescription(control, index) {
    control.removeAt(index);
    this.transferDescription()
  }

  newDecription(): FormGroup {
    return this.fb.group({
      dydescription: "",
    });
  }

  setDescriptions(x) {
    let arr = new FormArray([]);
    x?.diy_description?.forEach((y) => {
      arr.push(
        this.fb.group({
          dydescription: y,
        })
      );
    });
    return arr;
  }

  setData() {
    this.pricingForm = this.fb.group({
      price: new FormArray([]),
    });
    this.item.forEach((ao) => {
      (this.pricingForm.get("price") as FormArray).push(
        this.fb.group({
          id: [ao.id, [Validators.required]],
          //name: [ao.name, [Validators.required]],
          //sub_name: [ao.sub_name],
          //price: [ao.price, [Validators.required]],
          //currency_symbol: [ao.currency_symbol, [Validators.required]],
          consultant_fee: [ao.consultant_fee, [Validators.required]],
          //tax: [ao.tax, [Validators.required]],
         mearch_fee: [ao.mearch_fee, [Validators.required]],
          diy_description: this.setDescriptions(ao),
          product: [this.product],
          is_updated: [false],
        })
      );
    });
  }

  addDescription(empIndex: number) {
    const arr = this.pricingForm.get("price") as FormArray;
    this.descriptionArray = arr
      .at(empIndex)
      .get("diy_description") as FormArray;
    this.descriptionArray.push(this.newDecription());
  }

  transferDescription() {
    this.newItemEvent.emit(this.pricingForm.value);
  }
}

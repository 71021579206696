import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NotificationTemplatesService } from 'src/app/core/services/notification-templates.service';
import { PermissionService } from 'src/app/core/services/permision.service';

@Component({
  selector: 'app-trigger-point',
  templateUrl: './trigger-point.component.html',
  styleUrls: ['./trigger-point.component.scss']
})
export class TriggerPointComponent implements OnInit{
  triggerForm: FormGroup;
  breadCrumbItems: Array<{}>;
  isLoading = false;
  isDeleting = false;
  isEdit = false;
  triggerList: any;
  triggerSmsList= [];
  triggerEmailList= [];
  triggerWhatsAppList= [];
  templatesList = [];

  constructor(private api:NotificationTemplatesService, private fb: FormBuilder, private toastr: ToastrService, public permission: PermissionService){}
  ngOnInit() {
    this.breadCrumbItems = [
      { label: "Settings" },
      { label: "Templates" },
      { label: "Trigger Point", active: true },
    ];
    this.iniForm();
    this.getTriggerList();
    this.getTriggerSmsList();
    this.getTriggerEmailList();
    this.getTriggerWhatsAppList();
  }

  iniForm(){
    this.triggerForm = this.fb.group({
      templates: [null, Validators.required],
    })

  }

  changeList(point, type, templates) {
    let data = {"trigger_point":point, "template_type": type,"templates":templates}
    this.api.setTriggerNotifications(data).subscribe( result=>{
      this.toastr.success("Notificaions Added Successfully!")
    },
    error=>{
      console.log(error);
    })
  }

  getTriggerList(){
    this.isLoading = true;
    this.api.getTriggerPointsList().subscribe(result => {
      this.triggerList = result;
      this.isLoading = false;
    }, error=>{
      this.isLoading = false;
    });
  }

  getTriggerSmsList(){
    this.api.getTriggerSmsList().subscribe(result => {
      this.triggerSmsList = [];
      result.forEach(ae => {
        ae.templates.forEach(ao => {
          this.triggerSmsList.push({
            ...ao,
            tag_name: ae.tag_name
          })
        });
      });
    }, error=>{
    });
  }
  getTriggerEmailList(){
    this.api.getTriggerEmailList().subscribe(result => {
      this.triggerEmailList = [];
      result.forEach(ae => {
        ae.templates.forEach(ao => {
          this.triggerEmailList.push({
            ...ao,
            tag_name: ae.tag_name
          })
        });
      });
    }, error=>{
    });
  }
  getTriggerWhatsAppList(){
    this.api.getTriggerWhatsappList().subscribe(result => {
      this.triggerWhatsAppList = result;
    }, error=>{
    });
  }
}

import { ChangeDetectorRef, Component, Input, NgZone, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms';
import { GlobalQuestionsService } from 'src/app/core/services/global-questions.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})


export class FormModalComponent implements OnInit {

  openFor: string;
  currQueData: any;
  fieldTypeOptions: any;
  subCatForm: any;

  _EDIT: string = 'edit';
  _CREATE: string = 'create';

  formValues: FormGroup;
  strgVariOptions: any = '';

  constructor(public activeModal: NgbActiveModal, private ngZone: NgZone, private fb: FormBuilder,
    public globalQueService: GlobalQuestionsService, private toastr: ToastrService, private modalService: NgbModal, private ref: ChangeDetectorRef) {

    this.formValues = this.fb.group({
      field_name: ['', Validators.required],
      field_type: [null, Validators.required],
      display_name: ['', Validators.required],
      answer_choices: this.fb.array([]),
      info: ['', Validators.required],
      tag: ['', Validators.required],
      storage_variable: [null],
      miscellaneous: [{}, Validators.required],
      is_required: [false]
    });


  }

  ngOnInit(): void {


    if (!this.fieldTypeOptions) {
      this.getFieldTypes();
    }
    this.getStorVariOptions();

    this.setData(this.currQueData);


  }

  setData(data: any) {
    this.formValues.patchValue(data);
    this.addAnswerChoice(data?.answer_choices)

  }


  getFieldTypes() {
    this.globalQueService.getFormFields().subscribe((data: any) => {
      this.fieldTypeOptions = data;
    }), (error => {
      console.log(error);
      this.activeModal.close();
    });
  }

  getStorVariOptions() {

    this.globalQueService.getStorVarOptions().subscribe((data: any) => {

      this.strgVariOptions = data;

    }), (error => {
      console.log(error);

    });
  }

  changeFieldType() {

    if (this.formValues.value.field_type == 3 || this.formValues.value.field_type == 4 || this.formValues.value.field_type == 12 || this.formValues.value.field_type == 5) {

      if (this.formValues.value.answer_choices && this.formValues.value.answer_choices < 1) {

        this.newAnswerChoice();
      }

    }

  }





  get answerChoices() {
    return this.formValues.controls["answer_choices"] as FormArray;
  }

  addAnswerChoice(data): void {

    for (let index = 0; index < data?.length; index++) {

      const element = data[index];


      let answer_choice_array = this.fb.group({
        answer_choice: [element.answer_choice, Validators.required],
        id: [element.id, Validators.required],
      });

      this.answerChoices.push(answer_choice_array);

    }


  }


  newAnswerChoice(): void {

    const answer_choice_array = this.fb.group({
      answer_choice: ['', Validators.required],
    });

    this.answerChoices.push(answer_choice_array);

  }

  deleteAnswerChoice(index: number) {
    this.answerChoices.removeAt(index);
  }

  onSubmit() {

    if (this.formValues.valid && this.openFor == this._CREATE) {

      this.globalQueService.postGlobalQustionnair(this.formValues.value).subscribe(data => {

        this.activeModal.close({ reload: true });
        this.toastr.success('Successfully Created New Question');
      }), (error => {

        console.log(error);
      })

    } else {


      if (this.formValues.dirty) {
        let id = this.currQueData.id;
        this.globalQueService.editGlobalQustionnair(this.formValues.value, id).subscribe(res => {
          this.activeModal.close({ reload: true });
          this.toastr.success('Successfully Saved This Edited Data');
        }), ((err: any) => {
          console.log(err);
        });

      } else {
        this.toastr.warning('Please update something !');
      }

    }
  }

  delConfModal(modalElem: any) {
    this.modalService.open(modalElem, { ariaLabelledBy: 'modal-basic-title' }).result.then();
  }

  deleteQuestion(modalElem: any) {

    let id = this.currQueData.id;
    this.globalQueService.deleteGlobalQustionnair(id).subscribe((res: any) => {

      this.toastr.success('Successfully deleted.');
      this.activeModal.close({ reload: true });

    }), (err => {
      console.log(err);
    })
  }


}

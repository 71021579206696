<form [formGroup]="privacyForm" (ngSubmit)="onSubmit(privacyForm)">
    <div class="row">
        <div class="col-md-6 mt-2" formArrayName="steps" *ngFor="let item of steps().controls; let i = index;">
            <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                <a *ngIf="steps().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                    (click)="removesteps(i)"><i class="uil-times fa-2x"></i></a>
                <div class="my-2 row">
                    <div class="col-md-12">
                        <label class="col-form-label">Heading</label>
                        <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                            (change)="dataChanged(i)"
                            [ngClass]="{'invalid': item.get('heading').invalid && isSubmitted}">
                    </div>
                </div>
                <div class="my-2 row">
                    <div class="col-md-12">
                        <label class="col-form-label">Description</label>
                        <ckeditor [editor]="Editor" formControlName="description" (change)="dataChanged(i)"
                            [ngClass]="{'invalid': item.get('description').invalid && isSubmitted}"
                            data="<p>Content of the editor.</p>"></ckeditor>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 p-5">
            <div class="row">
                <button *ngIf="checkPermission('general_privacy_policy_create')"
                    class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addsteps()"><i
                        class="uil-plus"></i> Add</button>
                <button
                    *ngIf="checkPermission('general_privacy_policy_create') && checkPermission('general_privacy_policy_edit')"
                    class="btn btn-info mt-1 col-md-12" type="submit"><i
                        [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i> Save
                    All</button>
                <app-publish-btn *ngIf="checkPermission('general_privacy_policy_publish')" class="col-md-12 p-0 mt-1"
                    [publish]="'Privacy'" [page]="page"></app-publish-btn>
            </div>
        </div>
    </div>
</form>
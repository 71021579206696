import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { VisaManageService } from 'src/app/core/services/visa-manage.service';

@Component({
  selector: 'stp-passport-details',
  templateUrl: './passport-details.component.html',
  styleUrls: ['./passport-details.component.scss'],
})
export class PassportDetailsComponent implements OnInit {

  @Output('showNextStep') showNextStep: EventEmitter<any> = new EventEmitter();
  @Input() passportData: any;
  @Input() paxId: any;
  loader: boolean = false;
  frontImage: string = '';
  backImage: string = '';
  progress: number = 0;


  personalDetailsForm: FormGroup;
  file: any;


  constructor(private fb: FormBuilder, private visaMngService: VisaManageService, private toastr: ToastrService) {

    this.personalDetailsForm = this.fb.group({
      documentFront: ["", [Validators.required]],
      documentBack: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {

    this.frontImage = this.passportData?.image_url_front;
    this.backImage = this.passportData?.image_url_back;

  }


  uploadFrontImage(event: any) {

    let data = event.target.files[0];

    const formData: FormData = new FormData();
    formData.append('document', data);
    this.visaMngService.uploadImgFile(formData).subscribe((event: any) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          this.progress = Math.round(100 * event.loaded / event.total);
          break;
        case HttpEventType.Response:
          this.progress = 0;
          this.frontImage = event.body.document;
          // this.uploadSignzy(event?.document);
          break;
      }

    }, err => {
      console.log(err);

    });
  }

  uploadBackImage(event: any) {

    let data = event.target.files[0];

    const formData: FormData = new FormData();
    formData.append('document', data);
    this.visaMngService.uploadImgFile(formData).subscribe((event: any) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          this.progress = Math.round(100 * event.loaded / event.total);
          break;
        case HttpEventType.Response:
          this.progress = 0;
          // 
          this.backImage = event.body.document;
          break;
      }

    }, err => {
      console.log(err);

    });
  }

  onPassprtSubmit() {
    this.loader = true;
    this.uploadSignzy(this.frontImage, this.backImage);
  }

  uploadSignzy(frontImg: string, backImg: string) {

    let dataToSend = {
      "image_url_front":
        frontImg
      ,
      "image_url_back":
        backImg
      ,
      "pax_id": this.paxId
    }

    this.visaMngService.uploadSignzyProcess(dataToSend).subscribe((res: any) => {
      this.loader = false;
      this.showNextStep.emit();
      this.toastr.clear();
      this.toastr.success("Successfully updated");

    }, err => {
      this.loader = false;
      console.log(err);
      this.toastr.clear();
      this.toastr.error('Please try again!')
    });


  }





}

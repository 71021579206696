<div class="modal-header">
    <h5 class="modal-title">Add Message Template</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="userMessageForm" (ngSubmit)="onSubmit(userMessageForm)">
        <div class="my-3 row">
            <label class="col-md-12 col-form-label">Name</label>
            <div class="col-md-12">
                <input type="text" class="form-control" formControlName="name"
                    [ngClass]="{'invalid': userMessageForm.get('name').invalid && isSubmitted}">
            </div>
        </div>
        <div class="my-3 row">
            <label class="col-md-12 col-form-label">To</label>
            <div class="col-md-12">
                <input type="text" class="form-control" formControlName="to"
                    [ngClass]="{'invalid': userMessageForm.get('to').invalid && isSubmitted}">
            </div>
        </div>
        <div class="my-3 row">
            <div class="col-md-12">
                <!-- <label class="col-md-12 col-form-label">Tags</label>
                    <input type="text" class="form-control" formControlName="tags"
                    [ngClass]="{'invalid': userMessageForm.get('tags').invalid && isSubmitted}"> -->
                <div class="form-group">
                    <label class="col-form-label">Tags</label>
                    <div formArrayName="tagsGet">
                        <div *ngFor="let skill of tagsGet.controls; let i=index">
                            <div [formGroupName]="i">
                                <div class="form-group d-flex my-2">
                                    <input type="text" class="form-control" formControlName="tags">
                                    <button type="button" class="deletebtn btn btn-pseudo remove"
                                        (click)="removeTags(i)">
                                        <i class="fa fa-times text-danger"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="btn btn-sm btn-primary mt-2 float-end" (click)="addTags()">
                        <i class="fa fa-plus"></i> Add tags
                    </button>
                </div>
            </div>
        </div>
        <div class="my-3 row">
            <label class="col-md-12 col-form-label">Body</label>
            <div class="col-md-12">
                <textarea type="text" cols="30" rows="7" class="form-control" formControlName="body"
                    [ngClass]="{'invalid': userMessageForm.get('body').invalid && isSubmitted}"></textarea>
            </div>
        </div>
        <div class="my-3 row">
            <div class="col-md-12 text-end">
                <button type="submit" class="btn btn-primary">{{fromParent?.id ? 'Update':'Add'}}</button>
            </div>
        </div>
    </form>
</div>
<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"></h4>

    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <input type="text" class="form-control input-search mb-5" placeholder="Search..." tabindex="-1" aria-label="Search..."
        aria-describedby="input-search" (input)="searchChange($event.target.value)">
    <label class="form-check-label w-100" *ngFor="let question of allQueToDisplay;let i = index"
        for="checkboxLabal{{i}}">
        <div class="que-modal-box">
            <div class="d-flex w-100">
                <div>
                    <input mdbCheckbox class="form-check-input me-2" type="checkbox" id="checkboxLabal{{i}}"
                        aria-label="..." (change)="addDataToArray($event,question)" />
                    <!-- (change)="checkBoxEvent($event)"  -->
                </div>
                <div class="word_finish w-75">
                    {{question?.display_name}}
                </div>
            </div>
        </div>
    </label>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary btn-sm" type="submit" (click)="addQuestion()">
        save
    </button>
</div>
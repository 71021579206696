<div class="container mt-5">
    <div class="col-md-4 mt-2">
      <div class="border shadow p-4 mt-2 bg-white">
        <form [formGroup]="addOnForm">
        <div class="my-3 row">
          <div class="col-md-12">
            <label class="col-form-label">Flight</label>
            <input
              type="text"
              class="form-control"
              placeholder="Price"
              formControlName="flight"
              numbersOnly
            />
          </div>
        </div>
        <div class="my-3 row">
          <div class="col-md-12">
            <label class="col-form-label">Courier</label>
            <input
              type="text"
              class="form-control"
              placeholder="Price"
              formControlName="courier"
              numbersOnly
            />
          </div>
        </div>
        <div class="my-3 row">
          <div class="col-md-12">
            <label class="col-form-label">Hotel</label>
            <input
              type="text"
              class="form-control"
              placeholder="Price"
              formControlName="hotel"
              numbersOnly
            />
           
          </div>
        </div>
        <div class="my-3 row">
          <div class="col-md-12">
            <label class="col-form-label">Itinerary Price A</label>
            <input
              type="text"
              class="form-control"
              placeholder="Price"
              formControlName="Itinerary_price_a"
              numbersOnly
            />
          </div>
        </div>
        <div class="my-3 row">
          <div class="col-md-12">
            <label class="col-form-label">Itinerary Price B</label>
            <input
              type="text"
              class="form-control"
              placeholder="Price"
              formControlName="Itinerary_price_b"
              numbersOnly
            />
            <button
              class="btn btn-success mt-1"
              type="button"
              (click)="postHotelAndFlight()"
            >
              <i class="bx bx-save"></i> Save
            </button>
          </div>
        </div>
      </form>
      </div>
 
    </div>
  </div>
<div class="container-fluid">

    <app-page-title title="Integrations" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="row mt-3">
        <div class="col-md-3 d-flex" *ngFor="let item of integrationsList">
            <div class="card w-100 p-3 cpr" (click)="addModal(item)">
                <!-- <img class="m-auto" [src]="item.icon_url" height="50"> -->
                <div class="icon-url" [style.background-image]="'url(' + item.icon_url + ')'"></div>
                <h5 class="m-auto mt-2">{{item.name}}</h5>
            </div>
            <a class="cpr delete-btn"><i class="fas fa-trash text-danger" (click)="open(deleteModal);this.selectedIntegrationDel=item;"></i></a>
        </div>
        <div class="col-md-3">
            <div class="card p-4 cpr" (click)="addModal()">
                <img class="m-auto" src="/assets/icons/add.svg" width="50">
                <h5 class="m-auto mt-2">Add New</h5>
            </div>
        </div>
    </div>
</div>

<ng-template #deleteModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Delete</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body text-center">
		Are you sure to delete integration <b>{{selectedIntegrationDel.name}}</b>?
	</div>
    <div class="modal-footer text-end">
        <button class="btn btn-danger" type="button" (click)="deleteConfirm(selectedIntegrationDel)"><i [ngClass]="{'bx bx-save':!isDeleting, 'fa fa-spin fa-spinner fa-pulse':isDeleting}"></i> Delete
        </button>
        <button class="btn btn-outline" type="button" (click)="modal.close('Cancel')"> Cancel
        </button>
    </div>
</ng-template>
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MasterFormService {

  constructor(private http: HttpClient) { }


  getQustionnair(limit: number, offset: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/global-questionnaire/?` + `limit=${limit}` + '&' + `offset=${offset}`).pipe(retry(2));
  }

  // global - questionnaire /? limit = 5 & offset=0

  // setDescription(id, data): Observable<any> {
  //   return this.http.put(`${environment.apiUrl}/cms-admin/about-us/description/${id}/`, data);
  // }


}

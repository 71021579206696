<div class="modal-header">
    <h5 class="modal-title w-100 text-center">{{uploadDetails?.from_list ? 'Details' : 'Verify Data'}}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)">
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <div class="d-flex mb-4">
                <div class="w-100 m-auto">
                    <div class="w-100 text-center" *ngIf="isDocUpload">
                        <i *ngIf="isGetting" class="fa fa-spin fa-pulse fa-spinner fa-3x"></i>
                        <a [href]="doc_url" target="_blank"><img class="ms-1" height="350" [src]="doc_url"></a>
                        <p class="mt-3" *ngIf="status">Status: <b
                                [ngClass]="{'text-success': status=='ACCEPTED', 'text-danger': status=='REJECTED'}">{{status}}</b>
                        </p>
                    </div>
                    <div class="w-100 text-center" *ngIf="isAgentUpdated && !uploadDetails.from_list">
                        <div *ngFor="let item of dataList">
                            <div class="form-group d-flex my-2 px-5">
                                <input type="text" class="form-control" disabled [value]="item.key" placeholder="Key">
                                <span class="arrow-i m-auto"><i class="mdi mdi-arrow-right"></i></span>
                                <input type="text" class="form-control me-2" disabled [value]="item.value"
                                    placeholder="Value">
                                <button disabled class="btn btn-success me-2"
                                    *ngIf="item.status=='ACCEPTED'">Accepted</button>
                                <button disabled class="btn btn-danger me-2"
                                    *ngIf="item.status=='REJECTED'">Rejected</button>
                                <button class="btn btn-success me-2" *ngIf="item.status=='PENDING'"
                                    (click)="onVerify('ACCEPTED', item.uid)">Accept</button>
                                <button class="btn btn-danger" *ngIf="item.status=='PENDING'"
                                    (click)="onVerify('REJECTED', item.uid)">Reject</button>
                            </div>
                            <i class="fa fa-pulse fa-spin fa-spinner fa-2x" *ngIf="isGetting"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="openFor == _consulate_form">
        <div class="col-12">
            <ngb-accordion [closeOthers]="true" activeIds="static-0">
                <ngb-panel id="static-0">
                    <ng-template ngbPanelTitle>
                        <span (click)="passportLoad()" class="width_100">Passport</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="row">
                            <div class="col-md-6 text-center">
                                <img [src]="passportFront" alt="" class="w-100">
                                <a class="btn btn-primary mt-3" [href]="passportFront" [download]="passportFront"
                                    target="_blank">Download</a>
                            </div>
                            <div class="col-md-6 text-center">
                                <img [src]="passportBack" alt="" class="w-100">
                                <a class="btn btn-primary mt-3" [href]="passportBack" [download]="passportBack"
                                    target="_blank">Download</a>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
                <ngb-panel id="static-1">
                    <ng-template ngbPanelTitle>
                        <span (click)="consulateFormLoad()" class="width_100">Consulate Form</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <img [src]="consulateFormData?.document" alt="" class="w-25">
                    </ng-template>
                </ngb-panel>
                <ngb-panel id="static-2">
                    <ng-template ngbPanelTitle>
                        <span (click)="documentDataLoad()" class="width_100">Document</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div *ngFor="let documentItem of documentData">
                            <div>doc Type:- {{documentItem?.doc_type}}</div>
                            <div>doc :- <div>{{documentItem?.document}}</div>
                            </div>
                            <div>status :- {{documentItem?.status}}</div>
                        </div>

                    </ng-template>
                </ngb-panel>
                <ngb-panel id="static-3">
                    <ng-template ngbPanelTitle>
                        <span (click)="Details()" class="width_100">Details</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div *ngFor="let paxDetail of paxDetails" class="mb-4">
                            {{paxDetail?.key}}:&nbsp;{{paxDetail?.value}}
                            <!-- <div class="fs-6">{{paxDetail?.role}}</div> -->
                        </div>

                    </ng-template>
                </ngb-panel>
                <ngb-panel id="static-4">
                    <ng-template ngbPanelTitle>
                        <span (click)="schedule()" class="width_100">Schedule</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div *ngFor="let scheduleItem of scheduleData">
                            <div>From: {{scheduleItem.from}}</div>
                            <div>To: {{scheduleItem.to}}</div>
                        </div>
                    </ng-template>
                </ngb-panel>
                <ngb-panel id="static-5">
                    <ng-template ngbPanelTitle>
                        <span (click)="otherDocs()" class="width_100">Other Documents</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="row">
                            <div *ngFor="let docs of otherDocsList; let i=index;" class="col-md-4 text-center">
                                <a class="btn btn-primary" [href]="docs.document" [download]="docs.document"
                                    target="_blank">Download Document {{i+1}}</a>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </div>
    </div>
</div>
<div class="modal-footer text-end" *ngIf="isDocUpload && status=='PENDING'">
    <button class="btn btn-success me-2" (click)="onVerify('ACCEPTED')" [disabled]="!doc_url"> <i
            [ngClass]="{'fa fa-spin fa-pulse fa-spinner': isVerifiying, 'fa fa-upload': !isVerifiying}"></i>
        Accept</button>
    <button class="btn btn-danger" (click)="onVerify('REJECTED')">Reject</button>
</div>
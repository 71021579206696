import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { SharedService } from "src/app/core/services/shared.service";
import { HttpEvent, HttpEventType } from "@angular/common/http";
import { PermissionService } from "src/app/core/services/permision.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MediaService } from "src/app/core/services/media.service";

@Component({
  selector: "app-author",
  templateUrl: "./author.component.html",
  styleUrls: ["./author.component.scss"],
})
export class AuthorComponent implements OnInit {
  public Editor = ClassicEditor;

  featuresForm: FormGroup;
  hasChanges = false;
  fileToUpload: File | [null] = [null];
  isAuthorList: boolean = false;
  authorList = [];
  authorId: any;
  isUpdating = false;
  progress: number[] = [0];
  deletedFeatures = [];
  isSubmitted = false;
  seoComp: boolean = false;
  slugId: any;
  seoType: any;

  @Input() page;
  fetauresArray: any = [];
  constructor(
    private fb: FormBuilder,
    private api: MediaService,
    public permission: PermissionService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.resetForm();
    this.getAuthor();
    this.backtoListFun();
  }

  backtoListFun() {
    this.sharedService.backToList$.subscribe((res) => {
      if (res) {
        this.isAuthorList = false;
        this.seoComp = false;
      }
    });
  }

  resetForm() {
    this.featuresForm = this.fb.group({
      features: this.fb.array([
        this.fb.group({
          id: ["", [Validators.required]],
          image: ["", [Validators.required]],
          name: ["", [Validators.required]],
          author_url: ["", [Validators.required]],
          description: ["", [Validators.required]],
          media_type: [this.page == "blog" ? 1 : 2, [Validators.required]],
          is_updated: [""],
        }),
      ]),
    });
  }

  getAuthor() {
    if (this.page == "blog") {
      this.api.getAuthor(1).subscribe((result: any) => {
        this.authorList = result;
        this.fetauresArray = result;
        this.featuresForm = this.fb.group({
          features: new FormArray([]),
        });
        result.forEach((ao) => {
          (this.featuresForm.get("features") as FormArray).push(
            this.fb.group({
              id: [ao.id, [Validators.required]],
              image: [ao.image, [Validators.required]],
              name: [ao.name, [Validators.required]],
              author_url: [ao.author_url, [Validators.required]],
              description: [ao.description, [Validators.required]],
              media_type: [1, [Validators.required]],
              is_updated: [false],
            })
          );
          this.deletedFeatures = [];
        });
      });
    } else if (this.page == "news") {
      this.api.getNewsAuthor(2).subscribe((result: any) => {
        this.authorList = result;
        this.fetauresArray = result;
        this.featuresForm = this.fb.group({
          features: new FormArray([]),
        });
        result.forEach((ao) => {
          (this.featuresForm.get("features") as FormArray).push(
            this.fb.group({
              id: [ao.id, [Validators.required]],
              image: [ao.image, [Validators.required]],
              name: [ao.name, [Validators.required]],
              author_url: [ao.author_url, [Validators.required]],
              description: [ao.description, [Validators.required]],
              media_type: [2, [Validators.required]],
              is_updated: [false],
            })
          );
          this.deletedFeatures = [];
        });
      });
    }
  }

  features(): FormArray {
    return this.featuresForm.get("features") as FormArray;
  }

  newFeatures(): FormGroup {
    return this.fb.group({
      // image: ['', [Validators.required]],
      // heading: ['', [Validators.required]],
      // description: ['', [Validators.required]],
      // is_updated: [true],

      image: ["", [Validators.required]],
      name: ["", [Validators.required]],
      author_url: ["", [Validators.required]],
      description: ["", [Validators.required]],
      media_type: [this.page == "blog" ? 1 : 2, [Validators.required]],
      is_updated: [true],
    });
  }

  addFeatures() {
    this.isAuthorList = true;
    this.seoComp = false;

    (this.featuresForm.get("features") as FormArray).clear();
    this.features().push(this.newFeatures());
  }

  removeFeatures(i: number) {
    this.isSubmitted = false;
    this.featuresForm.value.features[i].is_deleted = true;
    if (this.featuresForm.value.features[i].hasOwnProperty("id"))
      this.deletedFeatures.push(this.featuresForm.value.features[i]);
    this.features().removeAt(i);
  }

  onSubmit(form) {
    if (this.page == "blog") {
      if (form.valid) {
        this.isUpdating = true;
        this.deletedFeatures.forEach((ae) => {
          ae.is_updated = false;
          form.value.features.push(ae);
        });

        this.api.setAuthor(form.value.features).subscribe(
          () => {
            this.toastr.success("Content Updated Successfully!");
            this.getAuthor();
            this.isAuthorList = false;
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        );

        // this.api.updateAuthor(form.value.features,this.authorId).subscribe(
        //   () => {
        //     this.toastr.success("Content Updated Successfully!");
        //     this.getAuthor();
        //     this.hasChanges = false;
        //     this.isUpdating = false;
        //   },
        //   () => {
        //     this.isUpdating = false;
        //   }
        // )
      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }
    } else if (this.page == "news") {
      if (form.valid) {
        this.isUpdating = true;
        this.deletedFeatures.forEach((ae) => {
          ae.is_updated = false;
          form.value.features.push(ae);
        });
        this.api.setNewsAuthor(form.value.features).subscribe(
          () => {
            this.isAuthorList = false;
            this.toastr.success("Content Updated Successfully!");
            this.getAuthor();
            this.hasChanges = false;
            this.isUpdating = false;
          },
          () => {
            this.isUpdating = false;
          }
        );
      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }
    }
  }

  handleIcon(files: FileList, i) {
    this.hasChanges = true;
    this.fileToUpload[i] = files.item(0);
    this.featuresForm.get(["features", i, "is_updated"]).setValue(true);

    const formData: FormData = new FormData();
    formData.append("document", this.fileToUpload[i]);

    this.sharedService
      .uploadFile(formData)
      .subscribe((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            this.progress[i] = Math.round((100 * event.loaded) / event.total);
            break;
          case HttpEventType.Response:
            this.featuresForm
              .get(["features", i, "image"])
              .setValue(event.body.document);
            this.progress[i] = 0;
            break;
        }
      });
    let unsafeImageUrl = URL.createObjectURL(this.fileToUpload[i]);
    this.featuresForm.value.features[i].image =
      this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
    this.dataChanged(i);
  }

  dataChanged(i) {
    if (this.featuresForm.value.features[i].id) {
      this.featuresForm.value.features[i].is_updated = true;
    } else {
      this.featuresForm.value.features[i].is_updated = false;
    }
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  gotoSeo(item: any) {
    this.seoComp = true;
    this.slugId = item.id;
    this.seoType = this.page === "news" ? "news_author" : "blogs_author";
  }

  gotoEditAuthor(item: any) {
    this.authorId = item.id;
    this.isAuthorList = true;

    (this.featuresForm.get("features") as FormArray).clear();

    this.fetauresArray?.forEach((ao, index) => {
      if (item.id == ao.id) {
        (this.featuresForm.get("features") as FormArray).push(
          this.fb.group({
            id: [ao.id, [Validators.required]],
            image: [ao.image, [Validators.required]],
            name: [ao.name, [Validators.required]],
            author_url: [ao.author_url, [Validators.required]],
            description: [ao.description, [Validators.required]],
            media_type: [this.page == "blog" ? 1 : 2, [Validators.required]],
            is_updated: [false],
          })
        );
      }
    });
  }

  backtoList() {
    this.isAuthorList = false;
  }
}

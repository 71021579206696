import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from 'src/app/core/services/shared.service';
import { SubCategoryModalComponent } from '../sub-category-modal/sub-category-modal.component';
import { ProductService } from 'src/app/core/services/product.service';
import { PermissionService } from 'src/app/core/services/permision.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sub-category',
  templateUrl: './sub-category.component.html',
  styleUrls: ['./sub-category.component.scss']
})
export class SubCategoryComponent implements OnInit {
@Input() subCategriesUnderCountry:any
subCategriesUnderCountryCopied:any
@Input() catId;
isGettingSubCat:boolean=false
searchTerm:string=''
constructor(private sharedService:SharedService,private modalService: NgbModal,private api: ProductService,public permission: PermissionService,private toastr: ToastrService){

}
ngOnInit(): void {
  this.subCategriesUnderCountryCopied=this.subCategriesUnderCountry
}

openAddorEditModal(subCat?:any){
  const modalRef = this.modalService.open(SubCategoryModalComponent, {
    size: "lg",
    // keyboard: false,
    backdrop: "static",
    centered: true,
  });
  modalRef.componentInstance.catId = this.catId;
  modalRef.componentInstance.subCategory=subCat?subCat:''
  modalRef.result.then((res)=>{
    if(res){
this.showSubcategories(this.catId)
    }
  })
 
}

showSubcategories(id) {
  this.isGettingSubCat = true;
  

  this.api.getSubcategories(id).subscribe(
    (result) => {
      this.isGettingSubCat = false;
      this.subCategriesUnderCountry = result.sub_categories;
    },
    (error) => {
      this.isGettingSubCat = false;
    }
  );
}

checkPermission(type) {
  return this.permission.hasPermission(type);
}

backToCategory(){
this.sharedService.backTolist()
this.sharedService.changeCatTitle(false)
}

changeStatus(event: any, data: any) {
  const dataToSend = {
    display_status: event.target.checked,
  };
  this.api.changeSubCategoryStatus(data?.id, dataToSend).subscribe((res) => {
   if(res){
    this.toastr.success('Status changed successfully','Success')
   }
  });
}

changeCourierStatus(event: any, data: any){
  const dataToSend = {
    courier_status: event.target.checked,
  };
  this.api.changeSubCategoryCourier(data?.id, dataToSend).subscribe((res) => {
   if(res){
    this.toastr.success('Courier status changed successfully','Success')
   }
  });
}

searchSubCat(searchTerm) {
  if (!searchTerm) {
    this.subCategriesUnderCountry = this.subCategriesUnderCountryCopied;
  } else {
    this.subCategriesUnderCountry = this.subCategriesUnderCountry.filter((ae) =>
      ae.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }
}
}

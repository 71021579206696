<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title"></h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-4">
      <div class="overflow_handling">
        <div class="que_and_sec_heading mb-3">
          SECTION
        </div>
        <div class="position-relative pb-5">
          <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <div class="example-box cpr {{selecteElemInd==i ? 'box_bg_yellow':''}}"
              *ngFor="let data of currData; let i=index" cdkDrag (click)="getSectionsWiseQuestion(data,i)">
              {{data.name}}
              <div class="move" cdkDragHandle>
                <svg width="24px" fill="currentColor" viewBox="0 0 24 24" class="move_cursor_color">
                  <path
                    d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                  </path>
                  <path d="M0 0h24v24H0z" fill="none"></path>
                </svg>
                <div class="example-custom-placeholder" *cdkDragPlaceholder>
                  {{data.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom_save_button">
        <button class="btn btn-primary" type="button" [disabled]="isSectionLoading || !isSectionChanged"
          (click)="sectionOrderSave()">
          <div *ngIf="isSectionLoading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </div>
          <div *ngIf="!isSectionLoading">
            Save
          </div>

        </button>
      </div>

    </div>
    <div class="col-md-8">

      <div class="position-relative pb-5 overflow_handling">
        <div class="que_and_sec_heading mb-3">
          QUESTION
        </div>
        <div class="loader_center d-flex align-items-center justify-content-center" *ngIf="isQueLoading">
          <div class="spinner-border m-5" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div cdkDropList class="example-list" (cdkDropListDropped)="dropQue($event)">
          <div class="example-box" *ngFor="let data of currQueData" cdkDrag>
            <div>
              <div>{{data?.display_name}}</div>
              <div class="{{data.parent <= data?.order  ? 'normal_order_bg':'disorder_bg'}}">
                [order:{{data?.order}},parent:{{data?.parent ? data?.parent:'Nill'}}]</div>
              <div class="text-danger">{{data?.question_error=='No error' ? '':data?.question_error}}</div>
              <div class="text-warning">{{data?.question_warning=='No warning' ? '':data?.question_warning}} </div>
            </div>
            <div class="move" cdkDragHandle>
              <svg width="24px" fill="currentColor" viewBox="0 0 24 24" class="move_cursor_color">
                <path
                  d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
                </path>
                <path d="M0 0h24v24H0z" fill="none"></path>
              </svg>
              <div class="example-custom-placeholder" *cdkDragPlaceholder>
                {{data?.display_name}}
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="bottom_save_button">
        <button class="btn btn-primary" type="button" [disabled]="isQueLoading || !isQueChanged"
          (click)="questionOrderSave()">
          <div *ngIf="isQueLoading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </div>
          <div *ngIf="!isQueLoading">
            Save
          </div>
        </button>
      </div>

    </div>
  </div>

  <div>
    Note: Please refresh for changes to reflect after saving.
  </div>

</div>


<!-- <div class="modal-footer">

  <button>Save</button>
</div> -->
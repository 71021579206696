import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthfakeauthenticationService } from '../services/authfake.service';
import { AuthenticationService } from '../services/auth.service';

import { environment } from '../../../environments/environment';
import { PermissionService } from '../services/permision.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    permission_list = localStorage.getItem('STP_Permissions') ? JSON.parse(atob(localStorage.getItem('STP_Permissions'))) : null;

    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private permissionService: PermissionService,
        private toastr: ToastrService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const requiredPermission = route.data.requiredPermission;
        const currentUser = this.authService.currentUserValue;
        if(requiredPermission){
            if (this.permissionService.hasPermission(requiredPermission) && currentUser) {
                return true;
            } else {
                this.toastr.error("Permission Denied!");
                return false;
            }
        }
        if (currentUser) {
            // logged in so return true
            return true;
        }
        

        // not logged in so redirect to login page with the return url

        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}

<div class="container">
    <form [formGroup]="sideForm" (ngSubmit)="onSubmit(sideForm)">
        <div class="row">
            <div class="col-md-4 mt-2" formArrayName="sideinfo"
                *ngFor="let item of sideinfo().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="sideinfo().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removesideinfo(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="row">
                        <div class="mt-2  col-md-12">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                                (change)="dataChanged(i)">
                        </div>
                        <div class="mt-2  col-md-12">
                            <label class="col-form-label">Sub Heading</label>
                            <input type="text" class="form-control" placeholder="Sub-Heading"
                                formControlName="sub_heading" (change)="dataChanged(i)">
                        </div>
                        <div class="mt-2  col-md-12">
                            <label class="col-form-label">Mobile Number</label>
                            <input type="text" class="form-control" placeholder="" formControlName="phone_no"
                                (change)="dataChanged(i)">
                        </div>
                        <div class="mt-2  row">
                            <div class="col-md-12">
                                <label class="col-form-label">Address</label>
                                <textarea class="form-control" rows="4" placeholder="Address" formControlName="address"
                                    (change)="dataChanged(i)"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button *ngIf="(page=='contact-us')" class="btn btn-primary mt-1 me-1 col-md-12" type="button"
                        (click)="addsideinfo()">
                        <i class="uil-plus"></i> Add
                    </button>
                    <button *ngIf="(page=='contact-us')" class="btn btn-info mt-1 col-md-12" type="submit"
                        [disabled]="!sideForm.dirty">
                        <i class="bx bx-save"></i> Save All
                    </button>
                    <app-publish-btn *ngIf="(page=='contact-us')" class="col-md-12 p-0 mt-1" [publish]="publishPage"
                        [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
import { Component } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss']
})
export class TemplatesComponent {
  
  breadCrumbItems: Array<{}>;
  page = "templates";

  constructor(private sharedService: SharedService,) {}
  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: "Settings" },
      { label: "Templates", active: true },
    ];
  }
}

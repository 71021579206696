import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-change-position-modal',
  templateUrl: './change-position-modal.component.html',
  styleUrls: ['./change-position-modal.component.scss']
})
export class ChangePositionModalComponent {
 
  @Input() arrayToSort:any
  openFor:string;
 
  constructor(public activeModal: NgbActiveModal){

  }
  closeModal(status) {
    this.activeModal.close(status);
  }
  

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.arrayToSort, event.previousIndex, event.currentIndex);
  }
  confirmSort(){
       const arrayModified=this.setPosition()
       this.activeModal.close(arrayModified)
  }

  setPosition(){
    const modifiedArray:any=[]
    if(this.openFor==='create'){
      this.arrayToSort.forEach((res,index)=>{
        let data={
          answer_choice:res.answer_choice,
          id:res.id,
        }
        modifiedArray.push(data)
      })
    }else{
      this.arrayToSort.forEach((res,index)=>{
        let data={
          answer_choice:res.answer_choice,
          id:res.id,
          position:index
        }
        modifiedArray.push(data)
      })
    }
  
  return modifiedArray
  }
}

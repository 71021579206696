import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowService } from 'src/app/core/services/workflow.service';

@Component({
  selector: 'app-flow-notifications',
  templateUrl: './flow-notifications.component.html',
  styleUrls: ['./flow-notifications.component.scss']
})

export class FlowNotificationsComponent implements OnInit{

  @Input() nodeDetails;
  templateTypes = this.api.templateTypes;
  selectedTabIndex:number;
  notificationList = [];
  tabsDiy = ['DIY', 'Consultant'];
  tabSelect = [true, false];
  selectedTab:string = 'DIY';
  reloadTab = true;

  constructor(public activeModal: NgbActiveModal, public api: WorkflowService) { }

  ngOnInit(): void {
    if(this.templateTypes.length==0) this.getTemplateTypes();
    this.nodeDetails.tabs.forEach((ae,i) => {
      if(ae.name == this.nodeDetails.tab) this.selectedTabIndex = i;
    });

    if(this.nodeDetails.tab == 'Consultant') this.tabSelect = [false, true];

    if(this.nodeDetails.stepType == 1) this.selectedTab = this.nodeDetails.tab;

  }

  closeModal() {
    this.activeModal.close('close');
  }

  getTemplateTypes(){
    this.api.getTemplateTypes().subscribe(
      result=>{
        this.api.templateTypes = this.templateTypes = result;
      }
    )
  }

  reloadTabFn(){
    this.reloadTab = false;
    setTimeout(() => { this.reloadTab = true; });
  }

}

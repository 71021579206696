<form [formGroup]="pricingForm">
  <div
   
    formArrayName="price"
    *ngFor="let item of price().controls; let i = index"
    cdkDrag
  >
    <div class="example-handle"></div>
    <div class="example-custom-placeholder"></div>
    <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
      <div class="row">
        <!-- <div class="mt-2 col-md-12">
          <label class="col-form-label">Name</label>
          <input type="text" class="form-control" placeholder="Name"  formControlName="name" readonly/>
        </div> -->
        <!-- <div class="mt-2 col-md-12">
          <label class="col-form-label">Info</label>
          <input type="text" class="form-control" placeholder="Sub Name"    formControlName="sub_name" readonly/>
        </div> -->
        <!-- <div class="mt-2 col-md-6">
          <label class="col-form-label">Currency&nbsp;Symbol</label>
          <input
            type="text"
            class="form-control"
            placeholder="Currency Symbol"
            formControlName="currency_symbol"
            
          />
        </div> -->
        <!-- <div class="mt-2 col-md-6">
          <label class="col-form-label">Price</label>
          <input
            type="number"
            min="0"
            class="form-control"
            placeholder="price"
            formControlName="price"
            readonly
          />
        </div> -->

        <div class="mt-2 col-md-6">
          <label class="col-form-label">DIY Fee</label>
          <input
          (change)="transferDescription()"
            type="number"
            min="0"
            class="form-control"
            placeholder="Consultant Fee"
            formControlName="mearch_fee"
            readonly
          />
        </div>
        <!-- <div class="mt-2 col-md-6">
          <label class="col-form-label">Tax</label>
          <input type="number" min="0" class="form-control" placeholder="Tax"  formControlName="tax"
          readonly />
        </div> -->
      </div>
        <div class="row" formArrayName="diy_description">
          <div class="mt-2 col-md-12" *ngFor="let description of item.get('diy_description').controls; let j=index">
           <div  [formGroupName]="j">
            <label class="col-form-label">DIY Description</label>
            <input
            (change)="transferDescription()"
              type="text"
              min="0"
              class="form-control"
              placeholder="DIY Fee"
              formControlName="dydescription"
            />
            <a
                  href="javascript:void(0)"
                  class="ms-3 close-btn"
                  (click)="deleteDescription(item.controls.diy_description, j)"
                  ><i class="uil-times fa-2x"></i
                ></a>
           </div>
           <div>
            
          </div>
          </div>
          <button class="btn btn-info mt-3 col-md-12" (click)="addDescription(i)">Add Decsription</button>
        </div>
       
      
    </div>
  </div>
</form>

<div class="container-fluid">
  <!-- start page title -->
  <app-page-title
    title="Countries"
    [breadcrumbItems]="breadCrumbItems"
  ></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <!-- <div class="col-sm-12 col-md-2">
                            <div class="dataTables_length mt-1" id="tickets-table_length"><label
                                    class="d-inline-flex align-items-center">Show
                                    <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                                        [(ngModel)]="pageSize" class="form-control form-control-sm mx-2">
                                        <option [ngValue]="5">5</option>
                                        <option [ngValue]="10">10</option>
                                        <option [ngValue]="25">25</option>
                                        <option [ngValue]="50">50</option>
                                        <option [ngValue]="100">100</option>
                                    </select> entries</label></div>
                        </div>
                        <div class="col-sm-12 col-md-3">
                            <div id="tickets-table_filter" class="dataTables_filter">
                                <div class="d-flex">
                                    <ng-select class="w-100" [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
                                    bindValue="id"  [(ngModel)]="searchTerm" [clearable]="false" (change)="searchCountry(searchTerm)"></ng-select>
                                    <div *ngIf="searchTerm" class="d-flex">
                                        <a href="javascript:void(0)" class="my-auto mx-2" (click)="searchTerm='';">
                                            <i class="fa fa-times text-danger"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <div class="border d-flex py-2">
                                <div class="form-check m-auto">
                                    <input class="form-check-input" type="radio" name="status" id="status_published" [(ngModel)]="status" value="published">
                                    <label class="form-check-label" for="status_published">Published</label>
                                </div>
                                <div class="form-check m-auto">
                                    <input class="form-check-input" type="radio" name="status" id="status_inprogress" [(ngModel)]="status" value="in-progress">
                                    <label class="form-check-label" for="status_inprogress">In-progress</label>
                                </div>
                                <div class="form-check m-auto">
                                    <input class="form-check-input" type="radio" name="status" id="status_any" [(ngModel)]="status" value="any">
                                    <label class="form-check-label" for="status_any">Any</label>
                                </div>
                            </div>
                        </div> -->
            <div class="col-md-3">
              <div id="tickets-table_filter" class="dataTables_filter">
                <div class="d-flex">
                  <input
                    class="form-control"
                    type="text"
                    [(ngModel)]="searchTerm"
                    placeholder="Search Country"
                    (keyup)="searchCountry(searchTerm)"
                  />
                  <div *ngIf="searchTerm" class="d-flex" autocomplete="off">
                    <a
                      href="javascript:void(0)"
                      class="my-auto mx-2"
                      (click)="searchTerm = ''; searchCountry(searchTerm)"
                    >
                      <i class="fa fa-times text-danger"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 offset-md-6"
              *ngIf="checkPermission('product_management_country_create')"
            >
              <button
                type="button"
                class="btn btn-success float-end"
                (click)="addCountryModal()"
              >
                <i class="mdi mdi-plus me-1"></i> Add Country
              </button>
            </div>
          </div>
          <!-- Table -->
          <div class="table-responsive" *ngIf="!isLoading">
            <table
              id="basic-datatable"
              class="table table-centered datatables dt-responsive nowrap table-card-list"
            >
              <thead>
                <tr>
                  <th>Country</th>
                  <th class="text-center">Jurisdiction</th>
                  <th class="text-center">Products</th>
                  <th class="text-center">Active</th>
                  <th class="text-center">Lead Form</th>
                  <th class="text-center">Hotel</th>
                  <th class="text-center">Flight</th>
                  <th class="text-center">Insurance</th>
                  <th class="text-center">Forex</th>
                  <th class="text-center">Courier</th>
                  <th class="text-center">Itinerary</th>
                  <th
                    class="text-center"
                    *ngIf="checkPermission('product_management_country_edit')"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let table of countryData; let i = index">
                  <td>
                    <ngb-highlight>{{ table.name }}</ngb-highlight>
                  </td>
                  <td class="text-center">
                    <ngb-highlight>{{
                      table.jurisdiction_count
                    }}</ngb-highlight>
                  </td>
                  <td class="text-center">
                    <ngb-highlight>{{ table.products_count }}</ngb-highlight>
                  </td>
                  <!-- <td class="text-center">
                                        <ngb-highlight>{{table.active}}</ngb-highlight>
                                    </td> -->
                  <td>
                    <div class="d-flex">
                      <div
                        class="form-check form-switch form-switch-lg m-auto"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          [disabled]="
                            !checkPermission(
                              'product_management_country_active'
                            )
                          "
                          class="form-check-input"
                          id="customSwitchsizelg"
                          [attr.checked]="table.display_status ? true : null"
                          (change)="changeStatus($event, table)"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div
                        class="form-check form-switch form-switch-lg m-auto"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="lead_form_{{ i }}"
                          [checked]="table.lead_form"
                          (change)="changeLdFrmStatus($event, table)"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div
                        class="form-check form-switch form-switch-lg m-auto"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="hotel{{ i }}"
                          [checked]="table.hotel_add"
                          (change)="changeHotel($event, table)"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div
                        class="form-check form-switch form-switch-lg m-auto"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="hotel{{ i }}"
                          [checked]="table.flight_add"
                          (change)="changeFlight($event, table)"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div
                        class="form-check form-switch form-switch-lg m-auto"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="hotel{{ i }}"
                          [checked]="table.insurance_add"
                          (change)="changeInsurance($event, table)"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div
                        class="form-check form-switch form-switch-lg m-auto"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="hotel{{ i }}"
                          [checked]="table.forex_add"
                          (change)="changeForex($event, table)"
                        />
                      </div>
                    </div>
                  </td>

                  <td>
                    <div class="d-flex">
                      <div
                        class="form-check form-switch form-switch-lg m-auto"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="hotel{{ i }}"
                          [checked]="table.courier_add"
                          (change)="changeCourier($event, table)"
                        />
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <div
                        class="form-check form-switch form-switch-lg m-auto"
                        dir="ltr"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="hotel{{ i }}"
                          [checked]="table.itinerary_add"
                          (change)="changeItinerary($event, table)"
                        />
                      </div>
                    </div>
                  </td>
                  <td
                    class="text-center"
                    *ngIf="checkPermission('product_management_country_edit')"
                  >
                    <a class="cpr" (click)="editCountry(table)"
                      ><i class="fas fa-edit text-info"></i
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="isLoading" class="d-flex mt-5">
            <i class="fa fa-spin fa-spinner fa-pulse fa-5x m-auto"></i>
          </div>
          <!-- End table -->
          <div
            class="row justify-content-md-between align-items-md-center mt-2"
          >
            <div class="col-sm-12 col-md-5">
              <div
                class="dataTables_info mb-2"
                id="tickets-table_info"
                role="status"
                aria-live="polite"
              >
                Showing
                {{ (pageNumber - 1) * pageSize }} to
                {{
                  pageNumber * pageSize < page_details.count
                    ? pageNumber * pageSize
                    : page_details.count
                }}
                of {{ page_details.count }} entries
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-5">
              <div class="text-md-end float-md-end pagination-rounded">
                <ngb-pagination
                  [collectionSize]="page_details.count"
                  [(page)]="pageNumber"
                  [pageSize]="pageSize"
                  (pageChange)="nextPage(pageNumber)"
                >
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- container-fluid -->

<ng-template #countryDetails let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="modal-basic-title">Edit Country Details</h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    ></button>
  </div>
  <div class="modal-body">
    <app-country-details
      [fromAdd]="false"
      [countriesList]="countryList"
      [countryData]="selectedData"
      (closeModal)="closeModal($event)"
    ></app-country-details>
  </div>
</ng-template>

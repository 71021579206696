import { Component, Input } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-publish-btn',
  templateUrl: './publish-btn.component.html',
  styleUrls: ['./publish-btn.component.scss']
})
export class PublishBtnComponent {

  @Input() publish;
  @Input() page;
  @Input() value_from_page;
  filter_value;
  filter_key;
  isPublishing = false; 
  seoPublish = ['SeoDatas', 'HomeSeo', 'CountrySeo', 'CategorySeo', 'ProductSeo', 'FaqCountrySeo', 'FaqSeo', 'VisaHomeSeo', 'ConsultantPageSeo', 'DiyAssistanceSeo', 'TestimonialSeo', 'AboutUsSeo']

  constructor(public sharedService: SharedService, public toastr: ToastrService){}

  ngOnInit(){
    switch (this.page) {
      case 'country': this.filter_key = this.page; break;
      case 'category': this.filter_key = this.page; break;
      case 'product': this.filter_key = this.page; break;
      case 'seo': this.filter_key = this.page; break;
      case 'faq': this.filter_key = 'faq_entity'; break;
      default: this.filter_key = null;this.filter_value = null; break;
    }
  }

  publishSection(){
    this.isPublishing = true;
    if(this.publish=='CategoryDetail') this.filter_key = 'category__country';
    else if(this.publish=='ProductDetail' && this.page=='country') this.filter_key = 'product__category__country';
    else if(this.publish=='ProductDetail' && this.page=='category') this.filter_key = 'product__category';
    else if(this.publish=='ProductDetail' && this.page=='home') 
    {this.filter_key = 'is_starred'; this.value_from_page = true;}
    this.sharedService.bulkPublish({
      entity: this.publish,
      filter_key: this.filter_key,
      update_related : this.seoPublish.includes(this.publish),
      filter_value: this.value_from_page ? this.value_from_page :( this.page=='country' ? this.sharedService.selectedCmsCountryId : (this.page=='category' ? this.sharedService.selectedCmsCategoryId : (this.page=='product' ? this.sharedService.selectedCmsProductId : null)))
    }).subscribe(
      result=>{
        this.isPublishing = false;
        this.toastr.success("Published Successfully!")
      },
      error=>{
        this.isPublishing = false;
      }
    )
   
  }

}

<div class="container-fluid">
    <!-- start page title -->
    <app-page-title title="Faq Page" [breadcrumbItems]="breadCrumbItems"></app-page-title>

    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <!-- orientation="vertical"  -->
                    <div class="row">
                        <!-- <div class="col-md-4"></div> -->
                        <div class="col-md-12">
                            <app-preview-btn [page]="page"></app-preview-btn>
                            <ul ngbNav #justifiednavpills="ngbNav" [activeId]="'G1'"
                                class="nav-pills nav-justified bg-light">
                                <li [ngbNavItem]="'G1'">
                                    <a ngbNavLink>
                                        <span>Header</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <app-header [page]="page"></app-header>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="'G2'" (click)="selectGeneral()">
                                    <a ngbNavLink>
                                        <span>General</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <app-faq-section #faqGeneralSection [page]="page"></app-faq-section>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="'G3'">
                                    <a ngbNavLink>
                                        <span>Country</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <div class="my-3 row">
                                            <div class="offset-md-8 col-md-4">
                                                <ng-select [(items)]="countryList" [multiple]="false"
                                                    [placeholder]="'Select Country'" bindLabel="name" bindValue="id"
                                                    [(ngModel)]="country" [clearable]="false"
                                                    (change)="selectCountry(country)"></ng-select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6 offset-lg-3 set-minwh-1" *ngIf="country==null;">
                                                <div class="card text-center p-4">
                                                    Select Country to Continue
                                                </div>
                                            </div>
                                            <div class="col-12" *ngIf="country">
                                                <ul ngbNav #faqSecondNav="ngbNav" 
                                                    class="nav-pills nav-justified bg-light" [activeId]="0">
                                                    <!-- [activeId]="1" -->
                                                    <li *ngFor="let item of categoryList; let i = index" [ngbNavItem]="i"
                                                        (click)="selectCategory(item)">
                                                        <a ngbNavLink>
                                                            <span>{{ item?.name }}</span>
                                                        </a>
                                                        <ng-template ngbNavContent>
                                                            <app-faq-section #faqSection [category]="categoryId" [page]="page"></app-faq-section>
                                                        </ng-template>
                                                    </li>
                                                </ul>
                                                <div [ngbNavOutlet]="faqSecondNav"></div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-12">
                            <div [ngbNavOutlet]="justifiednavpills"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AboutUsPageService } from 'src/app/core/services/about-us-page.service';
import { PermissionService } from 'src/app/core/services/permision.service';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-about-infos',
  templateUrl: './about-infos.component.html',
  styleUrls: ['./about-infos.component.scss']
})
export class AboutInfosComponent implements OnInit {

  aboutForm: FormGroup;
  hasChanges = false;

  isUpdating = false;
  deletedinfos = [];
  isSubmitted = false;
  @Input() page;
  constructor(private fb: FormBuilder, private api: AboutUsPageService, public permission: PermissionService,
    private toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.resetForm();
    this.getInfos();
  }

  resetForm() {
    this.aboutForm = this.fb.group({
      infos: this.fb.array([
        this.fb.group({
          'id': [''],
          'icon_url': ['', [Validators.required]],
          'heading': ['', [Validators.required]],
          'description': ['', [Validators.required]],
          'is_updated': [''],
        })])
    });
  }

  getInfos() {
    this.api.getInfoData().subscribe(
      (result: any) => {
        this.aboutForm = this.fb.group({
          infos: new FormArray([]),
        });
        result.forEach(ao => {
          (this.aboutForm.get('infos') as FormArray).push(
            this.fb.group({
              id: [ao.id],
              icon_url: [ao.icon_url, [Validators.required]],
              heading: [ao.heading, [Validators.required]],
              description: [ao.description, [Validators.required]],
              is_updated: [false],
            })
          );
          this.deletedinfos = [];
        });
      })
  }

  infos(): FormArray {
    return this.aboutForm.get("infos") as FormArray
  }

  newinfos(): FormGroup {
    return this.fb.group({
      icon_url: ['', [Validators.required]],
      heading: ['', [Validators.required]],
      description: ['', [Validators.required]],
      is_updated: [true],
    })
  }

  addinfos() {
    this.infos().push(this.newinfos());
  }

  removeinfos(i: number) {
    this.isSubmitted = false;
    this.aboutForm.value.infos[i].is_deleted = true;
    if (this.aboutForm.value.infos[i].hasOwnProperty("id")) this.deletedinfos.push(this.aboutForm.value.infos[i]);
    this.infos().removeAt(i);
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedinfos.forEach(ae => {
        ae.is_updated = false;
        form.value.infos.push(ae);
      })
      this.api.setInfoData(form.value.infos).subscribe(
        () => {
          this.toastr.success("Content Updated Successfully!");
          this.getInfos();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        }
      )
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save");
    }
  }

  dataChanged(i) {
    this.aboutForm.value.infos[i].is_updated = true;
  }
  
  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  openUploadModal(i, field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if(result.status) {
        this.aboutForm.get(['infos', i, field]).setValue(result.image);
        this.aboutForm.get(['infos', i, 'is_updated']).setValue(true);
      }
    });
}

}


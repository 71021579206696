import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { AccessManagementService } from "src/app/core/services/access-management.service";
import { PermissionItemComponent } from "./permission-item/permission-item.component";

@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.scss"],
})
export class RolesComponent implements OnInit {
  userRole = [];
  permissionList = [];
  selectedRoleId: number;

  showPermission: boolean = false;

  @ViewChild(PermissionItemComponent)
  permissionComponent: PermissionItemComponent;

  constructor(private api: AccessManagementService) {}

  ngOnInit(): void {
    this.getDataList();
  }

  addRole() {
    this.showPermission = true;
    this.permissionComponent.resetForm();
  }

  showPermissionDetails(id) {
    this.selectedRoleId = id;
    this.api.getUserPermissionDetailsId(this.selectedRoleId).subscribe(
      (result: any) => {
        this.permissionList = result;
        this.permissionComponent.resetForm(this.permissionList);
      },
      (error) => {
        console.log(error);
      }
    );
    this.showPermission = true;
  }

  getDataList() {
    this.api.getUserRole().subscribe(
      (result: any) => {
        this.userRole = result;
      },
      (error) => {
        console.log(error);
      }
    );
  }
}

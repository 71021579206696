import { ChangeDetectorRef, Component, Input } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NotificationTemplatesService } from "src/app/core/services/notification-templates.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: 'app-add-whatsapp',
  templateUrl: './add-whatsapp.component.html',
  styleUrls: ['./add-whatsapp.component.scss']
})
export class AddWhatsappComponent {

  @Input() fromParent: any;
  userWabaForm: FormGroup;
  isSubmitted = false;
  isAdding = false;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private api: NotificationTemplatesService,
    private toastr: ToastrService,
    private cdref: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.basicFormDetails(this.fromParent);
  }

  basicFormDetails(data?) {
    this.userWabaForm = this.fb.group({
      id: [data ? data.id : null],
      name: [data ? data.name : "", [Validators.required]],
      language: [data ? data.language : "", [Validators.required]],
      memory_variables: new FormArray([])
    });
    data.memory_variables.forEach(ao => {
      (this.userWabaForm.get('memory_variables') as FormArray).push(
        this.fb.group({
          cf_variable: [ao.cf_variable, [Validators.required]],
          stp_variable: [ao.stp_variable ? ao.stp_variable : "", [Validators.required]]
        })
      );
    });
  }

  closeModal(status) {
    this.activeModal.close(status);
  }

  onSubmit(form){
    this.isAdding = true;
    this.api.setWhatsappMemoryVariables(form.value).subscribe(
      result=>{
        this.isAdding = false;
        this.closeModal(true);
      },
      err=>{
        this.isAdding = false;
      }
    )
  }

  get memory_variables() : FormArray {
    return this.userWabaForm.get("memory_variables") as FormArray
  }


}

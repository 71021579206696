import { Component } from '@angular/core';

@Component({
  selector: 'app-term-details',
  templateUrl: './term-details.component.html',
  styleUrls: ['./term-details.component.scss']
})
export class TermDetailsComponent {

  termDetailsList = [
    { name: '7-Day' }
  ];

  categoryTypes = [
    { name: 'Business' }
  ];

}

import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Directive({
  selector: "[appOutsideClick]",
})
export class OutsideClickDirective implements OnInit {
  @Output() dataChanged: EventEmitter<any> = new EventEmitter<boolean>(false);
  @Input() index: number;
  @Input() table: any;
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
   
  }

  @HostListener("ngModelChange", ["$event.target"])
  public onClick(targetElement: any) {
      const data={
        index:this.index,
        value:this.elementRef.nativeElement.value,
        tableData:this.table
      }
      this.dataChanged.emit(data);
  }
}

<div class="container">
    <div class="my-3 row">
        <label class="col-md-4 col-form-label">Please select a Country</label>
        <div class="col-md-3">
            <ng-select [(items)]="countryList" [multiple]="true" [placeholder]="'Select Country'" bindLabel="name"
                bindValue="id" [(ngModel)]="country" [closeOnSelect]="false" [clearable]="false"></ng-select>
        </div>
    </div>
    <div class="my-3 row">
        <div class="col-md-12 text-end">
            <button type="submit" class="btn btn-primary" (click)="showNextStepRole(country)">Next</button>
        </div>
    </div>
</div>
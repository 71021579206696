<div class="container-fluid">
  <app-page-title
    title="Add-On Page"
    [breadcrumbItems]="breadCrumbItems"
  ></app-page-title>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <ul
            ngbNav
            #justifiednavpills="ngbNav"
            [activeId]="1"
            class="nav-pills nav-justified bg-light"
          >
            <li [ngbNavItem]="1" (click)="setBreadCrumb('Insurance')">
              <a ngbNavLink>
                <span>Insurance</span>
              </a>
              <ng-template ngbNavContent >
                <app-add-on-insurance></app-add-on-insurance>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" (click)="setBreadCrumb('Hotel and Flight')">
              <a ngbNavLink>
                <span>Hotel,Flight and Courier</span>
              </a>
              <ng-template ngbNavContent>
                <app-add-on-hotel-flight></app-add-on-hotel-flight>
              </ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="justifiednavpills"></div>
        </div>
      </div>
    </div>
  </div>
</div>

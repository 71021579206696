import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { UIModule } from "../../shared/ui/ui.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  NgbDatepickerModule,
  NgbPaginationModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AccessManagementComponent } from "./access-management/access-management.component";
import { UserComponent } from "./access-management/user/user.component";
import { RolesComponent } from "./access-management/roles/roles.component";
import { PermissionItemComponent } from "./access-management/roles/permission-item/permission-item.component";
import { AddUserComponent } from "./access-management/user/add-user/add-user.component";
import { UserDetailsComponent } from "./access-management/user/add-user/user-details/user-details.component";
import { UserRoleDetailsComponent } from "./access-management/user/add-user/user-role-details/user-role-details.component";
import { NgWizardConfig, NgWizardModule, THEME } from "ng-wizard";
import { GlobalQuestionsComponent } from "./global-questions/global-questions.component";
import { FormModalComponent } from "./form-modal/form-modal.component";
import { DeleteSectionComponent } from "./delete-section/delete-section.component";
import { WorkFlowComponent } from "./work-flow/work-flow.component";
import { CountryQuestionsComponent } from "./country-questions/country-questions.component";
import { AddedQueModalComponent } from "./added-que-modal/added-que-modal.component";
import { CountryFormModalComponent } from "./country-form-modal/country-form-modal.component";
import { OrderModalComponent } from "./order-modal/order-modal.component";
import { FlowViewComponent } from "./work-flow/flow-view/flow-view.component";
import { FlowNotificationsComponent } from "./work-flow/flow-notifications/flow-notifications.component";
import { TemplatesComponent } from "./templates/templates.component";
import { MessageComponent } from "./templates/message/message.component";
import { EmailComponent } from "./templates/email/email.component";
import { AddMessageComponent } from "./templates/message/add-message/add-message.component";
import { AddEmailComponent } from "./templates/email/add-email/add-email.component";
import { CoverLetterComponent } from "./templates/cover-letter/cover-letter.component";
import { AddCoverLetterComponent } from "./templates/cover-letter/add-cover-letter/add-cover-letter.component";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { SlaComponent } from "./work-flow/notify-types/sla/sla.component";
import { NotificationsComponent } from "./work-flow/notify-types/notifications/notifications.component";
import { NotifyTabComponent } from "./work-flow/notify-tab/notify-tab.component";
import { CountryAssignComponent } from "./access-management/user/add-user/country-assign/country-assign.component";
import { DeleteModalComponent } from "./work-flow/delete-modal/delete-modal.component";
import { IntegrationsComponent } from "./integrations/integrations.component";
import { AddIntegrationComponent } from "./integrations/add-integration/add-integration.component";
import { WhatsappComponent } from "./templates/whatsapp/whatsapp.component";
import { AddWhatsappComponent } from "./templates/whatsapp/add-whatsapp/add-whatsapp.component";
import { TriggerPointComponent } from "./templates/trigger-point/trigger-point.component";
import { ChangePositionModalComponent } from "./country-form-modal/change-position-modal/change-position-modal.component";
import { AriseCouponComponent } from "./arise-coupon/arise-coupon.component";
import { PieChartsComponent } from "./pie-charts/pie-charts.component";
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DatePickerComponent } from './pie-charts/date-picker/date-picker.component';
import { NgxPieChartComponent } from './pie-charts/ngx-pie-chart/ngx-pie-chart.component';
import { ChartTableComponent } from './chart-table/chart-table.component';
import { DateRangePickerComponent } from './chart-table/date-range-picker/date-range-picker.component';
import { CountryTableReportComponent } from "./country-table-report/country-table-report.component";
const routes: Routes = [
  { path: "access-management", component: AccessManagementComponent },
  { path: "global-questions", component: GlobalQuestionsComponent },
  { path: "delete-data", component: DeleteSectionComponent },
  { path: "work-flow", component: WorkFlowComponent },
  { path: "country-questions", component: CountryQuestionsComponent },
  { path: "templates", component: TemplatesComponent },
  { path: "integrations", component: IntegrationsComponent },
  { path: "arise-coupon", component: AriseCouponComponent },
  { path: "pie-charts", component: PieChartsComponent },
  { path: "date-table", component: ChartTableComponent },
  { path: "country-table", component: CountryTableReportComponent },
];

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default,
};

@NgModule({
  declarations: [
    AccessManagementComponent,
    GlobalQuestionsComponent,
    UserComponent,
    RolesComponent,
    PermissionItemComponent,
    AddUserComponent,
    UserDetailsComponent,
    UserRoleDetailsComponent,
    FormModalComponent,
    CountryFormModalComponent,
    AddedQueModalComponent,
    OrderModalComponent,
    DeleteSectionComponent,
    WorkFlowComponent,
    CountryQuestionsComponent,
    FlowViewComponent,
    FlowNotificationsComponent,
    TemplatesComponent,
    MessageComponent,
    EmailComponent,
    AddMessageComponent,
    AddEmailComponent,
    CoverLetterComponent,
    AddCoverLetterComponent,
    SlaComponent,
    NotificationsComponent,
    NotifyTabComponent,
    CountryAssignComponent,
    DeleteModalComponent,
    IntegrationsComponent,
    AddIntegrationComponent,
    WhatsappComponent,
    AddWhatsappComponent,
    TriggerPointComponent,
    ChangePositionModalComponent,
    AriseCouponComponent,
    PieChartsComponent,
    DatePickerComponent,
    NgxPieChartComponent,
    ChartTableComponent,
    DateRangePickerComponent,
    CountryTableReportComponent
  ],

  imports: [
    UIModule,
    FormsModule,
    NgbNavModule,
    CommonModule,
    DragDropModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgSelectModule,
    CKEditorModule,
    RouterModule.forChild(routes),
    NgWizardModule.forRoot(ngWizardConfig),
    NgxChartsModule,
    NgbDatepickerModule
  ],
  exports: [RouterModule],
})
export class SettingsModule {}

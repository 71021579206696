import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testimonial-page',
  templateUrl: './testimonial-page.component.html',
  styleUrls: ['./testimonial-page.component.scss']
})
export class TestimonialPageComponent implements OnInit{

  breadCrumbItems: Array<{}>;
  page = 'testimonial';

  ngOnInit(){
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'Testimonial Page', active: true }];
  }

}

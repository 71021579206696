<div class="container-fluid">
    <!-- start page title -->
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-md-2">
                        <div class="col-md-3 offset-md-9">
                            <div id="tickets-table_filter" class="dataTables_filter">
                                <div class="d-flex">
                                    <input class="form-control" type="text" [(ngModel)]="searchTerm"
                                        placeholder="Search Country" (keyup)="searchCountry(searchTerm)">
                                    <div *ngIf="searchTerm" class="d-flex" autocomplete="off">
                                        <a href="javascript:void(0)" class="my-auto mx-2"
                                            (click)="searchTerm='';searchCountry(searchTerm)">
                                            <i class="fa fa-times text-danger"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Table -->
                    <div class="table-responsive" *ngIf="!isLoading">
                        <table id="basic-datatable"
                            class="table table-centered datatables dt-responsive nowrap table-card-list">
                            <thead>
                                <tr>
                                    <th>Country</th>
                                    <th class="text-center">Keywords</th>
                                    <th class="text-center">Starred</th>
                                    <th class="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let table of countryData;let i=index">
                                <tr>
                                    <td>
                                        <ngb-highlight>{{table.name}}</ngb-highlight>
                                    </td>
                                    <td class="text-center">
                                        <div *ngIf="table.keywords.length>0">{{table.keywords}}</div>
                                        <small class="badge bg-danger text-white text-center" *ngIf="table.keywords.length == 0">No Keywords</small>
                                    </td>
                                    <td class="text-center">
                                        <i [ngClass]="{'selected': table.is_starred}" class="bx bxs-star"></i>
                                    </td>
                                    <td class="text-center">
                                        <a class="cpr" (click)="showSortModal(table)"><i
                                                class="fas fa-edit text-info"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div *ngIf="isLoading" class="d-flex mt-5">
                        <i class="fa fa-spin fa-spinner fa-pulse fa-5x m-auto"></i>
                    </div>
                    <!-- End table -->
                </div>
            </div>
        </div>
    </div>
</div> <!-- container-fluid -->
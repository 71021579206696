<div class="row mt-3">
  <div [ngClass]="{'offset-md-2': title!='diy'}" class="col-md-4">
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <div class="row">
            <div class="col-md-6 text-muted">Application&nbsp;Date</div>
            <div class="col-md-6 fw-bold text-end">
              {{ applicationDetails?.application_date }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-5 text-muted">Application&nbsp;No.</div>
            <div class="col-md-7 fw-bold text-end">
              {{ applicationDetails?.application_no }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6 text-muted">Country</div>
            <div class="col-md-6 fw-bold text-end">
              {{ applicationDetails?.country }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6 text-muted">Category</div>
            <div class="col-md-6 fw-bold text-end">
              {{ applicationDetails?.category }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6 text-muted">Product Type</div>
            <div class="col-md-6 fw-bold text-end">
              {{ applicationDetails?.product_type }}
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-6 text-muted">Total Travelers</div>
            <div class="col-md-6 fw-bold text-end">
              {{ applicationDetails?.total_travellers }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <div class="row">
            <div class="col-md-6 text-muted">Agent</div>
            <div class="col-md-6 fw-bold text-end">
              {{ applicationDetails?.agent }}
            </div>
          </div>
          <div class="row mt-2" *ngFor="let fee of paymentDetails?.fee_details">
            <div class="col-md-7 text-muted">{{ fee.fee_category }}</div>
            <div class="col-md-5 fw-bold text-end">
              {{ fee.currency_symbol }} {{ fee.price }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4" *ngIf="title==='diy'">
    <div class="card">
        <div class="card-body">
            <div class="table-responsive">
             
              <div class="row mt-2">
                <div class="col-md-5 text-muted">Error occured</div>
                <div class="col-md-7 fw-bold text-end">
                  {{ error }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-6 text-muted">Insurance paid</div>
                <div class="col-md-6 fw-bold text-end">
                  {{ insurance  }}
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-6 text-muted">Tata api call</div>
                <div class="col-md-6 fw-bold text-end">
                  {{apiCalls }}
                </div>
              </div>
             
              
            </div>
          </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(private http: HttpClient) { }


  
  // getSideInfo(): Observable<any> {
  //   return this.http.get(`${environment.apiUrl}/cms-admin/about-us/sideinfo/`).pipe(retry(2));
  // }

  getDescription(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/contact-us/description/`);
  }

  getSideInfo(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/contact-us/sideinfo/`);
  }

  postDescription(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/contact-us/description/`,data);
  }

  postSideInfo(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/contact-us/sideinfo/`, data);
  }

  editDescription(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/contact-us/description/${id}/`, data);
  }

  // editSideInfo(id, data): Observable<any> {
  //   return this.http.put(`${environment.apiUrl}/cms-admin/contact-us/sideinfo/${id}/`, data);
  // }


  

  
}

<div class="container">
  <form class="row" [formGroup]="headerForm" (ngSubmit)="onSubmit(headerForm)">
    <div class="col-md-8 offset-md-2 p-4 mt-2">
      <div class="border shadow p-3">
        <div class="my-2 row">
          <label class="col-md-4 col-form-label">Image URL</label>
          <div class="col-md-8 d-flex">
            <input type="text" class="form-control" placeholder="URL" formControlName="image_url">
            <a [href]="headerForm.value.image_url" target="_blank"><img class="ms-1" height="40"
                [src]="headerForm.value.image_url"></a>
            <button class="btn btn-primary ms-1" type="button" (click)="openUploadModal('image_url')"><i
                class="fas fa-cloud-upload-alt"></i></button>
          </div>
        </div>
        <div class="my-2 row">
          <label class="col-md-4 col-form-label">Image URL for Mobile</label>
          <div class="col-md-8 d-flex">
            <input type="text" class="form-control" placeholder="URL" formControlName="mob_banner_image_url">
            <a [href]="headerForm.value.mob_banner_image_url" target="_blank"><img class="ms-1" height="40"
                [src]="headerForm.value.mob_banner_image_url"></a>
            <button class="btn btn-primary ms-1" type="button" (click)="openUploadModal('mob_banner_image_url')"><i
                class="fas fa-cloud-upload-alt"></i></button>
          </div>
        </div>
        <div class="my-2 row">
          <div class="col-md-12">
            <button *ngIf="(page=='home' && checkPermission('content_management_home_create'))||(page=='testimonial' && checkPermission('content_management_testimonial_create')) || (page=='faq' && checkPermission('content_management_faq_create')) || 
            (page=='consultant' && checkPermission('content_management_consultant_create')) && (page=='home' && checkPermission('content_management_home_edit'))||
            (page=='testimonial' && checkPermission('content_management_testimonial_edit')) || (page=='faq' && checkPermission('content_management_faq_edit')) || 
            (page=='consultant' && checkPermission('content_management_consultant_edit'))"
              class="btn btn-info float-end me-5"><i
                [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i> Save
              Changes</button>
            <app-publish-btn *ngIf="(page=='home' && checkPermission('content_management_home_publish'))||(page=='testimonial' && checkPermission('content_management_testimonial_publish')) || (page=='faq' && checkPermission('content_management_faq_publish')) || 
            (page=='consultant' && checkPermission('content_management_consultant_publish'))" class="float-end me-2"
              [publish]="publishPage" [page]="page"></app-publish-btn>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
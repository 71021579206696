import { Injectable } from "@angular/core";
import { retry } from "rxjs/operators";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class FaqSeoService {
  constructor(private http: HttpClient) {}

  countryList: any = [];
  selectedFaqCountryId: number = null;
  selectedFaqCategoryId: number = null;
  faqCountryList = [];
  faqCategoryList = [];
  faqSubCategoryList = [];

  getSeoDatas(name): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/cms-admin/seo-datas/seo-datas/page-seo-datas/?page=${name}`
      )
      .pipe(retry(2));
  }

  getSeoDatasWithCountry(name, country?, category?, product?): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/cms-admin/seo-datas/seo-datas/page-seo-datas/` +
          `?&page=${name}` +
          `${country ? "&country=" : ""}${country ? country : ""}` +
          `${product ? "&product=" : ""}${product ? product : ""}` +
          `${category ? "&category=" : ""}${category ? category : ""}`
      )
      .pipe(retry(2));
  }

  getSeoDatasCountry(name, country): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/cms-admin/seo-datas/seo-datas/page-seo-datas/?page=${name}&country=${country}`
      )
      .pipe(retry(2));
  }

  getSeoDatasCategory(name, country, category): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/cms-admin/seo-datas/seo-datas/page-seo-datas/?page=${name}&country=${country}&category=${category}`
      )
      .pipe(retry(2));
  }

  getSeoDatasProduct(name, country, category, product): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/cms-admin/seo-datas/seo-datas/page-seo-datas/?page=${name}&country=${country}&category=${category}&product=${product}`
      )
      .pipe(retry(2));
  }

  editSeoDatas(id, data): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/cms-admin/seo-datas/seo-datas/${id}/`,
      data
    );
  }

  setSeoDatas(data): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/cms-admin/seo-datas/seo-datas/`,
      data
    );
  }

  getFaqGeneralDetails(): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/cms-admin/faq/faq/?faqtype=sub-category&is_general=true`
      )
      .pipe(retry(2));
  }

  getFaqCountryDetails(): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/cms-admin/faq/faq/?faqtype=Country`)
      .pipe(retry(2));
  }

  getFaqCategoryList(country): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/cms-admin/faq/faq/?faqtype=Category&parent=${country}`
      )
      .pipe(retry(2));
  }

  getFaqSubCategoryList(id?): Observable<any> {
    return this.http
      .get(
        `${environment.apiUrl}/cms-admin/faq/faq/?faqtype=sub-category&parent=${id}`
      )
      .pipe(retry(2));
  }

  editFaqSubCategoryList(id, data): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/cms-admin/faq/faq/${id}/`,
      data
    );
  }

  setFaqSubCategoryList(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/faq/faq/`, data);
  }

  getAddFaqSubCategoryList(id?): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}/cms-admin/faq/add-faq/?faq_entity=${id}`)
      .pipe(retry(2));
  }

  editAddFaqSubCategoryList(id, data): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/cms-admin/faq/add-faq/${id}/`,
      data
    );
  }

  setAddFaqSubCategoryList(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/faq/add-faq/`, data);
  }

  deleteFaqById(id): Observable<any> {
    return this.http
      .delete(`${environment.apiUrl}/cms-admin/faq/add-faq/` + `${id}/`)
      .pipe(retry(2));
  }

  deleteFaqCategory(id): Observable<any> {
    return this.http
      .delete(`${environment.apiUrl}/cms-admin/faq/faq/` + `${id}/`)
      .pipe(retry(2));
  }

  getMediaSeo(type: string) {
    return this.http.get(
      `${environment.apiUrl}/cms-admin/useful_media/news_blogs_seos/usefulmedia-seo-datas/?page=${type}`
    );
  }

  postMediaSeo(data: any) {
    return this.http.post(
      `${environment.apiUrl}/cms-admin/useful_media/news_blogs_seos/`,
      data
    );
  }

  editMediaSeo(id: any, data: any) {
    return this.http.put(
      `${environment.apiUrl}/cms-admin/useful_media/news_blogs_seos/${id}/`,
      data
    );
  }

  getSingleMediaSeo(seoType, id,isAuthor:boolean) {
    let type: string;
    if (seoType === "news_specific" || seoType === "news_author" ) {
      type =isAuthor?"news_author_id":"news_specific_id";
    } else {
      type =isAuthor?"blogs_author_id": "blogs_specific_id";
    }
    return this.http.get(
      `${environment.apiUrl}/cms-admin/useful_media/news_blogs_seos/usefulmedia-seo-datas/?page=${seoType}&${type}=${id}`
    );
  }

  getSingelAuthorSeo(seoType, id) {
    return this.http.get(
      `${environment.apiUrl}/cms-admin/useful_media/news_blogs_seos/usefulmedia-seo-datas/?page=${seoType}&news_author_id=${id}`
    );
  }

  viewSiteMap(){
    return this.http.get( `${environment.apiUrl}/create-sitemap/?publish=view`, { responseType: 'text' })
  }

  downloadSitemapXml(){
    return this.http.get( `${environment.apiUrl}/create-sitemap/?publish=view`, { responseType: 'blob' }) 
  }

  publishXml(){
    return this.http.get( `${environment.apiUrl}/create-sitemap/?publish=publish`,{ responseType: 'text' }) 
  }
   
  
}

import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class WorkflowService {

  stepsList = [];
  stepsDetails = [];
  templateTypes = [];

  public subProcess = [
    {
      type_id: 1, name: 'Agent Assign', multiple: false,
      tabs: { diy: true, consultant: false },
      steps: [{ s1: 'SCHEDULE', s2: 'ASSIGN OPS AGENT' }, { s1: 'ASSIGN OPS AGENT' }],
    },
    {
      type_id: 2, name: 'Master Form Filling', multiple: false,
      steps: [{ s1: 'FILL FORM', s2: 'AGENT VERIFICATION', s3: 'UNBLOCK QUESTION' }],
    },
    {
      type_id: 3, name: 'Consulate Form Filling', multiple: false,
      steps: [{ s1: 'AGENT FORM FILLING', s2: 'USER VERIFICATION', s3: 'UNBLOCK QUESTION' }],
    },
    {
      type_id: 4, name: 'Upload', multiple: true,
      tabs: { user: true, agent: false },
      steps: [{ s1: 'APPLICATION LETTER/COVERING LETTER/OTHER', s2: 'VERIFICATION' }, { s1: 'APPLICATION LETTER/COVERING LETTER/OTHER' }],
      file: { application_letter: true, covering_letter: false, other: false }
    },
    {
      type_id: 5, name: 'Data Update', multiple: true,
      tabs: { user: true, agent: false },
      steps: [{ s1: 'UPDATE', s2: 'VERIFICATION' }, { s1: 'UPDATE' }],
    },
    {
      type_id: 6, name: 'Scheduling', multiple: true,
      steps: [{ s1: 'SCHEDULE', s2: 'UPDATE DATE' }],
      for: { courier: true, interview: false, biometric: false, submission: false }
    },
    {
      type_id: 7, name: 'Document Submission', multiple: true,
      tabs_op: { other: true, passport: false },
      tabs_dc: { diy: true, consultant: false },
      steps: [{ s1: 'STATUS' }, { s1: 'RECEIVED', s2: 'STATUS' }]
    },
  ];

  constructor(private http: HttpClient) { }

  getDefaultWorkFlows(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/default-workflow-process/`).pipe(retry(2));
  }

  getWFbyCountry(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/workflow/?country=${id}`).pipe(retry(2));
  }

  getDefaultSubProcess(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/default-sub-processes/`).pipe(retry(2));
  }

  setWorkflow(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/workflow/`, data)
  }

  updateWorkflow(id,data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/workflow/${id}/`, data)
  }

  getWorkflowNotifications(process, status, isDiy): Observable<any> {
    return this.http.get(`${environment.apiUrl}/workflow-notifications/?workflow_process_id=${process}&status_id=${status}&diy=${isDiy}`).pipe(retry(2));
  }

  getSLAWorkflowNotifications(process, status, isDiy): Observable<any> {
    return this.http.get(`${environment.apiUrl}/workflow-notifications/?workflow_process_id=${process}&status_id=${status}&diy=${isDiy}&sla=true`).pipe(retry(2));
  }

  getTemplateTypes(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/notification-type/`).pipe(retry(2));
  }

  getTemplatesByType(type_id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/notification-type/all/?type_id=${type_id}`).pipe(retry(2));
  }

  getDefaultSubProcessById(process_id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/default-sub-processes/?process=${process_id}`).pipe(retry(2));
  }

  setWorkflowNotifications(data){
    return this.http.post(`${environment.apiUrl}/workflow-notifications/`, data)
  }

  removeWorkflowNotifications(id){
    return this.http.delete(`${environment.apiUrl}/workflow-notifications/${id}/`)
  }

  setDocUpdate(data){
    return this.http.post(`${environment.apiUrl}/workflow/doc-update/`, data)
  }

  getOtherFileName(process_id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/workflow/other-doc-update_get/?process_id=${process_id}`).pipe(retry(2));
  }

  getUpdatedKey(process_id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/data-update/get-updated-key/?workflow_process_id=${process_id}`).pipe(retry(2));
  }

  setUpdateKey(data){
    return this.http.post(`${environment.apiUrl}/data-update/update-key/`, data)
  }

}

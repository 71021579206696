<div class="col-md-12">
  <!-- <p class="text-center text-uppercase mb-0">Sub-Categories</p> -->
  <div class="border shadow p-3">
    <div class="d-flex justify-content-end">
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="closeModal()"
      ></button>
    </div>
    <form [formGroup]="subCatForm" (ngSubmit)="onSubCatSubmit(subCatForm)">
      <div class="mt-2 row">
        <div class="col-md-6">
          <label class="col-form-label">Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Name"
            formControlName="name"
            (keyup)="generateUrlParam(false, subCatForm.value.name)"
          />
          <div class="form-check mt-2">
            <input
              type="checkbox"
              id="forSubCat"
              class="form-check-input"
              [(ngModel)]="isSuggestSubCat"
              [ngModelOptions]="{ standalone: true }"
              (change)="generateUrlParam(false, subCatForm.value.name)"
            />
            <label for="forSubCat" class="form-check-label">
              Show Suggestion for URL Param</label
            >
          </div>
        </div>
        <div class="col-md-6">
          <label class="col-form-label">Entry Type</label>
          <select
            type="text"
            class="form-select"
            formControlName="entry_type"
            [ngClass]="{
              invalid: subCatForm.get('entry_type').invalid && isSubCatSubmitted
            }"
          >
            <option disabled value="">Select Entry</option>
            <option [value]="'Single'">SINGLE</option>
            <option [value]="'Multiple'">MULTIPLE</option>
            <option [value]="'Single/Multiple'">BOTH</option>
          </select>
        </div>
      </div>
      <div class="mt-2 row">
        <div class="col-md-6">
          <label class="col-form-label">Price</label>
          <input
            type="number"
            class="form-control"
            placeholder="Price"
            formControlName="price"
            [ngClass]="{
              invalid: subCatForm.get('price').invalid && isSubCatSubmitted
            }"
          />
        </div>
        <div class="col-md-6">
          <label class="col-form-label">Internal Code</label>
          <input
            type="text"
            class="form-control"
            placeholder="Internal Code"
            formControlName="internal_code"
            [ngClass]="{
              invalid:
                subCatForm.get('internal_code').invalid && isSubCatSubmitted
            }"
          />
        </div>
        <div class="col-md-8">
          <label class="col-form-label">URL Param</label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter URL Param"
            (keyup)="checkURLParam(subCatForm.value.url_param, false)"
            formControlName="url_param"
            [ngClass]="{
              invalid: subCatForm.get('url_param').invalid && isSubCatSubmitted
            }"
          />
        </div>
      </div>
      <div class="mt-2 row">
        <div class="col-md-12 row shadow group-box">
          <div class="col-md-6 col-form-label">Stay Duration</div>
          <div class="col-md-6">
            <div class="form-check mt-3 float-end">
              <input
                type="checkbox"
                id="asPerEmbassy"
                class="form-check-input"
                formControlName="as_per_embassy"
                (change)="
                  changeRequiredDuration(subCatForm.value.as_per_embassy, true)
                "
              />
              <label for="asPerEmbassy" class="form-check-label fw-bold">
                As Per Embassy
              </label>
            </div>
          </div>
          <div class="row col-md-12" *ngIf="!subCatForm.value.as_per_embassy">
            <div class="col-md-6">
              <label class="col-form-label">Value</label>
              <input
                type="number"
                class="form-control"
                placeholder="Duration"
                formControlName="duration_value"
                [ngClass]="{
                  invalid:
                    subCatForm.get('duration_value').invalid &&
                    isSubCatSubmitted
                }"
              />
            </div>
            <div class="col-md-6">
              <label class="col-form-label">Unit</label>
              <select
                type="text"
                class="form-select"
                formControlName="duration_unit"
                [ngClass]="{
                  invalid:
                    subCatForm.get('duration_unit').invalid && isSubCatSubmitted
                }"
              >
                <option disabled value="null">Select Unit</option>
                <option value="Day">Days</option>
                <option value="Month">Months</option>
                <option value="Year">Years</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 row">
        <div class="col-md-12 row shadow group-box">
          <label class="col-md-6 col-form-label">Visa Validity</label>
          <div class="col-md-6">
            <div class="form-check mt-3 float-end">
              <input
                type="checkbox"
                id="asPerEmbassyValidation"
                class="form-check-input"
                formControlName="validity_as_per_embassy"
                (change)="
                  changeRequiredDuration(
                    subCatForm.value.validity_as_per_embassy,
                    false
                  )
                "
              />
              <label
                for="asPerEmbassyValidation"
                class="form-check-label fw-bold"
              >
                As Per Embassy
              </label>
            </div>
          </div>
          <div
            class="row col-md-12"
            *ngIf="!subCatForm.value.validity_as_per_embassy"
          >
            <div class="col-md-6">
              <label class="col-form-label">Value</label>
              <input
                type="number"
                class="form-control"
                placeholder="Validity"
                formControlName="validity_value"
                [ngClass]="{
                  invalid:
                    subCatForm.get('validity_value').invalid &&
                    isSubCatSubmitted
                }"
              />
            </div>
            <div class="col-md-6">
              <label class="col-form-label">Unit</label>
              <select
                type="text"
                class="form-select"
                formControlName="validity_unit"
                [ngClass]="{
                  invalid:
                    subCatForm.get('validity_unit').invalid && isSubCatSubmitted
                }"
              >
                <option disabled value="null">Select Unit</option>
                <option value="Day">Days</option>
                <option value="Month">Months</option>
                <option value="Year">Years</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 row">
        <div class="col-md-12 row shadow group-box">
          <label class="col-md-12 col-form-label">Process Time</label>
          <div class="col-md-4">
            <label class="col-form-label">Minimum</label>
            <input
              type="number"
              class="form-control"
              placeholder="Min"
              min="0"
              formControlName="processing_time_min"
              [ngClass]="{
                invalid:
                  subCatForm.get('processing_time_min').invalid &&
                  isSubCatSubmitted
              }"
            />
          </div>
          <div class="col-md-4">
            <label class="col-form-label">Maximum</label>
            <input
              type="number"
              min="0"
              class="form-control"
              placeholder="Max"
              formControlName="processing_time_max"
              [ngClass]="{
                invalid:
                  subCatForm.get('processing_time_max').invalid &&
                  isSubCatSubmitted
              }"
            />
          </div>
          <div class="col-md-4">
            <label class="col-form-label">Unit</label>
            <select
              type="text"
              class="form-select"
              formControlName="processing_time_unit"
              [ngClass]="{
                invalid:
                  subCatForm.get('processing_time_unit').invalid &&
                  isSubCatSubmitted
              }"
            >
              <option disabled value="null">Select Unit</option>
              <option value="Hour">Hours</option>
              <option value="Day">Days</option>
            </select>
          </div>
        </div>
      </div>
      <div class="mt-2 row">
        <div class="col-md-12 text-end">
          <button
            class="btn btn-primary mt-1 w-20"
            type="submit"
            [disabled]="isSubmittingSubCat || isUrlSubCat"
          >
            <i class="uil-plus"></i>
            {{ subCategory ? "Update" : "Add" }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { VisaManageService } from "src/app/core/services/visa-manage.service";
import { VerifyModalComponent } from "../../visa-flow/modals/verify-modal/verify-modal.component";
import { CoverLetterModalComponent } from "../../visa-flow/modals/cover-letter-modal/cover-letter-modal.component";
import { ToastrService } from "ngx-toastr";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "src/app/core/services/shared.service";
import { BehaviorSubject, debounceTime } from "rxjs";

@Component({
  selector: "app-traveller-info",
  templateUrl: "./traveller-info.component.html",
  styleUrls: ["./traveller-info.component.scss"],
})
export class TravellerInfoComponent implements OnInit, OnChanges {
  @Input() travellerInfo;
  @Input() isPrimary;
  @Input() app_id;
  @Input() title: string;
  @Input() paxDetails: any;

  @Output() newItemEvent = new EventEmitter<boolean>(false);
  addOrRemve: number = 1;
  isGetting = false;
  isComplVisaDeta: boolean = false;
  isCoveringLetter: boolean = false;
  questionsList: any = [];
  viewPassport: boolean = false;
  quesionView: boolean = false;
  isMasterQuestion: boolean = false;
  travellorInfoForm: FormGroup;
  paxId: any;
  passportData: any;
  application_id: string;
  count = 0;
  myProfileOptions: any = [];
  profileItemId: any;
  customerPrflId: any;
  @ViewChild("questionModal", { static: false }) queModal: ElementRef;
  private validatePasswordSub = new BehaviorSubject<any>(null);
  validatePasswordSub$ = this.validatePasswordSub.asObservable();

  constructor(
    private modalService: NgbModal,
    private api: VisaManageService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private sharedService: SharedService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.count > 0) {
      this.travellerInfo = changes?.travellerInfo?.currentValue;
    }
  }

  ngOnInit(): void {
    this.application_id = this.route.snapshot.paramMap.get("id");
    this.initializeForm();
    this.getDropDownItems();
    this.validatePassport();
  }

  validatePassport() {
    this.validatePasswordSub$.pipe(debounceTime(500)).subscribe((response) => {
      if (response) {
       
        const data = {
          customer_detail_id: this.customerPrflId,
          passport_no: this.travellors.at(response?.index).get("passport_no").value,
        };
        this.api.validatePassport(data).subscribe((res: any) => {
          if (res?.is_exists) {  
            this.travellors
              .at(response?.index).get('passport_no')
              .setErrors({ 'is_exist': "Passport number already exists" });
              
          } else {
            this.travellors.at(response?.index).get('passport_no').setErrors(null);
           
          }
          
        });
      }
    });
  }

  getDropDownItems() {
    this.sharedService.profileDropdowns$.subscribe((res: any) => {
      this.myProfileOptions = res?.profile;
      this.customerPrflId = res?.myprofile_customer_detail_id;
    });
  }

  setData(data, index) {
    data.forEach((res, ind) => {
      this.travellors.at(index).get("first_name").setValue(res?.first_name);
      //this.travellors.at(index).get('first_name').disable()
      this.travellors.at(index).get("last_name").setValue(res?.last_name);
      //this.travellors.at(index).get('last_name').disable()
      this.travellors.at(index).get("dob").setValue(res?.dob);
      //this.travellors.at(index).get('dob').disable()
      this.travellors.at(index).get("phone_no").setValue(res?.phone_no);
      this.travellors.at(index).get("email_id").setValue(res?.email);
      this.travellors.at(index).get("passport_no").setValue(res?.passport_no);
      this.travellors.at(index).get("passport_no").disable();
    });
  }
  setPrfileData(index: number) {
    if (!this.travellors.at(index).get("profile_id").value) return;

    this.api
      .getSpecificProfileData(this.travellors.at(index).get("profile_id").value)
      .subscribe((res) => {
        
        this.setData(res, index);
      });
  }

  validatePassword(index: number) {
    const data = {
      value: this.travellors.at(index).get("passport_no").value,
      index: index,
    };
    this.validatePasswordSub.next(data);
  }

  initializeForm() {
    this.travellorInfoForm = this.fb.group({
      travellors: this.fb.array([
        this.fb.group({
          first_name: ["", Validators.required],
          last_name: ["", Validators.required],
          dob: ["", Validators.required],
          phone_no: ["", Validators.required],
          email_id: ["", [Validators.required, Validators.email]],
          passport_no: ["", Validators.required],
          profile_id: [null],
        }),
      ]),
    });
  }

  get travellors(): FormArray {
    return this.travellorInfoForm.get("travellors") as FormArray;
  }

  addTravellor() {
    this.travellors.push(this.addNewTravellor());
    this.addOrRemve++;
  }

  addNewTravellor(): FormGroup {
    return this.fb.group({
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      dob: ["", Validators.required],
      phone_no: ["", Validators.required],
      email_id: ["", [Validators.required, Validators.email]],
      passport_no: ["", Validators.required],
      profile_id: [null],
    });
  }

  // removeTravellor(index){
  //     const travellorsArray = this.travellorInfoForm.get('travellors') as FormArray;
  //     travellorsArray.removeAt(index);
  //     this.addOrRemve--;
  // }

  onSubmit() {
    if (this.travellorInfoForm.invalid) {
      this.travellorInfoForm.markAllAsTouched();
      return;
    }
    const travellorValues: any = [];
    const travellorsArray = this.travellorInfoForm.get(
      "travellors"
    ) as FormArray;
    travellorsArray.controls.forEach((travellorControl, index) => {
      // Get the value of each control and add it to the array
      const traveler = {
        visa_process: +this.application_id,
        id: this.paxDetails?.paxIds[index],
        first_name: travellorControl.get("first_name").value,
        last_name: travellorControl.get("last_name").value,
        dob: travellorControl.get("dob").value,
        phone_no: travellorControl.get("phone_no").value,
        email_id: travellorControl.get("email_id").value,
        passport_no: travellorControl.get("passport_no").value,
        // Add more properties as needed
      };

      travellorValues.push(traveler);
    });
    this.api.postVisaProcessData(travellorValues).subscribe(
      (res) => {
        if (res) {
          this.newItemEvent.next(true);
          this.count++;
          this.paxDetails.workflow = false;
          this.toastr.success("Success", "Pax successfully created");
        }
      },
      (err) => {
        this.toastr.error("Error", err);
      }
    );
  }

  open(content) {
    this.modalService.open(content, {
      // size: 'xl',
      fullscreen: true,
      scrollable: true,
      windowClass: "modal-big-lg",
      backdrop: "static",
    });
  }

  getQuestions(pax_id) {
    this.isGetting = true;
    this.paxId = pax_id;
    this.loadVisaImg(pax_id);
  }

  loadVisaImg(paxId) {
    this.api.getSavedVisaImg(paxId).subscribe(
      (res: any) => {
        if (res) {
          this.passportData = res;
          this.viewPassport = true;
          // this.frontImage = res?.image_url_front;
          // this.backImage = res?.image_url_back;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  showForm(pax_id, doc_type) {
    this.isMasterQuestion = false;
    let data = {
      api: "documents-upload",
      pax_id: pax_id,
      doc_type: doc_type,
      from_list: true,
    };

    if (doc_type == "application_details") {
      this.isComplVisaDeta = true;
      this.api.getVisaDetails(this.app_id).subscribe(
        (res: any) => {
          this.isComplVisaDeta = false;
          data["response"] = res;
          this.openVerifyModal(data);
        },
        (err) => {
          this.isComplVisaDeta = false;

          this.toastr.error(err);
        }
      );
    } else if (doc_type == "covering_letter") {
      this.isCoveringLetter = true;
      this.api.getCoverNotification().subscribe(
        (res: any) => {
          this.isCoveringLetter = false;
          data["response"] = res?.results;
          this.openCoverLetterModal(data);
        },
        (err) => {
          this.isCoveringLetter = false;
          this.toastr.error(err);
        }
      );
    }
  }

  openVerifyModal(data) {
    const modalRef = this.modalService.open(VerifyModalComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "modal-big-lg",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.uploadDetails = data;
    modalRef.componentInstance.openFor = "consulate_form";

    modalRef.result.then(
      (result) => {
        // console.log(result);
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  openCoverLetterModal(data) {
    const modalRef = this.modalService.open(CoverLetterModalComponent, {
      size: "lg",
      scrollable: true,
      windowClass: "modal-big-lg",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.coverDetails = data;

    modalRef.result.then(
      (result) => {
        // console.log(result);
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  showNextStep() {
    this.api.getCountryQuestionsByPax(this.paxId).subscribe(
      (result) => {
        this.isMasterQuestion = true;
        this.isGetting = false;
        this.questionsList = result;
        this.viewPassport = false;
        this.quesionView = true;

        this.open(this.queModal);
      },
      (error) => {
        this.isGetting = false;
      }
    );
  }
}

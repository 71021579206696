import { Component, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/core/services/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-preview-btn',
  templateUrl: './preview-btn.component.html',
  styleUrls: ['./preview-btn.component.scss']
})
export class PreviewBtnComponent {

  @Input() page;

  showPanel = false;
  hoverCog = false;
  country = this.sharedService.selectedCmsCountryId;
  category = this.sharedService.selectedCmsCategoryId;
  product = this.sharedService.selectedCmsProductId;

  countryList = this.sharedService.cmsCountryList;
  categoryList = this.sharedService.cmsCategoryList;
  productList = this.sharedService.cmsProductList;

  constructor(private sharedService: SharedService, private toastr: ToastrService) { }

  previewPage() {

    let desiredUrl = '';
    if (this.page == 'home') window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}?preview=true`, "_blank");
    else if (this.page == 'country') {
      this.country = this.sharedService.selectedCmsCountryId;
      let sel_country = this.countryList.filter(ae => ae.id == this.country);
      if (sel_country) desiredUrl = sel_country[0].country_url;
      window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}visa/${desiredUrl}-visa-online?preview=true`, "_blank");
    }
    else if (this.page == 'category') {
      let countryUrl = '';
      let categoryUrl = '';
      this.country = this.sharedService.selectedCmsCountryId;
      let sel_country = this.countryList.filter(ae => ae.id == this.country);
      if (sel_country) countryUrl = sel_country[0].country_url;

      this.category = this.sharedService.selectedCmsCategoryId;
      let sel_category = this.categoryList.filter(ae => ae.id == this.category);
      if (sel_category) categoryUrl = sel_category[0].category_url;
      window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}visa/${countryUrl}-visa-online?category=${categoryUrl}&preview=true`, "_blank");
    }
    // else if(this.page=='category') {
    //   this.category = this.sharedService.selectedCmsCategoryId;
    //   let sel_category = this.categoryList.filter(ae=>ae.id==this.category);
    //   if(sel_category) desiredUrl = sel_category[0].category_url;
    //   window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}visa/${desiredUrl}-visa-online-india?preview=true`, "_blank");
    // }
    else if (this.page == 'product') {
      this.product = this.sharedService.selectedCmsProductId;
      let sel_product = this.productList.filter(ae => ae.id == this.product);
      if (sel_product) desiredUrl = sel_product[0].product_url;
      window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}visa/${desiredUrl}-visa-apply-online?preview=true`, "_blank");
    }
    else if (this.page == 'consultant') {
      window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}visa/consultant?preview=true`, "_blank");
    }
    else if (this.page == 'about-us') {
      window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}visa/about-us?preview=true`, "_blank");
    }
    else if (this.page == 'testimonial') {
      window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}visa/testimonial?preview=true`, "_blank");
    }
    else if (this.page == 'faq') {
      window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}visa/faq?preview=true`, "_blank");
    } else if (this.page == 'contact-us') {
      window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}contact-us?preview=true`, "_blank");
    } else if (this.page == 'news') {
      window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}news?preview=true`, "_blank");
    } else if (this.page == 'blog') {
      window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}blog?preview=true`, "_blank");
    }
    // else if (this.page == 'author-news') {
    //   window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}author?preview=true`, "_blank");
    // }
    else if (this.page == 'specific-news') {
      window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}news/specific-news?preview=true`, "_blank");
    }
    // else if (this.page == 'author-blog') {
    //   window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}author?preview=true`, "_blank");
    // }
    else if (this.page == 'specific-blog') {
      window.open(`${environment.webUrl}/${!environment.production ? '#/' : ''}blog/specific-blog?preview=true`, "_blank");
    }

    this.showPanel = false;
  }

  publishPage() {
    let data = {};
    this.sharedService.bulkPublish(data).subscribe(
      result => {
        this.toastr.success("Page Published Successfully!");
      }
    )
  }
}

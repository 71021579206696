<div class="container">
    <div class="row mt-3">
        <div class="col-lg-4 col-md-6">
            <div class="col-md-12">
                <p class="text-center text-uppercase">Sub Category</p>
            </div>
            <div class="col-md-12 my-2 mb-3 text-center">
                <button *ngIf="(page=='faq' && checkPermission('content_management_faq_create'))" class="btn btn-primary" (click)="showFaqForm=!showFaqForm;isFaqEdit=false;resetForm();">{{showFaqForm?'Show List': '+ Add Faq List'}}</button>
            </div>
            <div class="row" *ngIf="!showFaqForm"> 
                <!-- <pre>{{faqList|json}}</pre> -->
                <div class="d-flex mt-2" *ngIf="isLoadingCats">
                    <i class="fa fa-2x fa-spin fa-pulse fa-spinner m-auto"></i>
                </div>
                <div class="col-md-12 mb-2" *ngFor="let faq of faqList">
                    <div class="border shadow text-center py-2 cpr" [ngClass]="{'faq-selected':faq.selected}" (click)="isFaq=true;showSubFaqlist(faq.id)">
                         {{faq.name}} 
                    </div>
                    <div class="d-grid m-1 sub-list" *ngIf="(page=='faq' && checkPermission('content_management_faq_edit'))">
                            <a class="cpr" (click)="editFaq(true,faq);isFaq=true"><i class="far fa-edit text-success"></i></a>
                            <a class="cpr" (click)="open(deleteFaqCatModal);selectedFaqCattoDel=faq;"><i class="dripicons-trash text-danger"></i></a>

                    </div>
                </div>
            </div>
            <div class="col-md-12" *ngIf="showFaqForm">
                <div class="border shadow p-3">
                    <form [formGroup]="faqForm" (ngSubmit)="onFaqSubmit(faqForm)">
                        <div class="my-3 row">
                            <div class="col-md-12">
                                <label class="col-form-label">Name</label>
                                <input type="text" class="form-control" placeholder="Faq Label"
                                    formControlName="name">
                            </div>
                        </div>
                        <div class="my-3 row">
                            <div class="col-md-12">
                                <button *ngIf="(page=='faq' && checkPermission('content_management_faq_create'))" class="btn btn-primary mt-1 w-100" type="submit"><i class="uil-plus"></i> Add
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col-lg-8 col-md-6" *ngIf="selectedFaqId">
            <div>
                <p class="text-center text-uppercase">Question & Answer</p>
                <div class="col-md-12 mb-2" *ngIf="selectedFaqId && subFaqList.length==0 && !isGettingSubFaq">
                    <div class="border shadow text-center py-2 cpr"> No Faq Details<br>
                    </div>
                </div>
                <div class="row">
                    <div class="offset-md-6 col-md-6 my-2 float-end d-flex">
                        <button class="btn btn-primary" 
                        (click)="open(content);isSubFaqEdit=false;">
                        Add Faq Question & Answer
                        </button>
                    <app-publish-btn *ngIf="(page=='faq' && checkPermission('content_management_faq_publish'))" class="ms-2" [publish]="'Faq'" [page]="page" [value_from_page]="selectedFaqId" ></app-publish-btn>
                    </div>
                    <div class="col-md-12 mb-2" *ngIf="isGettingSubFaq">
                        <div class="border shadow text-center py-2 cpr">
                            <i class="fa fa-2x fa-spin fa-spinner fa-pulse"></i>
                        </div>
                    </div>
                    <div class="col-md-12 row">
                         <div class="col-md-12 mb-2" *ngFor="let subfaq of subFaqList"> <!-- {{subfaq | json}} -->
                            <div class="border shadow py-2 px-4 cpr w-minus m-auto" [ngClass]="{'faq-selected':subfaq.selected}" (click)="isFaq=false;selectSubFaq(subfaq.id);">
                                <div class="mb-1">
                                    Q : <b>{{subfaq.question}}</b>
                                 </div>
                                <div>
                                    A : <i>{{subfaq.answer}}</i>
                                 </div> 
                            </div>
                            <div class="d-grid m-1 sub-list">
                                <a class="cpr" (click)="editFaq(false,subfaq);isFaq=false;selectSubFaq(subfaq.id)"><i class="far fa-edit text-success"></i></a>
                                <a class="cpr" (click)="open(deleteFaqModal);this.selectedFaqtoDel=subfaq;"><i class="dripicons-trash text-danger"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Question Answer</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body">
		<form [formGroup]="subFaqForm" (ngSubmit)="onSubFaqSubmit(subFaqForm)">
            <div class="my-3 row">
                <div class="col-md-12">
                    <label class="col-form-label">Faq Question</label>
                    <input type="text" class="form-control" placeholder="Faq Question"
                        formControlName="question">
                </div>
                <div class="col-md-12">
                    <label class="col-form-label">Faq Answer</label>
                    <!-- <textarea row="10" class="form-control" placeholder="Faq Answer"
                        formControlName="answer"></textarea> -->
                    <ckeditor [editor]="Editor" formControlName="answer" data="<p>Content of the editor.</p>"></ckeditor>
                </div>
            </div>
            <div class="my-3 row">
                <div class="col-md-12">
                    <button class="btn btn-primary mt-1 w-100" type="submit" (click)="modal.close('Save click')"><i class="bx bx-save"></i> Save
                    </button>
                </div>
            </div>
        </form>
	</div>
</ng-template>

<ng-template #deleteFaqModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Delete</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body text-center">
		Are you sure to delete this FAQ?<br><br>
        <div class="text-start d-grid">
            <p class="m-auto">Que:&nbsp;<span>{{selectedFaqtoDel.question}}</span></p>
            <p class="m-auto d-flex">Ans:&nbsp;<span [innerHTML]="selectedFaqtoDel.answer"></span></p>
        </div>
	</div>
    <div class="modal-footer text-end">
        <button class="btn btn-danger" type="button" (click)="deleteFaqConfirm(selectedFaqtoDel)"><i [ngClass]="{'bx bx-save':!isDeleting, 'fa fa-spin fa-spinner fa-pulse':isDeleting}"></i> Delete
        </button>
        <button class="btn btn-outline" type="button" (click)="modal.close('Cancel')"> Cancel
        </button>
    </div>
</ng-template>

<ng-template #deleteFaqCatModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Delete</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body text-center">
		Are you sure to delete Sub-Category <b>{{selectedFaqCattoDel.name}}</b> ?<br>
	</div>
    <div class="modal-footer text-end">
        <button class="btn btn-danger" type="button" (click)="deleteFaqCategory(selectedFaqCattoDel)"><i [ngClass]="{'bx bx-save':!isDeleting, 'fa fa-spin fa-spinner fa-pulse':isDeleting}"></i> Delete
        </button>
        <button class="btn btn-outline" type="button" (click)="modal.close('Cancel')"> Cancel
        </button>
    </div>
</ng-template>
<div class="modal-header">
    <h5 class="modal-title">Add Email Template</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal(false)">
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="userWabaForm" (ngSubmit)="onSubmit(userWabaForm)">
        <div class="my-3 row">
            <div class="col-md-6">
                <label class="col-md-12 col-form-label">Template Name</label>
                <div class="col-md-12">
                    <input readonly="true" type="text" class="form-control" formControlName="name"
                        [ngClass]="{'invalid': userWabaForm.get('name').invalid && isSubmitted}">
                </div>
            </div>
            <div class="col-md-6">
                <label class="col-md-12 col-form-label">Language</label>
                <div class="col-md-12">
                    <input readonly="true" type="text" class="form-control" formControlName="language"
                        [ngClass]="{'invalid': userWabaForm.get('language').invalid && isSubmitted}">
                </div>
            </div>
        </div>
        <div class="my-3 row">
            <div class="form-group col-md-12">
                <label class="col-form-label">Memory Variables</label>
                <div formArrayName="memory_variables">
                    <div *ngFor="let item of memory_variables.controls; let i=index">
                        <div [formGroupName]="i">
                            <div class="form-group d-flex my-2">
                                <input type="text" class="form-control me-2" formControlName="cf_variable" placeholder="CF Variable">
                                <span class="arrow-i"><i class="mdi mdi-arrow-right"></i></span>
                                <input type="text" class="form-control" formControlName="stp_variable" placeholder="STP Variable">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <button type="button" class="btn btn-sm btn-primary mt-2 float-end" (click)="addTags()">
                    <i class="fa fa-plus"></i> Add tags
                </button> -->
            </div>
        </div>
        <div class="my-3 row">
            <div class="col-md-12 text-end">
                <button type="submit" class="btn btn-primary"><i [ngClass]="{'fa fa-spin fa-pulse fa-spinner': isAdding, 'fa fa-pencil-square-o': isAdding}"></i>Update</button>
            </div>
        </div>
    </form>
</div>
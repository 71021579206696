import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductService } from 'src/app/core/services/product.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RelevantProductsComponent } from '../country/add-country/relevant-products/relevant-products.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  breadCrumbItems: Array<{}>;

  selected = false;

  isLoading = false;
  countryList = this.sharedService.addedCountryList;
  productList = [];
  country = this.sharedService.selectedCountryId;
  selectedCountry;
  @ViewChild(RelevantProductsComponent) productComponent: RelevantProductsComponent;


  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Product Management' }, { label: 'Relevant Products', active: true }];
    if(this.countryList.length==0)this.getCountriesList();
    if(this.country) this.getProducts(this.country);
  }

  constructor(private api: ProductService, private sharedService: SharedService, public activeModal: NgbActiveModal) { }

  getCountriesList() {
    this.api.getCountryDetails().subscribe(
      (result: any) => {
        this.isLoading = false;
        this.countryList = result;
        this.sharedService.addedCountryList = this.countryList;
      })
  }

  getProducts(country) {
    this.selectedCountry = country;
    this.selected = true;
    this.isLoading = true;
    // this.productList = [];
    this.api.getProductListByCountry(this.selectedCountry).subscribe(
      (result:any)=>{
        this.isLoading = false;
        this.sharedService.setCountryId(this.selectedCountry)
        this.productComponent.initProducts();
      })
  }

}

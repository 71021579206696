<div class="container">
    <form [formGroup]="featuresForm" (ngSubmit)="onSubmit(featuresForm)">
        <div class="row">
            <div class="col-md-6 mt-2" formArrayName="features"
                *ngFor="let item of features().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="features().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removeFeatures(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Icon</label>
                            <div class=" d-flex">
                                <input type="text" class="form-control" placeholder="URL" formControlName="icon_url"
                                    (change)="dataChanged(i)">
                                <a [href]="featuresForm.value.features[i].icon_url" target="_blank"><img class="ms-1"
                                        height="40" [src]="featuresForm.value.features[i].icon_url"></a>
                                <button class="btn btn-primary ms-1" type="button"
                                     (click)="openUploadModal(i)"><i class="fas fa-cloud-upload-alt"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                                (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('heading').invalid && isSubmitted}">
                        </div>
                    </div>
                    <div class="my-2 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Description</label>
                            <ckeditor [editor]="Editor" formControlName="description" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('description').invalid && isSubmitted}"
                                data="<p>Content of the editor.</p>"></ckeditor>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button *ngIf="checkPermission('content_management_home_create')"
                        class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addFeatures()"><i
                            class="uil-plus"></i> Add</button>
                    <button
                        *ngIf="checkPermission('content_management_home_create')&&checkPermission('content_management_home_edit')"
                        class="btn btn-info mt-1 col-md-12" type="submit"><i
                            [ngClass]="{'fa fa-pulse fa-spin fa-spinner': isUpdating,'bx bx-save': !isUpdating}"></i>
                        Save All</button>
                    <app-publish-btn *ngIf="checkPermission('content_management_home_publish')"
                        class="col-md-12 pt-1 px-0" [publish]="'UspFeature'" [page]="'home'"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
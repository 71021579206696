<form [formGroup]="infoForm" (ngSubmit)="onSubmit(infoForm)">
    <div class="row">
        <div class="col-md-8 offset-md-2 mt-2" formArrayName="info" *ngFor="let item of info().controls; let i = index;">
            <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                <a *ngIf="info().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                    (click)="removeinfo(i)"><i class="uil-times fa-2x"></i></a>
                <div class="row">
                    <!-- <div class="mt-2 col-md-6">
                        <label class="col-form-label">Title</label>
                        <input type="text" class="form-control" placeholder="Title" formControlName="title"
                            (change)="dataChanged(i)">
                    </div> -->
                    <div class="mt-2 col-md-6">
                        <label class="col-form-label">Heading</label>
                        <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                            (change)="dataChanged(i)">
                    </div>
                    <div class="mt-2 col-md-6">
                        <label class="col-form-label">Sub-Heading</label>
                        <input type="text" class="form-control" placeholder="Sub-Heading" formControlName="sub_heading"
                            (change)="dataChanged(i)">
                    </div>
                </div>
                <div class="my-2 row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="col-form-label">Description</label>
                            <div formArrayName="description" class="btn_grp row">
                                <div *ngFor="let lines of item.get('description').controls; let j=index" class="col-md-6">
                                    <div [formGroupName]="j" class="description">

                                        <div class="form-group d-flex my-2">
                                            <div class="d-flex">
                                                <input class="form-control" type="text" formControlName="key" (change)="dataChanged(i)"> : <input class="form-control" type="text" formControlName="value" (change)="dataChanged(i)">
                                            </div>
                                            <button type="button"
                                                *ngIf="item.get('description').controls.length!=1"
                                                class="deletebtn btn btn-pseudo remove"
                                                (click)="deleteDescription(item.controls.description, j);dataChanged(i);"><i
                                                    class="fa fa-times text-danger"></i></button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn btn-sm btn-primary mt-2 float-end"
                                (click)="addNewDescription(item.controls.description)"
                                *ngIf="item.get('description').controls.length<10">
                                <i class="fa fa-plus"></i> Add Description
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 p-5">
            <div class="row">
                <div class="col-md-4 offset-md-4">
                    <button *ngIf="checkPermission('content_management_product_create') && infoForm.value.info.length<1" class="btn btn-primary mt-1 col-md-12" type="button" (click)="addinfo()"><i class="uil-plus"></i>
                        Add</button>
                    <button *ngIf="checkPermission('content_management_product_create')&&checkPermission('content_management_product_edit')" class="btn btn-info mt-1 col-md-12 mb-1" type="submit" [disabled]="isUpdating"><i class="bx bx-save"></i> Save All</button>
                    <app-publish-btn *ngIf="checkPermission('content_management_product_publish')" class="col-md-12 p-0 mt-1" [publish]="'ProductData'" [page]="'product'"></app-publish-btn>
                </div>
            </div>
        </div>
    </div>
</form>
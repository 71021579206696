import { Component, Input } from "@angular/core";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  FormGroup,
  FormArray,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { ProductPageService } from "src/app/core/services/product-page.service";
import { PermissionService } from "src/app/core/services/permision.service";
import { firstFormModel } from "src/app/core/models/sku-data.model";
@Component({
  selector: "app-sku-pricing",
  templateUrl: "./sku-pricing.component.html",
  styleUrls: ["./sku-pricing.component.scss"],
})
export class SkuPricingComponent {
  pricingForm: FormGroup;
  firstForm:FormGroup;
  hasChanges = false;
  descriptionArray: FormArray;
  isUpdating = false;
  isSubmitted = false;
  deletedprice = [];
  isDisabled: boolean = false;
  priceData: any;
  dataChangedorNot: boolean = false;
  secondFormData: any;
  @Input() country;
  @Input() page;
  @Input() product;
  
  constructor(
    private fb: FormBuilder,
    private api: ProductPageService,
    private toastr: ToastrService,
    public permission: PermissionService
  ) {}

  ngOnInit(): void {
    this.resetForm();
    
    this.getApplicationprice();
  }

  resetForm() {
    this.pricingForm = this.fb.group({
      price: this.fb.array([
        this.fb.group({
          //name: ["", [Validators.required]],
          // sub_name: [""],
         // price: ["", [Validators.required]],
          //currency_symbol: ["", [Validators.required]],
          consultant_fee: ["", [Validators.required]],
          //tax: ["", [Validators.required]],
          // mearch_fee: ['', [Validators.required]],
          consultant_description: this.fb.array([
            this.fb.group({
              description: "",
            }),
          ]),
          product: [this.product],
          is_updated: [""],
        }),
      ]),
    });
  }

  getFirstForm(){
    this.firstForm=this.fb.nonNullable.group<firstFormModel>({
      name:new FormControl(this.priceData[0]?.name?this.priceData[0]?.name:'',[Validators.required]),
      sub_name:new FormControl(this.priceData[0]?.sub_name?this.priceData[0]?.sub_name:'',[Validators.required]),
      currency_symbol:new FormControl(this.priceData[0]?.currency_symbol?this.priceData[0]?.currency_symbol:'',[Validators.required]),
      price:new FormControl(this.priceData[0]?.price?this.priceData[0]?.price:'',[Validators.required])
    })
  }

  newDecription(): FormGroup {
    return this.fb.group({
      description: "",
    });
  }
  deleteDescription(control, index) {
    control.removeAt(index);
  }

  addDescription(empIndex: number) {
    const arr = this.pricingForm.get("price") as FormArray;
    this.descriptionArray = arr
      .at(empIndex)
      .get("consultant_description") as FormArray;
    this.descriptionArray.push(this.newDecription());
  }

  getApplicationprice() {
    this.api.getPricing(this.product).subscribe((result: any) => {
      this.priceData = result;
      this.getFirstForm()
      this.setData(result);
    });
  }

  setDescriptions(x) {
    let arr = new FormArray([]);
    x?.consultant_description?.forEach((y) => {
      arr.push(
        this.fb.group({
          description: y,
        })
      );
    });
    return arr;
  }

  setData(result) {
    this.pricingForm = this.fb.group({
      price: new FormArray([]),
    });
    result.forEach((ao) => {
      //this.subName=ao.sub_name;
      (this.pricingForm.get("price") as FormArray).push(
        this.fb.group({
          id: [ao.id, [Validators.required]],
          //name: [ao.name, [Validators.required]],
          //sub_name: [ao.sub_name],
          //price: [ao.price, [Validators.required]],
         // currency_symbol: [ao.currency_symbol, [Validators.required]],
         consultant_fee: [ao.consultant_fee, [Validators.required]],
          //tax: [ao.tax, [Validators.required]],
          //mearch_fee: [ao.mearch_fee, [Validators.required]],
          consultant_description: this.setDescriptions(ao),
          product: [this.product],
          is_updated: [false],
        })
      );

      this.deletedprice = [];
    });
  }

  price(): FormArray {
    return this.pricingForm.get("price") as FormArray;
  }

  newprice(): FormGroup {
    return this.fb.group({
      //name: ["", [Validators.required]],
      //sub_name: [""],
      //price: ["", [Validators.required]],
      //currency_symbol: ["", [Validators.required]],
      consultant_fee: ["", [Validators.required]],
     // tax: ["", [Validators.required]],
      mearch_fee: ["", [Validators.required]],
      product: [this.product],
      is_updated: [true],
    });
  }

  addprice() {
    this.price().push(this.newprice());
  }

  removeprice(i: number) {
    this.isSubmitted = false;
    this.pricingForm.value.price[i].is_deleted = true;
    if (this.pricingForm.value.price[i].hasOwnProperty("id"))
      this.deletedprice.push(this.pricingForm.value.price[i]);
    this.price().removeAt(i);
  }

  dataToTransfer(form) {
    let dataToTransfer: any;
    dataToTransfer = form.value.price;
    let consultantDescription = form.value.price[0].consultant_description.map(
      (foo) => foo.description
    );
    dataToTransfer[0].tax=this.priceData[0].tax
    dataToTransfer[0].mearch_fee=this.priceData[0]?.mearch_fee
    dataToTransfer[0].name = this.firstForm?.get('name').value;
    dataToTransfer[0].price = this.firstForm?.get('price').value;
    dataToTransfer[0].sub_name = this.firstForm?.get('sub_name').value;
    dataToTransfer[0].currency_symbol=this.firstForm?.get('currency_symbol').value; 
    dataToTransfer[0].is_updated = true;
    dataToTransfer[0].consultant_description = consultantDescription;
    if (this.dataChangedorNot) {
      let result = this.secondFormData.price[0].diy_description.map(
        (foo) => foo.dydescription
      );
      dataToTransfer[0].diy_description = result;
      //dataToTransfer[0].tax=this.secondFormData.price[0].tax
     // dataToTransfer[0].mearch_fee = this.secondFormData.price[0].mearch_fee;
    } else {
      dataToTransfer[0].diy_description = this.priceData[0].diy_description;
      //dataToTransfer[0].tax = this.priceData[0].tax;
     // dataToTransfer[0].mearch_fee = this.priceData[0].mearch_fee;
    }

    return dataToTransfer;
  }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      this.deletedprice.forEach((ae) => {
        ae.is_updated = false;
        form.value.price.push(ae);
      });
      this.api.setPricing(this.dataToTransfer(form)).subscribe(
        (result: any) => {
          this.toastr.success("Content Updated Successfully!");
          this.getApplicationprice();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        (error) => {
          this.isUpdating = false;
        }
      );
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  addItem(data) {
    this.secondFormData = data;
    this.dataChangedorNot = true;
  }

  drop(event: CdkDragDrop<[]>) {
    moveItemInArray(
      this.price().controls,
      event.previousIndex,
      event.currentIndex
    );
  }

  dataChanged(i) {
    this.pricingForm.value.price[i].is_updated = true;
  }
  checkPermission(type) {
    return this.permission.hasPermission(type);
  }
}

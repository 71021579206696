import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'specific-page',
  templateUrl: './specific-page.component.html',
  styleUrls: ['./specific-page.component.scss']
})
export class SpecificPageComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  page = 'blog';
  previewPage="specific-blog";
  curComponent = 'specific_blog_page';

  ngOnInit(){
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'About Us Page', active: true }];
  }
}

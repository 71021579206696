<div class="row mx-1 my-2">
  <div class="col-md-6">
    <app-date-picker
      (sendDate)="getAllCountryData($event)"
      [whichPieChart]="1"
    ></app-date-picker>
    <div class="card">
      <div class="min-height-setter">
        <div class="d-flex justify-content-center mt-2">
          <h4>Country-wise booking</h4>
        </div>
        <h5 *ngIf="loader[0]" class="center">Chart Loading....</h5>
       
          <app-ngx-pie-chart
          *ngIf="chartData[0]"
           [chartConfigs]="chartData"
           [index]="0"
          ></app-ngx-pie-chart>
       
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <app-date-picker
      (reportsEvent)="getNewProgressCompleted($event)"
      [whichPieChart]="2"
    ></app-date-picker>
    <div class="card">
      <div class="min-height-setter">
        <div class="d-flex justify-content-center mt-2">
          <h4>New vs in-progress vs completed</h4>
        </div>
        <h5 *ngIf="loader[1]" class="center">Chart Loading....</h5>
     
          <app-ngx-pie-chart
          *ngIf="chartData[1]"
          [chartConfigs]="chartData"
          [index]="1"
        ></app-ngx-pie-chart>
       
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <app-date-picker
      (visaDiyEvent)="getDiyVisapro($event)"
      [whichPieChart]="3"
    ></app-date-picker>
    <div class="card">
      <div class="min-height-setter">
        <div class="d-flex justify-content-center mt-2">
          <h4>DIY vs Visapro</h4>
        </div>
        <h5 *ngIf="loader[2]" class="center">Chart Loading....</h5>
       
          <app-ngx-pie-chart
          *ngIf="chartData[2]"
          [chartConfigs]="chartData"
          [index]="2"
        ></app-ngx-pie-chart>
       
      </div>
    </div>
  </div>
</div>

<div class="my-2 mt-3 row">
    <div class="col-md-12 text-center">
        <label class="col-form-label">{{title}}</label>
    </div>
</div>

<ul class="" style="list-style: auto;" cdkDropList (cdkDropListDropped)="drop($event)">
    <li class="border shadow bg-white px-2 py-1 col-md-10 offset-1 mt-2" *ngFor="let item of data; let i = index;" cdkDrag>
        {{item.country}}
        <div class="example-handle float-end pointer" cdkDragHandle>
            <i class="mdi mdi-cursor-move"></i>
        </div>
    </li>
</ul>
<div class="my-2 row">
    <div class="col-md-12">
        <button class="btn btn-info float-end me-5" (click)="save()">
            Save Changes
        </button>
        <app-publish-btn *ngIf="checkPermission('general_footer_section_publish')" class="float-end me-2" [publish]="'Footer'" [page]="page"></app-publish-btn>
    </div>
</div>
<div class="container">
    <form class="row" [formGroup]="jurisdictionForm" (ngSubmit)="onSubmit()">
            <div class="col-md-4 mt-2" formArrayName="jurisdiction"
            *ngFor="let item of jurisdiction().controls; let i = index;">
                <div class="border shadow p-4 mt-2 bg-white" [formGroupName]="i">
                    <a *ngIf="jurisdiction().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn" (click)="removeJurisdiction(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="my-3 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Name</label>
                            <input type="text" class="form-control" placeholder="Name" formControlName="name" (keyup)="checkDuplicateNames(i, item.value.name)" (change)="changeStatus(i)" [ngClass]="{'invalid': item.get('name').invalid && isSubmitted}">
                        </div>
                    </div>
                    <div class="my-3 row">
                        <div class="col-md-12">
                            <label class="col-form-label">States</label>
                            <ng-select [(items)]="statesList" [closeOnSelect]="false" [multiple]="true" [placeholder]="'States'" bindLabel="name"
                            bindValue="id" formControlName="states" (change)="selectState();changeStatus(i);" [ngClass]="{'invalid': item.get('states').invalid && isSubmitted}" [groupBy]="selectAllStates" [selectableGroup]="true" [selectableGroupAsModel]="false"></ng-select>
                        </div>
                    </div>
                    <div class="my-3 row">
                        <div class="col-md-12">
                            <label class="col-form-label">Internal Code</label>
                            <input type="text" class="form-control" placeholder="Internal Code" formControlName="internal_code" [ngClass]="{'invalid': item.get('internal_code').invalid && isSubmitted}" (change)="changeStatus(i)">
                        </div>
                    </div>


                    <div class="my-2 row">
                        <div class="col-md-12">
                                <div class="form-group">
                                  <label class="col-form-label">Address</label>
                                  <div formArrayName="addresses" class="btn_grp">
                                      <div
                                          *ngFor="let lines of item.get('addresses').controls; let j=index">
                                          <div [formGroupName]="j" class="description">
  
                                              <div class="form-group d-flex my-2">

                                                      <textarea class="form-control" rows="3" [formControl]="lines"  (change)="changeStatus(i)"></textarea>

                                                  <button type="button"
                                                      *ngIf="item.get('addresses').controls.length!=1"
                                                      class="deletebtn btn btn-pseudo remove"
                                                      (click)="deleteAddress(item.controls.addresses, j);"><i
                                                          class="fa fa-times text-danger"></i></button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <button type="button" class="btn btn-sm btn-primary mt-2 float-end"
                                      (click)="addNewAddress(item.controls.addresses)"
                                      *ngIf="item.get('addresses').controls.length<10">
                                      <i class="fa fa-plus"></i> Add Address
                                  </button>
                              </div>
                        </div>
                    </div>




                </div>
            </div>
            <div class="col-md-4 d-flex p-5">
                <div class="m-auto">
                    <button *ngIf="checkPermission('product_management_jurisdiction_create')" class="btn btn-primary mt-1 w-100" type="button" (click)="addJurisdiction()"><i class="uil-plus"></i> Add Jurisdiction</button>
                    <button *ngIf="checkPermission('product_management_jurisdiction_create') || checkPermission('product_management_jurisdiction_edit')" class="btn btn-success mt-1 w-100" type="submit" [disabled]="isDuplicate || isUpdating"><i class="bx bx-save"></i> {{fromAdd ? "Next" : "Save"}}</button>
                </div>
            </div>
    </form>
</div>
import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';
import { MediaService } from 'src/app/core/services/media.service';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-media-header',
  templateUrl: './media-header.component.html',
  styleUrls: ['./media-header.component.scss']
})
export class MediaHeaderComponent {

  headerForm: FormGroup;
  hasChanges = false;

  isUpdating = false;
  deletedheaders = [];
  isSubmitted = false;
  @Input() page;

  constructor(private fb: FormBuilder, private api: MediaService, public permission: PermissionService,
    private toastr: ToastrService, private modalService: NgbModal) { }

  ngOnInit(): void {
    // this.resetForm();


    this.headerForm = this.fb.group({
      image_url: ['', [Validators.required]],
      mob_image_url:['', [Validators.required]],
      button_label: [''],
      button_url: [''],
      id: []
    });
    this.getheaders();
  }

  resetForm() {
    this.headerForm.reset();
  }

  getheaders() {

    if (this.page == 'blog') {

      this.api.getBlogHeader().subscribe(
        (result: any) => {
          this.headerForm.patchValue(result[result.length - 1]);
        });
    } else if (this.page == 'specific_blog_page') {

      this.api.getSpecificBlogHeader().subscribe(
        (result: any) => {
          this.headerForm.patchValue(result[result.length - 1]);
        });
    } else if (this.page == 'author_blog_page') {

      this.api.getAutherBlogHeader().subscribe(
        (result: any) => {
          this.headerForm.patchValue(result[result.length - 1]);
        });
    } else if (this.page == 'news') {

      this.api.getNewsHeader().subscribe(
        (result: any) => {
          this.headerForm.patchValue(result[result.length - 1]);
        });
    } else if (this.page == 'specific_news_page') {

      this.api.getSpecificNewsHeader().subscribe(
        (result: any) => {
          this.headerForm.patchValue(result[result.length - 1]);
        });
    } else if (this.page == 'author_news_page') {

      this.api.getAutherNewsHeader().subscribe(
        (result: any) => {
          this.headerForm.patchValue(result[result.length - 1]);
        });
    }



  }

  // headers(): FormArray {
  //   return this.headerForm.get("headers") as FormArray
  // }

  newheaders(): FormGroup {
    return this.fb.group({
      image_url: ['', [Validators.required]],
      button_label: ['', [Validators.required]],
      button_url: ['', [Validators.required]],
      id: [],
      is_updated: ['']
    })
  }

  addheaders() {
    // this.headers().push(this.newheaders());
  }

  onSubmit(form) {
    if (this.page == 'blog') {
      if (form.valid) {
        this.isUpdating = true;
        this.deletedheaders.forEach(ae => {
          ae.is_updated = false;
          form.value.headers.push(ae);
        })
        if (form.value.id) {

          this.api.putBlogHeader(form.value).subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.getheaders();
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        } else {
          this.api.setBlogHeader(form.value).subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.getheaders();
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }

      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }
    } else if (this.page == 'specific_blog_page') {
      if (form.valid) {
        this.isUpdating = true;
        this.deletedheaders.forEach(ae => {
          ae.is_updated = false;
          form.value.headers.push(ae);
        })

        if (form.value.id) {
          this.api.putSpecificBlogHeader(form.value).subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.getheaders();
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        } else {
          this.api.setSpecificBlogHeader(form.value).subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.getheaders();
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }

      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }

    } else if (this.page == 'author_blog_page') {
      if (form.valid) {
        this.isUpdating = true;
        this.deletedheaders.forEach(ae => {
          ae.is_updated = false;
          form.value.headers.push(ae);
        })

        if (form.value.id) {
          this.api.putAutherBlogHeader(form.value).subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.getheaders();
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        } else {
          this.api.setAutherBlogHeader(form.value).subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.getheaders();
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }

      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }

    } if (this.page == 'news') {
      if (form.valid) {
        this.isUpdating = true;
        this.deletedheaders.forEach(ae => {
          ae.is_updated = false;
          form.value.headers.push(ae);
        })

        if (form.value.id) {
          this.api.putNewsHeader(form.value).subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.getheaders();
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        } else {
          this.api.setNewsHeader(form.value).subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.getheaders();
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }

      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }
    } else if (this.page == 'specific_news_page') {
      if (form.valid) {
        this.isUpdating = true;
        this.deletedheaders.forEach(ae => {
          ae.is_updated = false;
          form.value.headers.push(ae);
        })

        if (form.value.id) {
          this.api.putSpecificNewsHeader(form.value).subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.getheaders();
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        } else {
          this.api.setSpecificNewsHeader(form.value).subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.getheaders();
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }

      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }

    } else if (this.page == 'author_news_page') {
      if (form.valid) {
        this.isUpdating = true;
        this.deletedheaders.forEach(ae => {
          ae.is_updated = false;
          form.value.headers.push(ae);
        })

        if (form.value.id) {
          this.api.putAutherNewsHeader(form.value).subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.getheaders();
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        } else {
          this.api.setAutherNewsHeader(form.value).subscribe(
            () => {
              this.toastr.success("Content Updated Successfully!");
              this.getheaders();
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }

      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }

    }

  }

  dataChanged(i) {
    this.headerForm.value.headers[i].is_updated = true;
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  openUploadModal(field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if(result.status) {
        this.headerForm.get(field).setValue(result.image);
      }
    });
}
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "src/app/core/services/shared.service";
import { SeoSectionComponent } from "../sections/seo-section/seo-section.component";

@Component({
  selector: "app-seo",
  templateUrl: "./seo.component.html",
  styleUrls: ["./seo.component.scss"],
})
export class SeoComponent implements OnInit {
  
  breadCrumbItems: Array<{}>;
  page = "seo";
  country = this.sharedService.selectedCmsCountryId;
  category = this.sharedService.selectedCmsCategoryId;
  countryList = this.sharedService.cmsCountryList;
  categoryList = this.sharedService.cmsCategoryList;
  product = this.sharedService.selectedCmsProductId;
  productList = this.sharedService.cmsProductList;

  @ViewChild(SeoSectionComponent) seoComponent: SeoSectionComponent;
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.breadCrumbItems = [
      { label: "CMS" },
      { label: "Seo Page", active: true },
    ];
    if (this.countryList.length == 0) this.getCountriesList();
  }

  getCountriesList() {
    this.sharedService.getCmsCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response;
        this.sharedService.cmsCountryList = this.countryList;
      })
  }

  selectCountry(country){
    this.country = country;
    this.sharedService.selectedCmsCountryId = country;
    this.sharedService.selectedCmsCategoryId = null;
    this.sharedService.cmsCategoryList = [];
    this.category = null;
    this.categoryList = [];

    this.sharedService.selectedCmsProductId = null;
    this.sharedService.cmsProductList = [];
    this.product = null;
    this.productList = [];
    if(this.page=='country') this.seoComponent.dataOnSelect();
    this.getCategories(country);
    this.getCountrySeo(country);
  }

  getCountrySeo(country?, category?, product?){
    this.seoComponent?.getData(country, category, product);
  }

  getCategories(country_id){
    this.sharedService.getCmsCategoryList(country_id).subscribe(
      (result:any)=>{
        this.categoryList = result;
        this.sharedService.cmsCategoryList = this.categoryList;
      })
  }

  selectCategory(category){
    this.category = category;
    this.sharedService.selectedCmsCategoryId = category;
    this.sharedService.selectedCmsProductId = null;
    this.sharedService.cmsProductList = [];
    this.product = null;
    this.productList = [];
    if(this.page=='category')this.seoComponent.dataOnSelect();
    this.getProducts(category);
    this.getCountrySeo(this.country, category);
  }

  getProducts(category){
    this.sharedService.getCmsProductList(category).subscribe(
      (result:any)=>{
        this.productList = result;
        this.sharedService.cmsProductList = this.productList;
      })
  }

  selectProduct(product){
    this.product = product;
    this.sharedService.selectedCmsProductId = product;
    if(this.page=='product') this.seoComponent.dataOnSelect();
    this.getCountrySeo(this.country, this.category, product);
  }

  // getCountriesList() {
  //   this.sharedService.getCmsCountryDetails().subscribe(
  //     (response: any) => {
  //       this.countryList = response;
  //       this.sharedService.cmsCountryList = this.countryList;
  //     });
  // }

  // selectCountry(country) {
  //   this.sharedService.selectedCmsCountryId = country;
  //   this.sharedService.selectedCmsCategoryId = null;
  //   this.sharedService.cmsCategoryList = [];
  //   this.category = null;
  //   this.categoryList = [];
  //   this.getCategories(country);
  // }

  // selectCategory(category) {
  //   this.sharedService.selectedCmsCategoryId = category;
  // }
  // getCategories(country_id) {
  //   this.sharedService.getCmsCategoryList(country_id).subscribe(
  //     (result: any) => {
  //       this.categoryList = result;
  //       this.sharedService.cmsCategoryList = this.categoryList;
  //     });
  // }
}

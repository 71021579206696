<div class="modal-header">
  <h5 class="modal-title">Add User</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">

  <ng-wizard [config]="config">

    <ng-wizard-step [description]="'User Details'">
      <div class="tab-pane" id="user-details">
        <app-user-details [fromParent]="fromParent" (showNextStep)="showNextStep($event)"></app-user-details>
      </div>
    </ng-wizard-step>

    <ng-wizard-step [description]="'Country Assign'">
      <div class="tab-pane" id="country_assign">
        <app-country-assign [fromParent]="fromParent" (showNextStep)="showNextStep($event)"></app-country-assign>
      </div>
    </ng-wizard-step>


    <ng-wizard-step [description]="'User Role Details'">
      <div class="tab-pane" id="user-role-details">
        <app-user-role-details [fromParent]="fromParent" (showNextStep)="showNextStep($event)"></app-user-role-details>
      </div>
    </ng-wizard-step>


  </ng-wizard>

</div>
import { Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { HFCForm } from "src/app/core/models/add-on.model";
import { ProductService } from "src/app/core/services/product.service";

@Component({
  selector: "app-add-on-hotel-flight",
  templateUrl: "./add-on-hotel-flight.component.html",
  styleUrls: ["./add-on-hotel-flight.component.scss"],
})
export class AddOnHotelFlightComponent {
  isHotelAndFlightUpdated: boolean = false;
  hotelAndFlightId: number;
  addOnForm: FormGroup;
  constructor(
    private api: ProductService,
    private tosatr: ToastrService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getHotelAndFlight();
    this.getForm();
  }
  getHotelAndFlight() {
    this.api.getHotelAndFlight().subscribe((res: any) => {
      this.isHotelAndFlightUpdated = res?.length === 0 ? false : true;
      this.hotelAndFlightId = res[0]?.id ? res[0].id : "";
      this.getForm(res[0]?.key_values[0])
    });
  }

  getForm(data?:any) {
    this.addOnForm = this.fb.nonNullable.group<HFCForm>({
      hotel: new FormControl(data?.Hotel?data.Hotel:null,[Validators.required]),
      flight: new FormControl(data?.Flight?data.Flight:null,[Validators.required]),
     courier: new FormControl(data?.courier?data.courier:null,[Validators.required]),
     Itinerary_price_a: new FormControl(data?.Itinerary_price_a?data.Itinerary_price_a:null,[Validators.required]),
     Itinerary_price_b: new FormControl(data?.Itinerary_price_b?data.Itinerary_price_b:null,[Validators.required])
    });
  }

  postHotelAndFlight() {
    if(this.addOnForm.invalid){
      this.tosatr.error("All fields are required");
      return;
    }
    const data = {
      key_values: [
        {
          Hotel: this.addOnForm.value.hotel,
          Flight: this.addOnForm.value.flight,
          courier:this.addOnForm.value.courier,
          Itinerary_price_a:this.addOnForm.value.Itinerary_price_a,
          Itinerary_price_b:this.addOnForm.value.Itinerary_price_b
        },
      ],
    };
    if (!this.isHotelAndFlightUpdated) {
      this.api.postHotelAndFlight(data).subscribe((res) => {
        this.tosatr.success("Success", "Created successfully");
      });
    } else {
      this.api
        .editHotelAndFlight(data, this.hotelAndFlightId)
        .subscribe((res) => {
          this.tosatr.success(
            "Success",
            "Edited successfully"
          );
        });
    }
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit{

  breadCrumbItems: Array<{}>;
  page = 'home';

  ngOnInit(){
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'Home Page', active: true }];
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { HomeManagementService } from 'src/app/core/services/home-management.service';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CustomSortComponent } from '../../ui-elements/custom-sort/custom-sort.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';

@Component({
  selector: 'app-useful-media',
  templateUrl: './useful-media.component.html',
  styleUrls: ['./useful-media.component.scss']
})
export class UsefulMediaComponent implements OnInit {

  public Editor = ClassicEditor;
  mediaForm: FormGroup;
  hasChanges = false;

  isUpdating = false;
  deletedmedia = [];
  isSubmitted = false;
  @Input() page;


  constructor(private fb: FormBuilder,public permission: PermissionService, 
    private api: HomeManagementService, private toastr: ToastrService, 
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.resetForm();
    this.getMedia();
  }

  resetForm() {
    this.mediaForm = this.fb.group({
      media: this.fb.array([
        this.fb.group({
          id: ['', [Validators.required]],
          media_type: ['Blog', [Validators.required]],
          heading: ['', [Validators.required]],
          description: ['', [Validators.required]],
          date_published: ['', [Validators.required]],
          link_url: ['', [Validators.required]],
          banner_image_url: ['', [Validators.required]],
          is_updated: [''],
        })])
    });
  }

  getMedia() {
    this.api.getUsefulMedia().subscribe(
      (result: any) => {
        this.mediaForm = this.fb.group({
          media: new FormArray([]),
        });
        result.forEach(ao => {
          (this.mediaForm.get('media') as FormArray).push(
            this.fb.group({
              id: [ao.id, [Validators.required]],
              media_type: [ao.media_type, [Validators.required]],
              heading: [ao.heading, [Validators.required]],
              description: [ao.description, [Validators.required]],
              date_published: [ao.date_published, [Validators.required]],
              link_url: [ao.link_url, [Validators.required]],
              banner_image_url: [ao.banner_image_url, [Validators.required]],
              is_updated: [false],
            })
          );
          this.deletedmedia = [];
        });
      })
  }

  media(): FormArray {
    return this.mediaForm.get("media") as FormArray
  }

  newmedia(): FormGroup {
    return this.fb.group({
      media_type: ['Blog', [Validators.required]],
      heading: ['', [Validators.required]],
      description: ['', [Validators.required]],
      date_published: ['', [Validators.required]],
      link_url: ['', [Validators.required]],
      banner_image_url: ['', [Validators.required]],
      is_updated: [true],
    })
  }

  addmedia() {
    this.media().push(this.newmedia());
  }

  removemedia(i: number) {
    this.isSubmitted = false;
    this.mediaForm.value.media[i].is_deleted = true;
    if (this.mediaForm.value.media[i].hasOwnProperty("id")) this.deletedmedia.push(this.mediaForm.value.media[i]);
    this.media().removeAt(i);
  }

  onSubmit(form) {
    if(form.valid){
      this.isUpdating = true;
      this.deletedmedia.forEach(ae => {
        ae.is_updated = false;
        form.value.media.push(ae);
      })
      this.api.setUsefulMedia(form.value.media).subscribe(
        () => {
          this.toastr.success("Content Updated Successfully!");
          this.getMedia();
          this.hasChanges = false;
          this.isUpdating = false;
        },
        () => {
          this.isUpdating = false;
        }
      )
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }
  }

  dataChanged(i) {
    this.mediaForm.value.media[i].is_updated = true;
  }

  checkPermission(type){
    return this.permission.hasPermission(type);
  }

  showSortModal(data, label) {
    let data_card: any = {
      list: data,
      label: label,
      api_url: 'cms-admin/home/useful-media-priority/position'
    };
    const modalRef = this.modalService.open(CustomSortComponent,
      {
        size: 'md',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.sortData = data_card;
    modalRef.result.then((result) => {
      if (result) this.getMedia();
    });
  }

  openUploadModal(i, field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        this.mediaForm.get(['media', i, field]).setValue(result.image);
        this.mediaForm.get(['media', i, 'is_updated']).setValue(true);
      }
    });
  }

}

import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ngx-pie-chart',
  templateUrl: './ngx-pie-chart.component.html',
  styleUrls: ['./ngx-pie-chart.component.scss']
})
export class NgxPieChartComponent implements OnInit {
@Input() chartConfigs:any
@Input() index:any

ngOnInit(): void {
  console.log(this.chartConfigs)
}


}

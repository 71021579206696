<div class="container">
  <div class="my-3 row">
    <div class="offset-md-4 col-md-4">
      <ng-select
        [(items)]="categoryList"
        [multiple]="false"
        [placeholder]="'Select Category'"
        bindLabel="name"
        bindValue="id"
        [(ngModel)]="category"
        [clearable]="false"
        (change)="selectCategory(category, false)"
        [ngModelOptions]="{ standalone: true }"
      ></ng-select>
    </div>
    <div class="col-md-4">
      <ng-select
        [(items)]="subCatList"
        [multiple]="false"
        [placeholder]="'Select Sub-Category'"
        bindLabel="name"
        bindValue="id"
        [(ngModel)]="subcat"
        [clearable]="false"
        (change)="getAgeGroupUnderSubCat(subcat)"
        [ngModelOptions]="{ standalone: true }"
      ></ng-select>
    </div>
  </div>
  <form class="row" [formGroup]="ageGroupForm" (ngSubmit)="onSubmit(true)">
    <div
      class="col-md-4 mt-2"
      formArrayName="age_groups"
      *ngFor="let item of ageGroup().controls; let i = index"
    >
      <div class="border shadow bg-white p-4" [formGroupName]="i">
        <a
          *ngIf="ageGroup().controls.length > 1"
          href="javascript:void(0)"
          class="ms-2 close-btn"
          (click)="removeAgeGroup(i)"
          ><i class="uil-times fa-2x"></i
        ></a>
        <div class="card-body p-2">
          <h5 class="card-title">
            <label class="col-form-label">Group Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Group Name"
              formControlName="name"
              (keyup)="checkDuplicateNames(i, item.value.name)"
              (change)="changeStatus(i)"
              [ngClass]="{ invalid: item.get('name').invalid && isSubmitted }"
            />
          </h5>
          <div class="card-text text-start">
            <label class="col-form-label">Age</label>
            <div class="d-flex">
              <input
                type="number"
                placeholder="Minimum Age"
                class="form-control"
                formControlName="min_year"
                (change)="changeStatus(i)"
                min="0"
                [ngClass]="{
                  invalid: item.get('min_year').invalid && isSubmitted
                }"
              />
              <label class="m-auto">&nbsp;-&nbsp;</label>
              <input
                type="number"
                placeholder="Maximum Age"
                class="form-control"
                formControlName="max_year"
                (change)="changeStatus(i)"
                min="1"
                [ngClass]="{
                  invalid: item.get('max_year').invalid && isSubmitted
                }"
              />
            </div>
          </div>
          <div class="mt-2 row">
            <div class="col-md-12">
              <label class="col-form-label">Internal Code</label>
              <input
                type="text"
                class="form-control"
                placeholder="Internal Code"
                formControlName="internal_code"
                (change)="changeStatus(i)"
                [ngClass]="{
                  invalid: item.get('internal_code').invalid && isSubmitted
                }"
              />
            </div>
          </div>
          <div class="row">
            <div class="mt-2 col-md-6">
              <div>
                <label class="col-form-label">Visa Fee</label>
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  placeholder="Visa Fee"
                  formControlName="visa_fee"
                  (change)="changeStatus(i)"
                  [ngClass]="{
                    invalid: item.get('visa_fee').invalid && isSubmitted
                  }"
                />
              </div>
              <div>
                <label class="col-form-label cpr d-flex align-item-center">
                  <input
                    type="checkbox"
                    class="cpr"
                    (click)="changeStatus(i)"
                    formControlName="pay_visa_fee"
                  />
                  <span class="ms-1"> Is Payable </span>
                </label>
              </div>
            </div>
            <div class="mt-2 col-md-6">
              <div>
                <label class="col-form-label">Vendor Fee</label>
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  placeholder="Vendor Fee"
                  formControlName="vendor_fee"
                  (change)="changeStatus(i)"
                  [ngClass]="{
                    invalid: item.get('vendor_fee').invalid && isSubmitted
                  }"
                />
              </div>
              <div>
                <label class="col-form-label cpr d-flex align-item-center">
                  <input
                    type="checkbox"
                    (click)="changeStatus(i)"
                    class="cpr"
                    formControlName="pay_vendor_fee"
                  />
                  <span class="ms-1">Is Payable</span>
                </label>
              </div>
            </div>
            <div class="mt-2 col-md-6">
              <div>
                <label class="col-form-label">Express Fee</label>
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  placeholder="Express Fee"
                  formControlName="express_fee"
                  (change)="changeStatus(i)"
                  [ngClass]="{
                    invalid: item.get('express_fee').invalid && isSubmitted
                  }"
                />
              </div>
              <div>
                <label class="col-form-label cpr d-flex align-item-center">
                  <input
                    type="checkbox"
                    (click)="changeStatus(i)"
                    class="cpr"
                    formControlName="pay_express_fee"
                  />
                  <span class="ms-1"> Is Payable </span></label
                >
              </div>
            </div>
            <div class="mt-2 col-md-6">
              <div>
                <label class="col-form-label">Merch Fee</label>
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  placeholder="Merch Fee"
                  formControlName="merch_fee"
                  (change)="changeStatus(i)"
                  [ngClass]="{
                    invalid: item.get('merch_fee').invalid && isSubmitted
                  }"
                />
              </div>
              <div>
                <label class="col-form-label cpr d-flex align-item-center">
                  <input
                    type="checkbox"
                    (click)="changeStatus(i)"
                    formControlName="pay_merch_fee"
                    class="cpr"
                  />
                  <span class="ms-1">Is Payable</span>
                </label>
              </div>
            </div>
            <div class="mt-2 col-md-6">
              <div>
                <label class="col-form-label">Consultant Fee</label>
                <input
                  type="number"
                  class="form-control"
                  min="0"
                  placeholder="Consultant Fee"
                  formControlName="consultant_fee"
                  (change)="changeStatus(i)"
                  [ngClass]="{
                    invalid: item.get('consultant_fee').invalid && isSubmitted
                  }"
                />
              </div>
              <div>
                <label class="col-form-label cpr d-flex align-item-center">
                  <input
                    type="checkbox"
                    (click)="changeStatus(i)"
                    class="cpr"
                    formControlName="pay_consultant_fee"
                  />
                  <span class="ms-1">Is Payable</span>
                </label>
              </div>
            </div>
            <!-- <div class="mt-2 col-md-6">
              <label class="col-form-label">Courier Fee</label>
              <input type="number" class="form-control" min="0" placeholder="Courier Fee" formControlName="courier_fee" (change)="changeStatus(i)" [ngClass]="{'invalid': item.get('consultant_fee').invalid && isSubmitted}">
            </div> -->
            <div class="mt-2 col-md-6">
              <label class="col-form-label">GST %</label>
              <input
                type="number"
                class="form-control"
                min="0"
                max="100"
                placeholder="GST Percentage"
                formControlName="gst"
                (change)="changeStatus(i)"
                [ngClass]="{ invalid: item.get('gst').invalid && isSubmitted }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 d-flex p-5">
      <div class="m-auto">
        <ng-select
          *ngIf="checkPermission('product_management_age_group_create')"
          [(items)]="selectedCategoryList"
          [multiple]="false"
          [placeholder]="'Select Category'"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="selected_category"
          [clearable]="false"
          (change)="selectCategory(selected_category, true)"
          [ngModelOptions]="{ standalone: true }"
        ></ng-select>

        <ng-select
          *ngIf="checkPermission('product_management_age_group_create')"
          class="mt-1"
          [(items)]="selectedSubCatList"
          [multiple]="false"
          [placeholder]="'Select Sub-Category'"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="selected_subcat"
          [clearable]="false"
          (change)="selectAgeSuggestion(selected_subcat)"
          [ngModelOptions]="{ standalone: true }"
        ></ng-select>

        <ng-select
          *ngIf="selected_category && selected_subcat"
          class="mt-1"
          [(items)]="selectedAgeGroupList"
          [multiple]="false"
          [placeholder]="'Select From Suggestion'"
          bindLabel="name"
          bindValue="id"
          [(ngModel)]="selected_agegroup"
          [clearable]="false"
          [ngModelOptions]="{ standalone: true }"
          (change)="selectSuggestedAge(selected_agegroup)"
        ></ng-select>

        <button
          *ngIf="checkPermission('product_management_age_group_create')"
          class="btn btn-primary mt-1 w-100"
          type="button"
          (click)="addAgeGroup()"
          [disabled]="!category || !subcat"
        >
          <i class="uil-plus"></i> Add Blank
        </button>

        <button
          *ngIf="fromAdd && ageGroupForm.value.age_groups.length > 0"
          class="btn btn-success mt-1 w-100"
          (click)="onSubmit(false)"
          type="button"
          [disabled]="isDuplicate || !category || !subcat"
        >
          <i class="bx bx-save"></i> Save
        </button>

        <button
          *ngIf="checkPermission('product_management_age_group_edit')"
          class="btn btn-success mt-1 w-100"
          type="submit"
          [disabled]="
            isDuplicate || isUpdating || ageGroup().controls.length == 0
          "
        >
          <i class="bx bx-save"></i> {{ fromAdd ? "Next" : "Save" }}
        </button>
      </div>
    </div>
  </form>
</div>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { WorkflowService } from 'src/app/core/services/workflow.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { FlowViewComponent } from './flow-view/flow-view.component';

@Component({
  selector: 'app-work-flow',
  templateUrl: './work-flow.component.html',
  styleUrls: ['./work-flow.component.scss']
})
export class WorkFlowComponent implements OnInit {

  breadCrumbItems: Array<{}>;
  stepsList = [];
  country = this.sharedService.selectedCountryId;
  countryList = this.sharedService.addedCountryList;
  @ViewChild(FlowViewComponent, {static: true}) private flowViewCmp: FlowViewComponent;

  constructor(elementRef: ElementRef, public api: WorkflowService, 
    private sharedService: SharedService) { }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'Work Flow', active: true }];
    this.stepsList = this.api.stepsList;
    if(this.stepsList.length==0) this.getDefaultWorkFlows();
    if(this.countryList.length==0) this.getCountriesList();
    if(this.country) this.flowViewCmp.getWorkFlows(this.country);
  }

  getCountriesList() {
    this.sharedService.getCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response;
        this.sharedService.addedCountryList = this.countryList;
      })
  }

  selectCountry(country){
    this.sharedService.selectedCountryId = country;
    this.country = country;
    this.flowViewCmp.getWorkFlows(country);
  }

  getDefaultWorkFlows(){
    this.api.getDefaultWorkFlows().subscribe(
      result=>{
        this.stepsList = [];
        result.forEach(ae => {
          this.stepsList.push({
            ...ae,
            selected: false,
            isNew: true,
            isUpdated: true
          })
        });
        this.api.stepsList = this.stepsList;
      }
    )
  }

}

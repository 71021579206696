import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductPageService {

  constructor(private http: HttpClient) { }

  getDescription(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/products/product-description/?product=${id}`).pipe(retry(2));
  }

  setDescription(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/products/product-description/${id}/`, data);
  }

  postDescription(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/products/product-description/`, data);
  }

  // side info
  getSideInfo(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/products/sideinfo-product/?product=${id}`).pipe(retry(2));
  }

  setSideInfo(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/products/sideinfo-product/`, data);
  }

  // Eligibility
  getEligibility(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/products/product-eligibility/?product=${id}`).pipe(retry(2));
  }

  setEligibility(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/products/product-eligibility/${id}/`, data);
  }

  postEligibility(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/products/product-eligibility/`, data);
  }

  // Eligibility
  getSkuData(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/products/product-data/?product=${id}`).pipe(retry(2));
  }

  setSkuData(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/products/product-data/${id}/`, data);
  }

  postSkuData(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/products/product-data/`, data);
  }

  //sku price
  getPricing(id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/products/product-price/?product=${id}`).pipe(retry(2));
  }

  setPricing(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/products/product-price/`, data);
  }

  // How to apply 
  getApplySteps(product): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/products/product-visa-process/?product=${product}`).pipe(retry(2));
  }

  setApplySteps(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/products/product-visa-process/`, data);
  }

  // how to apply process
  getProductApplyProcess(product): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/products/product-application-process/?product=${product}`).pipe(retry(2));
  }

  setProductApplyProcess(id, data): Observable<any> {
    return this.http.put(`${environment.apiUrl}/cms-admin/products/product-application-process/${id}/`, data);
  }

  postProductApplyProcess(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/products/product-application-process/`, data);
  }

  // how to apply steps
  getProductApplySteps(product): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/products/product-application-step/?product=${product}`).pipe(retry(2));
  }

  setProductApplySteps(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/products/product-application-step/`, data);
  }

  // required docs
  getRequiredDocs(product): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/products/product-required-document/?product=${product}`).pipe(retry(2));
  }

  setRequiredDocs(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/products/product-required-document/`, data);
  }

  // Additional Info
  getAdditionalInfo(product): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/products/product-additional-information/?product=${product}`).pipe(retry(2));
  }

  setAdditionalInfo(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/products/product-additional-information/`, data);
  }
  
  // Voucher 
  getVoucher(product): Observable<any> {
    return this.http.get(`${environment.apiUrl}/cms-admin/products/voucher/?product=${product}`).pipe(retry(2));
  }

  setVoucher(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/cms-admin/products/voucher/`, data);
  }


}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ProductService } from 'src/app/core/services/product.service';
import { SharedService } from 'src/app/core/services/shared.service';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-required-docs',
  templateUrl: './required-docs.component.html',
  styleUrls: ['./required-docs.component.scss']
})
export class RequiredDocsComponent implements OnInit {

  countryList = this.sharedService.addedCountryList;
  breadCrumbItems: Array<{}>;
  country = this.sharedService.selectedCountryId;
  requiredDocsList = [];
  selected = false;
  isLoading = false;
  selectedCountry;
  reqdDocForm: FormGroup;
  isAdding = false;
  selectedDelete:any;
  isDeleting = false;

  isEdit = false;
  @ViewChild('addmodal', { static: false }) subModal: ElementRef;

  constructor(private api: ProductService, private sharedService: SharedService, 
    private fb: FormBuilder, private toastr: ToastrService, private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'Required Documents' }, { label: 'Jurisdiction', active: true }];
    this.resetForm();
    if (this.countryList.length == 0) this.getCountriesList();
    if(this.country) this.getDocs(this.country);
  }

  resetForm(data?) {
    this.reqdDocForm = this.fb.group({
      doc_id: [ data ? data.id : null, [Validators.required]],
      name: [ data ? data.name : '', [Validators.required]],
    });
  }

  getCountriesList() {
    this.api.getCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response;
        this.sharedService.addedCountryList = this.countryList;
      })
  }

  getDocs(country) {
    this.selectedCountry = country;
    this.selected = true;
    this.requiredDocsList = [];
    this.resetForm();
    this.api.getRequiredDocs(country).subscribe(
      (result: any) => {
        this.requiredDocsList = result;
      })
  }

  onSubmit() {
    this.isLoading = true;
    this.reqdDocForm.value.country = this.country;
    this.requiredDocsList = [];
    if(this.isEdit){
      this.api.editRequiredDocs(this.reqdDocForm.value.doc_id, this.reqdDocForm.value).subscribe(
        (result)=>{
          this.isLoading = false;
          this.resetForm();
          this.getDocs(this.country);
          this.modalService.dismissAll({ data: 'details'});
          this.toastr.success('Document Updated Successfully!');
          this.isEdit = false;
        },
        error=>{
          this.isLoading = false;
        })
    } else {
      this.api.setRequiredDocs(this.reqdDocForm.value).subscribe(
        (result)=>{
          this.isLoading = false;
          this.resetForm();
          this.getDocs(this.country);
          this.modalService.dismissAll({ data: 'details'});
          this.toastr.success('Document Added Successfully!')
        },
        error=>{
          this.isLoading = false;
        })
    }
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  deleteDoc(id){
    this.isDeleting = true;
    this.api.deleteRequiredDocs(id).subscribe(
      success=>{
        this.isDeleting = false;
        this.requiredDocsList = this.requiredDocsList.filter(ae=>ae.id!=id);
        this.selectedDelete = {};
        this.modalService.dismissAll({ data: 'details'});
      },
      error=>{
        this.isDeleting = false;
      }
    )
  }
  

}

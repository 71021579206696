import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { VisaManageService } from 'src/app/core/services/visa-manage.service';

@Component({
  selector: 'app-question-section',
  templateUrl: './question-section.component.html',
  styleUrls: ['./question-section.component.scss']
})

export class QuestionComponent implements OnInit {

  @Input() isMasterQuestion: boolean = false;
  @Input() questionsList;
  @Input() paxId;
  @Input() fromVisaFlow;
  @Input() isMaster;
  @Input() checked;

  constructor(private visaMngService: VisaManageService, private toastr: ToastrService) {

  }

  ngOnInit(): void {

    this.questionsList.forEach(ae => {
      ae.form_inputs.forEach(ao => {
        ao.is_verified = ao.verification_required == true ? false : true;
      });
    });
  }

  

  checkRequired(i) {
    for (let j in this.questionsList[i].form_inputs) {
      if (this.questionsList[i].form_inputs[j].agent_raised) { this.checked[i] = false; return true };
    }
    for (let j in this.questionsList[i].form_inputs) {
      if (!this.questionsList[i].form_inputs[j].is_verified) return true;
    }
    return false;
  }

  checkRaised(i) {
    for (let j in this.questionsList[i].form_inputs) {
      if (this.questionsList[i].form_inputs[j].customer_raised) { this.checked[i] = true; return true };
    }
    return false;
  }

  onFormSubmit(form: any) {

    form.visa_process_id = 0;
    delete form.visa_process_id;


    form['pax_id'] = this.paxId;
    this.visaMngService.postVisaProcessSteps(form).subscribe(
      (result: any) => {
        this.toastr.clear();
        this.toastr.success("Submitted successfully");
      }, error => {
        console.log(error);
        this.toastr.clear();
        this.toastr.error("Error occured");
      });
  }

}

import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PermissionService } from 'src/app/core/services/permision.service';
import { NotificationTemplatesService } from 'src/app/core/services/notification-templates.service';
import { AddCoverLetterComponent } from './add-cover-letter/add-cover-letter.component';

@Component({
  selector: 'app-cover-letter',
  templateUrl: './cover-letter.component.html',
  styleUrls: ['./cover-letter.component.scss']
})
export class CoverLetterComponent {
  breadCrumbItems: Array<{}>;
  isLoading = false;
  isDeleting = false;
  isEdit = false;
  coverList: any = [];
  selectedCoverDel: any;
  @ViewChild(AddCoverLetterComponent) addMsg:AddCoverLetterComponent;

  @ViewChild("coverDetails", { static: false }) covreModal: ElementRef;
  modalReference: any;

  constructor(
    private modalService: NgbModal,
    private api: NotificationTemplatesService,
    private toastr: ToastrService,
    public activeModal: NgbActiveModal,
    public permission: PermissionService
  ) {}

  ngOnInit() {
    this.breadCrumbItems = [
      { label: "Settings" },
      { label: "Templates" },
      { label: "Cover", active: true },
    ];
    this.getDataList();
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: "modal-basic-title" });
  }

  addCoverModal(data?) {
    const modalRef = this.modalService.open(AddCoverLetterComponent, {
      size: "lg",
      // scrollable: true,
      windowClass: "modal-small-lg",
      backdrop: "static",
    });

    modalRef.componentInstance.fromParent = data ? data : null;

    modalRef.result.then((result) => {
      this.getDataList();
    },
      (reason) => {}
    );
  }


  getDataList() {
    this.api.getCoverNotification().subscribe((response: any) => {
      this.coverList = response.results;
    });
  }

  deleteCover(table){
    this.isDeleting = true;
    this.api.deleteCoverNotification(table.id).subscribe(
      (result: any) => {
        this.isDeleting = false;
        this.modalService.dismissAll({ data: 'details' });
        this.toastr.success("Deleted Successfully!");
        this.getDataList();
      },
      error => {
        this.isDeleting = false;
      });
  }
}

<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <app-question-section [isMasterQuestion]="isMasterQuestion" [questionsList]="questionsList" [fromVisaFlow]="fromVisaFlow" [checked]="checked" [paxId]="paxId" [isMaster]="isMaster"></app-question-section>
        </div>
        <div class="col-12 text-end" *ngIf="fromVisaFlow" >
            <button class="btn btn-info me-2" (click)="unblockQuestions()" (mouseenter)="lock=true;" (mouseleave)="lock=false;" [disabled]="checkUnblocked()">
                <i class="fas" [ngClass]="{'fa-pulse fa-spin fa-spinner': isUnblocking , ' fa-lock': !isUnblocking && !lock , 'fa-lock-open': !isUnblocking && lock}"></i> Unblock Questions</button>
            <button *ngIf="isMaster" class="btn btn-success" [disabled]="!isVerify && checkVerified()" (click)="verifiedOk()"><i class="fas" [ngClass]="{'fa-check' : !isVerifying, 'fa-spinner fa-pulse fa-spin' : isVerifying}"></i> Verify</button>
            </div>
    </div>
</div>
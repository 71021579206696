<div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="header_portion d-flex justify-content-end mb-3">

                <div class="cursor-pointer cpr" (click)="openFormModal()">
                    <i class="mdi mdi-plus"></i>
                    Create New
                </div>
            </div>
        </div>
    </div>

    <!-- #region :- Tab view of questions -->
    <div class="row">
        <div class="col-12">

            <!--START:- LOADER -->
            <div class="loader_center d-flex align-items-center justify-content-center" *ngIf="loader">
                <div class="spinner-border m-5" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <!--END:- LOADER -->

            <div class="example-list" *ngIf="!loader">
                <div class="example-box cpr" *ngFor="let globalQ of globalQues;let i=index"
                    (click)="openCurrentModal(globalQ)">
                    <div>
                        {{i+1}}.&nbsp;{{globalQ?.display_name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- #endregion :- Tab view of questions -->


    <!-- #region :- Pagination section -->
    <div *ngIf="collectionSize" class="d-flex justify-content-between align-items-center">
        <div class="d-flex">
            <div *ngFor="let item of itemsArrToDspl"
                class="nmb_of_items cpr select_none {{(item==itemsInAPage)? 'active':''}}"
                (click)="itemsInAPageChanged(item)">
                {{item}}
            </div>
        </div>

        <ngb-pagination class="d-flex justify-content-end" [(page)]="page" [pageSize]="itemsInAPage"
            [collectionSize]="collectionSize" [maxSize]="4" [rotate]="true" [boundaryLinks]="true"
            (pageChange)="loadPage($event)">
        </ngb-pagination>
    </div>
    <!-- #endregion :- Pagination section -->
</div>
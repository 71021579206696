<div class="modal-header">
    <h5 class="modal-title w-100 text-center">{{details.name ? details.name : 'Add'}} Integration</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()">
    </button>
</div>

<form [formGroup]="integrationForm" (ngSubmit)="onSubmit(integrationForm)">
    <div class="modal-body">
        <div class="my-3 row">
            <div class="col-md-12">
                <label class="col-md-12 col-form-label">Name</label>
                <div class="col-md-12">
                    <input type="text" class="form-control" formControlName="name" placeholder="Enter Name"
                        [ngClass]="{'invalid': integrationForm.get('name').invalid && isSubmitted}">
                </div>
            </div>
            <div class="col-md-12">
                <label class="col-md-12 col-form-label">Icon URL</label>
                <div class="col-md-12 d-flex">
                    <input type="file" accept="image/*" (change)="handleIcon($event.target.files)" class="form-control" placeholder="Upload Icon" [ngClass]="{'invalid': integrationForm.get('icon_url').invalid && isSubmitted}">
                    <a [href]="integrationForm.value.icon_url" target="_blank"><img class="ms-1" height="40"
                        [src]="integrationForm.value.icon_url"></a>
                </div>
                <div class="col-md-12" *ngIf="progress>0">
                    <div class="mt-2 progress-bar">
                        <div class="progress" [style.width.%]="progress">
                            {{progress}}%
                        </div>
                      </div>
                </div>
            </div>
            <div class="form-group col-md-12">
                <div formArrayName="key_values">
                    <div class="row">
                        <label class="col-form-label col-4">Key</label>
                        <label class="col-form-label col-8">Value</label>
                    </div>
                    <div class="scrollable-div">
                        <div *ngFor="let item of keyValues.controls; let i=index">
                            <div [formGroupName]="i" [ngClass]="{'mt-2':i>0}">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <input type="text" placeholder="Enter Key" class="form-control"
                                                formControlName="key">
                                        </div>
                                        <div class="col-md-8">
                                            <div class="d-flex">
                                                <input type="text" placeholder="Enter Value" class="form-control"
                                                    formControlName="value">
                                                <button type="button" class="m-auto btn btn-pseudo remove"
                                                    (click)="removeKeyValue(i)">
                                                    <i class="fa fa-times text-danger"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" class="btn btn-sm btn-primary mt-2 float-end" (click)="addKeyValue()"> <i class="fa fa-plus"></i> Add </button>
            </div>
            <div class="col-md-12 text-end mt-3">
                <button type="submit" class="btn btn-primary me-2"><i [ngClass]="{'mdi mdi-floppy': !isAdding, 'fa fa-spin fa-spinner fa-pulse': isAdding}"></i> Save</button>
                <button class="btn btn-secondary" (click)="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
</form>
<div class="container-fluid mnht-300">
    <app-page-title title="Work Flow" [breadcrumbItems]="breadCrumbItems"></app-page-title>
    <div class="my-3 row">
        <div class="offset-md-8 col-md-4">
            <ng-select [(items)]="countryList" [multiple]="false" [placeholder]="'Select Country'" bindLabel="name"
                bindValue="id" [(ngModel)]="country" [clearable]="false" (change)="selectCountry(country)"></ng-select>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6 offset-lg-3 set-minwh-1" *ngIf="country==null;">
            <div class="card text-center p-4">
                Select Country to Continue
            </div>
        </div>
        <div class="col-12" [hidden]="country==null">
            <div class="wrapper">
                <app-flow-view [stepsList]="stepsList"></app-flow-view>
                <!-- <app-flow-view></app-flow-view> -->
            </div>
        </div>
    </div>
</div>
<!-- <pre>
    {{flowData|json}}
</pre> -->
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CountryQuestionsService {

  constructor(private http: HttpClient) { }


  getCountry(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/country/`).pipe(retry(2));
  }

  getCountryForms(countryId: number): Observable<any> {
    return this.http.get(`${environment.apiUrl}/country-forms/` + `?country=${countryId}`).pipe(retry(2));
  }

  getCountryFormsQuestion(countryFormId): Observable<any> {

    return this.http.get(`${environment.apiUrl}/country-questionnaire/` + `?form=${countryFormId}`).pipe(retry(2));
  }

  getFormSections(formId:number): Observable<any> {

    return this.http.get(`${environment.apiUrl}/country-form-sections/` + `?form=${formId}`).pipe(retry(2));
  }

  getSectionsWiseQuestion(sectionId:number): Observable<any> {
    
    return this.http.get(`${environment.apiUrl}/country-questionnaire/` + `?section=${sectionId}`).pipe(retry(2));
  }

  getAnswerChoices(queId:number): Observable<any> {

    return this.http.get(`${environment.apiUrl}/country-answer-choices/` + `?country_question=${queId}`).pipe(retry(2));
  }

  




  createCountryForm(data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/country-forms/`, data).pipe(retry(2));
  }

  addCountryFormQuestions(id: number, data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/country-forms/` + `${id}` + `/questions/`, data).pipe(retry(2));
  }

  createQuestion(id: number, data: any): Observable<any> {
    // country-forms/10/questions/
    return this.http.post(`${environment.apiUrl}/country-forms/` + `${id}` + `/questions/`, data).pipe(retry(2));
  }

  copyForm(data: any): Observable<any> {
    // country-forms/10/questions/
    return this.http.post(`${environment.apiUrl}/country-forms/form-duplication/`, data).pipe(retry(2));
  }


  editCountryQuestionaire(id: number, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/country-questionnaire/` + `${id}/`, data).pipe(retry(2));
  }

  editCountryFormName(id: number, data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/country-forms/` + `${id}/`, data).pipe(retry(2));
  }

  editSectionPosition(data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/country-form-sections/position/`, data).pipe(retry(2));
  }

  editQuePosition(data: any): Observable<any> {
    return this.http.put(`${environment.apiUrl}/country-questionnaire/question-position/`, data).pipe(retry(2));
  }

  deleteCountryForms(id: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/country-forms/` + `${id}/`);
  }

  deleteFormsQuestion(formId: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/country-questionnaire/` + `${formId}/`);
  }

  patchRaiseQueState(countryId: number,data:any): Observable<any> {
    return this.http.patch(`${environment.apiUrl}/country-forms/` + `${countryId}` + '/',data).pipe(retry(2));
  }


}

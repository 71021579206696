import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/core/services/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OtherPagesService } from 'src/app/core/services/other-pages.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-delete-section',
  templateUrl: './delete-section.component.html',
  styleUrls: ['./delete-section.component.scss']
})
export class DeleteSectionComponent implements OnInit {

  countryList: any;
  categoryList: any;
  productList: any;

  breadCrumbItems: Array<{}>;

  country: number;
  category: number;
  product: number;

  cmsCountry: number;

  isCountry: boolean;
  isCategory: boolean;
  isProduct: boolean;

  selectedCountryDetails: any;
  selectedCategoryDetails: any;
  selectedProductDetails: any;
  selectedCMSCountryDetails: any;

  confirmDeleteName:string;
  isNameSame:boolean;
  isDeleting:boolean;

  confirmCMSDeleteName:string;
  isCMSNameSame:boolean;

  @ViewChild('deletemodal', { static: false }) deleteModal: ElementRef;

  constructor(private sharedService: SharedService, private modalService: NgbModal, private delService: OtherPagesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'CMS' }, { label: 'Delete Section', active: true }];
    this.getCountriesList();
  }

  getCountriesList() {
    this.sharedService.getCmsCountryDetails().subscribe(
      (response: any) => {
        this.countryList = response;
      })
  }

  selectCountry(country) {
    this.categoryList = [];
    this.category = null;
    this.productList = [];
    this.product = null;
    this.getCategories(country);
  }

  selectCategory(category) {
    this.productList = [];
    this.product = null;
    this.getProducts(category);
  }

  getCategories(country_id) {
    this.sharedService.getCmsCategoryList(country_id).subscribe(
      (result: any) => {
        this.categoryList = result;
      })
  }

  getProducts(category) {
    this.sharedService.getCmsProductList(category).subscribe(
      (result: any) => {
        this.productList = result;
      })
  }

  open(content) {
    const modalRef = this.modalService.open(content,
      {
        ariaLabelledBy: 'modal-basic-title',
        animation: false,
        centered: true,
        keyboard: false,
        size: 'lg',
        backdrop: 'static'
      });
      modalRef.result.then((result) => {
        this.resetAll();
      }, (reason) => {
       this.resetAll();
      });
  }

  resetAll(){
    this.isCountry = false;
    this.isCategory = false;
    this.isProduct = false;
  
    this.selectedCountryDetails = null;
    this.selectedCategoryDetails = null;
    this.selectedProductDetails = null;
  
    this.confirmDeleteName = null;
    this.isNameSame = false;

    this.cmsCountry = null;
    this.confirmCMSDeleteName = null;
  }

  hardReset(){
    this.getCountriesList();
    this.country = null;
    this.categoryList = [];
    this.category = null;
    this.productList = [];
    this.product = null;
    this.cmsCountry = null;
    this.resetAll();
  }

  deleteCountry() {
    this.isCountry = true;
    this.isCategory = false;
    this.isProduct = false;
    this.getDetails();
    this.open(this.deleteModal);
  }

  deleteCategory() {
    this.isCountry = false;
    this.isCategory = true;
    this.isProduct = false;
    this.getDetails();
    this.open(this.deleteModal);
  }

  deleteProduct() {
    this.isCountry = false;
    this.isCategory = false;
    this.isProduct = true;
    this.getDetails();
    this.open(this.deleteModal);
  }

  getDetails() {
    if (this.isCountry) {
      this.delService.getDeleteCountryData(this.country).subscribe(
        data => {
          this.selectedCountryDetails = data;
        })
    } else if (this.isCategory) {
      this.delService.getDeleteCategoryData(this.category).subscribe(
        data => {
          this.selectedCategoryDetails = data;
        })
    } else if (this.isProduct) {
      this.delService.getDeleteProductData(this.product).subscribe(
        data => {
          this.selectedProductDetails = data;
        })
    }
  }

  checkSameName(typedname){
    let realname = this.isCountry ? this.selectedCountryDetails?.name : (this.isCategory ? this.selectedCategoryDetails?.name : this.selectedProductDetails?.heading);
    if(realname.trim() === typedname.trim()) this.isNameSame = true;
    else this.isNameSame = false;
  }
  
  checkCMSSameName(typedname){
    let realname = this.selectedCMSCountryDetails?.name
    if(realname.trim() === typedname.trim()) this.isCMSNameSame = true;
    else this.isCMSNameSame = false;
  }

  confirmDelete(){
    this.isDeleting = true;
    if(this.isCountry){
      this.delService.deleteCountryData(this.country).subscribe(
        result=>{
          this.isDeleting = false;
          this.hardReset();
          this.modalService.dismissAll();
          this.sharedService.countryList = [];
          this.sharedService.cmsCountryList = [];
          this.sharedService.cmsCategoryList = [];
          this.sharedService.cmsProductList = [];
          this.sharedService.addedCountryList=[];
          this.toastr.warning("Country Deleted Successfully!");
        }, error=>{
          this.isDeleting = false;
        })
    } else if(this.isCategory){
      this.delService.deleteCategoryData(this.category).subscribe(
        result=>{
          this.isDeleting = false;
          this.hardReset();
          this.modalService.dismissAll();
          this.sharedService.countryList = [];
          this.sharedService.cmsCountryList = [];
          this.sharedService.cmsCategoryList = [];
          this.sharedService.cmsProductList = [];
          this.sharedService.addedCountryList=[];
          this.toastr.warning("Category Deleted Successfully!");
        }, error=>{
          this.isDeleting = false;
        })
    } if(this.isProduct){
      this.delService.deleteProductData(this.product).subscribe(
        result=>{
          this.isDeleting = false;
          this.hardReset();
          this.modalService.dismissAll();
          this.sharedService.countryList = [];
          this.sharedService.cmsCountryList = [];
          this.sharedService.cmsCategoryList = [];
          this.sharedService.cmsProductList = [];
          this.sharedService.addedCountryList=[];
          this.toastr.warning("Product Deleted Successfully!");
        }, error=>{
          this.isDeleting = false;
        })
    }
  }

  getCMSDetails(cmsCountry){
    this.delService.getDeleteCountryData(cmsCountry).subscribe(
      data => {
        this.selectedCMSCountryDetails = data;
      })
  }

  confirmCMSDelete(){
    this.isDeleting = true;
    this.delService.deleteCMSCountryData(this.cmsCountry).subscribe(
      result=>{
        this.isDeleting = false;
        this.hardReset();
        this.modalService.dismissAll();
        this.sharedService.countryList = [];
        this.sharedService.cmsCountryList = [];
        this.toastr.warning("Country Data Deleted Successfully!");
      }, error=>{
        this.isDeleting = false;
      })
  }


}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/core/services/product.service';
import { SharedService } from 'src/app/core/services/shared.service';

@Component({
  selector: 'app-add-edit-category-modal',
  templateUrl: './add-edit-category-modal.component.html',
  styleUrls: ['./add-edit-category-modal.component.scss']
})
export class AddEditCategoryModalComponent implements OnInit {
  catForm: FormGroup;
  @Input() countryId;
  @Input() category:any;
  isSubmittingCat = false;
  
  //selectedCat;
  isSubmitted = false;
  isSuggestCat = false;
  checkInterval: any;
  isUrlCat = false;
constructor(public activeModal: NgbActiveModal,private fb: FormBuilder,private api: ProductService,private toastr: ToastrService,private sharedService: SharedService){

}

ngOnInit(): void {
  //console.log(this.countryId)
  this.resetForm(this.category?this.category:'')
}



resetForm(data?) {
  console.log(data)
  this.catForm = this.fb.group({
    id: [data ? data.id : ''],
    name: [data ? data.name : '', [Validators.required]],
    country: [this.countryId, [Validators.required]],
    internal_code: [data ? data.internal_code : '', [Validators.required]],
    url_param: [data ? data.url_param : '', [Validators.required]],
    sub_categories: [[]],
  })
}
  closeModal(){
this.activeModal.close();
  }

  generateUrlParam(isCat, text) {
    if (isCat && this.isSuggestCat) this.catForm.get('url_param').setValue(text.toLowerCase().replace(/[^\w\s]/gi, '').split(' ').join('-'));
  }

  checkURLParam(url, isCat) {
    let data:any;
    data = {
          category_url_param: url,
           country_id: this.countryId
         }
  if(this.category?.id) data.id=this.category?.id
    clearTimeout(this.checkInterval);
    // if (isCat) {
    //   this.isUrlCat = true;
    //   data = {
    //     category_url_param: url,
    //     country_id: this.countryId
    //   }
    //   if(this.isCatEdit) data.id = this.selectedCatId;
    // } else {
    //   this.isUrlSubCat = true;
    //   data = {
    //     product_url_param: url,
    //     category_id: this.selectedCatId
    //   }
    //   if(this.isCatEdit) data.id = this.selectedSubCat.id;
    // }
    
    this.checkInterval = setTimeout(() => {
      this.sharedService.checkUrlParam(data).subscribe(
        result => {
          if(result.is_exists) {
            this.isUrlCat=true;
            this.toastr.warning("URL Param must be unique")}else{
              this.isUrlCat=false;
            };
          // this.isDupUrl = result.is_exists;
          // if (!this.isDupUrl) {
          //   if (isCat) this.isUrlCat = false;
          //   else this.isUrlSubCat = false;
          // }
         // if (this.isDupUrl) this.toastr.warning("URL Param must be unique");
        }
      )
    }, 1000);
  }

  onCatSubmit(form) {
    if (form.valid) {
      this.isSubmittingCat = true;
      if (this.category?.id) {
        console.log('heloo')
        this.api.editCatTypeDetails(this.category?.id, form.value).subscribe(
          (result: any) => {
            this.isSubmittingCat = false;
            this.resetForm();
            // this.categoryList.forEach((ae, i) => {
            //   if (ae.id == this.selectedCat.id) this.categoryList[i] = result;
            // });
           // if (!this.fromAdd) 
            this.toastr.success("Category Updated Successfully!");
            this.activeModal.close(true)
          },
          error => {
            this.isSubmittingCat = false;
          })
      } else {
        this.api.setCatTypeDetails(form.value).subscribe(
          (result: any) => {
            // this.categoryList.forEach(ae => {
            //   ae.selected = false;
            // });
            result.selected = true;
           // this.categoryList.push(result);
            this.resetForm();
           // this.showCatForm = false;
           // this.selectedCatId = result.id;
           // this.resetSubCatForm();
           // this.subCatList = result.sub_categories;
            this.isSubmittingCat = false;
            //if (!this.fromAdd) 
            this.toastr.success("Category Added Successfully!");
            this.activeModal.close(true)
          },
          error => { this.isSubmittingCat = false; })
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all the fields to continue")
    }
  }
}

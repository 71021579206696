import { Component, NgZone, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { GlobalQuestionsService } from 'src/app/core/services/global-questions.service';
import { ToastrService } from 'ngx-toastr';
import { CountryQuestionsService } from 'src/app/core/services/country-questions.service';
import { ChangePositionModalComponent } from './change-position-modal/change-position-modal.component';

@Component({
  selector: 'app-country-form-modal',
  templateUrl: './country-form-modal.component.html',
  styleUrls: ['./country-form-modal.component.scss']
})


export class CountryFormModalComponent implements OnInit {
  openFor: string;
  allFormQuestions: any;
  currQueData: any;
  subCatForm: any;
  fieldTypeOptions: any;
  _EDIT: string = 'edit';
  _CREATE: string = 'create';
  formValues: FormGroup;
  answerChoicesOptions: any;
  storVariOptions: any;

  // formValues = this.fb.group({
  //   field_name: ['', Validators.required],
  //   field_type: [null, Validators.required],
  //   display_name: ['', Validators.required],
  //   info: ['', Validators.required],
  //   tag: ['', Validators.required],
  //   miscellaneous: [{}, Validators.required],
  //   is_required: [false]
  // });

  constructor(public activeModal: NgbActiveModal, private ngZone: NgZone, private fb: FormBuilder,
    public globalQueService: GlobalQuestionsService, private toastr: ToastrService, private modalService: NgbModal, public countryQueSer: CountryQuestionsService) {
     

    this.formValues = this.fb.group({
      field_name: ['', Validators.required],
      field_type: [null, Validators.required],
      display_name: ['', Validators.required],
      answer_choices: this.fb.array([]),
      info: [''],
      tag: ['', Validators.required],
      miscellaneous: [{}, Validators.required],
      storage_variable: [null],
      is_required: [false],
      is_tabular_parent:[true],
      verification_required: [false],
      related_question: [null],
      related_answer_condition: [null],
    });
  }

  ngOnInit(): void {
    console.log(this.currQueData)
    this.getFieldTypes();
    this.getStorVariOptions();
    this.setData(this.currQueData);

  }

  setData(data: any) {
    this.formValues.patchValue(data);
    this.addAnswerChoice(data.answer_choices);
    this.relateQuestionChange(data.related_question, data.related_answer_condition);
  }


  getFieldTypes() {
    this.globalQueService.getFormFields().subscribe((data: any) => {
      this.fieldTypeOptions = data;
    }), (error => {
      this.activeModal.close();
    });
  }

  changePosition(){
    let arrayOfMarriageStatus=this.answerChoices.value 
    const modalRef=this.modalService.open(ChangePositionModalComponent,{centered:true})
    modalRef.componentInstance.arrayToSort=arrayOfMarriageStatus
    modalRef.componentInstance.openFor=this.openFor
    modalRef.result.then((res)=>{
      if(res){
        this.formValues.markAsDirty()
        this.answerChoices.setValue(res)
      }
     
    })
  }

  getStorVariOptions() {
    this.globalQueService.getStorVarOptions().subscribe((data: any) => {

      this.storVariOptions = data;

    }), (error => {
      console.log(error);

    });
  }
 

  changeFieldType() {
    if (this.formValues.value.field_type == 3 || this.formValues.value.field_type == 4 || this.formValues.value.field_type == 12 || this.formValues.value.field_type == 5) {

      if (this.formValues.value.answer_choices && this.formValues.value.answer_choices < 1) {

        this.newAnswerChoice();
      }

    }

  }





  get answerChoices() {
    return this.formValues.controls["answer_choices"] as FormArray;
  }

  addAnswerChoice(data): void {




    for (let index = 0; index < data?.length; index++) {

      const element = data[index];
      let answer_choice_array = this.fb.group({
        id: [element.id, Validators.required],
        answer_choice: [element.answer_choice, Validators.required],
        position:[element.position]
      });

      this.answerChoices.push(answer_choice_array);

    }


  }


  newAnswerChoice(): void {

    const answer_choice_array = this.fb.group({
      id: [''],
      answer_choice: ['', Validators.required],
      // position:['']
    });

    this.answerChoices.push(answer_choice_array);

  }

  deleteAnswerChoice(index: number) {
    this.answerChoices.removeAt(index);
    this.formValues.markAsDirty()
  }

  relateQuestionChange(value, ans?) {

    if (value != undefined && value != undefined) {

      this.answerChoicesOptions = [];
      this.formValues.controls['related_answer_condition'].setValue('');
      this.countryQueSer.getAnswerChoices(value).subscribe((res: any) => {

        this.answerChoicesOptions = res;
        if (ans) this.formValues.controls['related_answer_condition'].setValue(ans);

      }, err => {
        console.log(err);
      })
    }

  }

  onSubmit() {

    if (this.formValues.valid && this.openFor == this._CREATE) {

      let id = this.currQueData.id;
      let dataToSend = { questions: this.formValues.value };

      this.countryQueSer.createQuestion(id, dataToSend).subscribe((res: any) => {
        this.toastr.success('Data added successfully');
        this.activeModal.close({ reload: true });

      }, err => { console.log(err) });

    } else {

      if (this.formValues.dirty) {

        let id = this.currQueData.id;
        let dataToSend = this.formValues.value;



        dataToSend = Object.assign({}, dataToSend, { section: this.currQueData?.section });

        this.countryQueSer.editCountryQuestionaire(id, dataToSend).subscribe(res => {
          this.activeModal.close({ reload: true });
          this.toastr.success('Successfully Saved This Edited Data');
        }), ((err: any) => {
          console.log(err);
        });

      } else {
        this.toastr.warning('Please update something !');
      }

    }
  }

  delConfModal(modalElem: any) {
    this.modalService.open(modalElem, { ariaLabelledBy: 'modal-basic-title' }).result.then();
  }

  deleteQuestion(modalElem: any) {
    let id = this.currQueData.id;
    this.countryQueSer.deleteFormsQuestion(id).subscribe((res: any) => {
      this.toastr.success('Successfully deleted.');
      this.activeModal.close({ reload: true });
    }), (err => {
      console.log(err);
    })
  }

}

import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormArray, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { PermissionService } from "src/app/core/services/permision.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MediaService } from "src/app/core/services/media.service";
import { UploadGalleryComponent } from "src/app/shared/ui/upload-gallery/upload-gallery.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "src/app/core/services/shared.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-specific-content",
  templateUrl: "./specific-content.component.html",
  styleUrls: ["./specific-content.component.scss"],
})
export class SpecificContentComponent implements OnInit {
  public Editor = ClassicEditor;
slugTitle:string=''
isAddCheck:boolean=false
  featuresForm: FormGroup;
  hasChanges = false;
  fileToUpload: File | [null] = [null];
  loading: boolean = false;
  isUpdating = false;
  progress: number[] = [0];
  deletedFeatures = [];
  deletedDetails = [];
  isSubmitted = false;
  isContentLoaded = false;
  allMedia: any;
  pageSize = 10;
  pageNumber = 1;
  totalCount: number;
  isSlugList: boolean = false;
  slugListArray = [];
  fetauresArray: any = [];
  seoComp: boolean = false;
  page_details = {
    count: 1,
    current: 0,
    limit: 10,
  };
  slugId: any;
  seoType: string;
  @Input() page;

  constructor(
    private fb: FormBuilder,
    private api: MediaService,
    public permission: PermissionService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private sharedService:SharedService,
    private router:Router
  ) {}

  ngOnInit(): void {
    this.resetForm();
    this.getSpecificData();
    this.getUsefulMedia();
    this.backtoListFun();
  }

  backtoListFun() {
    this.sharedService.backToList$.subscribe((res) => {
      if (res) {
        this.isSlugList = false;
        this.seoComp = false;
      }
    });
  }

  resetForm() {
    this.featuresForm = this.fb.group({
      features: this.fb.array([
        this.fb.group({
          id: [""],
          useful_media: [],
          useful_media_url: [""],
          is_updated: [""],
          is_deleted: [""],
          details: new FormArray([]),
        }),
      ]),
    });
  }

  get details() {
    return (this.featuresForm.get("features") as FormArray)
      .at(0)
      .get("details") as FormArray;
  }

  newDetails() {
    // return this.fb.group({
    //   description: [''],
    //   heading: [''],
    //   id: [null],
    //   image_url: [''],
    //   is_deleted: [false],
    //   is_updated: [false],
    // })

    let arr = new FormArray([]);
    arr.push(
      this.fb.group({
        description: "",
        heading: [""],
        id: [null],
        image_url: [""],
        is_deleted: [false],
        is_updated: [false],
      })
    );
    return arr;
  }

  addDetailsTofmGrp(): FormGroup {
    return this.fb.group({
      description: [""],
      heading: [""],
      id: [null],
      image_url: [""],
      is_deleted: [false],
      is_updated: [false],
    });
  }

  addDetails(item: any) {
    item.controls.details.push(this.addDetailsTofmGrp());
  }

  delDetails(i, j, detail) {
    if (detail.value.id) {
      detail.get("is_deleted").setValue(true);
    } else {
      const featureArray = this.featuresForm.get("features") as FormArray;
      const detailsArray = featureArray.at(i).get("details") as FormArray;
      detailsArray.removeAt(j);
    }
    // const featureArray = this.featuresForm.get('features') as FormArray;
    // const detailsArray = (featureArray.at(i).get('details') as FormArray);
    // detailsArray.at(j).get('is_deleted').setValue(true);
    // detailsArray.removeAt(j);
  }

  getUsefulMedia() {
    if (this.page == "blog") {
      this.api.getUsefulMedia(1).subscribe((res) => {
        this.allMedia = res;
      });
    } else if (this.page == "news") {
      this.api.getUsefulMedia(2).subscribe((res) => {
        this.allMedia = res;
      });
    }
  }

  addFetchedDataToDetails(data: any) {
    let arr = new FormArray([]);

    if (data && data.length) {
      data.forEach((eachItem) => {
        arr.push(
          this.fb.group({
            description: eachItem.description,
            heading: eachItem.heading,
            id: eachItem.id,
            image_url: eachItem.image_url,
            is_deleted: eachItem.is_deleted,
            is_updated: eachItem.is_updated,
          })
        );
      });
    }
    return arr;

    // console.log(data);
    // let fmArray = this.newDetails();
    // if (data) {
    //   for (const item of data) {
    //     fmArray.patchValue(item);
    //   }
    // }
    // console.log(fmArray);

    // return fmArray;
  }

  getSpecificData() {
    (this.featuresForm.get("features") as FormArray).clear();
    if (this.page == "blog") {
      this.slugListArray = [];
      this.api.getSpecificMedia(1, this.page_details).subscribe(
        (result: any) => {
          this.loading = true;
          this.page_details.count = result?.count;
          this.fetauresArray = result;
          result?.results?.forEach((ao) => {
            (this.featuresForm.get("features") as FormArray).push(
              this.fb.group({
                id: [ao.id, [Validators.required]],
                image_url: [ao.image_url],
                heading: [ao.heading],
                description: [ao.description],
                useful_media: [ao.useful_media],
                useful_media_url: [ao.useful_media_url],
                is_updated: [false],
                is_deleted: [false],
                details: this.addFetchedDataToDetails(ao?.details),
              })
            );
            this.deletedFeatures = [];
            this.slugListArray.push(ao);
            this.slugListArray.sort((a, b) => a.useful_media_url.localeCompare(b.useful_media_url))
          });
          this.isContentLoaded = true;
        },
        (error) => {
          console.log(error);
        }
      );
    } else if (this.page == "news") {
      this.slugListArray = [];
      this.api.getNewsSpecificMedia(2, this.page_details).subscribe(
        (result: any) => {
          this.loading = true;
          this.page_details.count = result?.count;
          this.fetauresArray = result;
          result?.results?.forEach((ao) => {
            (this.featuresForm.get("features") as FormArray).push(
              this.fb.group({
                id: [ao.id, [Validators.required]],
                image_url: [ao.image_url],
                heading: [ao.heading],
                description: [ao.description],
                useful_media: [ao.useful_media],
                useful_media_url: [ao.useful_media_url],
                is_updated: [false],
                is_deleted: [false],
                details: this.addFetchedDataToDetails(ao.details),
              })
            );
            this.deletedFeatures = [];
            this.slugListArray.push(ao);
            this.slugListArray.sort((a, b) => a.useful_media_url.localeCompare(b.useful_media_url))
          });
          this.isContentLoaded = true;
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  features(): FormArray {
    return this.featuresForm.get("features") as FormArray;
  }

  newFeatures(): FormGroup {
    return this.fb.group({
      id: [null],
      // image_url: [''],
      // heading: [''],
      // description: [''],
      // useful_media: ["", [Validators.required]],
      useful_media: [""],
      useful_media_url: [""],
      is_updated: [false],
      is_deleted: [false],
      details: this.newDetails(),
    });
  }

  addFeatures() {
    this.isAddCheck=true;
    this.isSlugList = true;
    this.seoComp = false;
    (this.featuresForm.get("features") as FormArray).clear();
    this.features().push(this.newFeatures());
  }

  removeFeatures(i: number) {
    this.isSubmitted = false;
    this.featuresForm.value.features[i].is_deleted = true;
    if (this.featuresForm.value.features[i].hasOwnProperty("id"))
      this.deletedFeatures.push(this.featuresForm.value.features[i]);
    this.features().removeAt(i);
  }

  onSubmit(form) {
    if (this.page == "blog") {
      if (form.valid) {
        this.isUpdating = true;
        this.deletedFeatures.forEach((ae) => {
          ae.is_updated = false;
          form.value.features.push(ae);
        });
        this.api.setSpecificMedia(form.value.features).subscribe(
          (res) => {
            this.toastr.success("Content Updated Successfully!");
            this.getSpecificData();
            this.hasChanges = false;
            this.isUpdating = false;
            this.isSlugList = false;
          },
          (err) => {
            this.isUpdating = false;
            this.toastr.error(err.error);
          }
        );
      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }
    } else if (this.page == "news") {
      if (form.valid) {
        this.isUpdating = true;
        this.deletedFeatures.forEach((ae) => {
          ae.is_updated = false;
          form.value.features.push(ae);
        });
        this.api.setSpecificMedia(form.value.features).subscribe(
          (res) => {
            this.toastr.success("Content Updated Successfully!");
            this.getSpecificData();
            this.hasChanges = false;
            this.isUpdating = false;
            this.isSlugList = false;
          },
          (err) => {
            this.isUpdating = false;
            console.log(err);
          }
        );
      } else {
        this.isSubmitted = true;
        this.toastr.warning("Fill all fields and Save!");
      }
    }
  }

  dataChanged(i) {
    if (this.featuresForm.value.features[i].id) {
      this.featuresForm.value.features[i].is_updated = true;
    } else {
      this.featuresForm.value.features[i].is_updated = false;
    }
  }

  detailsDataChanged(i, j) {
    if (this.isDetailsReadyForUpdates(i, j)) {
      let featureArray = this.featuresForm.get("features") as FormArray;
      let detailArray = (featureArray.at(i).get("details") as FormArray).at(j);
      detailArray.get("is_updated").setValue(true);
    }
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  openUploadModal(i, j, field) {
    const modalRef = this.modalService.open(UploadGalleryComponent, {
      size: "xl",
      scrollable: true,
      windowClass: "modal-big-lg",
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if (result.status) {
        let featureArray = this.featuresForm.get("features") as FormArray;
        let detailArray = (featureArray.at(i).get("details") as FormArray).at(
          j
        );
        let imageValue = detailArray.get("image_url").value;
        if (imageValue) {
          detailArray.get("is_updated").setValue(true);
        }
        detailArray.get("image_url").setValue(result.image);
      } else {
        this.toastr.clear();
        this.toastr.error("Updation Failed");
      }
    });
  }

  isDetailsReadyForUpdates(i, j) {
    if (this.featuresForm.value.features[i].details[j].id) {
      return true;
    } else {
      return false;
    }
  }

  editSlug(item: any, index: number) {
    let useFullMedia;
    useFullMedia=this.allMedia.filter((res)=>item?.useful_media===res?.id)
    this.isSlugList = true;
    this.isAddCheck=false;
    (this.featuresForm.get("features") as FormArray).clear();

    this.fetauresArray?.results?.forEach((ao, index) => {
      if (item.id == ao.id) {
        (this.featuresForm.get("features") as FormArray).push(
          this.fb.group({
            id: [ao.id, [Validators.required]],
            image_url: [ao.image_url],
            heading: [ao.heading],
            description: [ao.description],
           // useful_media: [ao.id],
            useful_media: [useFullMedia[0]?.heading],
            useful_media_url: [ao.useful_media_url],
            is_updated: [false],
            is_deleted: [false],
            details: this.addFetchedDataToDetails(ao.details),
          })
        );
      }
    });
  }

  backtoList() {
    this.isSlugList = false;
  }

  gotoSeo(item: any) {
    this.seoComp = true;
    this.slugId = item.useful_media;
    this.slugTitle=item?.useful_media_url
    this.seoType = this.page === "news" ? "news_specific" : "blogs_specific";
  }

  nextPage(pageNumber) {
    this.loading = false;
    (this.page_details.current = this.pageSize * (pageNumber - 1)),
      this.getSpecificData();
  }

  gotoDescription(item:any){
    this.router.navigate(['/content/specific/description'],{queryParams:{id:item?.useful_media,page:this.page}})
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { HomeManagementService } from 'src/app/core/services/home-management.service';
import { ToastrService } from 'ngx-toastr';
import { OtherPagesService } from 'src/app/core/services/other-pages.service';
import { PermissionService } from 'src/app/core/services/permision.service';
import { UploadGalleryComponent } from 'src/app/shared/ui/upload-gallery/upload-gallery.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// Pages => home, testimonial, faq, consultant
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  headerForm: FormGroup;
  fileToUpload: File | null = null;
  hasChanges = false;
  isUpdating = false;
  isSubmitted = false;
  @Input() page;
  isNew = false;
  publishPage;

  constructor(private fb: FormBuilder,
    private api: HomeManagementService, private toastr: ToastrService,
    public permission: PermissionService,
    private otherApi: OtherPagesService, private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.resetForm();
    this.getData();
    switch (this.page) {
      case 'home': this.publishPage = 'HomeDescription'; break;
      case 'testimonial': this.publishPage = 'TestimonialDescription'; break;
      case 'faq': this.publishPage = 'FaqDescription'; break;
      case 'consultant': this.publishPage = 'ConsultancyDescription'; break;
      default: break;
    }
  }

  resetForm(data?) {
    this.headerForm = this.fb.group({
      id: [data ? data.id : null],
      heading: [data ? data.heading : '-'],
      sub_heading: [data ? data.sub_heading : '-'],
      description: [data ? data.description : '-'],
      image_url: [data ? data.image_url : ''],
      mob_banner_image_url: [data ? data.mob_banner_image_url : ''],
      headings: new FormArray([]),
    })
    if (this.page == 'home') {
      if (data?.heading != undefined) {
        let heads = JSON.parse(data?.heading);
        heads.forEach(ao => {
          (this.headerForm.get('headings') as FormArray).push(
            this.fb.group({
              text: [ao, [Validators.required]],
            })
          );
        });
      } else {
        this.addheads();
      }
    }

    if (this.page == 'testimonial' || this.page == 'faq' || this.page == 'home') { this.headerForm.get('description').setValue('-'); }

    if (this.page == 'home') { this.headerForm.get('heading').setValue('-'); }

    if (this.page == 'faq') { this.headerForm.get('sub_heading').setValue('-'); }
    this.onChanges();
  }

  heads(): FormArray {
    return this.headerForm.get("headings") as FormArray
  }

  newheads(): FormGroup {
    return this.fb.group({
      text: ['', [Validators.required]]
    })
  }

  addheads() {
    this.heads().push(this.newheads());
  }

  removeheads(i: number) {
    this.isSubmitted = false;
    this.heads().removeAt(i);
  }

  onChanges(): void {
    this.headerForm.valueChanges.subscribe(() => {
      Object.keys(this.headerForm.controls).map((key) => {
        if (this.headerForm.get(key).dirty) this.hasChanges = true;
      })

    });
  }

  // handleIcon(files: FileList) {
  //   this.hasChanges = true;
  //   this.fileToUpload = files.item(0);
  //   let unsafeImageUrl = URL.createObjectURL(this.fileToUpload);
  //   this.headerForm.value.image_url = this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
  // }

  onSubmit(form) {
    if (form.valid) {
      this.isUpdating = true;
      // const formData: FormData = new FormData();
      // if (this.fileToUpload) formData.append('image_url', this.fileToUpload);
      // else formData.delete('image_url')
      // if (this.page == 'home') {
      //   let heads = [];
      //   form.value.headings.forEach(ae => {
      //     heads.push(ae.text);
      //   });
      //   // formData.append('heading', JSON.stringify(heads));
      // }
      // else formData.append('heading', form.value.heading);
      // formData.append('sub_heading', form.value.sub_heading);
      // formData.append('description', form.value.description);
      if (this.page == 'testimonial') {
        if (this.isNew) {
          this.otherApi.postTestimonialData(form.value).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Added Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
              this.isNew = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }
        else {
          this.otherApi.setTestimonialData(form.value.id, form.value).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }
      } else if (this.page == 'faq') {
        if (this.isNew) {
          this.otherApi.postFaqDescription(form.value).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Added Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
              this.isNew = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }
        else {
          this.otherApi.setFaqDescription(form.value.id, form.value).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }
      } else if (this.page == 'consultant') {
        if (this.isNew) {
          this.otherApi.postConsultantDescription(form.value).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Added Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
              this.isNew = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }
        else {
          this.otherApi.setConsultantDescription(form.value.id, form.value).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }
      } else {
        if (this.isNew) {
          this.api.postHomeDescription(form.value).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Added Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
              this.isNew = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        } else {
          this.api.setHomeDescription(form.value.id, form.value).subscribe(
            (result: any) => {
              this.resetForm(result);
              this.toastr.success("Content Updated Successfully!");
              this.hasChanges = false;
              this.isUpdating = false;
            },
            () => {
              this.isUpdating = false;
            }
          )
        }
      }
    } else {
      this.isSubmitted = true;
      this.toastr.warning("Fill all fields and Save!");
    }

  }

  getData() {
    if (this.page == 'testimonial') {
      this.otherApi.getTestimonialData().subscribe(
        (result: any) => {
          this.resetForm(result[0]);
          if (result.length == 0) this.isNew = true;
          else { this.isNew = false; };
        },

      )
    } else if (this.page == 'faq') {
      this.otherApi.getFaqDescription().subscribe(
        (result: any) => {
          this.resetForm(result[0]);
          if (result.length == 0) this.isNew = true;
          else this.isNew = false;
        },

      )
    } else if (this.page == 'consultant') {
      this.otherApi.getConsultantDescription().subscribe(
        (result: any) => {
          this.resetForm(result[0]);
          if (result.length == 0) this.isNew = true;
          else this.isNew = false;
        },

      )
    }
    else {
      this.api.getHomeDescription().subscribe(
        (result: any) => {
          this.resetForm(result[0]);
          if (result.length == 0) this.isNew = true;
          else this.isNew = false;
        },
      )
    }
  }

  checkPermission(type) {
    return this.permission.hasPermission(type);
  }

  openUploadModal(field) {
    const modalRef = this.modalService.open(UploadGalleryComponent,
      {
        size: 'xl',
        scrollable: true,
        windowClass: 'modal-big-lg',
        keyboard: false,
        backdrop: 'static'
      });
    modalRef.componentInstance.uploadDetails = {};
    modalRef.result.then((result) => {
      if(result.status) {
        this.headerForm.get(field).setValue(result.image);
      }
    });
}

}

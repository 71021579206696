import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  NgWizardConfig,
  NgWizardService,
  StepChangedArgs,
  StepValidationArgs,
  STEP_STATE,
  THEME,
} from "ng-wizard";
import { of } from "rxjs";
import { UserDetailsComponent } from "./user-details/user-details.component";
import { UserRoleDetailsComponent } from "./user-role-details/user-role-details.component";
import { SharedService } from "src/app/core/services/shared.service";
import { CountryAssignComponent } from "./country-assign/country-assign.component";

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  @Input() fromParent: any;
  @ViewChild(UserDetailsComponent) userDetailComponent: UserDetailsComponent;
  @ViewChild(UserRoleDetailsComponent) roleDetailComponent: UserRoleDetailsComponent;
  @ViewChild(CountryAssignComponent) CountryAssignComponent: CountryAssignComponent;

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden,
  };

  config: NgWizardConfig = {
    selected: 0,
    anchorSettings: {
      enableAllAnchors: false, //make tabs enable
    },
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false,
    },
  };

  collectedData: any = {};

  constructor(
    public activeModal: NgbActiveModal,
    private ngWizardService: NgWizardService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.activeModal.close("close");
  }

  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?) {
    console.log("event.data", event.data)
    this.ngWizardService.next();
    this.collectedData[event.tab] = event.data;
    this.sharedService.selectedUserPermissionData = event.data;
  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) {}

  isValidTypeBoolean: boolean = true;

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return of(true);
  }
}

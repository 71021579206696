import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
  styleUrls: ["./date-picker.component.scss"],
})
export class DatePickerComponent implements OnInit {
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = this.calendar.getPrev(
      this.calendar.getToday(),
      "d",
      30
    );
  toDate: NgbDate | null = this.calendar.getToday()
  @Input()whichPieChart:number
  @Output() sendDate: EventEmitter<any> = new EventEmitter();
  @Output() reportsEvent: EventEmitter<any> = new EventEmitter();
  @Output() visaDiyEvent: EventEmitter<any> = new EventEmitter();
  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
    
  }
  ngOnInit(): void {
   
  }

  passDateEvent() {
    const date = {
      fromDate:
        this.fromDate.year +
        "-" +
        this.fromDate.month +
        "-" +
        this.fromDate.day,
      toDate:
        this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day,
    };
    if(this.whichPieChart===1){
      this.sendDate.emit(date);
    }else if(this.whichPieChart===2){
      this.reportsEvent.emit(date);
    }else {
      this.visaDiyEvent.emit(date);
    }
   
   
     
   
    
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    if(this.fromDate && this.toDate){
      console.log(this.fromDate);
      this.passDateEvent()
    }
 
  
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }
}

import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { JurisdictionDetailsComponent } from './jurisdiction-details/jurisdiction-details.component';
import { AgeGroupsComponent } from './age-groups/age-groups.component';
import { CategoryDetailsComponent } from './category-details/category-details.component';
import { RelevantProductsComponent } from './relevant-products/relevant-products.component';

@Component({
  selector: 'app-add-country',
  templateUrl: './add-country.component.html',
  styleUrls: ['./add-country.component.scss']
})
export class AddCountryComponent implements OnInit {

  @Input() fromParent;
  @ViewChild(JurisdictionDetailsComponent) jurisComponent: JurisdictionDetailsComponent;
  @ViewChild(AgeGroupsComponent) ageComponent: AgeGroupsComponent;
  @ViewChild(CategoryDetailsComponent) catComponent: CategoryDetailsComponent;
  @ViewChild(RelevantProductsComponent) relevantComponent: RelevantProductsComponent;

  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };

  config: NgWizardConfig = {
    selected: 0,
    anchorSettings:{
      enableAllAnchors: false, //make tabs enable
      enableAnchorOnDoneStep: false
    },
    toolbarSettings: {
      showNextButton: false,
      showPreviousButton: false
    }
  };

  collectedData:any={};
  fromAdd = true;


  constructor(public activeModal: NgbActiveModal, private ngWizardService: NgWizardService) { }

  ngOnInit(): void {

  }

  closeModal() {
    this.activeModal.close('close');
  }

  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?) {
    this.ngWizardService.next();
    this.collectedData[event.tab] = event.data;
    if(event.tab=='country') this.jurisComponent.initJurisdiction();
    else if(event.tab=='jurisdiction') this.catComponent.initCategories();
    else if(event.tab=='category') this.ageComponent.initAgeGroup();
    else if(event.tab=='age_groups') this.relevantComponent.initProducts();
  }

  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }

  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }

  stepChanged(args: StepChangedArgs) { }

  isValidTypeBoolean: boolean = true;

  isValidFunctionReturnsBoolean(args: StepValidationArgs) {
    return true;
  }

  isValidFunctionReturnsObservable(args: StepValidationArgs) {
    return of(true);
  }
}

import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/core/services/general.service';
import { SharedService } from 'src/app/core/services/shared.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  business = [];
  tourist = [];

  constructor(
    private api: GeneralService,
    public sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.api.getFooterBusiness().subscribe(
      (result:any)=>{
        this.business = result;
      },
      (error) => {
        console.log(error);
      }
    ),
    this.api.getFooterTourist().subscribe(
      (result:any)=>{
        this.tourist = result;
      },
      (error) => {
        console.log(error);
      }
    )
  }
}

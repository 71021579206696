<div class="container">
    <form [formGroup]="sideForm" (ngSubmit)="onSubmit(sideForm)">
        <div class="row">
            <div class="col-md-4 mt-2" formArrayName="sideinfo" *ngFor="let item of sideinfo().controls; let i = index;">
                <div class="border shadow bg-white p-4 mt-2" [formGroupName]="i">
                    <a *ngIf="sideinfo().controls.length>1" href="javascript:void(0)" class="ms-2 close-btn"
                        (click)="removesideinfo(i)"><i class="uil-times fa-2x"></i></a>
                    <div class="row">
                        <div class="mt-2  col-md-12">
                            <label class="col-form-label">Heading</label>
                            <input type="text" class="form-control" placeholder="Heading" formControlName="heading"
                                (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('heading').invalid && isSubmitted}">
                        </div>
                        <div class="mt-2  col-md-12">
                            <label class="col-form-label">Sub Heading</label>
                            <input type="text" class="form-control" placeholder="Sub-Heading"
                                formControlName="sub_heading" (change)="dataChanged(i)"
                                [ngClass]="{'invalid': item.get('sub_heading').invalid && isSubmitted}">
                        </div>
                        <div class="mt-2  row">
                            <div class="col-md-12">
                                <label class="col-form-label">Description</label>
                                <textarea class="form-control" rows="4" placeholder="Description"
                                    formControlName="description" (change)="dataChanged(i)"
                                    [ngClass]="{'invalid': item.get('description').invalid && isSubmitted}"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 p-5">
                <div class="row">
                    <button
                        *ngIf="(page=='about-us' && checkPermission('content_management_about_us_create')) || (page=='country' && checkPermission('content_management_country_create')) || (page=='category' &&checkPermission('content_management_category_create')) || (page=='product' && checkPermission('content_management_product_create'))"
                        class="btn btn-primary mt-1 me-1 col-md-12" type="button" (click)="addsideinfo()">
                        <i class="uil-plus"></i> Add
                    </button>
                    <button
                        *ngIf="(page=='about-us' && checkPermission('content_management_about_us_create')) || (page=='country' && checkPermission('content_management_country_create')) || (page=='category' &&checkPermission('content_management_category_create')) || (page=='product' && checkPermission('content_management_product_create')) && (page=='about-us' && checkPermission('content_management_about_us_edit')) || (page=='country' && checkPermission('content_management_country_edit')) || (page=='category' &&checkPermission('content_management_category_edit')) || (page=='product' && checkPermission('content_management_product_edit'))"
                        class="btn btn-info mt-1 col-md-12" type="submit">
                        <i class="bx bx-save"></i> Save All
                    </button>
                    <app-publish-btn
                        *ngIf="(page=='about-us' && checkPermission('content_management_about_us_publish')) || (page=='country' && checkPermission('content_management_country_publish')) || (page=='category' &&checkPermission('content_management_category_publish')) || (page=='product' && checkPermission('content_management_product_publish'))"
                        class="col-md-12 p-0 mt-1" [publish]="publishPage" [page]="page"></app-publish-btn>
                </div>
            </div>
        </div>
    </form>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationTemplatesService {

  constructor(private http: HttpClient) { }

  getNotification(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/sms-template/`).pipe(retry(2));
  }

  setNotification(data): Observable<any>  {
    return this.http.post(`${environment.apiUrl}/sms-template/`, data);
  }

  editNotification(id, data): Observable<any>  {
    return this.http.put(`${environment.apiUrl}/sms-template/${id}/`, data);
  }

  deleteNotification(id: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/sms-template/` + `${id}/`);
  }

  getEmailNotification(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/email-template/`).pipe(retry(2));
  }

  setEmailNotification(data): Observable<any>  {
    return this.http.post(`${environment.apiUrl}/email-template/`, data);
  }

  editEmailNotification(id, data): Observable<any>  {
    return this.http.put(`${environment.apiUrl}/email-template/${id}/`, data);
  }

  deleteEmailNotification(id: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/email-template/` + `${id}/`);
  }

  getCoverNotification(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/cover-letter/`).pipe(retry(2));
  }

  setCoverNotification(data): Observable<any>  {
    return this.http.post(`${environment.apiUrl}/cover-letter/`, data);
  }

  editCoverNotification(id, data): Observable<any>  {
    return this.http.put(`${environment.apiUrl}/cover-letter/${id}/`, data);
  }

  deleteCoverNotification(id: number): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/cover-letter/` + `${id}/`);
  }

  getWhatsappNotification(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/whatsapp-template/get-whatsapp-memory-variables/`).pipe(retry(2));
  }

  syncWhatsappNotification(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/whatsapp-template/whatsapp-template-creyoface/`).pipe(retry(2));
  }

  setWhatsappMemoryVariables(data): Observable<any>  {
    return this.http.post(`${environment.apiUrl}/whatsapp-template/whatsapp-memory-variables/`, data);
  }

// Trigger Points

  getTriggerPointsList(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/trigger-points/`).pipe(retry(2));
  }
  
  setTriggerNotifications(data): Observable<any>  {
    return this.http.post(`${environment.apiUrl}/trigger-notification/`, data);
  }
  
  getTriggerSmsList(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/notification-type/all/?type_id=1`).pipe(retry(2));
  }

  getTriggerEmailList(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/notification-type/all/?type_id=2`).pipe(retry(2));
  }

  getTriggerWhatsappList(): Observable<any>  {
    return this.http.get(`${environment.apiUrl}/notification-type/all/?type_id=3`).pipe(retry(2));
  }
}
